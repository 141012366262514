import { FC } from "react"
import { FormattedDate, IntlProvider, FormatDateOptions } from "react-intl"
import { SV } from "Constants/languages"

type CustomFormattedDatePropTypes = {
  value: string | Date | number
} & FormatDateOptions

const CustomFormattedDate: FC<CustomFormattedDatePropTypes> = ({
  value,
  ...rest
}) => (
  <IntlProvider locale={SV}>
    <FormattedDate value={value} {...rest} />
  </IntlProvider>
)

export default CustomFormattedDate
