import axios from "ApiServices/axios"
import { Api } from "Constants/api"
import type { AxiosError } from "axios"
import type { IUser } from "Interfaces/userInterfaces"

export const getUser = async () => {
  try {
    const { data } = await axios.get<IUser>(Api.User)

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const logout = () => axios.post(Api.Logout)
