import { FC, useState } from "react"
import { useLocation } from "react-router-dom"
import { FormattedMessage } from "react-intl"

import Button from "UI/Button"
import { ICING_EVENTS } from "Events/List"
import LoanItem from "./LoanItem/LoanItem"
import { publish } from "../../../Events"
import Information from "../UI/Information/Information"
import { SelectedLoans } from "./LoanItem/LoanItem.types"
import * as S from "./LoansList.styles"

interface LoansListProps {
  loans: SelectedLoans[]
  onAddLoan: (loan: SelectedLoans[]) => void
  onRemoveLoan?: (id: string) => void
  showAddButton?: boolean
  onShowLoans?: () => void
  isMainInterface?: boolean
}

const LoansList: FC<LoansListProps> = ({
  loans,
  showAddButton,
  onAddLoan,
  onRemoveLoan,
  onShowLoans,
  isMainInterface,
}) => {
  const [tempLoans, setTempLoans] = useState<SelectedLoans[]>([])
  const location = useLocation()

  const handleAddTempLoan = (loan: SelectedLoans) => {
    const updatedTempLoans = [...tempLoans, loan]

    setTempLoans(updatedTempLoans)
  }

  const handleRemoveTempLoan = (id: string) => {
    const updatedTempLoans = tempLoans.filter((tempLoan) => tempLoan.id !== id)

    setTempLoans(updatedTempLoans)
  }

  const handleConfirmLoans = () => {
    publish(ICING_EVENTS.CLICK_CONFIRM_SELECTED_LOANS, {
      standalone: location.pathname === "/iceing",
    })
    onAddLoan(tempLoans)
    if (onShowLoans) {
      onShowLoans()
    }
  }

  let loansUI

  if (loans.length === 0) {
    loansUI = <h3>No loans available.</h3>
  } else {
    const loansListItems = loans.map((loan) => (
      <div key={loan.id}>
        <LoanItem
          loan={loan}
          checkboxId={loan.id}
          onAddTempLoan={handleAddTempLoan}
          onRemoveLoan={
            isMainInterface && onRemoveLoan
              ? onRemoveLoan
              : handleRemoveTempLoan
          }
          isMainInterface={isMainInterface}
        />
      </div>
    ))

    loansUI = (
      <>
        {loansListItems}
        {showAddButton && (
          <>
            <Information
              content={{
                p: "app.mypages.iceing.use.another.creditor",
              }}
              style={{ p: { color: "gray" } }}
            />
            <Button
              type="button"
              onClick={handleConfirmLoans}
              disabled={tempLoans.length === 0}
            >
              <FormattedMessage id="app.mypages.iceing.add.to.application" />
            </Button>
          </>
        )}
      </>
    )
  }

  return <S.LoansList data-cy="loans-list">{loansUI}</S.LoansList>
}

export default LoansList
