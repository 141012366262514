import { FC, useCallback, useState } from "react"
import { AxiosError } from "axios"
import { useParams } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"

import {
  useGetDepositServiceByID,
  useProcessBankPaymentRequest,
} from "Hooks/API/useDepositServices"
import Loading from "UI/Loading"
import TinkCallback from "Components/TinkCallback"
import { useFeedbackModal } from "Context/modal-context"
import { useGetCustomer } from "Hooks/API/useCustomer"
import IconFactory from "UI/IconFactory"
import { PaymentMethod, paymentMethodNames, PaymentMethods } from "./constants"
import { ErrorType } from "./SavingsDeposit.types"
import * as S from "./SavingsDeposit.styles"
import DepositModalSuccess from "./DepositModalSuccess"

const SavingsDeposit: FC<{ closeDrawer: () => void }> = ({ closeDrawer }) => {
  const { accountID } = useParams() as { accountID: string }
  const intl = useIntl()

  const { isLoading, data, isError } = useGetDepositServiceByID(accountID)
  const { data: customer } = useGetCustomer()
  const processBankPayment = useProcessBankPaymentRequest(accountID)
  const { errorModal } = useFeedbackModal()

  const [isTinkWindow, setIsTinkWindow] = useState(false)
  const [paymentID, setPaymentID] = useState("")
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null)
  const [isSuccessModal, setIsSuccessModal] = useState(false)

  const handleCloseDrawer = useCallback(() => {
    setPaymentMethod(null)
    setIsTinkWindow(false)
    closeDrawer()
  }, [closeDrawer])

  const closeSuccessModal = () => {
    setIsSuccessModal(false)
    closeDrawer()
  }

  const handleTinkEvent = useCallback(
    (type: string, eventData: string | { element: string }) => {
      if (type === "error") {
        errorModal(
          intl.formatMessage({
            id: "app.mypages.savings.onBoarding.verification.tink.error",
          })
        )
        setIsTinkWindow(false)
        return
      }

      if (
        typeof eventData === "object" &&
        eventData.element === "CLOSE_BUTTON"
      ) {
        setIsTinkWindow(false)
      }

      if (type !== "payment_request_id") {
        return
      }

      if (eventData && typeof eventData === "string") {
        processBankPayment
          .mutateAsync({ uid: accountID, requestID: eventData })
          .then(() => {
            setPaymentMethod(null)
            setIsTinkWindow(false)
            setIsSuccessModal(true)
          })
          .catch((error: AxiosError<ErrorType>) =>
            errorModal(
              error.response
                ? error?.response?.data?.message
                : intl.formatMessage({
                    id: "app.mypages.notifications.errorMessage",
                  })
            )
          )
      }
    },
    [errorModal, intl, processBankPayment, accountID]
  )

  if (isLoading || !data) {
    return (
      <S.LoadingWrapper>
        <Loading />
      </S.LoadingWrapper>
    )
  }

  if (isError) {
    return (
      <h1 data-testid="error">
        <FormattedMessage
          id="app.mypages.error.deposit.getDeposit"
          defaultMessage="Kan inte få tillgång till den begärda insättningstjänsten. Försök igen."
        />
      </h1>
    )
  }

  const SelectedPaymentMethod = paymentMethod
    ? PaymentMethods[paymentMethod]
    : null

  return (
    <S.SavingsDepositWrap>
      <S.SavingsDepositBody>
        {!SelectedPaymentMethod && (
          <div>
            <S.DepositsContentHeader>
              <FormattedMessage
                id="app.mypages.savings.deposit.paragraph"
                defaultMessage="Hur vill du sätta in pengar?"
              />
            </S.DepositsContentHeader>

            <S.SavingsDepositDescription>
              <FormattedMessage
                id="app.mypages.savings.deposit.description"
                defaultMessage="Välj hur du vill sätta in pengar. Insättningar tar vanligtvis 1-2 vardagar."
              />
            </S.SavingsDepositDescription>

            <S.DepositContent>
              {paymentMethodNames.map((item) => (
                <S.DepositMethod
                  key={item.method}
                  onClick={() => setPaymentMethod(item.method)}
                >
                  <S.DepositMethodNameWrap>
                    <S.DepositMethodName>{item.name}</S.DepositMethodName>
                    <S.DepositMethodDecription>
                      {item.description}
                    </S.DepositMethodDecription>
                  </S.DepositMethodNameWrap>
                  <IconFactory name="chevron" />
                </S.DepositMethod>
              ))}
            </S.DepositContent>
          </div>
        )}

        {SelectedPaymentMethod && (
          <S.BackButton
            onClick={() => {
              setPaymentMethod(null)
              setIsTinkWindow(false)
            }}
          >
            <IconFactory name="chevron" />
            <FormattedMessage id="app.common.back" defaultMessage="Tillbaka" />
          </S.BackButton>
        )}

        {SelectedPaymentMethod && !isTinkWindow && (
          <SelectedPaymentMethod
            setIsTinkWindow={setIsTinkWindow}
            setPaymentID={setPaymentID}
            productNumber={data.product_number}
            marketplaceNumber={data.marketplace_bg_number}
            handleClose={handleCloseDrawer}
          />
        )}

        {isTinkWindow && customer && (
          <TinkCallback
            type="pay"
            showBackButton
            paymentID={paymentID}
            handleTinkEvent={handleTinkEvent}
            legalEntityNumber={customer.legal_entity_number}
          />
        )}

        <DepositModalSuccess
          isSuccessModal={isSuccessModal}
          onClick={closeSuccessModal}
        />
      </S.SavingsDepositBody>
    </S.SavingsDepositWrap>
  )
}

export default SavingsDeposit
