import { useMemo } from "react"
import { useIntl } from "react-intl"

export const useCountryEnglishList = () => {
  return [
    ["SE", "Sweden"],
    ["AF", "Afghanistan"],
    ["AL", "Albania"],
    ["DZ", "Algeria"],
    ["AS", "American Samoa"],
    ["AD", "Andorra"],
    ["AO", "Angola"],
    ["AI", "Anguilla"],
    ["AQ", "Antarctica"],
    ["AG", "Antigua and Barbuda"],
    ["AR", "Argentina"],
    ["AM", "Armenia"],
    ["AW", "Aruba"],
    ["AU", "Australia"],
    ["AT", "Austria"],
    ["AZ", "Azerbaijan"],
    ["BS", "Bahamas (the)"],
    ["BH", "Bahrain"],
    ["BD", "Bangladesh"],
    ["BB", "Barbados"],
    ["BY", "Belarus"],
    ["BE", "Belgium"],
    ["BZ", "Belize"],
    ["BJ", "Benin"],
    ["BM", "Bermuda"],
    ["BT", "Bhutan"],
    ["BO", "Bolivia (Plurinational State of)"],
    ["BQ", "Bonaire, Sint Eustatius and Saba"],
    ["BA", "Bosnia and Herzegovina"],
    ["BW", "Botswana"],
    ["BV", "Bouvet Island"],
    ["BR", "Brazil"],
    ["IO", "British Indian Ocean Territory (the)"],
    ["BN", "Brunei Darussalam"],
    ["BF", "Burkina Faso"],
    ["BG", "Bulgaria"],
    ["BI", "Burundi"],
    ["CV", "Cabo Verde"],
    ["KH", "Cambodia"],
    ["CM", "Cameroon"],
    ["CA", "Canada"],
    ["KY", "Cayman Islands (the)"],
    ["CF", "Central African Republic (the)"],
    ["TD", "Chad"],
    ["CL", "Chile"],
    ["CN", "China"],
    ["CX", "Christmas Island"],
    ["CC", "Cocos (Keeling) Islands (the)"],
    ["CO", "Colombia"],
    ["KM", "Comoros (the)"],
    ["CD", "Congo (the Democratic Republic of the)"],
    ["CG", "Congo (the)"],
    ["CK", "Cook Islands (the)"],
    ["CR", "Costa Rica"],
    ["HR", "Croatia"],
    ["CU", "Cuba"],
    ["CW", "Curaçao"],
    ["CY", "Cyprus"],
    ["CZ", "Czechia"],
    ["CI", "Côte d'Ivoire"],
    ["DK", "Denmark"],
    ["DJ", "Djibouti"],
    ["DM", "Dominica"],
    ["DO", "Dominican Republic (the)"],
    ["EC", "Ecuador"],
    ["EG", "Egypt"],
    ["SV", "El Salvador"],
    ["GQ", "Equatorial Guinea"],
    ["ER", "Eritrea"],
    ["EE", "Estonia"],
    ["SZ", "Eswatini"],
    ["ET", "Ethiopia"],
    ["FK", "Falkland Islands (the) [Malvinas]"],
    ["FO", "Faroe Islands (the)"],
    ["FJ", "Fiji"],
    ["FI", "Finland"],
    ["FR", "France"],
    ["GF", "French Guiana"],
    ["PF", "French Polynesia"],
    ["TF", "French Southern Territories (the)"],
    ["GA", "Gabon"],
    ["GM", "Gambia (the)"],
    ["GE", "Georgia"],
    ["DE", "Germany"],
    ["GH", "Ghana"],
    ["GI", "Gibraltar"],
    ["GR", "Greece"],
    ["GL", "Greenland"],
    ["GD", "Grenada"],
    ["GP", "Guadeloupe"],
    ["GU", "Guam"],
    ["GT", "Guatemala"],
    ["GG", "Guernsey"],
    ["GN", "Guinea"],
    ["GW", "Guinea-Bissau"],
    ["GY", "Guyana"],
    ["HT", "Haiti"],
    ["HM", "Heard Island and McDonald Islands"],
    ["VA", "Holy See (the)"],
    ["HN", "Honduras"],
    ["HK", "Hong Kong"],
    ["HU", "Hungary"],
    ["IS", "Iceland"],
    ["IN", "India"],
    ["ID", "Indonesia"],
    ["IR", "Iran (Islamic Republic of)"],
    ["IQ", "Iraq"],
    ["IE", "Ireland"],
    ["IM", "Isle of Man"],
    ["IL", "Israel"],
    ["IT", "Italy"],
    ["JM", "Jamaica"],
    ["JP", "Japan"],
    ["JE", "Jersey"],
    ["JO", "Jordan"],
    ["KZ", "Kazakhstan"],
    ["KE", "Kenya"],
    ["KI", "Kiribati"],
    ["KP", "Korea (the Democratic People's Republic of)"],
    ["KR", "Korea (the Republic of)"],
    ["KW", "Kuwait"],
    ["KG", "Kyrgyzstan"],
    ["LA", "Lao People's Democratic Republic (the)"],
    ["LV", "Latvia"],
    ["LB", "Lebanon"],
    ["LS", "Lesotho"],
    ["LR", "Liberia"],
    ["LY", "Libya"],
    ["LI", "Liechtenstein"],
    ["LT", "Lithuania"],
    ["LU", "Luxembourg"],
    ["MO", "Macao"],
    ["MG", "Madagascar"],
    ["MW", "Malawi"],
    ["MY", "Malaysia"],
    ["MV", "Maldives"],
    ["ML", "Mali"],
    ["MT", "Malta"],
    ["MH", "Marshall Islands (the)"],
    ["MQ", "Martinique"],
    ["MR", "Mauritania"],
    ["MU", "Mauritius"],
    ["YT", "Mayotte"],
    ["MX", "Mexico"],
    ["FM", "Micronesia (Federated States of)"],
    ["MD", "Moldova (the Republic of)"],
    ["MC", "Monaco"],
    ["MN", "Mongolia"],
    ["ME", "Montenegro"],
    ["MS", "Montserrat"],
    ["MA", "Morocco"],
    ["MZ", "Mozambique"],
    ["MM", "Myanmar"],
    ["NA", "Namibia"],
    ["NR", "Nauru"],
    ["NP", "Nepal"],
    ["NL", "Netherlands (the)"],
    ["NC", "New Caledonia"],
    ["NZ", "New Zealand"],
    ["NI", "Nicaragua"],
    ["NE", "Niger (the)"],
    ["NG", "Nigeria"],
    ["NU", "Niue"],
    ["NF", "Norfolk Island"],
    ["MP", "Northern Mariana Islands (the)"],
    ["NO", "Norway"],
    ["OM", "Oman"],
    ["PK", "Pakistan"],
    ["PW", "Palau"],
    ["PS", "Palestine, State of"],
    ["PA", "Panama"],
    ["PG", "Papua New Guinea"],
    ["PY", "Paraguay"],
    ["PE", "Peru"],
    ["PH", "Philippines (the)"],
    ["PN", "Pitcairn"],
    ["PL", "Poland"],
    ["PT", "Portugal"],
    ["PR", "Puerto Rico"],
    ["QA", "Qatar"],
    ["MK", "Republic of North Macedonia"],
    ["RO", "Romania"],
    ["RU", "Russian Federation (the)"],
    ["RW", "Rwanda"],
    ["RE", "Réunion"],
    ["BL", "Saint Barthélemy"],
    ["SH", "Saint Helena, Ascension and Tristan da Cunha"],
    ["KN", "Saint Kitts and Nevis"],
    ["LC", "Saint Lucia"],
    ["MF", "Saint Martin (French part)"],
    ["PM", "Saint Pierre and Miquelon"],
    ["VC", "Saint Vincent and the Grenadines"],
    ["WS", "Samoa"],
    ["SM", "San Marino"],
    ["ST", "Sao Tome and Principe"],
    ["SA", "Saudi Arabia"],
    ["SN", "Senegal"],
    ["RS", "Serbia"],
    ["SC", "Seychelles"],
    ["SL", "Sierra Leone"],
    ["SG", "Singapore"],
    ["SX", "Sint Maarten (Dutch part)"],
    ["SK", "Slovakia"],
    ["SI", "Slovenia"],
    ["SB", "Solomon Islands"],
    ["SO", "Somalia"],
    ["ZA", "South Africa"],
    ["GS", "South Georgia and the South Sandwich Islands"],
    ["SS", "South Sudan"],
    ["ES", "Spain"],
    ["LK", "Sri Lanka"],
    ["SD", "Sudan (the)"],
    ["SR", "Suriname"],
    ["SJ", "Svalbard and Jan Mayen"],
    ["SE", "Sweden"],
    ["CH", "Switzerland"],
    ["SY", "Syrian Arab Republic"],
    ["TW", "Taiwan"],
    ["TJ", "Tajikistan"],
    ["TZ", "Tanzania, United Republic of"],
    ["TH", "Thailand"],
    ["TL", "Timor-Leste"],
    ["TG", "Togo"],
    ["TK", "Tokelau"],
    ["TO", "Tonga"],
    ["TT", "Trinidad and Tobago"],
    ["TN", "Tunisia"],
    ["TR", "Turkey"],
    ["TM", "Turkmenistan"],
    ["TC", "Turks and Caicos Islands (the)"],
    ["TV", "Tuvalu"],
    ["UG", "Uganda"],
    ["UA", "Ukraine"],
    ["AE", "United Arab Emirates (the)"],
    ["GB", "United Kingdom of Great Britain and Northern Ireland (the)"],
    ["UM", "United States Minor Outlying Islands (the)"],
    ["US", "United States of America (the)"],
    ["UY", "Uruguay"],
    ["UZ", "Uzbekistan"],
    ["VU", "Vanuatu"],
    ["VE", "Venezuela (Bolivarian Republic of)"],
    ["VN", "Viet Nam"],
    ["VG", "Virgin Islands (British)"],
    ["VI", "Virgin Islands (U.S.)"],
    ["WF", "Wallis and Futuna"],
    ["EH", "Western Sahara"],
    ["YE", "Yemen"],
    ["ZM", "Zambia"],
    ["ZW", "Zimbabwe"],
    ["AX", "Åland Islands"],
  ]
}

export const useCountrySwedishList = () => {
  return [
    ["SE", "Sverige"],
    ["AF", "Afghanistan"],
    ["AL", "Albanien"],
    ["DZ", "Algeriet"],
    ["VI", "Amerikanska Jungfruöarna"],
    ["AS", "Amerikanska Samoa"],
    ["AD", "Andorra"],
    ["AO", "Angola"],
    ["AI", "Anguilla"],
    ["AQ", "Antarktis"],
    ["AG", "Antigua och Barbuda"],
    ["AR", "Argentina"],
    ["AM", "Armenien"],
    ["AW", "Aruba"],
    ["AU", "Australien"],
    ["AZ", "Azerbajdzjan"],
    ["BS", "Bahamas"],
    ["BH", "Bahrain"],
    ["BD", "Bangladesh"],
    ["BB", "Barbados"],
    ["BE", "Belgien"],
    ["BZ", "Belize"],
    ["BJ", "Benin"],
    ["BM", "Bermuda"],
    ["BT", "Bhutan"],
    ["BO", "Bolivia"],
    ["BA", "Bosnien och Hercegovina"],
    ["BW", "Botswana"],
    ["BV", "Bouvetön"],
    ["BR", "Brasilien"],
    ["VG", "Brittiska Jungfruöarna"],
    ["IO", "Brittiska territoriet i Indiska oceanen"],
    ["BN", "Brunei"],
    ["BG", "Bulgarien"],
    ["BF", "Burkina Faso"],
    ["BI", "Burundi"],
    ["KY", "Caymanöarna"],
    ["CF", "Centralafrikanska republiken"],
    ["CL", "Chile"],
    ["CO", "Colombia"],
    ["CK", "Cooköarna"],
    ["CR", "Costa Rica"],
    ["CW", "Curaçao"],
    ["CY", "Cypern"],
    ["CI", "Côte d’Ivoire"],
    ["DK", "Danmark"],
    ["DJ", "Djibouti"],
    ["DM", "Dominica"],
    ["DO", "Dominikanska republiken"],
    ["EC", "Ecuador"],
    ["EG", "Egypten"],
    ["GQ", "Ekvatorialguinea"],
    ["SV", "El Salvador"],
    ["ER", "Eritrea"],
    ["EE", "Estland"],
    ["ET", "Etiopien"],
    ["FK", "Falklandsöarna"],
    ["FJ", "Fiji"],
    ["PH", "Filippinerna"],
    ["FI", "Finland"],
    ["FR", "Frankrike"],
    ["GF", "Franska Guyana"],
    ["PF", "Franska Polynesien"],
    ["TF", "Franska sydterritorierna"],
    ["FO", "Färöarna"],
    ["AE", "Förenade Arabemiraten"],
    ["GA", "Gabon"],
    ["GM", "Gambia"],
    ["GE", "Georgien"],
    ["GH", "Ghana"],
    ["GI", "Gibraltar"],
    ["GR", "Grekland"],
    ["GD", "Grenada"],
    ["GL", "Grönland"],
    ["GP", "Guadeloupe"],
    ["GU", "Guam"],
    ["GT", "Guatemala"],
    ["GG", "Guernsey"],
    ["GN", "Guinea"],
    ["GW", "Guinea-Bissau"],
    ["GY", "Guyana"],
    ["HT", "Haiti"],
    ["HM", "Heardön och McDonaldöarna"],
    ["HN", "Honduras"],
    ["HK", "Hongkong"],
    ["IN", "Indien"],
    ["ID", "Indonesien"],
    ["IQ", "Irak"],
    ["IR", "Iran"],
    ["IE", "Irland"],
    ["IS", "Island"],
    ["IM", "Isle of Man"],
    ["IL", "Israel"],
    ["IT", "Italien"],
    ["JM", "Jamaica"],
    ["JP", "Japan"],
    ["YE", "Jemen"],
    ["JE", "Jersey"],
    ["JO", "Jordanien"],
    ["CX", "Julön"],
    ["KH", "Kambodja"],
    ["CM", "Kamerun"],
    ["CA", "Kanada"],
    ["CV", "Kap Verde"],
    ["BQ", "Karibiska Nederländerna"],
    ["KZ", "Kazakstan"],
    ["KE", "Kenya"],
    ["CN", "Kina"],
    ["KG", "Kirgizistan"],
    ["KI", "Kiribati"],
    ["CC", "Kokosöarna"],
    ["KM", "Komorerna"],
    ["CG", "Kongo-Brazzaville"],
    ["CD", "Kongo-Kinshasa"],
    ["HR", "Kroatien"],
    ["CU", "Kuba"],
    ["KW", "Kuwait"],
    ["LA", "Laos"],
    ["LS", "Lesotho"],
    ["LV", "Lettland"],
    ["LB", "Libanon"],
    ["LR", "Liberia"],
    ["LY", "Libyen"],
    ["LI", "Liechtenstein"],
    ["LT", "Litauen"],
    ["LU", "Luxemburg"],
    ["MO", "Macao"],
    ["MG", "Madagaskar"],
    ["MW", "Malawi"],
    ["MY", "Malaysia"],
    ["MV", "Maldiverna"],
    ["ML", "Mali"],
    ["MT", "Malta"],
    ["MA", "Marocko"],
    ["MH", "Marshallöarna"],
    ["MQ", "Martinique"],
    ["MR", "Mauretanien"],
    ["MU", "Mauritius"],
    ["YT", "Mayotte"],
    ["MX", "Mexiko"],
    ["FM", "Mikronesien"],
    ["MZ", "Moçambique"],
    ["MD", "Moldavien"],
    ["MC", "Monaco"],
    ["MN", "Mongoliet"],
    ["ME", "Montenegro"],
    ["MS", "Montserrat"],
    ["MM", "Myanmar (Burma)"],
    ["NA", "Namibia"],
    ["NR", "Nauru"],
    ["NL", "Nederländerna"],
    ["NP", "Nepal"],
    ["NI", "Nicaragua"],
    ["NE", "Niger"],
    ["NG", "Nigeria"],
    ["NU", "Niue"],
    ["KP", "Nordkorea"],
    ["MK", "Nordmakedonien"],
    ["MP", "Nordmarianerna"],
    ["NF", "Norfolkön"],
    ["NO", "Norge"],
    ["NC", "Nya Kaledonien"],
    ["NZ", "Nya Zeeland"],
    ["OM", "Oman"],
    ["PK", "Pakistan"],
    ["PW", "Palau"],
    ["PS", "Palestinska territorierna"],
    ["PA", "Panama"],
    ["PG", "Papua Nya Guinea"],
    ["PY", "Paraguay"],
    ["PE", "Peru"],
    ["PN", "Pitcairnöarna"],
    ["PL", "Polen"],
    ["PT", "Portugal"],
    ["PR", "Puerto Rico"],
    ["QA", "Qatar"],
    ["RE", "Réunion"],
    ["RO", "Rumänien"],
    ["RW", "Rwanda"],
    ["RU", "Ryssland"],
    ["BL", "S:t Barthélemy"],
    ["SH", "S:t Helena"],
    ["KN", "S:t Kitts och Nevis"],
    ["LC", "S:t Lucia"],
    ["PM", "S:t Pierre och Miquelon"],
    ["VC", "S:t Vincent och Grenadinerna"],
    ["MF", "Saint-Martin"],
    ["SB", "Salomonöarna"],
    ["WS", "Samoa"],
    ["SM", "San Marino"],
    ["ST", "São Tomé och Príncipe"],
    ["SA", "Saudiarabien"],
    ["CH", "Schweiz"],
    ["SN", "Senegal"],
    ["RS", "Serbien"],
    ["SC", "Seychellerna"],
    ["SL", "Sierra Leone"],
    ["SG", "Singapore"],
    ["SX", "Sint Maarten"],
    ["SK", "Slovakien"],
    ["SI", "Slovenien"],
    ["SO", "Somalia"],
    ["ES", "Spanien"],
    ["LK", "Sri Lanka"],
    ["GB", "Storbritannien"],
    ["SD", "Sudan"],
    ["SR", "Surinam"],
    ["SJ", "Svalbard och Jan Mayen"],
    ["SE", "Sverige"],
    ["SZ", "Swaziland"],
    ["ZA", "Sydafrika"],
    ["GS", "Sydgeorgien och Sydsandwichöarna"],
    ["KR", "Sydkorea"],
    ["SS", "Sydsudan"],
    ["SY", "Syrien"],
    ["TJ", "Tadzjikistan"],
    ["TW", "Taiwan"],
    ["TZ", "Tanzania"],
    ["TD", "Tchad"],
    ["TH", "Thailand"],
    ["CZ", "Tjeckien"],
    ["TG", "Togo"],
    ["TK", "Tokelau"],
    ["TO", "Tonga"],
    ["TT", "Trinidad och Tobago"],
    ["TN", "Tunisien"],
    ["TR", "Turkiet"],
    ["TM", "Turkmenistan"],
    ["TC", "Turks- och Caicosöarna"],
    ["TV", "Tuvalu"],
    ["DE", "Tyskland"],
    ["UG", "Uganda"],
    ["UA", "Ukraina"],
    ["HU", "Ungern"],
    ["UY", "Uruguay"],
    ["US", "USA"],
    ["UM", "USA:s yttre öar"],
    ["UZ", "Uzbekistan"],
    ["VU", "Vanuatu"],
    ["VA", "Vatikanstaten"],
    ["VE", "Venezuela"],
    ["VN", "Vietnam"],
    ["BY", "Vitryssland"],
    ["EH", "Västsahara"],
    ["WF", "Wallis- och Futunaöarna"],
    ["ZM", "Zambia"],
    ["ZW", "Zimbabwe"],
    ["AX", "Åland"],
    ["AT", "Österrike"],
    ["TL", "Östtimor"],
  ]
}

export const useCountryList = () => {
  const intl = useIntl()
  const swedishList = useCountrySwedishList()
  const englishList = useCountryEnglishList()

  return useMemo(() => {
    if (intl.locale === "sv") {
      return swedishList
    }
    return englishList
  }, [intl, englishList, swedishList])
}
