import { ChangeEvent } from "react"
import { Optional } from "types/utils"
import { SelectedLoans } from "Pages/LoanCollection/LoansList/LoanItem/LoanItem.types"

export enum BankEnabled {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
  IN_PROGRESS = "IN_PROGRESS",
}

export type IScrappingLoanBank = {
  bankId: number
  bankName: string
  loginStrategies: string[]
  enabled: "ENABLED" | "DISABLED" | "IN_PROGRESS"
}

export type LoanBank = Optional<
  IScrappingLoanBank,
  "bankId" | "loginStrategies" | "enabled"
>

export interface SelectBank {
  onSelectedBank: (bank: {
    bankId: number
    bankName: string
    loginStrategies?: string[]
  }) => void
}

export interface LoginSelectedBank {
  autostarttoken: string
  isLoading: boolean
  isLoggedIn: boolean
  bankName: string
  onLoginSelectedBank: (isLoginIn: boolean) => void
}

export interface OtherActorFormProps {
  onAddLoan: (loan: SelectedLoans[]) => void
  onReset: () => void
  onUploadFiles: (files: File[]) => void
  bankName: string
}

export interface LoanCollectionFilesProps {
  uploadInvalid: boolean
  filesNumber: number
  onUpdateFiles: (event: ChangeEvent<HTMLInputElement>) => void
}

export interface ScrapingBank {
  bankData: {
    sessionToken: string
    userBank: string
    loginType: string
    firstName: string
    lastName: string
    ssn?: string
  }
}

export interface ManualProps {
  isManualForm: boolean
}

export interface ScrapeBank {
  ok: boolean
  loans: {
    creditCards: never[]
    loans: never[]
    installments: never[]
  }
  error: LoanCollectionError
}

export type LoanCollectionError = {
  type: ErrorType
  message: string
}

export const enum ErrorType {
  General = "GENERAL",
  Login = "LOGIN",
  ActionNeeded = "ACTION_NEEDED",
  NameMissmatch = "NAME_MISSMATCH",
  BankError = "BANK_ERROR",
}
