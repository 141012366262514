import styled, { css } from "styled-components"
import {
  HiddenCheckboxPropTypes,
  StyledCheckboxPropTypes,
} from "./Checkbox.types"

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  margin-right: 0.5em;
`

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

export const HiddenCheckbox = styled.input.attrs({
  type: "checkbox",
})<HiddenCheckboxPropTypes>`
  border: 0;
  height: 100%;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 100%;
  opacity: 0;
  z-index: 5;
  cursor: pointer;
`

export const StyledCheckbox = styled.div<StyledCheckboxPropTypes>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid;
  cursor: pointer;

  ${(p) => css`
    border-color: ${p.checked ? "transparent" : "var(--light-gray)"};
    margin: ${p.priority && "0 20px"};
    background: ${p.color && "white"};
    background: ${p.checked ? "var(--light-yellow)" : "var(--white)"};
    background: ${!p.checked && p.priority && "var(--very-light-gray)"};

    ${p.size === "large" &&
    css`
      width: 26px;
      height: 26px;
    `};
    ${Icon} {
      visibility: ${p.checked ? "visible" : "hidden"};
    }
  `}
`
