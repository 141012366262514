import { FC, useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"

import Button from "UI/Button"
import Loading from "UI/Loading"
import Modal from "UI/Modal"
import { Urls } from "Constants/urls"
import { useFeedbackModal } from "Context/modal-context"
import { useDepositCancelApplication } from "Hooks/API/useDepositServices"

import * as S from "./CancelApplication.styles"

const CancelApplication: FC<{
  handleCancelModal: () => void
}> = ({ handleCancelModal }) => {
  const navigate = useNavigate()
  const { accountID } = useParams() as { accountID: string }

  const { successModal, errorModal } = useFeedbackModal()
  const { mutateAsync, isLoading } = useDepositCancelApplication()

  const handleCancelApplication = useCallback(() => {
    mutateAsync(accountID)
      .then(() => {
        successModal(
          <FormattedMessage id="app.mypages.savings.onboarding.cancelApplication.success" />
        )
        navigate(Urls.Savings)
      })
      .catch(() => errorModal())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountID, mutateAsync, navigate])

  return (
    <Modal
      maxWidth="600px"
      onClick={!isLoading ? handleCancelModal : () => {}}
      body={
        <S.CancelModalBody>
          <p>
            <FormattedMessage id="app.mypages.savings.onboarding.cancelApplication" />
          </p>

          <S.ButtonsWrap>
            <Button
              disabled={isLoading}
              variant="inverse"
              onClick={handleCancelModal}
            >
              <FormattedMessage id="app.common.no" />
            </Button>
            <Button onClick={handleCancelApplication} disabled={isLoading}>
              {isLoading ? (
                <Loading size="small" />
              ) : (
                <FormattedMessage id="app.common.yes" />
              )}
            </Button>
          </S.ButtonsWrap>
        </S.CancelModalBody>
      }
    />
  )
}

export default CancelApplication
