import styled from "styled-components"
import { DropdownInner } from "Components/Dropdown/DropdownContent/DropdownContent.styles"
import { DropdownWrapper } from "Components/Dropdown/Dropdown.styles"
import { DropdownTitle } from "Components/Dropdown/Title/Title.styles"
import { font, mediaQueries } from "Styles/styleGlobal"

export const LoanWrap = styled.div`
  padding-top: 1.5em;
`
export const LoanDetailsHeader = styled.h3`
  margin-top: 1.5em;
  margin-bottom: 0.3em;
`

export const LoanDetailsTable = styled.div`
  width: 100%;
`

export const LoanDetailsLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 2em;
`

export const LoanDetailsRow = styled.div`
  width: 100%;
  padding: 1.5em 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--light-gray);
  gap: 1.5em;
  &:last-child {
    border-bottom: none;
    padding: 1em 0;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    font-size: ${font.size.sm};
  }
`
export const LoanDetailsRowValue = styled.div`
  text-align: right;
  font-weight: 500;
  svg {
    cursor: pointer;
  }
`
export const LoanDetailsRowName = styled.div``

export const LoanDetailsAccordionWrap = styled.div`
  padding-top: 1em;
  ${DropdownWrapper} {
    border: 1px solid var(--light-gray);
    border-bottom: none;
    border-radius: 0;
    &:last-child {
      border-bottom: 1px solid var(--light-gray);
      border-radius: 0 0 6px 6px;
      ${DropdownTitle} {
        border-radius: 0 0 6px 6px;
        &.active {
          border-radius: 0;
        }
      }
    }
    &:first-child {
      border-radius: 6px 6px 0 0;
      ${DropdownTitle} {
        border-radius: 6px 6px 0 0;
      }
    }
    ${DropdownTitle} {
      font-weight: 500;
      padding: 1em 0.5em;
      background: var(--very-light-gray) !important;
      margin: 0;
      border-radius: 0;
      @media screen and (max-width: ${mediaQueries.small}) {
        font-size: ${font.size.sm};
      }
    }
  }
  ${DropdownInner} {
    padding: 0 0.5em;
  }
`
