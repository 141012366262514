import { FormattedMessage } from "react-intl"
import { FC, useCallback, useState } from "react"
import { getFirstName } from "Utils/getFirstName"
import Button from "UI/Button"
import { useAuth } from "Context/AuthContext/auth-context"
import type { StatusType } from "Interfaces/KYCInterfaces"
import type { IUser } from "Interfaces/userInterfaces"
import { useFeedbackModal } from "Context/modal-context"
import IconFactory from "UI/IconFactory"
import { POSTPONED_KYC } from "Constants/storage"
import { setStorageItem } from "Utils/localStorage"
import Form from "Components/KYC/Form"
import { useInvestmentServices } from "Hooks/API/useInvestmentServices"
import { useGetDepositServices } from "Hooks/API/useDepositServices"
import * as S from "./UpdateKYC.styles"

type UpdateKYCPropsType = {
  user: IUser
  status: Omit<StatusType, "ok">
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

const UpdateKYC: FC<UpdateKYCPropsType> = ({ user, status, setShow }) => {
  const name = user && getFirstName(user.name)
  const [showKYC, setShowKYC] = useState(false)
  const { closeWithCallback } = useFeedbackModal()

  const { data: investments } = useInvestmentServices()
  const { data: deposits } = useGetDepositServices()

  const hasDepositOrInvestment = Boolean(
    investments?.data.length || deposits?.data.length
  )

  const { logout } = useAuth()
  const handleLogout = useCallback(() => logout(false), [logout])

  const handleShowKYC = useCallback(() => setShowKYC(true), [setShowKYC])

  const closeUpdateKYC = useCallback(() => {
    setStorageItem(POSTPONED_KYC, "true")
    setShow(false)
  }, [setShow])

  const callback = () => {
    closeWithCallback({
      isSuccess: true,
      btnMsg: (
        <FormattedMessage
          id="app.mypages.account.kyc.updateSuccess.modalBtn"
          defaultMessage="Varsågod!"
        />
      ),
      callback: () => {
        setShow(false)
      },
      body: (
        <S.KYCSuccessWrap>
          <p>
            <FormattedMessage
              id="app.mypages.kyc.success.heading"
              defaultMessage="Tack för dina svar!"
            />
          </p>
          <p>
            <FormattedMessage
              id="app.mypages.kyc.success.message"
              defaultMessage="Tänk på att du när som helst kan välja att uppdatera dina svar."
            />
          </p>
        </S.KYCSuccessWrap>
      ),
    })
  }

  return (
    <S.Wrap>
      <S.LogoWrap>
        <IconFactory name="logo" />
      </S.LogoWrap>

      {showKYC ? (
        <Form
          submitCallback={callback}
          hasDepositOrInvestment={hasDepositOrInvestment}
          buttonText={
            <FormattedMessage
              id="app.mypages.updateKYC.cta"
              defaultMessage="Uppdatera mina svar"
            />
          }
        />
      ) : (
        <>
          <h1>
            <FormattedMessage id="app.mypages.home.greeting" /> {name}!
          </h1>

          <p>
            {status === "overdue" ? (
              <FormattedMessage
                id="app.mypages.updateKYC.overdue.paragraph"
                defaultMessage="För att fortsätta på Mina sidor måste du först uppdatera din kundkännedom."
              />
            ) : (
              <FormattedMessage
                id="app.mypages.updateKYC.pending.paragraph"
                defaultMessage="Nu är det dags att uppdatera din kundkännedom."
              />
            )}
          </p>
          <p>
            <FormattedMessage
              id="app.mypages.updateKYC.paragraph2"
              defaultMessage=""
            />
          </p>
          <p>
            <FormattedMessage
              id="app.mypages.updateKYC.paragraph3"
              defaultMessage=""
            />
          </p>
          <S.ButtonWrap>
            <Button onClick={handleShowKYC}>
              <FormattedMessage
                id="app.mypages.updateKYC.cta"
                defaultMessage="Uppdatera mina svar"
              />
            </Button>

            {status === "overdue" ? (
              <Button variant="inverse" onClick={handleLogout}>
                <FormattedMessage
                  id="app.mypages.mainNav.logout"
                  defaultMessage="Logga ut"
                />
              </Button>
            ) : (
              <Button variant="inverse" onClick={closeUpdateKYC}>
                <FormattedMessage
                  id="app.mypages.mainNav.updateLater"
                  defaultMessage="Jag gör det senare"
                />
              </Button>
            )}
          </S.ButtonWrap>
        </>
      )}
    </S.Wrap>
  )
}

export default UpdateKYC
