import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"
import WomanMagnifyingGlass from "Assets/Vectors/woman-magnifying-glass.svg"

export const NotFoundSection = styled.section`
  padding-top: 250px;
  padding-bottom: 150px;
  margin-left: 50px;
  height: 100vh;
  position: relative;
  h1 {
    font-size: ${font.size.xl6};
  }

  p {
    font-size: ${font.size.xl2};
    line-height: 1.4;
    max-width: 550px;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    background-repeat: no-repeat;
    background-position: 70% 20%;
    background-size: 35%;
    margin: 0 20px;
    h1 {
      font-size: ${font.size.xl4};
    }
    p {
      font-size: ${font.size.xl};
    }
  }
`

export const Links = styled.div`
  display: flex;
  flex-direction: flex-start;
  justify-content: space-between;
  max-width: 450px;

  @media screen and (max-width: ${mediaQueries.medium}) {
    flex-direction: column;
    a {
      line-height: 2;
    }
  }

  a {
    color: var(--dark-yellow);
    font-size: ${font.size.xl2};
    font-weight: 700;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    a {
      font-size: ${font.size.xl};
    }
  }
`

export const IconWrapper = styled.div`
  background-image: url(${WomanMagnifyingGlass});
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 90% 50%;
  background-size: 30%;
  top: 0;
  left: 0;
  z-index: -1;
  @media screen and (max-width: ${mediaQueries.medium}) {
    background-position: center 18%;
    background-size: 35%;
  }
`
export const NotFoundHeading = styled.h1``

export const NotFoundDescription = styled.p``
