export class Api {
  static readonly User = "/user"

  static readonly Logout = "/logout"

  static readonly Token = "/token"

  static readonly PaymentFreeMonths = "/payment-free-months"

  static readonly Leads = "/leads"

  static readonly Kyc = "/kyc"
  static readonly KycStatus = `${Api.Kyc}/status`

  static readonly Invoices = "/invoices"
  static readonly InvoicePdf = `${Api.Invoices}/:id/pdf`

  static readonly Eligibilities = "/eligibilities"

  static readonly Customer = "/customer"

  static readonly BasicLoanTransactions = "/basic-loan-service-transactions"

  static readonly Reports = "/documents"
  static readonly ReportsInvestments = `${Api.Reports}/investment-reports`
  static readonly ReportInvestment = `${Api.ReportsInvestments}/:id`
  static readonly ReportsDepositService = `${Api.Reports}/deposit-service-reports`
  static readonly ReportDeposit = `${Api.ReportsDepositService}/:id`
  static readonly ReportPdf = `${Api.Reports}/:id/pdf`

  static readonly BankidAuth = "/bankid/auth"
  static readonly BankidAuthStatus = `${Api.BankidAuth}/:id`

  static readonly LoanContracts = "/loan-contracts"
  static readonly LoanContract = `${Api.LoanContracts}/:id`
  static readonly LoanContractPdf = `${Api.LoanContract}/pdf`
  static readonly LoanContractSignature = `${Api.LoanContract}/signatures`
  static readonly LoanContractSignatureStatus = `${Api.LoanContractSignature}/:signatureId`

  static readonly LoanApplications = "/loan-applications"
  static readonly LoanApplication = `${Api.LoanApplications}/:id`
  static readonly LoanApplicationForm = `${Api.LoanApplications}/:id/application-form`
  static readonly LoanApplicationSign = `${Api.LoanApplication}/signatures`
  static readonly LoanApplicationSignature = `${Api.LoanApplicationSign}/:signatureId`
  static readonly LoanApplicationMarkAsSign = `${Api.LoanApplication}/signed`
  static readonly LoanApplicationBankAccount = `${Api.LoanApplication}/bank-account`
  static readonly LoanApplicationInsurance = `${Api.LoanApplication}/insurance`
  static readonly LoanApplicationInsuranceAgreement = `${Api.LoanApplication}/insurance-agreement`
  static readonly LoanApplicationDocuments = `${Api.LoanApplication}/documents`
  static readonly LoanApplicationCollectLoans = `${Api.LoanApplication}/existing-loans`
  static readonly LoanApplicationAgreement = `${Api.LoanApplication}/agreement`
  static readonly LoanApplicationAgreementPdf = `${Api.LoanApplicationAgreement}/pdf`
  static readonly LoanApplicationAccept = `${Api.LoanApplication}/accept`
  static readonly LoanApplicationStoreKYC = `${Api.LoanApplication}/kyc`
  static readonly LoanApplicationInsuranceAgreementSignatures = `${Api.LoanApplicationInsuranceAgreement}/signatures`
  static readonly LoanApplicationInsuranceAgreementSignature = `${Api.LoanApplicationInsuranceAgreementSignatures}/:signatureId`
  static readonly LoanApplicationBankAccountScreening = `${Api.LoanApplication}/bank-account-screenings`

  static readonly Cashback = "/cashback"
  static readonly CashbackRedemptions = `${Api.Cashback}/redemptions`

  static readonly BasicLoanServices = "/basic-loan-services"
  static readonly BasicLoanService = `${Api.BasicLoanServices}/:id`
  static readonly BasicLoanServiceCoApplicants = `${Api.BasicLoanService}/co-applicants`
  static readonly BasicLoanServiceTermChanges = `${Api.BasicLoanService}/term-changes`
  static readonly BasicLoanServiceContracts = `${Api.BasicLoanService}/contracts`
  static readonly BasicLoanServiceInsurance = `${Api.BasicLoanService}/insurance`

  static readonly InsuranceEligibilities = `/insurances/eligibilities`

  static readonly InvestmentServices = "/investment-services"

  static readonly DepositServices = "/deposit-services"
  static readonly DepositServicesAffectedByInterestRate = `${Api.DepositServices}/profile-interest-rate-change`
  static readonly DepositService = `${Api.DepositServices}/:id`
  static readonly DepositServiceProfiles = `${Api.DepositService}/profiles`
  static readonly DepositServiceTransactionds = `${Api.DepositService}/transactions`
  static readonly DepositServicePreferences = `${Api.DepositService}/preferences`
  static readonly DepositServiceInitialAmount = `${Api.DepositService}/initial-deposit-amount`
  static readonly DepositServiceUpdateManualBank = `${Api.DepositService}/bank-accounts/manual`
  static readonly DepositServiceWithdrawals = `${Api.DepositService}/withdrawals`
  static readonly DepositServiceSignatures = `${Api.DepositService}/signatures`
  static readonly DepositServiceSignatureStatus = `${Api.DepositServiceSignatures}/:signatureId`
  static readonly DepositServiceMonthlySaving = `${Api.DepositService}/monthly-saving`
  static readonly DepositServiceDirectDebitPayment = `${Api.DepositService}/direct-debit-payments`
  static readonly DepositServiceBankPayments = `${Api.DepositService}/bank-payments`
  static readonly DepositServiceBankAccounts = `${Api.DepositService}/bank-accounts`
  static readonly DepositServiceBankPayment = `${Api.DepositServiceBankPayments}/:requestId`
  static readonly DepositServiceAgreementPdf = `${Api.DepositService}/agreement/pdf`
  static readonly DepositServiceReviewStatus = `${Api.DepositService}/status`
  static readonly DepositApplications = "/deposit-applications"
  static readonly DepositServiceCancelApplication = `${Api.DepositService}/cancel-application`
  static readonly DepositServiceCloseAccount = `${Api.DepositService}/close`
}
