import type { ITokenData } from "Interfaces/authInterfaces"
import { getRefreshedToken } from "ApiServices/login"
import type { AxiosRequestConfig, Axios, AxiosError } from "axios"

interface FailedQueueProm {
  resolve: (token: null | string) => void
  reject: (err: AxiosError | null) => void
}

interface IConfig extends AxiosRequestConfig {
  _queued: boolean
  _retry: boolean
}

interface IAxiosError extends AxiosError {
  config: IConfig
}

type TokenDataType = {
  idToken: string
  refreshToken: string
  expiresAt: number
}

const shouldIntercept = (error: AxiosError) => {
  try {
    return (
      error?.response?.status === 401 && !error.config.url?.includes("/scrape")
    )
  } catch (e) {
    return false
  }
}

const handleTokenRefresh = () =>
  new Promise((resolve, reject) => {
    getRefreshedToken()
      .then((data: ITokenData) => {
        const tokenData = {
          idToken: data.access_token,
          refreshToken: data.refresh_token,
          expiresAt: data.expires_in,
        }
        resolve(tokenData)
      })
      .catch(reject)
  })

const attachTokenToRequest = (request: AxiosRequestConfig, token: string) => {
  const auth = request?.headers
  if (auth) {
    auth.Authorization = `Bearer ${token}`
  }
  if (request.url && /\/orders/.test(request.url)) {
    request.params.token = token
  }
}

const refreshEvent = new Event("refresh")

export function applyAppTokenRefreshInterceptor(axiosClient: Axios) {
  let isRefreshing = false
  let failedQueue: FailedQueueProm[] = []

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    shouldIntercept,
  }
  const processQueue = (
    error: AxiosError | null,
    token: null | string = null
  ) => {
    failedQueue.forEach((prom: FailedQueueProm) => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })

    failedQueue = []
  }

  const errorInterceptor = (error: AxiosError) => {
    const err = error as IAxiosError
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error)
    }

    if (err.config._queued) {
      return Promise.reject(error)
    }

    if (err.config._retry) {
      return Promise.reject(error)
    }

    const originalRequest = error.config as IConfig
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject })
      })
        .then((token) => {
          originalRequest._queued = true
          options.attachTokenToRequest(originalRequest, token as string)
          return axiosClient.request(originalRequest)
        })
        .catch(() => Promise.reject(error))
    }

    originalRequest._retry = true
    isRefreshing = true
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then((tokenData) => {
          const { idToken } = tokenData as TokenDataType
          options.attachTokenToRequest(originalRequest, idToken)
          processQueue(null, idToken)
          resolve(axiosClient.request(originalRequest))
          window.dispatchEvent(refreshEvent)
        })
        .catch((e) => {
          processQueue(e, null)
          reject(e)
        })
        .finally(() => {
          isRefreshing = false
        })
    })
  }

  axiosClient.interceptors.response.use(undefined, errorInterceptor)
}
