import { FC } from "react"

import IconFactory from "UI/IconFactory/IconFactory"
import { banksInformation } from "./BanksInformation"
import * as S from "./BankIcon.styles"

interface BankIconProps {
  bankName: string
}

const BankIcon: FC<BankIconProps> = ({ bankName }) => {
  const bankStyling: { background?: string; color?: string } = {}

  if (banksInformation[bankName.toLowerCase()]) {
    bankStyling.background = banksInformation[bankName.toLowerCase()].background
    bankStyling.color =
      banksInformation[bankName.toLowerCase()].description.color
  }

  const IconContentUI = banksInformation[bankName.toLowerCase()] ? (
    <strong>
      {banksInformation[bankName.toLowerCase()].description.firstCharacter}
    </strong>
  ) : (
    <IconFactory name="bank" />
  )

  return <S.Icon isGenericBank={{ ...bankStyling }}>{IconContentUI}</S.Icon>
}

export default BankIcon
