import { useQuery, useMutation, useQueryClient } from "react-query"
import {
  getListLoanContracts,
  getLoanContract,
  createLoanContractSignature,
  getLoanContractSignatureStatus,
} from "ApiServices/loanContracts"
import {
  CreateLoanContractSignature,
  LoanContractSignatureStatus,
} from "Interfaces/loanInterfaces"
import {
  LOAN_CONTRACTS_CONTRACT,
  LOAN_CONTRACTS_DATA,
  LOAN_CONTRACTS_SIGNATURE,
} from "Constants/queryKeys"
import {
  DepositContractSignatureProgressStatus,
  LoanContractSignatureStatusEnum,
} from "types/enums"
import { useState, useEffect, useMemo } from "react"
import { useIntl } from "react-intl"

export function useGetListLoanContracts() {
  return useQuery({
    queryKey: [LOAN_CONTRACTS_DATA],
    queryFn: getListLoanContracts,
    staleTime: 0,
    retry: 0,
  })
}

export function useGetLoanContract(id: string) {
  return useQuery({
    queryKey: [LOAN_CONTRACTS_CONTRACT, id],
    queryFn: () => getLoanContract(id),
    staleTime: 0,
    retry: 0,
  })
}

/** api docs - {@link https://engine.test.brocc.se/docs/customer-api#tag/loan-contracts/paths/~1loan-contracts~1%7Buid%7D~1signatures/post} */
export const useCreateLoanContractSignature = () => {
  return useMutation<CreateLoanContractSignature, unknown, string>({
    mutationFn: createLoanContractSignature,
    retry: 0,
  })
}

/** api docs - {@link https://engine.test.brocc.se/docs/customer-api#tag/loan-contracts/paths/~1loan-contracts~1%7Buid%7D~1signatures~1%7Bsignature-uid%7D/get} */
export const useGetLoanContractSignatureStatus = ({
  enabled,
  signatureUid,
  uid,
}: {
  uid: string
  signatureUid: string
  enabled: boolean
}) => {
  const queryClient = useQueryClient()

  return useQuery<LoanContractSignatureStatus>({
    queryKey: [LOAN_CONTRACTS_SIGNATURE, uid, signatureUid],
    queryFn: () => getLoanContractSignatureStatus(uid, signatureUid),
    enabled,
    refetchInterval: (data) => {
      if (data?.status === LoanContractSignatureStatusEnum.pending) {
        return 2000
      }
      return false
    },
    onSuccess: (data) => {
      if (data.status === LoanContractSignatureStatusEnum.complete) {
        queryClient.invalidateQueries([LOAN_CONTRACTS_CONTRACT, uid])
        queryClient.removeQueries([LOAN_CONTRACTS_DATA])
      }
    },
  })
}

export const useSignLoanContract = (accountID: string) => {
  const intl = useIntl()

  const [
    isLoanContractSignatureStatusQueryEnabled,
    setIsLoanContractSignatureStatusQueryEnabled,
  ] = useState(false)
  const [isSignComplete, setIsSignComplete] = useState(false)
  const [isSignError, setIsSignError] = useState(false)

  const createLoanContractSignatureMutation = useCreateLoanContractSignature()
  const loanContractSignatureStatusQuery = useGetLoanContractSignatureStatus({
    uid: accountID,
    signatureUid: createLoanContractSignatureMutation.data?.uid ?? "",
    enabled:
      !!createLoanContractSignatureMutation.data?.uid &&
      isLoanContractSignatureStatusQueryEnabled,
  })

  const startSigning = () => {
    setIsSignError(false)
    setIsLoanContractSignatureStatusQueryEnabled(true)
    createLoanContractSignatureMutation.mutateAsync(accountID)
  }

  const cancelSigning = () => {
    setIsSignError(false)
    setIsLoanContractSignatureStatusQueryEnabled(false)
  }

  useEffect(() => {
    if (
      loanContractSignatureStatusQuery.data?.status ===
      LoanContractSignatureStatusEnum.complete
    ) {
      setIsLoanContractSignatureStatusQueryEnabled(false)
      setIsSignComplete(true)
      return
    }
    if (
      createLoanContractSignatureMutation.data?.status ===
        LoanContractSignatureStatusEnum.error ||
      createLoanContractSignatureMutation.data?.progress_status ===
        DepositContractSignatureProgressStatus.alreadyInProgress ||
      loanContractSignatureStatusQuery.data?.status ===
        LoanContractSignatureStatusEnum.error
    ) {
      setIsLoanContractSignatureStatusQueryEnabled(false)
      setIsSignError(true)
      loanContractSignatureStatusQuery.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loanContractSignatureStatusQuery.data?.status,
    createLoanContractSignatureMutation.data?.status,
    createLoanContractSignatureMutation.data?.progress_status,
  ])

  const errorMessage = useMemo(() => {
    if (
      loanContractSignatureStatusQuery.data?.status ===
      LoanContractSignatureStatusEnum.error
    ) {
      return intl.formatMessage({
        id: "app.mypages.loans.contractsToSign.status.error",
        defaultMessage:
          "Något gick fel. Prova igen eller kontakta oss om problemet kvarstår.",
      })
    }

    return intl.formatMessage({
      id: "app.login.error.retry",
      defaultMessage: "Internt tekniskt fel. Försök igen.",
    })
  }, [loanContractSignatureStatusQuery.data?.status, intl])

  return {
    isSignError,
    isSignComplete,
    errorMessage,
    qrCode:
      loanContractSignatureStatusQuery.data?.qr_code ||
      createLoanContractSignatureMutation.data?.qr_code ||
      "",
    autostartToken:
      createLoanContractSignatureMutation.data?.auto_start_token || "",
    isSigning:
      loanContractSignatureStatusQuery.data?.progress_status === "user_sign" ||
      loanContractSignatureStatusQuery.data?.progress_status === "complete",
    isLoading: createLoanContractSignatureMutation.isLoading,
    startSigning,
    cancelSigning,
  }
}
