import { FC, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import {
  useGetPaymentFreeMonthStatus,
  usePaymentFreeMonth,
} from "Hooks/API/usePaymentFreeMonth"
import Button from "UI/Button"
import Loading from "UI/Loading"
import Modal from "UI/Modal"
import { TextareaField } from "Components/form/TextareaField"
import IconFactory from "UI/IconFactory"
import { SelectField } from "Components/form/SelectField"
import { useReasonOptions } from "./constants"
import { PaymentFreeSchema } from "./validation"
import {
  PaymentFreeModalPropTypes,
  PaymentFreeMonthPayload,
  ReasonType,
} from "./PaymentFreeModal.types"
import * as S from "./PaymentFreeModal.styles"

const PaymentFreeModal: FC<PaymentFreeModalPropTypes> = ({ setModal }) => {
  const intl = useIntl()
  const paymentFreeMutation = usePaymentFreeMonth()
  const paymentFreeStatus = useGetPaymentFreeMonthStatus()

  const [isPaymentChangeRequested, setIsPaymentChangeRequested] =
    useState(false)

  const reasonOptions = useReasonOptions()

  const form = useForm<PaymentFreeMonthPayload>({
    values: {
      reason: "" as ReasonType,
      comments: "",
    },
    resolver: yupResolver(PaymentFreeSchema),
  })

  const onSubmit: SubmitHandler<PaymentFreeMonthPayload> = (values) => {
    paymentFreeMutation
      .mutateAsync(values)
      .finally(() => setIsPaymentChangeRequested(true))
  }

  const closeModal = () => setModal(false)

  const showCommentsField: boolean = form.watch("reason") === "others"

  let modalTextContent: JSX.Element = (
    <S.TextBlock>
      <IconFactory name="pauseNoBorder" />
      <h2>
        <FormattedMessage
          id="app.mypages.loans.ctaLinks.paymentFreeMonth.modal.form.title"
          defaultMessage="Pausa betalningen"
        />
      </h2>
      <p>
        {intl.formatMessage({
          id: "app.mypages.loans.ctaLinks.paymentFreeMonth.modal.form.text",
          defaultMessage:
            "Pausa din månadsbetalning om något oförutsett händer.",
        })}
      </p>
    </S.TextBlock>
  )

  if (isPaymentChangeRequested && paymentFreeMutation.isError) {
    modalTextContent = (
      <S.ChangeSuccessOrErrorBlock>
        <IconFactory name="pauseNoBorder" />
        <p>
          {intl.formatMessage({
            id: "app.login.error.generalError",
            defaultMessage:
              "Något gick fel. Prova igen senare eller kontakta oss om problemet kvarstår.",
          })}
        </p>
        <Button type="button" onClick={closeModal}>
          {intl.formatMessage({
            id: "app.common.tryAgain",
            defaultMessage: "Prova igen",
          })}
        </Button>
      </S.ChangeSuccessOrErrorBlock>
    )
  }

  if (isPaymentChangeRequested && paymentFreeMutation.isSuccess) {
    modalTextContent = (
      <S.ChangeSuccessOrErrorBlock>
        <IconFactory name="like" />
        <p>
          {intl.formatMessage({
            id: "app.mypages.loans.ctaLinks.paymentFreeMonth.success.title",
            defaultMessage: "Månadsbetalningen är nu pausad!",
          })}
        </p>
        <Button type="button" onClick={closeModal}>
          {intl.formatMessage({
            id: "app.common.thankYou",
            defaultMessage: "Tack!",
          })}
        </Button>
      </S.ChangeSuccessOrErrorBlock>
    )
  }

  return (
    <Modal
      fullWidth
      maxWidth="700px"
      title={
        <FormattedMessage
          id="app.mypages.loans.ctaLinks.paymentFreeMonth.modal.title"
          defaultMessage="Betalfri månad"
        />
      }
      onClick={closeModal}
      body={
        paymentFreeMutation.isLoading || paymentFreeStatus.isLoading ? (
          <S.LoadingContainer>
            <Loading isCentered />
          </S.LoadingContainer>
        ) : (
          <S.PaymentFreeMonthForm onSubmit={form.handleSubmit(onSubmit)}>
            {modalTextContent}
            {!isPaymentChangeRequested && (
              <>
                {paymentFreeStatus.data?.is_eligible &&
                !paymentFreeStatus.data?.is_pending ? (
                  <>
                    <SelectField
                      control={form.control}
                      name="reason"
                      options={reasonOptions}
                      aria-label={intl.formatMessage({
                        id: "app.mypages.loans.ctaLinks.paymentFreeMonth.modal.form.selectPlaceholder",
                        defaultMessage: "Anledning",
                      })}
                    />
                    {showCommentsField && (
                      <TextareaField
                        name="comments"
                        control={form.control}
                        placeholder={intl.formatMessage({
                          id: "app.mypages.loans.ctaLinks.paymentFreeMonth.modal.form.input.placeholder",
                          defaultMessage: "Anledning",
                        })}
                      />
                    )}
                    <Button
                      type="submit"
                      disabled={
                        !paymentFreeStatus.data?.is_eligible ||
                        paymentFreeStatus.data.is_pending ||
                        paymentFreeMutation.isLoading
                      }
                    >
                      {intl.formatMessage({
                        id: "app.mypages.loans.ctaLinks.paymentFreeMonth.modal.form.submitButton",
                        defaultMessage: "Pausa betalning",
                      })}
                    </Button>
                  </>
                ) : (
                  <S.NotEligible>
                    {paymentFreeStatus.data?.is_pending
                      ? intl.formatMessage({
                          id: "app.mypages.loans.ctaLinks.paymentFreeMonth.pending",
                          defaultMessage: "Din nästa betalning är pausad.",
                        })
                      : intl.formatMessage({
                          id: "app.mypages.loans.ctaLinks.paymentFreeMonth.modal.form.notEligible",
                          defaultMessage:
                            "Just nu går det inte att pausa din betalning.",
                        })}
                  </S.NotEligible>
                )}
              </>
            )}
          </S.PaymentFreeMonthForm>
        )
      }
    />
  )
}

export default PaymentFreeModal
