import { FC } from "react"
import { useIntl } from "react-intl"

import IconFactory from "UI/IconFactory"
import { ErrorWrap } from "./ErrorService.styles"
import { ErrorLayoutType, errorMessages } from "./ErrorService.config"

type ErrorServiceProps = {
  type: ErrorLayoutType
}

const ErrorService: FC<ErrorServiceProps> = ({ type }) => {
  const intl = useIntl()

  return (
    <ErrorWrap>
      <IconFactory name="manBinoculars" />
      {intl.formatMessage(errorMessages[type])}
    </ErrorWrap>
  )
}

export default ErrorService
