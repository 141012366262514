import { createGlobalStyle, css, DefaultTheme } from "styled-components"
import "modern-normalize"

declare module "styled-components" {
  export interface DefaultTheme {
    fontColor: string
    background: string
    foreground: string
    borderShadow: string
    infoBox: string
    ownerBox: string
  }
}

export const mediaQueries = {
  extraSmall: "320px",
  small: "480px",
  medium: "768px",
  large: "1024px",
  extraLarge: "1140px",
}

export const font = {
  size: {
    xs: "0.75rem", // "12px / base"
    sm: "0.875rem", // "14px",
    base: "16px",
    lg: "1.125rem", // "18px",
    xl: "1.25rem", // "20px",
    xl2: "1.5rem", // "24px",
    xl3: "1.75rem", // "28px",
    xl4: "2rem", // "32px",
    xl5: "2.5rem", // "40px",
    xl6: "3.5rem", // "56px"
  },
}

export const lightTheme: DefaultTheme = {
  fontColor: "var(--black)",
  background: "var(--white)",
  foreground: "var(--black)",
  borderShadow: "var(--light-gray)",
  infoBox: "var(--white)",
  ownerBox: "var(--very-light-gray)",
}

export const GlobalStyle = createGlobalStyle`
    :root {
        --white: #FFFFFF;
        --black: #000;
        --very-dark-gray: #141414;
        --dark-gray: #717171;
        --light-gray: #E6E6E6;
        --very-light-gray: #F7F7F7;
        --green: #8FBBAF;
        --green-rgb: 143, 187, 175;
        --light-green: #E1EAE8;
        --very-light-yellow: #fef8ea;
        --light-yellow: #F9D682;
        --dark-yellow: #F9B013;
        --black-rgb: 19,19,19;
        --light-yellow-rgb: 249, 214, 130;
        --very-light-gray-rgb: 247, 247, 247;
        --brand-danger: #E1352A;
        --brand-success: #4BB543;
        --purple: #B486B9;
        --purple-rgb: 180,134,185;
        --orange: #F5874C;
        --light-red: #E68382;
        --light-red-rgb: 230, 131, 130;

        --grade-a-bg: #8FBBAF;
        --grade-b-bg: #F9D682;
        --grade-c-bg: #B486B9;
        --grade-d-bg: #E6E6E6;
        --grade-e-bg: #E48483;
        --grade-f-bg: #96AB59;
        --grade-default-bg: #8FBBAF;
    }
    
    *,
    *:before,
    *:after {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
    }

    html {
      font-size: ${font.size.base};
    }

    body {
        font-family: 'Kumbh Sans', sans-serif;
        scrollbar-width: 10px;
    }

    ${(p) => css`
      body {
        color: ${p.theme.fontColor};
        background: ${p.theme.background};
        scrollbar-color: ${p.theme.ownerBox} ${p.theme.background};
      }
    `}

    h1 {
        font-size: ${font.size.xl4};
      }
    h2 {
      font-size: ${font.size.xl3};
    }
    h3 {
      font-size: ${font.size.lg};
    }
    h4,
    h5,
    h6 {
      font-size: ${font.size.base};
    }

    @media screen and (min-width: ${mediaQueries.medium}) {
      h1 {
        font-size: ${font.size.xl5};
      }
      h2 {
        font-size: ${font.size.xl4};
      }
      h3 {
        font-size: ${font.size.xl};
      }
    }

    button {
      font-family: inherit;
      font-size: ${font.size.lg};
      background: none;
      border: none;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }

    h1,h2,h3,h4,h5,h6,button {
        font-weight: 700;
    }

    p,span {
        font-weight: 400;
        line-height: 28px;
        &.small-print {
            font-size: .8em;
            color: var(--dark-gray);
            line-height: 1.4;
        }
    }

    hr {
        border: 1px solid var(--light-gray);
        width: 100%;
    }

    a {
        color: var(--dark-yellow);
        text-decoration: none;
    }

    input {
      font-family: inherit;
    }
    
    body, ul, ol {
      margin: 0;
      padding: 0;
    }

    .visually-hidden {
      position:absolute;
      opacity: 0;
      transform:scale(0);
      width:1px;
      height:1px;
      margin: -1px;
      overflow:hidden;
    }
`
