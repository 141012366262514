import { FormattedMessage } from "react-intl"

import yup from "config/yup"

export const createDepositSchema = (minAmount = 1) =>
  yup.object().shape({
    amount: yup
      .number()
      .transform((v) => (Number.isNaN(v) ? null : v))
      .nullable(true)
      .required()
      .min(minAmount, () => (
        <FormattedMessage
          id="app.depositAccordion.trustly.body.formError"
          defaultMessage="Beloppet bör vara mer än {amount} kr"
          values={{ amount: minAmount }}
        />
      )),
  })
