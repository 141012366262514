import { ReactComponent as MoneyBagIcon } from "Assets/Icons/bordered-icons/money-bag-icon.svg"
import {
  ReactComponent as NewLoanIcon,
  ReactComponent as NewInvestmentIcon,
} from "Assets/Icons/suggestion-icons/new-loan-icon.svg"
import { ReactComponent as ShieldIcon } from "Assets/Icons/shield-icon.svg"
import { ReactComponent as ResultsIcon } from "Assets/Icons/results-icon.svg"
import { ReactComponent as InsuranceIcon } from "Assets/Icons/suggestion-icons/insurance-icon.svg"
import { ReactComponent as CloseIcon } from "Assets/Icons/close-icon.svg"
import { ReactComponent as InfoIcon } from "Assets/Icons/info-icon.svg"
import { ReactComponent as InfoIcon24 } from "Assets/Icons/info-icon-24.svg"
import { ReactComponent as InfoBadgeIcon } from "Assets/Icons/info-badge-icon.svg"
import { ReactComponent as SadIcon } from "Assets/Icons/sad-icon.svg"
import { ReactComponent as SuccessIcon } from "Assets/Icons/success.svg"
import { ReactComponent as ErrorIcon } from "Assets/Icons/error.svg"
import { ReactComponent as Chevron } from "Assets/Icons/chevron-icon.svg"
import { ReactComponent as ChevronRight } from "Assets/Icons/chevron-right.svg"
import { ReactComponent as HappyIcon } from "Assets/Icons/happy-icon.svg"
import { ReactComponent as ExclaimationIcon } from "Assets/Icons/bordered-icons/exclaimation-icon.svg"
import { ReactComponent as PrevButton } from "Assets/Icons/suggestion-icons/previous-icon.svg"
import { ReactComponent as NextButton } from "Assets/Icons/suggestion-icons/next-icon.svg"
import { ReactComponent as PauseIcon } from "Assets/Icons/pause-icon.svg"
import { ReactComponent as TipFriendIcon } from "Assets/Icons/suggestion-icons/tip-friend-icon.svg"
import { ReactComponent as CollectLoansIcon } from "Assets/Icons/suggestion-icons/collect-loans-icon.svg"
import { ReactComponent as TermTimeIcon } from "Assets/Icons/suggestion-icons/term-time-icon.svg"
import { ReactComponent as LowerInterestIcon } from "Assets/Icons/suggestion-icons/lower-interest-icon.svg"
import { ReactComponent as AccountIcon } from "Assets/Icons/account-icon.svg"
import { ReactComponent as SupportIcon } from "Assets/Icons/support-icon.svg"
import { ReactComponent as ExternalLinkIcon } from "Assets/Icons/external-link.svg"
import { ReactComponent as LogOutIcon } from "Assets/Icons/logout-icon.svg"
import { ReactComponent as ReportsIcon } from "Assets/Icons/reports-icon.svg"
import { ReactComponent as BankgirotIcon } from "Assets/Icons/service-icons/bankgirot.svg"
import { ReactComponent as SwishIcon } from "Assets/Icons/service-icons/swish.svg"
import { ReactComponent as TrustlyIcon } from "Assets/Icons/service-icons/trustly.svg"
import { ReactComponent as HappyGreenIcon } from "Assets/Icons/happy-green-icon.svg"
import { ReactComponent as DownloadIcon } from "Assets/Icons/download-icon.svg"
import { ReactComponent as ExtraIcon } from "Assets/Icons/bordered-icons/extra-icon.svg"
import { ReactComponent as OverviewIcon } from "Assets/Icons/overview-icon.svg"
import { ReactComponent as TransactionsIcon } from "Assets/Icons/transactions-icon.svg"
import { ReactComponent as TellAFriendIcon } from "Assets/Icons/tell-a-friend-icon.svg"
import { ReactComponent as StartSavingIcon } from "Assets/Icons/start-saving-icon.svg"
import { ReactComponent as HoldingIcon } from "Assets/Icons/holding-icon.svg"
import { ReactComponent as InvestmentChoicesIcon } from "Assets/Icons/investment-choices-icon.svg"
import { ReactComponent as OffersIcon } from "Assets/Icons/offers-icon.svg"
import { ReactComponent as InvoicesIcon } from "Assets/Icons/invoices-icon.svg"
import { ReactComponent as LoanIcon } from "Assets/Icons/loan-icon.svg"
import { ReactComponent as CashBackIcon } from "Assets/Icons/cash-back-icon.svg"
import { ReactComponent as TrashIcon } from "Assets/Icons/trash-icon.svg"
import { ReactComponent as ManPhonePurple } from "Assets/Vectors/man-phone-purple.svg"
import { ReactComponent as LoanProtectionTick } from "Assets/Icons/loan-protection-tick.svg"
import { ReactComponent as MobilePhone } from "Assets/Vectors/mobile-phone-with-list.svg"
import { ReactComponent as LoanProtectionCross } from "Assets/Icons/loan-protection-cross.svg"
import { ReactComponent as LowerInterestSmallIcon } from "Assets/Icons/lower-interest-icon.svg"
import { ReactComponent as Logo } from "Assets/Vectors/logo.svg"
import { ReactComponent as BellIcon } from "Assets/Icons/bell-icon.svg"
import { ReactComponent as ManRelaxing } from "Assets/Vectors/man-relaxing-color.svg"
import { ReactComponent as ManOkSign } from "Assets/Vectors/man-ok-sign.svg"
import { ReactComponent as PersonArmchairLaptop } from "Assets/Vectors/person-armchair-laptop.svg"
import { ReactComponent as ManBinoculars } from "Assets/Vectors/man-binoculars.svg"
import { ReactComponent as CashbackPigBorder } from "Assets/Icons/bordered-icons/moneybox-pig.svg"
import { ReactComponent as CashbackPig } from "Assets/Icons/cashback-pig.svg"
import { ReactComponent as Chest } from "Assets/Vectors/treasure-chest.svg"
import { ReactComponent as ManThoughtBubble } from "Assets/Vectors/man-thought-bubble.svg"
import { ReactComponent as EditPenIcon } from "Assets/Icons/edit-pen-icon.svg"
import { ReactComponent as BankIDIcon } from "Assets/Icons/bankID-icon.svg"
import { ReactComponent as BankIDWhiteIcon } from "Assets/Icons/bankID-white-icon.svg"
import { ReactComponent as SquareBox } from "Assets/Icons/square-box.svg"
import { ReactComponent as ManPhoneYellow } from "Assets/Vectors/man-phone-yellow.svg"
import { ReactComponent as ManRelaxingGreen } from "Assets/Vectors/man-relaxing-green.svg"
import { ReactComponent as TinkIcon } from "Assets/Icons/service-icons/tink.svg"
import { ReactComponent as AutogiroIcon } from "Assets/Icons/service-icons/autogiro.svg"
import { ReactComponent as Transaction } from "Assets/Icons/transaction.svg"
import { ReactComponent as ClockIcon } from "Assets/Icons/clock-icon.svg"
import { ReactComponent as WomanWithGlass } from "Assets/Vectors/woman-magnifying-glass.svg"
import { ReactComponent as Language } from "Assets/Icons/language.svg"
import { ReactComponent as EnglishIcon } from "Assets/Icons/language-icons/gb-icon.svg"
import { ReactComponent as SwedishIcon } from "Assets/Icons/language-icons/se-icon.svg"
import { ReactComponent as DiscountIcon } from "Assets/Icons/discount-icon.svg"
import { ReactComponent as ArrowLeftIcon } from "Assets/Icons/arrow-left-icon.svg"
import { ReactComponent as ArrowRightIcon } from "Assets/Icons/arrow-right-icon.svg"
import { ReactComponent as SubstractionIcon } from "Assets/Icons/substraction-icon.svg"
import { ReactComponent as PlusIcon } from "Assets/Icons/plus-icon.svg"
import { ReactComponent as WelcomeIcon } from "Assets/Icons/welcome-icon.svg"
import { ReactComponent as GlassesIcon } from "Assets/Icons/glasses-icon.svg"
import { ReactComponent as PauseNoBorderIcon } from "Assets/Icons/pause-noborder-icon.svg"
import { ReactComponent as SecurityIcon } from "Assets/Icons/security-icon.svg"
import { ReactComponent as ArrowRightShort } from "Assets/Icons/arrow-right-short.svg"
import { ReactComponent as LikeIcon } from "Assets/Icons/like-icon.svg"
import { ReactComponent as FilterIcon } from "Assets/Icons/filter.svg"
import { ReactComponent as QuestionIcon } from "Assets/Icons/question-icon.svg"
import { ReactComponent as BankDefaultIcon } from "Assets/Icons/bank-fallback.svg"

export type IconNameType = keyof typeof icons

export const icons = {
  shield: <ShieldIcon />,
  moneyBag: <MoneyBagIcon />,
  newLoan: <NewLoanIcon />,
  results: <ResultsIcon />,
  close: <CloseIcon />,
  insurance: <InsuranceIcon />,
  info: <InfoIcon />,
  info24: <InfoIcon24 />,
  infoBadge: <InfoBadgeIcon />,
  sad: <SadIcon />,
  happy: <HappyIcon />,
  success: <SuccessIcon />,
  error: <ErrorIcon />,
  chevron: <Chevron />,
  chevronRight: <ChevronRight />,
  exclaimation: <ExclaimationIcon />,
  prev: <PrevButton />,
  next: <NextButton />,
  pause: <PauseIcon />,
  pauseNoBorder: <PauseNoBorderIcon />,
  tipFriend: <TipFriendIcon />,
  collectLoans: <CollectLoansIcon />,
  termTime: <TermTimeIcon />,
  lowerInterest: <LowerInterestIcon />,
  lowerInterestSmall: <LowerInterestSmallIcon />,
  account: <AccountIcon />,
  support: <SupportIcon />,
  externalLink: <ExternalLinkIcon />,
  logOut: <LogOutIcon />,
  reports: <ReportsIcon />,
  bankgirot: <BankgirotIcon />,
  swish: <SwishIcon />,
  trustly: <TrustlyIcon />,
  happyGreen: <HappyGreenIcon />,
  newInvestment: <NewInvestmentIcon />,
  download: <DownloadIcon />,
  extra: <ExtraIcon />,
  overview: <OverviewIcon />,
  transactions: <TransactionsIcon />,
  tellAFriend: <TellAFriendIcon />,
  startSaving: <StartSavingIcon />,
  holding: <HoldingIcon />,
  investmentChoices: <InvestmentChoicesIcon />,
  offers: <OffersIcon />,
  invoices: <InvoicesIcon />,
  loan: <LoanIcon />,
  cashBack: <CashBackIcon />,
  trash: <TrashIcon />,
  manPhonePurple: <ManPhonePurple />,
  loanProtectionTick: <LoanProtectionTick />,
  mobilePhone: <MobilePhone />,
  loanProtectionCross: <LoanProtectionCross />,
  logo: <Logo />,
  bell: <BellIcon />,
  manRelaxing: <ManRelaxing />,
  personArmchairLaptop: <PersonArmchairLaptop />,
  manBinoculars: <ManBinoculars />,
  cashbackPigBorder: <CashbackPigBorder />,
  cashbackPig: <CashbackPig />,
  chest: <Chest />,
  manThoughtBubble: <ManThoughtBubble />,
  editPen: <EditPenIcon />,
  bankId: <BankIDIcon />,
  bankIdWhite: <BankIDWhiteIcon />,
  squareBox: <SquareBox />,
  manPhoneYellow: <ManPhoneYellow />,
  manRelaxingGreen: <ManRelaxingGreen />,
  tink: <TinkIcon />,
  autogiro: <AutogiroIcon />,
  transaction: <Transaction />,
  clock: <ClockIcon />,
  womanWithGlass: <WomanWithGlass />,
  discount: <DiscountIcon />,
  language: <Language />,
  english: <EnglishIcon />,
  swedish: <SwedishIcon />,
  arrowLeft: <ArrowLeftIcon />,
  arrowRight: <ArrowRightIcon />,
  substraction: <SubstractionIcon />,
  plus: <PlusIcon />,
  welcome: <WelcomeIcon />,
  glasses: <GlassesIcon />,
  security: <SecurityIcon />,
  arrowRightShort: <ArrowRightShort />,
  like: <LikeIcon />,
  filter: <FilterIcon />,
  question: <QuestionIcon />,
  bank: <BankDefaultIcon />,
  manOkSign: <ManOkSign />,
}
