import English from "Assets/Translations/en.json"
import Swedish from "Assets/Translations/sv.json"
import EnglishIcon from "Assets/Icons/language-icons/gb-icon.svg"
import SwedishIcon from "Assets/Icons/language-icons/se-icon.svg"

export enum LanguagesEnum {
  EN = "en",
  SV = "sv",
}

export type LocaleType = `${LanguagesEnum}`

interface LanguageOption {
  value: `${LanguagesEnum}`
  language: "English" | "Svenska"
}
type LanguageType = typeof Swedish | typeof English

export const EN = "en"
export const SV = "sv"
export const DA = "da"
export const ET = "et"
export const SE = "SE"

export const languages: Record<LanguagesEnum, LanguageType> = {
  sv: Swedish,
  en: English,
}

export const localCountryCodes = {
  [SV]: "sv_SE",
  [EN]: "en_GB",
  [DA]: "da_DK",
  [ET]: "et_EE",
}

export const languageOptions: LanguageOption[] = [
  {
    value: LanguagesEnum.SV,
    language: "Svenska",
  },
  {
    value: LanguagesEnum.EN,
    language: "English",
  },
]

export const languageIcons: Record<LocaleType, string> = {
  sv: SwedishIcon,
  en: EnglishIcon,
}
// prettier-ignore
export type MessageId = keyof typeof languages[LocaleType]

declare global {
  namespace FormatjsIntl {
    interface Message {
      ids: MessageId
      locale: LocaleType
    }
  }
}
