import type { AxiosError, AxiosResponse } from "axios"
import axios from "ApiServices/axios"
import { Api } from "Constants/api"
import type {
  CreateChangeTermRequestOptions,
  CreateChangeTermRequestResponse,
  IBasicLoanService,
  IBasicLoanServices,
  ICoApplicantRequestBody,
  ICoApplicantRequestValues,
  ICurrentContract,
  ILatestCoApplicantRequest,
} from "Interfaces/loanInterfaces"

export const getBasicLoanServices = async () => {
  try {
    const { data } = await axios.get<IBasicLoanServices>(Api.BasicLoanServices)

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getBasicLoanServiceByID = async (uid: string) => {
  try {
    const { data } = await axios.get<IBasicLoanService>(
      Api.BasicLoanService.replace(":id", uid)
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const requestCoApplicant = async ({
  uid,
  email,
  message,
}: ICoApplicantRequestValues) => {
  try {
    const { data } = await axios.post<
      ICoApplicantRequestBody,
      AxiosResponse<ILatestCoApplicantRequest>,
      ICoApplicantRequestBody
    >(Api.BasicLoanServiceCoApplicants.replace(":id", uid), {
      email,
      message,
    })
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const requestLoanTermChange = async ({
  uid,
  repaymentPeriods,
  reason,
}: CreateChangeTermRequestOptions) => {
  try {
    const { data } = await axios.post<CreateChangeTermRequestResponse>(
      Api.BasicLoanServiceTermChanges.replace(":id", uid),
      {
        repayment_periods: repaymentPeriods,
        ...(reason ? { reason } : {}),
      }
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getLoanContracts = async (uid: string) => {
  try {
    const { data } = await axios.get<{ data: ICurrentContract[] }>(
      Api.BasicLoanServiceContracts.replace(":id", uid)
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const postCreateLoanInsurance = async (uid: string) => {
  const { data } = await axios.post(
    Api.BasicLoanServiceInsurance.replace(":id", uid)
  )
  return data
}
