import { useNavigate } from "react-router-dom"
import { FormattedMessage } from "react-intl"

import { Urls } from "Constants/urls"
import { SignFlow } from "Components/BankIDAuth/SignFlow/SignFlow"
import { SignFlowMobile } from "Components/BankIDAuth/SignFlowMobile/SignFlowMobile"
import { isMobile } from "Utils"
import { useSignLoanContract } from "Hooks/API/useLoanContracts"
import Modal from "UI/Modal"
import { SignLoanContractComplete } from "../SignLoanContractComplete/SignLoanContractComplete"

export const SignLoanContractModal = ({
  accountID,
  onClose,
  contractDate,
}: {
  accountID: string
  onClose: () => void
  contractDate: string | null
}) => {
  const navigate = useNavigate()

  const signProcess = useSignLoanContract(accountID)

  if (signProcess.isSignComplete) {
    const onComplete = () => {
      navigate(Urls.Home, { replace: true })
    }

    return (
      <Modal
        maxWidth="600px"
        title={
          <FormattedMessage
            id="app.common.signWithBankID"
            defaultMessage="Signera med BankID"
          />
        }
        onClick={onComplete}
        body={
          <SignLoanContractComplete
            onComplete={onComplete}
            contractDate={contractDate}
          />
        }
      />
    )
  }

  let modalBodyJsx = (
    <SignFlow
      qrCode={signProcess.qrCode}
      autostartToken={signProcess.autostartToken}
      isSigning={signProcess.isSigning}
      isError={signProcess.isSignError}
      errorMessage={signProcess.errorMessage}
      isLoading={signProcess.isLoading}
      startSigning={signProcess.startSigning}
      cancelSigning={signProcess.cancelSigning}
    />
  )

  if (isMobile()) {
    modalBodyJsx = (
      <SignFlowMobile
        qrCode={signProcess.qrCode}
        autostartToken={signProcess.autostartToken}
        isLoading={signProcess.isLoading}
        isSigning={signProcess.isSigning}
        isError={signProcess.isSignError}
        errorMessage={signProcess.errorMessage}
        startSigning={signProcess.startSigning}
        cancelSigning={signProcess.cancelSigning}
      />
    )
  }

  return (
    <Modal
      maxWidth="600px"
      title={
        <FormattedMessage
          id="app.common.signWithBankID"
          defaultMessage="Signera med BankID"
        />
      }
      onClick={onClose}
      body={modalBodyJsx}
    />
  )
}
