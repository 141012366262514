import { FC } from "react"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"

import IconFactory from "UI/IconFactory"
import { ILoanApplication } from "Interfaces/loanApplicationInterfaces"
import { DocumentationActivityType } from "ApiServices/loanApplications"
import { getEnv, EnvKeysEnum } from "Utils/environment"
import RepaymentPeriods from "Components/RepaymentPeriods"
import { formatNumber } from "Utils/formatNumber"
import FileUpload from "./FileUpload"
import * as Styles from "../Onboarding.styles"
import {
  ApplicationProgress,
  DetailsWrap,
  LoanProtectionWrap,
  Link,
} from "./FollowApplication.styles"

type FollowApplicationPropsType = {
  data: ILoanApplication
}

const documentationActivityTypes: DocumentationActivityType[] = [
  "require_three_payslips",
  "require_employment_agreement",
  "require_certificate_of_employment",
  "require_one_payslip",
  "require_company_tax_return",
  "require_existing_loan_redemption",
]

const FollowApplication: FC<FollowApplicationPropsType> = ({ data }) => {
  const { id } = useParams()

  const results: DocumentationActivityType[] = []

  data.activities.forEach((requirement) => {
    documentationActivityTypes.forEach((element) => {
      if (requirement.name === element && !requirement.completed) {
        results.push(requirement.name)
      }
    })
  })

  return (
    <Styles.Container>
      <h1>
        <FormattedMessage
          id="app.onboarding.followApplication.heading"
          defaultMessage="Följ din ansökan"
        />
      </h1>
      <ApplicationProgress>
        <div className="circle-wrap">
          <div className="circle active" />
          <span className="active">
            <FormattedMessage
              id="app.onboarding.followApplication.stage1"
              defaultMessage="Ansökan handläggs"
            />
          </span>
        </div>
        <div className="line" />
        {results.length > 0 && (
          <>
            {" "}
            <div className="circle-wrap">
              <div className="circle active" />
              <span className="active">
                <FormattedMessage
                  id="app.onboarding.followApplication.stage2"
                  defaultMessage="Väntar på kompletteringar"
                />
                <span className="small">
                  <FormattedMessage
                    id="app.onboarding.followApplication.stage2.explaination"
                    defaultMessage="Vi saknar lösenuppgifter, ladda upp dem direkt eller kontakta oss på help@brocc.se."
                  />
                </span>
              </span>
            </div>
            <div className="line" />
          </>
        )}

        <div className="circle-wrap">
          <div className={data.is_issued ? "circle active" : "circle"} />
          <span className={data.is_issued ? "active" : ""}>
            <FormattedMessage
              id="app.onboarding.followApplication.stage3"
              defaultMessage="Pengarna på väg"
            />
          </span>
        </div>

        <div className="line" />
        <div className="circle-wrap">
          <div className={data.is_paid_out ? "circle active" : "circle"} />
          <span className={data.is_paid_out ? "active" : ""}>
            <FormattedMessage
              id="app.onboarding.followApplication.stage4"
              defaultMessage="Pengarna på ditt konto inom 1-2 dagar"
            />
          </span>
        </div>
      </ApplicationProgress>

      {results.length !== 0 ? (
        <Styles.GreenAttentionBox>
          <FormattedMessage
            id="app.onboarding.followApplication.greenAttentionBox.fileUpload"
            defaultMessage="För att snabba på utbetalningen kan du ladda upp lösenuppgifter nedan!"
          />
        </Styles.GreenAttentionBox>
      ) : (
        <Styles.GreenAttentionBox>
          <FormattedMessage
            id="app.onboarding.followApplication.greenAttentionBox.help"
            defaultMessage="Har du frågor kring ditt lån är du välkommen att höra av dig till help@brocc.se"
          />
        </Styles.GreenAttentionBox>
      )}

      <Styles.GrayBox>
        <DetailsWrap>
          <div>
            <div className="detail-amount">
              {formatNumber(data.amount, {
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
            <div className="detail-title">
              <FormattedMessage
                id="app.common.loanAmount"
                defaultMessage="Lånebelopp"
              />
            </div>
          </div>
          <div>
            <div className="detail-amount">
              {formatNumber(data.period_cost, {
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
            <div className="detail-title">
              <FormattedMessage
                id="app.common.monthlyCost"
                defaultMessage="Månadskostnad"
              />
            </div>
          </div>
          <div>
            <div className="detail-amount">
              <RepaymentPeriods repaymentPeriods={data.repayment_periods} />
            </div>
            <div className="detail-title">
              <FormattedMessage
                id="app.common.loanPeriod"
                defaultMessage="Återbetalningstid"
              />
            </div>
          </div>
          <div>
            <div className="detail-amount">
              {formatNumber(+data.effective_interest_rate, {
                style: "percent",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
            <div className="detail-title">
              <FormattedMessage
                id="app.common.effectiveInterest"
                defaultMessage="Effektiv ränta"
              />
            </div>
          </div>
        </DetailsWrap>
        {data.payment_protection_insurance && (
          <div>
            <hr />
            <LoanProtectionWrap>
              <div className="loan-protection-header">
                <FormattedMessage
                  id="app.common.loanProtectionStatement"
                  defaultMessage="Försäkring tillagd"
                />
              </div>
              <div>
                <IconFactory
                  name={
                    data.payment_protection_insurance
                      ? "loanProtectionTick"
                      : "loanProtectionCross"
                  }
                />
              </div>
            </LoanProtectionWrap>
          </div>
        )}
      </Styles.GrayBox>
      {results.length !== 0 && <FileUpload results={results} />}
      <p>
        <FormattedMessage
          id="app.onboarding.followApplication.loanAgreement.text"
          defaultMessage="För att se ditt låneavtal klicka"
        />
        <Link
          href={`${getEnv(
            EnvKeysEnum.BroccApi
          )}/loan-applications/${id}/agreement/pdf`}
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage
            id="app.onboarding.followApplication.loanAgreement.linkText"
            defaultMessage="här"
          />
        </Link>
      </p>
    </Styles.Container>
  )
}

export default FollowApplication
