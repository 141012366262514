import styled from "styled-components"
import { flex } from "config/mixins"
import { font, mediaQueries } from "Styles/styleGlobal"

type DetailsMessagePropTypes = {
  show: boolean
}

export const DetailsWrap = styled.div`
  ${flex.columnAlignCenter}
  span {
    font-weight: bold;
    text-decoration: underline;
    margin: 2em 0 1em 0;
    cursor: pointer;
    @media screen and (max-width: ${mediaQueries.medium}) {
      margin: 1em 0;
    }
  }
`

export const ButtonWrap = styled.div`
  margin: 2em 0 0 0;
  @media screen and (max-width: ${mediaQueries.medium}) {
    display: flex;
    margin: 2em 0;
    justify-content: center;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    margin: 1em 0;
  }
`

export const OverviewTitle = styled.span`
  color: var(--dark-gray);
  font-size: ${font.size.xl};

  @media screen and (max-width: ${mediaQueries.medium}) {
    font-size: ${font.size.base};
  }
`

export const OverviewFigure = styled.div`
  font-size: ${font.size.xl3};
  margin: 1em 0;
  @media screen and (max-width: ${mediaQueries.large}) {
    font-size: ${font.size.lg};
  }
`

export const Amount = styled.div`
  font-size: ${font.size.xl5};
  font-weight: 700;
  @media screen and (min-width: ${mediaQueries.medium}) {
    font-size: ${font.size.xl6};
    font-weight: 700;
    margin: 0.25em 0 0 0;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    font-size: ${font.size.xl4};
  }
`

export const AmountTitle = styled.span`
  color: var(--dark-gray);
  font-size: ${font.size.xl};
`

export const Overview = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  gap: 1.5em 0;
  @media screen and (max-width: ${mediaQueries.small}) {
    gap: 0;
  }
`
export const WelcomeHeading = styled.h1`
  line-height: 42px;
`
export const OverviewItem = styled.div`
  ${flex.columnAlignCenter}
`
export const DetailsMessage = styled.div<DetailsMessagePropTypes>`
  display: ${(props) => (props.show ? "block" : "none")};
  margin-bottom: 1em;
  line-height: 1.5em;
`
