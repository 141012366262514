import { useState, useEffect, useMemo, FC } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"
import { useFlags } from "launchdarkly-react-client-sdk"

import UnauthenticatedNav from "Components/UnauthenticatedNav"
import ProgressBar from "UI/ProgressBar"
import { useGetLoanApplication } from "Hooks/API/useLoansApplications"
import useQueryParams from "Hooks/UI/useQueryParams"
import { useAuth } from "Context/AuthContext/auth-context"
import NotFound from "Components/NotFound"
import Error from "Components/Error"
import Loading from "UI/Loading"
import { LanguagesEnum } from "Constants/languages"
import { useLangContext } from "Context/lang-context"

import { STEPS } from "./constants"
import { OnboardingStepsEnum, LaunchDarklyInterface } from "./Onboarding.types"
import { determineStep } from "./helpers/index"
import Layout from "./Layout"
import * as S from "./Onboarding.styles"

const Onboarding: FC = () => {
  const { locale, selectLanguage } = useLangContext()
  const [step, setStep] = useState<number>(OnboardingStepsEnum.Default)
  const { id } = useParams() as { id: string }
  const { data, isError, isLoading, isSuccess } = useGetLoanApplication(id)
  const query = useQueryParams()
  const { isAuthenticated } = useAuth()
  const flags: LaunchDarklyInterface = useFlags()

  const getCurrentProgress = (): number => {
    const overrideStep =
      step > OnboardingStepsEnum.LoanCollection
        ? OnboardingStepsEnum.LoanCollection
        : step
    return (100 / OnboardingStepsEnum.LoanCollection) * overrideStep
  }

  const ppiReviewStatus = useMemo(
    () =>
      data?.activities.find(
        (activity) => activity.name === "review_payment_protection_insurance"
      ),
    [data]
  )

  // Required while english is not fully supported for the onboarding, might be removed later
  useEffect(() => {
    if (locale !== LanguagesEnum.SV) {
      selectLanguage(LanguagesEnum.SV)
    }
  }, [locale, selectLanguage])

  useEffect(() => {
    if (data) {
      const currentStep: OnboardingStepsEnum = determineStep(data, query, flags)
      setStep(currentStep)
    }
  }, [data, query, flags])

  if (isLoading) {
    return (
      <S.LoadingContainer>
        <Loading size="small" />
      </S.LoadingContainer>
    )
  }

  if (isError) {
    return <NotFound isError={false} />
  }

  const CurrentStep = STEPS[step]

  return (
    <S.OnboardingWrap>
      {isSuccess && !isLoading && CurrentStep ? (
        <>
          <Helmet>
            <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
            <script
              type="text/javascript"
              src="https://frame.euc1.instantor.com/instantor-0.7.3.min.js"
            />
          </Helmet>
          {!isAuthenticated && (
            <UnauthenticatedNav
              hasBankAccount={!!data?.applicant.bank_account_number}
              isSigned={data.is_signed}
              ppiReviewStatus={ppiReviewStatus}
              step={step}
              setStep={setStep}
            />
          )}

          <S.ProgressBarWrap>
            <ProgressBar width={getCurrentProgress()} />
          </S.ProgressBarWrap>

          <Layout data={data} step={step}>
            <CurrentStep data={data} source={data.source} />
          </Layout>
        </>
      ) : (
        <Error />
      )}
    </S.OnboardingWrap>
  )
}

export default Onboarding
