import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const BalanceItem = styled.div`
  @media screen and (max-width: ${mediaQueries.large}) {
    margin: 1.25em 0;
  }
`

export const BalanceTitle = styled.span`
  font-size: ${font.size.base};
  color: var(--dark-gray);
  display: block;
  text-align: center;
`

export const BalanceAmount = styled.div`
  font-size: ${font.size.xl5};
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: ${mediaQueries.large}) {
    font-size: ${font.size.xl2};
  }
`

export const BalanceSubtitle = styled.span`
  display: flex;
  flex-direction: column;
  font-size: ${font.size.sm};
  color: var(--dark-gray);
  span {
    text-align: right;
    line-height: 24px;
    @media screen and (max-width: ${mediaQueries.small}) {
      text-align: center;
    }
  }
`
