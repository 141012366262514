import { useState, forwardRef } from "react"
import { InputProps } from "./Input.types"
import {
  InputWrap,
  StyledInput,
  Placeholder,
  InputValidationMessage,
} from "./Input.styles"

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { placeholder, error, message, onChange, type, name, min, max, ...rest },
    ref
  ) => {
    const [isActive, setIsActive] = useState(false)
    const [value, setValue] = useState("")

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.currentTarget.value)

      if (e.currentTarget.value !== "") {
        setIsActive(true)
      } else {
        setIsActive(false)
      }
      if (onChange) {
        onChange(e)
      }
    }

    return (
      <InputWrap active={isActive || !!rest?.value} data-testid="input">
        <StyledInput
          {...rest}
          ref={ref}
          value={rest.value || value}
          onChange={handleChange}
          error={error}
          type={type}
          name={name}
          id={name}
          min={type === "number" ? min : undefined}
          max={type === "number" ? max : undefined}
        />
        <Placeholder
          htmlFor={name}
          className={isActive || rest.value || value ? "active" : ""}
          error={error}
        >
          {placeholder}
        </Placeholder>
        <InputValidationMessage
          className="error-message"
          data-testid="errorMessage"
        >
          {message}
        </InputValidationMessage>
      </InputWrap>
    )
  }
)

Input.displayName = "Input"

export default Input
