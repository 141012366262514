import { useEffect } from "react"

export const useOverflowEffect = (isHidden: boolean) => {
  useEffect(() => {
    if (isHidden) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }, [isHidden])
}
