import NotFound from "Components/NotFound"
import LoanCollection from "Pages/LoanCollection/LoanCollection"
import FollowApplication from "./FollowApplication/FollowApplication"
import ManualCheck from "./ManualCheck/ManualCheck"
import { StepsType } from "./Onboarding.types"
import Rejected from "./Rejected/Rejected"
import Accept from "./Steps/Accept/Accept"
import BankAccountDetails from "./Steps/BankAccountDetails/BankAccountDetails"
import BankScreening from "./Steps/BankScreening/BankScreening"
import InsuranceCheck from "./Steps/InsuranceCheck/InsuranceCheck"
import LoanPurpose from "./Steps/LoanPurpose/LoanPurpose"
import Signing from "./Steps/Signing/Signing"

export const STEPS: StepsType = {
  1: Accept,
  2: BankScreening,
  3: BankAccountDetails,
  4: Signing,
  5: InsuranceCheck,
  6: LoanPurpose,
  7: LoanCollection,
  8: FollowApplication,
  9: Rejected,
  10: ManualCheck,
  11: NotFound,
}
