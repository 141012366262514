import { FC, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"

import AccountName from "Components/AccountName"
import { IData } from "Utils/getInfoUtils"
import { MessageId } from "Constants/languages"
import * as S from "./PromoList.styles"
import { PromoItemType } from "../PromoWrap.types"
import { StatusOrBalance } from "./StatusOrBalance/StatusOrBalance"

interface PromoListProps {
  data: IData[]
  type: PromoItemType
  accountTitleId: MessageId
}

const PromoList: FC<PromoListProps> = ({ data, type, accountTitleId }) => {
  const [showClosedAccounts, setShowClosedAccounts] = useState(false)

  const { activeAccounts, closedAccounts } = useMemo(() => {
    const active: JSX.Element[] = []
    const closed: JSX.Element[] = []

    data
      .slice()
      .sort((a, b) => {
        if (a.deactivationDatetime && b.deactivationDatetime) {
          return (
            new Date(b.deactivationDatetime).getTime() -
            new Date(a.deactivationDatetime).getTime()
          )
        }
        return 0
      })
      .forEach((item) => {
        const itemJsx = (
          <S.Anchor
            key={item.id}
            as={type === "loanApplications" ? "a" : Link}
            {...getLinkProps(item, type)}
          >
            <S.ActiveListRow type={type}>
              <AccountName
                type={type}
                accountNumber={item.accountNumber}
                accountTitleId={accountTitleId}
                name={item.name}
                progress={item.progress}
                status={item.loanStatus}
              />
              <StatusOrBalance
                type={type}
                status={item.status}
                balance={item.balance}
              />
            </S.ActiveListRow>
          </S.Anchor>
        )
        if (item.status === "closed") {
          closed.push(itemJsx)
        } else {
          active.push(itemJsx)
        }
      })

    return { activeAccounts: active, closedAccounts: closed }
  }, [data, type, accountTitleId])

  return (
    <>
      <S.ActiveList>
        {activeAccounts}
        {showClosedAccounts && closedAccounts}
      </S.ActiveList>
      {type === "savings" && data.length !== activeAccounts.length && (
        <S.ToggleClosedAccountsButton
          onClick={() => setShowClosedAccounts(!showClosedAccounts)}
        >
          <FormattedMessage
            id={`app.mypages.savings.accounts.${
              showClosedAccounts ? "hideClosed" : "showClosed"
            }`}
          />
        </S.ToggleClosedAccountsButton>
      )}
    </>
  )
}

function getLinkProps(item: IData, type: PromoItemType) {
  if (type === "loanApplications") {
    return {
      href: item.link,
      target: "_blank",
      rel: "nooneper noreferrer",
    }
  }
  return {
    to: item.link,
  }
}

export default PromoList
