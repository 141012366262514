import styled from "styled-components"

export const StyledSlider = styled.input`
  -webkit-appearance: none;
  margin-right: 15px;
  width: 100%;
  height: 6px;
  background: var(--light-gray);
  border-radius: 5px;
  background-size: 0%;
  background-image: linear-gradient(var(--black), var(--black));
  background-repeat: no-repeat;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: var(--black);
    cursor: pointer;
    border: none;
    transition: background 0.3s ease-in-out;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: var(--dark-yellow);
    cursor: pointer;
    border: none;
    transition: background 0.3s ease-in-out;
  }

  &::-ms-thumb {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: var(--dark-yellow);
    cursor: pointer;
    border: none;
    transition: background 0.3s ease-in-out;
  }

  &::-moz-range-thumb:hover {
    background: #ff0200;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`
