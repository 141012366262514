import {
  useState,
  useEffect,
  useRef,
  FC,
  Dispatch,
  SetStateAction,
} from "react"
import { Page } from "react-pdf"

const CustomPage: FC<{
  page: number
  setCurrentPage: Dispatch<SetStateAction<number>>
}> = ({ page, setCurrentPage }) => {
  const elementRef = useRef<HTMLDivElement>(null)

  const [isVisible, setIsVisible] = useState(false)

  const onVisibilityChange = (entries: IntersectionObserverEntry[]) => {
    if (entries) {
      setIsVisible(entries[0].isIntersecting)
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(onVisibilityChange)
    if (elementRef.current) {
      observer.observe(elementRef.current)
    }
  }, [elementRef])

  useEffect(() => {
    if (isVisible && elementRef.current) {
      const activePage = elementRef?.current?.dataset.pageNumber || 1
      setCurrentPage(+activePage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (
    <Page
      inputRef={elementRef}
      pageNumber={page}
      scale={window.innerWidth <= 768 ? 1 : 1.5}
      width={window.innerWidth <= 768 ? window.innerWidth - 150 : undefined}
    />
  )
}

export default CustomPage
