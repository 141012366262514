import styled from "styled-components"
import { flex } from "config/mixins"

export const LanguagesBlock = styled.div`
  ${flex.alignCenter}
  gap: 1em;
`

export const LangItem = styled.div`
  position: relative;
  display: flex;
  gap: 1em;
`

export const LangLabel = styled.label<{ langFlagBg: string }>`
  display: block;
  color: transparent;
  line-height: 0;
  border-radius: 50%;
  overflow: hidden;
  width: 30px;
  height: 30px;
  background-image: url(${(p) => p.langFlagBg});
  background-position: center;
  background-size: cover;
  cursor: pointer;
`

export const LangRadioButton = styled.input.attrs({
  type: "radio",
  className: "visually-hidden",
})`
  :not(:checked) + ${LangLabel} {
    opacity: 0.5;
  }
`
