import { ReactElement } from "react"
import { FormattedMessage } from "react-intl"
import { IDepositService } from "Interfaces/depositInterfaces"
import { IBasicLoanService } from "Interfaces/loanInterfaces"
import { MessageId } from "Constants/languages"
import { Urls } from "Constants/urls"

export const createSavingsLinks = (id: string) => [
  {
    to: `${Urls.Savings}/${id}/${Urls.SavingsAccountOverview}`,
    end: true,
    text: (
      <FormattedMessage
        id="app.mypages.mainNav.loan.link.overview"
        defaultMessage="Översikt"
      />
    ),
  },
  {
    to: `${Urls.Savings}/${id}/${Urls.SavingsAccountSettings}`,
    end: true,
    text: (
      <FormattedMessage
        id="app.mypages.mainNav.savings.link.settings"
        defaultMessage="Inställningar"
      />
    ),
  },
  {
    to: `${Urls.Savings}/${id}/${Urls.SavingsAccountDetails}`,
    end: true,
    text: (
      <FormattedMessage
        id="app.mypages.mainNav.savings.link.details"
        defaultMessage="Detaljer"
      />
    ),
  },
]

export const createloanLinksWithID = (id: string) => [
  {
    to: `${Urls.Loans}/${id}/${Urls.LoansAccountOverview}`,
    end: true,
    text: (
      <FormattedMessage
        id="app.mypages.mainNav.loan.link.overview"
        defaultMessage="Översikt"
      />
    ),
  },
  {
    to: `${Urls.Loans}/${id}/${Urls.LoansAccountDetails}`,
    end: true,
    text: (
      <FormattedMessage
        id="app.mypages.mainNav.savings.link.details"
        defaultMessage="Detaljer"
      />
    ),
  },
  {
    to: `${Urls.Loans}/${id}/${Urls.LoansInvoices}`,
    end: true,
    text: (
      <FormattedMessage
        id="app.mypages.mainNav.loan.link.invoices"
        defaultMessage="Fakturor"
      />
    ),
  },
]

export const loanLinks = [
  {
    to: Urls.Loans,
    end: false,
    text: (
      <FormattedMessage
        id="app.mypages.mainNav.loan.link.overview"
        defaultMessage="Översikt"
      />
    ),
  },
  {
    to: `${Urls.Loans}/${Urls.LoansInvoices}`,
    end: true,
    text: (
      <FormattedMessage
        id="app.mypages.mainNav.loan.link.invoices"
        defaultMessage="Fakturor"
      />
    ),
  },
]

interface IStudentRecord {
  [key: string]: ReactElement | JSX.Element
}

export const serviceHeadings: IStudentRecord = {
  [Urls.Loans]: (
    <FormattedMessage
      id="app.mypages.mainNav.loan.link.loan"
      defaultMessage="Lån"
    />
  ),
  [`${Urls.Loans}/${Urls.LoansInvoices}`]: (
    <FormattedMessage
      id="app.mypages.mainNav.loan.link.invoices"
      defaultMessage="Fakturor"
    />
  ),
}

export const createSavingsAccountProps = (
  data: IDepositService | undefined
) => ({
  type: "savings" as const,
  name: data?.name,
  accountNumber: data?.product_number || 0,
  accountTitleId: "app.common.account.savingsAccount" as MessageId,
  scaled: true,
  showDepositUpdate: data?.status !== "closed",
})

export const createLoanAccountProps = (data?: IBasicLoanService) => ({
  type: "loan" as const,
  accountNumber: data?.loan_number || 0,
  accountTitleId: "app.mypages.loans.header" as MessageId,
  progress: data?.loan_repayment_rate || 1,
  scaled: true,
})
