import { forwardRef, Ref, useEffect, useRef, RefObject } from "react"
import useCloseOnEsc from "Hooks/UI/useCloseOnEsc"
import { FormattedMessage } from "react-intl"
import useHandleClickOutside from "Hooks/UI/useHandleClickOutside"
import Languages from "../Languages"
import { MenuWrapper, Menu, MenuList, MenuLink } from "./SideMenu.styles"

type SidemenuPropTypes = {
  closeMenu: (e?: MouseEvent | TouchEvent) => void
  isOpen: boolean
  showLanguages?: boolean
}

const Sidemenu = forwardRef(
  (
    { closeMenu, isOpen, showLanguages }: SidemenuPropTypes,
    ref: Ref<HTMLDivElement>
  ) => {
    useCloseOnEsc(closeMenu)
    useHandleClickOutside(ref as RefObject<HTMLDivElement>, closeMenu)
    const listRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (isOpen) {
        const anchor = listRef.current?.firstChild as HTMLAnchorElement
        anchor.focus()
      }
    }, [isOpen, listRef])

    return (
      <MenuWrapper aria-hidden="true" isOpen={isOpen}>
        <Menu isOpen={isOpen} ref={ref}>
          <MenuList ref={listRef}>
            <MenuLink href="mailto:help@brocc.se">help@brocc.se</MenuLink>
            <MenuLink href="callto:+460850121580">
              <FormattedMessage
                id="app.login.sideMenu.call"
                defaultMessage="Ring"
                values={{ number: "08 501 215 80" }}
              />
            </MenuLink>
          </MenuList>
          <Languages isSidebar showLanguages={showLanguages} />
        </Menu>
      </MenuWrapper>
    )
  }
)

Sidemenu.displayName = "Sidemenu"

export default Sidemenu
