import { useMutation, useQuery, useQueryClient } from "react-query"

import {
  getPaymentFreeMonthStatus,
  postPaymentFreeMonth,
} from "ApiServices/paymentFreeMonth"
import { PAYMENT_FREE_MONTHS } from "Constants/queryKeys"

export function usePaymentFreeMonth() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: postPaymentFreeMonth,
    onSuccess: () => {
      queryClient.invalidateQueries([PAYMENT_FREE_MONTHS])
    },
  })
}

export const useGetPaymentFreeMonthStatus = () =>
  useQuery({
    queryKey: [PAYMENT_FREE_MONTHS],
    queryFn: getPaymentFreeMonthStatus,
  })
