import styled from "styled-components"

export const ModalBody = styled.div`
  padding: 0 0 2em;
  ul {
    margin-left: 1em;
    li {
      line-height: 28px;
    }
  }
`
