import styled, { css } from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"
import { flex } from "config/mixins"
import {
  TableDataItemPropTypes,
  MobileRowWrapPropTypes,
  ExpandButtonPropTypes,
} from "./ReportsTableRow.types"

export const TableItemsWrap = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr 1fr 1fr 100px;
  @media screen and (max-width: ${mediaQueries.large}) {
    grid-template-columns: 60px 1fr 60px;
    grid-template-rows: 1fr 100%;
  }
  @media screen and (max-width: 360px) {
    grid-template-columns: 60px minmax(170px, 200px) 60px;
  }
`

export const TableDataItem = styled.div<TableDataItemPropTypes>`
  ${flex.center}
  border-top: 1px solid #dfdfdf;
  padding: 1em 0;
  text-align: center;
  &:first-of-type {
    justify-content: flex-start;
    padding: 1em 2em;
    @media screen and (max-width: ${mediaQueries.large}) {
      padding: 1em 0.5em;
    }
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    padding: 1em;
  }
  &:nth-of-type(5) {
    padding: 1em 2em;
    justify-content: flex-end;
  }

  ${(p) => css`
    ${p.chevron &&
    css`
      display: none;
      align-items: end;
    `}

    @media screen and (max-width: ${mediaQueries.large}) {
      display: ${p.hide && "none"};
      ${p.chevron &&
      css`
        display: flex;
        align-items: end;
        justify-content: flex-end;
        padding: 1em 0.5em;
        svg path {
          stroke: var(--black);
        }
      `}
    }
  `}
`

export const DeleteLink = styled.a`
  text-decoration: none;
  svg path {
    fill: ${(p) => p.theme.foreground};
  }
  &:focus {
    border: 1px solid var(--yellow);
    border-radius: 3px;
  }
`
export const DownloadLink = styled.a`
  text-decoration: none;
  cursor: pointer;

  svg path {
    stroke: ${(p) => p.theme.foreground};
  }

  &:focus {
    border: 1px solid var(--yellow);
    border-radius: 3px;
  }
`

export const MobileRow = styled.div`
  ${flex.alignCenterBetween}
  padding: 0 0.9em 0 0.5em;
  margin: 0.25em 0 0.5em 0;

  @media screen and (min-width: ${mediaQueries.large}) {
    display: none;
  }
`

export const MobileRowWrap = styled.div<MobileRowWrapPropTypes>`
  ${(p) => css`
    display: ${p.show ? "block" : "none"};
    ${p.mobile &&
    css`
      grid-row-start: 2;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 5;
    `}
  `}

  @media screen and (min-width: ${mediaQueries.large}) {
    display: none;
  }
`

export const MobileRowItem = styled.div`
  margin: 0.5em 0;
`

export const BodyModal = styled.div`
  ${flex.columnAlignCenter}
  width: auto;
  gap: 2em;
  margin: 15px 0;
`

export const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2em;
`

export const ExpandButton = styled.button<ExpandButtonPropTypes>`
  transition: 300ms all ease-in-out;
  transform: ${(p) => `rotate(${p.show ? 90 : 0}deg)`};
  svg path {
    stroke: ${(p) => p.theme.foreground};
  }

  &:focus {
    box-shadow: none;
  }
`
