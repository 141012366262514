import { createIntl, IntlShape } from "react-intl"
import { SV } from "Constants/languages"
import { IBasicLoanService } from "Interfaces/loanInterfaces"
import { IDepositService } from "Interfaces/depositInterfaces"
import {
  ILoanApplication,
  LoanApplicationStatusType,
} from "Interfaces/loanApplicationInterfaces"
import { Urls } from "Constants/urls"
import {
  BROCC_ONBOARDING,
  BROCC_TEST_ONBOARDING,
} from "Constants/externalLinks"
import { DepositServiceStatus } from "types/enums"
import { EnvKeysEnum, getEnv } from "./environment"
import { formatNumber } from "./formatNumber"

const SVLocaleIntl = createIntl({
  locale: SV,
})

export interface IData {
  name?: string
  progress?: number
  accountNumber: number | string
  balance: number
  link: string
  status?: string
  loanStatus?: LoanApplicationStatusType
  id: string
  deactivationDatetime?: string | null
}

export const getLoansInfo = (loans: IBasicLoanService[]): IData[] =>
  loans.map((loan) => ({
    accountNumber: loan.loan_number,
    balance: loan.remaining_principal,
    progress: loan.loan_repayment_rate,
    link: `${Urls.Loans}/${loan.uid}/${Urls.LoansAccountOverview}`,
    id: loan.uid,
  }))

export const getLoanApplicationsInfo = (
  loanApplications: ILoanApplication[],
  intl: IntlShape
): IData[] => {
  const tinkEnvironment = getEnv(EnvKeysEnum.TinkEnvironment)
  return loanApplications.map((item) => ({
    id: item.uid,
    balance: item.amount,
    link:
      tinkEnvironment === "test"
        ? BROCC_TEST_ONBOARDING.replace(":uid", item.uid)
        : BROCC_ONBOARDING.replace(":uid", item.uid),
    accountNumber: intl.formatMessage(
      { id: "app.mypages.main.loanApplication.validUntil" },
      { date: SVLocaleIntl.formatDate(item.expiry_date) }
    ),
    loanStatus: item.status,
  }))
}

export const getSavingsInfo = (
  savings: IDepositService[],
  intl: IntlShape
): IData[] =>
  savings
    .sort((a, b) => b.status.localeCompare(a.status))
    .map((deposit) => {
      const interestRate = formatNumber(deposit.interest_rate, {
        style: "percent",
      })
      const endDate =
        deposit.type === "fixed" && deposit.term_end_date
          ? intl.formatMessage(
              {
                id: "app.mypages.main.savings.until",
                defaultMessage: "Till {date}",
              },
              {
                date: SVLocaleIntl.formatDate(deposit.term_end_date),
              }
            )
          : undefined
      const accountArray: (string | number)[] = [deposit.product_number]

      if (deposit.status !== DepositServiceStatus.created) {
        if (deposit.status !== DepositServiceStatus.closed) {
          accountArray.push(interestRate)
        }
        if (endDate) {
          accountArray.push(endDate)
        }
      }

      return {
        id: deposit.uid,
        accountNumber: accountArray.join(" | "),
        name: deposit.name,
        status: deposit.status,
        balance: deposit.current_balance,
        deactivationDatetime: deposit.deactivation_datetime,
        link:
          deposit.status === DepositServiceStatus.created
            ? `${Urls.Savings}/${Urls.SavingsOnboarding.replace(
                ":accountID",
                deposit.uid
              )}`
            : `${Urls.Savings}/${deposit.uid}/${Urls.SavingsAccountOverview}`,
      }
    })
