import styled from "styled-components"

export const ButtonContainer = styled.div`
  display: inherit;
  flex-direction: column;
  justify-content: center;
  gap: 1.5em;
`

export const InformationContainer = styled.div`
  text-align: center;
`
