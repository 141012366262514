import { useState } from "react"
import { useParams } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { AnimatePresence } from "framer-motion"

import { useTransitionToSigned } from "Hooks/API/useLoansApplications"
import { hasValidSignatures } from "Utils/signingUtils"
import Button from "UI/Button"
import IconFactory from "UI/IconFactory"
import type { ILoanApplication } from "Interfaces/loanApplicationInterfaces"
import Modal from "UI/Modal"
import { SignLoanApplicationModal } from "../SignLoanApplicationModal/SignLoanApplicationModal"
import * as SigningStyles from "../Signing.styles"

const SigningButtons = ({
  loanApplication,
}: {
  loanApplication: ILoanApplication
}) => {
  const { id } = useParams() as { id: string }

  const [showSignLoanApplicationModal, setShowSignLoanApplicationModal] =
    useState(false)
  const [isCoApplicant, setIsCoApplicant] = useState(false)
  const [showTransitionToSignedModal, setShowTransitionToSignedModal] =
    useState(false)

  const transitionToSignedMutation = useTransitionToSigned(id)

  const handleTransitionToSigned = () => {
    return transitionToSignedMutation.mutateAsync(undefined, {
      onError: () => {
        setShowTransitionToSignedModal(true)
      },
    })
  }

  const handleCloseModal = () => {
    setShowSignLoanApplicationModal(false)
  }

  const showSignAgreementModal = () => {
    setShowSignLoanApplicationModal(true)
    setIsCoApplicant(false)
  }

  const showSignAgreementModalWithCoApplicant = () => {
    setShowSignLoanApplicationModal(true)
    setIsCoApplicant(true)
  }

  const showTransitionToSignedButton = !(
    loanApplication.is_signed || !hasValidSignatures(loanApplication)
  )

  return (
    <>
      <AnimatePresence>
        {showSignLoanApplicationModal && (
          <SignLoanApplicationModal
            accountID={id}
            onClose={handleCloseModal}
            isCoApplicant={isCoApplicant}
            handleTransitionToSigned={handleTransitionToSigned}
            isTransitionToSignedError={transitionToSignedMutation.isError}
          />
        )}
        {showTransitionToSignedModal && (
          <Modal
            onClick={() => setShowTransitionToSignedModal(false)}
            title=" "
            body={
              <FormattedMessage
                id="app.common.error.header"
                defaultMessage="Oj då! Något gick fel :("
              />
            }
          />
        )}
      </AnimatePresence>
      <SigningStyles.ButtonWrap>
        {loanApplication.applicant_signed ? (
          <SigningStyles.ApplicantSigned>
            <FormattedMessage id="app.onboarding.signing.applicantHasSigned" />
            <IconFactory name="loanProtectionTick" />
          </SigningStyles.ApplicantSigned>
        ) : (
          <Button onClick={showSignAgreementModal}>
            <FormattedMessage
              id="app.onboarding.signing.nextCTAText"
              defaultMessage="Skriv under med BankID"
            />
          </Button>
        )}
        {loanApplication.co_applicant &&
          (!loanApplication.co_applicant_signed ? (
            <Button
              variant="inverse"
              onClick={showSignAgreementModalWithCoApplicant}
            >
              <FormattedMessage
                id="app.onboarding.signing.nextCTAText.co-applicant"
                defaultMessage="Skriv under med BankID (Medsökande)"
              />
            </Button>
          ) : (
            <SigningStyles.ApplicantSigned>
              <FormattedMessage id="app.onboarding.signing.coApplicantHasSigned" />
              <IconFactory name="loanProtectionTick" />
            </SigningStyles.ApplicantSigned>
          ))}
        {showTransitionToSignedButton && (
          <Button
            isLoading={transitionToSignedMutation.isLoading}
            disabled={transitionToSignedMutation.isLoading}
            onClick={handleTransitionToSigned}
          >
            <FormattedMessage id="app.onboarding.signing.nextCTAText.sign" />
          </Button>
        )}
      </SigningStyles.ButtonWrap>
    </>
  )
}

export default SigningButtons
