import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"
import { flex } from "config/mixins"
import { TransactionsOverviewPropTypes } from "./TransactionsTable.types"

export const LoadingWrap = styled.div`
  width: 100%;
  margin-top: 1em;
`

export const TransactionsOverview = styled.div<TransactionsOverviewPropTypes>`
  border-radius: 20px;
  margin-top: 1em;
  padding-bottom: 1em;
  position: relative;
  padding: ${(p) => p.isLoading && "2em"};
  background: ${(p) => p.theme.infoBox};
  @media screen and (max-width: ${mediaQueries.medium}) {
    padding-bottom: 0;
  }
`

export const TransactionsOverviewHeader = styled.div`
  padding: 0.25em 0;
  border-bottom: 1px solid var(--light-gray);
  margin-bottom: 1em;
`

export const TransactionsTableRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  font-size: ${font.size.base};
  div {
    padding: 1em 0;
    @media screen and (max-width: ${mediaQueries.small}) {
      font-size: 0.9em;
    }
  }
`

export const EmptyTransactionWrap = styled.div`
  ${flex.alignCenter}
  padding: 2em;
  @media screen and (max-width: ${mediaQueries.medium}) {
    padding: 0.5em 1em;
  }
`
export const TransactionsTableAmount = styled.div`
  ${flex.alignCenter}
  font-size: ${font.size.base};
  font-weight: 500;
`
export const TransactionsTableTypeDate = styled.div`
  ${flex.alignCenter}
  > svg {
    width: 40px;
    height: 40px;
    margin-right: 1em;
    border-radius: 50%;
    background-color: var(--light-gray);
    padding: 0.6em;
  }
  > div {
    padding: 0;
    font-weight: 500;
    font-size: ${font.size.base};
    > span {
      font-size: ${font.size.sm};
      color: var(--dark-gray);
      line-height: 14px;
    }
  }
`
