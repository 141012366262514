import styled from "styled-components"
import { motion } from "framer-motion"

export const ActiveLine = styled(motion.div)`
  display: block;
  width: 100%;
  height: 3px;
  background-color: var(--dark-yellow);
  position: absolute;
  bottom: -10px;
  left: 0px;
`

export const MenuLink = styled(motion.span)`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.fontColor};
  cursor: pointer;
  white-space: nowrap;
  svg {
    margin-right: 0.5em;
    width: 20px;
  }
`
