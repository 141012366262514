import { flex } from "config/mixins"
import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const PaymentFreeMonthForm = styled.form`
  ${flex.columnAlignCenter}
  padding: 1.5em 0.5em;

  .icon-pauseNoBorder {
    width: 40px;
    height: 40px;
  }

  p {
    font-weight: 500;
    margin: 0;
  }

  > button {
    margin-top: 1em;
  }
`

export const TextBlock = styled.div`
  ${flex.columnAlignCenter}
  row-gap: 8px;
  margin-bottom: 1.5em;

  h2 {
    font-size: ${font.size.lg};
    margin: 14px 0 0;
  }
`

export const LoadingContainer = styled.div`
  ${flex.justifyCenter}
  min-height: 120px;
`

export const NotEligible = styled.p`
  width: 100%;
  margin: 0;
  padding: 1em;
  border-radius: 15px;
  background-color: var(--very-light-yellow);
  text-align: center;
`

export const ChangeSuccessOrErrorBlock = styled.div`
  ${flex.columnAlignCenter}
  gap: 1em;

  p {
    margin: 0;
  }
`
