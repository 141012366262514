import { FC } from "react"
import { MessageDescriptor, useIntl } from "react-intl"

import IconFactory from "UI/IconFactory"
import { formatNumber } from "Utils/formatNumber"
import * as S from "./StatusOrBalance.styles"
import { PromoItemType } from "../../PromoWrap.types"

interface StatusOrBalanceProps {
  status?: string
  balance: number
  type: PromoItemType
}

const statusLabels: Record<string, MessageDescriptor> = {
  created: {
    id: "app.common.activate",
    defaultMessage: "Aktivera",
  },
  closed: {
    id: "app.common.closed",
    defaultMessage: "Avslutat",
  },
}

export const StatusOrBalance: FC<StatusOrBalanceProps> = ({
  status,
  balance,
  type,
}) => {
  const intl = useIntl()

  if (status && statusLabels[status]) {
    return (
      <S.ActivateText>
        {intl.formatMessage(statusLabels[status])}
      </S.ActivateText>
    )
  }

  return (
    <S.ActiveBalance>
      {formatNumber(balance, {
        style: "currency",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
      {type === "loanApplications" && (
        <IconFactory name="chevron" viewBox="0 0 24 33" />
      )}
    </S.ActiveBalance>
  )
}
