import styled from "styled-components"
import { motion } from "framer-motion"
import { flex } from "config/mixins"
import { DropdownInnerPropTypes } from "./DropdownContent.types"

export const DropdownInner = styled(motion.div)<DropdownInnerPropTypes>`
  ${flex.column}
  padding: 0.5em;
  a {
    margin-bottom: 1.25em;
    span {
      opacity: 0.4;
    }
    &.active span {
      opacity: 1;
    }
    &:first-of-type {
      margin-top: ${(p) => p.$firstLevel && "1.25em"};
    }
  }
`
export const DropdownWrap = styled(motion.div)``
