import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"
import { useQueryClient } from "react-query"

import RepaymentPeriods from "Components/RepaymentPeriods"
import type { ILoanApplication } from "Interfaces/loanApplicationInterfaces"
import * as OnboardingStyles from "Pages/Unauthenticated/Onboarding/Onboarding.styles"
import { LOAN_APPLICATIONS_APPLICATION } from "Constants/queryKeys"
import { formatNumber } from "Utils/formatNumber"
import * as SigningStyles from "../Signing.styles"

const GrayBox = () => {
  const queryClient = useQueryClient()
  const { id } = useParams() as { id: string }

  const data = queryClient.getQueryData<ILoanApplication>([
    LOAN_APPLICATIONS_APPLICATION,
    id,
  ])

  if (!data) return null

  return (
    <OnboardingStyles.GrayBox>
      <SigningStyles.SpaceBetween>
        <div>
          <FormattedMessage id="app.common.amount" defaultMessage="Belopp" />
        </div>
        {formatNumber(data.amount, {
          style: "currency",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      </SigningStyles.SpaceBetween>
      <SigningStyles.SpaceBetween>
        <div>
          <FormattedMessage
            id="app.common.monthlyCost"
            defaultMessage="Månadskostnad"
          />
        </div>
        {formatNumber(data.period_cost, {
          style: "currency",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      </SigningStyles.SpaceBetween>
      <SigningStyles.SpaceBetween>
        <div>
          <FormattedMessage
            id="app.common.loanPeriod"
            defaultMessage="Återbetalningstid"
          />
        </div>
        <RepaymentPeriods repaymentPeriods={data.repayment_periods} />
      </SigningStyles.SpaceBetween>
      <SigningStyles.SpaceBetween>
        <div>
          <FormattedMessage
            id="app.common.effectiveInterest"
            defaultMessage="Effektiv ränta"
          />
        </div>
        {formatNumber(+data.effective_interest_rate, { style: "percent" })}
      </SigningStyles.SpaceBetween>
    </OnboardingStyles.GrayBox>
  )
}

export default GrayBox
