import { AnimatePresence } from "framer-motion"
import { Dispatch, FC, SetStateAction } from "react"
import { FormattedMessage } from "react-intl"

import { useLangContext } from "Context/lang-context"
import Modal from "UI/Modal"

import * as S from "./termsModal.styles"

type TermsModalPropTypes = {
  setTermsModal: Dispatch<SetStateAction<boolean>>
}

const TermsModal: FC<TermsModalPropTypes> = ({ setTermsModal }) => {
  const { locale } = useLangContext()

  return (
    <AnimatePresence>
      <Modal
        body={
          <>
            <S.Paragraph>
              <FormattedMessage
                id="app.mypages.cashback.termsmodal.paragraphOne"
                defaultMessage="Brocc har lanserat ett Lojalitetsprogram för alla Låntagare, som kallas ”Pengar tillbaka” programmet. ”Pengar tillbaka” programmet innebär att Låntagare, from 14 oktober 2019 erhåller en del av den inbetalda räntan. ”Pengar tillbaka” programmet uppgår till 5% av inbetald ränta som Låntagaren kan få tillbaka. Räntan beräknas från och med dagen då räntan är inbetald. ”Pengar tillbaka” ackumuleras månatligen när Låntagarens månatliga inbetalning (dvs. ränta och amortering) mottagits av Brocc. För att erhålla ”Pengar tillbaka” ska inbetalningen ha varit Brocc tillhanda senast dagen efter förfallodagen."
              />
            </S.Paragraph>
            <S.UnorderedList>
              <S.ListItem>
                <FormattedMessage
                  id="app.mypages.cashback.termsmodal.unorderedList.list1"
                  defaultMessage="Programmet gäller endast lån där Brocc är kreditgivare."
                />
              </S.ListItem>
              <S.ListItem>
                <FormattedMessage
                  id="app.mypages.cashback.termsmodal.unorderedList.list2"
                  defaultMessage="Intjänat belopp beräknas f.n. som 5% av inbetald avtalsränta (inte på amortering, avgifter eller dröjsmålsränta)."
                />
              </S.ListItem>
              <S.ListItem>
                <FormattedMessage
                  id="app.mypages.cashback.termsmodal.unorderedList.list3"
                  defaultMessage="Intjänat belopp ackumuleras månatligen."
                />
              </S.ListItem>
              <S.ListItem>
                <FormattedMessage
                  id="app.mypages.cashback.termsmodal.unorderedList.list4"
                  defaultMessage="För att kvalificera till utbetalning enligt Pengar tillbaka ska alla betalningar till Brocc vara i tid (d.v.s. senast oss tillhanda på förfallodagen enligt fakturan). Om du är sen med betalning har Brocc rätt att nollställa programmet, vilket innebär att ackumuleringen startar om från 0 kronor."
                />
              </S.ListItem>
              <S.ListItem>
                <FormattedMessage
                  id="app.mypages.cashback.termsmodal.unorderedList.list5"
                  defaultMessage="Intjänat belopp kan disponeras av låntagaren först när en total summa om 3 000 kronor har ackumulerats."
                />
              </S.ListItem>
              <S.ListItem>
                <FormattedMessage
                  id="app.mypages.cashback.termsmodal.unorderedList.list6"
                  defaultMessage="Intjänat belopp kan endast disponeras av låntagaren på två sätt; antingen genom insättning på ett inlåningskonto hos Brocc med bindningstid på minst 24 månader, eller genom extraamortering på ditt lån hos Brocc. Du väljer själv vilket alternativ som du vill använda pengarna till genom att ansöka i Mina sidor."
                />
              </S.ListItem>
            </S.UnorderedList>
            <S.Paragraph>
              <FormattedMessage
                id="app.mypages.cashback.termsmodal.paragraphTwo"
                defaultMessage="”Pengar tillbaka” är ett lojalitetsprogram med två syften: vi vill att du ska stanna kvar som kund hos Brocc, och vi vill främja ditt eget sparande. Villkoren är satta mot bakgrund av dessa syften och ränteläget per september 2023, och Brocc har rätt att ändra den procentuella nivån som utgör grund för återbetalningen och/eller det lägsta belopp som behöver uppnås för utbetalning, om detta behövs för att lojalitetssyftet ska uppfyllas. Brocc förbehåller sig även i övrigt rätten att ändra villkoren för ”Pengar tillbaka” när som helst. Disponerat belopp innebär en ränteintäkt för låntagaren där Brocc skickar sedvanlig kontrollinformation till Skatteverket."
              />
            </S.Paragraph>
            <S.Paragraph>
              <FormattedMessage
                id="app.mypages.cashback.termsmodal.paragraphThree"
                defaultMessage="Disponerat belopp innebär en ränteintäkt för låntagaren där Brocc skickar sedvanlig kontrollinformation till Skatteverket."
              />
            </S.Paragraph>
            {locale === "en" && (
              <p className="small-print">
                <FormattedMessage
                  id="app.mypages.cashback.termsmodal.paragraphFour"
                  defaultMessage="This English translation is for convenience only, in case of discrepancies the Swedish version shall prevail."
                />
              </p>
            )}
          </>
        }
        onClick={() => setTermsModal(false)}
        title={
          <FormattedMessage
            id="app.mypages.cashback.termsmodal.header"
            defaultMessage="Villkor Pengar tillbaka"
          />
        }
      />
    </AnimatePresence>
  )
}

export default TermsModal
