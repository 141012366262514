import { flex } from "config/mixins"
import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const DocumentWrap = styled.div`
  ${flex.column}
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  .react-pdf__Document {
    display: flex;
    margin: 10px 0 0 0;
  }
`

export const CurrentPage = styled.div`
  flex: 1;
  max-height: calc(65vh - 20px);
  background-color: var(--very-light-gray);
  overflow-y: scroll;
  ::-webkit-scrollbar-track {
    background-color: var(--light-gray);
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--dark-gray);
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .react-pdf__Page {
    flex: 1;
    ${flex.justifyCenter}
    margin-bottom: 15px;
  }
  // @media screen and (max-width: ${mediaQueries.large}) {
  //   display: none;
  // }
`

export const PagesNavigation = styled.div`
  ${flex.column}
  max-height: calc(65vh - 20px);
  overflow-y: scroll;
  background-color: var(--light-gray);
  padding: 20px;
  ::-webkit-scrollbar-track {
    background-color: var(--light-gray);
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--dark-gray);
  }
  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  .react-pdf__Page {
    width: 100%;
    margin-bottom: 15px;
    cursor: pointer;
  }

  @media screen and (max-width: ${mediaQueries.large}) {
    padding: 0;
    max-height: calc(70vh - 20px);
    width: 100%;
    background-color: white;
    canvas,
    div {
      width: 100% !important;
      height: auto !important;
    }
  }
`

export const DocumentNavigation = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  button {
    width: auto;
    display: block;
    padding: 10px 15px;
    font-size: 1em;
    margin-left: 10px;
    margin-top: 10px;
  }
  svg {
    cursor: pointer;
  }
`

export const PagesCount = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 4em;
  @media screen and (max-width: ${mediaQueries.large}) {
    margin-left: 0;
  }
`

export const AgreementNavigation = styled.div`
  ${flex.alignCenter}
  margin-left: auto;
`

export const LoadingWrapper = styled.div`
  ${flex.center}
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(85vh + 20px);
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.7);
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    filter: blur(5px);
    z-index: 0;
  }
  p {
    font-size: 1.2em;
  }
  svg,
  p {
    z-index: 5;
  }
`
