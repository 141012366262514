import { useState, FC, useEffect } from "react"
import { NavLink, useLocation } from "react-router-dom"

import { TabLinkPropTypes } from "./TabLink.types"
import { MenuLink, ActiveLine } from "./TabLink.styles"

const spring = {
  type: "spring",
  stiffness: 500,
  damping: 30,
}

const TabLink: FC<TabLinkPropTypes> = ({ text, to, end }) => {
  const [active, setActive] = useState(false)

  const { pathname } = useLocation()

  useEffect(() => {
    setActive(false)
    if (pathname === to) {
      setActive(true)
    }
  }, [setActive, pathname, to])

  return (
    <NavLink className={active ? "custom-active" : ""} to={to} end={end}>
      <MenuLink>{text}</MenuLink>
      {active && (
        <ActiveLine layoutId="outline" initial={false} transition={spring} />
      )}
    </NavLink>
  )
}

export default TabLink
