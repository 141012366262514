import styled from "styled-components"

export const DepositForm = styled.form`
  input {
    margin-bottom: 0;
  }
  .error-message {
    margin-top: 0.5em;
  }
`
export const DepositTo = styled.div`
  margin: 1em 0;
`
export const DepositAccountItem = styled.div`
  width: 100%;
  border-radius: 6px;
  background-color: var(--very-light-gray);
  padding: 0.8em;
  margin-bottom: 1.5em;
`
