import { FC, useState, useCallback, useMemo } from "react"
import { useParams } from "react-router-dom"
import { FormattedMessage, FormattedNumber } from "react-intl"
import {
  useGetDepositServiceByID,
  useGetDepositServiceProfiles,
  useUpdateDepositServicePreferences,
} from "Hooks/API/useDepositServices"
import { useFeedbackModal } from "Context/modal-context"
import Button from "UI/Button"
import Loading from "UI/Loading"
import { SAVINGS_ONBOARDING } from "Constants/storage"
import {
  getParsedStorageItemOrDefault,
  setStorageItem,
} from "Utils/localStorage"
import { FIXED_PROFILE_TYPE } from "Constants/dataStrings"
import AccountInitAmount from "./AccountInitAmount"
import * as S from "./AccountType.styles"
import { SavingsOnboardingData } from "../SavingsOnboarding.types"

type AccountTypePropTypes = {
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const AccountType: FC<AccountTypePropTypes> = ({ setStep }) => {
  const [profileId, setProfileId] = useState<null | number>(null)
  const [profileType, setProfileType] = useState<string>()

  const { accountID } = useParams() as { accountID: string }
  const { errorModal } = useFeedbackModal()
  const { isLoading, isError, data } = useGetDepositServiceProfiles(accountID)
  const updateProfilePreferances = useUpdateDepositServicePreferences(accountID)

  const { data: depositData, isLoading: isDepositLoading } =
    useGetDepositServiceByID(accountID)

  const [savingsOnboardingData] = useState(() =>
    getParsedStorageItemOrDefault<SavingsOnboardingData>(SAVINGS_ONBOARDING, {})
  )

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setProfileId(+target.value)
    setProfileType(target.dataset.type)
  }

  const showInitialAmount = useMemo(
    () =>
      depositData?.type === FIXED_PROFILE_TYPE &&
      !depositData?.initial_deposit_amount,
    [depositData]
  )

  const handleNextStep = useCallback(
    (isInitAmountSet: boolean) => {
      const updatedStatus: SavingsOnboardingData = {
        ...savingsOnboardingData,
        [accountID]: {
          ...savingsOnboardingData[accountID],
          brocc_has_account_type: true,
          brocc_has_type_initial_amount: isInitAmountSet,
        },
      }

      setStorageItem(SAVINGS_ONBOARDING, updatedStatus)

      if (isInitAmountSet) {
        setStep((prev) => prev + 1)
      }
    },
    [accountID, savingsOnboardingData, setStep]
  )

  const handleClick = () => {
    if (profileId)
      updateProfilePreferances
        .mutateAsync({ uid: accountID, payload: { profile_id: profileId } })
        .then(() => {
          handleNextStep(profileType !== FIXED_PROFILE_TYPE)
        })
        .catch(() => {
          errorModal()
        })
  }

  if (
    showInitialAmount ||
    (profileType === FIXED_PROFILE_TYPE && updateProfilePreferances.isSuccess)
  ) {
    return <AccountInitAmount handleNextStep={handleNextStep} />
  }

  if (isLoading || isDepositLoading) {
    return (
      <S.LoadingErrorWrapper>
        <Loading isCentered />
      </S.LoadingErrorWrapper>
    )
  }

  if (isError) {
    return (
      <S.LoadingErrorWrapper>
        <FormattedMessage
          id="app.mypages.error.depositServices.useGetDepositServiceByID"
          defaultMessage="Kan inte få tillgång till den begärda insättningstjänsten. Försök igen"
        />
      </S.LoadingErrorWrapper>
    )
  }

  return (
    <>
      <S.MainHeader>
        <FormattedMessage
          id="app.mypages.savings.onBoarding.start.accountType.header"
          defaultMessage="Välj mellan ett konto med fast eller rörlig ränta."
        />
      </S.MainHeader>
      <S.AccountTypeWrap>
        <S.RadioButtonsWrap>
          {data?.data.map((profile) => (
            <S.RadioButtonContainer key={profile.id}>
              <S.RadioButton
                type="radio"
                name="profile"
                value={profile.id}
                data-type={profile.type}
                id={`${profile.id}`}
                checked={profile.id === profileId}
                onChange={handleChange}
              />
              <label htmlFor={`${profile.id}`}>
                <S.AccountTypeBox>
                  <S.AccountTypeHeader>{profile.name}</S.AccountTypeHeader>
                  <S.AccountTypeDescription>
                    <FormattedMessage
                      id={`app.mypages.savings.onBoarding.start.accountType.${profile.type}.description`}
                      values={{ value: profile.term_period }}
                    />
                  </S.AccountTypeDescription>
                  <S.AccountTypePercent>
                    <FormattedNumber
                      value={profile.interest_rate}
                      style="percent"
                      minimumFractionDigits={2}
                    />
                  </S.AccountTypePercent>
                </S.AccountTypeBox>
              </label>
            </S.RadioButtonContainer>
          ))}
        </S.RadioButtonsWrap>
      </S.AccountTypeWrap>
      <Button
        disabled={!profileId || updateProfilePreferances.isLoading}
        isLoading={updateProfilePreferances.isLoading}
        onClick={handleClick}
      >
        <FormattedMessage
          id="app.onboarding.loanPurpose.nextCTAText"
          defaultMessage="Gå vidare"
        />
      </Button>
    </>
  )
}

export default AccountType
