import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const Wrap = styled.div`
  width: 60%;
  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    width: 100%;
  }
`

export const Warning = styled.p`
  background-color: var(--very-light-yellow);
  margin: 0 0 20px;
  padding: 20px;
  border-radius: 20px;
`

export const Message = styled.p`
  font-weight: 500;
`

export const Form = styled.form`
  button {
    margin-top: 10px;
    width: 160px;
  }
`
