import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const AccountTypeWrap = styled.div`
  display: flex;
  flex-direction: column;
  button {
    width: calc(100% - 1em);
    margin-top: 1em;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    button {
      width: 100%;
      margin: 0;
    }
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    & + button {
      margin: 0 auto;
    }
  }
`
export const RadioButtonsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  padding: 30px 0;

  @media screen and (max-width: ${mediaQueries.small}) {
    flex-direction: column;
  }
`

export const RadioButtonContainer = styled.div`
  flex: 1;
  min-width: 240px;
  label {
    display: block;
    height: 100%;
  }

  @media screen and (min-width: ${mediaQueries.large}) {
    max-width: 320px;
  }
`

export const MainHeader = styled.h4`
  font-size: ${font.size.lg};
  font-weight: 500;
  margin: 0 0 0.5em;

  @media screen and (max-width: ${mediaQueries.small}) {
    text-align: center;
  }
`
export const AccountTypeBox = styled.div`
  display: grid;
  grid-auto-rows: auto;
  align-content: center;
  row-gap: 0.5em;
  border-radius: 16px;
  background: var(--white);
  cursor: pointer;
  transition: all 200ms ease-in-out;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 2em;
  box-shadow: inset 0px 0px 0px 1px var(--light-gray);
`
export const AccountTypeHeader = styled.h3`
  margin: 0;
`

export const AccountTypeDescription = styled.p`
  margin: 0;
  color: var(--dark-gray);
`

export const AccountTypePercent = styled.p`
  font-size: 1.2em;
  font-weight: 700;
  margin: 0;
`
export const RadioButton = styled.input`
  opacity: 0;
  position: fixed;
  width: 0;
  &:checked + label {
    ${AccountTypeBox} {
      background-color: var(--very-light-yellow);
      box-shadow: inset 0px 0px 0px 3px var(--light-yellow);
    }
  }
`
export const LoadingErrorWrapper = styled.div`
  padding-top: 3em;
`
