import { Urls } from "Constants/urls"
import AccountLayout from "Layouts/AccountLayout"
import Account from "Pages/Authenticated/Account/Account"
import KYCForm from "Pages/Authenticated/Account/KYCForm"
import Language from "Pages/Authenticated/Account/Language/Language"
import PersonalInformation from "Pages/Authenticated/Account/PersonalInformation/PersonalInformation"
import Reports from "Pages/Authenticated/Account/Reports/Reports"
import { Outlet } from "react-router-dom"

const accountRoutes = {
  path: Urls.Account,
  element: <Outlet />,
  children: [
    {
      element: <Account />,
      index: true,
    },
    {
      element: <AccountLayout type="account" />,
      children: [
        {
          path: Urls.AccountInfo,
          element: <PersonalInformation />,
        },
        {
          path: Urls.AccountKyc,
          element: <KYCForm />,
        },
        {
          path: Urls.AccountReports,
          element: <Reports />,
        },
        {
          path: Urls.AccountLanguage,
          element: <Language />,
        },
      ],
    },
  ],
}

export default accountRoutes
