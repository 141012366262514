import { FormattedMessage } from "react-intl"

import IconFactory from "UI/IconFactory"
import BankFallback from "Assets/Icons/bank-fallback.svg"
import Nordea from "Assets/Icons/bank-logos/Nordea.png"
import DanskeBank from "Assets/Icons/bank-logos/DanskeBank.png"
import Handelsbanken from "Assets/Icons/bank-logos/Handelsbanken.png"
import ICABanken from "Assets/Icons/bank-logos/ICABanken.png"
import Lansforsakringar from "Assets/Icons/bank-logos/Länsförsäkringar.png"
import SBAB from "Assets/Icons/bank-logos/SBAB.png"
import SEB from "Assets/Icons/bank-logos/SEB.png"
import Skandia from "Assets/Icons/bank-logos/Skandia.png"
import Sparbankerna from "Assets/Icons/bank-logos/Sparbankerna.png"
import Swedbank from "Assets/Icons/bank-logos/Swedbank.png"
import {
  LoanApplicationStatusEnum,
  LoanApplicationStatusType,
} from "Interfaces/loanApplicationInterfaces"

export const determineStatusMessage = (status: LoanApplicationStatusType) => {
  switch (status) {
    case "approved":
      return (
        <FormattedMessage
          id="app.mypages.home.currentLoanApplications.status.approved"
          defaultMessage="Ansökan beviljad"
        />
      )

    case "rejected":
      return (
        <FormattedMessage
          id="app.mypages.home.currentLoanApplications.status.rejected"
          defaultMessage="Ansökan avslagen"
        />
      )

    case "manual_check":
      return (
        <FormattedMessage
          id="app.mypages.home.currentLoanApplications.status.manualCheck"
          defaultMessage="Ansökan granskas"
        />
      )

    case "customer_accepted":
      return (
        <FormattedMessage
          id="app.mypages.home.currentLoanApplications.status.customerAccepted"
          defaultMessage="Ansökan granskas"
        />
      )

    case "customer_rejected":
      return (
        <FormattedMessage
          id="app.mypages.home.currentLoanApplications.status.customerRejected"
          defaultMessage="Ansökan avslagen"
        />
      )

    case "signed":
      return (
        <FormattedMessage
          id="app.mypages.home.currentLoanApplications.status.signed"
          defaultMessage="Ansökan granskas"
        />
      )

    case "converted":
      return (
        <FormattedMessage
          id="app.mypages.home.currentLoanApplications.status.converted"
          defaultMessage="Ansökan granskas"
        />
      )

    case "offer_timed_out":
      return (
        <FormattedMessage
          id="app.mypages.home.currentLoanApplications.status.offerTimedOut"
          defaultMessage="Ansökan avslagen"
        />
      )

    case "canceled":
      return (
        <FormattedMessage
          id="app.mypages.home.currentLoanApplications.status.canceled"
          defaultMessage="Ansökan avbruten"
        />
      )
    case "uc_failed":
    case "approval_failed":
      return (
        <FormattedMessage
          id="app.mypages.home.currentLoanApplications.status.approvalFailed"
          defaultMessage="Ansökan avslagen"
        />
      )

    default:
      return ""
  }
}

export const determineStatusIcon = (status?: LoanApplicationStatusType) => {
  switch (status) {
    case LoanApplicationStatusEnum.Approved:
      return <IconFactory name="happy" />

    case LoanApplicationStatusEnum.Rejected:
    case LoanApplicationStatusEnum.CustomerRejected:
    case LoanApplicationStatusEnum.OfferTimedOut:
    case LoanApplicationStatusEnum.Canceled:
    case LoanApplicationStatusEnum.ApprovalFailed:
    case LoanApplicationStatusEnum.UcFailed:
      return <IconFactory name="sad" />

    case LoanApplicationStatusEnum.ManualCheck:
    case LoanApplicationStatusEnum.CustomerAccepted:
    case LoanApplicationStatusEnum.Signed:
    case LoanApplicationStatusEnum.Converted:
      return <IconFactory name="exclaimation" />

    default:
      return null
  }
}

export const determineBankIcon = (bankName?: string | null) => {
  switch (bankName) {
    case "Nordea":
    case "Nordea Personkonto":
      return Nordea
    case "Danske Bank":
      return DanskeBank
    case "Handelsbanken":
      return Handelsbanken
    case "ICA Banken":
      return ICABanken
    case "Länsförsäkringar":
      return Lansforsakringar
    case "SBAB":
      return SBAB
    case "SEB":
      return SEB
    case "Skandia":
      return Skandia
    case "Sparbankerna":
      return Sparbankerna
    case "Swedbank":
      return Swedbank
    default:
      return BankFallback
  }
}

export const getBackgroundColorByType = (type?: string): string => {
  switch (type) {
    case "bank":
      return "var(--light-gray)"
    case "savings":
      return "rgba(var(--purple-rgb), 0.5)"
    default:
      return "rgba(var(--green-rgb), 0.5)"
  }
}

export const getBackgroundColorByStatus = (
  status?: LoanApplicationStatusType
): string | null => {
  switch (status) {
    case LoanApplicationStatusEnum.Approved:
      return "var(--green)"
    case LoanApplicationStatusEnum.Rejected:
    case LoanApplicationStatusEnum.CustomerRejected:
    case LoanApplicationStatusEnum.OfferTimedOut:
    case LoanApplicationStatusEnum.Canceled:
    case LoanApplicationStatusEnum.ApprovalFailed:
      return "var(--green)"
    case LoanApplicationStatusEnum.ManualCheck:
    case LoanApplicationStatusEnum.CustomerAccepted:
    case LoanApplicationStatusEnum.Signed:
    case LoanApplicationStatusEnum.Converted:
      return "var(--light-yellow)"
    default:
      return null
  }
}
