import { LoanApplicationForm } from "Interfaces/loanApplicationInterfaces"
import { UseFormReturn } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import SliderInput from "Components/SliderInput/SliderInput"
import { formatNumber } from "Utils/formatNumber"
import { useState } from "react"
import * as S from "../LoanApplication.styles"
import {
  calculatePaymentDetails,
  getFeesBySource,
  getInterestRateBySource,
} from "../helpers"
import { DEFAULT_AMOUNT, DEFAULT_REPAYMENT_PERIODS } from "../constants"

type DynamicFieldsProps = {
  initialFormData?: LoanApplicationForm
  form: UseFormReturn<LoanApplicationForm>
}

const DynamicFields = ({ initialFormData, form }: DynamicFieldsProps) => {
  const defaultAmount = initialFormData?.amount || DEFAULT_AMOUNT
  const defaultRepaymentPeriods =
    initialFormData?.repayment_periods || DEFAULT_REPAYMENT_PERIODS
  const [amount] = useState(Number(form.getValues("amount")) || defaultAmount)

  const [repaymentPeriods, setRepaymentPeriods] = useState(
    form.getValues("repayment_periods") || defaultRepaymentPeriods
  )

  const { setupFee } = getFeesBySource(initialFormData?.source)

  const getPeriodCost = () => {
    const { periodCost } = calculatePaymentDetails(
      amount,
      repaymentPeriods,
      getInterestRateBySource(initialFormData?.source),
      setupFee,
      0 // adminFee is set to 0, to align monthly cost with the one we use in onboarding
    )

    return periodCost
  }

  const onChange = (value: number) => {
    setRepaymentPeriods(value)
    form.setValue("repayment_periods", value)
  }

  return (
    <S.DynamicFieldsContainer>
      <S.InputContainer>
        <S.UnderTitleContainer>
          <S.Description>
            <FormattedMessage
              id="app.mypages.loans.application.under_title"
              defaultMessage="Slutför din låneansökan om {totalAmount} kr*"
              values={{
                totalAmount: formatNumber(amount, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
              }}
            />
          </S.Description>
          <S.Explanation>
            <FormattedMessage
              id="app.mypages.loans.application.under_title.explanation"
              defaultMessage="* Beloppet består av {amount} kr lånebelopp och {setupFee} kr uppläggningsavgift."
              values={{
                amount: formatNumber(amount - setupFee, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
                setupFee: formatNumber(setupFee, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
              }}
            />
          </S.Explanation>
        </S.UnderTitleContainer>
        <S.SliderWrapper>
          <S.SliderHeader>
            <S.SliderHeaderLeft>
              <h3>
                <FormattedMessage id="app.mypages.loans.application.repayment_periods" />
              </h3>
            </S.SliderHeaderLeft>
            <S.SliderHeaderRight>
              <h3>
                {repaymentPeriods / 12}{" "}
                <FormattedMessage id="app.common.years" />
              </h3>
            </S.SliderHeaderRight>
          </S.SliderHeader>
          <SliderInput
            defaultValue={defaultRepaymentPeriods}
            step={12}
            min={12}
            max={180}
            name="repayment_periods"
            control={form.control}
            onChange={(e) => onChange(Number(e.target.value))}
          />
        </S.SliderWrapper>
        <S.MonthlyCostWrap>
          <S.MonthlyCostAmount>
            {formatNumber(getPeriodCost(), {
              style: "currency",
              currency: "SEK",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
            {" *"}
          </S.MonthlyCostAmount>
          <FormattedMessage
            id="app.common.monthlyCost"
            defaultMessage="Månadskostnad"
          />
        </S.MonthlyCostWrap>
      </S.InputContainer>
    </S.DynamicFieldsContainer>
  )
}

export default DynamicFields
