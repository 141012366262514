import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const LoanBoxContainer = styled.div`
  background: ${(props) => props.theme.infoBox};
  border-radius: 20px;

  small {
    display: block;
    margin-top: 2em;
    line-height: 16.8px;
    color: var(--dark-gray);
  }
`

export const SliderWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  margin-bottom: 2em;
  gap: 3em;

  label {
    width: 100%;

    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5em;

      span {
        font-weight: bold;
        font-size: ${font.size.xl};
      }
    }
  }

  @media screen and (max-width: ${mediaQueries.medium}) {
    grid-template-columns: 1fr;
  }
`

export const MonthlyCostWrap = styled.div`
  background: ${(props) => props.theme.background};
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5em 0;
  margin-bottom: 2em;
  @media screen and (max-width: ${mediaQueries.medium}) {
    padding: 1em 0em;
  }
`

export const MonthlyCostTitle = styled.div`
  font-size: ${font.size.xl};
  font-weight: bold;
  margin-bottom: 1em;
  @media screen and (max-width: ${mediaQueries.medium}) {
    margin-bottom: 0.5em;
  }
`

export const MonthlyCostAmount = styled.div`
  font-weight: 700;
  font-size: ${font.size.xl3};
`

export const Slider = styled.input`
  -webkit-appearance: none;
  margin-right: 15px;
  width: 100%;
  height: 8px;
  background: var(--light-gray);
  border-radius: 5px;
  background-size: 0%;
  background-image: linear-gradient(var(--light-yellow), var(--light-yellow));
  background-repeat: no-repeat;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: var(--dark-yellow);
    cursor: pointer;
    border: none;
    transition: background 0.3s ease-in-out;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: var(--dark-yellow);
    cursor: pointer;
    border: none;
    transition: background 0.3s ease-in-out;
  }

  &::-ms-thumb {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: var(--dark-yellow);
    cursor: pointer;
    border: none;
    transition: background 0.3s ease-in-out;
  }

  &::-moz-range-thumb:hover {
    background: #ff0200;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`

export const InputsWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3em;
  margin: 2em 0;
  box-shadow: 0 0 10px var(--light-gray);
  border-radius: 6px;
  padding: 2.5em 1em 1em;
  position: relative;

  label {
    font-weight: bold;
  }

  @media screen and (max-width: ${mediaQueries.medium}) {
    grid-template-columns: 1fr;
  }
`

export const CollectLoanLinkContainer = styled.div`
  margin-bottom: 2em;

  span {
    color: var(--black);
    a {
      color: var(--black);
      text-decoration: underline;
    }
  }
`

export const AddLoanButtonWrap = styled.div`
  display: flex;
  margin: 0.5em 0 1.5em 0;
  gap: 1em;

  button {
    max-width: 50%;
  }

  @media screen and (max-width: ${mediaQueries.medium}) {
    justify-content: center;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    flex-direction: column;
    button {
      max-width: 100%;
    }
  }
`
export const ErrorMessage = styled.div`
  margin-top: 1em;
  color: var(--brand-danger);
`
export const ExistingLoanDesc = styled.div`
  position: absolute;
  top: 1em;
  left: 1em;
  font-weight: bold;
`
export const CollectLoansWrapper = styled.div`
  padding: 0 2.5em;
  @media screen and (max-width: ${mediaQueries.large}) {
    padding: 1em;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    padding: 0em;
  }
`
