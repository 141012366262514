import styled, { css } from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

type TableHeadPropTypes = {
  chevron?: boolean
  hide?: boolean
}

export const Table = styled.div`
  background: ${(props) => props.theme.infoBox};
  border-radius: 20px;
  margin-bottom: 2em;
  padding: 2em 0;
  box-sizing: border-box;
  width: 100%;
`
export const TableHeadWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  @media screen and (max-width: ${mediaQueries.large}) {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const TableHead = styled.div<TableHeadPropTypes>`
  font-size: ${font.size.sm};
  font-weight: bold;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;

  ${(props) =>
    props.chevron &&
    css`
      display: none;
    `}

  @media screen and (max-width: ${mediaQueries.large}) {
    ${(props) =>
      props.hide &&
      css`
        display: none;
      `}

    ${(props) =>
      props.chevron &&
      css`
        display: flex;
      `}
  }
`

export const TableBody = styled.div`
  margin-bottom: 1em;
`

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`
