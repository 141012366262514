import { forwardRef } from "react"

import { TextareaProps } from "./TextArea.types"
import {
  TextAreaWrap,
  StyledTextArea,
  ValidationMessage,
} from "./TextArea.styles"

const TextArea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ message, error, ...rest }, ref) => (
    <TextAreaWrap>
      <StyledTextArea
        {...rest}
        ref={ref}
        error={error}
        aria-label={rest.placeholder}
      />
      <ValidationMessage>{message}</ValidationMessage>
    </TextAreaWrap>
  )
)

TextArea.displayName = "Textarea"

export default TextArea
