import styled from "styled-components"
import { flex } from "config/mixins"
import { font, mediaQueries } from "Styles/styleGlobal"

export const Back = styled.a`
  ${flex.alignCenter}
  color: ${(p) => p.theme.fontColor};
  position: relative;
  cursor: pointer;
  margin-bottom: 3em;
  svg {
    transform: rotate(180deg) translate(0, -50%);
    margin-right: 1em;
    width: 30px;
    height: 30px;
    margin-top: -1em;
    position: absolute;
    left: -40px;
    @media screen and (max-width: 1500px) {
      left: -10px;
      margin-right: 0;
      position: relative;
    }

    path {
      stroke: ${(p) => p.theme.fontColor};
    }
  }
  h1 {
    margin: 0;
    @media screen and (max-width: ${mediaQueries.small}) {
      font-size: ${font.size.xl2};
    }
  }
`
