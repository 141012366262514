import { useCallback, useState } from "react"
import { FormattedMessage, FormattedNumber } from "react-intl"
import { useParams } from "react-router-dom"

import { useGetDepositServiceByID } from "Hooks/API/useDepositServices"
import Button from "UI/Button"
import Loading from "UI/Loading"
import { formatNumber } from "Utils/formatNumber"

import Drawer from "UI/Drawer"
import CloseAccountModal from "./CloseAccountModal/CloseAccountModal"
import SavingsWithdrawal from "../Withdrawal/SavingsWithdrawal"
import * as S from "./SavingsDetails.styles"

const SavingsDetails = () => {
  const { accountID } = useParams() as { accountID: string }

  const [toggleAccountModal, setToggleAccountModal] = useState(false)
  const [showWithdrawalDrawer, setShowWithdrawalDrawer] = useState(false)

  const { data, isLoading } = useGetDepositServiceByID(accountID)

  const handleCloseAccountModal = useCallback(() => {
    setToggleAccountModal((prev) => !prev)
  }, [setToggleAccountModal])

  const openWithdrawalDrawer = useCallback(
    () => setShowWithdrawalDrawer((prev) => !prev),
    []
  )

  if (isLoading || !data) {
    return <Loading />
  }

  return (
    <S.Wrap>
      <S.InfoDetailsContainer>
        <h3>
          <FormattedMessage
            id="app.mypages.savings.details.heading"
            defaultMessage="Kontoinformation"
          />
        </h3>
        <S.DetailsContainer>
          <ul>
            <li>
              <span>
                <FormattedMessage id="app.common.name" />
              </span>
              <span>{data.name || "-"}</span>
            </li>
            <li>
              <span>
                <FormattedMessage id="app.mypages.savings.settings.input.accountNumber" />
              </span>
              <span>{data.product_number || "-"}</span>
            </li>
            <li>
              <span>
                <FormattedMessage id="app.common.interestRate" />
              </span>
              <span>
                <FormattedNumber
                  value={data.interest_rate}
                  style="percent"
                  minimumFractionDigits={2}
                />
              </span>
            </li>
            <li>
              <span>
                <FormattedMessage id="app.common.commitmentPeriod" />
              </span>
              <span>
                {data.type === "flex" ? (
                  <FormattedMessage id="app.common.noBindingPeriod" />
                ) : (
                  data.term_end_date || "-"
                )}
              </span>
            </li>
          </ul>
        </S.DetailsContainer>
      </S.InfoDetailsContainer>
      <S.InfoDetailsContainer>
        <h3>
          <FormattedMessage id="app.common.balances" />
        </h3>
        <S.DetailsContainer>
          <ul>
            <li>
              <span>
                <FormattedMessage id="app.common.balance" />
              </span>
              <span>
                {formatNumber(data.current_balance, { style: "currency" })}
              </span>
            </li>
            <li>
              <span>
                <FormattedMessage id="app.mypages.deposits.home.balanceOverview.accruedInterest" />
              </span>
              <span>
                {formatNumber(data.accrued_interest, { style: "currency" })}
              </span>
            </li>
          </ul>
        </S.DetailsContainer>
      </S.InfoDetailsContainer>
      <Button
        variant="inverse"
        style={{
          borderColor: "rgba(255, 123, 123, 1)",
          color: "rgba(255, 123, 123, 1)",
        }}
        onClick={
          data.current_balance > 0
            ? openWithdrawalDrawer
            : handleCloseAccountModal
        }
      >
        <FormattedMessage id="app.mypages.savings.details.closeAccount.btn" />
      </Button>
      {toggleAccountModal && (
        <CloseAccountModal handleCloseModal={handleCloseAccountModal} />
      )}
      <Drawer
        isOpen={showWithdrawalDrawer}
        handleClose={openWithdrawalDrawer}
        disableBackdropClick
        closeBtn
      >
        <SavingsWithdrawal
          isClosingTransferring
          closeDrawer={openWithdrawalDrawer}
          successWithdrawalCallback={handleCloseAccountModal}
        />
      </Drawer>
    </S.Wrap>
  )
}

export default SavingsDetails
