import { FormattedMessage } from "react-intl"

import { formatNumber } from "Utils/formatNumber"
import * as S from "../LoanApplication.styles"
import {
  getFeesBySource,
  calculatePaymentDetails,
  getInterestRateBySource,
} from "../helpers"

type CalculationExampleProps = {
  source?: string
}

const CalculationExample = ({ source }: CalculationExampleProps) => {
  const getPaymentDetails = () => {
    const { setupFee, adminFee } = getFeesBySource(source)
    const exampleInterestRate = getInterestRateBySource(source)
    const exampleAmount = 130000
    const exampleRepaymentPeriods = 12 * 8

    const { periodCost, effectiveInterestRate, totalCost, interestRate } =
      calculatePaymentDetails(
        exampleAmount,
        exampleRepaymentPeriods,
        exampleInterestRate,
        setupFee,
        adminFee
      )

    const paymentDetails = {
      amount: formatNumber(exampleAmount, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      interestRate: formatNumber(interestRate),
      periodCost: formatNumber(periodCost, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      effectiveInterestRate: formatNumber(effectiveInterestRate),
      totalCost: formatNumber(totalCost, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      fee: formatNumber(setupFee, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      adminFee: formatNumber(adminFee, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    }

    return paymentDetails
  }

  return (
    <S.Calculation>
      <FormattedMessage
        id="app.mypages.loans.application.calculation_example"
        defaultMessage="*Räkneexempel: Ett lån på {amount} kr till {interestRate} % nominell ränta (räntan är rörlig och sätts individuellt baserat på dina förutsättningar, f.n. 8,98% – 24,99%) kostar {periodCost} kr/månad (Effektiv ränta {effectiveInterestRate} %), dvs totalt {totalCost} kr. Totalkostnaden och den effektiva räntan inkluderar {fee} kr uppläggningsavgift samt {adminFee} kr i administrationskostnad."
        values={getPaymentDetails()}
      />
    </S.Calculation>
  )
}
export default CalculationExample
