import { ReactElement, ReactNode } from "react"

export type InformationBannerPropTypes = {
  title: string | ReactElement
  type: InfoBannerType
  body: ReactNode
  onClick: (event: React.MouseEvent<Element, MouseEvent>) => void
}

export type IconWrapPropTypes = {
  type: string
}

export type StyledInformationBannerPropTypes = {
  type: string
}

export enum InfoBannerTypeEnum {
  Attention = "attention",
  Error = "error",
  Info = "info",
  Success = "success",
}

type InfoBannerType = `${InfoBannerTypeEnum}`
