import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const Form = styled.form``

export const InputWrap = styled.div`
  position: absolute;
  display: flex;
  margin: 0;
  width: 100%;
  background: var(--white);
  left: 0;
  input {
    padding: 0.5em 0.5em 0em;
    width: 200px;
    margin: -0.2em 0 0;
    @media screen and (max-width: ${mediaQueries.small}) {
      width: 170px;
      margin-top: -0.4em;
    }
  }
  label {
    left: 0.5em;
    top: -6px !important;
    @media screen and (max-width: ${mediaQueries.small}) {
      top: -10px !important;
    }
  }
`

export const IconWrap = styled.div`
  color: ${(p) => p.theme.fontColor};
  height: 20px;
  width: 20px;
  margin-left: 0.3em;
  opacity: 0;
  transition-duration: 0.3s;
  cursor: pointer;

  svg {
    transform: scale(1.6);
    fill: ${(p) => p.theme.fontColor};
  }
`

export const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 1.5em;
`

export const ButtonEditNameWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  @media screen and (max-width: ${mediaQueries.large}) and (min-width: ${mediaQueries.medium}) {
    margin-left: 5px;
    flex-direction: column;
    justify-content: center;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    margin-left: 5px;
    flex-direction: column;
    justify-content: center;
  }
`

export const TextButton = styled.button`
  text-decoration: underline;
  font-size: ${font.size.base};
  color: ${(p) => p.theme.fontColor};

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
