import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const Wrap = styled.div`
  margin: 2em 0;
  @media screen and (max-width: ${mediaQueries.medium}) {
    margin: 1.25em 0;
  }
`

export const InfoDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  h3 {
    margin: 0;
  }
  ul {
    list-style: none;
    width: 100%;
    margin: 1em 0 3em 0;
    @media screen and (max-width: ${mediaQueries.medium}) {
      margin: 0 0 3em 0;
    }
    li {
      display: flex;
      justify-content: space-between;
      padding: 1em 0;
      border-bottom: 1px solid var(--light-gray);
      &:last-of-type {
        border-bottom: 0;
      }
      span:last-of-type {
        font-weight: 500;
        text-align: right;
      }
    }
  }
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 1em;
  @media screen and (min-width: ${mediaQueries.large}) {
    margin-bottom: 0;
  }
`
