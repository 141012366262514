import { FC, useRef, MutableRefObject } from "react"
import { useInView } from "react-intersection-observer"

import IconFactory from "UI/IconFactory"
import TabLink from "./TabLink"
import { TabLinksPropsType } from "./TabLinks.types"
import * as S from "./TabLinks.styles"

export const TabLinks: FC<TabLinksPropsType> = ({ links }) => {
  const tabLinksRef = useRef() as MutableRefObject<HTMLDivElement>
  const { ref, inView } = useInView()

  const handleScroll = () => {
    tabLinksRef.current.scrollBy({ left: 100, behavior: "smooth" })
  }

  return (
    <S.TabLinksWrap ref={tabLinksRef}>
      {links.map((props, index) => (
        <TabLink {...props} key={`${props.to}+${index}`} />
      ))}
      <S.Anchor ref={ref} />
      {!inView && (
        <S.SVGWrap>
          <IconFactory name="chevronRight" onClick={handleScroll} />
        </S.SVGWrap>
      )}
    </S.TabLinksWrap>
  )
}

export default TabLinks
