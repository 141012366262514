import { FC } from "react"
import { FormattedMessage } from "react-intl"

const RepaymnetPeriods: FC<{ repaymentPeriods: number }> = ({
  repaymentPeriods,
}) => {
  return (
    <>
      {repaymentPeriods % 12 !== 0 ? repaymentPeriods : repaymentPeriods / 12}{" "}
      {repaymentPeriods % 12 !== 0 ? (
        <FormattedMessage id="app.common.months" defaultMessage="månader" />
      ) : (
        <FormattedMessage id="app.common.years" defaultMessage="år" />
      )}
    </>
  )
}

export default RepaymnetPeriods
