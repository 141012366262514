import { FC, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import Input from "UI/Input"
import { useUpdateLoanApplication } from "Hooks/API/useLoansApplications"
import { LoanPurposeType } from "Interfaces/loanApplicationInterfaces"
import Button from "UI/Button"
import Select from "UI/Select"
import { useOptions } from "./purposes"
import { LoanPerksBox } from "../../Shared"
import { StepPropsType } from "../../Onboarding.types"
import * as Styles from "../../Onboarding.styles"
import { FormWrap } from "./LoanPurpose.styles"

const LoanPurpose: FC<StepPropsType> = ({ data }) => {
  const { id } = useParams() as { id: string }
  const [loanPurpose, setLoanPurpose] = useState(data.loan_purpose || undefined)
  const [employer, setEmployer] = useState(
    data.applicant.applicant_detail.employer || ""
  )
  const intl = useIntl()

  const options = useOptions()

  const updateLoanApplication = useUpdateLoanApplication({
    payload: {
      applicant: { applicant_detail: { employer } },
      loan_purpose: loanPurpose,
    },
    uid: id,
  })

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLoanPurpose(e.target.value as LoanPurposeType)
  }
  const handleEmployer = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmployer(e.target.value)
  }

  const handleSubmit = () => updateLoanApplication.mutate()

  return (
    <Styles.Container>
      <h1>
        <FormattedMessage
          id="app.onboarding.loanPurpose.heading"
          defaultMessage="Nästan klart!"
        />
      </h1>
      <p>
        <FormattedMessage
          id="app.onboarding.loanPurpose.description"
          defaultMessage="Vi behöver veta namnet på din arbetsgivare och syfte till lånet."
        />
      </p>
      <FormWrap>
        {!data.applicant.applicant_detail.employer && (
          <Input
            name="employer"
            placeholder={
              <FormattedMessage
                id="app.onboarding.loanPurpose.input.placeholder"
                defaultMessage="Arbetsgivare"
              />
            }
            type="text"
            onChange={handleEmployer}
          />
        )}
        {!data.loan_purpose && (
          <Select
            aria-label={intl.formatMessage({
              id: "app.onboarding.loanPurpose.option1",
              defaultMessage: "Välj syfte",
            })}
            options={options}
            onChange={handleSelect}
            value={loanPurpose}
          />
        )}
        <Button
          onClick={handleSubmit}
          disabled={
            !(employer !== "" && loanPurpose) || updateLoanApplication.isLoading
          }
          isLoading={updateLoanApplication.isLoading}
        >
          <FormattedMessage
            id="app.onboarding.loanPurpose.nextCTAText"
            defaultMessage="Gå vidare"
          />
        </Button>
      </FormWrap>
      <Styles.SmallPrint>
        <FormattedMessage
          id="app.onboarding.loanPurpose.smallPrint"
          defaultMessage="Genom att skriva under intygar du att uppgifterna du lämnat stämmer och att du har förstått avtalsvillkoren."
        />
      </Styles.SmallPrint>
      <Styles.MobileAttentionBox>
        <LoanPerksBox estimatedCashBack={data.estimated_cashback} />
      </Styles.MobileAttentionBox>
    </Styles.Container>
  )
}

export default LoanPurpose
