import { FormattedMessage } from "react-intl"

import yup from "config/yup"

const currentDate = new Date()

export const CollectLoansSchema = yup.object().shape({
  amount: yup
    .number()
    .min(10000, () => (
      <FormattedMessage
        id="app.mypages.loans.collectLoans.amountError"
        defaultMessage="Belopp att samla kan ej vara mindre än 10 000 kr."
      />
    ))
    .required(),
  repayment_periods: yup.number().required(),
  applicant: yup.object().shape({
    email: yup.string().email().required(),
    tel: yup
      .string()
      .min(5, () => (
        <FormattedMessage
          id="app.mypages.loans.collectLoans.telError"
          defaultMessage="Telefonnumret måste vara minst 5 tecken"
        />
      ))
      .required(),
    legal_entity_number: yup
      .string()
      .min(12, () => (
        <FormattedMessage
          id="app.mypages.loans.collectLoans.personalNumberError"
          defaultMessage="Personnummer måste bestå av minst 12 tecken"
        />
      ))
      .required(),
    applicant_detail: yup.object().shape({
      work_income: yup.number().min(1),
      occupation_from_year: yup
        .number()
        .min(1)
        .max(currentDate.getFullYear())
        .required(),
      occupation_from_month: yup.number().min(1).max(12).required(),
      housing_cost: yup.number().min(1),
      occupation: yup
        .string()
        .oneOf([
          "full_time",
          "student",
          "unemployed",
          "early_pensioner",
          "finite_time",
          "hourly",
          "self_employed",
        ])
        .required(),
      marital_status: yup
        .string()
        .oneOf(["single", "living_together", "married", "divorced"])
        .required(),
      housing_type: yup
        .string()
        .oneOf(["rental", "house", "condominium", "tenant"])
        .required(),
      number_of_kids: yup.number().required(),
      employer: yup.string().required(),
    }),
  }),
  existing_loans: yup.array().of(
    yup.object().shape({
      ocr: yup.number().min(1),
      creditor: yup.string().required(),
      payment_to: yup.number().min(1),
      amount: yup.number().min(1),
    })
  ),
})
