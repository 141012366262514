import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom"
import { Urls } from "Constants/urls"
import Main from "Pages/Authenticated/Main/Main"
import MyPages from "Pages/Authenticated/MyPages"
import Login from "Pages/Unauthenticated/Login/Login"
import Onboarding from "Pages/Unauthenticated/Onboarding/Onboarding"
import IndependentBankScreening from "Pages/Unauthenticated/IndependentBankScreening/IndependentBankScreening"
import LoanCollection from "Pages/LoanCollection/Iceing"
import LoanApplication from "Pages/Unauthenticated/LoanApplication/LoanApplication"
import Investments from "Pages/Authenticated/Investments"
import accountRoutes from "./account"
import { loansRoutes } from "./loans"
import savingsRoutes from "./savings"

import { PrivateRoute, PublicRoute } from "./routes"
import Router from "./router"

export const routes: RouteObject[] = [
  {
    element: <Router />,
    children: [
      {
        element: <PrivateRoute redirectPath="login" />,
        children: [
          {
            element: <MyPages />,
            path: "/",
            errorElement: <Navigate to={Urls.Home} />,
            children: [
              {
                path: Urls.Home,
                element: <Main />,
              },
              {
                path: Urls.Investments,
                element: <Investments />,
              },
              accountRoutes,
              loansRoutes,
              savingsRoutes,
            ],
          },
        ],
      },
      {
        element: <PublicRoute />,
        children: [
          {
            element: <Login />,
            path: "/login",
          },
        ],
      },
      {
        path: "onboarding/:id",
        element: <Onboarding />,
      },
      {
        path: "bank-account-screening/:application/:applicant",
        element: <IndependentBankScreening />,
      },
      {
        path: "/iceing",
        element: <LoanCollection />,
      },
      {
        path: "/loan-application/:id",
        element: <LoanApplication />,
      },
      {
        path: "/loan-application",
        element: <LoanApplication />,
      },
      {
        path: "*",
        element: <Navigate to={Urls.Home} />,
      },
    ],
  },
]

export const router = createBrowserRouter(routes)
