import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const LoadingWrap = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
`

export const ReportsWrap = styled.div`
  margin: 3em 0 0 0;
  h1 {
    margin-bottom: 1em;
  }
  select {
    width: 300px;
    margin: 0;
    height: auto;
    font-size: ${font.size.base};
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    padding: 0;
  }
`

export const SelectLabel = styled.label`
  display: flex;
  flex-direction: column;
  select {
    margin-top: 20px;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    select {
      width: 100%;
    }
  }
`

export const InvestmentsWrap = styled.div`
  width: 50%;
  position: relative;
`
