import { FormattedMessage } from "react-intl"

import Button from "UI/Button"
import IconFactory from "UI/IconFactory"
import { isMobile } from "Utils"

import * as S from "./BankIDAuthMethods.styles"

interface BankIDAuthMethodsProps {
  authWithAutostartToken: () => void
  authWithBankId: () => void
  isLoading: boolean
  errorMessage: string
  isError: boolean
}

export const BankIDAuthMethods = ({
  authWithAutostartToken,
  authWithBankId,
  isLoading,
  errorMessage,
  isError,
}: BankIDAuthMethodsProps) => {
  const isMobileDevice = isMobile()

  return (
    <S.MethodsBlock>
      <Button
        type="button"
        className="button-bankId"
        disabled={isLoading}
        onClick={isMobileDevice ? authWithAutostartToken : authWithBankId}
      >
        <IconFactory name="bankId" color="#fff" width={32} height={28} />
        <FormattedMessage id="app.login.button.bankId" />
      </Button>
      <Button
        type="button"
        variant="inverse"
        disabled={isLoading}
        onClick={isMobileDevice ? authWithBankId : authWithAutostartToken}
      >
        <FormattedMessage
          id={
            isMobileDevice
              ? "app.login.button.bankIdOtherDevice"
              : "app.login.button.bankIdThisDevice"
          }
        />
      </Button>
      {isError && <p className="error-message">{errorMessage}</p>}
    </S.MethodsBlock>
  )
}
