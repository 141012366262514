import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media screen and (max-width: ${mediaQueries.medium}) {
    height: 90vh;
  }
`

export const Heading = styled.h1`
  margin-top: 0;
  @media screen and (max-width: ${mediaQueries.medium}) {
    margin-top: 20px;
  }
`
