import { FC, ReactNode } from "react"
import { FormattedMessage } from "react-intl"

import {
  InformationKeys as Keys,
  InformationValues as Values,
} from "Pages/LoanCollection/Constants/Types"

import * as S from "./Information.styles"

interface Props {
  content: { [K in Keys]?: Values }
  values?: { [K in Keys]?: { [key: string]: string } }
  style?: { [K in Keys]?: { [key: string]: string } }
  additionalInformation?: { [K in Keys]?: { children: ReactNode } }
}

const Information: FC<Props> = ({
  content,
  values = {},
  style = {},
  additionalInformation = {},
}) => (
  <>
    {content.h3 && (
      <S.Title style={{ marginBottom: "0", ...style.h3 }}>
        <FormattedMessage id={content.h3} />
      </S.Title>
    )}
    {content.p && (
      <p style={{ ...style.p }}>
        <FormattedMessage id={content.p} values={{ ...values.p }} />
        {additionalInformation.p?.children}
      </p>
    )}
  </>
)

export default Information
