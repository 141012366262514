export const tinkTypes = {
  accountVerification: "account-check",
  pay: "pay",
  bundledFlow: "reports/create-report",
}

export const screenTypes = {
  providerSelection: "PROVIDER_SELECTION_SCREEN",
  accessSelection: "ACCESS_TYPE_SELECTION_SCREEN",
  credentialsSelection: "CREDENTIALS_TYPE_SELECTION_SCREEN",
  submitCredentials: "SUBMIT_CREDENTIALS_SCREEN",
  accountSelection: "ACCOUNT_SELECTION_SCREEN_MULTIPLE",
}

export const reportTypes = [
  "INCOME_CHECK_REPORT",
  "RISK_INSIGHTS_REPORT",
  "TRANSACTION_REPORT",
]
