import {
  ErrorType,
  type IScrappingLoanBank,
  type ScrapeBank,
  type ScrapingBank,
} from "Interfaces/iceingInterfaces"
import axios from "ApiServices/axios"

export const getBanks = async () => {
  try {
    const { data } = await axios.get<IScrappingLoanBank[]>(
      `/loan-collection/banks`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    return data
  } catch {
    throw new Error("Cannot get banks")
  }
}

export const scrapeBank = async ({
  bankData,
}: ScrapingBank): Promise<ScrapeBank> => {
  try {
    const { data } = await axios.post(
      `/loan-collection/loans/${bankData.userBank.toLowerCase()}`,
      {
        ...bankData,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    return {
      ok: data.ok,
      loans: {
        creditCards: data.creditCards,
        loans: data.loans,
        installments: data.installments,
      },
      error: {
        type: data.error.type,
        message: data.error.message,
      },
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const errorType = error.response?.data?.error?.type
    const errorMessage = error.response?.data?.error?.message

    return {
      ok: false,
      loans: {
        creditCards: [],
        loans: [],
        installments: [],
      },
      error: {
        type: errorType || ErrorType.General,
        message: errorMessage || error.message,
      },
    }
  }
}
