import { useCallback, useState } from "react"
import { useParams } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { useQueryClient } from "react-query"

import { useSaveSelectedBankAccount } from "Hooks/API/useDepositServices"
import TinkCallback from "Components/TinkCallback"
import { useFeedbackModal } from "Context/modal-context"
import { IDepositService } from "Interfaces/depositInterfaces"
import { useGetCustomer } from "Hooks/API/useCustomer"
import { DEPOSIT_SERVICES_SERVICE } from "Constants/queryKeys"
import LinkedBankAccount from "./LinkedBankAccount"
import * as S from "./styles"

const SavingsDetails = () => {
  const [isTinkWindow, setIsTinkWindow] = useState(false)
  const { accountID } = useParams() as { accountID: string }
  const queryClient = useQueryClient()
  const intl = useIntl()

  const saveSelectedBankAccount = useSaveSelectedBankAccount(accountID)
  const { data, isSuccess } = useGetCustomer()
  const { errorModal, successModal } = useFeedbackModal()

  const deposit = queryClient.getQueryData<IDepositService>([
    DEPOSIT_SERVICES_SERVICE,
    accountID,
  ])

  const handleChangeBankAccount = () => {
    setIsTinkWindow(true)
  }

  const handleTinkEvent = useCallback(
    (type: string, eventData: string | { element: string }) => {
      if (type === "error") {
        errorModal(
          intl.formatMessage({
            id: "app.mypages.savings.onBoarding.verification.tink.error",
          })
        )
        return
      }
      if (
        typeof eventData === "object" &&
        eventData.element === "CLOSE_BUTTON"
      ) {
        setIsTinkWindow(false)
      }
      if (type !== "account_verification_report_id") {
        return
      }

      if (eventData && typeof eventData === "string") {
        saveSelectedBankAccount
          .mutateAsync({ uid: accountID, data: eventData })
          .then(() => {
            successModal(
              intl.formatMessage({
                id: "app.mypages.savings.settings.changeAccount.tink.success",
                defaultMessage: "Ditt bankkonto har ändrats.",
              })
            )
          })
          .catch((error) => {
            errorModal(
              error?.response?.data?.message ||
                intl.formatMessage({
                  id: "app.mypages.notifications.errorMessage",
                  defaultMessage: "Oj då! Något gick fel :(",
                })
            )
          })
          .finally(() => setIsTinkWindow(false))
      }
    },
    [accountID, errorModal, intl, saveSelectedBankAccount, successModal]
  )

  if (!deposit) {
    return (
      <>
        <S.DetailsHeading>
          <FormattedMessage
            id="app.mypages.mainNav.savings.link.settings"
            defaultMessage="Inställningar"
          />
        </S.DetailsHeading>
        <FormattedMessage
          id="app.mypages.error.deposit.getDeposit"
          defaultMessage="Kan inte få tillgång till den begärda insättningstjänsten. Försök igen."
        />
      </>
    )
  }

  if (!isTinkWindow) {
    return (
      <S.DetailsWrap>
        <LinkedBankAccount
          bankAccount={deposit.bank_account}
          handleChangeBankAccount={handleChangeBankAccount}
        />
      </S.DetailsWrap>
    )
  }

  if (!isSuccess) return null

  return (
    <TinkCallback
      handleTinkEvent={handleTinkEvent}
      type="accountVerification"
      legalEntityNumber={data.legal_entity_number}
      showBackButton
    />
  )
}

export default SavingsDetails
