import styled, { css } from "styled-components"

interface GenericBank {
  isGenericBank?: { background?: string; color?: string }
}

const commonStyles = css`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--very-light-gray);
  margin-right: 5px;
  object-fit: contain;
`

export const Icon = styled.div<GenericBank>`
  ${commonStyles}

  svg {
    ${commonStyles}
    background-color: var(--light-gray);
  }

  ${(props) => {
    if (props.isGenericBank && Object.keys(props.isGenericBank).length > 0) {
      return `
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${props.isGenericBank?.background};
        color: ${props.isGenericBank?.color}
      `
    }

    return ``
  }}
`
