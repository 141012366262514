import { IPep } from "Interfaces/KYCInterfaces"
import { IAMLQuestionsRequest } from "./AMLQuestions.types"

export const initialData: IAMLQuestionsRequest = {
  living_in_sweden: true,
  birthplace_citizenship: "",
  occupancy: "",
  tax_domicile: undefined,
  tax_identification_number: undefined,
  pep: null,
}

export const emptyPepData: IPep = {
  pep_position: [],
  pep_relation: [],
  pep_coworker: [],
  pep_relation_position_title: "",
  pep_relation_position_country: "",
  pep_position_country: "",
  pep_position_title: "",
}
