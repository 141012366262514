import { useMemo } from "react"
import { FormattedMessage } from "react-intl"

import { useBasicLoanServices } from "Hooks/API/useBasicLoanServices"
import { getLoansInfo } from "Utils/getInfoUtils"
import PromoWrap from "Components/PromoWrap"
import Loading from "UI/Loading"
import LoansFeatures from "Components/LoansFeatures"
import * as S from "./LoansOverview.styles"

const LoansOverview = () => {
  const { data: loans, isLoading, isError } = useBasicLoanServices(true)

  const loansData = useMemo(
    () =>
      loans?.data && loans?.data.length > 0 ? getLoansInfo(loans.data) : null,
    [loans?.data]
  )

  if (isLoading)
    return (
      <S.LoadingContainer>
        <Loading />
      </S.LoadingContainer>
    )

  if (isError)
    return (
      <FormattedMessage
        id="app.mypages.error.basicsLoansServices.getBasicLoanServices"
        defaultMessage="Du kan inte få en lista över dina grundläggande lånetjänster. Försök igen"
      />
    )

  return (
    <S.OverviewWrap>
      {loans && loans?.data.length > 0 && (
        <PromoWrap
          type="loans"
          data={loansData}
          accountTitleId="app.mypages.loans.header"
        />
      )}
      <LoansFeatures />
    </S.OverviewWrap>
  )
}

export default LoansOverview
