import { FieldValues, useController } from "react-hook-form"

import Input from "UI/Input"
import { TextFieldProps } from "./TextField.types"

export const TextField = <FieldsType extends FieldValues>({
  name,
  control,
  rules,
  error,
  message,
  defaultValue,
  type = "text",
  autoComplete = "off",
  ...props
}: TextFieldProps<FieldsType>) => {
  const { field, fieldState } = useController<FieldsType>({
    name,
    rules,
    control,
    defaultValue,
  })

  return (
    <Input
      {...props}
      {...field}
      type={type}
      defaultValue={defaultValue}
      autoComplete={autoComplete}
      error={!!fieldState.error || error}
      message={fieldState.error?.message || message}
    />
  )
}
