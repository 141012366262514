import { flex } from "config/mixins"
import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const OnboardingStepsContainer = styled.div`
  flex: 1;
`

export const CounterContainer = styled.div`
  height: 55px;
  display: flex;
  align-items: center;
`

export const StepContainer = styled.div`
  height: 100%;
  margin-top: 50px;
  @media screen and (max-width: ${mediaQueries.small}) {
    margin-top: 0px;
    h1 {
      margin-top: 0px;
    }
  }
`

export const StepHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  span {
    color: var(--dark-gray);
    margin: 0 10px 0 5px;
  }

  @media screen and (min-width: ${mediaQueries.medium}) {
    span {
      margin: 0 15px 0 10px;
    }
  }
`

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  position: relative;
  left: -7px;

  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    svg path {
      stroke: var(--dark-gray);
    }
  }

  .icon-chevronRight,
  svg {
    transform: rotate(180deg);
    path {
      stroke: var(--black);
    }
  }

  &:disabled {
    cursor: not-allowed;
    .icon-chevronRight {
      path {
        stroke: var(--light-gray);
      }
    }
  }
`

export const LoadingWrapper = styled.div`
  ${flex.center}
  flex: 1;
`

export const InfoBlock = styled.div`
  ${flex.column}
  row-gap: 0.5em;
  padding: 2em;
  border-radius: 15px;
  background-color: #e6e6e6;

  > * {
    line-height: 1.5;
    margin: 0;
  }
`

export const TempMessage = styled.p`
  font-size: 18px;
  margin-bottom: 40px;
`

export const CancelButton = styled.button`
  margin-left: auto;
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 0;
  span {
    margin: 0;
    font-size: 16px;
    color: black;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    span {
      font-size: 14px;
    }
  }
`
