import { useInfiniteQuery, useMutation, useQueryClient } from "react-query"

import {
  createDepositReports,
  getReports,
  deleteReport,
  DepositReportsPayload,
} from "ApiServices/reports"
import { REPORTS_DEPOSIT_SERVICE, REPORTS_DOCUMENTS } from "Constants/queryKeys"
import { IDocument, IDocuments } from "Interfaces/documentsInterfaces"

export const useCreateDepositReports = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [REPORTS_DEPOSIT_SERVICE],
    mutationFn: (filters: DepositReportsPayload) =>
      createDepositReports(filters),
    onSuccess: () => {
      setTimeout(() => {
        return queryClient.invalidateQueries([REPORTS_DOCUMENTS, 1])
      }, 15000)
    },
  })
}

export const useDeleteReport = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (publishableUid: string) => deleteReport(publishableUid),
    onSuccess: () => queryClient.invalidateQueries([REPORTS_DOCUMENTS]),
  })
}

export function useGetReports(pageNumber: number) {
  return useInfiniteQuery<IDocuments, unknown, IDocument>({
    queryKey: [REPORTS_DOCUMENTS, pageNumber],
    queryFn: ({ pageParam = 1 }) => getReports(pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.last_page === lastPage.meta.current_page) {
        return undefined
      }
      return lastPage.meta.current_page + 1 ?? undefined
    },
    select(data) {
      return {
        ...data,
        pages: data.pages.flatMap((page) => page.data),
      }
    },
  })
}
