import styled from "styled-components"

export const TaxBannerBlock = styled.div`
  display: flex;
  column-gap: 16px;
  padding: 20px;
  border-radius: 16px;
  background-color: var(--light-gray);

  .icon {
    flex: 1 0 auto;
    margin-top: 2px;
  }
`

export const TaxBannerText = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
`
