import styled, { css } from "styled-components"
import { font } from "Styles/styleGlobal"
import {
  StyledButtonPropType,
  LoadingWrapPropType,
  VariantEnum,
} from "./Button.types"

const anchorStyles = css`
  font-weight: 700;
`

export const StyledButton = styled.button<StyledButtonPropType>`
  padding: 13px 25px;
  font-size: ${font.size.base};
  min-height: 50px;
  min-width: 100px;
  border-radius: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  transition: all 300ms ease-in-out;
  ${(p) => p.component !== "button" && anchorStyles}
  &:focus {
    outline: none;
  }

  ${(p) => {
    switch (p.variant) {
      case VariantEnum.Primary:
        return css`
          background: ${p.theme.foreground};
          border: 2px solid transparent;
          color: ${p.theme.background};
          &:not(:disabled):hover {
            background: var(--dark-yellow);
            color: var(--black);
          }

          &:disabled {
            background: var(--light-gray);
            color: var(--white);
            cursor: not-allowed;
          }
        `
      case VariantEnum.Inverse:
        return css`
          background: transparent;
          color: ${p.theme.fontColor};
          border: 2px solid ${p.theme.foreground};

          &:not(:disabled):hover {
            border-color: var(--dark-yellow);
            color: var(--dark-yellow);
          }

          &:disabled {
            border: 2px solid var(--light-gray);
            color: var(--light-gray);
            cursor: not-allowed;
          }
        `
      case VariantEnum.Chevron:
        return css`
          height: 45px;
          background: ${p.theme.foreground};
          border-radius: 16px;
          border: none;
          color: ${p.theme.background};

          .icon-chevronRight {
            path {
              transition: all 300ms ease-in-out;
              stroke: ${p.theme.background};
            }
          }

          :not(:disabled):hover {
            background: var(--dark-yellow);
            color: ${p.theme.foreground};

            .icon-chevronRight path {
              stroke: ${p.theme.foreground};
            }
          }

          &:disabled {
            background: var(--light-gray);
            color: var(--white);
            cursor: not-allowed;
          }
        `
      default:
        return null
    }
  }}
`

export const LoadingWrap = styled.div<LoadingWrapPropType>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  svg {
    height: 20px;
  }

  svg .path {
    stroke: ${(p) => {
      switch (p.variant) {
        case VariantEnum.Inverse:
          return "black"
        case VariantEnum.Primary:
        case VariantEnum.Chevron:
          return "white"
        default:
          return null
      }
    }};
  }
`
