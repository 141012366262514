import { FormattedMessage } from "react-intl"

import Button from "UI/Button"
import IconFactory from "UI/IconFactory"

import * as S from "./BankIDAuthMethodsMobile.styles"

interface BankIDAuthMethodsMobileProps {
  authWithAutostartToken: () => void
  authWithBankId: () => void
  isLoading: boolean
}

export const BankIDAuthMethodsMobile = ({
  authWithAutostartToken,
  authWithBankId,
  isLoading,
}: BankIDAuthMethodsMobileProps) => {
  return (
    <S.MethodsBlock>
      <p>
        <FormattedMessage id="hintcode-mobile-outstandingTransaction" />
      </p>
      <Button
        type="button"
        className="button-bankId"
        disabled={isLoading}
        onClick={authWithAutostartToken}
      >
        <IconFactory name="bankId" color="#fff" width={32} height={28} />
        <FormattedMessage id="app.login.button" />
      </Button>
      <S.LinkStyledButton
        type="button"
        disabled={isLoading}
        onClick={authWithBankId}
      >
        <FormattedMessage id="open-bankid-on-another-device" />
      </S.LinkStyledButton>
    </S.MethodsBlock>
  )
}
