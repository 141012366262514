import { FormattedMessage } from "react-intl"

import Modal from "UI/Modal"
import {
  ModalsBody2,
  ModalsBody3,
  ModalsBody1,
} from "./InsuranceInfoModalsBody"
import { InfoModalProps, InfoModalType } from "./InsuranceInfoModal.types"
import * as S from "./InsuranceInfoModal.styles"

const ModalsContent: InfoModalProps = {
  modal1: <ModalsBody1 />,
  modal2: <ModalsBody2 />,
  modal3: <ModalsBody3 />,
}

const ModalsTitle: InfoModalProps = {
  modal1: (
    <FormattedMessage
      id="app.onboarding.insuranceCheck.checkboxlabel.fitToWorkConfirmation.modal1"
      defaultMessage="Fullt arbetsför"
    />
  ),
  modal2: (
    <FormattedMessage
      id="app.onboarding.insuranceCheck.checkboxlabel.fitToWorkConfirmation.modal2"
      defaultMessage="Teckningsreglerna"
    />
  ),
  modal3: (
    <FormattedMessage
      id="app.onboarding.insuranceCheck.checkboxlabel.fitToWorkConfirmation.modal3"
      defaultMessage="Symptomklausulen"
    />
  ),
}

export const InsuranceInfoModal = ({
  setActiveModal,
  activeModal,
}: {
  setActiveModal: React.Dispatch<React.SetStateAction<InfoModalType | null>>
  activeModal: InfoModalType
}) => {
  const handleClose = () => setActiveModal(null)

  return (
    <Modal
      onClick={handleClose}
      title={ModalsTitle[activeModal]}
      body={<S.ModalBody>{ModalsContent[activeModal]}</S.ModalBody>}
    />
  )
}
