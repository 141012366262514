import { FC, useState, useCallback, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"

import Button from "UI/Button"
import TinkCallback from "Components/TinkCallback"
import { useFeedbackModal } from "Context/modal-context"
import { useSaveBankAccountScreening } from "Hooks/API/useLoansApplications"
import { BankAccountScreeningPayload } from "ApiServices/loanApplications"
import { HAS_CLEARED_BANK_ACCOUNT_SCREENING } from "Constants/storage"
import { setStorageItem } from "Utils/localStorage"
import * as Styles from "../../Onboarding.styles"
import {
  BankScreeningDisclaimer,
  LoginButtonWrap,
} from "./BankScreening.styles"
import LoanPerksBox from "../../Shared/LoanPerksBox"
import { BankScreeningPropTypes } from "../../Onboarding.types"

const BankScreening: FC<BankScreeningPropTypes> = ({
  data,
  isIndependent,
  setIsScreeningCompleted,
}) => {
  const { id } = useParams() as { id: string }
  const intl = useIntl()
  const saveBankAccountScreening = useSaveBankAccountScreening(id)
  const { errorModal } = useFeedbackModal()
  const [isTinkWindow, setIsTinkWindow] = useState(false)
  const state = useMemo(
    () => ({
      loan_application_uid: data.uid,
      customer_uid: data.applicant.uid,
      redirect_uri: window.location.pathname,
    }),
    [data.applicant.uid, data.uid]
  )

  const handleTinkEvent = useCallback(
    (type: string, eventData: string | Record<string, unknown>) => {
      if (type === "error") {
        errorModal(
          intl.formatMessage({
            id: "app.mypages.savings.onBoarding.verification.tink.error",
          })
        )
        setIsTinkWindow(false)
        return
      }

      if (
        typeof eventData === "object" &&
        eventData?.element === "CLOSE_BUTTON"
      ) {
        setIsTinkWindow(false)
      }

      if (type !== "reports") {
        return
      }

      if (eventData && typeof eventData === "object") {
        saveBankAccountScreening
          .mutateAsync({
            uid: state.loan_application_uid,
            params: {
              ...eventData,
              customer_uid: state.customer_uid,
              is_independent: isIndependent,
            } as BankAccountScreeningPayload,
          })
          .then(() => {
            if (!isIndependent) {
              setStorageItem(
                HAS_CLEARED_BANK_ACCOUNT_SCREENING,
                window.btoa(
                  state.loan_application_uid.concat(state.customer_uid)
                )
              )
            } else {
              setIsScreeningCompleted?.(true)
            }
          })
          .catch((error) => {
            errorModal(error.message)
          })
          .finally(() => setIsTinkWindow(false))
      }
    },
    [
      errorModal,
      intl,
      isIndependent,
      saveBankAccountScreening,
      setIsScreeningCompleted,
      state.customer_uid,
      state.loan_application_uid,
    ]
  )

  return (
    <Styles.Container>
      {isTinkWindow ? (
        <TinkCallback
          type="bundledFlow"
          legalEntityNumber={data.applicant.legal_entity_number}
          handleTinkEvent={handleTinkEvent}
          state={state}
          isIndependent={isIndependent}
        />
      ) : (
        <>
          <h1>
            <FormattedMessage
              id="app.onboarding.bankScreening.heading"
              defaultMessage="Välj lönekonto"
            />
          </h1>

          <p>
            <FormattedMessage
              id="app.onboarding.bankScreening.description"
              defaultMessage="Välj banken där du har ditt lönekonto för verifiering och utbetalning."
            />
          </p>

          <LoginButtonWrap>
            <Button type="button" onClick={() => setIsTinkWindow(true)}>
              <FormattedMessage
                id="app.onboarding.bankScreening.login"
                defaultMessage="Logga in på internetbanken"
              />
            </Button>
          </LoginButtonWrap>

          <BankScreeningDisclaimer>
            <FormattedMessage
              id="app.onboarding.bankScreening.disclaimer"
              defaultMessage="I samband med att du verifierar dina bankuppgifter upplåter du information till Brocc om dina samtliga konton och transaktioner. Brocc förbehåller sig rätten att nyttja denna information vid kreditbedömning och granskning av ansökan."
            />
          </BankScreeningDisclaimer>

          <Styles.MobileAttentionBox>
            <LoanPerksBox estimatedCashBack={data.estimated_cashback} />
          </Styles.MobileAttentionBox>
        </>
      )}
    </Styles.Container>
  )
}

export default BankScreening
