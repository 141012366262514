import styled from "styled-components"
import { motion } from "framer-motion"
import { flex } from "config/mixins"
import { mediaQueries } from "Styles/styleGlobal"

export const DrawerWrapper = styled(motion.div)`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: flex;
  bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 98;
  background: rgba(0, 0, 0, 0.1);

  @media screen and (max-height: ${mediaQueries.medium}) {
    overflow: auto;
  }
`
export const Drawer = styled(motion.div)`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: flex;
  background-color: var(--white);
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  justify-content: flex-start;
  gap: 2em;
  width: 500px;
  flex-direction: column;
  z-index: 99;
  padding: 2em;
  overflow: scroll;

  @media screen and (max-width: 500px) {
    width: 100%;
    padding: 1em;
  }

  @media screen and (max-height: ${mediaQueries.medium}) {
    height: 50rem;
  }
`
export const CloseBtnWrapper = styled.div`
  ${flex.center}
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--light-gray);
  cursor: pointer;
  svg path {
    stroke: var(--dark-gray);
    stroke-width: 5;
  }
`
