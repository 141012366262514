import { FC, useState, useCallback, useRef } from "react"
import { Document, pdfjs } from "react-pdf"
import { FormattedMessage } from "react-intl"
import IconFactory from "UI/IconFactory"

import Button from "UI/Button"
import Loading from "UI/Loading"
import { useFileDownload } from "Hooks/API/useFileDownload"
import { getDepositAgreementPdf } from "ApiServices/depositServices"
import { DEPOSIT_AGREEMENT_PDF } from "Constants/fileNames"
import testFile from "./testFile.pdf"
import "react-pdf/dist/esm/Page/TextLayer.css"
import { DocumentViewProps, IAgreementData } from "./DocumentView.types"

import * as S from "./DocumentView.styles"
import CustomPage from "./CustomPage"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const DocumentView: FC<DocumentViewProps> = ({ id, onSignDocument }) => {
  const parentRef = useRef(null)

  const [currentPage, setCurrentPage] = useState(1)
  const [documentData, setDocumentData] = useState<IAgreementData>({})

  const { fileDownloadQuery, saveFile } = useFileDownload({
    fetchFile: getDepositAgreementPdf,
    fileId: id,
    fileName: DEPOSIT_AGREEMENT_PDF,
    enabled: true,
    saveFileOnLoad: false,
  })

  const handleSaveFile = () => {
    if (fileDownloadQuery.data) {
      saveFile(fileDownloadQuery.data)
    }
  }

  const onDocumentLoadSuccess = useCallback(
    (data: IAgreementData) => setDocumentData(data),
    [setDocumentData]
  )

  if (fileDownloadQuery.isError) {
    return (
      <h3>
        <FormattedMessage
          id="app.mypages.savings.onBoarding.agreement.noData"
          defaultMessage="Det går inte att ladda avtalsdokumentet"
        />
      </h3>
    )
  }

  if (fileDownloadQuery.isLoading || fileDownloadQuery.status !== "success") {
    return (
      <S.LoadingWrapper>
        <Loading />
      </S.LoadingWrapper>
    )
  }

  return (
    <S.DocumentWrap>
      <Document
        onLoadSuccess={onDocumentLoadSuccess}
        file={fileDownloadQuery.data || testFile}
        ref={parentRef}
        options={{
          cMapPacked: true,
          cMapUrl: "cmaps/",
          verbosity: pdfjs?.VerbosityLevel?.ERRORS,
        }}
        loading={
          <S.LoadingWrapper>
            <Loading />
          </S.LoadingWrapper>
        }
      >
        <S.CurrentPage>
          {[...Array(documentData?.numPages).keys()].map((page) => (
            <CustomPage
              key={page}
              page={page + 1}
              setCurrentPage={setCurrentPage}
            />
          ))}
        </S.CurrentPage>
      </Document>
      <S.DocumentNavigation>
        <IconFactory
          data-testid="download"
          onClick={handleSaveFile}
          name="download"
        />
        {documentData?.numPages && (
          <S.PagesCount>{`${currentPage} / ${documentData?.numPages}`}</S.PagesCount>
        )}
        <Button onClick={onSignDocument}>
          <FormattedMessage
            id="app.common.signWithBankID"
            defaultMessage="Signera med BankID"
          />
        </Button>
      </S.DocumentNavigation>
    </S.DocumentWrap>
  )
}

export default DocumentView
