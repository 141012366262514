import { flex } from "config/mixins"
import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const ButtonWrap = styled.div`
  ${flex.center}
  gap: 2em;
  width: 100%;

  @media screen and (max-width: ${mediaQueries.medium}) {
    flex-direction: column-reverse;
    padding-top: 1em;
  }

  a {
    ${flex.center}
    cursor: pointer;
    padding: 13px 25px;
    font-size: ${font.size.base};
    min-height: 50px;
    min-width: 100px;
    border-radius: 30px;
    position: relative;
    transition: all 300ms ease-in-out;
    background: transparent;
    color: var(--black);
    border: 2px solid var(--black);
    font-weight: 700;
    &:hover {
      border-color: var(--dark-yellow);
      color: var(--dark-yellow);
    }
  }
`

export const LoanFooter = styled.div`
  ${flex.alignCenter}
  justify-content: space-between;
  @media screen and (max-width: ${mediaQueries.small}) {
    flex-direction: column-reverse;
    gap: 2em;
    align-items: flex-start;
    padding-top: 0;
  }
`

export const LoanItemWrap = styled.div`
  background: ${(props) => props.theme.infoBox};
  border-radius: 20px;
  min-height: 300px;
  margin-bottom: 48px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

export const FeaturedBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;
  margin-top: 3em;
  @media screen and (max-width: ${mediaQueries.medium}) {
    grid-template-columns: 1fr;
  }
`
