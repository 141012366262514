import { FormattedMessage } from "react-intl"

import Modal from "UI/Modal"
import { SignFlow } from "Components/BankIDAuth/SignFlow/SignFlow"
import { SignFlowMobile } from "Components/BankIDAuth/SignFlowMobile/SignFlowMobile"
import { isMobile } from "Utils"
import { useSignInsuranceAgreement } from "Hooks/API/useLoansApplications"

export const SignInsuranceAgreementModal = ({
  accountID,
  onClose,
  handleUpdatePaymentProtectionInsurance,
  isUpdateInsuranceError,
}: {
  accountID: string
  onClose: () => void
  handleUpdatePaymentProtectionInsurance: (values: {
    withInsurance: boolean
  }) => Promise<unknown>
  isUpdateInsuranceError: boolean
}) => {
  const signProcess = useSignInsuranceAgreement({
    accountID,
    onClose,
    handleUpdatePaymentProtectionInsurance,
    isUpdateInsuranceError,
  })

  let modalBodyJsx = (
    <SignFlow
      qrCode={signProcess.qrCode}
      autostartToken={signProcess.autostartToken}
      isSigning={signProcess.isSigning}
      isError={signProcess.isSignError}
      errorMessage={signProcess.errorMessage}
      isLoading={signProcess.isLoading}
      startSigning={signProcess.startSigning}
      cancelSigning={signProcess.cancelSigning}
    />
  )

  if (isMobile()) {
    modalBodyJsx = (
      <SignFlowMobile
        qrCode={signProcess.qrCode}
        autostartToken={signProcess.autostartToken}
        isLoading={signProcess.isLoading}
        isSigning={signProcess.isSigning}
        isError={signProcess.isSignError}
        errorMessage={signProcess.errorMessage}
        startSigning={signProcess.startSigning}
        cancelSigning={signProcess.cancelSigning}
      />
    )
  }

  return (
    <Modal
      maxWidth="600px"
      title={
        <FormattedMessage
          id="app.common.signWithBankID"
          defaultMessage="Signera med BankID"
        />
      }
      onClick={onClose}
      body={modalBodyJsx}
    />
  )
}
