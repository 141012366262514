import { forwardRef, useCallback, useEffect, useRef, Ref } from "react"
import useCloseOnEsc from "Hooks/UI/useCloseOnEsc"

import Menu from "../Menu"
import { MenuWrapper, Transparent } from "./Sidemenu.styles"

export type SidemenuPropTypes = {
  isOpen: boolean
  closeMenu: () => void
}

const Sidemenu = forwardRef(
  ({ closeMenu, isOpen }: SidemenuPropTypes, ref: Ref<HTMLDivElement>) => {
    useCloseOnEsc(closeMenu)
    const transparentRef = useRef(null)

    const handleClickOutside = useCallback(
      (evt: MouseEvent) => {
        if (
          isOpen &&
          evt?.target instanceof Node &&
          evt?.target.contains(transparentRef.current)
        ) {
          closeMenu()
        }
      },

      [isOpen, closeMenu]
    )

    useEffect(() => {
      document.addEventListener("click", handleClickOutside)

      return () => {
        document.removeEventListener("click", handleClickOutside)
      }
    }, [handleClickOutside])

    return (
      <MenuWrapper
        aria-hidden="true"
        ref={ref}
        initial={{ opacity: 0, right: "-100%" }}
        animate={{ opacity: 1, right: "0%" }}
        exit={{ opacity: 0, right: "-100%" }}
        transition={{ type: "tween" }}
      >
        <Menu />
        <Transparent
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.1 }}
          exit={{ opacity: 0, transition: { duration: 0 } }}
          ref={transparentRef}
          data-testid="background"
        />
      </MenuWrapper>
    )
  }
)

Sidemenu.displayName = "Sidemenu"

export default Sidemenu
