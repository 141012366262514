import { useFormContext } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { SelectField } from "Components/form/SelectField"
import { TextField } from "Components/form/TextField"
import {
  useIncomeOptions,
  useOccupancyOptions,
  useOccupationOptions,
} from "Components/KYC/options"
import { KYCPrivateRequestFormValues } from "Interfaces/KYCInterfaces"
import { RadioGroupField } from "Components/form/RadioGroupField"
import Radio from "UI/Radio"
import KYCCitizenship from "./KYCCitizenship"
import * as S from "../Form.styles"

const CommonFields = () => {
  const intl = useIntl()

  const form = useFormContext<KYCPrivateRequestFormValues>()

  const incomeOptions = useIncomeOptions()
  const occupationOptions = useOccupationOptions()
  const occupancyOptions = useOccupancyOptions()

  const occupation = form.watch("occupation")
  const livingInSweden = form.watch("living_in_sweden")

  const toggleSweden = (value: boolean) => {
    form.setValue("living_in_sweden", value)
  }

  return (
    <S.FormBlock>
      <S.FormBlockTitle>
        <h3>
          <FormattedMessage
            id="app.mypages.investments.onboarding.KYC.private.formGroup.questionsAboutYou"
            defaultMessage="FrÃ¥gor om dig"
          />
        </h3>
      </S.FormBlockTitle>
      <S.FormFieldsBlock>
        <KYCCitizenship />
        <S.InputContainer>
          <label>
            <SelectField
              control={form.control}
              name="income"
              options={incomeOptions}
              aria-label={intl.formatMessage({
                id: "app.mypages.investments.onboarding.KYC.private.incomeSelect.inputYourIncome",
                defaultMessage: "Årsinkomst",
              })}
            />
          </label>
          <TextField
            control={form.control}
            name="work_info"
            placeholder={
              <FormattedMessage
                id="app.mypages.investments.onboarding.KYC.private.input.workInfo"
                defaultMessage="Yrke"
              />
            }
          />
          <label>
            <SelectField
              control={form.control}
              name="occupation"
              options={occupationOptions}
              aria-label={intl.formatMessage({
                id: "app.mypages.investments.onboarding.KYC.private.occupationSelect.chooseOccupation",
                defaultMessage: "Sysselsättning",
              })}
            />
          </label>
          {occupation === "other" && (
            <TextField
              control={form.control}
              name="other_occupation"
              placeholder={
                <FormattedMessage
                  id="app.mypages.investments.onboarding.KYC.private.input.otherOccupation"
                  defaultMessage="Annan sysselsättning"
                />
              }
            />
          )}
        </S.InputContainer>
        <S.ToggleContainer>
          <S.ToggleLabel>
            <FormattedMessage
              id="app.mypages.investments.onboarding.KYC.private.livingInSweden.title"
              defaultMessage="Ã„r du bosatt i och enbart skattskyldig till Sverige?"
            />
            <div>
              <Radio
                name="living_in_sweden-true"
                value="true"
                onChange={() => toggleSweden(true)}
                checked={!!livingInSweden}
                labelText={
                  <FormattedMessage id="app.common.yes" defaultMessage="Ja" />
                }
              />
              <Radio
                name="living_in_sweden-false"
                value="false"
                onChange={() => toggleSweden(false)}
                checked={!livingInSweden}
                labelText={
                  <FormattedMessage id="app.common.no" defaultMessage="Nej" />
                }
              />
            </div>
          </S.ToggleLabel>

          {!livingInSweden && (
            <S.InputContainer>
              <label>
                <SelectField
                  control={form.control}
                  name="occupancy"
                  options={occupancyOptions}
                  aria-label={intl.formatMessage({
                    id: "app.mypages.investments.onboarding.KYC.private.input.occupancy",
                    defaultMessage: "Land dÃ¤r du bor nu",
                  })}
                />
              </label>
              <TextField
                control={form.control}
                name="birthplace_citizenship"
                placeholder={
                  <FormattedMessage
                    id="app.mypages.investments.onboarding.KYC.private.input.birthplaceCitizenship"
                    defaultMessage="Födelseort och medborgarskap"
                  />
                }
              />

              <TextField
                control={form.control}
                name="tax_domicile"
                placeholder={
                  <FormattedMessage
                    id="app.mypages.investments.onboarding.KYC.private.input.taxDomicile"
                    defaultMessage="Skatterättslig hemvist"
                  />
                }
              />
              <TextField
                control={form.control}
                name="tax_identification_number"
                placeholder={
                  <FormattedMessage
                    id="app.mypages.investments.onboarding.KYC.private.input.taxIdentificationNumber"
                    defaultMessage="Utländskt skatteregistreringsnummer"
                  />
                }
              />
            </S.InputContainer>
          )}

          <S.ToggleLabel>
            <FormattedMessage
              id="app.mypages.investments.onboarding.KYC.private.taxableUSA.title"
              defaultMessage="Är du skatteskyldig i USA på grund av medborgarskap eller annan
      anknytning?"
            />
            <div>
              <RadioGroupField
                control={form.control}
                transformValueToString={String}
                name="taxable_usa"
                options={[
                  {
                    label: {
                      id: "app.common.yes",
                      defaultMessage: "Ja",
                    },
                    value: true,
                  },
                  {
                    label: {
                      id: "app.common.no",
                      defaultMessage: "Nej",
                    },
                    value: false,
                  },
                ]}
              />
            </div>
          </S.ToggleLabel>
        </S.ToggleContainer>
      </S.FormFieldsBlock>
    </S.FormBlock>
  )
}

export default CommonFields
