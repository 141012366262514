import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

interface BanksListContainerProps {
  isBanksList?: boolean
}

export const BanksListContainer = styled.div<BanksListContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 0.5em;
  ${(p) => {
    if (p.isBanksList) {
      return `
        height: 27rem;
        overflow: auto;
      `
    }

    return ``
  }}

  p {
    margin-bottom: 0;
    color: gray;
  }

  @media screen and (max-height: ${mediaQueries.medium}) {
    height: 12rem;
  }
`
