import { cloneElement, FC } from "react"

import { icons } from "./icons"
import { IconFactoryProps } from "./IconFactory.types"

const IconFactory: FC<IconFactoryProps> = ({ name, className, ...props }) => {
  const icon = icons[name]

  if (!icon) return null

  return cloneElement(icon, {
    className: `icon icon-${name} ${className || ""}`.trimEnd(),
    ...props,
  })
}

export default IconFactory
