import { FC, useState, ChangeEvent, useCallback } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { yupResolver } from "@hookform/resolvers/yup"

import Button from "UI/Button"
import { OtherActorFormProps } from "Interfaces/iceingInterfaces"
import { SelectField } from "Components/form/SelectField"
import { TextField } from "Components/form/TextField"
import LoanCollectionFileUpload from "./FileUpload/LoanCollectionFileUpload"
import { OtherBankSchema } from "./OtherActorForm.schema"
import { OtherActorFormValues } from "./OtherActorFormValues.types"
import {
  ACCOUNT_TYPE_OPTIONS,
  LOAN_TYPE_OPTIONS,
  ANNAN_AKTOR,
} from "../Constants"
import { SelectedLoans } from "../LoansList/LoanItem/LoanItem.types"
import { transformToSelectedLoan } from "../Utils"

const allowedTypes = [
  "application/pdf",
  "image/jpg",
  "image/jpeg",
  "image/jpe",
  "image/png",
]

const OtherActorForm: FC<OtherActorFormProps> = ({
  onAddLoan,
  onReset,
  onUploadFiles,
  bankName,
}) => {
  const [files, setFiles] = useState<File[]>([])
  const [uploadInvalid, setUploadInvalid] = useState(false)

  const intl = useIntl()

  const form = useForm<OtherActorFormValues>({
    resolver: yupResolver(OtherBankSchema),
    values: {
      bankName: bankName === ANNAN_AKTOR ? "" : bankName,
      bankAccount: "",
      accountType: "BG",
      loanType: "Lån",
      paymentReference: "",
      totalAmount: 0,
      interestRate: 0,
      monthlyCost: 0,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const handleNewFileUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()

      const enteredFiles = event.target.files ? [...event.target.files] : []
      let isInvalid = false

      try {
        enteredFiles.forEach((file) => {
          if (!allowedTypes.includes(file.type)) {
            isInvalid = true

            throw new Error("Tillåtna filtyper: png, jpg, jpeg, jpe, pdf")
          }
        })
      } catch (error) {
        if (isInvalid && error instanceof Error) {
          setUploadInvalid(true)
        }
      }

      if (!isInvalid) {
        setFiles(enteredFiles)
      }
    },
    []
  )

  const onSubmit: SubmitHandler<OtherActorFormValues> = (values) => {
    const loan: SelectedLoans = transformToSelectedLoan(values)

    onAddLoan([...[loan]])
    onUploadFiles(files)
    onReset()
  }

  const numberFieldRules = { valueAsNumber: true }

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      noValidate
      data-cy="other-actor-form"
    >
      <TextField
        required
        control={form.control}
        name="bankName"
        placeholder={intl.formatMessage({
          id: "app.mypages.iceing.bankName",
          defaultMessage: "",
        })}
      />
      <label>
        <SelectField
          required
          control={form.control}
          name="loanType"
          options={LOAN_TYPE_OPTIONS}
          aria-label={intl.formatMessage({
            id: "app.mypages.iceing.loanType",
            defaultMessage: "",
          })}
        />
      </label>
      <label>
        <SelectField
          required
          control={form.control}
          name="accountType"
          options={ACCOUNT_TYPE_OPTIONS}
          aria-label={intl.formatMessage({
            id: "app.mypages.iceing.accountType",
            defaultMessage: "",
          })}
        />
      </label>
      <TextField
        required
        control={form.control}
        name="bankAccount"
        placeholder={intl.formatMessage({
          id: "app.mypages.iceing.bankAccount",
          defaultMessage: "",
        })}
      />
      <TextField
        required
        control={form.control}
        name="paymentReference"
        placeholder={intl.formatMessage({
          id: "app.mypages.iceing.paymentReference",
          defaultMessage: "",
        })}
      />
      <TextField
        required
        type="number"
        rules={numberFieldRules}
        control={form.control}
        name="totalAmount"
        placeholder={intl.formatMessage({
          id: "app.mypages.iceing.totalAmount",
          defaultMessage: "",
        })}
      />
      <TextField
        required
        type="number"
        rules={numberFieldRules}
        control={form.control}
        name="interestRate"
        placeholder={intl.formatMessage({
          id: "app.mypages.iceing.interestRate",
          defaultMessage: "",
        })}
      />
      <TextField
        required
        type="number"
        rules={numberFieldRules}
        control={form.control}
        name="monthlyCost"
        placeholder={intl.formatMessage({
          id: "app.mypages.iceing.monthlyCost",
          defaultMessage: "",
        })}
      />
      <br />
      <LoanCollectionFileUpload
        uploadInvalid={uploadInvalid}
        filesNumber={files.length}
        onUpdateFiles={handleNewFileUpload}
      />
      <br />
      <Button
        type="submit"
        style={{ width: "100%" }}
        disabled={!form.formState.isValid || files.length === 0}
      >
        <FormattedMessage id="app.mypages.iceing.collect.other.creditor.form" />
      </Button>
    </form>
  )
}

export default OtherActorForm
