import { memo, FC } from "react"
import { CheckboxPropTypes } from "./Checkbox.types"
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
} from "./Checkbox.styles"

const Checkbox: FC<CheckboxPropTypes> = ({
  className,
  checked,
  color = "gray",
  size = "medium",
  name,
  value,
  onChange,
  priority,
  ...props
}) => (
  <CheckboxContainer className={className} data-testid="checkbox">
    <HiddenCheckbox
      {...props}
      checked={checked || false}
      name={name}
      value={value}
      id={value}
      onChange={onChange}
    />
    <StyledCheckbox
      checked={checked || false}
      id={value}
      color={color}
      size={size}
      priority={priority || false}
    >
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" stroke="black" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

export default memo(Checkbox)
