import { Urls } from "Constants/urls"
import PageLayout from "Layouts/PageLayout"
import ServiceLayout from "Layouts/ServiceLayout"
import Accounts from "Pages/Authenticated/Savings/Accounts/Accounts"
import SavingsSettings from "Pages/Authenticated/Savings/Settings/SavingsSettings"
import SavingsDetails from "Pages/Authenticated/Savings/Details/SavingsDetails"
import SavingsHome from "Pages/Authenticated/Savings/Home/SavingsHome"
import SavingsOnboarding from "Pages/Authenticated/Savings/OnBoarding/SavingsOnboarding"

const savingsRoutes = {
  path: Urls.Savings,
  element: <PageLayout type="savings" />,
  children: [
    {
      element: <Accounts />,
      index: true,
    },
    {
      path: Urls.SavingsOnboarding,
      element: <SavingsOnboarding />,
    },
    {
      element: <ServiceLayout type="savings" />,
      children: [
        {
          path: `${Urls.SavingsAccount}/${Urls.SavingsAccountOverview}`,
          element: <SavingsHome />,
          index: true,
        },
        {
          path: `${Urls.SavingsAccount}/${Urls.SavingsAccountSettings}`,
          element: <SavingsSettings />,
        },
        {
          path: `${Urls.SavingsAccount}/${Urls.SavingsAccountDetails}`,
          element: <SavingsDetails />,
        },
      ],
    },
  ],
}

export default savingsRoutes
