import { MessageDescriptor } from "react-intl"

export const loginErrorMessages: Record<string, MessageDescriptor> = {
  already_in_progress: {
    id: "app.login.error.alreadyInProgress",
    defaultMessage: "Åtgärden avbruten. Försök igen.",
  },
  invalid_parameters: {
    id: "app.login.error.checkSSN",
    defaultMessage: "Ogiltigt personnummer. Prova igen.",
  },
  internal_error: {
    id: "app.login.error.generalError",
    defaultMessage:
      "Något gick fel. Prova igen senare eller kontakta oss om problemet kvarstår.",
  },
  retry: {
    id: "app.login.error.retry",
    defaultMessage: "Internt tekniskt fel. Försök igen.",
  },
  "client-err": {
    id: "app.login.error.retry",
    defaultMessage: "Internt tekniskt fel. Försök igen.",
  },
  expired_transaction: {
    id: "app.login.error.expiredTransaction",
    defaultMessage:
      "BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din Bank. Försök sedan igen.",
  },
  "failed to load xml string": {
    id: "app.login.error.retry",
    defaultMessage: "Internt tekniskt fel. Försök igen.",
  },
}
