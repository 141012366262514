import styled, { css } from "styled-components"
import { mediaQueries, font } from "Styles/styleGlobal"
import {
  InputWrapPropTypes,
  StyledInputPropTypes,
  PlaceholderPropTypes,
} from "./Input.types"

export const InputWrap = styled.div<InputWrapPropTypes>`
  display: flex;
  flex-direction: column;
  position: relative;

  &:focus-within label {
    top: 6px;
    transform: translate(0, 13px) scale(0.65);
    width: max-content;
  }

  .active {
    top: 6px;
    transform: translate(0, 13px) scale(0.65);
    width: max-content;
  }
`

export const StyledInput = styled.input<StyledInputPropTypes>`
  border-radius: 6px;
  background: var(--white);
  border: 1px solid var(--light-gray);
  width: 100%;
  height: 100%;
  padding: 1em;
  font-size: ${font.size.lg};
  min-height: 60px;
  margin: 0.5em 0;
  padding-bottom: 0px;
  appearance: none;

  ${(p) => [
    p.error && `border-color: var(--brand-danger);`,
    p.sellAssets &&
      css`
        width: 57px;
        height: 32px;
        text-align: right;
        font-size: inherit;
        text-align: center;
      `,
  ]}

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--light-yellow);
    z-index: 2;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    padding: 0.7em 1em 0;
  }
`

export const Placeholder = styled.label<PlaceholderPropTypes>`
  color: var(--dark-gray);
  pointer-events: none;
  position: absolute;
  left: 1em;
  top: 0px;
  transform: translate(0, 26px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  font-weight: normal !important;
  z-index: 3;
  line-height: 1.5em;
  font-size: ${font.size.lg};
  @media screen and (max-width: ${mediaQueries.small}) {
    font-size: ${font.size.lg};
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    font-size: ${font.size.base};
  }
`

export const InputValidationMessage = styled.div`
  font-size: ${font.size.xs};
  color: var(--brand-danger);
`
