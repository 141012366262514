import type { FC } from "react"
import { useLocation } from "react-router-dom"
import { IScrappingLoanBank, SelectBank } from "Interfaces/iceingInterfaces"
import { ICING_EVENTS } from "Events/List"
import { publish } from "../../../Events"
import BankIcon from "./BankIcons/BankIcon"
import { banksInformation } from "./BankIcons/BanksInformation"
import { ANNAN_AKTOR } from "../Constants"
import * as S from "./Bank.styles"

const Bank: FC<
  Pick<IScrappingLoanBank, "bankId" | "bankName" | "loginStrategies"> &
    SelectBank
> = ({ bankId, bankName, loginStrategies, onSelectedBank }) => {
  const location = useLocation()
  const fullBankName = banksInformation[bankName.toLowerCase()]
    ? banksInformation[bankName.toLowerCase()].description.fullName
    : bankName

  const handleSelectedBank = () => {
    publish(ICING_EVENTS.CLICK_SELECTED_BANK_BUTTON, {
      bankName,
      loginStrategies,
      standalone: location.pathname === "/iceing",
    })
    onSelectedBank({ bankId, bankName, loginStrategies })
  }

  return (
    <S.Bank
      onClick={handleSelectedBank}
      isAnnanKreditgivare={bankName === ANNAN_AKTOR}
    >
      <BankIcon bankName={bankName} />
      <span>{fullBankName}</span>
    </S.Bank>
  )
}

export default Bank
