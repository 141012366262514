import { FC, ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import TinkDeposit from "./TinkDeposit"
import BankgiroTransfer from "./BankgiroTransfer"
import AutogiroDeposit from "./AutogiroDeposit"
import {
  AutogiroDepositProps,
  BankgiroTransferPropTypes,
  TinkDepositPropTypes,
} from "./SavingsDeposit.types"

type PaymentMethodsType = {
  [key in PaymentMethod]:
    | FC<BankgiroTransferPropTypes>
    | FC<TinkDepositPropTypes>
    | FC<AutogiroDepositProps>
}

export type PaymentMethod = "tink" | "autogiro" | "bankgiro"

export const PaymentMethods: PaymentMethodsType = {
  tink: TinkDeposit,
  autogiro: AutogiroDeposit,
  bankgiro: BankgiroTransfer,
}

export const paymentMethodNames: {
  method: PaymentMethod
  name: ReactNode
  description: ReactNode
}[] = [
  {
    method: "tink",
    name: (
      <FormattedMessage
        id="app.mypages.savings.deposit.tink.heading"
        defaultMessage="Internetbanken"
      />
    ),
    description: (
      <FormattedMessage
        id="app.mypages.savings.deposit.tink.description"
        defaultMessage="Sätt in pengar direkt via din internetbank med BankID."
      />
    ),
  },
  {
    method: "autogiro",
    name: (
      <FormattedMessage
        id="app.mypages.savings.deposit.autogiro.heading"
        defaultMessage="Autogiro"
      />
    ),
    description: (
      <FormattedMessage
        id="app.mypages.savings.deposit.autogiro.description"
        defaultMessage="Gör snabbt och enkelt insättningar med autogiro."
      />
    ),
  },
  {
    method: "bankgiro",
    name: (
      <FormattedMessage
        id="app.mypages.savings.deposit.bankgiroTransfer.heading"
        defaultMessage="Banköverföring"
      />
    ),
    description: (
      <FormattedMessage
        id="app.mypages.savings.deposit.bankgiroTransfer.description"
        defaultMessage="Gör en insättning med en bankgiro överföring."
      />
    ),
  },
]
