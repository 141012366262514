import { FC } from "react"
import { FormattedMessage } from "react-intl"

import { LoadingPropTypes } from "./Loading.types"
import { StyledSpinner, Centered } from "./Loading.styles"

const Loading: FC<LoadingPropTypes> = ({
  isCentered = false,
  size,
  withMessage = false,
}) => {
  if (isCentered) {
    return (
      <Centered data-testid="spinner">
        {withMessage && (
          <p>
            <FormattedMessage
              id="app.common.longLoading"
              defaultMessage="Inläsningen kan ta upp till 1 minut..."
            />
          </p>
        )}
        <StyledSpinner viewBox="0 0 50 50" className="loading" size={size}>
          <circle className="path" cx="25" cy="25" r="20" strokeWidth="4" />
        </StyledSpinner>
      </Centered>
    )
  }
  return (
    <StyledSpinner
      viewBox="0 0 50 50"
      className="loading"
      size={size}
      data-testid="spinner"
    >
      <circle className="path" cx="25" cy="25" r="20" strokeWidth="4" />
    </StyledSpinner>
  )
}

export default Loading
