import { useEffect } from "react"

const useHandleKeyPress = (
  callback: (event: React.KeyboardEvent | KeyboardEvent) => void
) => {
  useEffect(() => {
    if (callback && typeof callback === "function") {
      window.addEventListener("keydown", callback)
    }
    return () => {
      if (callback && typeof callback === "function") {
        window.removeEventListener("keydown", callback)
      }
    }
  }, [callback])
}

export default useHandleKeyPress
