import { MessageDescriptor } from "react-intl"

export type ErrorLayoutType =
  | "savings"
  | "investments"
  | "loan"
  | "reports"
  | "invoices"

export const errorMessages: Record<ErrorLayoutType, MessageDescriptor> = {
  savings: {
    id: "app.mypages.error.depositServices.useGetDepositServiceByID",
    defaultMessage:
      "Kan inte få tillgång till den begärda insättningstjänsten. Försök igen",
  },
  investments: {
    id: "app.mypages.error.investment.getInvestmentServiceByID",
    defaultMessage: "Kan inte få den begärda investeringstjänsten. Försök igen",
  },
  loan: {
    id: "app.mypages.error.depositServices.useGetBasicLoanServiceByID",
    defaultMessage:
      "Det går inte att komma åt den begärda lånetjänsten. Försök igen",
  },
  reports: {
    id: "app.mypages.reports.noReports",
    defaultMessage: "Inga rapporter att visa",
  },
  invoices: {
    id: "app.mypages.loans.invoices.noInvoices",
    defaultMessage: "Inga fakturor att visa",
  },
}
