import { FC } from "react"
import { useParams } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { useCreateBankPaymentRequest } from "Hooks/API/useDepositServices"
import { useFeedbackModal } from "Context/modal-context"
import DepositForm from "Components/DepositForm"
import { DepositFormValues } from "Components/DepositForm/DepositForm.types"
import { TinkDepositPropTypes } from "../SavingsDeposit.types"
import * as S from "./TinkDeposit.styles"

const TinkDeposit: FC<TinkDepositPropTypes> = ({
  setIsTinkWindow,
  setPaymentID,
}) => {
  const intl = useIntl()

  const { accountID } = useParams() as { accountID: string }
  const { errorModal } = useFeedbackModal()

  const bankPaymentRequestMutation = useCreateBankPaymentRequest(accountID)

  const handleDeposit = (values: DepositFormValues) => {
    bankPaymentRequestMutation
      .mutateAsync({ amount: +values.amount })
      .then((data) => {
        setPaymentID(data.payment_request_id)
        setIsTinkWindow(true)
      })
      .catch((error) =>
        errorModal(
          error?.response?.data?.message ||
            intl.formatMessage({
              id: "app.mypages.notifications.errorMessage",
            })
        )
      )
  }

  return (
    <S.TinkWrap>
      <S.TinkHeading>
        <FormattedMessage
          id="app.mypages.savings.deposit.tink.heading2"
          defaultMessage="Sätt in pengar via din internet bank"
        />
      </S.TinkHeading>

      <S.TinkDescription>
        <FormattedMessage
          id="app.mypages.savings.deposit.tink.description2"
          defaultMessage="Ange belopp du önskar sätta in via din internetbank. Insättningar tar vanligtvis 1 vardag."
        />
      </S.TinkDescription>

      <DepositForm
        onSubmit={handleDeposit}
        isLoading={bankPaymentRequestMutation.isLoading}
        account="savings"
      />
    </S.TinkWrap>
  )
}

export default TinkDeposit
