import type { FC } from "react"
import { FormattedMessage } from "react-intl"

import RepaymentPeriods from "Components/RepaymentPeriods"
import { formatNumber } from "Utils/formatNumber"
import * as S from "./OverviewBox.styles"

type OverviewBoxPropsType = {
  periodCost: number
  repaymentPeriods: number
  effectiveInterestRate: number
}

const OverviewBox: FC<OverviewBoxPropsType> = ({
  periodCost,
  repaymentPeriods,
  effectiveInterestRate,
}) => {
  return (
    <S.Overview>
      <div>
        <span>
          <FormattedMessage
            id="app.common.monthlyCost"
            defaultMessage="Månadskostnad"
          />
        </span>
        <br />
        <p>
          {formatNumber(periodCost, {
            style: "currency",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </p>
      </div>
      <div>
        <span>
          <FormattedMessage
            id="app.common.loanPeriod"
            defaultMessage="Återbetalningstid"
          />
        </span>
        <br />
        <p>
          <RepaymentPeriods repaymentPeriods={repaymentPeriods} />
        </p>
      </div>
      <div>
        <span>
          <FormattedMessage
            id="app.common.effectiveInterest"
            defaultMessage="Effektiv ränta"
          />
        </span>
        <br />
        <p>
          {formatNumber(effectiveInterestRate, {
            style: "percent",
          })}
        </p>
      </div>
    </S.Overview>
  )
}

export default OverviewBox
