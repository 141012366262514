import styled from "styled-components"

export const SignLoanContractCompleteBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 32px;
  width: 100%;
  padding-bottom: 24px;

  p {
    margin: 0;
  }
`
