import styled from "styled-components"

export const LoginProcessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  margin: 0.5em;
`
export const QrCodeContainer = styled.div`
  display: inherit;
  flex-direction: column;
  text-align: center;

  svg {
    margin-bottom: 1rem;
  }

  div {
    margin: 1rem;
  }
`
