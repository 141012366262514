import styled from "styled-components"

export const TransactionsWrap = styled.div`
  position: relative;
  > span {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
  }
`
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`
