import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const SignFlowMobileBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 32px;
  margin: 0 auto;
  width: 100%;
  padding: 24px 0;

  &.selected-method {
    text-align: center;

    .qr-code {
      align-self: center;
    }
  }

  .error-message {
    margin: 0;
    font-size: ${font.size.xs};
    color: var(--brand-danger);
    text-align: center;
  }

  p {
    margin: 0;
    text-align: center;
  }
`
