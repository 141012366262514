import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import { font, mediaQueries } from "Styles/styleGlobal"
import { flex } from "config/mixins"

type MenuLinkPropTypes = {
  $isOnboarding?: boolean
}

export const LogoWrap = styled.div`
  margin-bottom: 1em;
  display: none;

  svg path {
    fill: ${(p) => p.theme.fontColor};
  }

  @media screen and (min-width: ${mediaQueries.medium}) {
    display: flex;
  }
`

export const StyledMenu = styled.div`
  ${flex.columnAlignCenter}
  margin: 0;
  min-width: 250px;
  position: relative;
  padding: 0 40px;
  height: 100%;
  @media screen and (max-width: ${mediaQueries.medium}) {
    padding-top: calc(7em - 75px);
    margin-top: 75px;
    height: auto;
  }
  a.logo {
    svg {
      width: 100px;
    }
  }
  a {
    color: ${(p) => p.theme.fontColor};
    font-size: ${font.size.lg};
    padding: 0.5em 0.5em 0.5em 0;
    margin: 0.25em 0;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 1em;
    width: 100%;
    &.active {
      color: var(--dark-yellow);
    }
    svg {
      width: 26px;
      height: 26px;
    }
  }
`

export const MenuLink = styled(motion.span)<MenuLinkPropTypes>`
  ${flex.alignCenter}
  text-decoration: none;
  color: ${(p) => p.theme.fontColor};
  ${(p) =>
    p.$isOnboarding &&
    css`
      align-items: flex-start;
      line-height: 23px;
    `}
  svg {
    margin-right: 0.5em;
    width: 20px;
  }
`

export const SavingsList = styled(motion.div)`
  padding: 0 0 1.25em 0.8em;
  a {
    span {
      display: grid !important;
      grid-template-columns: 2em 1fr;
      align-items: center;
    }
  }
`

export const DarkModeWrap = styled.label`
  ${flex.columnCenter}
  width: 100%;
`

export const menuVariants = {
  opened: {
    height: "auto",
    width: "auto",
    transition: {
      duration: 0.1,
    },
  },
  closed: {
    height: "0px",
    width: "auto",
    transition: {
      duration: 0.1,
    },
  },
}

export const linkVariants = {
  opened: {
    display: "flex",
  },
  closed: {
    display: "none",
  },
}

export const ActivateAccountMessageWrap = styled.div``

export const ActivateAccountLink = styled.div`
  margin-top: 1.25em;
  width: calc(100% + 1.7em);
`

export const DropdownWrapper = styled.div`
  width: 80%;
`

export const LinksWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin: auto;
  margin-top: 3em;
  a {
    transition: 0.3s ease-in-out all;
    &:hover {
      color: var(--dark-yellow);
    }
  }
`

export const AccountLink = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0;
  width: 100%;
  min-height: 80px;
  margin: auto 0 0;
  @media screen and (max-width: ${mediaQueries.medium}) {
    margin: auto 0 2em;
  }
  a {
    display: grid;
    grid-template-columns: 40px 1fr;
    font-size: ${font.size.base};
    transition: 0.3s ease-in-out all;
    &.active {
      color: var(--black);
    }
    &:hover {
      color: var(--dark-yellow);
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
`

export const AccountIcon = styled.div`
  ${flex.center}
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--light-yellow);
  color: var(--black);
`

export const Name = styled.div`
  ${flex.column}
  span:first-of-type {
    font-weight: bold;
    font-size: ${font.size.base};
  }
  span {
    line-height: 1.3em;
  }
`

export const Subname = styled.span`
  font-size: ${font.size.sm};
  color: var(--dark-gray);
`
