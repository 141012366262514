import { FC, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useGetCustomer } from "Hooks/API/useCustomer"
import { SAVINGS_ONBOARDING } from "Constants/storage"
import {
  getParsedStorageItemOrDefault,
  setStorageItem,
} from "Utils/localStorage"
import IconFactory from "UI/IconFactory"
import * as S from "./AccountVerification.styles"
import { SavingsOnboardingData } from "../SavingsOnboarding.types"
import { VerificationOption } from "./AccountVerification.styles"
import ManualVerification from "./ManualVerification"
import VerificationSuccess from "./VerificationSuccess"

type AccountVerificationPropTypes = {
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const AccountVerification: FC<AccountVerificationPropTypes> = ({ setStep }) => {
  const [showTink, setShowTink] = useState<string>("tink")
  const { accountID: uid } = useParams() as { accountID: string }
  const { data: customerData, isSuccess } = useGetCustomer()

  const [savingsOnboardingData] = useState(() =>
    getParsedStorageItemOrDefault<SavingsOnboardingData>(SAVINGS_ONBOARDING, {})
  )

  const handleNextStep = useCallback(() => {
    const updatedStatus: SavingsOnboardingData = {
      ...savingsOnboardingData,
      [uid]: {
        ...savingsOnboardingData[uid],
        brocc_has_account_verified: true,
      },
    }
    setStorageItem(SAVINGS_ONBOARDING, updatedStatus)
    setStep((prev) => prev + 1)
  }, [setStep, savingsOnboardingData, uid])

  const handleClick = useCallback(() => {
    setShowTink(showTink === "tink" ? "manual" : "tink")
  }, [showTink])

  useEffect(() => {
    document.querySelector("main")?.scrollTo({ top: 0 })
  })

  return (
    <S.AccountVerificationWrap>
      {isSuccess && showTink === "tink" && (
        <VerificationSuccess
          legalEntityNumber={customerData.legal_entity_number}
          uid={uid}
          handleNextStep={handleNextStep}
        />
      )}
      {showTink === "manual" && (
        <ManualVerification handleNextStep={handleNextStep} />
      )}

      <VerificationOption>
        <button onClick={handleClick}>
          <FormattedMessage
            id="app.mypages.savings.onBoarding.accountVerification.change"
            defaultMessage="Ändra verifieringsmetod"
          />
          <IconFactory name="chevronRight" />
        </button>
      </VerificationOption>
    </S.AccountVerificationWrap>
  )
}

export default AccountVerification
