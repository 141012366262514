export function setCurrencyByLocale(locale: string) {
  switch (locale) {
    case "dk-da":
    case "dk-en":
      return "DKK"
    case "no-no":
    case "no-en":
      return "NOK"
    case "fi-fi":
    case "fi-en":
      return "EUR"
    case "sv-se":
    case "sv-en":
    default:
      return "SEK"
  }
}
