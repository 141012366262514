import { FC, memo } from "react"
import { RangePropTypes } from "./Range.types"
import { Slider } from "./Range.styles"

const Range: FC<RangePropTypes> = ({
  value,
  elRef,
  onChange,
  min,
  max,
  step,
  name,
  ...rest
}) => (
  <Slider
    {...rest}
    type="range"
    min={min}
    max={max}
    step={step}
    name={name}
    value={value}
    ref={elRef}
    onChange={onChange}
  />
)

export default memo(Range)
