import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const CheckboxLink = styled.a`
  display: block;
  margin: 1em 0;
  color: var(--black);
  font-weight: 700;
  text-decoration: underline;
`

export const CheckboxErrorMsg = styled.span`
  color: var(--brand-danger);
  display: block;
  font-size: ${font.size.sm};
  padding-left: 1.7em;
`
export const CheckboxLabel = styled.label`
  display: block;
  margin: 0.5em 0;

  span {
    margin-left: 10px;
    a {
      font-weight: 500;
      color: var(--black);
      text-decoration: underline;
    }
  }
  button {
    font-weight: 500;
    font-size: inherit;
    text-decoration: underline;
    padding: 0;
  }
`

export const ModalButton = styled.button`
  color: var(--black);
  text-transform: lowercase;
`
