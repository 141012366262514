import { flex } from "config/mixins"
import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const SavingsDepositWrap = styled.div`
  form {
    button {
      margin-top: 20px;
    }
  }
`

export const SavingsDepositHeader = styled.h1`
  margin-bottom: 1em;
`

export const SavingsDepositBody = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2em;
  @media screen and (max-width: ${mediaQueries.large}) {
    grid-template-columns: repeat(1, 100%);
  }
`
export const DepositsContentHeader = styled.h3`
  margin: 0;
  padding: 0 0 0.5em 0;
  font-size: ${font.size.xl};
`

export const DepositContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
`

export const DepositMethod = styled.button`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 24px;
  gap: 1em;
  width: 100%;
  padding: 1.5em;
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  text-align: left;
  svg {
    path {
      stroke: var(--black);
    }
  }
  &:hover {
    box-shadow: 0 0 5px var(--light-gray);
  }
`
export const DepositMethodNameWrap = styled.div``

export const DepositMethodName = styled.h4`
  margin: 0 0 5px;
  font-weight: 500;
  font-size: ${font.size.lg};
  color: var(--black);
`

export const DepositMethodDecription = styled.p`
  margin: 0;
  font-size: ${font.size.base};
  color: var(--dark-gray);
  line-height: 22px;
`

export const LoadingWrapper = styled.div`
  ${flex.center}
  flex: 1;
`

export const DepositLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
  padding-left: 5px;
`

export const DepositAugorioWrap = styled.div`
  display: inline-block;
`
export const SavingsDepositDescription = styled.p`
  margin: 1em 0 2em;
`

export const BackButton = styled.button`
  ${flex.alignCenter}
  width: max-content;
  gap: 4px;
  padding: 0;
  text-align: left;
  color: black;
  font-size: 16px;
  font-weight: 500;

  .icon {
    transform: translateY(2px) rotateZ(180deg);
    transform-origin: center;
    path {
      stroke: currentColor;
    }
  }
`
