import { useCallback, useEffect, useRef, useState } from "react"
import useHandleKeyPress from "../useHandleKeyPress"
import findTabbable from "./helpers"

const TAB_KEY = "Tab"
const optionsDefault = { focusOnRender: true, returnFocus: true }

function isWithinCurrentElementScope(elementList: HTMLElement[] = []) {
  const currentActiveElement = document.activeElement as HTMLElement
  return elementList.includes(currentActiveElement)
}

export default function useTrapFocus(isOpen: boolean, opts = {}) {
  const options = opts ? { ...optionsDefault, ...opts } : optionsDefault
  const [previouslyOpen, setPreviouslyOpen] = useState(isOpen)
  const ref = useRef() as React.MutableRefObject<HTMLElement>
  const previouslyFocusedElementRef =
    useRef() as React.MutableRefObject<HTMLElement>

  const [tabbableElements, setTabbableElements] = useState<HTMLElement[]>([])
  // Handle initial focus of the referenced element, and return focus to previously focused element on cleanup
  // and find all the tabbable elements in the referenced element

  useEffect(() => {
    const { current } = ref
    if (current) {
      const focusableChildNodes = findTabbable(current)
      if (options.focusOnRender) {
        current.focus()
      }

      setTabbableElements(focusableChildNodes)
    }
    return () => {
      const { current: elem } = previouslyFocusedElementRef
      if (elem instanceof HTMLElement && options.returnFocus) {
        elem.focus()
      }
    }
  }, [options.focusOnRender, options.returnFocus, ref, setTabbableElements])

  useEffect(() => {
    if (isOpen) {
      setPreviouslyOpen(true)
    }

    if (!isOpen && previouslyOpen) {
      const { current } = previouslyFocusedElementRef
      if (current instanceof HTMLElement && options.returnFocus) {
        current.focus()
      }
    }
  }, [isOpen, options.returnFocus, previouslyOpen, setPreviouslyOpen])

  const handleUserKeyPress = useCallback(
    (event: React.KeyboardEvent<Element> | KeyboardEvent) => {
      const { key, shiftKey } = event
      const first = tabbableElements[0]
      const last = tabbableElements[tabbableElements.length - 1]
      const currentActiveElement = document.activeElement
      // Scope current tabs to current root element
      if (isWithinCurrentElementScope([...tabbableElements, ref.current])) {
        if (key === TAB_KEY) {
          if (
            currentActiveElement === first ||
            currentActiveElement === ref.current
          ) {
            // move focus to last element if shift+tab while currently focusing the first tabbable element
            if (shiftKey) {
              event.preventDefault()
              last.focus()
            }
          }
          if (currentActiveElement === last) {
            // move focus back to first if tabbing while currently focusing the last tabbable element
            if (!shiftKey) {
              event.preventDefault()
              first.focus()
            }
          }
        }
      }
    },
    [ref, tabbableElements]
  )
  useHandleKeyPress(handleUserKeyPress)

  return [ref, previouslyFocusedElementRef]
}
