import type { FC, ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { useLocation } from "react-router-dom"

import EmptyLoans from "Layouts/EmptyService/EmptyLoans"
import Button from "UI/Button"
import EmptySavings from "Layouts/EmptyService/EmptySavings"
import CreateDepositApplication from "Pages/Authenticated/Savings/Accounts/CreateDepositApplication"
import type { PromoItemType, PromoWrapPropTypes } from "./PromoWrap.types"
import PromoList from "./PromoList"
import * as S from "./PromoWrap.styles"

const emptyPromo: {
  [key in PromoItemType]?: ReactNode
} = {
  loans: <EmptyLoans />,
  savings: <EmptySavings />,
}

const PromoWrap: FC<PromoWrapPropTypes> = ({
  type,
  titleId,
  secondTitleId,
  descriptionId,
  descriptionList,
  accountTitleId,
  linkText,
  data,
  link,
}) => {
  const { pathname } = useLocation()

  const showCreateApplication = type === "savings" && pathname === "/"

  const defaultPromo = emptyPromo[type] || (
    <>
      <S.InfoTile>
        <S.ActiveTitle>
          <FormattedMessage id={secondTitleId} />
        </S.ActiveTitle>
      </S.InfoTile>
      <S.ActiveDescription>
        <FormattedMessage id={descriptionId} />
      </S.ActiveDescription>
      {descriptionList && (
        <S.DescriptionList>
          {descriptionList.map((item, i) => (
            <S.DescriptionItem key={i + item}>
              <FormattedMessage id={item} />
            </S.DescriptionItem>
          ))}
        </S.DescriptionList>
      )}
      <Button
        component="a"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage id={linkText} />
      </Button>
    </>
  )

  return (
    <S.PromoWrap
      noData={!data || data.length === 0}
      type={type}
      data-testid="promo-wrap"
    >
      {data && data.length > 0 ? (
        <>
          <S.InfoTile
            style={showCreateApplication ? { marginBottom: "5px" } : undefined}
          >
            {titleId && (
              <S.ActiveTitle>
                <FormattedMessage id={titleId} />
              </S.ActiveTitle>
            )}
            {showCreateApplication && <CreateDepositApplication />}
          </S.InfoTile>
          <PromoList accountTitleId={accountTitleId} type={type} data={data} />
        </>
      ) : (
        defaultPromo
      )}
    </S.PromoWrap>
  )
}

export default PromoWrap
