import axios from "ApiServices/axios"
import { Api } from "Constants/api"
import type { AxiosError, AxiosResponse } from "axios"
import type {
  ILoanApplicantDetails,
  ILoanApplication,
  ILoanApplications,
  LoanApplicationAgreementSignature,
  LoanApplicationForm,
  LoanApplicationSignatureStatus,
  LoanPurposeType,
} from "Interfaces/loanApplicationInterfaces"
import type { IAMLQuestionsRequest } from "Pages/Unauthenticated/Onboarding/Steps/Accept/AMLQuestions/AMLQuestions.types"

type ApplicantType = {
  legal_entity_number: string
  email: string
  tel: string
  customer_origin?: string
  applicant_detail?: ILoanApplicantDetails
  bank_account_screening?: BankAccountScreening
}

type ApplicantUpdateType = {
  applicant_detail: {
    employer?: string
    telephone_to_employer?: string
    occupation_from_year?: number
    occupation_from_month?: number
  }
}

export type BankAccountPayload = {
  bank_clearing_number: string
  bank_account_number: string
}

export type DocumentationActivityType =
  | "require_one_payslip"
  | "require_three_payslips"
  | "require_employment_agreement"
  | "require_certificate_of_employment"
  | "require_company_tax_return"
  | "require_existing_loan_redemption"

export type DocumentationType = {
  activity: DocumentationActivityType
  files: File[]
}

export interface IExistingLoan {
  ocr: number
  creditor: string
  payment_to: number
  amount: number
}

export type LoanApplicationPayload = {
  amount: number
  repayment_periods: number
  applicant: ApplicantType
  requested_consolidation_amount?: number | null
  loan_purpose?: LoanPurposeType
  extra_info?: string | null
  source?: string
  co_applicant?: ApplicantType
  existing_loans?: IExistingLoan[]
}

export type BankAccountScreening = {
  income_check_report_id: string
  risk_insights_report_id: string
  transaction_report_id: string
}

export type LoanApplicationUpdatePayload = {
  loan_purpose?: LoanPurposeType
  applicant?: ApplicantUpdateType
  co_applicant?: ApplicantUpdateType
}

export type BankAccountScreeningPayload = {
  customer_uid: string
  is_independent: boolean
  income_check_report_id: string
  risk_insights_report_id: string
  transaction_report_id: string
}

export interface LoanCollection {
  amount: number
  creditor: string
  interest: number | bigint
  loan_type: string
  monthly_cost: number
  ocr: string | null
  payment_to: string
  to_resolve: boolean
  type: string | null
  verified: boolean
  remaining_term?: number | null
  disbursement_date?: string | null
  original_amount?: number | null
}

export const getLoanApplications = async () => {
  try {
    const { data } = await axios.get<ILoanApplications>(Api.LoanApplications)
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const createLoanApplication = async (
  payload: LoanApplicationPayload
) => {
  try {
    const { data } = await axios.post(Api.LoanApplications, payload)
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const updateLoanApplication = async (
  update: LoanApplicationUpdatePayload,
  uid: string
) => {
  try {
    const { data } = await axios.put(
      Api.LoanApplication.replace(":id", uid),
      update
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getLoanApplicationById = async (uid: string) => {
  try {
    const { data } = await axios.get<ILoanApplication>(
      Api.LoanApplication.replace(":id", uid)
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getLoanApplicationFormById = async (uid: string) => {
  const { data } = await axios.get<LoanApplicationForm>(
    Api.LoanApplicationForm.replace(":id", uid)
  )
  return data
}

export const createLoanApplicationSignature = async (
  uid: string,
  isCoApplicant: boolean
) => {
  try {
    const { data } = await axios.post<
      LoanApplicationAgreementSignature,
      AxiosResponse,
      { use_new_version: boolean; co_applicant: boolean }
    >(Api.LoanApplicationSign.replace(":id", uid), {
      co_applicant: isCoApplicant,
      use_new_version: true,
    })
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const transitionToSigned = async (uid: string) => {
  try {
    const { data } = await axios.put<{ signed: boolean }>(
      Api.LoanApplicationMarkAsSign.replace(":id", uid)
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const updateBankAccount = async (
  application: BankAccountPayload,
  uid: string
) => {
  try {
    const { data } = await axios.put(
      Api.LoanApplicationBankAccount.replace(":id", uid),
      application
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const updatePaymentProtectionInsurance = async (
  isPPI: { payment_protection_insurance: boolean },
  uid: string
) => {
  try {
    const { data } = await axios.put(
      Api.LoanApplicationInsurance.replace(":id", uid),
      isPPI
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const updateDocumentation = async (
  documentation: DocumentationType,
  uid: string
) => {
  try {
    const files = new FormData()

    documentation.files.forEach((file) => files.append("files", file))
    files.append("activity", documentation.activity)

    const { data } = await axios.post<void>(
      Api.LoanApplicationDocuments.replace(":id", uid),
      files
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const collectLoans = async (
  loansCollected: LoanCollection[],
  uid: string
) => {
  try {
    const body = {
      existing_loans: [...loansCollected],
    }
    const { data } = await axios.post<{ data: LoanCollection[] }>(
      Api.LoanApplicationCollectLoans.replace(":id", uid),
      body
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const generateAgreement = async (uid: string) => {
  try {
    const { data } = await axios.post(
      Api.LoanApplicationAgreement.replace(":id", uid)
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const acceptLoanApplication = async (uid: string) => {
  try {
    const { data } = await axios.put(
      Api.LoanApplicationAccept.replace(":id", uid)
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const saveBankAccountScreening = async ({
  uid,
  params,
}: {
  uid: string
  params: BankAccountScreeningPayload
}) => {
  try {
    const { data } = await axios.post(
      Api.LoanApplicationBankAccountScreening.replace(":id", uid),
      params
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const storeKYCLoanApplication = async (
  uid: string,
  payload: IAMLQuestionsRequest
) => {
  try {
    const { data } = await axios.post(
      Api.LoanApplicationStoreKYC.replace(":id", uid),
      payload
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getLoanApplicationSignatureStatus = async (
  uid: string,
  signatureId: string
) => {
  try {
    const { data } = await axios.get<LoanApplicationSignatureStatus>(
      Api.LoanApplicationSignature.replace(":id", uid).replace(
        ":signatureId",
        signatureId
      )
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getInsuranceAgreementSignatureStatus = async (
  uid: string,
  signatureId: string
) => {
  try {
    const { data } = await axios.get(
      Api.LoanApplicationInsuranceAgreementSignature.replace(
        ":id",
        uid
      ).replace(":signatureId", signatureId)
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const createSignatureInsuranceAgreement = async (uid: string) => {
  try {
    const { data } = await axios.post(
      Api.LoanApplicationInsuranceAgreementSignatures.replace(":id", uid),
      {
        use_new_version: true,
      }
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}
