import { memo, FC } from "react"
import IconFactory from "../IconFactory"
import { InformationBannerPropTypes } from "./InformationBanner.types"
import {
  StyledInformationBanner,
  IconWrap,
  Button,
} from "./InformationBanner.styles"

const InformationBanner: FC<InformationBannerPropTypes> = ({
  title,
  body,
  onClick,
  type,
}) => {
  return (
    <StyledInformationBanner data-testid="information-banner" type={type}>
      <div className="content-wrap">
        <IconWrap type={type}>
          <IconFactory name={type === "info" ? "info" : "sad"} />
        </IconWrap>
        <h4 className="information-title">{title}</h4>
        <p className="information-body">{body}</p>
      </div>
      <div className="button-wrap">
        <Button
          aria-label="Close Banner"
          onClick={(e) => onClick(e)}
          type="button"
        >
          <IconFactory name="close" />
        </Button>
      </div>
    </StyledInformationBanner>
  )
}

export default memo(InformationBanner)
