import styled from "styled-components"
import { flex } from "config/mixins"
import { mediaQueries } from "Styles/styleGlobal"

export const WithdrawalsOverviewWrap = styled.div``

export const ListTitle = styled.h3`
  margin: 0;
  padding: 0 0 0.5em 0;
  font-size: 1.25em;
`

export const ListDescription = styled.p`
  margin: 1em 0 2em;
`

export const WithdrawalAccountsList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
`

export const WithdrawalAccountsListItem = styled.li`
  list-style: none;
`

export const WithdrawalsInfoBox = styled.div`
  margin-top: 2em;
  p {
    margin: 0;
  }
  button {
    margin-top: 1.5em;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    width: 100%;
  }
`

export const BackButton = styled.button`
  ${flex.alignCenter}
  width: max-content;
  gap: 4px;
  padding: 0;
  text-align: left;
  color: black;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 2em;

  .icon {
    transform: translateY(2px) rotateZ(180deg);
    transform-origin: center;
    path {
      stroke: currentColor;
    }
  }
`
