export type InvoiceStatusPropTypes = {
  status: `${InvoiceStatusEnum}`
  iconNeutral?: boolean
}

export type StyledInvoiceStatusPropTypes = InvoiceStatusPropTypes

export enum InvoiceStatusEnum {
  Paid = "paid",
  Unpayed = "unpayed",
  Notified = "notified",
  Outstanding = "outstanding",
  Reminder = "reminder",
  Overdue = "overdue",
  Collection = "collection",
}
