export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

export const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent)
}

export const redirectToTarget = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const redirectParam = urlParams.get("redirect")

  if (redirectParam) {
    window.location.href = redirectParam
  }
}
