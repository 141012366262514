import styled from "styled-components"
import WomanMagnifyingGlass from "Assets/Vectors/woman-magnifying-glass.svg"
import { font, mediaQueries } from "Styles/styleGlobal"

export const StyledNotFound = styled.section`
  padding-top: 250px;
  padding-bottom: 150px;
  margin-left: 50px;
  background-image: url(${WomanMagnifyingGlass});
  background-repeat: no-repeat;
  background-position: 90% 50%;
  background-size: 30%;
  flex: 1;

  h1 {
    font-size: ${font.size.xl6};
  }

  p {
    font-size: ${font.size.xl2};
    line-height: 1.4;
    max-width: 550px;
  }

  @media screen and (max-width: ${mediaQueries.medium}) {
    background-repeat: no-repeat;
    background-position: center 20%;
    background-size: 35%;
    margin: 0 20px;
    h1 {
      font-size: ${font.size.xl4};
    }
    p {
      font-size: ${font.size.xl};
    }
  }
`
