import { FC, useMemo } from "react"
import {
  FormattedNumber,
  useIntl,
  IntlProvider,
  FormatNumberOptions,
} from "react-intl"

import { setCurrencyByLocale } from "Utils/setCurrencyByLocale"
import { SV } from "Constants/languages"
import { formats } from "config/translation"

type CustomFormattedNumberPropTypes = {
  value: number
  type: "number" | "currency"
} & FormatNumberOptions

const CustomFormattedNumber: FC<CustomFormattedNumberPropTypes> = ({
  value,
  type,
  ...rest
}) => {
  const intl = useIntl()
  const currency = useMemo(() => setCurrencyByLocale(intl.locale), [intl])

  return (
    <IntlProvider locale={SV}>
      <FormattedNumber
        value={value || 0}
        {...formats[type][currency]}
        {...rest}
      />
    </IntlProvider>
  )
}

export default CustomFormattedNumber
