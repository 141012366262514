import styled, { css } from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"
import { StyledPromoWrapPropTypes } from "./PromoWrap.types"

const typeOrders = {
  loanApplications: 2,
  savings: 4,
  loans: 5,
}

export const InfoTile = styled.div`
  width: 100%;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0;
`
export const ActiveDescription = styled.p`
  color: var(--black);
  font-size: ${font.size.base};
  text-align: left;
  padding: 0;
  margin-bottom: 0;
`

export const PromoWrap = styled.section<StyledPromoWrapPropTypes>`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  order: ${(props) => typeOrders[props.type]};
  a {
    color: ${(props) => props.color};
    font-weight: bold;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    width: 100%;
  }
  ${(props) =>
    props.noData &&
    css`
      order: 10;
      border-radius: 6px;
      padding: 2em;
      box-shadow: 0 0 10px var(--light-gray);
      ${InfoTile} {
        justify-content: center;
        h2 {
          margin: 0.5em 0;
        }
      }
      ${ActiveDescription} {
        text-align: center;
      }
      button {
        margin: 1em auto 0;
      }
    `}
`
export const ActiveTitle = styled.h2`
  color: var(--black);
  font-size: ${font.size.xl2};
  margin: 0 0 5px;
  @media screen and (max-width: ${mediaQueries.small}) {
    font-size: ${font.size.xl2};
  }
`
export const InfoTitle = styled.span`
  color: var(--black);
  font-weight: bold;
  margin-top: 1em;
`

export const DescriptionList = styled.ul`
  margin: 1em auto 0.5em;
`

export const DescriptionItem = styled.li`
  list-style-position: inside;
  line-height: 28px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5em;
  gap: 0.5em;
  &::before {
    content: "";
    margin-top: 0.25em;
    display: block;
    min-width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 92 92'%3E%3Ccircle cx='46' cy='46' r='46' fill='%23F9B013'/%3E%3Cpath d='M71.7,33.1,48.7,67l-18-16.7a1.981,1.981,0,0,1,2.7-2.9L48,60.9,68.4,30.8a2.05,2.05,0,0,1,2.8-.5A2.173,2.173,0,0,1,71.7,33.1Z' transform='translate(-4 -4)' fill='%23131313'/%3E%3C/svg%3E%0A");
  }
`

export const ToggleClosedAccountsButton = styled.button`
  align-self: flex-start;
  margin-top: 1em;
  text-decoration: underline;
  font-weight: 500;
  font-size: ${font.size.base};
`
