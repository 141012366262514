import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const LoanInsuranceSignFormBlock = styled.div`
  padding: 24px 0;
  color: ${(props) => props.theme.fontColor};

  h2 {
    margin: 0 0 16px;
  }

  h3 {
    font-size: ${font.size.base};
    line-height: 24px;
    font-weight: 700;
    color: var(--black);
    margin: 0;
  }
`

export const AgreementSubtitle = styled.p`
  font-size: ${font.size.base};
  line-height: 24px;
  font-weight: 400;
  color: #717171;
  margin-top: 32px;
  margin-bottom: 0;
`

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 1em;
  margin-top: 32px;
  @media screen and (max-width: ${mediaQueries.large}) {
    flex-direction: column;
    > button {
      width: 100%;
    }
  }
`

export const InsuranceCompleteBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    white-space: pre-line;
    text-align: center;
  }

  a {
    color: var(--dark-yellow);
  }
`
