import { SelectedLoans } from "Pages/LoanCollection/LoansList/LoanItem/LoanItem.types"

export enum Actions {
  ADD_INITIAL_LOANS = "ADD_INITIAL_LOANS",
  CLEAN_INITIAL_LOANS = "CLEAN_INITIAL_LOANS",
  CLEAN_SELECTED_LOANS = "CLEAN_SELECTED_LOANS",
  ADD_LOAN_TO_DISPLAY = "ADD_LOAN_TO_DISPLAY",
  REMOVE_LOAN_TO_DISPLAY = "REMOVE_LOAN_TO_DISPLAY",
  ADD_DOCUMENTATION = "ADD_DOCUMENTATION",
  UPDATE_MONTHLY_COSTS = "UPDATE_MONTHLY_COSTS",
  UPDATE_TOTAL_AMOUNT_COSTS = "UPDATE_TOTAL_AMOUNT_COSTS",
}

type LoanAction =
  | {
      type: Actions.ADD_INITIAL_LOANS
      payload: { initialLoans: SelectedLoans[]; bankName: string }
    }
  | { type: Actions.CLEAN_INITIAL_LOANS }
  | { type: Actions.CLEAN_SELECTED_LOANS }
  | { type: Actions.ADD_LOAN_TO_DISPLAY; payload: { loans: SelectedLoans[] } }
  | { type: Actions.REMOVE_LOAN_TO_DISPLAY; payload: { id: string } }
  | { type: Actions.ADD_DOCUMENTATION; payload: { files: File[] } }
  | {
      type: Actions.UPDATE_MONTHLY_COSTS
      payload: { loans: SelectedLoans[] }
    }
  | {
      type: Actions.UPDATE_MONTHLY_COSTS
      payload: { id: string }
    }
  | {
      type: Actions.UPDATE_TOTAL_AMOUNT_COSTS
      payload: { loans: SelectedLoans[] }
    }
  | {
      type: Actions.UPDATE_TOTAL_AMOUNT_COSTS
      payload: { id: string }
    }

interface LoanState {
  initialLoans: SelectedLoans[]
  selectedLoans: SelectedLoans[]
  files: File[]
  monthlyCosts: number
  totalAmountCosts: number
}

export const initialState: LoanState = {
  initialLoans: [],
  selectedLoans: [],
  files: [],
  monthlyCosts: 0,
  totalAmountCosts: 0,
}

const cleanLoans = (
  loansToAdd: SelectedLoans[],
  selectedLoans: SelectedLoans[]
) => {
  return loansToAdd.filter(
    (loanToAdd) =>
      !selectedLoans.some(
        (selectedLoan) =>
          selectedLoan.accountNumber === loanToAdd.accountNumber &&
          selectedLoan.balance === loanToAdd.balance
      )
  )
}

export const reducer = (state: LoanState, action: LoanAction): LoanState => {
  switch (action.type) {
    case "ADD_INITIAL_LOANS": {
      const { initialLoans, bankName } = action.payload

      const initialLoansWithBankName = initialLoans.map((loan) => {
        const loanWithBankName = {
          ...loan,
          bankName,
          verified: true,
        }

        return loanWithBankName
      })

      return {
        ...state,
        initialLoans: initialLoansWithBankName,
      }
    }
    case "CLEAN_INITIAL_LOANS":
      return {
        ...state,
        initialLoans: [],
      }
    case "CLEAN_SELECTED_LOANS":
      return {
        ...state,
        selectedLoans: [],
      }
    case "ADD_LOAN_TO_DISPLAY": {
      const { loans } = action.payload
      const loansToAdd = cleanLoans(loans, state.selectedLoans)
      const newSelectedLoans = [...state.selectedLoans, ...loansToAdd]

      // Calculate the new total cost
      const newTotalAmountCosts = newSelectedLoans.reduce(
        (acc, loan) => acc + loan.balance,
        0
      )

      return {
        ...state,
        selectedLoans: newSelectedLoans,
        totalAmountCosts: newTotalAmountCosts,
      }
    }
    case "REMOVE_LOAN_TO_DISPLAY": {
      const { selectedLoans } = state
      const { id } = action.payload

      const filteredLoans = selectedLoans.filter((loan) => loan.id !== id)

      // Calculate the new total cost
      const newTotalAmountCosts = filteredLoans.reduce(
        (acc, loan) => acc + loan.balance,
        0
      )

      return {
        ...state,
        selectedLoans: filteredLoans,
        totalAmountCosts: newTotalAmountCosts,
      }
    }
    case "ADD_DOCUMENTATION": {
      const { files } = action.payload

      return {
        ...state,
        files,
      }
    }
    default:
      return state
  }
}
