import { AnimatePresence } from "framer-motion"
import { useCallback, useState } from "react"
import { FormattedMessage } from "react-intl"
import Modal from "UI/Modal"
import * as S from "./FormModal.styles"

const FormModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleModal = useCallback(() => setIsOpen(!isOpen), [isOpen])

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <Modal
            body={
              <p>
                <FormattedMessage id="app.mypages.investments.onboarding.KYC.modal.body" />
              </p>
            }
            onClick={handleModal}
            title={
              <FormattedMessage id="app.mypages.investments.onboarding.KYC.modal.header" />
            }
          />
        )}
      </AnimatePresence>
      <S.ModalButton type="button" onClick={handleModal}>
        <FormattedMessage
          id="app.mypages.investments.onboarding.KYC.modal.link"
          defaultMessage="Varför samlar Brocc in den här informationen?"
        />
      </S.ModalButton>
    </>
  )
}

export default FormModal
