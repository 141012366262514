import styled from "styled-components"

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--very-light-gray-rgb), 0.7);
  transition: 0.3s ease-in-out all;
  opacity: 0;
  button {
    background-color: transparent;
    padding: 0;
    min-width: auto;
    display: flex;
    flex-direction: column;
    &:not(:disabled):hover {
      background-color: rgba(var(--very-light-gray-rgb), 0.7);
    }
  }
  span {
    color: black;
    font-weight: 500;
  }
`

export const Wrap = styled.div`
  position: relative;
  z-index: 15;
  &:hover {
    ${Overlay} {
      opacity: 1;
    }
  }
`
