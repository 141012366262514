import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const LoanProtectionWrap = styled.div`
  background: var(--green);
  padding: 2em;
  border-radius: 20px;
  margin: 2em 0 1em 0;
  color: ${(props) => props.theme.fontColor};

  @media screen and (min-width: ${mediaQueries.extraLarge}) {
    background-repeat: no-repeat;
    background-position: top 40px right 10px;
    background-size: 40%;
  }
`

export const AgreementSubtitle = styled.p`
  font-style: italic;
  font-size: ${font.size.sm};
`

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 1em;
  @media screen and (max-width: ${mediaQueries.large}) {
    flex-direction: column;
    gap: 0;
    > button {
      width: 100%;
    }
  }
`
