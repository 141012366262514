import styled from "styled-components"
import { flex } from "config/mixins"
import { mediaQueries } from "Styles/styleGlobal"

export const InstantorWrap = styled.div`
  min-height: 442px;
`

export const LoadingWrap = styled.div`
  ${flex.center}
  height: 400px;
`

export const BankScreeningDisclaimer = styled.div`
  color: var(--dark-gray);
  line-height: 1.5em;
`
export const LoginButtonWrap = styled.div`
  margin: 2em 0;

  > button {
    width: 100%;
  }

  @media screen and (min-width: ${mediaQueries.medium}) {
    > button {
      width: auto;
    }
  }
`
