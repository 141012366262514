import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const AutogiroHeading = styled.h4`
  margin-top: 0;
  font-size: ${font.size.lg};
`

export const AutogiroDescription = styled.p``

export const AutogiroWrap = styled.div``

export const AutogiroTempMessage = styled.div`
  display: grid;
  row-gap: 1em;
  padding: 1.5em;
  margin-bottom: 1.5em;
  border-radius: 16px;
  background-color: var(--very-light-yellow);
  font-weight: 500;

  > p {
    margin: 0;
  }
`
