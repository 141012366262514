import { FC, memo } from "react"
import { FormattedMessage } from "react-intl"

import CustomFormattedDate from "Components/CustomFormattedDate"
import IconFactory from "UI/IconFactory"
import { InvoiceType } from "Interfaces/invoicesInterfaces"
import { formatNumber } from "Utils/formatNumber"
import * as S from "./Overview.styles"

type OverviewPropsType = {
  total_outstanding_amount: number
  total_amount: number
  due_date: string
  invoice_type: InvoiceType
  invoice_number: number
  isAnyUnpaid: boolean
}

const Overview: FC<OverviewPropsType> = ({
  total_outstanding_amount: totalOutAmount,
  total_amount: totalAmount,
  due_date: dueDate,
  invoice_type: invoiceType,
  invoice_number: invoiceNumber,
  isAnyUnpaid,
}) => (
  <S.Overview>
    <S.UnpaidInvoicesBox isAnyUnpaid={isAnyUnpaid}>
      <S.IconWrap isAnyUnpaid={isAnyUnpaid}>
        <IconFactory name={isAnyUnpaid ? "sad" : "happy"} />
      </S.IconWrap>
      <S.UnpaidInvoiceDetails>
        <S.UnpaidInvoiceStatus>
          <FormattedMessage
            id="app.mypages.loans.invoices.overview.unpaidInvoiceAmount"
            defaultMessage="Obetalda fakturor"
          />
        </S.UnpaidInvoiceStatus>
        <S.UnpaidInvoiceAmount>
          {formatNumber(totalOutAmount, { style: "currency" })}
        </S.UnpaidInvoiceAmount>
      </S.UnpaidInvoiceDetails>
    </S.UnpaidInvoicesBox>
    <S.NextPaymentDetails>
      <S.NextPaymentHeader>
        <FormattedMessage
          id="app.mypages.loans.invoices.overview.nextPaymentAmount"
          defaultMessage="Nästa Betalning"
        />
      </S.NextPaymentHeader>
      <S.NextPaymentAmount>
        {formatNumber(totalAmount, { style: "currency" })}
      </S.NextPaymentAmount>
    </S.NextPaymentDetails>
    <S.NextPaymentDetails>
      <S.NextPaymentHeader>
        <FormattedMessage
          id="app.mypages.loans.invoices.overview.dueDate"
          defaultMessage="Förfallodatum"
        />
      </S.NextPaymentHeader>
      <S.NextPaymentAmount>
        <CustomFormattedDate value={dueDate} />
      </S.NextPaymentAmount>
    </S.NextPaymentDetails>
    <S.NextPaymentDetails>
      <S.NextPaymentHeader>
        <FormattedMessage
          id="app.mypages.loans.invoices.overview.paymentMethod"
          defaultMessage="Betalsätt"
        />
      </S.NextPaymentHeader>
      <S.NextPaymentAmount>
        <FormattedMessage
          id={`app.mypages.loans.invoices.paymentMethods.${invoiceType}`}
          defaultMessage=""
        />
      </S.NextPaymentAmount>
    </S.NextPaymentDetails>
    <S.NextPaymentDetails>
      <S.NextPaymentHeader>
        <FormattedMessage
          id="app.mypages.loans.invoices.overview.invoiceNumber"
          defaultMessage="Fakturanummer"
        />
      </S.NextPaymentHeader>
      <S.NextPaymentAmount>{invoiceNumber}</S.NextPaymentAmount>
    </S.NextPaymentDetails>
  </S.Overview>
)

export default memo(Overview)
