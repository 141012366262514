import styled from "styled-components"

export const CloseModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 30px;
`

export const ButtonsWrap = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`
