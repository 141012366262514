import styled from "styled-components"
import { motion } from "framer-motion"
import { mediaQueries } from "Styles/styleGlobal"
import { flex } from "config/mixins"

export const StyledFeedbackDialogue = styled.div`
  ${flex.columnCenter}
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(var(--black-rgb), 0.6);
  z-index: 9998;
`

export const Icon = styled.div`
  z-index: 9999;
  position: fixed;
  align-self: center;
  top: -45px;
  svg {
    width: 70px;
  }
`

export const DialogueInner = styled(motion.div)`
  @media screen and (min-width: ${mediaQueries.large}) {
    top: -5vh;
  }

  @media screen and (min-width: ${mediaQueries.medium}) {
    top: 0;
    min-width: unset;
    max-width: unset;
    width: 40%;
  }

  @media screen and (min-width: ${mediaQueries.extraLarge}) {
    max-width: 30%;
  }

  background: ${(props) => props.theme.background};
  min-width: 25%;
  max-width: 95%;
  border-radius: 20px;
  position: relative;
  z-index: 999;
  max-height: 90%;
  overflow: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 2em;
  .body {
    ${flex.center}
    padding: 4em 2em 2em 2em;
    min-height: 15vh;
    text-align: center;
    line-height: 1.5;
  }

  .btn-container {
    width: 80%;
    align-self: center;
    button {
      margin: 0 auto;
      min-width: 40%;
    }
  }
`
