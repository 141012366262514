import { FC, ReactNode } from "react"
import { useNavigate } from "react-router-dom"

import IconFactory from "UI/IconFactory"
import * as S from "./BackTitleWithLink.styles"

type BackTitleWithLinkProps = {
  titleNode: ReactNode
}

const BackTitleWithLink: FC<BackTitleWithLinkProps> = ({ titleNode }) => {
  const navigate = useNavigate()

  const handleClick = () => navigate(-1)

  return (
    <S.Back onClick={handleClick}>
      <IconFactory name="chevronRight" data-testid="back-icon" />
      {titleNode && <h1>{titleNode}</h1>}
    </S.Back>
  )
}

export default BackTitleWithLink
