import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const BankgiroTransferWrap = styled.div`
  ol {
    li {
      margin-bottom: 10px;
      line-height: 1.5em;
      list-style-position: inside;
    }
  }
`
export const BankgiroTransferHeading = styled.h4`
  margin-top: 0;
  font-size: ${font.size.lg};
`
export const BankgiroTransferDescription = styled.p``
