import { useMemo, useState } from "react"
import type { FC } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AnimatePresence } from "framer-motion"

import InfoBadge from "UI/InfoBadge"
import Button from "UI/Button"
import { TextField } from "Components/form/TextField"
import { useFeedbackModal } from "Context/modal-context"
import { formatNumber } from "Utils/formatNumber"
import AccountName from "Components/AccountName"
import Modal from "UI/Modal"
import { TaxBanner } from "Components/TaxBanner/TaxBanner"
import { BankAccountItem } from "Pages/Authenticated/Savings/Withdrawal/BankAccountItem/BankAccountItem"
import { useDepositCloseAccount } from "Hooks/API/useDepositServices"
import { Urls } from "Constants/urls"
import { ACCURED_INTEREST_TAX } from "Constants/dataNumbers"
import type {
  WithdrawalsFormProps,
  WithdrawalsFormValues,
} from "./WithdrawalsForm.types"
import { createWithdrawalsSchema } from "./validation"
import * as S from "./WithdrawalsForm.styles"

const WithdrawalsForm: FC<WithdrawalsFormProps> = ({
  deposit,
  link,
  type,
  onSubmit: postWithdrawalMutation,
  isLoading,
  selectedAccount,
  closeDrawer,
  isClosingTransferring,
  successWithdrawalCallback,
}) => {
  const { accountID } = useParams() as { accountID: string }

  const navigate = useNavigate()
  const { errorModal, successModal } = useFeedbackModal()
  const [showWithdrawalConfirmModal, setShowWithdrawalConfirmModal] =
    useState(false)
  const [withdrawalCompleted, setWithdrawalCompleted] = useState(false)

  const { mutateAsync: closeMutateAsync } = useDepositCloseAccount(
    accountID,
    selectedAccount?.deposit.uid
  )

  const { handleSubmit, watch, formState, control, getValues } =
    useForm<WithdrawalsFormValues>({
      values: { amount: isClosingTransferring ? deposit.currentBalance : "" },
      mode: "onChange",
      resolver: yupResolver(createWithdrawalsSchema()),
    })

  const cancelWithdrawal = () => {
    if (withdrawalCompleted && closeDrawer) {
      closeDrawer()
      if (successWithdrawalCallback) {
        successWithdrawalCallback()
      }
    }
    setShowWithdrawalConfirmModal(false)
  }

  const isExternalBank = selectedAccount?.bankAccountType === "bank"

  const confirmWithdrawal = () => {
    if (isClosingTransferring && selectedAccount) {
      closeMutateAsync({
        uid: accountID,
        toDepositServiceUid: isExternalBank
          ? null
          : selectedAccount.deposit.uid,
      })
        .then(() => {
          successModal(
            isExternalBank ? (
              <FormattedMessage id="app.mypages.savings.details.closeAccount.fixed.success" />
            ) : (
              <FormattedMessage id="app.mypages.savings.details.closeAccount.success" />
            )
          )
          navigate(Urls.Savings)
        })
        .catch(() => errorModal())
    } else {
      postWithdrawalMutation(getValues())
        .then(() => setWithdrawalCompleted(true))
        .catch(() => errorModal())
    }
  }

  const onSubmit = () => {
    if (type === "investments") {
      confirmWithdrawal()
      return
    }
    setShowWithdrawalConfirmModal(true)
  }

  const amount = +watch("amount")
  const {
    withdrawalFee = 0,
    lockUntilDate,
    currentBalance,
    bankAccountNumber,
    bankName,
    bankClearingNumber,
  } = deposit

  const isDisabled =
    type === "investments"
      ? !formState.isValid
      : !formState.isValid || amount > currentBalance

  const hasBankAccount = useMemo(
    () => Boolean(bankName && bankClearingNumber && bankAccountNumber),
    [bankAccountNumber, bankClearingNumber, bankName]
  )

  if (!hasBankAccount) {
    return (
      <S.WithdrawalsInfoBox>
        <p>
          <FormattedMessage
            id="app.main.withdrawingInvestment.enter.account.number"
            defaultMessage="Innan du kan registrera uttag behöver du ange ditt kontonummer. Detta gör du enkelt under inställningar. Vänligen försök att göra en överföring igen när du har kopplat ditt bankkonto."
          />
        </p>
        <Button type="button" onClick={() => navigate(link)}>
          <FormattedMessage
            id="app.mypages.savings.settings.bankAccountButton"
            defaultMessage="Ange bankkonto"
          />
        </Button>
      </S.WithdrawalsInfoBox>
    )
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.WithdrawalsBody>
          <S.WithdrawalsTitle>
            {isClosingTransferring ? (
              <FormattedMessage
                id="app.mypages.savings.withdrawal.drawer.withdrawalForm.title.closing"
                defaultMessage="Avsluta konto"
              />
            ) : (
              <FormattedMessage
                id="app.mypages.savings.withdrawal.drawer.withdrawalForm.title"
                defaultMessage="Överför pengar"
              />
            )}
          </S.WithdrawalsTitle>
          <S.WithdrawalsItem>
            <S.WithdrawalsItemubtitle id="balance">
              {isClosingTransferring ? (
                <FormattedMessage id="app.mypages.deposits.home.balanceOverview.accountBalance" />
              ) : (
                <FormattedMessage
                  id="app.common.balance"
                  defaultMessage="Tillgängligt för överföring"
                />
              )}
            </S.WithdrawalsItemubtitle>
            <S.WithdrawalsItemTitle aria-labelledby="balance">
              {formatNumber(currentBalance, { style: "currency" })}
            </S.WithdrawalsItemTitle>
          </S.WithdrawalsItem>
          {isClosingTransferring ? (
            <S.WithdrawalsItem>
              <S.WithdrawalsItemubtitle id="balance">
                <FormattedMessage
                  id="app.mypages.savings.withdrawal.taxBanner.sidebar.closing.accuredInterest"
                  values={{
                    amount: ACCURED_INTEREST_TAX,
                  }}
                />
              </S.WithdrawalsItemubtitle>
              <S.WithdrawalsItemTitle aria-labelledby="balance">
                {formatNumber(deposit.accruedInterest || 0, {
                  style: "currency",
                })}
              </S.WithdrawalsItemTitle>
            </S.WithdrawalsItem>
          ) : (
            <S.InputWrap>
              <TextField
                control={control}
                name="amount"
                type="number"
                inputMode="numeric"
                autoFocus
                placeholder={
                  <FormattedMessage
                    id="app.mypages.savings.withdrawal.withdrawalsOverview.body.input.placeholder"
                    defaultMessage="Ange belopp i kr"
                  />
                }
              />
              {type === "investments" && (
                <InfoBadge
                  placement="left"
                  title={
                    <FormattedMessage
                      id="app.main.withdrawingInvestment.enter.amount"
                      defaultMessage="Ange belopp du önskar registrera för uttag. Minsta uttagbara belopp är 50 kr."
                    />
                  }
                />
              )}
            </S.InputWrap>
          )}
          <S.WithdrawalsTo>
            <FormattedMessage
              id="app.mypages.report.filter.button.title"
              defaultMessage="Till"
            />
          </S.WithdrawalsTo>
          {!selectedAccount && (
            <S.WithdrawalsBankItem>
              <AccountName
                type="bank"
                accountNumber={`${bankClearingNumber} - ${bankAccountNumber}`}
                accountTitleId="app.mypages.account.bankAccountNo"
                name={bankName || undefined}
              />
            </S.WithdrawalsBankItem>
          )}
          {selectedAccount && (
            <BankAccountItem
              title={
                isExternalBank
                  ? selectedAccount.deposit.bank_account.bank_name
                  : selectedAccount.deposit.name
              }
              description={
                isExternalBank
                  ? `${selectedAccount.deposit.bank_account.bank_clearing_number} - ${selectedAccount.deposit.bank_account.bank_account_number}`
                  : `${selectedAccount.deposit.product_number}`
              }
              balance={
                isExternalBank
                  ? undefined
                  : selectedAccount.deposit.current_balance
              }
              type={selectedAccount.bankAccountType}
              name={selectedAccount.deposit.bank_account.bank_name}
              isExternalBank={isExternalBank}
              disabled
            />
          )}
          <S.ButtonWrap>
            <Button type="submit" disabled={isDisabled} isLoading={isLoading}>
              {isClosingTransferring ? (
                <FormattedMessage
                  id="app.mypages.savings.details.closeAccount.btn"
                  defaultMessage="Avsluta konto"
                />
              ) : (
                <FormattedMessage
                  id="app.mypages.savings.withdrawal.withdrawalsOverview.body.button.postWithdrawals"
                  defaultMessage="Registrera överföring"
                />
              )}
            </Button>
          </S.ButtonWrap>
        </S.WithdrawalsBody>
      </form>
      {type === "savings" && (
        <AnimatePresence>
          {showWithdrawalConfirmModal && (
            <Modal
              onClick={cancelWithdrawal}
              body={
                <S.ConfirmWithdrawalModalBody>
                  {!withdrawalCompleted && (
                    <>
                      <S.ConfirmationModalMessage>
                        {isClosingTransferring ? (
                          <FormattedMessage id="app.mypages.savings.withdrawal.confirmationMessage.closing" />
                        ) : (
                          <FormattedMessage
                            id="app.mypages.savings.withdrawal.confirmationMessage"
                            values={{
                              amount: formatNumber(amount, {
                                style: "currency",
                              }),
                              bankName: isExternalBank
                                ? selectedAccount?.deposit.bank_account
                                    .bank_name
                                : selectedAccount?.deposit.name,
                              bankAccountNumber: isExternalBank
                                ? selectedAccount?.deposit.bank_account
                                    .bank_account_number
                                : selectedAccount?.deposit.product_number,
                            }}
                          />
                        )}
                      </S.ConfirmationModalMessage>
                      {deposit.type === "fixed" && withdrawalFee > 0 && (
                        <TaxBanner>
                          {isClosingTransferring ? (
                            <FormattedMessage
                              id="app.mypages.savings.withdrawal.taxBanner.confirmation.closing"
                              values={{
                                amount: formatNumber(amount, {
                                  style: "currency",
                                }),
                                fee: formatNumber(withdrawalFee, {
                                  style: "percent",
                                  minimumFractionDigits: undefined,
                                }),
                                feeAmount: formatNumber(
                                  amount * withdrawalFee,
                                  {
                                    style: "currency",
                                  }
                                ),
                                totalAmount: formatNumber(
                                  amount -
                                    Math.floor(amount * withdrawalFee * 100) /
                                      100,
                                  { style: "currency" }
                                ),
                              }}
                            />
                          ) : (
                            <FormattedMessage
                              id="app.mypages.savings.withdrawal.taxBanner.confirmation"
                              values={{
                                lockUntilDate,
                                amount: formatNumber(amount, {
                                  style: "currency",
                                }),
                                fee: formatNumber(withdrawalFee, {
                                  style: "percent",
                                  minimumFractionDigits: undefined,
                                }),
                                feeAmount: formatNumber(
                                  amount * withdrawalFee,
                                  {
                                    style: "currency",
                                  }
                                ),
                                totalAmount: formatNumber(
                                  amount -
                                    Math.floor(amount * withdrawalFee * 100) /
                                      100,
                                  { style: "currency" }
                                ),
                              }}
                            />
                          )}
                        </TaxBanner>
                      )}
                      <S.ConfirmWithdrawalButtons>
                        <Button
                          type="button"
                          variant="inverse"
                          onClick={cancelWithdrawal}
                        >
                          {isClosingTransferring ? (
                            <FormattedMessage id="app.common.no" />
                          ) : (
                            <FormattedMessage
                              id="app.common.cancel"
                              defaultMessage="Avbryt"
                            />
                          )}
                        </Button>
                        <Button
                          type="button"
                          onClick={confirmWithdrawal}
                          disabled={isLoading}
                          isLoading={isLoading}
                        >
                          {isClosingTransferring ? (
                            <FormattedMessage id="app.common.yes" />
                          ) : (
                            <FormattedMessage
                              id="app.common.confirm"
                              defaultMessage="Bekräfta"
                            />
                          )}
                        </Button>
                      </S.ConfirmWithdrawalButtons>
                    </>
                  )}
                  {withdrawalCompleted && (
                    <>
                      <S.ConfirmationModalMessage>
                        <FormattedMessage
                          id={
                            isExternalBank
                              ? "app.mypages.savings.withdrawal.withdrawalsOverview.body.feedBackDialogue.withdrawal.success"
                              : "app.mypages.savings.withdrawal.withdrawalsOverview.body.feedBackDialogue.transfer.success"
                          }
                          defaultMessage={
                            isExternalBank
                              ? "Överföring registrerat! Pengarna kommer inom 1-2 bankdagar."
                              : "Överföring utförd"
                          }
                        />
                      </S.ConfirmationModalMessage>
                      <S.ConfirmWithdrawalButtons>
                        <Button type="button" onClick={cancelWithdrawal}>
                          <FormattedMessage
                            id="app.common.thankYou"
                            defaultMessage="Tack!"
                          />
                        </Button>
                      </S.ConfirmWithdrawalButtons>
                    </>
                  )}
                </S.ConfirmWithdrawalModalBody>
              }
            />
          )}
        </AnimatePresence>
      )}
    </>
  )
}

export default WithdrawalsForm
