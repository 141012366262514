import { useCallback, FC } from "react"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"

import IconFactory from "UI/IconFactory"
import Button from "UI/Button"
import { SAVINGS_ONBOARDING } from "Constants/storage"
import {
  getParsedStorageItemOrDefault,
  setStorageItem,
} from "Utils/localStorage"
import {
  SavingsOnboardingData,
  SavingsOnboardingStepsEnum,
} from "../SavingsOnboarding.types"
import * as S from "./Intro.styles"

type IntroPropTypes = {
  setStep: React.Dispatch<React.SetStateAction<number>>
  handleCancelModal?: () => void
}

const Intro: FC<IntroPropTypes> = ({ setStep, handleCancelModal }) => {
  const { accountID: id } = useParams() as { accountID: string }

  const savingsOnboardingData =
    getParsedStorageItemOrDefault<SavingsOnboardingData>(SAVINGS_ONBOARDING, {})

  const handleNextStep = useCallback(() => {
    if (!savingsOnboardingData[id]) {
      const updatedStatus: SavingsOnboardingData = {
        ...savingsOnboardingData,
        [id]: {
          brocc_has_started_savings_onboarding: true,
        },
      }
      setStorageItem(SAVINGS_ONBOARDING, updatedStatus)
    }

    setStep(SavingsOnboardingStepsEnum.KYC)
  }, [setStep, id, savingsOnboardingData])

  return (
    <S.IntroWrap>
      <h1>
        <FormattedMessage id="app.mypages.savings.onboarding.step.intro.title" />
      </h1>
      <S.SavingsWrap>
        <IconFactory name="personArmchairLaptop" />
        <S.CTAContent>
          <p>
            <IconFactory name="squareBox" />
            <FormattedMessage id="app.mypages.savings.onboarding.step.intro.savingWrap.first.CTAContent" />
          </p>
          <p>
            <IconFactory name="squareBox" />
            <FormattedMessage id="app.mypages.savings.onboarding.step.intro.savingWrap.second.CTAContent" />
          </p>
          <p>
            <IconFactory name="squareBox" />
            <FormattedMessage id="app.mypages.savings.onboarding.step.intro.savingWrap.third.CTAContent" />
          </p>
          <p>
            <IconFactory name="squareBox" />
            <FormattedMessage id="app.mypages.savings.onboarding.step.intro.savingWrap.fourth.CTAContent" />
          </p>
          <S.ButtonWrap>
            <Button onClick={handleNextStep}>
              <FormattedMessage
                id="app.mypages.savings.onboarding.step.intro.buttonCTA"
                defaultMessage="Starta ansökan"
              />
            </Button>
            <Button variant="inverse" onClick={handleCancelModal}>
              <FormattedMessage id="app.common.cancel" />
            </Button>
          </S.ButtonWrap>
        </S.CTAContent>
      </S.SavingsWrap>
    </S.IntroWrap>
  )
}

export default Intro
