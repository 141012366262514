import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const CashbackHeadContainer = styled.section`
  margin-top: 3em;
  @media screen and (max-width: ${mediaQueries.large}) {
    h1 {
      font-size: ${font.size.xl3};
    }
  }
`
export const CashbackCTA = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 4em;
  @media screen and (max-width: ${mediaQueries.large}) {
    gap: 2em;
    flex-direction: column-reverse;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    gap: 3em;
  }
`
export const CTAContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--black);

  h1,
  h2 {
    margin: 0;
  }
  .remainder {
    font-size: ${font.size.lg};
    margin-bottom: 2em;
  }
  .description {
    font-size: ${font.size.base};
    line-height: 1.6;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    gap: 1em;
  }
`
export const CashbackContainer = styled.div`
  min-height: 300px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 2em;
  @media screen and (max-width: ${mediaQueries.large}) {
    flex-direction: column-reverse;
    margin-bottom: 2em;
  }
  @media screen and (max-width: 1295px) and (min-width: ${mediaQueries.large}) {
    gap: 3em;
    ${CTAContent} {
      width: 50%;
    }
  }
`
export const ButtonWrap = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: flex-start;
  align-items: center;

  h2 {
    margin-top: 0;
    font-weight: 500;
    text-align: center;
  }

  button {
    max-width: 280px;
    width: 100%;
  }

  @media screen and (max-width: ${mediaQueries.small}) {
    button {
      max-width: 100%;
    }
  }
`
export const ModalLinks = styled.div`
  display: flex;
`
export const ModalLink = styled.button`
  text-decoration: underline;
  margin: 0 1em 0 0;
  font-size: ${font.size.sm};
  padding: 0;
`
export const LoadingContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CTAContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  justify-content: flex-start;
  p {
    margin-top: 0;
  }
  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    margin-bottom: 1em;
  }
`
export const ProgressWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    min-width: 200px;
  }
`
export const Balance = styled.p`
  font-size: ${font.size.xl2};
  font-weight: 600;
  position: absolute;
`

export const CashbackHeadingWrap = styled.div`
  display: flex;
  align-items: flex-start;
  a {
    margin-bottom: 0;
    padding-top: 1em;
  }
`

export const WarningBox = styled.div`
  background-color: var(--light-gray);
  padding: 15px 20px;
  color: var(--black);
  border-radius: 10px;
  line-height: 1.5em;
  max-width: 80%;
  @media screen and (max-width: ${mediaQueries.large}) {
    margin-top: 10px;
    max-width: 100%;
  }
  p {
    line-height: 1.5;
    margin: 0;
  }
`
