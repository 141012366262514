import { Helmet } from "react-helmet-async"

import IconFactory from "UI/IconFactory"
import { LoginMethods } from "./LoginMethods/LoginMethods"
import { Languages } from "./Languages/Languages"
import { Animation } from "./Animation/Animation"
import * as S from "./Login.styles"

const Login = () => {
  return (
    <S.LoginWrap>
      <Helmet>
        <meta
          name="description"
          content="Logga in på Mina Sidor på Brocc.se. Använd BankID för att logga in."
        />
      </Helmet>
      <S.LeftSection>
        <S.HeaderBlock>
          <S.LogoWrap>
            <IconFactory name="logo" />
          </S.LogoWrap>
          <Languages />
        </S.HeaderBlock>
        <LoginMethods />
      </S.LeftSection>
      <S.RightSection>
        <Animation />
      </S.RightSection>
    </S.LoginWrap>
  )
}

export default Login
