import { FC, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { useForm, SubmitHandler } from "react-hook-form"

import AccountName from "Components/AccountName"
import { IDepositService } from "Interfaces/depositInterfaces"
import Button from "UI/Button"
import { yupResolver } from "@hookform/resolvers/yup"
import { TextField } from "Components/form/TextField"
import { DEPOSIT_SERVICES_SERVICE } from "Constants/queryKeys"
import { createDepositSchema } from "./validation"
import * as S from "./DepositForm.styles"
import { accountTitles, initialValues } from "./config"
import { DepositFormValues } from "./DepositForm.types"

type DepositFormPropTypes = {
  onSubmit: SubmitHandler<DepositFormValues>
  isLoading: boolean
  account: "savings"
  minAmount?: number
}

const DepositForm: FC<DepositFormPropTypes> = ({
  onSubmit,
  isLoading,
  account,
  minAmount,
}) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const { accountID } = useParams() as { accountID: string }

  const accountData = useMemo(
    () => ({
      savings: queryClient.getQueryData<IDepositService>([
        DEPOSIT_SERVICES_SERVICE,
        accountID,
      ]),
    }),
    [accountID, queryClient]
  )

  const data = useMemo(() => accountData[account], [account, accountData])

  const accountName = useMemo(
    () => (data && "name" in data ? data?.name : undefined),
    [data]
  )

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<DepositFormValues>({
    defaultValues: initialValues,
    mode: "onChange",
    resolver: yupResolver(createDepositSchema(minAmount)),
  })

  return (
    <S.DepositForm onSubmit={handleSubmit(onSubmit)}>
      <TextField
        control={control}
        name="amount"
        type="number"
        autoFocus
        placeholder={intl.formatMessage({
          id: "app.mypages.savings.deposit.ongoingDeposits.form.input.placeholder",
          defaultMessage: "Belopp i kr",
        })}
      />
      <S.DepositTo>
        <FormattedMessage
          id="app.mypages.report.filter.button.title"
          defaultMessage="Till"
        />
      </S.DepositTo>
      <S.DepositAccountItem>
        <AccountName
          type={account}
          name={accountName}
          accountNumber={data?.product_number || 0}
          accountTitleId={accountTitles[account]}
        />
      </S.DepositAccountItem>
      <Button
        type="submit"
        disabled={!isDirty || isLoading}
        isLoading={isLoading}
      >
        <FormattedMessage
          id="app.mypages.savings.deposit.btn"
          defaultMessage="Sätt in pengar"
        />
      </Button>
    </S.DepositForm>
  )
}

export default DepositForm
