import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"
import { flex } from "config/mixins"

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75em 2.5em;
`

export const ButtonWrap = styled.div`
  button {
    width: 100%;
    margin: 0.5em 0;
  }

  @media screen and (min-width: ${mediaQueries.medium}) {
    button {
      width: auto;
    }
  }
`

export const PauseContainer = styled.div`
  background: var(--light-yellow);
  padding: 2em;
  border-radius: 16px;
`

export const WaitForAgreementContainer = styled.section`
  ${flex.columnCenter}
  height: 70vh;
  text-align: center;
  background: var(--very-light-gray);
  margin: 4em 3em;
  border-radius: 16px;
  padding: 0 2em;
  h1 {
    margin-top: 2em;
  }
`
export const ApplicantSigned = styled.div`
  ${flex.center}
  padding: 17px 42px;
  border: 3px solid var(--green);
  width: 100%;
  background: var(--white);
  color: var(--green);
  font-weight: bold;
  border-radius: 100px;
  font-size: ${font.size.lg};
  margin: 0.5em 0;

  svg {
    margin-left: 0.5em;
  }
`
