import styled, { css } from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"
import { flex } from "config/mixins"
import {
  TableDataItemPropTypes,
  MobileRowWrapPropTypes,
  ExpandButtonPropTypes,
} from "./InvoicesTableRow.types"

export const TableItemsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  &:hover {
    background-color: var(--very-light-gray);
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 100%;
  }
`

export const TableDataItem = styled.div<TableDataItemPropTypes>`
  ${flex.center}
  padding: 1em 0;

  ${(p) => css`
    ${p.chevron &&
    css`
      display: none;
      align-items: end;
    `}

    @media screen and (max-width: ${mediaQueries.large}) {
      display: ${p.hide && "none"};
      ${p.chevron &&
      css`
        display: flex;
        align-items: end;
        svg path {
          stroke: var(--black);
        }
      `}
    }
  `}
`

export const Status = styled.div``

export const DownloadLink = styled.div`
  text-decoration: none;
  cursor: pointer;
  &:focus {
    border: 1px solid var(--yellow);
    border-radius: 3px;
  }

  @media screen and (max-width: ${mediaQueries.large}) {
    svg {
      transform: scale(2);
    }
  }
`

export const MobileRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 0.25em 0 0.5em 0;
  align-items: center;

  @media screen and (min-width: ${mediaQueries.large}) {
    display: none;
  }
`

export const MobileRowWrap = styled.div<MobileRowWrapPropTypes>`
  ${(p) => css`
    display: ${p.show ? "block" : "none"};
    ${p.mobile &&
    css`
      grid-row-start: 2;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 5;
    `}
  `}

  @media screen and (min-width: ${mediaQueries.large}) {
    display: none;
  }
`

export const MobileRowItem = styled.div`
  margin: 0.5em 0;
`

export const ExpandButton = styled.button<ExpandButtonPropTypes>`
  border: none;
  background: none;
  transition: 300ms all ease-in-out;
  transform: ${(p) => (p.show ? "rotate(90deg)" : "rotate(0deg)")};

  &:focus {
    box-shadow: none;
  }
`
