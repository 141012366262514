import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media screen and (min-width: ${mediaQueries.medium}) {
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    min-width: 200px;
  }
`

export const SideNav = styled.aside`
  display: none;
  max-width: 300px;
  min-width: 300px;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 3em 0;
  background-color: var(--very-light-gray);
  @media screen and (min-width: 769px) {
    display: initial;
  }

  @media screen and (max-width: ${mediaQueries.medium}) {
    margin-left: 0;
  }
`

export const Main = styled.main`
  position: relative;
  display: flex;
  height: 100vh;
  flex-direction: column;
  margin-left: 300px;
  padding: 3em 0;
  width: calc(100vw - 300px);
  overflow-y: scroll;
  @media screen and (max-width: ${mediaQueries.medium}) {
    margin-left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0;
    min-height: calc(100vh - 91px);
  }
`

export const NotificationsWrap = styled.div`
  position: absolute;
  right: 30px;
  top: 0px;
  @media screen and (max-width: ${mediaQueries.medium}) {
    display: none;
  }
`

export const MainContainer = styled.div`
  @media screen and (min-width: ${mediaQueries.medium}) {
    margin: 0 auto;
  }
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding: 0 2em 3em 2em;
  position: relative;

  @media screen and (max-width: 1400px) {
    width: 100%;
    padding: 0 3em;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    width: 100%;
    padding: 5px 20px 3em;
  }
`
