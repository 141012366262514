import { FormattedMessage } from "react-intl"

import IconFactory from "UI/IconFactory"
import { onboarding } from "config/animations"
import * as S from "./ManualCheck.styles"

const ManualCheck = () => (
  <S.ManualChecKWrap
    initial="hidden"
    animate="visible"
    variants={onboarding.variants}
    transition={onboarding.transition}
  >
    <S.IllustrationWrap>
      <IconFactory name="manBinoculars" />
    </S.IllustrationWrap>
    <S.TextContent>
      <h1>
        <FormattedMessage
          id="app.onboarding.manualCheck.header"
          defaultMessage="Din ansökan har skickats för ytterligare granskning"
        />
      </h1>

      <p>
        <FormattedMessage
          id="app.onboarding.manualCheck.paragraph"
          defaultMessage="Tack för din ansökan! Vi behöver granska din ansökan mer i detalj för att kunna avgöra om vi kan bevilja dig ett lån. Broccs kredithandläggare återkommer till dig så snabbt som möjligt men tveka inte att kontakta oss om det är något du undrar över."
        />
      </p>

      <p>
        <FormattedMessage
          id="app.onboarding.manualCheck.contact"
          defaultMessage="Har du frågor är du varmt välkommen att kontakta oss på "
        />{" "}
        <a href="tel:0850121580">08-50 12 15 80</a>
      </p>
    </S.TextContent>
  </S.ManualChecKWrap>
)

export default ManualCheck
