import styled, { css } from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"
import {
  TinkCallbackWrapPropTypes,
  LoadingWrapperPropTypes,
} from "./TinkCallback.types"

export const TinkCallbackWrap = styled.div<TinkCallbackWrapPropTypes>`
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  &::after,
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: ${(props) => (props.isPayment ? "0" : "2rem")};
    background-color: var(--white);
    width: 50px;
    height: 50px;
    border-radius: 6px;
  }
  &::after {
    right: ${(props) => (props.isPayment ? "0" : "2rem")};
  }
  &::before {
    display: ${({ isFirstScreen }) => (isFirstScreen ? "block" : "none")};
    left: 2rem;
  }
  @media screen and (max-width: 1370px) {
    &::after,
    &::before {
      top: 0;
      left: 0;
    }

    &::after {
      right: 0;
      left: initial;
    }
  }
`

export const LoadingWrapper = styled.div<LoadingWrapperPropTypes>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
  padding-top: 3em;
  flex: 1;
  width: 100%;
  svg {
    margin-bottom: 3em;
    ${(p) => css`
      width: ${p.isPayment ? "80%" : "100%"};
      path {
        &:last-child {
          stroke: ${p.theme.fontColor};
          fill: ${p.theme.fontColor};
        }
      }
    `}
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    padding-top: 0;
    height: 50vh;
    svg {
      margin-bottom: 0;
      &:first-child {
        width: 80vw;
      }
    }
  }
`
