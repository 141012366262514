import { removeApiAuthorizationHeader } from "ApiServices/axios"
import {
  ACCESS_TOKEN,
  LOGIN_TIMESTAMP_IN_MS,
  POSTPONED_KYC,
  REFRESH_TOKEN,
  SIGNATURE_CODE,
  TOKEN_EXPIRES_IN_SECONDS,
} from "Constants/storage"
import { IAuthData } from "Interfaces/authContextInterfaces"
import { setStorageItem } from "Utils/localStorage"

export const cleanStorageAndAxios = () => {
  removeApiAuthorizationHeader()

  localStorage.removeItem(ACCESS_TOKEN)
  localStorage.removeItem(REFRESH_TOKEN)
  localStorage.removeItem(SIGNATURE_CODE)
  localStorage.removeItem(LOGIN_TIMESTAMP_IN_MS)
  localStorage.removeItem(TOKEN_EXPIRES_IN_SECONDS)
  localStorage.removeItem(POSTPONED_KYC)
}

export const getAuthDataFromStorage = (): IAuthData => ({
  refreshToken: localStorage.getItem(REFRESH_TOKEN) || "",
  accessToken: localStorage.getItem(ACCESS_TOKEN) || "",
  tokenExpiresInSec: localStorage.getItem(TOKEN_EXPIRES_IN_SECONDS)
    ? Number(localStorage.getItem(TOKEN_EXPIRES_IN_SECONDS))
    : 0,
  loginTimestampInMs: localStorage.getItem(LOGIN_TIMESTAMP_IN_MS)
    ? Number(localStorage.getItem(LOGIN_TIMESTAMP_IN_MS))
    : 0,
  isLoggedIn: Boolean(localStorage.getItem(ACCESS_TOKEN)),
  tokenExpired: false,
})

export const saveAuthDataInStorage = (data: {
  accessToken: string
  refreshToken: string
  loginTimestampInMs: number
  tokenExpiresInSec: number
}) => {
  setStorageItem(ACCESS_TOKEN, data.accessToken)
  setStorageItem(REFRESH_TOKEN, data.refreshToken)
  setStorageItem(LOGIN_TIMESTAMP_IN_MS, data.loginTimestampInMs)
  setStorageItem(TOKEN_EXPIRES_IN_SECONDS, data.tokenExpiresInSec)
}
