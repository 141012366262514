import styled, { css } from "styled-components"
import { LoadingSizeEnum, StyledSpinnerPropTypes } from "./Loading.types"

export const StyledSpinner = styled.svg<StyledSpinnerPropTypes>`
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;

  & .path {
    stroke: ${(props) => props.theme.fontColor};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  circle {
    fill: transparent;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  ${(p) => {
    switch (p.size) {
      case LoadingSizeEnum.ExtraSmall:
        return css`
          width: 15px;
          height: 15px;
        `
      case LoadingSizeEnum.Small:
        return css`
          width: 25px;
          height: 25px;
        `
      default:
        return null
    }
  }};
`

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: inherit;
  width: inherit;
  > p {
    margin-bottom: 15px;
  }
`
