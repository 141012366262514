import styled from "styled-components"
import { ProgressPropTypes } from "./ProgressBar.types"

export const StyledProgressWrap = styled.div`
  width: 95%;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    border-radius: 0px;
    width: 100%;
  }
`

export const StyledProgressBar = styled.div`
  height: 5px;
  background: var(--light-gray);
  border-radius: 5px;
`

export const Progress = styled.div<ProgressPropTypes>`
  height: inherit;
  width: ${(props) => `${props.width}%` || "0%"};
  background-color: var(--light-yellow);
  transition: width 300ms ease-in-out;
  border-radius: 5px;
`
