import { FormattedMessage } from "react-intl"

import yup from "config/yup"
import { WithdrawalsFormValues } from "./WithdrawalsForm.types"

export const createWithdrawalsSchema = (
  minAmount = 0.01
): yup.SchemaOf<WithdrawalsFormValues> =>
  yup.object({
    amount: yup
      .number()
      .min(minAmount, () => (
        <FormattedMessage
          id="app.mypages.investment.transactions.withdrawals.amount.minError"
          defaultMessage="Minsta uttagbara belopp är {minAmount} kr"
          values={{ minAmount }}
        />
      ))
      .transform((v) => (Number.isNaN(v) ? null : v))
      .nullable(true)
      .required(),
  })
