import type { FC } from "react"
import { FormattedMessage } from "react-intl"

import {
  LoanBank,
  LoginSelectedBank,
  ManualProps,
  OtherActorFormProps,
} from "Interfaces/iceingInterfaces"
import { banksInformation } from "Pages/LoanCollection/Banks/BankIcons/BanksInformation"
import OtherActorForm from "Pages/LoanCollection/OtherActorForm/OtherActorForm"
import { ANNAN_AKTOR } from "Pages/LoanCollection/Constants"
import BankIcon from "Pages/LoanCollection/Banks/BankIcons/BankIcon"
import Actions from "./Actions"
import LoginProcess from "./LoginProcess"
import * as S from "./SelectedBank.styles"

const SelectedBank: FC<
  LoanBank & LoginSelectedBank & OtherActorFormProps & ManualProps
> = ({
  bankName,
  loginStrategies,
  autostarttoken,
  isLoading,
  isLoggedIn,
  isManualForm,
  onLoginSelectedBank,
  onAddLoan,
  onReset,
  onUploadFiles,
}) => {
  const fullBankName = banksInformation[bankName.toLowerCase()]
    ? banksInformation[bankName.toLowerCase()].description.fullName
    : bankName
  let loginInProcessUI

  if (bankName === ANNAN_AKTOR || isManualForm) {
    loginInProcessUI = (
      <>
        <S.FillInformationText>
          <FormattedMessage id="app.mypages.iceing.fill.annan.kreditgivare.form.text" />
        </S.FillInformationText>
        <OtherActorForm
          onUploadFiles={onUploadFiles}
          onAddLoan={onAddLoan}
          onReset={onReset}
          bankName={bankName}
        />
      </>
    )
  } else if (!autostarttoken && !isLoading) {
    loginInProcessUI = (
      <Actions onLoginSelectedBank={onLoginSelectedBank} bankName={bankName} />
    )
  } else {
    loginInProcessUI = (
      <LoginProcess
        autostarttoken={autostarttoken}
        isLoading={isLoading}
        isLoggedIn={isLoggedIn}
        bankName={bankName}
        loginStrategies={loginStrategies}
      />
    )
  }

  return (
    <S.SelectedBankWrapper>
      <S.BankHeader>
        <BankIcon bankName={bankName} />
        <h3>{fullBankName}</h3>
      </S.BankHeader>
      {loginInProcessUI}
    </S.SelectedBankWrapper>
  )
}

export default SelectedBank
