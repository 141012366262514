import { Outlet } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import { CypressHistorySupport } from "cypress-react-router"
import { ReactQueryDevtools } from "react-query/devtools"
import AuthProvider from "Context/AuthContext/auth-context"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
})

const Router = () => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <CypressHistorySupport />
    <AuthProvider>
      <Outlet />
    </AuthProvider>
  </QueryClientProvider>
)

export default Router
