export function isOfType<T>(value: unknown): value is T {
  return (value as T) !== null
}

export function jsonParse<T>(item: string): T | null {
  try {
    const parsedItem = JSON.parse(item)
    if (isOfType<T>(parsedItem)) {
      return parsedItem
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("JSON parse error", e)
  }
  return null
}
