import { ChangeEvent, FC } from "react"
import { useLocation } from "react-router-dom"

import TrashOverlay from "Components/TrashOverlay/TrashOverlay"
import { ICING_EVENTS } from "Events/List"
import ScrapedItem from "./ScrapedItem/ScrapedItem"
import { publish } from "../../../../Events"

import { Installment, Credit, Loan, SelectedLoans } from "./LoanItem.types"

interface LoanItemProps {
  loan: Loan | Installment | Credit
  checkboxId: string
  onAddTempLoan: (loan: SelectedLoans) => void
  onRemoveLoan: (id: string) => void
  isMainInterface?: boolean
}

const LoanItem: FC<LoanItemProps> = ({
  loan,
  checkboxId,
  onAddTempLoan,
  onRemoveLoan,
  isMainInterface,
}) => {
  const location = useLocation()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isMainInterface && event.target.checked) {
      publish(ICING_EVENTS.CLICK_SELECT_LOAN, {
        standalone: location.pathname === "/iceing",
      })
      onAddTempLoan(loan)
    } else if (!isMainInterface && !event.target.checked) {
      publish(ICING_EVENTS.CLICK_REMOVE_LOAN, {
        standalone: location.pathname === "/iceing",
      })
      onRemoveLoan(loan.id)
    }
  }

  return isMainInterface ? (
    <TrashOverlay onClick={() => onRemoveLoan(loan.id)}>
      <ScrapedItem
        loan={loan}
        checkboxId={checkboxId}
        onHandleLoanItem={handleChange}
        isMainInterface={isMainInterface}
      />
    </TrashOverlay>
  ) : (
    <ScrapedItem
      loan={loan}
      checkboxId={checkboxId}
      onHandleLoanItem={handleChange}
      isMainInterface={isMainInterface}
    />
  )
}

export default LoanItem
