import { FormattedMessage } from "react-intl"
import { AnimatePresence } from "framer-motion"
import { useState } from "react"
import { useQueryClient } from "react-query"

import IconFactory from "UI/IconFactory"
import Modal from "UI/Modal"
import {
  BASIC_LOAN_SERVICES_DATA,
  BASIC_LOAN_SERVICES_SERVICE,
} from "Constants/queryKeys"
import { useGetInsuranceEligibilities } from "Hooks/API/useInurance"
import { InsurancePromotionModalContent } from "./InsurancePromotionModalContent/InsurancePromotionModalContent"
import * as S from "./InsurancePromotion.styles"

interface InsurancePromotionProps {
  loanId?: string
  onSuccess?: () => void
}

export const InsurancePromotion = ({
  loanId,
  onSuccess,
}: InsurancePromotionProps) => {
  const queryClient = useQueryClient()
  const insuranceEligibilitiesQuery = useGetInsuranceEligibilities()

  const [showSignInsuranceModal, setShowSignInsuranceModal] = useState(false)

  const openSingModal = () => {
    setShowSignInsuranceModal(true)
  }

  const closeSingModal = () => {
    setShowSignInsuranceModal(false)
    queryClient.invalidateQueries([BASIC_LOAN_SERVICES_DATA])
    queryClient.invalidateQueries([BASIC_LOAN_SERVICES_SERVICE])
  }

  return (
    <>
      <S.InsurancePromotionButton onClick={openSingModal}>
        <IconFactory name="shield" />
        <S.PromotionText>
          <FormattedMessage
            {...(insuranceEligibilitiesQuery.data?.free_months
              ? {
                  id: "app.insurancePromotion.banner.hasFreeMonth.text",
                  values: {
                    freeMonths: insuranceEligibilitiesQuery.data.free_months,
                  },
                }
              : {
                  id: "app.insurancePromotion.banner.noFreeMonths.text",
                })}
          />
        </S.PromotionText>
        <IconFactory name="chevronRight" />
      </S.InsurancePromotionButton>
      <AnimatePresence>
        {showSignInsuranceModal && (
          <Modal
            maxWidth="650px"
            onClick={closeSingModal}
            body={
              <InsurancePromotionModalContent
                closeModal={closeSingModal}
                loanId={loanId}
                onSuccess={onSuccess}
              />
            }
          />
        )}
      </AnimatePresence>
    </>
  )
}
