import { font, mediaQueries } from "Styles/styleGlobal"
import { InputWrap } from "UI/Input/Input.styles"
import { flex } from "config/mixins"
import styled from "styled-components"

export const Nav = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 2em;
  @media screen and (max-width: ${mediaQueries.medium}) {
    padding: 1em 2em;
  }
  .opened {
    svg path.line {
      stroke: var(--white);
    }
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1em;
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const UnderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: 2em;
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  padding: 1em;
`

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  padding: 1em;
`

export const SubmitButtonContainer = styled.div`
  ${flex.center}
  margin: 1em 0;
`

export const BackButton = styled.button`
  ${flex.center}
  width: 35px;
  height: 35px;
`

export const CloseWrapper = styled.button`
  ${flex.center}
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--light-gray);
  cursor: pointer;
  svg path {
    stroke: var(--dark-gray);
    stroke-width: 5;
  }
`

export const SliderWrapper = styled.div`
  margin: 0.5em 0;
`
export const SliderHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

export const SliderHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`

export const SliderHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 850px;
  padding: 0 1em 1em 1em;
`

export const TinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 850px;
  width: 100%;
`

export const Description = styled.p`
  font-size: ${font.size.xl2};
  text-align: center;
  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    font-size: ${font.size.xl};
  }
`

export const Explanation = styled.p`
  font-size: ${font.size.sm};
  color: var(--dark-gray);
  text-align: center;
  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    font-size: ${font.size.xs};
  }
`

export const LoadingContainer = styled.div`
  height: 60vh;
`

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1em;

  > *:not(:last-child) {
    margin-bottom: 30px;
  }
`

export const ToggleLabel = styled.div`
  line-height: 1.5em;
  > div {
    margin: 1em 0;
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 4;
`

export const MonthlyCostWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: ${font.size.xl};
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-top: 1em;
`

export const MonthlyCostAmount = styled.div`
  font-weight: bold;
  font-size: ${font.size.xl5};
`

export const DynamicFieldsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 0 3em 0;
`

export const InputGridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
  column-gap: 1em;
  h4 {
    font-size: ${font.size.base};
    margin: 0;
  }
  select {
    & + span {
      top: 6px;
    }
  }

  ${InputWrap} {
    input {
      height: 60px;
    }
    label[for="purpose_of_saving"] {
      top: -5px;
    }
    &:focus-within label[for="purpose_of_saving"] {
      top: 6px;
    }
    .active {
      top: 6px !important;
      line-height: 1.5em;
    }
  }
  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    grid-template-columns: 1fr;
    ${InputWrap} {
      label[for="purpose_of_saving"] {
        top: 6px;
      }
      &:focus-within label[for="purpose_of_saving"] {
        top: 9px;
      }
    }
  }

  @media screen and (max-width: ${mediaQueries.large}) and (min-width: ${mediaQueries.medium}) {
    ${InputWrap} {
      label[for="business_focus"],
      label[for="purpose_of_saving"] {
        top: -5px;
      }
      &:focus-within label[for="purpose_of_saving"] {
        top: 5px;
      }
      &:focus-within label[for="business_focus"] {
        top: 5px;
      }
    }
  }

  @media screen and (max-width: ${mediaQueries.medium}) {
    ${InputWrap} {
      label[for="purpose_of_saving"] {
        top: 1px;
      }
    }
  }

  @media screen and (max-width: ${mediaQueries.small}) {
    ${InputWrap} {
      label[for="business_focus"],
      label[for="purpose_of_saving"] {
        top: -9px;
      }
      &:focus-within label[for="purpose_of_saving"] {
        top: 5px;
      }
      &:focus-within label[for="business_focus"] {
        top: 5px;
      }
    }
  }
`
export const Terms = styled.span`
  font-size: ${font.size.sm};
  color: var(--black);
  line-height: 1.5em;
  margin-top: 2em;
`

export const Calculation = styled.span`
  font-size: ${font.size.sm};
  color: var(--dark-gray);
  line-height: 1.5em;
`

export const ButtonLink = styled.button`
  color: var(--dark-yellow);
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
`
