import styled from "styled-components"

export const NavLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  font-weight: 500;
  text-decoration: none;
  color: var(--black);
  border-bottom: 1px solid var(--light-gray);
  transition: 0.2s ease-in-out all;

  svg path {
    stroke: var(--black);
  }
  &:last-of-type {
    border-bottom: 0;
  }
  &:hover {
    color: var(--dark-yellow);
    svg {
      path {
        fill: var(--dark-yellow);
      }
    }
  }
`
