import { useEffect, useMemo } from "react"
import { FormattedMessage } from "react-intl"
import { useInView } from "react-intersection-observer"

import { useGetInvoices } from "Hooks/API/useInvoices"
import InvoicesTableRow from "Components/InvoicesTableRow"
import Loading from "UI/Loading"
import * as S from "./TableInvoices.styles"

const TableInvoices = () => {
  const invoices = useGetInvoices(1)
  const { ref, inView } = useInView()

  const transactionsList = useMemo(() => {
    if (invoices.data) {
      return invoices.data.pages.flatMap((page) => page.data)
    }
    return []
  }, [invoices.data])

  useEffect(() => {
    if (inView) {
      invoices.fetchNextPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  if (invoices.isLoading || !invoices.data) {
    return (
      <S.LoadingContainer>
        <Loading />
      </S.LoadingContainer>
    )
  }

  return (
    <S.Table>
      <S.TableHeadWrap>
        <S.TableHead>
          <FormattedMessage
            id="app.mypages.loans.invoices.table.dueDate"
            defaultMessage="Förfallodatum"
          />
        </S.TableHead>
        <S.TableHead>
          <FormattedMessage
            id="app.mypages.loans.invoices.table.invoiceNumber"
            defaultMessage="Fakturanummer"
          />
        </S.TableHead>
        <S.TableHead>
          <FormattedMessage
            id="app.mypages.loans.invoices.table.status"
            defaultMessage="Status"
          />
        </S.TableHead>
        <S.TableHead hide>
          <FormattedMessage
            id="app.mypages.loans.invoices.table.paymentMethod"
            defaultMessage="Betalsätt"
          />
        </S.TableHead>
        <S.TableHead hide>
          <FormattedMessage
            id="app.mypages.loans.invoices.table.amount"
            defaultMessage="Belopp"
          />
        </S.TableHead>
        <S.TableHead hide>
          <FormattedMessage
            id="app.mypages.loans.invoices.table.payedAmount"
            defaultMessage="Betalt Belopp"
          />
        </S.TableHead>
        <S.TableHead hide>
          <FormattedMessage
            id="app.mypages.loans.invoices.table.download"
            defaultMessage="Ladda ned"
          />
        </S.TableHead>
        <S.TableHead chevron>
          <FormattedMessage id="app.common.expand" defaultMessage="Expandera" />
        </S.TableHead>
      </S.TableHeadWrap>
      <S.TableBody>
        {transactionsList.map((invoice) => (
          <InvoicesTableRow key={invoice.uid} invoice={invoice} />
        ))}
      </S.TableBody>
      {invoices.isFetching && <Loading isCentered />}
      <span ref={ref} />
    </S.Table>
  )
}

export default TableInvoices
