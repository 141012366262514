import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useIntl } from "react-intl"

import { Urls } from "Constants/urls"
import { DepositServiceStatus } from "types/enums"
import { useGetDepositServiceByID } from "Hooks/API/useDepositServices"
import Loading from "UI/Loading"
import SavingsOverview from "./SavingsOverview"
import Transactions from "./Transactions"
import * as S from "./SavingsHome.styles"

const SavingsHome = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { accountID } = useParams() as { accountID: string }

  const savingsData = useGetDepositServiceByID(accountID)

  useEffect(() => {
    if (savingsData.data?.status === DepositServiceStatus.created) {
      navigate(Urls.Home)
    }
  }, [savingsData, navigate])

  if (savingsData.status === "loading") {
    return (
      <S.LoadingContainer>
        <Loading />
      </S.LoadingContainer>
    )
  }

  return (
    <S.SavingsHomeWrap>
      {savingsData.data?.status === DepositServiceStatus.closed && (
        <S.ClosedAccountMessage>
          {intl.formatMessage({
            id: "app.mypages.savings.accounts.closedAccount",
            defaultMessage:
              "Kontot är avslutat och visar endast historiska transaktioner.",
          })}
        </S.ClosedAccountMessage>
      )}
      {savingsData.data?.status !== DepositServiceStatus.closed && (
        <SavingsOverview />
      )}
      <Transactions />
    </S.SavingsHomeWrap>
  )
}

export default SavingsHome
