import * as yup from "yup"

export const InsuranceFormSchema = yup
  .object()
  .shape({
    insuranceChecked: yup.boolean().oneOf([true]).required(),
    readPrePurchaseInfo: yup.boolean().oneOf([true]).required(),
    fitForWork: yup.boolean().oneOf([true]).required(),
  })
  .required()
