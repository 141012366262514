import { useState } from "react"

import { AutostartTokenSign } from "../AutostartTokenSign/AutostartTokenSign"
import { BankIDAuthMethodsMobile } from "../BankIDAuthMethodsMobile/BankIDAuthMethodsMobile"
import { SignBankIDQRCode } from "../SignBankIDQRCode/SignBankIDQRCode"
import { SignFlowError } from "../SignFlowError/SignFlowError"

import * as S from "./SignFlowMobile.styles"

export const SignFlowMobile = ({
  startSigning,
  autostartToken,
  cancelSigning,
  isSigning,
  qrCode,
  errorMessage,
  isError,
  isLoading,
}: {
  isSigning: boolean
  qrCode: string
  autostartToken: string
  cancelSigning: () => void
  startSigning: () => void
  isError: boolean
  errorMessage: string
  isLoading: boolean
}) => {
  const [showAuthWithAutostartToken, setShowAuthWithAutostartToken] =
    useState(false)
  const [showAuthWithQRCode, setShowAuthWithQRCode] = useState(false)

  const authWithAutostartToken = () => {
    startSigning()
    setShowAuthWithAutostartToken(true)
  }
  const authWithQRCode = () => {
    startSigning()
    setShowAuthWithQRCode(true)
  }

  const handleTryAgain = () => {
    cancelSigning()
    setShowAuthWithAutostartToken(false)
    setShowAuthWithQRCode(false)
  }

  if (isError) {
    return (
      <SignFlowError
        errorMessage={errorMessage}
        handleTryAgain={handleTryAgain}
      />
    )
  }

  if (showAuthWithAutostartToken && autostartToken) {
    return (
      <S.SignFlowMobileBlock className="selected-method">
        <AutostartTokenSign
          autostartToken={autostartToken}
          cancelAuth={cancelSigning}
          isSigning={isSigning}
        />
      </S.SignFlowMobileBlock>
    )
  }

  if (showAuthWithQRCode && qrCode) {
    return (
      <S.SignFlowMobileBlock className="selected-method">
        <SignBankIDQRCode
          authWithAutostartToken={authWithAutostartToken}
          qrCode={qrCode}
          isSigning={isSigning}
          isLoading={isLoading}
        />
      </S.SignFlowMobileBlock>
    )
  }

  return (
    <BankIDAuthMethodsMobile
      authWithAutostartToken={authWithAutostartToken}
      authWithBankId={authWithQRCode}
      isLoading={isLoading}
    />
  )
}
