import axios from "axios"
import {
  ACCESS_TOKEN,
  LOGIN_TIMESTAMP_IN_MS,
  TOKEN_EXPIRES_IN_SECONDS,
} from "Constants/storage"
import { getEnv, EnvKeysEnum } from "Utils/environment"
import { cleanStorageAndAxios } from "Context/AuthContext/helpers"
import { Urls } from "Constants/urls"
import { logout } from "./user"

const instance = axios.create({
  baseURL: getEnv(EnvKeysEnum.BroccApi),
})

export const setApiAuthorizationHeader = (token: string) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const removeApiAuthorizationHeader = () => {
  delete instance.defaults.headers.common.Authorization
}

instance.interceptors.request.use((config) => {
  const userLoggedInMS = Number(localStorage.getItem(LOGIN_TIMESTAMP_IN_MS))
  const tokenExpiresInMS =
    Number(localStorage.getItem(TOKEN_EXPIRES_IN_SECONDS)) * 1000

  if (
    userLoggedInMS &&
    tokenExpiresInMS &&
    Date.now() > userLoggedInMS + tokenExpiresInMS
  ) {
    logout()
    cleanStorageAndAxios()
    window.location.href = Urls.Login
  }

  return config
})

setApiAuthorizationHeader(localStorage.getItem(ACCESS_TOKEN) || "")

export default instance
