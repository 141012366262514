import { useQuery, useMutation } from "react-query"
import { getBanks, scrapeBank } from "ApiServices/iceing"
import { ScrapingBank } from "Interfaces/iceingInterfaces"

export function useIceingBanks() {
  return useQuery({
    queryKey: ["getBanks"],
    queryFn: getBanks,
  })
}

export function useIceingScrapeBanks() {
  return useMutation({
    mutationFn: (bankData: ScrapingBank) => scrapeBank({ ...bankData }),
    onError: (error) => {
      return Promise.reject(error)
    },
    retry: false,
  })
}
