import { FC, useState } from "react"
import { FormattedMessage } from "react-intl"

import IconFactory from "UI/IconFactory"
import { getFirstName } from "Utils/getFirstName"
import Modal from "UI/Modal/Modal"

import { IDepositServicesAffectedByInterestRate } from "Interfaces/depositInterfaces"
import { formatNumber } from "Utils/formatNumber"
import * as S from "./UserGreetings.styles"

type UserGreetingsPropTypes = {
  username: string
  interestRateChange?: IDepositServicesAffectedByInterestRate
}

const UserGreetings: FC<UserGreetingsPropTypes> = ({
  username,
  interestRateChange,
}) => {
  const [isLoanModalOpen, setIsLoanModalOpen] = useState(false)

  const toggleLoanModalOpen = () => {
    setIsLoanModalOpen(!isLoanModalOpen)
  }

  const name = getFirstName(username)

  return (
    <>
      <h1>
        <FormattedMessage id="app.mypages.home.greeting" /> {name}!
      </h1>

      {interestRateChange?.is_affected &&
        interestRateChange?.from &&
        interestRateChange?.to && (
          <>
            <S.LoanBanner type="button" onClick={toggleLoanModalOpen}>
              <IconFactory name="infoBadge" />
              &nbsp;
              <span>
                <FormattedMessage
                  id="app.mypages.interestDecreaseBanner.heading"
                  defaultMessage="Information om villkorsändring"
                />
              </span>
            </S.LoanBanner>

            {isLoanModalOpen && (
              <Modal
                title={
                  <FormattedMessage
                    id="app.mypages.interestDecreaseBanner.heading"
                    defaultMessage="Information om villkorsändring"
                  />
                }
                onClick={toggleLoanModalOpen}
                fullWidth
                maxWidth="700px"
                body={
                  <p>
                    <FormattedMessage
                      id="app.mypages.interestDecreaseBanner.text"
                      values={{
                        from: formatNumber(interestRateChange.from, {
                          style: "percent",
                        }),
                        to: formatNumber(interestRateChange.to, {
                          style: "percent",
                        }),
                      }}
                    />
                    <span
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {interestRateChange.date}.
                    </span>
                  </p>
                }
              />
            )}
          </>
        )}
    </>
  )
}

export default UserGreetings
