import styled from "styled-components"
import { motion } from "framer-motion"
import { font, mediaQueries } from "Styles/styleGlobal"
import { flex } from "config/mixins"
import { StyledDropdownTitleProps, StyledChevronProps } from "../Dropdown.types"

export const DropdownTitle = styled(motion.div)<StyledDropdownTitleProps>`
  ${flex.alignCenterBetween}
  font-size: ${font.size.lg};
  padding: 0.5em 0;
  margin: 0.25em 0;
  color: var(--black);
  font-weight: 700;
  border-radius: 16px;
  cursor: pointer;
  color: ${(p) =>
    p.$active && p.$firstLevel ? "var(--dark-yellow)" : p.theme.fontColor};
  @media screen and (max-width: ${mediaQueries.medium}) {
    font-size: ${font.size.base};
  }
`

export const StyledChevron = styled(motion.div)<StyledChevronProps>`
  line-height: 0;
  path {
    stroke: ${(p) => (p.$active ? "var(--dark-yellow);" : p.theme.fontColor)};
  }
`

export const dropdownTitleVariant = {
  opened: {
    background: "rgba(var(--light-yellow-rgb), 0.2)",
    boxShadow: "rgba(var(--light-yellow-rgb), 1) 0px 0px 0px 3px inset",
  },
  closed: {
    background: "rgba(var(--light-yellow-rgb), 0.0)",
    boxShadow: "rgb(var(--light-yellow-rgb), 0) 0px 0px 0px 3px inset",
  },
}

export const iconVariants = {
  up: { transform: "rotate(90deg)" },
  down: { transform: "rotate(0deg)" },
}
