import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"
import Button from "UI/Button"

export const ModalBody = styled.div`
  padding: calc(32px - 0.5em) 0 calc(32px - 0.5em) calc(39px - 2em);
  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 46px;
    margin: 0 0 32px;
    @media screen and (max-width: ${mediaQueries.medium}) {
      margin: 0 0 10px;
    }
  }

  ul {
    margin: 1.5em 0;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: ${mediaQueries.small}) {
      gap: 10px;
    }
    @media screen and (max-width: ${mediaQueries.medium}) {
      margin-top: 15px;
    }
    li {
      list-style: none;
      align-items: center;
      text-align: left;
      display: grid;
      grid-template-columns: 30px 1fr;
      gap: 16px;
      &:nth-of-type(2) {
        svg {
          width: 30px;
        }
      }
      p {
        font-size: 18px;
        margin: 0;
        line-height: 46px;
        @media screen and (max-width: ${mediaQueries.small}) {
          line-height: 32px;
        }
      }
      svg {
        width: 25px;
        height: 30px;
      }
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  width: 40px;
  height: 40px;
  svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    top: 5px;
    right: 5px;
  }
`

export const PlusButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--light-gray);
  margin-left: auto;
  transition: 0.2s ease-in-out all;
  position: relative;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  svg {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const CreateApplicaionButton = styled(Button)`
  width: 100%;
`
