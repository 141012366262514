import React, { useCallback } from "react"
import { FormattedMessage } from "react-intl"
import Form from "Components/KYC/Form"
import { SAVINGS_ONBOARDING } from "Constants/storage"
import {
  getParsedStorageItemOrDefault,
  setStorageItem,
} from "Utils/localStorage"
import { useParams } from "react-router-dom"
import { SavingsOnboardingData } from "../SavingsOnboarding.types"

const KYCStep = ({
  setStep,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>
}) => {
  const { accountID: id } = useParams() as { accountID: string }

  const savingsOnboardingData =
    getParsedStorageItemOrDefault<SavingsOnboardingData>(SAVINGS_ONBOARDING, {})

  const submitCallback = useCallback(() => {
    setStep((prev) => prev + 1)

    if (savingsOnboardingData[id]) {
      const updatedStatus: SavingsOnboardingData = {
        ...savingsOnboardingData,
        [id]: {
          ...savingsOnboardingData[id],
          brocc_has_saved_kyc: true,
        },
      }
      setStorageItem(SAVINGS_ONBOARDING, updatedStatus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setStep])

  return (
    <Form
      hasDepositOrInvestment
      submitCallback={submitCallback}
      buttonText={
        <FormattedMessage
          id="app.mypages.investments.onboarding.KYC.nextCTA"
          defaultMessage="Gå vidare"
        />
      }
    />
  )
}

export default KYCStep
