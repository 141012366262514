import { FC } from "react"
import { FormattedMessage } from "react-intl"

import { formatNumber } from "Utils/formatNumber"
import * as S from "./InformationOfCosts.styles"

interface InformationOfCostsProps {
  monthlyCosts: number
  totalAmountCosts: number
  periodCost: number | undefined
  amount: number | undefined
}

const InformationOfCosts: FC<InformationOfCostsProps> = ({
  monthlyCosts,
  totalAmountCosts,
  periodCost,
  amount,
}) => {
  const getMonthlyCosts = () => {
    if (periodCost) {
      return monthlyCosts - +periodCost
    }

    return 0
  }

  const getTotalAmount = () => {
    if (amount) {
      return +amount - totalAmountCosts
    }

    return 0
  }

  const isAppliedAmountLowerThanTotalAmount = () => {
    if (amount) {
      return amount < totalAmountCosts
    }

    return false
  }

  const newMonthlyCost = getMonthlyCosts()
  const newTotalAmounts = getTotalAmount()

  if (isAppliedAmountLowerThanTotalAmount()) {
    return null
  }

  return (
    <S.InformationContainer>
      {newMonthlyCost > 0 && (
        <S.MonthlyCostText>
          <FormattedMessage
            id="app.mypages.iceing.monthlyCostReducedBy"
            values={{
              amount: formatNumber(newMonthlyCost, { style: "currency" }),
            }}
          />
        </S.MonthlyCostText>
      )}
      <p>
        <FormattedMessage
          id="app.mypages.iceing.refinance"
          values={{
            amount: formatNumber(totalAmountCosts, { style: "currency" }),
          }}
        />
      </p>
      {newTotalAmounts > 0 && (
        <p>
          <FormattedMessage
            id="app.mypages.iceing.remainingToPay"
            values={{
              amount: formatNumber(newTotalAmounts, { style: "currency" }),
            }}
          />
        </p>
      )}
    </S.InformationContainer>
  )
}

export default InformationOfCosts
