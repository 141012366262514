import { useEffect, FC, useRef } from "react"
import { useParams } from "react-router-dom"
import { FormattedMessage } from "react-intl"

import type { ILoanApplication } from "Interfaces/loanApplicationInterfaces"
import { useGenerateAgreement } from "Hooks/API/useLoansApplications"
import useQueryParams from "Hooks/UI/useQueryParams"
import IconFactory from "UI/IconFactory"
import { EnvKeysEnum, getEnv } from "Utils/environment"
import LoanPerksBox from "../../Shared/LoanPerksBox"
import GrayBox from "./GrayBox"
import SigningButtons from "./SigningButtons"

import * as Styles from "../../Onboarding.styles"
import * as SigningStyles from "./Signing.styles"

const Signing: FC<{ data: ILoanApplication }> = ({ data }) => {
  const { id } = useParams() as { id: string }
  const verifiying = useRef(true)

  const agreement = useGenerateAgreement(id)

  const query = useQueryParams()

  useEffect(() => {
    if (verifiying.current) {
      verifiying.current = false
      if (
        !data.agreement_file_uid &&
        !query.get("co-applicant") &&
        !agreement.isLoading
      ) {
        agreement.mutate()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (query.get("co-applicant") && !data.agreement_file_uid) {
    return (
      <SigningStyles.WaitForAgreementContainer>
        <SigningStyles.PauseContainer>
          <IconFactory name="pause" />
        </SigningStyles.PauseContainer>
        <h1>
          <FormattedMessage id="app.onboarding.signing.no-agreement.heading" />
        </h1>
        <p>
          <FormattedMessage id="app.onboarding.signing.no-agreement.body" />
        </p>
      </SigningStyles.WaitForAgreementContainer>
    )
  }

  return (
    <Styles.Container>
      <h1>
        <FormattedMessage
          id="app.onboarding.signing.heading"
          defaultMessage="Signera ditt avtal"
        />
      </h1>
      <GrayBox />
      <Styles.SmallPrint>
        <FormattedMessage
          id="app.onboarding.signing.smallPrint"
          defaultMessage="Genom att skriva under intygar du att uppgifterna du lämnat stämmer och att du har förstått avtalsvillkoren."
        />
        &nbsp;
        <a
          href={`${getEnv(
            EnvKeysEnum.BroccApi
          )}/loan-applications/${id}/agreement/pdf`}
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage
            id="app.onboarding.signing.aggreementLink"
            defaultMessage="Se ditt avtal här"
          />
        </a>
      </Styles.SmallPrint>
      <SigningButtons loanApplication={data} />
      <Styles.MobileAttentionBox>
        <LoanPerksBox estimatedCashBack={data.estimated_cashback} />
      </Styles.MobileAttentionBox>
    </Styles.Container>
  )
}

export default Signing
