import styled from "styled-components"

export const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 80vh;
`

export const SuccessWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 80vh;

  svg {
    margin: 2em 0;
  }
`

export const IndependentBankScreeningWrap = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
