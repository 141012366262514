export const MAX_UPCOMING = 10
export const MIN_SAVING_AMOUNT = 250

export const FIXED_PROFILE_TYPE = 2
export const FLEX_PROFILE_TYPE = 3

export const INSURANCE_AMOUNT_MAX = 500000
export const INSURANCE_TERMS_AND_CONDITIONS_AMOUNT_TIMES = 50

export const ACCURED_INTEREST_TAX = 30

export const INSURANCE_COMPENSATION_AMOUNT_MAX = 10000
