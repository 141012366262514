import { FC, useRef, useState, useCallback, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useNavigate } from "react-router-dom"

import {
  useGetLoanHasRecentTermChangeRequest,
  useRequestLoanTermChange,
} from "Hooks/API/useBasicLoanServices"
import { reCalcPMT } from "Utils/calculations"
import Range from "UI/Range"
import Button from "UI/Button"
import IconFactory from "UI/IconFactory"
import { TextareaField } from "Components/form/TextareaField"
import { Urls } from "Constants/urls"
import { formatNumber } from "Utils/formatNumber"
import { useFeedbackModal } from "Context/modal-context"
import { getChangeTermSchema } from "./ChangeTerm.validation"
import { ChangeTermRequest, ChangeTermModalProps } from "./ChangeTerm.types"
import * as S from "./ChangeTermModal.styles"

const ChangeTermModal: FC<ChangeTermModalProps> = ({ closeModal, loan }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { errorModal } = useFeedbackModal()
  const monthlyAmountEl = useRef() as React.MutableRefObject<HTMLInputElement>

  const postLoanTerm = useRequestLoanTermChange()

  const {
    data: hasRecentTermChangeRequest,
    refetch: refetchHasTermChangeRequest,
  } = useGetLoanHasRecentTermChangeRequest(loan.uid)

  const loanValues = useMemo(
    () => ({
      loanInterestRate: loan.loan_interest_rate,
      loanServiceFee: loan.loan_service_fee,
      remainingPrincipal: loan.remaining_principal,
    }),
    [loan.loan_interest_rate, loan.loan_service_fee, loan.remaining_principal]
  )
  const [isChangeApplied, setIsChangeApplied] = useState(false)
  const [amount, setAmount] = useState<number>(
    () => reCalcPMT(loanValues, loan.remaining_repayment_periods) || 0
  )

  const form = useForm<ChangeTermRequest>({
    values: {
      repaymentPeriods: loan.remaining_repayment_periods,
      reason: "",
    },
    resolver: yupResolver(
      getChangeTermSchema(loan.is_eligible_for_loan_term_change)
    ),
  })

  const onSubmit: SubmitHandler<ChangeTermRequest> = (values) => {
    postLoanTerm
      .mutateAsync({
        uid: loan.uid,
        ...values,
      })
      .then((res) => {
        if (res.contract_uid) {
          navigate(
            `${Urls.Loans}/${Urls.LoansProposedContract.replace(
              ":id",
              res.contract_uid
            )}`
          )
        } else {
          refetchHasTermChangeRequest()
          setIsChangeApplied(true)
        }
      })
      .catch(() => {
        errorModal()
      })
  }
  const hasRecentRequest =
    loan.has_recent_term_change_request || hasRecentTermChangeRequest
  const repaymentPeriods = form.watch("repaymentPeriods")

  const handleRangeChange = useCallback(() => {
    const {
      min: minStr,
      max: maxStr,
      value: valueStr,
    } = monthlyAmountEl.current
    const [min, max, value]: number[] = [minStr, maxStr, valueStr].map(Number)
    monthlyAmountEl.current.style.backgroundSize = `${
      ((value - min) * 100) / (max - min)
    }% 100%`

    form.setValue("repaymentPeriods", value)
    setAmount(reCalcPMT(loanValues, value) || 0)
  }, [monthlyAmountEl, loanValues, form])

  let textContent: JSX.Element = (
    <>
      <IconFactory name="glasses" />
      <h6>
        <FormattedMessage
          id="app.mypages.loans.changeTermModal.title"
          defaultMessage="Ändra månadskostnad när du vill"
        />
      </h6>
      <p>
        <FormattedMessage
          id="app.mypages.loans.changeTermModal.paragraph"
          defaultMessage="Anpassa dit lån efter din vardag genom att ändra löptiden."
        />
      </p>
    </>
  )

  if (isChangeApplied) {
    textContent = (
      <>
        <IconFactory name="like" />
        <h6>
          <FormattedMessage
            id="app.mypages.loans.changeTermModal.changeApplied.title"
            defaultMessage="Begäran av ny löptid har skickats"
          />
        </h6>
        <p>
          <FormattedMessage
            id="app.mypages.loans.changeTermModal.changeApplied.paragraph"
            defaultMessage="Vi granskar din ändring av löptid. Du får besked via e-post!"
          />
        </p>
      </>
    )
  }

  if (postLoanTerm.isError) {
    textContent = (
      <>
        <IconFactory name="pauseNoBorder" />
        <p>
          <FormattedMessage
            id="app.login.error.generalError"
            defaultMessage="Något gick fel. Prova igen senare eller kontakta oss om problemet kvarstår."
          />
        </p>
        <Button type="button" onClick={closeModal}>
          <FormattedMessage
            id="app.common.tryAgain"
            defaultMessage="Prova igen"
          />
        </Button>
      </>
    )
  }

  return (
    <S.ChangeTermForm noValidate onSubmit={form.handleSubmit(onSubmit)}>
      <S.ChangeTermDescriptionBlock>{textContent}</S.ChangeTermDescriptionBlock>
      {hasRecentRequest && !isChangeApplied && !postLoanTerm.isError && (
        <S.ChangeIsNotAvailible>
          <FormattedMessage
            id="app.mypages.loans.changeTermModal.changeAlreadyRequested"
            defaultMessage="Du har nyligen begärt ändring av löptid."
          />
        </S.ChangeIsNotAvailible>
      )}

      {loan.remaining_repayment_periods >= loan.max_prolong_term && (
        <S.ChangeIsNotAvailible>
          <FormattedMessage
            id="app.mypages.loans.changeTermModal.prolongExceeded"
            defaultMessage="Du kan tyvärr inte ändra din löptid eftersom du redan har maximal löptid"
          />
        </S.ChangeIsNotAvailible>
      )}

      {loan.remaining_repayment_periods < loan.max_prolong_term &&
        !hasRecentRequest &&
        !isChangeApplied &&
        !postLoanTerm.isError && (
          <S.ChangeAvailibleBlock>
            <S.RangeLabels>
              <label>
                {repaymentPeriods}
                &nbsp;
                <FormattedMessage
                  id="app.common.months"
                  defaultMessage="månader"
                />
              </label>
              <label>{formatNumber(amount, { style: "currency" })}</label>
            </S.RangeLabels>
            <Range
              min={loan.remaining_repayment_periods}
              max={loan.max_prolong_term}
              onChange={handleRangeChange}
              step={1}
              name="repaymentPeriods"
              value={repaymentPeriods || 0}
              elRef={monthlyAmountEl}
              required
            />
            <S.SpanLabels>
              <span>
                <FormattedMessage
                  id="app.mypages.loans.changeTermModal.term"
                  defaultMessage="Löptid"
                />
              </span>
              <span>
                <FormattedMessage
                  id="app.mypages.loans.changeTermModal.monthlyCost"
                  defaultMessage="Månadskostnad"
                />
              </span>
            </S.SpanLabels>

            {!loan.is_eligible_for_loan_term_change && (
              <TextareaField
                className="textarea-field"
                required
                name="reason"
                control={form.control}
                minLength={10}
                maxLength={100}
                rules={{ minLength: 10, maxLength: 100 }}
                placeholder={intl.formatMessage({
                  id: "app.mypages.loans.changeTermModal.textarea.placeholder",
                  defaultMessage: "Anledning",
                })}
              />
            )}

            <Button
              type="submit"
              isLoading={postLoanTerm.isLoading}
              disabled={
                postLoanTerm.isLoading ||
                repaymentPeriods === loan.remaining_repayment_periods
              }
            >
              {intl.formatMessage({
                id: "app.mypages.loans.changeTermModal.button",
                defaultMessage: "Begär ändring av löptid",
              })}
            </Button>
          </S.ChangeAvailibleBlock>
        )}
    </S.ChangeTermForm>
  )
}

export default ChangeTermModal
