import { forwardRef } from "react"

import * as S from "./Radio.styles"
import { RadioProps } from "./Radio.types"

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ name, value, checked, id, labelText, onChange }, ref) => (
    <S.RadioLabel htmlFor={id}>
      <S.RadioInput
        type="radio"
        ref={ref}
        name={name}
        value={value}
        id={id}
        onChange={onChange}
        checked={checked}
      />
      {labelText}
    </S.RadioLabel>
  )
)

Radio.displayName = "Radio"

export default Radio
