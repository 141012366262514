import styled, { css } from "styled-components"

export const RadioInput = styled.input`
  margin: 0.25em 1em 0.25em 0;
  cursor: pointer;
  min-width: 25px;
  min-height: 25px;
  -webkit-appearance: none;
  appearance: none;
  color: red;
  font: inherit;
  border-radius: 50%;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--dark-yellow);
  }

  ${(p) => css`
    background-color: ${p.theme.background};
    border: 2px solid ${p.theme.fontColor};
    &::before {
      transform: ${p.checked ? "scale(1)" : "scale(0)"};
    }
  `}
`
export const RadioLabel = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ErrorMessage = styled.div`
  padding-top: 0.5em;
  color: var(--brand-danger);
`
