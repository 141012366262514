import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { BROCC_LANDING_LOGIN } from "Constants/externalLinks"
import { useAuth } from "Context/AuthContext/auth-context"
import { useLogin } from "Hooks/API/useLogin"
import InformationBanner from "UI/InformationBanner"
import { BankIDQRCode } from "Components/BankIDAuth/BankIDQRCode/BankIDQRCode"
import { AutostartToken } from "Components/BankIDAuth/AutostartToken/AutostartToken"
import { BankIDAuthMethods } from "Components/BankIDAuth/BankIDAuthMethods/BankIDAuthMethods"
import * as S from "./LoginMethods.styles"

export const LoginMethods = () => {
  const { tokenExpired } = useAuth()
  const {
    startLogin,
    cancelLogin,
    qrCode,
    isLoading,
    autostartToken,
    errorMessage,
    isError,
    isSigning,
  } = useLogin({ onError: () => cancelAuthHandler() })

  const [showTokenExpiredMessage, setShowTokenExpiredMessage] =
    useState(tokenExpired)
  const [showLoginWithBankID, setShowLoginWithBankID] = useState(false)
  const [showLoginWithAutostartToken, setShowLoginWithAutostartToken] =
    useState(false)

  const authWithBankId = () => {
    startLogin()
    setShowLoginWithBankID(true)
  }
  const authWithAutostartToken = () => {
    startLogin()
    setShowLoginWithAutostartToken(true)
  }

  const cancelAuthHandler = () => {
    cancelLogin()
    setShowLoginWithBankID(false)
    setShowLoginWithAutostartToken(false)
  }

  if (showLoginWithBankID && qrCode) {
    return (
      <S.LoginSection className="selected-method">
        <BankIDQRCode
          isSigning={isSigning}
          qrCode={qrCode}
          cancelAuth={cancelAuthHandler}
        />
      </S.LoginSection>
    )
  }

  if (showLoginWithAutostartToken && autostartToken) {
    return (
      <S.LoginSection className="selected-method">
        <AutostartToken
          isSigning={isSigning}
          autostartToken={autostartToken}
          cancelAuth={cancelAuthHandler}
        />
      </S.LoginSection>
    )
  }

  return (
    <S.LoginSection>
      {showTokenExpiredMessage && (
        <S.InformationBannerWrap>
          <InformationBanner
            type="attention"
            title={
              <FormattedMessage
                id="app.login.error.sessionExpired.title"
                defaultMessage="Din session har gått ut."
              />
            }
            onClick={() => setShowTokenExpiredMessage(false)}
            body={
              <FormattedMessage
                id="app.login.error.sessionExpired.body"
                defaultMessage="Din session har gått ut. Logga in igen."
              />
            }
          />
        </S.InformationBannerWrap>
      )}
      <S.TempWelcomeMessage>
        <h6>
          <FormattedMessage id="app.login.welcomeTitle" />
        </h6>

        <p>
          <FormattedMessage
            id="app.login.welcomeMessage"
            values={{
              link: (
                <a
                  href={BROCC_LANDING_LOGIN}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="app.common.here" defaultMessage="här" />
                </a>
              ),
            }}
          />
        </p>
      </S.TempWelcomeMessage>

      <h1>
        <FormattedMessage
          id="app.login.header"
          defaultMessage="Logga in på Brocc!"
        />
      </h1>

      <BankIDAuthMethods
        isError={isError}
        isLoading={isLoading}
        errorMessage={errorMessage}
        authWithBankId={authWithBankId}
        authWithAutostartToken={authWithAutostartToken}
      />
    </S.LoginSection>
  )
}
