import { useMemo } from "react"
import { useIntl } from "react-intl"

export const useReasonOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        id: 0,
        value: "",
        name: intl.formatMessage({
          id: "app.mypages.loans.ctaLinks.paymentFreeMonth.modal.form.selectPlaceholder",
        }),
      },
      {
        id: 1,
        value: "lower_salary",
        name: intl.formatMessage({
          id: "app.mypages.loans.ctaLinks.paymentFreeMonth.modal.form.radio.lower_salary",
        }),
      },
      {
        id: 2,
        value: "retirement",
        name: intl.formatMessage({
          id: "app.mypages.loans.ctaLinks.paymentFreeMonth.modal.form.radio.retirement",
        }),
      },
      {
        id: 3,
        value: "sickness",
        name: intl.formatMessage({
          id: "app.mypages.loans.ctaLinks.paymentFreeMonth.modal.form.radio.sickness",
        }),
      },
      {
        id: 4,
        value: "unemployed",
        name: intl.formatMessage({
          id: "app.mypages.loans.ctaLinks.paymentFreeMonth.modal.form.radio.unemployed",
        }),
      },
      {
        id: 5,
        value: "unforeseen_expenses",
        name: intl.formatMessage({
          id: "app.mypages.loans.ctaLinks.paymentFreeMonth.modal.form.radio.unforeseen_expenses",
        }),
      },
      {
        id: 6,
        value: "VAB",
        name: intl.formatMessage({
          id: "app.mypages.loans.ctaLinks.paymentFreeMonth.modal.form.radio.VAB",
        }),
      },
      {
        id: 7,
        value: "others",
        name: intl.formatMessage({
          id: "app.mypages.loans.ctaLinks.paymentFreeMonth.modal.form.radio.others",
        }),
      },
    ],
    [intl]
  )
}
