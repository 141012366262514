import { FC } from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"

import Error from "Components/Error"
import { Urls } from "Constants/urls"
import {
  BROCC_LANDING_ABOUT,
  BROCC_LANDING_MY_PAGES,
} from "Constants/externalLinks"
import * as S from "./NotFound.styles"

type NotFoundPropTypes = {
  isError?: boolean
}

const NotFound: FC<NotFoundPropTypes> = ({ isError }) => (
  <S.NotFoundSection data-cy="404">
    <S.IconWrapper />
    {isError ? (
      <Error />
    ) : (
      <>
        <S.NotFoundHeading>
          <FormattedMessage
            id="app.common.notFound.header"
            defaultMessage="Oj då!"
          />
        </S.NotFoundHeading>
        <S.NotFoundDescription>
          <FormattedMessage
            id="app.common.notFound.body"
            defaultMessage="Det verkar som att sidan du sökte efter inte hittades på denna plats. Prova länkarna nedan eller kontakta oss."
          />
        </S.NotFoundDescription>
        <S.Links>
          <Link data-testid="home-link" to={Urls.Home}>
            <FormattedMessage
              id="app.mypages.main.home.page.link"
              defaultMessage="Startsida"
            />
          </Link>
          <a href={BROCC_LANDING_ABOUT} target="_blank" rel="noreferrer">
            <FormattedMessage
              id="app.mypages.main.home.omBrocc.link"
              defaultMessage="Om oss"
            />
          </a>
          <a href={BROCC_LANDING_MY_PAGES}>
            <FormattedMessage
              id="app.mypages.main.home.minaSidor.link"
              defaultMessage="Mina sidor"
            />
          </a>
        </S.Links>
      </>
    )}
  </S.NotFoundSection>
)

export default NotFound
