import { LocaleType } from "Constants/languages"
import { createContext, useContext } from "react"

interface LangContextInterface {
  locale: LocaleType
  selectLanguage: (value: LocaleType) => void
}

export const LangContext = createContext<LangContextInterface>(
  {} as LangContextInterface
)

export const useLangContext = () => useContext(LangContext)
