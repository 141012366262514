import { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { setCurrencyByLocale } from "Utils/setCurrencyByLocale"
import { YellowAttentionBox } from "./LoanPerksBox.styles"

type LoanPerksBoxPropsType = {
  estimatedCashBack: number
}

const LoanPerksBox: FC<LoanPerksBoxPropsType> = ({ estimatedCashBack }) => {
  const intl = useIntl()

  return (
    <YellowAttentionBox>
      <ul>
        <li data-testid="first-list">
          <FormattedMessage
            id="app.onboarding.general.yellowAttentionBox.point1"
            defaultMessage="Pausa betalningen 4 gånger per år!"
          />
        </li>
        <li data-testid="second-list">
          <FormattedMessage
            id="app.onboarding.general.yellowAttentionBox.point2"
            defaultMessage="0kr i pengar tillbaka varje månad"
            values={{
              estimatedCashBack: intl.formatNumber(estimatedCashBack, {
                style: "currency",
                currency: setCurrencyByLocale(intl.locale),
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
            }}
          />
        </li>
        <li data-testid="third-list">
          <FormattedMessage
            id="app.onboarding.general.yellowAttentionBox.point3"
            defaultMessage="Ändra månadskostnad"
          />
        </li>
      </ul>
    </YellowAttentionBox>
  )
}

export default LoanPerksBox
