import { FC, useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import Hamburger from "UI/Hamburger"
import useTrapFocus from "Hooks/UI/useTrapFocus"
import IconFactory from "UI/IconFactory"
import { AnimatePresence } from "framer-motion"

import { Urls } from "Constants/urls"
import { useOverflowEffect } from "Hooks/UI/useOverflowEffect"
import Sidemenu from "./MobileMenu"
import { Nav, LogoWrap } from "./MainNav.styles"

const MainNav: FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [sidemenuRef, hamburgerRef] = useTrapFocus(isOpen)
  const currentPath = useParams()

  const closeMenu = () => {
    setIsOpen(false)
  }

  const toggleIsOpen = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }

  useOverflowEffect(isOpen)

  useEffect(() => {
    setIsOpen(false)
  }, [currentPath])

  return (
    <Nav>
      <Link to={Urls.Home}>
        <LogoWrap>
          <IconFactory name="logo" />
        </LogoWrap>
      </Link>
      <Hamburger
        handleClick={toggleIsOpen}
        isOpen={isOpen}
        ref={hamburgerRef as React.MutableRefObject<HTMLButtonElement>}
        isBlack
      />
      <AnimatePresence>
        {isOpen && (
          <Sidemenu
            closeMenu={closeMenu}
            isOpen={isOpen}
            ref={sidemenuRef as React.MutableRefObject<HTMLDivElement>}
          />
        )}
      </AnimatePresence>
    </Nav>
  )
}

export default MainNav
