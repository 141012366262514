import { FormattedMessage } from "react-intl"

export const STEP_TITLES: { [key: number]: JSX.Element } = {
  1: (
    <FormattedMessage
      id="app.onboarding.title.step1"
      defaultMessage="Erbjudande"
    />
  ),
  2: (
    <FormattedMessage
      id="app.onboarding.title.step2"
      defaultMessage="Välj konto"
    />
  ),
  3: (
    <FormattedMessage
      id="app.onboarding.title.step3"
      defaultMessage="Ange konto"
    />
  ),
  4: (
    <FormattedMessage
      id="app.onboarding.title.step4"
      defaultMessage="Signera"
    />
  ),
  5: (
    <FormattedMessage
      id="app.onboarding.title.step5"
      defaultMessage="Låneskydd"
    />
  ),
  6: (
    <FormattedMessage id="app.onboarding.title.step6" defaultMessage="Syfte" />
  ),
}
