import { DocumentationActivityType } from "ApiServices/loanApplications"

export enum LoanApplicationStatusEnum {
  Approved = "approved",
  Rejected = "rejected",
  ManualCheck = "manual_check",
  CustomerAccepted = "customer_accepted",
  CustomerRejected = "customer_rejected",
  Signed = "signed",
  Converted = "converted",
  OfferTimedOut = "offer_timed_out",
  Canceled = "canceled",
  ApprovalFailed = "approval_failed",
  UcFailed = "uc_failed",
}

export enum Source {
  Sparlan = "sparlan",
  Brocc = "brocc",
  Mypages = "mypages",
  Silentium = "silentium",
  Teleperformance = "teleperformance",
  Brocctm = "brocc_tm",
  Sambla = "sambla",
  Lanabra = "lana_bra",
  Myloan = "myloan",
  Advisa = "advisa",
  Consector = "consector",
  Zensum = "zensum",
  Direkto = "direkto",
  Centomila = "centomila",
  Comboloan = "comboloan",
  Reducero = "reducero",
  Likvidum = "likvidum",
  Lr = "lr",
  Axofinans = "axo_finans",
  Enklare = "enklare",
  Lendo = "lendo",
  Fbg = "fbg",
  Zmarta = "zmarta",
  Bestrate = "bestrate",
  Svensklaneformedling = "svensk_lanformedling",
  Zparo = "zparo",
  Handlarfinans = "handlarfinans",
}

export type LoanApplicationStatusType = `${LoanApplicationStatusEnum}`

export type LoanPurposeType =
  | "consolidate_existing_loans"
  | "capital_purschase"
  | "capital_improvement"
  | "capital_repair"
  | "consumption"
  | "equity_down_payment"
  | "purchase_of_service"
  | "furnitures"
  | "other"

type LoanApplicationFeeType = "fixed_amount" | "effective_interest_rate_margin"

type LoanApplicationFeeMethod = "markup" | "deduction"

export type ApplicantHousingType = "rental" | "house" | "condominium" | "tenant"

export type ApplicantMaritalStatus =
  | "single"
  | "living_together"
  | "married"
  | "divorced"

export type ApplicantOccupationType =
  | "full_time"
  | "student"
  | "unemployed"
  | "early_pensioner"
  | "finite_time"
  | "hourly"
  | "self_employed"

export interface ILoanApplicantDetails {
  number_of_kids: number | string
  housing_cost: number
  housing_type: ApplicantHousingType
  marital_status: ApplicantMaritalStatus
  occupation: ApplicantOccupationType
  work_income: number
  employer?: string | null
  telephone_to_employer?: string | null
  occupation_from_year?: number | null
  occupation_from_month?: number | null
}

export interface ILoanApplicant {
  uid: string
  broadcast_channel: string
  legal_entity_number: string
  email: string
  tel: string
  age: number
  bank_clearing_number: string | null
  bank_account_number: string | null
  bank_name: string | null
  city: string
  customer_number: number
  first_name: string
  last_name: string
  full_name: string
  is_corporate: boolean
  is_customer: boolean
  contact_person: string | null
  active: boolean
  is_eligible_for_insurance: boolean
  legal_entity_type: "private" | "corporate"
  street_address: string
  zip_code: string
  applicant_detail: ILoanApplicantDetails
}

interface ILoanActivty {
  name: DocumentationActivityType | string
  completed: boolean
}

export interface ILoanApplication {
  uid: string
  product_number: number
  status: LoanApplicationStatusType
  loan_type: "annuity"
  loan_market: "SE"
  loan_purpose?: LoanPurposeType | null
  payment_protection_insurance: boolean
  extra_info: string | null
  amount: number
  requested_consolidation_amount: number | null
  period_cost: number
  term_in_days: number
  repayment_periodicity_per_year: number
  repayment_periods: number
  repayment_method: string
  interest_rate: number | string
  effective_interest_rate: number | string
  loan_application_fee: number
  loan_application_fee_type: LoanApplicationFeeType
  loan_application_fee_method: LoanApplicationFeeMethod
  invoice_admin_fee: number
  loan_servicing_fee: number | null
  min_redemption_amount: number
  activities: ILoanActivty[]
  agreement_file_uid: string | null
  source: Source
  agreement_sent_date: string
  accept_date: string
  signed_date: string | null
  loan_application_fee_period_cost: number | null
  effective_interest_rate_markup_margin_percentage: number | null
  loan_service_fee: number | null
  maximum_loanable_amount: number
  original_requested_repayment_periods: number
  original_requested_amount: number
  amount_to_collect: number | null
  has_agreement_requirements: boolean
  is_rejected: boolean
  offer_accepted: boolean
  applicant_signed: boolean
  co_applicant_signed: boolean
  is_signed: boolean
  status_ended: boolean
  is_issued: boolean
  is_paid_out: boolean
  estimated_cashback: number
  existing_loans: never[] | Record<string, unknown>
  applicant: ILoanApplicant
  co_applicant: ILoanApplicant | null
  expiry_date: string
  updated_at: string
  created_at: string
}

export interface LoanApplicationForm {
  uid: string
  amount: number
  repayment_periods: number
  is_consolidation_requested: boolean
  loan_purpose?: string
  source?: string
  applicant: LoanApplicantForm
  has_coapplicant: boolean
  co_applicant?: LoanApplicantForm
}

export interface LoanApplicantForm {
  legal_entity_number: string
  email: string
  tel: string
  customer_origin: string
  applicant_detail?: LoanApplicantDetails
}

export interface LoanApplicantDetails {
  number_of_kids?: number | string | null
  has_kids: boolean
  housing_cost: number
  housing_type: string
  marital_status: string
  occupation: string
  work_income: number
  employer?: string
  telephone_to_employer?: string
  occupation_from_year?: number
  occupation_from_month?: number
}

export interface ILoanApplications {
  data: ILoanApplication[]
}

export interface LoanApplicationAgreementSignature {
  uid: string
  qr_code: string | null
  auto_start_token: string | null
  status: "pending" | "error"
  progress_status:
    | "already_in_progress"
    | "invalid_parameters"
    | "internal_error"
    | "retry"
    | "access_denied_rp"
}

export interface LoanApplicationSignatureStatus {
  qr_code: string | null
  status: "pending" | "complete" | "error"
  progress_status:
    | "outstanding_transaction"
    | "no_client"
    | "started"
    | "user_sign"
    | "complete"
    | "user_cancel"
    | "cancelled"
}
