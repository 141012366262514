import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const Wrap = styled.div`
  p:first-of-type {
    font-size: ${font.size.lg};
  }
  p:nth-of-type(2n) {
    font-weight: bold;
    margin: 1em 0 0;
  }
  p:last-of-type {
    margin: 0;
  }
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding: 3em 2em;
  max-width: 1100px;
  overflow-y: scroll;
  margin: 0 auto;
  h1 {
    margin-top: 1em;
  }
`

export const ButtonWrap = styled.div`
  display: flex;
  gap: 1em;
  margin-top: 2em;

  @media screen and (max-width: ${mediaQueries.small}) {
    flex-direction: column;
  }
`

export const KYCSuccessWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p:first-of-type {
    font-weight: bold;
    margin-bottom: 10px;
  }
  p {
    margin: 0;
  }
`

export const LogoWrap = styled.div`
  svg path {
    fill: ${(props) => props.theme.fontColor};
  }
  margin-bottom: 1.5em;
`
