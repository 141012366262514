import { FieldValues, useController } from "react-hook-form"
import { useIntl } from "react-intl"
import { v4 as uuidv4 } from "uuid"

import FormCheckbox from "UI/FormCheckbox/FormCheckbox"
import { CheckboxGroupFieldProps } from "./CheckboxGroupField.types"

export const CheckboxGroupField = <FieldsType extends FieldValues>({
  name,
  control,
  rules,
  defaultValue,
  options,
  ...props
}: CheckboxGroupFieldProps<FieldsType>) => {
  const intl = useIntl()
  const { field } = useController<FieldsType>({
    name,
    rules,
    control,
    defaultValue,
  })

  const includeValue = (value: unknown): boolean =>
    field.value?.some((item: unknown) => item === value)

  const handleChange = (value: unknown) => {
    if (!includeValue(value)) {
      field.onChange([...field.value, value])
    } else {
      const newValues: unknown[] = field.value?.filter(
        (item: unknown) => item !== value
      )
      field.onChange(newValues)
    }
  }

  return (
    <>
      {options.map(({ label, value }) => (
        <FormCheckbox
          {...props}
          {...field}
          key={value}
          value={value}
          id={uuidv4()}
          label={intl.formatMessage(label)}
          checked={includeValue(value)}
          onChange={() => handleChange(value)}
          aria-label={intl.formatMessage(label)}
        />
      ))}
    </>
  )
}
