import { useEffect, FC, memo } from "react"

import Button from "../Button"
import IconFactory from "../IconFactory"
import { FeedbackDialoguePropTypes } from "./FeedbackDialogue.types"
import {
  StyledFeedbackDialogue,
  DialogueInner,
  Icon,
} from "./FeedbackDialogue.styles"

const FeedbackDialogue: FC<FeedbackDialoguePropTypes> = ({
  body,
  onClick,
  isSuccess,
  btnmsg = "OK",
  showIcon = true,
  btnVariant = "primary",
  ...props
}) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden"
    return () => {
      document.body.style.overflowY = "visible"
    }
  }, [props])

  return (
    <StyledFeedbackDialogue data-testid="feedbackDialog" role="alert">
      <DialogueInner
        animate={{ y: 30 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
      >
        {showIcon && (
          <Icon>
            <IconFactory name={isSuccess ? "success" : "error"} />
          </Icon>
        )}
        <div className="body">
          <div>{body}</div>
        </div>
        <div className="btn-container">
          <Button variant={btnVariant} onClick={onClick}>
            {btnmsg}
          </Button>
        </div>
      </DialogueInner>
    </StyledFeedbackDialogue>
  )
}

export default memo(FeedbackDialogue)
