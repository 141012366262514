import { FieldValues, useController } from "react-hook-form"

import TextArea from "UI/TextArea"
import { TextareaFieldProps } from "./TextareaField.types"

export const TextareaField = <FieldsType extends FieldValues>({
  name,
  control,
  rules,
  error,
  message,
  defaultValue,
  autoComplete = "off",
  ...props
}: TextareaFieldProps<FieldsType>) => {
  const { field, fieldState } = useController<FieldsType>({
    name,
    rules,
    control,
    defaultValue,
  })

  return (
    <TextArea
      {...props}
      {...field}
      defaultValue={defaultValue}
      autoComplete={autoComplete}
      error={!!fieldState.error || error}
      message={fieldState.error?.message || message}
    />
  )
}
