import {
  IKYCPrivateRequest,
  KYCPrivateRequestFormValues,
} from "Interfaces/KYCInterfaces"

export const normalizeToFormValues = (
  values: IKYCPrivateRequest
): KYCPrivateRequestFormValues => {
  return {
    ...values,
    source_of_funds: values.source_of_funds || [],
    purpose_of_saving: values.purpose_of_saving || [],
    citizenship: values.citizenship.map((c) => ({ countryCode: c })),
  }
}

export const normalizeToKycRequest = (
  values: KYCPrivateRequestFormValues
): IKYCPrivateRequest => {
  return {
    ...values,
    citizenship: values.citizenship.map((c) => c.countryCode),
    other_purpose_of_saving: values.purpose_of_saving?.includes("other")
      ? values.other_purpose_of_saving
      : "",
    other_source_of_funds: values.source_of_funds?.includes("other")
      ? values.other_source_of_funds
      : "",
  }
}
