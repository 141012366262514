import { useState } from "react"
import { FormattedMessage } from "react-intl"
import { UseFormReturn } from "react-hook-form"
import CheckboxField from "Components/form/CheckboxField"
import { PRE_PURCHASE_IMFORMATION } from "Constants/links"

import { InfoModalType } from "Components/InsuranceInfoModal/InsuranceInfoModal.types"
import { InsuranceInfoModal } from "Components/InsuranceInfoModal/InsuranceInfoModal"
import * as S from "./InsuranceFormFields.styles"

export const InsuranceFormFields = ({
  form,
}: {
  form: UseFormReturn<{
    insuranceChecked: boolean
    readPrePurchaseInfo: boolean
    fitForWork: boolean
  }>
}) => {
  const [selectedInfoModal, setSelectedInfoModal] =
    useState<InfoModalType | null>(null)
  return (
    <>
      {selectedInfoModal && (
        <InsuranceInfoModal
          activeModal={selectedInfoModal}
          setActiveModal={setSelectedInfoModal}
        />
      )}
      <S.CheckboxLabel>
        <CheckboxField
          color="white"
          name="insuranceChecked"
          control={form.control}
          size="large"
        />
        <span>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.checkboxlabel.subscribeToInsurance"
            defaultMessage="Jag vill teckna trygghetsförsäkring"
          />
        </span>
        <S.CheckboxErrorMsg>
          {form.formState.errors.insuranceChecked && (
            <FormattedMessage
              id="app.onboarding.insuranceCheck.insuranceCheckedError"
              defaultMessage="Du måste teckna trygghetsförsäkring att gå vidare"
            />
          )}
        </S.CheckboxErrorMsg>
      </S.CheckboxLabel>
      <S.CheckboxLabel>
        <CheckboxField
          color="white"
          name="readPrePurchaseInfo"
          control={form.control}
          size="large"
        />
        <span>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.checkboxlabel.readPrepurchaseConfirmation"
            values={{
              link: (
                <a
                  href={PRE_PURCHASE_IMFORMATION}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="app.onboarding.insuranceCheck.checkboxlabel.readPrepurchaseConfirmation.link"
                    defaultMessage="förköpsinformationen"
                  />
                </a>
              ),
            }}
          />
        </span>
        <S.CheckboxErrorMsg>
          {form.formState.errors.readPrePurchaseInfo && (
            <FormattedMessage
              id="app.onboarding.insuranceCheck.prePurchaseInfoError"
              defaultMessage="Du måste acceptera att du tagit del av förköpsinformationen innan du kan lägga till trygghetsförsäkringen."
            />
          )}
        </S.CheckboxErrorMsg>
      </S.CheckboxLabel>
      <S.CheckboxLabel>
        <CheckboxField
          color="white"
          name="fitForWork"
          control={form.control}
          size="large"
        />
        <span>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.checkboxlabel.fitToWorkConfirmation"
            defaultMessage="Jag är {modal1} och uppfyller {modal2} samt är medveten om {modal3}."
            values={{
              modal1: (
                <S.ModalButton
                  type="button"
                  onClick={() => setSelectedInfoModal("modal1")}
                >
                  <FormattedMessage
                    id="app.onboarding.insuranceCheck.checkboxlabel.fitToWorkConfirmation.modal1"
                    defaultMessage="fullt arbetsför"
                  />
                </S.ModalButton>
              ),
              modal2: (
                <S.ModalButton
                  type="button"
                  onClick={() => setSelectedInfoModal("modal2")}
                >
                  <FormattedMessage
                    id="app.onboarding.insuranceCheck.checkboxlabel.fitToWorkConfirmation.modal2"
                    defaultMessage="teckningsreglerna"
                  />
                </S.ModalButton>
              ),
              modal3: (
                <S.ModalButton
                  type="button"
                  onClick={() => setSelectedInfoModal("modal3")}
                >
                  <FormattedMessage
                    id="app.onboarding.insuranceCheck.checkboxlabel.fitToWorkConfirmation.modal3"
                    defaultMessage="symptomklausulen"
                  />
                </S.ModalButton>
              ),
            }}
          />
        </span>
        <S.CheckboxErrorMsg>
          {form.formState.errors.fitForWork && (
            <FormattedMessage
              id="app.onboarding.insuranceCheck.fitForWorkError"
              defaultMessage="Du måste acceptera teckningsreglerna och att du är medveten om symptomklausulen innan du kan lägga till trygghetsförsäkringen."
            />
          )}
        </S.CheckboxErrorMsg>
      </S.CheckboxLabel>
    </>
  )
}
