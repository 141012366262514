import styled, { css } from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"
import { LanguagesPropTypes, LanguageLinkPropTypes } from "./Languages.types"

export const LanguagesWrap = styled.div<LanguagesPropTypes>`
  display: flex;
  align-items: center;
  gap: 15px;

  ${(p) => css`
    margin-left: ${p.isSidebar ? 0 : "auto"};
    margin-right: ${p.isSidebar ? 0 : "2em"};
    color: ${p.isSidebar ? "var(--white)" : p.theme.fontColor};
    @media screen and (max-width: ${mediaQueries.medium}) {
      display: ${p.isSidebar ? "flex" : "none"};
    }
  `}
`

export const LanguageLink = styled.a<LanguageLinkPropTypes>`
  text-decoration: underline;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  cursor: pointer;
  color: inherit;
`
