export const ICING_EVENTS = {
  ICEING_LANDED: "Landed on Icing",
  CLICK_COLLECT_LOANS_BUTTON: "Click Collect Loans button",
  CLICK_SELECTED_BANK_BUTTON: "Click Selected Bank Button",
  CLICK_LOGIN_SELECTED_BANK_BUTTON: "Click Login Selected Bank Button",
  UNSUCCESSFUL_LOGIN: "Unsuccessful Bank Login",
  SUCCESSFUL_LOGIN: "Successful Bank Login",
  CLICK_SELECT_LOAN: "Click Select Loan",
  CLICK_REMOVE_LOAN: "Click Remove Loan",
  CLICK_CONFIRM_SELECTED_LOANS: "Click Confirm Selected Loans",
  CLICK_CONTINUE_APPLICATION: "Click Continue Application",
  CLICK_CONFIRM_CONTINUE_APPLICATION: "Click Confirm Continue Application",
}

export const EVENTS = {
  LOGIN_SUCCESS: "Login Success",
}
