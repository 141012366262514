import type {
  ICashBack,
  RequestCashback,
  RequestCashbackData,
} from "Interfaces/cashbackIntefaces"
import axios from "ApiServices/axios"
import { Api } from "Constants/api"
import type { CashbackTypeEnum } from "Constants/dataStrings"
import type { AxiosError } from "axios"

export const getCashback = async () => {
  try {
    const { data } = await axios.get<ICashBack>(Api.Cashback)

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const postRequestCashback = async (
  typeOfCashbackMethod: CashbackTypeEnum
) => {
  try {
    return await axios.post<
      RequestCashbackData,
      RequestCashback,
      RequestCashback
    >(Api.CashbackRedemptions, { cashback_usage: typeOfCashbackMethod })
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}
