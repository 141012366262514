import { useIntl } from "react-intl"

import Button from "UI/Button"
import { ILoanContract } from "Interfaces/loanContractsInterfaces"
import { Urls } from "Constants/urls"
import * as S from "./ContractsToSign.styles"

interface ContractsToSignProps {
  contracts: ILoanContract[]
}

export const ContractsToSign = ({ contracts }: ContractsToSignProps) => {
  const intl = useIntl()
  return (
    <S.ContractsBlock>
      {contracts.map(({ uid }) => (
        <S.ContractItem key={uid}>
          <S.ContractHeading>
            {intl.formatMessage({
              id: "app.mypages.loans.loanAgreement.changeOfTerms",
              defaultMessage: "Villkorsändring",
            })}
            &nbsp;🎉
          </S.ContractHeading>
          <p>
            {intl.formatMessage({
              id: "app.mypages.loans.loanAgreement.newTermsToSign",
              defaultMessage:
                "Du har en ny villkorsändring att signera, gå vidare för signering!",
            })}
          </p>
          <Button
            component="link"
            to={`${Urls.Loans}/${Urls.LoansProposedContract.replace(
              ":id",
              uid
            )}`}
          >
            {intl.formatMessage({
              id: "app.mypages.loans.loanAgreement.proceedToSign",
              defaultMessage: "Vidare till signering",
            })}
          </Button>
        </S.ContractItem>
      ))}
    </S.ContractsBlock>
  )
}
