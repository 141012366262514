import { FC } from "react"
import { FormattedMessage } from "react-intl"

import { IScrappingLoanBank, BankEnabled } from "Interfaces/iceingInterfaces"
import { EnvKeysEnum, getEnv } from "Utils/environment"
import { BanksListPropsType } from "./Banks.types"
import Bank from "./Bank"
import * as S from "./Banks.styles"
import { ANNAN_AKTOR } from "../Constants"
import { removeBankByName } from "../Utils"

const BanksList: FC<BanksListPropsType> = ({ banks, onSelectedBank }) => {
  if (!banks) {
    return (
      <S.BanksListContainer data-cy="banks-not-found">
        <p>
          <FormattedMessage id="app.mypages.iceing.bank.not.found" />
        </p>
        <Bank
          bankId={0}
          bankName={ANNAN_AKTOR}
          loginStrategies={[]}
          onSelectedBank={onSelectedBank}
        />
      </S.BanksListContainer>
    )
  }

  const enabledBanks = [BankEnabled.ENABLED, BankEnabled.IN_PROGRESS]

  let filteredBanks: IScrappingLoanBank[] = banks.filter((bank) =>
    enabledBanks.includes(bank.enabled as BankEnabled)
  )

  if (getEnv(EnvKeysEnum.Env) !== "test") {
    filteredBanks = removeBankByName(filteredBanks, "Mock")
  }

  const listItems = filteredBanks.map((item) => (
    <Bank key={item.bankId} onSelectedBank={onSelectedBank} {...item} />
  ))

  return (
    <>
      <S.BanksListContainer isBanksList>{listItems}</S.BanksListContainer>
      <S.BanksListContainer>
        <p>
          <FormattedMessage id="app.mypages.iceing.bank.not.found" />
        </p>
        <Bank
          bankId={0}
          bankName={ANNAN_AKTOR}
          loginStrategies={[]}
          onSelectedBank={onSelectedBank}
        />
      </S.BanksListContainer>
    </>
  )
}

export default BanksList
