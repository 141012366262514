import styled from "styled-components"
import { flex } from "config/mixins"
import { font } from "Styles/styleGlobal"

export const ChangeTermForm = styled.form`
  padding: 1.5em 0.5em;

  button {
    margin: 20px auto 0;
  }
`

export const ChangeIsNotAvailible = styled.p`
  border-radius: 15px;
  background-color: var(--very-light-yellow);
  padding: 1em;
  text-align: center;
  margin: 0;
`

export const ChangeAvailibleBlock = styled.div`
  .textarea-field {
    margin-top: 1em;
  }
`

export const ChangeTermDescriptionBlock = styled.div`
  ${flex.columnAlignCenter}
  row-gap: 8px;
  text-align: center;
  :not(:last-child) {
    margin-bottom: 1.5em;
  }

  > .icon-pauseNoBorder {
    width: 40px;
    height: 40px;
    margin-bottom: 14px;
  }

  p,
  h6 {
    margin: 0;
    font-size: ${font.size.lg};
  }

  h6 {
    margin-top: 14px;
  }

  p {
    line-height: 26px;
  }
`

export const RangeLabels = styled.div`
  display: flex;
  justify-content: space-between;
  color: #131313;
  font-size: ${font.size.xl};
  line-height: 36px;
  font-weight: 700;
`

export const SpanLabels = styled.div`
  display: flex;
  justify-content: space-between;
  color: #717171;
  font-size: ${font.size.base};
  line-height: 16.8px;
`
