import { useMutation, useQuery, useQueryClient } from "react-query"
import { useState, useEffect, useMemo } from "react"
import crypto from "crypto"
import { MessageDescriptor, useIntl } from "react-intl"

import { AuthSignatureStatus } from "types/enums"
import { useAuth } from "Context/AuthContext/auth-context"
import { base64URLEncode, sha256 } from "Utils/signingUtils"
import {
  initiateAuthSession,
  getToken,
  getRefreshedToken,
  getSignatureAuthStatus,
} from "ApiServices/login"
import { cleanStorageAndAxios } from "Context/AuthContext/helpers"
import { AUTH_REFRESH_TOKEN, AUTH_STATUS } from "Constants/queryKeys"
import { BankIdAuthData, ITokenData } from "Interfaces/authInterfaces"
import { loginErrorMessages } from "Pages/Unauthenticated/Login/Login.config"

export const useInitiateAuthSession = () => {
  return useMutation<BankIdAuthData>({
    mutationFn: () => initiateAuthSession(),
  })
}

export const useGetToken = () => {
  return useMutation<ITokenData, unknown, { code: string; verifier: string }>({
    mutationFn: ({ code, verifier }) => getToken(code, verifier),
  })
}

export const useGetRefreshedToken = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: getRefreshedToken,
    mutationKey: [AUTH_REFRESH_TOKEN],
    retry: 0,
    onMutate: () => {
      queryClient.setDefaultOptions({
        queries: {
          enabled: false,
        },
      })
    },
    onError: () => {
      cleanStorageAndAxios()
    },
  })
}

interface GetAuthSignatureParams {
  uid: string
  challenge: string
  state: string
  enabled: boolean
  onError?: () => void
}

export const useGetAuthSignatureStatus = ({
  uid,
  challenge,
  state,
  enabled,
  onError,
}: GetAuthSignatureParams) => {
  return useQuery({
    queryKey: [AUTH_STATUS, uid],
    queryFn: ({ signal }) =>
      getSignatureAuthStatus(uid, challenge, state, signal),
    refetchInterval: 1500,
    enabled,
    onError,
  })
}

const getAuthSignatureParams = () => {
  const verifier = base64URLEncode(crypto.randomBytes(32))
  const challenge = base64URLEncode(sha256(verifier))
  const state = crypto.randomBytes(64).toString("hex")
  return {
    verifier,
    challenge,
    state,
  }
}

export const useLogin = ({ onError }: { onError: () => void }) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const [isGetAuthSignatureEnabled, setIsGetAuthSignatureEnabled] =
    useState(true)
  const [authSignatureParams, setAuthSignatureParams] = useState<
    ReturnType<typeof getAuthSignatureParams>
  >({ challenge: "", state: "", verifier: "" })

  const { login } = useAuth()

  const getTokenMutation = useGetToken()
  const authSessionMutation = useInitiateAuthSession()
  const authSignatureQuery = useGetAuthSignatureStatus({
    challenge: authSignatureParams.challenge,
    state: authSignatureParams.state,
    enabled: !!authSessionMutation.data?.uid && isGetAuthSignatureEnabled,
    uid: authSessionMutation.data?.uid || "",
    onError: () => {
      onError()
      cancelLogin()
    },
  })

  const startLogin = () => {
    setAuthSignatureParams(getAuthSignatureParams())
    setIsGetAuthSignatureEnabled(true)
    authSessionMutation.mutateAsync()
  }

  const cancelLogin = () => {
    queryClient.cancelQueries({
      queryKey: [AUTH_STATUS, authSessionMutation.data?.uid],
    })
    setIsGetAuthSignatureEnabled(false)
  }

  useEffect(() => {
    if (authSignatureQuery.data?.status === AuthSignatureStatus.complete) {
      setIsGetAuthSignatureEnabled(false)
      getTokenMutation
        .mutateAsync({
          code: authSignatureQuery.data.code || "",
          verifier: authSignatureParams.verifier,
        })
        .then(login)
        .catch() // Add error handling
    }
    if (authSignatureQuery.data?.status === AuthSignatureStatus.error) {
      setIsGetAuthSignatureEnabled(false)
      cancelLogin()
      onError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authSignatureParams.verifier,
    authSignatureQuery.data?.status,
    authSignatureQuery.data?.code,
    login,
  ])

  const errorMessage = useMemo(() => {
    const defaultMessage: MessageDescriptor = {
      id: "app.login.error.default",
      defaultMessage:
        "Något gick fel. Det gick inte att logga in just nu. Om  du inte har ett konto än, vänligen skapa ett på brocc.se",
    }
    if (
      authSessionMutation.data?.progress_status &&
      loginErrorMessages[authSessionMutation.data.progress_status]
    ) {
      return intl.formatMessage(
        loginErrorMessages[authSessionMutation.data.progress_status] ||
          defaultMessage
      )
    }
    if (authSignatureQuery.data?.status === AuthSignatureStatus.error) {
      return intl.formatMessage(defaultMessage)
    }

    return intl.formatMessage(defaultMessage)
  }, [
    authSessionMutation.data?.progress_status,
    authSignatureQuery.data?.status,
    intl,
  ])

  return {
    startLogin,
    cancelLogin,
    errorMessage,
    isSigning:
      authSignatureQuery.data?.progress_status === "user_sign" ||
      authSignatureQuery.data?.progress_status === "complete",
    isError:
      authSessionMutation.isError ||
      authSessionMutation.data?.status === AuthSignatureStatus.error ||
      authSignatureQuery.data?.status === AuthSignatureStatus.error,
    isLoading: authSignatureQuery.isLoading || authSessionMutation.isLoading,
    autostartToken: authSessionMutation.data?.auto_start_token || "",
    qrCode:
      authSignatureQuery.data?.qr_code ||
      authSessionMutation.data?.qr_code ||
      "",
  }
}
