import styled from "styled-components"
import { mediaQueries, font } from "Styles/styleGlobal"

export const OffersWrap = styled.div`
  padding-top: 3em;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-row-gap: 3em;
  @media screen and (max-width: ${mediaQueries.small}) {
    padding-top: 2em;
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const MainWrap = styled.div`
  h1 {
    margin: 0;
  }
`

export const LoansContainer = styled.div`
  order: 2;
  h2 {
    color: var(--black);
    font-size: ${font.size.xl2};
    margin: 0 0 5px;
    @media screen and (max-width: ${mediaQueries.small}) {
      font-size: ${font.size.xl2};
    }
  }
`
