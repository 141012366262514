import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const ModalButton = styled.button`
  align-self: flex-start;
  text-decoration: underline;
  margin: 1em 0 1.5em 0;
  font-size: ${font.size.sm};
  padding: 0;
  color: ${(props) => props.theme.fontColor};
`
