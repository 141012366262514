import type { FC, ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import IconFactory from "UI/IconFactory"
import * as Styles from "./KYCSuccess.styles"

type KYCSuccessPropsType = {
  children?: ReactNode
}

const KYCSuccess: FC<KYCSuccessPropsType> = ({ children }) => {
  return (
    <Styles.KYCSuccessWrap>
      <IconFactory name="like" />
      <h2>
        <FormattedMessage
          id="app.mypages.kyc.success.heading"
          defaultMessage="Tack för dina svar!"
        />
      </h2>
      <p>
        <FormattedMessage
          id="app.mypages.kyc.success.message"
          defaultMessage="Tänk på att du när som helst kan välja att uppdatera dina svar."
        />
      </p>
      {children}
    </Styles.KYCSuccessWrap>
  )
}

export default KYCSuccess
