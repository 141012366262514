import { FC } from "react"
import { TogglePropTypes } from "./Toggle.types"
import { CheckboxWrap, CheckBox, CheckBoxSpan } from "./Toggle.styles"

const Toggle: FC<TogglePropTypes> = ({ checked, onChange, name, value }) => (
  <CheckboxWrap data-testid="toggle">
    <CheckBox
      id="checkbox"
      type="checkbox"
      checked={checked || false}
      onChange={onChange}
      name={name}
      value={value}
    />
    <CheckBoxSpan />
  </CheckboxWrap>
)

export default Toggle
