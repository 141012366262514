import { FC } from "react"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import LoanItem from "Components/LoanItem"
import { IBasicLoanService } from "Interfaces/loanInterfaces"
import { BASIC_LOAN_SERVICES_SERVICE } from "Constants/queryKeys"
import * as Styled from "./Loan.styles"

const LoanOverview: FC = () => {
  const { accountID } = useParams() as { accountID: string }
  const queryClient = useQueryClient()
  const loan = queryClient.getQueryData([
    BASIC_LOAN_SERVICES_SERVICE,
    accountID,
  ]) as IBasicLoanService

  return (
    <Styled.LoanWrap>
      <LoanItem loan={loan} />
    </Styled.LoanWrap>
  )
}

export default LoanOverview
