import styled from "styled-components"

export const Nav = styled.nav`
  height: 91px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 20px;

  @media screen and (min-width: 769px) {
    display: none;
  }
`

export const LogoWrap = styled.div`
  svg path {
    fill: ${(props) => props.theme.fontColor};
  }
`
