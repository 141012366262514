import { FC, useState, useMemo, ReactNode } from "react"
import { IntlProvider } from "react-intl"

import { LangContext } from "Context/lang-context"
import {
  languages,
  LocaleType,
  LanguagesEnum,
  languageOptions,
} from "Constants/languages"
import { LANGUAGE } from "Constants/storage"
import { getStorageItem, setStorageItem } from "Utils/localStorage"

type LocaleWrapperPropTypes = {
  children?: ReactNode
}

const isLanguageSupported = (lang: LocaleType): boolean => {
  return languageOptions.some((l) => l.value === lang)
}

const getSavedOrDefaultLanguage = (): LocaleType => {
  const savedLanguage = getStorageItem(LANGUAGE) as LocaleType
  if (savedLanguage && isLanguageSupported(savedLanguage)) {
    return savedLanguage
  }
  const navigatorLanguage = navigator.language.slice(0, 2) as LocaleType
  if (isLanguageSupported(navigatorLanguage)) {
    return navigatorLanguage
  }

  return LanguagesEnum.SV
}

const LocaleWrapper: FC<LocaleWrapperPropTypes> = ({ children }) => {
  const [locale, setLocale] = useState<LocaleType>(getSavedOrDefaultLanguage)

  const selectLanguage = (value: LocaleType) => {
    setLocale(value)
    setStorageItem(LANGUAGE, value)
  }

  const value = useMemo(() => ({ locale, selectLanguage }), [locale])

  return (
    <LangContext.Provider value={value}>
      <IntlProvider
        messages={languages[locale]}
        defaultLocale={locale}
        locale={locale}
      >
        {children}
      </IntlProvider>
    </LangContext.Provider>
  )
}

export default LocaleWrapper
