import styled from "styled-components"
import { flex } from "config/mixins"
import { mediaQueries } from "Styles/styleGlobal"

export const DepositModalSuccess = styled.div`
  ${flex.columnAlignCenter}
  padding: 0 0 50px;
  p,
  h3 {
    margin: 0;
    text-align: center;
  }
  p {
    padding: 0 6em;
    @media screen and (max-width: ${mediaQueries.medium}) {
      padding: 0;
    }
  }
  h3 {
    margin: 30px 0 10px;
  }
  button {
    margin-top: 20px;
  }
`

export const CloseButton = styled.button`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: var(--light-gray);
  position: relative;
  align-self: flex-start;
  margin-top: 0;
  svg {
    width: 45px;
    height: 45px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    path {
      stroke: var(--dark-gray);
    }
  }
`
