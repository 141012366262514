/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import { FormattedMessage } from "react-intl"

import yup from "config/yup"
import {
  BaseKYCPrivateSchema,
  ExtendedKYCPrivateSchema,
  IPep,
} from "Interfaces/KYCInterfaces"

yup.setLocale({
  array: {
    unique: () => {
      return (
        <FormattedMessage
          id="app.common.errors.unique"
          defaultMessage="Detta fält ska vara unikt"
        />
      )
    },
  },
  number: {
    min: (number) => (
      <FormattedMessage
        id="app.mypages.investments.onboarding.KYC.company.errors.minNumber"
        defaultMessage="Bör vara ett heltal mer än {min}"
        values={{ min: number.min }}
      />
    ),
    max: (number) => (
      <FormattedMessage
        id="app.mypages.investments.onboarding.KYC.company.errors.maxNumber"
        defaultMessage="Bör vara ett heltal mindre än {max}"
        values={{ max: number.max }}
      />
    ),
  },
  string: {
    min: () => (
      <FormattedMessage
        id="app.common.obligatory"
        defaultMessage="Obligatorisk"
      />
    ),
    matches: () => (
      <FormattedMessage
        id="app.common.obligatory"
        defaultMessage="Obligatorisk"
      />
    ),
  },
})

yup.addMethod(
  yup.array,
  "unique",
  function (mapper?: (v: unknown) => unknown, msg?: any) {
    return this.test("unique", msg, function (list: any) {
      return list.length === new Set(list.map(mapper)).size
    })
  }
)

const pep: yup.SchemaOf<IPep> = yup.object().shape(
  {
    pep_position: yup
      .array()
      .of(
        yup
          .string()
          .oneOf([
            "head_of_state",
            "member_of_parliament",
            "judge",
            "auditor",
            "diplomat",
            "public_company_mgmt",
            "international_pep",
          ])
      ),
    pep_relation: yup
      .array()
      .of(yup.string().oneOf(["married", "partner", "children", "parents"])),
    pep_relation_position_title: yup.string().when("pep_relation", {
      is: (relation: string[]) => relation?.length > 0,
      then: yup.string().required(),
    }),
    pep_relation_position_country: yup.string().when("pep_relation", {
      is: (relation: string[]) => relation?.length > 0,
      then: yup.string().required(),
    }),
    pep_coworker: yup
      .array()
      .of(yup.string().oneOf(["relationship_to_pep", "beneficiary_of_pep"])),
    pep_position_title: yup.string().when("pep_position", {
      is: (relation: string[]) => relation?.length > 0,
      then: yup.string().required(),
    }),
    pep_position_country: yup.string().when("pep_position", {
      is: (relation: string[]) => relation?.length > 0,
      then: yup.string().required(),
    }),
  },
  [["pep_position", "pep_relation"]]
)

export const baseKYCPrivateSchema: yup.SchemaOf<BaseKYCPrivateSchema> = yup
  .object()
  .shape({
    is_corporate: yup.bool().default(false),
    citizenship: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            countryCode: yup.string().required(),
          })
          .required()
      )
      .min(1)
      .unique(
        (value) => value.countryCode,
        <FormattedMessage
          id="app.common.errors.unique"
          defaultMessage="Detta fält ska vara unikt"
        />
      ),
    occupation: yup
      .string()
      .oneOf(["employed", "self_employed", "unemployed", "retired", "other"])
      .required(),
    other_occupation: yup
      .string()
      .nullable()
      .when("occupation", {
        is: (occupation: string) => occupation === "other",
        then: yup.string().required().nullable(),
      }),
    work_info: yup.string().required(),
    income: yup
      .string()
      .required()
      .oneOf([
        "below150kSEK",
        "150-300kSEK",
        "300-500kSEK",
        "500-1000kSEK",
        "above1000kSEK",
      ]),
    living_in_sweden: yup.bool().default(true),
    birthplace_citizenship: yup
      .string()
      .nullable()
      .when("living_in_sweden", {
        is: (livingInSweden: boolean) => livingInSweden === false,
        then: yup.string().required().nullable(),
      }),
    occupancy: yup
      .string()
      .nullable()
      .when("living_in_sweden", {
        is: (livingInSweden: boolean) => livingInSweden === false,
        then: yup.string().min(2).required().nullable(),
      }),
    taxable_usa: yup.bool().default(false),
    pep: pep.nullable(true),
  })

export const extendedKYCPrivateSchema: yup.SchemaOf<ExtendedKYCPrivateSchema> =
  baseKYCPrivateSchema.shape({
    tax_domicile: yup.string().nullable(true),
    tax_identification_number: yup.string().nullable(true),
    deposit_frequency: yup
      .string()
      .oneOf([
        "more_than_4_times_a_month",
        "up_to_4_times_a_month",
        "monthly",
        "quarterly",
        "every_six_months",
        "yearly",
        "rarely",
        "one_time",
      ])
      .required()
      .nullable(true),
    withdrawal_frequency: yup
      .string()
      .oneOf([
        "more_than_4_times_a_month",
        "up_to_4_times_a_month",
        "monthly",
        "quarterly",
        "every_six_months",
        "yearly",
        "rarely",
        "one_time",
      ])
      .required()
      .nullable(true),
    capital_owner: yup
      .string()
      .oneOf(["own_capital", "other"])
      .required()
      .nullable(true),
    other_capital_owner: yup
      .string()
      .nullable()
      .when("capital_owner", {
        is: (capitalOwner: string) => capitalOwner === "other",
        then: yup.string().required().nullable(true),
      }),
    source_of_funds: yup
      .array()
      .of(
        yup
          .string()
          .oneOf([
            "internal_transfer",
            "dividends",
            "pay",
            "inheritence",
            "insurance",
            "gambling",
            "home_sale",
            "real_estate_sale",
            "company_sale",
            "child_benefit",
            "sale_of_vehicle",
            "other_bank",
            "other",
          ])
      )
      .min(1),
    other_source_of_funds: yup
      .string()
      .nullable()
      .when("source_of_funds", {
        is: (sourceOfFunds: string[]) => sourceOfFunds?.includes("other"),
        then: yup.string().nullable().required(),
      }),
    purpose_of_saving: yup
      .array()
      .of(
        yup
          .string()
          .oneOf([
            "economy",
            "retirement",
            "gift",
            "consumption",
            "home_purchase",
            "other",
          ])
      )
      .min(1),
    other_purpose_of_saving: yup
      .string()
      .nullable()
      .when("purpose_of_saving", {
        is: (purpose: string[]) => purpose?.includes("other"),
        then: yup.string().nullable().required(),
      }),
    investment: yup
      .string()
      .oneOf([
        "below10kSEK",
        "10-100kSEK",
        "100-500kSEK",
        "500-1000kSEK",
        "above1000kSEK",
      ])
      .required()
      .nullable(true),
    deposit_size: yup
      .string()
      .oneOf([
        "below10kSEK",
        "10-30kSEK",
        "30-50kSEK",
        "50-100kSEK",
        "above100kSEK",
      ])
      .required()
      .nullable(true),
  })

export const KYCCompanySchema = yup.object().shape({
  is_corporate: yup.bool(),
  business_focus: yup.string().required(),
  investment: yup
    .string()
    .oneOf([
      "below10kSEK",
      "10-100kSEK",
      "100-500kSEK",
      "500-1000kSEK",
      "above1000kSEK",
    ])
    .nullable()
    .required(),
  deposit_frequency: yup
    .string()
    .oneOf([
      "more_than_4_times_a_month",
      "up_to_4_times_a_month",
      "monthly",
      "quarterly",
      "every_six_months",
      "yearly",
      "rarely",
      "one_time",
    ])
    .required(),
  source_of_funds: yup
    .array()
    .of(
      yup
        .string()
        .oneOf([
          "dividends",
          "pay",
          "insurance",
          "real_estate_sale",
          "company_sale",
          "other",
        ])
    )
    .min(1),
  other_source_of_funds: yup.string().when("source_of_funds", {
    is: (sourceOfFunds: string[]) => sourceOfFunds?.includes("other"),
    then: yup.string().required(),
  }),
  purpose_of_saving: yup.string().required(),
  owner: yup
    .array()
    .nullable()
    .of(
      yup.object().shape({
        owner_uid: yup.string().required(),
        owner_parent_uid: yup.string().nullable(),
        owner_name: yup.string().required(),
        owner_legal_entity_number: yup.string().required(),
        owner_address: yup.string().nullable(),
        owner_country: yup.string().nullable(),
        owner_capital: yup.number().min(25).max(100).integer().nullable(),
        owner_votes: yup.number().min(25).max(100).integer().nullable(),
      })
    )
    .min(1),
  true_owner: yup.object().nullable().shape({
    true_owner_name: yup.string().required(),
    true_owner_legal_entity_number: yup.string().required(),
    true_owner_address: yup.string().nullable(),
    true_owner_country: yup.string().nullable(),
  }),
  taxable_usa: yup.bool(),
  pep,
})
