import { FC, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useBasicLoanServices } from "Hooks/API/useBasicLoanServices"
import {
  useGetDepositsAffectedByInterestRateChange,
  useGetDepositServices,
} from "Hooks/API/useDepositServices"
import { useGetLoanApplications } from "Hooks/API/useLoansApplications"
import { useGetUser } from "Hooks/API/useUser"
import PromoWrap from "Components/PromoWrap"
import {
  getLoansInfo,
  getLoanApplicationsInfo,
  getSavingsInfo,
} from "Utils/getInfoUtils"
import UserGreetings from "Components/UserGreetings"
import Loading from "UI/Loading"
import { ContractsToSign } from "Components/ContractsToSign/ContractsToSign"
import { useGetListLoanContracts } from "Hooks/API/useLoanContracts"
import { useGetInsuranceEligibilities } from "Hooks/API/useInurance"
import { InsurancePromotion } from "Components/InsurancePromotion/InsurancePromotion"
import {
  BROCC_LANDING_LOAN,
  BROCC_LANDING_SAVINGS,
} from "Constants/externalLinks"
import * as S from "./Main.styles"

const Main: FC = () => {
  const intl = useIntl()
  const { data: user, isError, isLoading: isUserLoading } = useGetUser()
  const { data: loans, isLoading: isLoanLoading } = useBasicLoanServices()
  const insuranceEligibilitiesQuery = useGetInsuranceEligibilities()
  const { data: interestRateChange, isLoading: isLoadingInterestRateChange } =
    useGetDepositsAffectedByInterestRateChange()

  const getLoanApplications = useGetLoanApplications()
  const listLoanContracts = useGetListLoanContracts()

  const { data: savings, isLoading: isDepositLoading } = useGetDepositServices()
  const loanApplications =
    getLoanApplications.data?.data &&
    Object.values(getLoanApplications.data?.data)

  const isFetching = useMemo(
    () =>
      isUserLoading ||
      isLoanLoading ||
      getLoanApplications.isLoading ||
      isDepositLoading ||
      insuranceEligibilitiesQuery.isLoading ||
      isLoadingInterestRateChange,
    [
      getLoanApplications.isLoading,
      isDepositLoading,
      isLoanLoading,
      isUserLoading,
      insuranceEligibilitiesQuery.isLoading,
      isLoadingInterestRateChange,
    ]
  )

  const loansData = useMemo(
    () =>
      loans?.data && loans?.data.length > 0 ? getLoansInfo(loans.data) : null,
    [loans?.data]
  )

  const loanApplicationsData = useMemo(
    () =>
      loanApplications && loanApplications.length > 0
        ? getLoanApplicationsInfo(loanApplications, intl)
        : [],
    [intl, loanApplications]
  )

  const savingsData = useMemo(
    () =>
      savings?.data && savings?.data.length > 0
        ? getSavingsInfo(savings.data, intl)
        : null,
    [intl, savings?.data]
  )

  const showInsurancePromotion = useMemo(() => {
    return Boolean(
      insuranceEligibilitiesQuery.data?.is_eligible &&
        insuranceEligibilitiesQuery.data.free_months > 0 &&
        loans?.data.some((loan) => loan.is_eligible_for_insurance)
    )
  }, [
    loans,
    insuranceEligibilitiesQuery.data?.is_eligible,
    insuranceEligibilitiesQuery.data?.free_months,
  ])

  if (isFetching) {
    return (
      <S.LoadingContainer>
        <Loading />
      </S.LoadingContainer>
    )
  }

  if (isError) {
    return (
      <div>
        <FormattedMessage id="app.login.error.generalError" />
      </div>
    )
  }

  return (
    <S.MainWrap>
      <UserGreetings
        interestRateChange={interestRateChange}
        username={user ? user.name : ""}
      />

      <S.OffersWrap>
        {listLoanContracts.data && listLoanContracts.data.data.length > 0 && (
          <ContractsToSign contracts={listLoanContracts.data.data} />
        )}

        {showInsurancePromotion && <InsurancePromotion />}

        {loanApplicationsData.length > 0 && (
          <S.LoansContainer>
            <h2>
              <FormattedMessage
                id="app.common.loanApplications"
                defaultMessage="Låneansökningar"
              />
            </h2>
            <PromoWrap
              type="loanApplications"
              accountTitleId="app.common.loanApplication"
              data={loanApplicationsData}
            />
          </S.LoansContainer>
        )}

        <PromoWrap
          type="loans"
          titleId="app.mypages.loans.header"
          secondTitleId="app.mypages.main.loansTitle"
          descriptionId="app.mypages.main.loansDescription"
          accountTitleId="app.mypages.loans.header"
          linkText="app.mypages.loans.applyForLoan.title"
          link={BROCC_LANDING_LOAN}
          data={loansData}
          descriptionList={[
            "app.mypages.loans.noLoans.listItem1",
            "app.mypages.loans.noLoans.listItem2",
            "app.mypages.loans.noLoans.listItem4",
          ]}
        />

        <PromoWrap
          type="savings"
          titleId="app.mypages.mainNav.savings.title"
          secondTitleId="app.mypages.main.savingsTitle"
          descriptionId="app.mypages.main.savingsDescription"
          descriptionList={[
            "app.mypages.main.savingsDescriptionList.item1",
            "app.mypages.main.savingsDescriptionList.item2",
            "app.mypages.main.savingsDescriptionList.item3",
          ]}
          accountTitleId="app.common.account.savingsAccount"
          linkText="app.mypages.investments.requiresOnboarding.buttonCTA"
          link={BROCC_LANDING_SAVINGS}
          data={savingsData}
        />
      </S.OffersWrap>
    </S.MainWrap>
  )
}

export default Main
