import styled, { css } from "styled-components"
import { font } from "Styles/styleGlobal"

type ButtonPropTypes = {
  active: boolean
}

export const Button = styled.button<ButtonPropTypes>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 22px;
  border-radius: 10px;
  border: 1px solid var(--light-gray);
  transition: all 200ms ease-in-out;

  &:hover:enabled {
    background: var(--dark-yellow);
    color: var(--black);
  }
  ${(p) => css`
    font-size: ${font.size.base};
    color: ${p.theme.fontColor};
    background: ${p.active ? "var(--dark-yellow)" : p.theme.infoBox};
  `}
`
