import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const Wrap = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  gap: 7px;
  margin-bottom: 0;

  @media screen and (max-width: ${mediaQueries.small}) {
    margin-bottom: 50px;
  }
`

export const Step = styled.div<{ isStepActive: boolean }>`
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.isStepActive ? "var(--light-yellow)" : "var(--light-gray)"};
`
