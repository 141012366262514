import { FC, useEffect, useState } from "react"
import { ProgressRingPropTypes } from "./ProgressRing.types"
import * as S from "./ProgressRing.styles"

const ProgressRing: FC<ProgressRingPropTypes> = ({
  size,
  progress,
  strokeWidth,
}) => {
  const [offset, setOffset] = useState(0)

  const center = size / 2
  const radius = size / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius

  useEffect(() => {
    if (!offset) {
      const progressOffset = progress * circumference
      setOffset(progressOffset)
    }
  }, [setOffset, progress, circumference, offset])

  return (
    <S.Wrap width={size} height={size} data-testid="progress-ring">
      <S.Circle cx={center} cy={center} r={radius} strokeWidth={strokeWidth} />
      <S.Progress
        data-testid="circle-element"
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      />
    </S.Wrap>
  )
}

export default ProgressRing
