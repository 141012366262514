import { flex } from "config/mixins"
import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const KYCSuccessWrap = styled.div`
  ${flex.columnAlignCenter}
  text-align: center;
  padding-top: 70px;
  h2 {
    margin: 20px 0px;
  }
  svg {
    width: 50px;
  }
  p {
    margin: 0 0 20px;
    font-size: ${font.size.lg};
    font-weight: 500;
  }
`
