import { ReactElement } from "react"
import { FormattedNumber, useIntl } from "react-intl"
import {
  Installment,
  Credit,
  Loan,
} from "Pages/LoanCollection/LoansList/LoanItem/LoanItem.types"

interface UseLoanTypeProps {
  loan: Loan | Installment | Credit
}

const useLoanType = ({ loan }: UseLoanTypeProps) => {
  const intl = useIntl()

  let monthlyCost = ""
  let interestRate: ReactElement | string = ""
  let balance = "0"
  let interestRateProp: number | bigint = 0

  if ("monthlyCost" in loan && loan.monthlyCost) {
    monthlyCost = intl.formatNumber(loan.monthlyCost, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  if ("interestRate" in loan && loan.interestRate) {
    interestRateProp = loan.interestRate
  }

  if (interestRateProp) {
    interestRate = (
      <FormattedNumber
        value={interestRateProp}
        style="percent"
        maximumFractionDigits={2}
        minimumFractionDigits={2}
      />
    )
  }

  if (loan.balance) {
    balance = intl.formatNumber(loan.balance, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  return { monthlyCost, interestRate, balance }
}

export default useLoanType
