import { FC } from "react"
import ReactDOM from "react-dom"
import { useCloseModal, useModalContext } from "Context/modal-context"
import FeedbackDialogue from "UI/FeedbackDialogue/FeedbackDialogue"

const modal = document.createElement("div")
modal.setAttribute("id", "modal")
document.body.appendChild(modal)

const FeedbackModal: FC = () => {
  const { state } = useModalContext()
  const closeModal = useCloseModal()

  const handleClose = () => {
    if (state.closeCallback) {
      closeModal()
      state.closeCallback()
    }
  }

  if (state.isOpen) {
    return ReactDOM.createPortal(
      <FeedbackDialogue
        isSuccess={state.isSuccess}
        btnmsg={state.btnMsg}
        body={state.body}
        onClick={state.closeCallback ? handleClose : closeModal}
        showIcon={state.showIcon}
        btnVariant={state.btnVariant}
      />,
      modal
    )
  }
  return null
}

export default FeedbackModal
