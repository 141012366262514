import mixpanel from "mixpanel-browser"
import { v4 as uuidv4 } from "uuid"

import { SESSION_STORAGE_ID } from "Constants/storage"
import { EnvKeysEnum, getEnv } from "Utils/environment"
import { EVENTS, ICING_EVENTS } from "Events/List"
import { subscribe } from "../../Events"

const token = getEnv(EnvKeysEnum.MixpanelProjectToken)
mixpanel.init(token, { disable_persistence: true, ip: false })

if (!sessionStorage.getItem(SESSION_STORAGE_ID)) {
  sessionStorage.setItem(SESSION_STORAGE_ID, uuidv4())
}

mixpanel.identify(sessionStorage.getItem(SESSION_STORAGE_ID)!)

Object.keys(ICING_EVENTS).forEach((property) => {
  const key = property as keyof typeof ICING_EVENTS

  subscribe(ICING_EVENTS[key], (eventDetails: Event) => {
    const details = (eventDetails as CustomEvent).detail
    mixpanel.track(ICING_EVENTS[key], { ...details })
  })
})

Object.keys(EVENTS).forEach((property) => {
  const key = property as keyof typeof EVENTS

  subscribe(EVENTS[key], (eventDetails: Event) => {
    const details = (eventDetails as CustomEvent).detail
    mixpanel.track(EVENTS[key], { ...details })
  })
})
