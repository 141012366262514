import { ReactNode } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormattedMessage } from "react-intl"
import { omit } from "lodash"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useCreateKYC, useGetKYC } from "Hooks/API/useKYC"

import { filterPepFields, withMissingPepFields } from "Utils/kycUtils"
import { useFeedbackModal } from "Context/modal-context"
import Button from "UI/Button"
import Loading from "UI/Loading"
import type {
  IKYCPrivateRequest,
  KYCPrivateRequestFormValues,
} from "Interfaces/KYCInterfaces"
import { AxiosError } from "axios"
import Radio from "UI/Radio"
import PEPFields from "../PepUpdated/PEPupdated"
import { emptyInitialPrivateData, emptyPepData } from "../initialData"
import { normalizeToFormValues, normalizeToKycRequest } from "./helpers"
import { baseKYCPrivateSchema, extendedKYCPrivateSchema } from "../validations"
import KYCDepositInvestmentFields from "./KYCDepositInvestmentFields"
import CommonFields from "./CommonFields"
import FormModal from "./FormModal"
import * as S from "./Form.styles"

type FormPropsType = {
  emptyInitialData?: boolean
  submitCallback?: () => void
  buttonText: ReactNode
  hasDepositOrInvestment: boolean
}

enum Relation {
  investment = "investment",
  deposit_size = "deposit_size",
  deposit_frequency = "deposit_frequency",
  withdrawal_frequency = "withdrawal_frequency",
  capital_owner = "capital_owner",
  other_capital_owner = "other_capital_owner",
  source_of_funds = "source_of_funds",
  other_source_of_funds = "other_source_of_funds",
  purpose_of_saving = "purpose_of_saving",
  other_purpose_of_saving = "other_purpose_of_saving",
}

const omitValues: Relation[] = Object.values(Relation)

const PEP = "pep"

const Form = ({
  buttonText,
  emptyInitialData,
  submitCallback,
  hasDepositOrInvestment,
}: FormPropsType) => {
  const { data: fetchedkycData, isLoading: isKycLoading } = useGetKYC(true)
  const createKYCMutation = useCreateKYC()
  const { errorModal } = useFeedbackModal()

  const form = useForm<KYCPrivateRequestFormValues>({
    values:
      emptyInitialData || fetchedkycData instanceof AxiosError
        ? normalizeToFormValues(emptyInitialPrivateData)
        : normalizeToFormValues(
            fetchedkycData
              ? (withMissingPepFields(
                  fetchedkycData,
                  emptyPepData
                ) as IKYCPrivateRequest)
              : emptyInitialPrivateData
          ),
    resolver: yupResolver(
      hasDepositOrInvestment ? extendedKYCPrivateSchema : baseKYCPrivateSchema
    ),
  })

  const onSubmit: SubmitHandler<KYCPrivateRequestFormValues> = (v) => {
    const values = normalizeToKycRequest(v)
    const filteredValues = hasDepositOrInvestment
      ? values
      : omit({ ...values }, omitValues)
    const pep = filterPepFields(filteredValues)

    const { occupancy, ...rest } = { ...filteredValues, pep }
    return createKYCMutation
      .mutateAsync(occupancy ? filteredValues : rest)
      .then((res) => {
        const { updated_at: updatedAt, ...updatedValues } = res
        form.reset(normalizeToFormValues(updatedValues as IKYCPrivateRequest))
        if (submitCallback) {
          submitCallback()
        }
      })
      .catch(() => {
        errorModal()
      })
  }

  const handleTogglePep = (isPep: boolean) => {
    form.setValue(PEP, isPep ? emptyPepData : null)
  }

  const pep = form.watch(PEP)

  if (!form.formState.defaultValues || isKycLoading) {
    return (
      <S.LoadingContainer>
        <Loading />
      </S.LoadingContainer>
    )
  }

  return (
    <FormProvider {...form}>
      <S.FormContainer onSubmit={form.handleSubmit(onSubmit)}>
        <FormModal />

        <CommonFields />

        {hasDepositOrInvestment && <KYCDepositInvestmentFields />}

        <S.FormBlock>
          <S.FormBlockTitle>
            <h3>
              <FormattedMessage
                id="app.mypages.investments.onboarding.KYC.private.formGroup.pep"
                defaultMessage="PEP"
              />
            </h3>
          </S.FormBlockTitle>
          <S.FormFieldsBlock>
            <S.ToggleLabel>
              <FormattedMessage
                id="app.mypages.investments.onboarding.KYC.private.toggles.PEP"
                defaultMessage="Ã„r du en PEP (Person i politiskt utsatt stÃ¤llning), nÃ¤rstÃ¥ende-
                eller har/haft ett affÃ¤rsfÃ¶rhÃ¥llande till en person som Ã¤r eller
                har varit PEP?"
              />
              <div>
                <Radio
                  name="pep-true"
                  value="true"
                  onChange={() => handleTogglePep(true)}
                  checked={!!pep}
                  labelText={
                    <FormattedMessage id="app.common.yes" defaultMessage="Ja" />
                  }
                />
                <Radio
                  name="pep-false"
                  value="false"
                  onChange={() => handleTogglePep(false)}
                  checked={!pep}
                  labelText={
                    <FormattedMessage id="app.common.no" defaultMessage="Nej" />
                  }
                />
              </div>
            </S.ToggleLabel>
            {pep && <PEPFields />}
          </S.FormFieldsBlock>
        </S.FormBlock>

        <S.ConfirmationWrap>
          <FormattedMessage
            id="app.mypages.investments.onboarding.KYC.confirmationMessage"
            defaultMessage="Genom att spara mina svar bekräftar jag att jag svarat korrekt på alla frågor och kommer själv att uppdatera eventuella ändringar på mitt konto."
          />
        </S.ConfirmationWrap>

        <S.ButtonWrap>
          <Button
            type="submit"
            isLoading={createKYCMutation.isLoading}
            disabled={createKYCMutation.isLoading}
          >
            {buttonText}
          </Button>
        </S.ButtonWrap>
      </S.FormContainer>
    </FormProvider>
  )
}

export default Form
