import { useMemo } from "react"
import { FormattedMessage } from "react-intl"

import { formatNumber } from "Utils/formatNumber"
import {
  INSURANCE_AMOUNT_MAX,
  INSURANCE_COMPENSATION_AMOUNT_MAX,
  INSURANCE_TERMS_AND_CONDITIONS_AMOUNT_TIMES,
} from "Constants/dataNumbers"

import * as S from "./InsuranceInfoList.styles"

export const InsuranceInfoList = ({ periodCost }: { periodCost: number }) => {
  const insuranceData = useMemo(() => {
    const montlyCost = periodCost * 0.0795
    const lifetimeAmount = Math.min(
      INSURANCE_AMOUNT_MAX,
      periodCost * INSURANCE_TERMS_AND_CONDITIONS_AMOUNT_TIMES
    )

    const compensationAmount = Math.min(
      periodCost,
      INSURANCE_COMPENSATION_AMOUNT_MAX
    )
    return {
      montlyCost,
      lifetimeAmount,
      compensationAmount,
    }
  }, [periodCost])
  return (
    <>
      <div>
        <S.InsuranceList>
          <li>
            <FormattedMessage id="app.onboarding.insuranceCheck.point1" />
          </li>
          <li>
            <FormattedMessage
              id="app.onboarding.insuranceCheck.point2"
              values={{
                value: formatNumber(insuranceData.compensationAmount, {
                  style: "currency",
                  minimumFractionDigits: 0,
                }),
              }}
            />
          </li>
          <li>
            <FormattedMessage
              id="app.onboarding.insuranceCheck.point3"
              values={{
                value: formatNumber(insuranceData.lifetimeAmount, {
                  style: "currency",
                  minimumFractionDigits: 0,
                }),
              }}
            />
          </li>
        </S.InsuranceList>
      </div>
      <span>
        <strong>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.monthlyCost"
            values={{
              value: formatNumber(insuranceData.montlyCost, {
                style: "currency",
              }),
            }}
          />
        </strong>
      </span>
    </>
  )
}
