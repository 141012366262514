import {
  FC,
  useState,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react"

import { FormattedMessage } from "react-intl"

import Hamburger from "UI/Hamburger"
import useTrapFocus from "Hooks/UI/useTrapFocus"
import useQueryParams from "Hooks/UI/useQueryParams"
import IconFactory from "UI/IconFactory"
import { OnboardingStepsEnum } from "Pages/Unauthenticated/Onboarding/Onboarding.types"
import { HAS_CLEARED_INSTANTOR } from "Constants/storage"
import { getStorageItem } from "Utils/localStorage"
import { useOverflowEffect } from "Hooks/UI/useOverflowEffect"
import Sidemenu from "./SideMenu"
import { STEP_TITLES } from "./constants"
import { Nav, BackButtonWrap, LogoWrap } from "./UnauthenticatedNav.styles"

type UnauthenticatedNavPropTypes = {
  hasBankAccount?: boolean
  isSigned?: boolean
  ppiReviewStatus?: {
    name: string
    completed: boolean
  }
  step?: number
  setStep?: Dispatch<SetStateAction<number>>
}

const UnauthenticatedNav: FC<UnauthenticatedNavPropTypes> = ({
  hasBankAccount,
  isSigned,
  ppiReviewStatus,
  step = OnboardingStepsEnum.LoanCollection,
  setStep,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [sidemenuRef, hamburgerRef] = useTrapFocus(isOpen)
  const query = useQueryParams()

  const isStepBackButtonDisabled = useMemo(() => {
    const hasClearedInstantor = getStorageItem(HAS_CLEARED_INSTANTOR)

    if (hasClearedInstantor && step <= OnboardingStepsEnum.BankAccountDetails) {
      return true
    }

    if (hasBankAccount && step <= OnboardingStepsEnum.Signing) {
      return true
    }

    if (query.get("co-applicant")) {
      return true
    }

    if (isSigned && step <= OnboardingStepsEnum.InsuranceCheck) {
      return true
    }

    if (
      ppiReviewStatus &&
      ppiReviewStatus.completed &&
      step <= OnboardingStepsEnum.LoanPurpose
    ) {
      return true
    }

    return false
  }, [step, hasBankAccount, isSigned, ppiReviewStatus, query])

  const handleBackClick = () => {
    if (step > OnboardingStepsEnum.Accept && setStep) {
      setStep(step - 1)
    }
  }

  const closeMenu = useCallback(
    (e?: TouchEvent | MouseEvent) => {
      if (e && hamburgerRef.current?.contains(e.target as Node)) return
      setIsOpen(false)
    },
    [hamburgerRef]
  )

  const toggleIsOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }

  useOverflowEffect(isOpen)

  return (
    <Nav>
      {step < OnboardingStepsEnum.LoanCollection ? (
        <BackButtonWrap>
          <button
            onClick={handleBackClick}
            type="button"
            aria-label="Go Back a Step"
            disabled={isStepBackButtonDisabled}
          >
            <IconFactory name="chevronRight" />
          </button>

          <span>
            <FormattedMessage id="app.common.step" defaultMessage="Steg" />{" "}
            {step}/6{" "}
          </span>

          <strong>{STEP_TITLES[step]}</strong>
        </BackButtonWrap>
      ) : (
        <LogoWrap>
          <IconFactory name="logo" />
        </LogoWrap>
      )}

      <Hamburger
        handleClick={toggleIsOpen}
        isOpen={isOpen}
        ref={hamburgerRef as React.MutableRefObject<HTMLButtonElement>}
        isBlack
      />

      <Sidemenu
        closeMenu={closeMenu}
        isOpen={isOpen}
        ref={sidemenuRef as React.MutableRefObject<HTMLDivElement>}
      />
    </Nav>
  )
}

export default UnauthenticatedNav
