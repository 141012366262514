import { FC, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import { useGetCustomer } from "Hooks/API/useCustomer"
import { useFeedbackModal } from "Context/modal-context"
import { IBasicLoanService } from "Interfaces/loanInterfaces"
import CustomFormattedDate from "Components/CustomFormattedDate"
import Loading from "UI/Loading"
import IconFactory from "UI/IconFactory"
import { getLoanContractPdf } from "ApiServices/loanContracts"
import { useFileDownload } from "Hooks/API/useFileDownload"
import { BASIC_LOAN_SERVICES_SERVICE } from "Constants/queryKeys"
import { LOAN_CONTRACT_PDF } from "Constants/fileNames"
import { formatNumber } from "Utils/formatNumber"
import * as S from "./Loan.styles"

const LoanDetails: FC = () => {
  const [contractID, setContractID] = useState("")

  const { accountID } = useParams() as { accountID: string }
  const queryClient = useQueryClient()
  const { errorModal } = useFeedbackModal()
  const customer = useGetCustomer()
  const loan = queryClient.getQueryData([
    BASIC_LOAN_SERVICES_SERVICE,
    accountID,
  ]) as IBasicLoanService

  const { downloadFile, fileDownloadQuery } = useFileDownload({
    fileId: contractID,
    enabled: !!contractID,
    fileName: LOAN_CONTRACT_PDF,
    fetchFile: getLoanContractPdf,
    onError: () => errorModal(),
  })

  const handleDownload = (id: string) => {
    setContractID(id)
    downloadFile()
  }

  return (
    <S.LoanWrap>
      <S.LoanDetailsHeader>
        <FormattedMessage
          id="app.mypages.loans.loanInformation"
          defaultMessage="Låneinformation"
        />
      </S.LoanDetailsHeader>
      <S.LoanDetailsTable>
        <S.LoanDetailsRow>
          <S.LoanDetailsRowName id="original-amount">
            <FormattedMessage
              id="app.mypages.loans.loanOriginalSize"
              defaultMessage="Ursprungligt lånebelopp"
            />
          </S.LoanDetailsRowName>
          <S.LoanDetailsRowValue aria-labelledby="original-amount">
            {formatNumber(loan.loan_original_size, {
              style: "currency",
            })}
          </S.LoanDetailsRowValue>
        </S.LoanDetailsRow>
        <S.LoanDetailsRow>
          <S.LoanDetailsRowName id="remaining-amount">
            <FormattedMessage
              id="app.mypages.loans.loanRemainingAmoubt"
              defaultMessage="Återstående lånebelopp"
            />
          </S.LoanDetailsRowName>
          <S.LoanDetailsRowValue aria-labelledby="remaining-amount">
            {formatNumber(loan.remaining_principal, {
              style: "currency",
            })}
          </S.LoanDetailsRowValue>
        </S.LoanDetailsRow>
        <S.LoanDetailsRow>
          <S.LoanDetailsRowName id="interest-rate">
            <FormattedMessage
              id="app.common.interestRate"
              defaultMessage="Ränta"
            />
          </S.LoanDetailsRowName>
          <S.LoanDetailsRowValue aria-labelledby="interest-rate">
            {formatNumber(loan.current_contract.interest_rate, {
              style: "percent",
            })}
          </S.LoanDetailsRowValue>
        </S.LoanDetailsRow>
        <S.LoanDetailsRow>
          <S.LoanDetailsRowName id="effective-interest-rate">
            <FormattedMessage
              id="app.common.effectiveInterest"
              defaultMessage="Effektiv Ränta"
            />
          </S.LoanDetailsRowName>
          <S.LoanDetailsRowValue aria-labelledby="effective-interest-rate">
            {formatNumber(loan.current_contract.effective_interest_rate, {
              style: "percent",
            })}
          </S.LoanDetailsRowValue>
        </S.LoanDetailsRow>
        <S.LoanDetailsRow>
          <S.LoanDetailsRowName id="start-date">
            <FormattedMessage
              id="app.mypages.loans.loanInterestFrom"
              defaultMessage="Ränta gäller från och med"
            />
          </S.LoanDetailsRowName>
          <S.LoanDetailsRowValue aria-labelledby="start-date">
            <CustomFormattedDate
              value={loan.current_contract.period_start_date}
            />
          </S.LoanDetailsRowValue>
        </S.LoanDetailsRow>
        <S.LoanDetailsRow>
          <S.LoanDetailsRowName id="monthly-cost">
            <FormattedMessage
              id="app.common.monthlyCost"
              defaultMessage="Månadskostnad"
            />
          </S.LoanDetailsRowName>
          <S.LoanDetailsRowValue aria-labelledby="monthly-cost">
            {formatNumber(loan.current_contract.period_cost, {
              style: "currency",
            })}
          </S.LoanDetailsRowValue>
        </S.LoanDetailsRow>
        <S.LoanDetailsRow>
          <S.LoanDetailsRowName id="term">
            <FormattedMessage
              id="app.mypages.loans.changeTermModal.term"
              defaultMessage="Löptid"
            />
          </S.LoanDetailsRowName>
          <S.LoanDetailsRowValue aria-labelledby="term">
            {loan.current_contract.repayment_periods}{" "}
            <FormattedMessage
              id="app.common.monthAbbreviated"
              defaultMessage="mån"
            />
          </S.LoanDetailsRowValue>
        </S.LoanDetailsRow>
        <S.LoanDetailsRow>
          <S.LoanDetailsRowName id="insurance">
            <FormattedMessage
              id="app.mypages.loans.changeTermModal.insurance"
              defaultMessage="Försäkring"
            />
          </S.LoanDetailsRowName>
          <S.LoanDetailsRowValue aria-labelledby="insurance">
            <FormattedMessage
              id={
                loan.payment_protection_insurance
                  ? "app.common.yes"
                  : "app.common.no"
              }
            />
          </S.LoanDetailsRowValue>
        </S.LoanDetailsRow>
        <S.LoanDetailsRow>
          <S.LoanDetailsRowName id="agreement">
            <FormattedMessage
              id="app.mypages.loans.agreement"
              defaultMessage="Avtal"
            />
          </S.LoanDetailsRowName>
          <S.LoanDetailsRowValue
            onClick={() => handleDownload(loan.current_contract.uid)}
            aria-labelledby="agreement"
          >
            {fileDownloadQuery.isFetching ? (
              <Loading size="small" />
            ) : (
              <IconFactory name="download" />
            )}
          </S.LoanDetailsRowValue>
        </S.LoanDetailsRow>
      </S.LoanDetailsTable>
      <S.LoanDetailsHeader>
        <FormattedMessage
          id="app.mypages.loans.bankDetails"
          defaultMessage="Bankkupgifter"
        />
      </S.LoanDetailsHeader>
      <S.LoanDetailsTable>
        <S.LoanDetailsRow>
          <S.LoanDetailsRowName id="bank">
            <FormattedMessage
              id="app.mypages.account.bankName"
              defaultMessage="Bank"
            />
          </S.LoanDetailsRowName>
          <S.LoanDetailsRowValue aria-labelledby="bank">
            {customer.data?.bank_name}
          </S.LoanDetailsRowValue>
        </S.LoanDetailsRow>
        <S.LoanDetailsRow>
          <S.LoanDetailsRowName id="bank-account">
            <FormattedMessage
              id="app.mypages.account.bankAccountNo"
              defaultMessage="Bankkonto"
            />
          </S.LoanDetailsRowName>
          <S.LoanDetailsRowValue aria-labelledby="bank-account">
            {customer.data?.bank_clearing_number}
            {" - "}
            {customer.data?.bank_account_number}
          </S.LoanDetailsRowValue>
        </S.LoanDetailsRow>
      </S.LoanDetailsTable>
    </S.LoanWrap>
  )
}

export default LoanDetails
