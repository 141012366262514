import {
  PEPCoworkerOptionsUpdated,
  PEPPositionTypesUpdated,
  PEPRelationTypesUpdated,
} from "../KYC.types"

export const pepCoworkerOptions: PEPCoworkerOptionsUpdated[] = [
  {
    value: "relationship_to_pep",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.relationPosition.coworker.checkbox.relationshipToPep",
      defaultMessage:
        "Tillsammans med en person med ovanstående befattning, verklig förmånstagare till juridiska enheter eller juridiska konstruktioner, eller som på annat sätt haft nära affärsförbindelser med en sådan person.",
    },
  },
  {
    value: "beneficiary_of_pep",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.relationPosition.coworker.checkbox.beneficiaryOfPep",
      defaultMessage:
        "Ensam förmånstagare till juridiska enheter eller juridiska konstruktioner  som har upprättats till förmån för en person med ovanstående befattning.",
    },
  },
]

export const pepRelationOptions: PEPRelationTypesUpdated[] = [
  {
    value: "married",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.relation.married",
      defaultMessage: "Maka / make",
    },
  },
  {
    value: "partner",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.relation.partner",
      defaultMessage:
        "Partner som enligt internationell lag likställs med maka / make",
    },
  },
  {
    value: "children",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.relation.children",
      defaultMessage: "Barn eller barns make / partner",
    },
  },
  {
    value: "parents",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.relation.parents",
      defaultMessage: "Förälder",
    },
  },
]

export const pepPositionOptions: PEPPositionTypesUpdated[] = [
  {
    value: "head_of_state",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.checkbox.headOfState",
      defaultMessage:
        "Stats- eller regeringschef, minister, eller vice/biträdande minister",
    },
  },
  {
    value: "member_of_parliament",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.checkbox.memberOfParliment",
      defaultMessage: "Parlamentsledamot",
    },
  },
  {
    value: "judge",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.checkbox.judge",
      defaultMessage:
        "Domare i högsta domstolen eller annat rättslig organ vilkas beslut endast undantagsvis kan överklagas",
    },
  },
  {
    value: "auditor",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.checkbox.auditor",
      defaultMessage:
        "Högre tjänsteman vid revisionsmyndighet eller styrelseledamot i centralbank",
    },
  },
  {
    value: "diplomat",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.checkbox.diplomat",
      defaultMessage:
        "Ambassadör, diplomatiskt sändebud, eller hög officer i försvarsmakten",
    },
  },
  {
    value: "public_company_mgmt",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.checkbox.publicCompanyManagement",
      defaultMessage:
        "Person som ingår i statsägt företags förvaltnings-, lednings- eller kontrollorgan",
    },
  },
  {
    value: "international_pep",
    label: {
      id: "app.mypages.investments.onboarding.KYC.private.PEP.checkbox.internationalPEP",
      defaultMessage: "Någon av befattningarna i 1 - 5 på internationell nivå",
    },
  },
]
