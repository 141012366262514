import { flex } from "config/mixins"
import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const CancelModalBody = styled.div`
  ${flex.columnAlignCenter}
  text-align: center;
  padding: 40px 30px;

  @media screen and (max-width: ${mediaQueries.medium}) {
    padding: 20px;
  }
`
export const ButtonsWrap = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`
