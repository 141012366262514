import * as yup from "yup"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { account } from "se-bank-account"
import { ACCOUNT_TYPE, AccountType } from "Pages/LoanCollection/Constants"
import { OtherActorFormValues } from "./OtherActorFormValues.types"

export const OtherBankSchema: yup.SchemaOf<OtherActorFormValues> = yup
  .object()
  .shape({
    bankName: yup.string().required(),
    accountType: yup.string().oneOf(["BG", "PG"]).required(),
    bankAccount: yup
      .string()
      .required()
      .test(
        "bank-account-validation",
        "Ogiltigt kontonummer",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (value: string, context: any) => {
          return account(
            `${
              ACCOUNT_TYPE[
                `${context?.parent?.accountType as keyof AccountType}`
              ]
            }${value}`
          )
        }
      ),
    loanType: yup.string().required(),
    paymentReference: yup.string().required(),
    totalAmount: yup
      .number()
      .typeError("Obligatorisk")
      .positive()
      .min(1)
      .required("Obligatorisk"),
    interestRate: yup
      .number()
      .typeError("Obligatorisk")
      .positive()
      .min(1)
      .required("Obligatorisk"),
    monthlyCost: yup
      .number()
      .typeError("Obligatorisk")
      .positive()
      .min(1)
      .required("Obligatorisk"),
  })
  .required()
