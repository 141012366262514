import styled from "styled-components"

export const YellowAttentionBox = styled.div`
  background: var(--light-yellow);
  border-radius: 16px;
  padding: 0.125em;

  ul {
    padding: 0.5em 1.5em;
    li {
      list-style-type: none;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.6639 10.6639H5.32782V5.32782H10.6556V10.6639H10.6639ZM8 0C3.58221 0 0 3.58221 0 8C0 12.4178 3.58221 16 8 16C12.4178 16 16 12.4178 16 8C15.9917 3.58221 12.4178 0 8 0Z' fill='%23131313'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      padding: 2px 0 3px 24px;
      margin: 1em 0;
      font-weight: bold;
      vertical-align: baseline;
      background-position: left center;
    }
  }
`
