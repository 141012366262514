import { LoanApplicationForm } from "Interfaces/loanApplicationInterfaces"
import { PaymentDetails } from "./LoanApplication.types"
import { DEFAULT_AMOUNT, DEFAULT_REPAYMENT_PERIODS } from "./constants"

export const emptyLoanApplicationData: LoanApplicationForm = {
  uid: "",
  amount: DEFAULT_AMOUNT,
  repayment_periods: DEFAULT_REPAYMENT_PERIODS,
  is_consolidation_requested: false,
  loan_purpose: "",
  source: "",
  applicant: {
    legal_entity_number: "",
    email: "",
    tel: "",
    customer_origin: "",
    applicant_detail: {
      has_kids: false,
      number_of_kids: 0,
      housing_cost: 0,
      housing_type: "",
      marital_status: "",
      occupation: "",
      work_income: 0,
      employer: "",
      telephone_to_employer: "",
      occupation_from_year: 0,
      occupation_from_month: 0,
    },
  },
  has_coapplicant: false,
  co_applicant: {
    legal_entity_number: "",
    email: "",
    tel: "",
    customer_origin: "",
    applicant_detail: {
      has_kids: false,
      number_of_kids: "",
      housing_cost: 0,
      housing_type: "",
      marital_status: "",
      occupation: "",
      work_income: 0,
      employer: "",
      telephone_to_employer: "",
      occupation_from_year: 0,
      occupation_from_month: 0,
    },
  },
}

export const initialPaymentDetails: PaymentDetails = {
  amount: "",
  interestRate: "",
  periodCost: "",
  effectiveInterestRate: "",
  totalCost: "",
  fee: "",
  adminFee: "",
}
