interface BankInformation {
  background: string
  description: {
    fullName: string
    firstCharacter: string
    color: string
  }
}

type BanksInformation = {
  [K in string]: BankInformation
}

export const banksInformation: BanksInformation = {
  santander: {
    background: "#CC0000",
    description: {
      fullName: "Santander Consumer Bank",
      firstCharacter: "S",
      color: "#FFFFFF",
    },
  },
  klarna: {
    background: "#FFB3C7",
    description: {
      fullName: "Klarna Bank",
      firstCharacter: "K",
      color: "#000000",
    },
  },
  marginalen: {
    background: "#FFFFFF",
    description: {
      fullName: "Marginalen Bank Bankaktiebolag",
      firstCharacter: "M",
      color: "#FA0032",
    },
  },
  nordax: {
    background: "#09261B",
    description: {
      fullName: "Nordax Bank",
      firstCharacter: "N",
      color: "#B2E3E0",
    },
  },
  lunar: {
    background: "#FFFFFF",
    description: {
      fullName: "Lunar Bank",
      firstCharacter: "L",
      color: "#000000",
    },
  },
  northmill: {
    background: "#8C5AC8",
    description: {
      fullName: "Northmill",
      firstCharacter: "N",
      color: "#FFFFFF",
    },
  },
  sevenday: {
    background: "#FFFFFF",
    description: {
      fullName: "SevenDay Bank",
      firstCharacter: "S",
      color: "#66CA58",
    },
  },
  coop: {
    background: "#333",
    description: {
      fullName: "Coop",
      firstCharacter: "C",
      color: "#00aa46",
    },
  },
  avida: {
    background: "#FFFFFF",
    description: {
      fullName: "Avida",
      firstCharacter: "A",
      color: "#EF7A13",
    },
  },
  ecster: {
    background: "#5EB189",
    description: {
      fullName: "Ecster",
      firstCharacter: "E",
      color: "#FFFFFF",
    },
  },
  icabanken: {
    background: "#FFFFFF",
    description: {
      fullName: "Ica Banken",
      firstCharacter: "I",
      color: "#E13205",
    },
  },
  remember: {
    background: "#333",
    description: {
      fullName: "Remember",
      firstCharacter: "R",
      color: "#F28D00",
    },
  },
  resurs: {
    background: "#FFFFFF",
    description: {
      fullName: "Resurs",
      firstCharacter: "R",
      color: "#333",
    },
  },
  collector: {
    background: "#420F5C",
    description: {
      fullName: "Collector",
      firstCharacter: "C",
      color: "#FFF",
    },
  },
  dnb: {
    background: "#14555A",
    description: {
      fullName: "DnB",
      firstCharacter: "D",
      color: "#FFF",
    },
  },
  fairlo: {
    background: "#FFF",
    description: {
      fullName: "Fairlo",
      firstCharacter: "F",
      color: "#41AC3D",
    },
  },
  ikano: {
    background: "#FFF",
    description: {
      fullName: "Ikano",
      firstCharacter: "I",
      color: "#EC0000",
    },
  },
  lea: {
    background: "#FFF",
    description: {
      fullName: "Lea",
      firstCharacter: "L",
      color: "#333",
    },
  },
  norwegian: {
    background: "#D81939",
    description: {
      fullName: "Norwegian",
      firstCharacter: "N",
      color: "#FFF",
    },
  },
  svea: {
    background: "#FFF",
    description: {
      fullName: "Svea",
      firstCharacter: "S",
      color: "#00325c",
    },
  },
  tryggkredit: {
    background: "#4FB448",
    description: {
      fullName: "TryggKredit",
      firstCharacter: "T",
      color: "#FFF",
    },
  },
  walley: {
    background: "#0D3843",
    description: {
      fullName: "Walley",
      firstCharacter: "W",
      color: "#FFF",
    },
  },
  volvo: {
    background: "#FFF",
    description: {
      fullName: "Volvo",
      firstCharacter: "V",
      color: "#003057",
    },
  },
  wasakredit: {
    background: "#FFF",
    description: {
      fullName: "Wasakredit",
      firstCharacter: "W",
      color: "#005aa0",
    },
  },
  entercardcoop: {
    background: "#1E22AA",
    description: {
      fullName: "Entercard Coop",
      firstCharacter: "E",
      color: "#FFF",
    },
  },
  entercardmervarde: {
    background: "#1E22AA",
    description: {
      fullName: "Entercard Mervarde",
      firstCharacter: "E",
      color: "#FFF",
    },
  },
  entercardmoregolf: {
    background: "#1E22AA",
    description: {
      fullName: "Entercard More Golf",
      firstCharacter: "E",
      color: "#FFF",
    },
  },
  entercardpromervarde: {
    background: "#1E22AA",
    description: {
      fullName: "Entercard Pro Mervarde",
      firstCharacter: "E",
      color: "#FFF",
    },
  },
}
