import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const IntroWrap = styled.div`
  margin: 3em 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: ${mediaQueries.medium}) {
    margin: 2em 0 1em;
  }
  h1 {
    margin-bottom: 1em;
  }
`

export const SavingsWrap = styled.section`
  border-radius: 20px;
  margin-bottom: 2em;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3em 0;
  align-items: center;
  gap: 3em;
  h1 {
    width: 100%;
    flex: 1;
  }
  svg {
    width: 50%;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    padding: 1em 0;
    margin-bottom: 0;
  }
  @media screen and (min-width: ${mediaQueries.large}) {
    flex-direction: row;
    align-items: flex-start;
    gap: 0;
    svg {
      width: 300px;
      margin-right: 3em;
    }
  }
`
export const CTAContent = styled.main`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  p {
    color: ${(props) => props.theme.fontColor};
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 0 0 15px 0;
    svg {
      min-width: 35px;
      width: auto;
      margin-right: 30px;
      margin-top: 0;
      @media screen and (max-width: ${mediaQueries.medium}) {
        width: 25px;
        min-width: 25px;
        margin-right: 20px;
      }
    }
  }
`

export const ButtonWrap = styled.div`
  margin-top: 4em;
  display: flex;
  align-items: center;
  gap: 40px;

  button {
    width: 180px;
  }

  @media screen and (max-width: ${mediaQueries.medium}) {
    width: 100%;
    margin-top: 1em;
    button {
      margin: 0 auto;
    }
  }

  @media screen and (max-width: ${mediaQueries.small}) {
    flex-direction: column;
    gap: 20px;
    button {
      width: 100%;
    }
  }
`
