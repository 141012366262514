import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const SavingsOverviewBody = styled.div`
  padding: 0 4em;
  height: 143px;
  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    height: auto;
  }
`

export const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  gap: 0 2em;
  padding: 1em 0;
  justify-content: center;
  button {
    min-width: 115px;
    max-height: 52px;
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    flex-direction: row;
    gap: 0em 1em;
    margin-bottom: 2em;
    align-items: center;
  }
`
export const SavingsOverviewWrap = styled.div`
  background: ${(p) => p.theme.infoBox};
  margin-top: 0.5em;
  @media screen and (max-width: ${mediaQueries.medium}) {
    margin-top: 0;
  }
`

export const SavingsModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin: 32px 0;

  p {
    margin: 0;
  }
`
