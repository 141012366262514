import styled from "styled-components"

export const ActivateText = styled.span`
  font-weight: 500;
`

export const ActiveBalance = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  svg {
    transform: scale(1.1);
    margin-left: 0.5em;
    path {
      stroke: ${(p) => p.theme.fontColor};
    }
  }
`
