import { useMemo } from "react"
import { useIntl } from "react-intl"

export const useOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        id: 1,
        value: "",
        name: intl.formatMessage({
          id: "app.onboarding.loanPurpose.option1",
          defaultMessage: "Syfte",
        }),
      },
      {
        id: 2,
        value: "consolidate_existing_loans",
        name: intl.formatMessage({
          id: "app.onboarding.loanPurpose.option2",
          defaultMessage: "Samla andra befintliga lån",
        }),
      },
      {
        id: 3,
        value: "consumption",
        name: intl.formatMessage({
          id: "app.onboarding.loanPurpose.option3",
          defaultMessage: "Konsumtion",
        }),
      },
      {
        id: 4,
        value: "capital_purchase",
        name: intl.formatMessage({
          id: "app.onboarding.loanPurpose.option4",
          defaultMessage: "Fordonsköp/Elektronikköp",
        }),
      },
      {
        id: 5,
        value: "capital_improvement",
        name: intl.formatMessage({
          id: "app.onboarding.loanPurpose.option5",
          defaultMessage: "Renovering",
        }),
      },
      {
        id: 6,
        value: "furnitures",
        name: intl.formatMessage({
          id: "app.onboarding.loanPurpose.option6",
          defaultMessage: "Möbler",
        }),
      },
      {
        id: 7,
        value: "equity_down_payment",
        name: intl.formatMessage({
          id: "app.onboarding.loanPurpose.option7",
          defaultMessage: "Handpenning",
        }),
      },
      {
        id: 8,
        value: "purchase_of_service",
        name: intl.formatMessage({
          id: "app.onboarding.loanPurpose.option8",
          defaultMessage: "Inköp av tjänster",
        }),
      },
      {
        id: 9,
        value: "other",
        name: intl.formatMessage({
          id: "app.onboarding.loanPurpose.option9",
          defaultMessage: "Övrig",
        }),
      },
    ],
    [intl]
  )
}
