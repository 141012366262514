import { FC, memo } from "react"
import { FormikProps, FormikErrors } from "formik"
import { FormattedMessage } from "react-intl"

import Input from "UI/Input"
import { formatNumber } from "Utils/formatNumber"
import { defaultValues } from "./data"
import * as Styled from "./styles"

type CollectLoansErrors = {
  [key: string]: string | unknown
}

type CustomFormikErrors = {
  errors: FormikErrors<CollectLoansErrors>
}

type ExistingLoanPropTypes = {
  formik: Omit<FormikProps<typeof defaultValues>, "errors"> & CustomFormikErrors
  index: number
}

const ExistingLoan: FC<ExistingLoanPropTypes> = ({ formik, index }) => {
  const { values, errors, setFieldValue } = formik

  return (
    <Styled.InputsWrap aria-label={`lån ${index + 1}`}>
      <Styled.ExistingLoanDesc data-cy="loan-desc">
        {values.existing_loans[index]?.creditor}{" "}
        {formatNumber(values.existing_loans[index]?.amount, {
          style: "currency",
        })}
      </Styled.ExistingLoanDesc>
      <Input
        placeholder={
          <FormattedMessage
            id="app.mypages.loans.collectLoans.ocr"
            defaultMessage="OCR"
          />
        }
        name={`existing_loans.ocr${index + 1}`}
        value={
          values.existing_loans[index]?.ocr > 0
            ? values.existing_loans[index]?.ocr
            : ""
        }
        onChange={(e) => {
          setFieldValue(`existing_loans[${index}].ocr`, e.currentTarget.value)
        }}
        type="number"
        inputMode="numeric"
        pattern="\d*"
        error={
          Array.isArray(errors.existing_loans) &&
          !!errors.existing_loans[index]?.ocr
        }
        message={
          Array.isArray(errors.existing_loans) &&
          errors.existing_loans[index]?.ocr
        }
      />
      <Input
        placeholder={
          <FormattedMessage
            id="app.mypages.loans.collectLoans.bgNumber"
            defaultMessage="BG/PG nummer"
          />
        }
        value={
          values.existing_loans[index]?.payment_to > 0
            ? values.existing_loans[index]?.payment_to
            : ""
        }
        name={`existing_loans.payment_to${index + 1}`}
        onChange={(e) => {
          setFieldValue(
            `existing_loans[${index}].payment_to`,
            e.currentTarget.value
          )
        }}
        type="number"
        inputMode="numeric"
        pattern="\d*"
        error={
          Array.isArray(errors.existing_loans) &&
          !!errors.existing_loans[index]?.payment_to
        }
        message={
          Array.isArray(errors.existing_loans) &&
          errors.existing_loans[index]?.payment_to
        }
      />
      <Input
        placeholder={<FormattedMessage id="app.mypages.account.bankName" />}
        name={`existing_loans.creditor${index + 1}`}
        value={values.existing_loans[index]?.creditor}
        onChange={(e) => {
          setFieldValue(
            `existing_loans[${index}].creditor`,
            e.currentTarget.value
          )
        }}
        type="text"
        error={
          Array.isArray(errors.existing_loans) &&
          !!errors.existing_loans[index]?.creditor
        }
        message={
          Array.isArray(errors.existing_loans) &&
          errors.existing_loans[index]?.creditor
        }
      />
      <Input
        placeholder={
          <FormattedMessage id="app.common.amount" defaultMessage="Belopp" />
        }
        name={`existing_loans.amount${index + 1}`}
        value={
          values.existing_loans[index]?.amount > 0
            ? values.existing_loans[index]?.amount
            : ""
        }
        onChange={(e) => {
          setFieldValue(
            `existing_loans[${index}].amount`,
            e.currentTarget.value
          )
        }}
        type="number"
        inputMode="numeric"
        pattern="\d*"
        min={0}
        max={500000}
        error={
          Array.isArray(errors.existing_loans) &&
          !!errors.existing_loans[index]?.amount
        }
        message={
          Array.isArray(errors.existing_loans) &&
          errors.existing_loans[index]?.amount
        }
      />
    </Styled.InputsWrap>
  )
}

export default memo(ExistingLoan)
