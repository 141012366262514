import styled from "styled-components"

export const Wrap = styled.svg`
  max-width: 100%;
  transform: rotate(-90deg) scale(1, -1);
`

export const Circle = styled.circle`
  stroke: var(--light-yellow);
  fill: none;
`

export const Progress = styled.circle`
  fill: none;
  stroke: var(--light-gray);

  transition: stroke-dashoffset 850ms ease-in-out;
`
