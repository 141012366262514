import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

type IconWrapPropsType = {
  bg: string
}

export const LanguageWrap = styled.div`
  margin: 2em 0;
  width: 50%;
  button {
    margin-top: 1.5em;
  }

  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    width: 100%;
  }
`
export const LanguageInfo = styled.p``

export const LanguageItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0;
  border-bottom: 1px solid var(--light-gray);
  &:last-of-type {
    border-bottom: 0;
  }
  label {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    font-weight: 500;
    input {
      margin-left: auto;
    }
  }
`

export const IconWrap = styled.div<IconWrapPropsType>`
  position: relative;
  width: 25px;
  height: 25px;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 20px;
  svg {
    position: absolute;
  }
`
