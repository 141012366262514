import { useQuery } from "react-query"

import { getInsuranceEligibilities } from "ApiServices/insurance"
import { LoanInsuranceEligibilities } from "Interfaces/loanInterfaces"
import { INSURANCE_ELIGIBILITIES } from "Constants/queryKeys"

export const useGetInsuranceEligibilities = () => {
  return useQuery<LoanInsuranceEligibilities>({
    queryKey: [INSURANCE_ELIGIBILITIES],
    queryFn: getInsuranceEligibilities,
  })
}
