import styled, { css } from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"
import {
  IconWrapPropTypes,
  InfoBannerTypeEnum,
  StyledInformationBannerPropTypes,
} from "./InformationBanner.types"

export const StyledInformationBanner = styled.div<StyledInformationBannerPropTypes>`
  position: relative;
  margin: 1em 0;
  border-radius: 16px;
  border: 1px solid;

  .content-wrap {
    display: grid;
    grid-template-columns: max-content auto;
    gap: 0.5em;
    grid-template-areas:
      "icon title"
      "icon info";
    align-items: center;
    padding: 1em 0.5em;

    @media screen and (min-width: ${mediaQueries.large}) {
      gap: 1em;
    }

    h4 {
      grid-area: title;
      margin: 0;
    }

    p {
      line-height: 18px;
      margin: 0;
      grid-area: info;
    }
  }

  .button-wrap {
    position: absolute;
    right: 4px;
    top: 4px;
    line-height: 0;
  }

  ${(p) => {
    switch (p.type) {
      case InfoBannerTypeEnum.Attention:
        return "border-color: var(--light-yellow)"
      case InfoBannerTypeEnum.Error:
        return "border-color: var(--brand-danger)"
      case InfoBannerTypeEnum.Success:
        return "border-color: var(--brand-success)"
      case InfoBannerTypeEnum.Info:
        return css`
          background-color: var(--light-yellow);
          align-items: center;
          .content-wrap {
            justify-content: center;
          }
        `
      default:
        return null
    }
  }}

  .information-body {
    color: var(--dark-gray);
  }
`

export const IconWrap = styled.div<IconWrapPropTypes>`
  grid-area: icon;
  justify-self: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 40px;
  height: 40px;

  svg {
    width: 100%;
    height: 100%;
  }

  ${(p) => {
    switch (p.type) {
      case InfoBannerTypeEnum.Attention:
        return "background-color: var(--light-yellow)"
      case InfoBannerTypeEnum.Error:
        return "background-color: var(--brand-danger)"
      case InfoBannerTypeEnum.Success:
        return "background-color: var(--brand-success)"
      case InfoBannerTypeEnum.Info:
        return css`
          svg path {
            fill: var(--black);
          }
        `
      default:
        return null
    }
  }}
`

export const Button = styled.button`
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;

  svg {
    width: 100%;
    height: 100%;
  }
`
