import { FC, useState } from "react"
import { useParams } from "react-router-dom"
import { AnimatePresence } from "framer-motion"

import { useUpdatePaymentProtectionInsurance } from "Hooks/API/useLoansApplications"
import LoanPerksBox from "../../Shared/LoanPerksBox"
import type { StepPropsType } from "../../Onboarding.types"
import InsuranceForm from "./InsuranceForm"
import { SignInsuranceAgreementModal } from "./SignInsuranceAgreementModal/SignInsuranceAgreementModal"
import * as Styles from "../../Onboarding.styles"

const InsuranceCheck: FC<StepPropsType> = ({ data }) => {
  const { id } = useParams() as { id: string }
  const [showSignInsuranceAgreementModal, setShowSignInsuranceAgreementModal] =
    useState(false)

  const updatePaymentProtectionInsuranceMutation =
    useUpdatePaymentProtectionInsurance(id)

  const handleUpdatePaymentProtectionInsurance = ({
    withInsurance,
  }: {
    withInsurance: boolean
  }) => {
    return updatePaymentProtectionInsuranceMutation.mutateAsync({
      isPPI: { payment_protection_insurance: withInsurance },
    })
  }

  const openSignInsuranceAgreementModal = () => {
    setShowSignInsuranceAgreementModal(true)
  }

  const closeSignInsuranceAgreementModal = () => {
    setShowSignInsuranceAgreementModal(false)
  }

  return (
    <Styles.Container>
      <InsuranceForm
        periodCost={data.period_cost}
        handleSubmit={openSignInsuranceAgreementModal}
        handleSubmitWithoutInsurance={() => {
          handleUpdatePaymentProtectionInsurance({ withInsurance: false })
        }}
      />
      <Styles.MobileAttentionBox>
        <LoanPerksBox estimatedCashBack={data.estimated_cashback} />
      </Styles.MobileAttentionBox>
      <AnimatePresence>
        {showSignInsuranceAgreementModal && (
          <SignInsuranceAgreementModal
            accountID={id}
            onClose={closeSignInsuranceAgreementModal}
            handleUpdatePaymentProtectionInsurance={
              handleUpdatePaymentProtectionInsurance
            }
            isUpdateInsuranceError={
              updatePaymentProtectionInsuranceMutation.isError
            }
          />
        )}
      </AnimatePresence>
    </Styles.Container>
  )
}

export default InsuranceCheck
