import styled from "styled-components"
import { font } from "Styles/styleGlobal"
import { flex } from "config/mixins"
import { FeatureBoxWrapProps } from "./FeatureBox.types"

export const FeatureBoxWrap = styled.div<FeatureBoxWrapProps>`
  ${flex.column}
  padding: 1.5em;
  background-color: ${(p) => `rgba(${p.color}, 0.7)`};
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  a {
    color: var(--black);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  p,
  h4 {
    margin: 0;
    padding: 0;
  }
  p {
    font-weight: 500;
    font-size: ${font.size.lg};
  }

  h4 {
    font-size: ${font.size.base};
    font-weight: 400;
    margin-bottom: 10px;
  }

  svg {
    margin-left: auto;
    display: block;
    width: 30px;
    margin-top: auto;
  }
`

export const FeatureBoxBody = styled.div`
  margin-bottom: 1em;
`
