import { FC, useMemo, useState, useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { useQueryClient } from "react-query"

import type { IDepositServices } from "Interfaces/depositInterfaces"
import { useCreateDepositReports } from "Hooks/API/useReports"
import { useFeedbackModal } from "Context/modal-context"
import Button from "UI/Button"
import { DEPOSIT_SERVICES_DATA } from "Constants/queryKeys"
import { DepositServiceStatus } from "types/enums"
import RadioItem from "./RadioItem"
import type { AccountsComponentProps } from "../Reports.types"
import {
  SavingsWrap,
  ErrorMessage,
  SubmitButtonContainer,
} from "./SavingsAccounts.styles"

const SavingsAccounts: FC<AccountsComponentProps> = ({ children }) => {
  const queryClient = useQueryClient()
  const [activeId, setActiveId] = useState<string | null>(null)
  const [rangeDate, setRangeDate] = useState({
    start_date: "",
    end_date: "",
  })
  const { successModal, errorModal } = useFeedbackModal()
  const createDepositReports = useCreateDepositReports()

  const initialData = queryClient.getQueryData<IDepositServices>(
    DEPOSIT_SERVICES_DATA
  )

  const filteredSavings = useMemo(
    () =>
      initialData?.data.filter(
        (deposit) =>
          deposit.status === DepositServiceStatus.active ||
          deposit.status === DepositServiceStatus.closed
      ),
    [initialData]
  )

  const handleChange = useCallback(
    (id: string) => setActiveId(id),
    [setActiveId]
  )
  const handleRangeDate = useCallback(
    (value: { start_date: string; end_date: string }) => setRangeDate(value),
    [setRangeDate]
  )

  const handleCreateReport = useCallback(() => {
    if (activeId) {
      createDepositReports
        .mutateAsync({
          ...rangeDate,
          deposit_service_uid: activeId,
        })
        .then(() => {
          successModal(
            <FormattedMessage
              id="app.mypages.reports.creation.successMessage"
              defaultMessage="Din rapport håller på att skapas! Var god vänta. Den kommer att visas i listan om några sekunder."
            />
          )
        })
        .catch(() => {
          errorModal()
        })
    }
  }, [activeId, createDepositReports, rangeDate, successModal, errorModal])

  if (!filteredSavings?.length) {
    return (
      <ErrorMessage>
        <FormattedMessage
          id="app.mypages.reports.savings.noAccounts"
          defaultMessage="You don't have any active savings account"
        />
      </ErrorMessage>
    )
  }

  return (
    <>
      <h3>
        <FormattedMessage
          id="app.mypages.reports.creation.filters.selectAccount"
          defaultMessage="Basera rapporten på kontot:"
        />
      </h3>
      <SavingsWrap>
        {filteredSavings?.map((account) => (
          <RadioItem
            key={account.uid}
            onChange={handleChange}
            checked={activeId === account.uid}
            account={account}
          />
        ))}

        {filteredSavings?.length > 0 &&
          activeId &&
          children(activeId, handleRangeDate, "savings")}

        <SubmitButtonContainer>
          <Button
            disabled={
              !activeId ||
              !rangeDate.start_date ||
              createDepositReports.isLoading
            }
            onClick={handleCreateReport}
            isLoading={createDepositReports.isLoading}
          >
            <FormattedMessage id="app.mypages.reports.creation.create" />
          </Button>
        </SubmitButtonContainer>
      </SavingsWrap>
    </>
  )
}

export default SavingsAccounts
