import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  p {
    margin: 0;
    color: gray;
  }
`

export const MonthlyCostText = styled.p`
  color: black !important;
  font-weight: bold;
  font-size: ${font.size.lg};
`
