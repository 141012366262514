import {
  ApplicantHousingType,
  ApplicantMaritalStatus,
  ApplicantOccupationType,
} from "Interfaces/loanApplicationInterfaces"

export const existingLoan = {
  ocr: 0,
  creditor: "",
  payment_to: 0,
  amount: 0,
}

export const defaultValues = {
  amount: 0,
  repayment_periods: 12,
  applicant: {
    email: "",
    tel: "",
    legal_entity_number: "",
    applicant_detail: {
      work_income: 0,
      occupation_from_year: 0,
      occupation_from_month: 0,
      housing_cost: 0,
      occupation: "" as ApplicantOccupationType,
      marital_status: "" as ApplicantMaritalStatus,
      housing_type: "" as ApplicantHousingType,
      number_of_kids: "",
      employer: "",
    },
  },
  existing_loans: [existingLoan],
}
