import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const Overview = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-direction: row;

  div > span {
    color: var(--dark-gray);
  }

  @media screen and (min-width: ${mediaQueries.medium}) {
    flex-direction: column;
  }

  @media screen and (min-width: ${mediaQueries.extraLarge}) {
    flex-direction: row;
  }

  border-bottom: 2px solid var(--light-gray);
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;

  margin-bottom: 1.65em;
`
