import styled, { css } from "styled-components"
import { font } from "Styles/styleGlobal"
import { InfoPropTypes } from "./InfoBadge.types"

export const Badge = styled.span`
  display: inline-block;
  position: relative;
  min-width: 16px;
  width: 16px;
  height: 16px;
  align-self: center;
  margin: 0.2em;
  cursor: pointer;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }

  svg path {
    fill: ${(props) => props.theme.foreground};
  }

  &:hover > p {
    visibility: visible;
    opacity: 1;
  }
`

export const Info = styled.p<InfoPropTypes>`
  visibility: hidden;
  width: 100vw;
  max-width: 200px;
  position: absolute;
  padding: 0.8em;
  line-height: 1.2;
  border-radius: 8px;
  transition: opacity 0.3s;
  opacity: 0;
  z-index: 1000;
  text-align: left;

  :before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    transform: translate(50%, -50%) rotate(45deg);
  }

  ${(p) => css`
    font-size: ${font.size.xs};
    background: ${p.theme.foreground};
    color: ${p.theme.background};
    :before {
      background: ${p.theme.foreground};
    }
  `}

  ${(props) => {
    switch (props.placement) {
      case "top":
        return css`
          bottom: 100%;
          right: 50%;
          transform: translate(50%, 0);

          &:before {
            top: 100%;
            right: 50%;
          }
        `
      case "bottom":
        return css`
          top: 100%;
          right: 50%;
          transform: translate(50%, 0);

          &:before {
            top: 0;
            right: 50%;
          }
        `
      case "right":
        return css`
          bottom: -25%;
          left: 175%;
          transform: translate(0, 50%);

          &:before {
            top: 50%;
            right: 100%;
          }
        `
      case "left":
        return css`
          bottom: -25%;
          right: 175%;
          transform: translate(0, 50%);

          &:before {
            top: 50%;
            right: 0;
          }
        `
      default:
        return null
    }
  }}
`
