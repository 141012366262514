import Lottie from "react-lottie"

import animationData from "./man-yellow-phone-animation.json"

export const Animation = () => {
  return (
    <Lottie
      options={{
        animationData,
        autoplay: true,
        loop: true,
      }}
      style={{ maxWidth: 520 }}
    />
  )
}
