import { FC, useRef, useState, useEffect, useCallback, useMemo } from "react"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import Button from "UI/Button"
import Range from "UI/Range"
import FeedbackDialogue from "UI/FeedbackDialogue"
import { useCreateLoanApplication } from "Hooks/API/useLoansApplications"
import { determineMonthlyCost, determineTotalCost } from "Utils/calculations"
import BackTitleWithLink from "Components/BackTitleWithLink"
import { useFeedbackModal } from "Context/modal-context"
import LoanApplicationForm from "Components/LoanApplicationForm"
import { Urls } from "Constants/urls"
import * as Styled from "./styles"
import { CollectLoansSchema } from "./validations"
import { defaultValues, existingLoan } from "./data"
import ExistingLoan from "./ExistingLoan"

const CollectLoans: FC = () => {
  const loanDurationtEl = useRef() as React.MutableRefObject<HTMLInputElement>
  const [modal, setModal] = useState(false)
  const createLoan = useCreateLoanApplication()
  const navigate = useNavigate()
  const { errorModal } = useFeedbackModal()

  const formik = useFormik({
    initialValues: defaultValues,
    validateOnChange: false,
    validationSchema: CollectLoansSchema,
    onSubmit: (values) => {
      createLoan
        .mutateAsync(values)
        .then(() => setModal(true))
        .catch(() => errorModal())
    },
  })

  const { values, setFieldValue, errors } = formik

  const monthlyCost = useMemo(() => determineMonthlyCost(values), [values])

  const totalCost = useMemo(() => determineTotalCost(values), [values])

  const handleLoanDurationChange = () => {
    const { min } = loanDurationtEl.current
    const { max } = loanDurationtEl.current
    const val = loanDurationtEl.current.value
    loanDurationtEl.current.style.backgroundSize = `${
      ((Number(val) - Number(min)) * 100) / (Number(max) - Number(min))
    }% 100%`

    setFieldValue("repayment_periods", val)
    determineMonthlyCost(values)
  }

  const handleChangeToNumber = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLSelectElement>
        | React.ChangeEvent<HTMLInputElement>
    ) => {
      const { value, name } = event.target
      setFieldValue(name, value !== "" ? parseInt(value, 10) : value)
    },
    [setFieldValue]
  )

  const handleAddLoan = () => {
    setFieldValue("existing_loans", [...values.existing_loans, existingLoan])
  }

  const handleDeleteLoan = () => {
    values.existing_loans.splice(values.existing_loans.length - 1, 1)
    setFieldValue("existing_loans", values.existing_loans)
  }

  useEffect(() => {
    const total = values.existing_loans.reduce(
      (prev, item) => prev + (item.amount ? +item.amount : 0),
      0
    )
    setFieldValue("amount", total)
  }, [setFieldValue, values.existing_loans, values.existing_loans.length])

  return (
    <Styled.CollectLoansWrapper>
      {modal && (
        <FeedbackDialogue
          body={
            <p>
              <FormattedMessage
                id="app.mypages.loans.applyForLoan.success"
                defaultMessage="Ansökan har skickas!"
              />
            </p>
          }
          onClick={() => {
            navigate(Urls.Loans)
          }}
          isSuccess={createLoan.isSuccess}
        />
      )}
      <BackTitleWithLink
        titleNode={
          <FormattedMessage
            id="app.mypages.loans.collectLoans.title"
            defaultMessage="Samla lån och krediter"
          />
        }
      />

      <Styled.LoanBoxContainer>
        <h3>
          <FormattedMessage
            id="app.mypages.loans.collectLoans.subtitle"
            defaultMessage="Vi behöver lösenuppgifter på de lån som du vill samla."
          />
        </h3>
        <form onSubmit={formik.handleSubmit}>
          {values?.existing_loans?.map((_, index) => (
            <ExistingLoan key={index} formik={formik} index={index} />
          ))}
          <Styled.AddLoanButtonWrap>
            <Button
              variant="inverse"
              onClick={handleAddLoan}
              disabled={values.existing_loans.length >= 4}
              type="button"
            >
              <FormattedMessage
                id="app.mypages.loans.collectLoans.addMoreLoans"
                defaultMessage="Lägg till fler lån"
              />
            </Button>
            {values.existing_loans.length > 1 && (
              <Button
                onClick={handleDeleteLoan}
                disabled={values.existing_loans.length === 1}
              >
                <FormattedMessage
                  id="app.common.remove"
                  defaultMessage="Ta bort"
                />
              </Button>
            )}
          </Styled.AddLoanButtonWrap>

          <Styled.SliderWrap>
            <label>
              <div>
                <span>
                  <FormattedMessage
                    id="app.common.loanPeriod"
                    defaultMessage="Lånetid"
                  />
                </span>
                <span data-cy="loan-period">
                  {values.repayment_periods / 12}{" "}
                  <FormattedMessage id="app.common.years" defaultMessage="år" />
                </span>
              </div>
              <Range
                min={12}
                max={180}
                step={12}
                name="loanDuration"
                value={values.repayment_periods}
                elRef={loanDurationtEl}
                onChange={() => {
                  handleLoanDurationChange()
                }}
              />
            </label>
            <Styled.MonthlyCostWrap data-cy="monthly-cost">
              <Styled.MonthlyCostTitle>
                <FormattedMessage
                  id="app.common.monthlyCost"
                  defaultMessage="Månadskostnad"
                />
              </Styled.MonthlyCostTitle>
              <Styled.MonthlyCostAmount>
                {monthlyCost} *
              </Styled.MonthlyCostAmount>
              {!!errors.amount && (
                <Styled.ErrorMessage>{errors.amount}</Styled.ErrorMessage>
              )}
            </Styled.MonthlyCostWrap>
          </Styled.SliderWrap>
          <LoanApplicationForm
            formik={formik}
            handleChangeToNumber={handleChangeToNumber}
          />
        </form>
        <small>
          <FormattedMessage
            id="app.mypages.loans.applyForLoan.interestSmallPrint"
            defaultMessage="* Räkneexempel: Ett lån på {amount} till 4,45 % nominell ränta (räntan är rörlig och sätts individuellt baserat på dina förutsättningar, f.n. 3,25 % –24,99 %) kostar {monthlyCost} (Effektiv ränta 5,35 %), dvs totalt {total}. Totalkostnaden och den effektiva räntan inkluderar 500 kr uppläggningsavgift samt 29 kr i administrationskostnad."
            values={{
              amount: values.amount,
              monthlyCost,
              total: totalCost,
            }}
          />
        </small>
      </Styled.LoanBoxContainer>
    </Styled.CollectLoansWrapper>
  )
}

export default CollectLoans
