import styled from "styled-components"

export const TabLinksWrap = styled.div`
  display: flex;
  border-bottom: 1px solid var(--light-gray);
  padding: 10px 0;
  gap: 2em;
  overflow-x: auto;
  width: 100%;
  ::-webkit-scrollbar:horizontal {
    height: 0px;
  }
  a {
    color: ${(props) => props.theme.fontColor};
    position: relative;
    &.custom-active span {
      font-weight: bold;
    }
  }
`
export const SVGWrap = styled.div`
  width: 15%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 15px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1),
    var(--white) 75%
  );
  svg {
    width: 25px;
    path {
      stroke: black;
    }
  }
`
export const Anchor = styled.div`
  width: 1px;
  margin-left: -40px;
`
