import { useRef, useState, useEffect, FC, useCallback, ReactNode } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"

import Button from "UI/Button"
import Select from "UI/Select"
import {
  useUpdateDocumentation,
  useGetLoanApplication,
} from "Hooks/API/useLoansApplications"
import { DocumentationActivityType } from "ApiServices/loanApplications"
import IconFactory from "UI/IconFactory"
import { FileUploadPropsType, PopulateActivitiesType } from "./FileUpload.types"
import * as S from "./FileUpload.styles"

const allowedFileTypes = [
  "application/pdf",
  "image/jpg",
  "image/jpeg",
  "image/jpe",
  "image/png",
]

const FileUpload: FC<FileUploadPropsType> = ({ results }) => {
  const { id } = useParams() as { id: string }
  const intl = useIntl()
  const fileSubmitEl = useRef() as React.MutableRefObject<HTMLInputElement>
  const fileInputField = useRef() as React.MutableRefObject<HTMLInputElement>
  const [fileBtnText, setFileBtnText] = useState<ReactNode>(
    <FormattedMessage
      id="app.onboarding.followApplication.fileUpload.selectButton"
      defaultMessage="Välj en fil"
    />
  )
  const [file, setFile] = useState<File[]>([])
  const [uploadInvalid, setUploadInvalid] = useState<boolean>(false)
  const [documentType, setDocumentType] = useState<DocumentationActivityType>(
    results[0]
  )
  const [options, setOptions] = useState<PopulateActivitiesType[]>([])

  const updateDocumentationMutation = useUpdateDocumentation(id)

  const getLoanApplication = useGetLoanApplication(id)

  useEffect(() => {
    const populateActivities: PopulateActivitiesType[] = []
    results.forEach((element, i) => {
      const obj = {
        value: element,
        name: (
          <FormattedMessage
            id={`app.onboarding.followApplication.fileUpload.options.${element}`}
          />
        ),
        id: i,
      }
      populateActivities.push(obj)
    })

    setOptions(populateActivities)
    setDocumentType(results[0])
  }, [results, intl])

  const handleNewFileUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      if (e.target.files && e.target.files[0]) {
        const newFile = e.target.files[0]
        if (allowedFileTypes.includes(newFile.type)) {
          setUploadInvalid(false)
        } else {
          setUploadInvalid(true)
        }
        const formattedText = e.target.value.split("\\").pop()
        if (formattedText) setFileBtnText(formattedText)
        setFile([newFile])
      }
    },
    []
  )

  const handleSubmit = () => {
    updateDocumentationMutation.mutate({
      documentation: { files: file, activity: documentType },
    })
  }

  const handleUploadBtnClick = useCallback(
    () => fileInputField.current.click(),
    [fileInputField]
  )

  const handleSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) =>
      setDocumentType(e.target.value as DocumentationActivityType),
    [setDocumentType]
  )

  const handleReset = () => {
    updateDocumentationMutation.reset()
    setFile([])
    setFileBtnText(
      <FormattedMessage
        id="app.onboarding.followApplication.fileUpload.selectButton"
        defaultMessage="Välj en fil"
      />
    )
    setDocumentType(results[0])
  }

  if (updateDocumentationMutation.isSuccess) {
    getLoanApplication.refetch()

    if (getLoanApplication.isSuccess) {
      const hasMoreActivites = getLoanApplication.data.activities.filter(
        (requirement) =>
          requirement.name.includes("require_") && !requirement.completed
      )

      return hasMoreActivites.length !== 0 ? (
        <>
          <h1>
            <FormattedMessage
              id="app.onboarding.followApplication.fileUpload.success"
              defaultMessage="Dina uppgifter har blivit uppladdade"
            />
          </h1>
          <Button onClick={handleReset}>
            <FormattedMessage
              id="app.onboarding.followApplication.fileUpload.reset"
              defaultMessage="Ladda upp fler dokument här"
            />
          </Button>
        </>
      ) : (
        <h1>
          <FormattedMessage
            id="app.onboarding.followApplication.fileUpload.noMoreUploads"
            defaultMessage="Bra! Vi har allt som behövs :)"
          />
        </h1>
      )
    }
  }

  return (
    <S.FormWrap>
      <h1>
        <FormattedMessage
          id="app.onboarding.followApplication.fileUpload.heading"
          defaultMessage="Ladda upp lösenuppgifter"
        />
      </h1>
      <S.Form>
        <S.FileUploadWrap invalid={uploadInvalid}>
          <p>
            <FormattedMessage
              id="app.onboarding.followApplication.fileUpload.directions"
              defaultMessage="Dra och släpp dina filer här eller"
            />
          </p>
          <Button onClick={handleUploadBtnClick}>{fileBtnText}</Button>
          <S.Upload
            type="file"
            name="file"
            ref={fileInputField}
            accept="image/png, image/jpeg,image/jpg, image/jpe, application/pdf"
            onChange={handleNewFileUpload}
          />

          <S.SupportedFileTypes invalid={uploadInvalid}>
            <FormattedMessage
              id="app.onboarding.followApplication.fileUpload.fileTypeDirection"
              defaultMessage="Tillåtna filtyper: png, jpg, jpeg, jpe, pdf"
            />
          </S.SupportedFileTypes>
        </S.FileUploadWrap>

        <Select options={options} onChange={handleSelectChange} />
        <S.Submit
          type="submit"
          disabled={
            !!updateDocumentationMutation.isLoading || !documentType || !file
          }
          ref={fileSubmitEl}
          onClick={handleSubmit}
        />
        {updateDocumentationMutation.isSuccess && (
          <>
            <h3>Success!</h3>
            <IconFactory name="loanProtectionTick" />
          </>
        )}

        {updateDocumentationMutation.isError && (
          <>
            <h3>Something went wrong :( Try again</h3>
            <IconFactory name="loanProtectionCross" />
          </>
        )}
      </S.Form>
    </S.FormWrap>
  )
}

export default FileUpload
