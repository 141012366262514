import {
  createContext,
  useState,
  FC,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from "react"
import { useQueryClient } from "react-query"
import * as Sentry from "@sentry/browser"
import { AxiosError } from "axios"

import axios, { setApiAuthorizationHeader } from "ApiServices/axios"
import {
  IAuthData,
  IAuthContext,
  IAuthProvidertProps,
} from "Interfaces/authContextInterfaces"
import { useGetRefreshedToken } from "Hooks/API/useLogin"
import { ITokenData } from "Interfaces/authInterfaces"
import { applyAppTokenRefreshInterceptor } from "ApiServices/axiosRefreshInterceptor"
import { useLogout } from "Hooks/API/useUser"
import { redirectToTarget } from "Utils"
import { useCloseModal, useModalContext } from "Context/modal-context"
import { UNAUTHORIZED } from "Constants/status"
import { EVENTS } from "Events/List"
import { publish } from "Events/index"
import {
  cleanStorageAndAxios,
  getAuthDataFromStorage,
  saveAuthDataInStorage,
} from "./helpers"

export const AuthContext = createContext({} as IAuthContext)

applyAppTokenRefreshInterceptor(axios)

const AuthProvider: FC<IAuthProvidertProps> = ({ children }) => {
  const [authData, setAuthData] = useState<IAuthData>(getAuthDataFromStorage())
  const getRefreshToken = useGetRefreshedToken()
  const logoutMutation = useLogout()
  const queryClient = useQueryClient()
  const closeModal = useCloseModal()
  const { state } = useModalContext()

  const login = useCallback(
    (tokenData: ITokenData) => {
      const data: IAuthData = {
        ...authData,
        accessToken: tokenData.access_token,
        refreshToken: tokenData.refresh_token,
        isLoggedIn: !!tokenData.access_token,
        tokenExpiresInSec: tokenData.expires_in,
        loginTimestampInMs: Date.now(),
      }
      queryClient.setDefaultOptions({
        queries: {
          enabled: true,
        },
      })
      setApiAuthorizationHeader(data.accessToken)
      saveAuthDataInStorage(data)
      setAuthData(data)
      redirectToTarget()
      publish(EVENTS.LOGIN_SUCCESS)
    },
    [authData, queryClient]
  )

  const logout = useCallback(
    async (isTokenExpired = false) => {
      if (!isTokenExpired) {
        await logoutMutation.mutateAsync()
      }

      if (state?.isOpen) {
        closeModal()
      }

      cleanStorageAndAxios()
      queryClient.clear()
      setAuthData({
        ...getAuthDataFromStorage(),
        tokenExpired: isTokenExpired,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryClient, state]
  )

  const refreshTimeoutRef = useRef<ReturnType<typeof setTimeout>>()
  useEffect(() => {
    if (!authData.isLoggedIn) return

    const timeToWaitBeforeRefreshInMs = 600000 // 10 minutes
    const timeToRefresh =
      authData.loginTimestampInMs + timeToWaitBeforeRefreshInMs
    const timeoutBeforeRefresh = timeToRefresh - Date.now()

    refreshTimeoutRef.current = setTimeout(() => {
      if (timeoutBeforeRefresh > 0 && timeoutBeforeRefresh <= Date.now()) {
        getRefreshToken.mutateAsync().then(login)
      }
    }, timeoutBeforeRefresh)

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(refreshTimeoutRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData, login])

  useEffect(() => {
    const errorInterceptor = (err: AxiosError | Error) => {
      if (err instanceof Error) {
        Sentry.captureException(err)
      }
      if (err instanceof AxiosError && err.response?.status === UNAUTHORIZED) {
        logout(true)
      }
      return Promise.reject(err)
    }

    const interceptorResponse = axios.interceptors.response.use(
      undefined,
      errorInterceptor
    )

    return () => {
      axios.interceptors.response.eject(interceptorResponse)
    }
  }, [logout, authData.isLoggedIn])

  const authContextValue = useMemo(
    () => ({
      tokenExpired: authData.tokenExpired,
      accessToken: authData.accessToken,
      isAuthenticated: !!authData.accessToken,
      logout,
      login,
    }),
    [logout, authData.accessToken, login, authData.tokenExpired]
  )

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error("AuthContext must be within AuthProvider")
  }

  return context
}

export default AuthProvider
