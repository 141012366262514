import type { FC } from "react"
import { FormattedMessage } from "react-intl"

import IconFactory from "UI/IconFactory"
import Button from "UI/Button"
import { BROCC_LANDING_LOAN } from "Constants/externalLinks"
import { EmptyServiceWrap } from "../EmptyService.styles"

const EmptyLoans: FC = () => (
  <EmptyServiceWrap type="loans">
    <div>
      <h2>
        <FormattedMessage
          id="app.mypages.loans.noLoans"
          defaultMessage="Lån till bra villkor"
        />
      </h2>
      <p>
        <FormattedMessage
          id="app.mypages.loans.noLoans.paragraph1"
          defaultMessage="Hos Brocc kan du få låna upp till 400.000 kronor."
        />
      </p>
      <p>
        <FormattedMessage
          id="app.mypages.loans.noLoans.paragraph2"
          defaultMessage="Vi erbjuder en individanpassad ränta med bästa möjliga villkor för just dig och din ekonomi."
        />
      </p>
      <ul>
        <li>
          <IconFactory name="pauseNoBorder" />
          <p>
            <FormattedMessage
              id="app.mypages.loans.noLoans.listItem2"
              defaultMessage="Pausa betalningen"
            />
          </p>
        </li>
        <li>
          <IconFactory name="glasses" />
          <p>
            <FormattedMessage
              id="app.mypages.loans.noLoans.listItem1"
              defaultMessage="Tryggt och snabbt - ansök på 2 min."
            />
          </p>
        </li>

        {/* // Temporary hidden
        <li>
          <IconFactory name="discount" />
          <p>
            <FormattedMessage
              id="app.mypages.loans.noLoans.listItem3"
              defaultMessage="Pengar tillbaka på räntan du betalar."
            />
          </p>
        </li> */}
        <li>
          <IconFactory name="security" />
          <p>
            <FormattedMessage
              id="app.mypages.loans.noLoans.listItem4"
              defaultMessage="Gratis låneskydd första månaden."
            />
          </p>
        </li>
      </ul>
      <Button component="a" href={BROCC_LANDING_LOAN}>
        <FormattedMessage
          id="app.mypages.loans.applyForLoan.title"
          defaultMessage="Ansök om lån"
        />
      </Button>
    </div>
    <IconFactory name="manPhoneYellow" />
  </EmptyServiceWrap>
)

export default EmptyLoans
