import { FC } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { useIntl } from "react-intl"

import BackTitleWithLink from "Components/BackTitleWithLink"
import { headings, LayoutTypes } from "./AccountLayout.config"

export type AccountLayoutPropType = {
  type: LayoutTypes
}

const AccountLayout: FC<AccountLayoutPropType> = ({ type }) => {
  const { pathname } = useLocation()
  const intl = useIntl()

  const lastSegment = pathname.split("/").pop()
  const linkTitle =
    lastSegment && headings[type][lastSegment]
      ? intl.formatMessage(headings[type][lastSegment])
      : ""

  return (
    <>
      <BackTitleWithLink titleNode={linkTitle} />
      <Outlet />
    </>
  )
}

export default AccountLayout
