import { Urls } from "Constants/urls"
import EmptyLoans from "Layouts/EmptyService/EmptyLoans"
import PageLayout from "Layouts/PageLayout"
import ServiceLayout from "Layouts/ServiceLayout"
import Cashback from "Pages/Authenticated/Loans/Cashback/Cashback"
import CollectLoans from "Pages/Authenticated/Loans/CollectLoans/CollectLoans"
import Invoices from "Pages/Authenticated/Loans/Invoices/Invoices"
import LoanDetails from "Pages/Authenticated/Loans/Loan/LoanDetails"
import LoanOverview from "Pages/Authenticated/Loans/Loan/LoanOverview"
import LoansOverview from "Pages/Authenticated/Loans/LoansOverview/LoansOverview"
import ProposedLoanContract from "Pages/Authenticated/Loans/LoansOverview/ProposedLoanContract/ProposedLoanContract"

export const loansRoutes = {
  element: <PageLayout type="loans" />,
  path: Urls.Loans,
  children: [
    {
      element: <ServiceLayout type="loans" />,
      children: [
        {
          element: <LoansOverview />,
          index: true,
        },
        {
          path: Urls.LoansInvoices,
          element: <Invoices />,
        },
      ],
    },
    {
      path: Urls.LoansCashback,
      element: <Cashback />,
    },
    {
      path: Urls.LoansProposedContract,
      element: <ProposedLoanContract />,
    },
    {
      path: Urls.LoansCollect,
      element: <CollectLoans />,
    },
    {
      element: <ServiceLayout type="loan" />,
      children: [
        {
          path: `${Urls.LoansAccountID}/${Urls.LoansAccountOverview}`,
          element: <LoanOverview />,
          index: true,
        },
        {
          path: `${Urls.LoansAccountID}/${Urls.LoansAccountDetails}`,
          element: <LoanDetails />,
        },
        {
          path: `${Urls.LoansAccountID}/${Urls.LoansAccountInvoices}`,
          element: <Invoices />,
        },
      ],
    },
  ],
}

export const emptyLoansRoutes = {
  element: <EmptyLoans />,
  path: Urls.Loans,
}
