import { useQuery } from "react-query"

import { getCustomer } from "ApiServices/customer"
import { CUSTOMER } from "Constants/queryKeys"

export const useGetCustomer = () =>
  useQuery({
    queryKey: [CUSTOMER],
    queryFn: getCustomer,
  })
