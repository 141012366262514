import { useMemo } from "react"
import { useIntl } from "react-intl"
import MoneyBagCashbackIcon from "Assets/Icons/money-bag.png"
import { Urls } from "Constants/urls"
import { BROCC_LANDING_LOAN } from "Constants/externalLinks"

export const useFeaturesBoxes = () => {
  const intl = useIntl()

  return useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: "app.mypages.loanSuggestions.newLoan",
          defaultMessage: "Nytt lån",
        }),
        bodyNode: intl.formatMessage(
          {
            id: "app.mypages.loanSuggestions.newLoan.body",
            defaultMessage:
              "Ansök om nytt lån eller samla befintliga lån och spara upp till 500 kr!",
          },
          { amount: 500 }
        ),
        color: "var(--light-yellow-rgb)",
        externalLink: BROCC_LANDING_LOAN,
      },
      {
        title: intl.formatMessage({
          id: "app.mypages.mainNav.loan.link.cashBack",
          defaultMessage: "Pengar tillbaka",
        }),
        bodyNode: (
          <>
            {intl.formatMessage(
              {
                id: "app.mypages.loanSuggestions.cashBack.body",
                defaultMessage:
                  "Pengar tillbaka varje månad. Få tillbaka minst 1 500 kr!",
              },
              { amount: "3 000" }
            )}
            <img src={MoneyBagCashbackIcon} alt="money bag" />
          </>
        ),
        color: "var(--green-rgb)",
        link: `${Urls.Loans}/${Urls.LoansCashback}`,
      },
      {
        title: intl.formatMessage({
          id: "app.mypages.loanSuggestions.freeMonth",
          defaultMessage: "Betalfri månad",
        }),
        bodyNode: intl.formatMessage({
          id: "app.mypages.loanSuggestions.freeMonth.body",
          defaultMessage: "Pausa din betalning upp till 4 gånger per år",
        }),
        color: "var(--purple-rgb)",
      },
    ],
    [intl]
  )
}
