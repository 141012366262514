import { FormattedMessage } from "react-intl"

import { LoanApplicationStatusType } from "Interfaces/loanApplicationInterfaces"
import IconFactory from "UI/IconFactory"
import { formatNumber } from "Utils/formatNumber"
import * as S from "./BankAccountItem.styles"

interface BankAccountItemProps {
  title: string | null
  description: string
  balance?: number
  disabled?: boolean
  isExternalBank?: boolean
  type?: "bank" | "savings"
  name: string | null
  status?: LoanApplicationStatusType
  showArrowIcon?: boolean
  onClick?: () => void
}

export const BankAccountItem = ({
  title,
  description,
  balance,
  disabled,
  type,
  name,
  status,
  showArrowIcon,
  isExternalBank,
  onClick,
}: BankAccountItemProps) => {
  return (
    <S.WithdrawalAccountItem
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      <S.AccountInfo>
        <S.SVGWrap type={type} name={name} status={status}>
          {!isExternalBank && <IconFactory name="cashbackPig" />}
        </S.SVGWrap>
        <S.DetailsInfoWrap>
          <S.AccountName>
            {title || (
              <FormattedMessage id="app.common.account.savingsAccount" />
            )}
          </S.AccountName>
          <S.AccountNumber>{description}</S.AccountNumber>
        </S.DetailsInfoWrap>
      </S.AccountInfo>
      <S.BalanceBlock>
        {balance && <p>{formatNumber(balance, { style: "currency" })}</p>}
        {showArrowIcon && <IconFactory name="chevron" />}
      </S.BalanceBlock>
    </S.WithdrawalAccountItem>
  )
}
