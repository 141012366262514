import { FormattedMessage, useIntl } from "react-intl"

import Loading from "UI/Loading"
import PromoWrap from "Components/PromoWrap"
import { useGetDepositServices } from "Hooks/API/useDepositServices"
import { BROCC_LANDING_SAVINGS } from "Constants/externalLinks"

import { getSavingsInfo } from "Utils/getInfoUtils"
import CreateDepositApplication from "./CreateDepositApplication"
import * as S from "./Accounts.styles"

const Accounts = () => {
  const intl = useIntl()
  const {
    data: savings,
    isLoading,
    isError,
    isFetching,
  } = useGetDepositServices()

  const savingsData =
    savings?.data && savings.data.length > 0
      ? getSavingsInfo(savings.data, intl)
      : []

  return (
    <S.SavingsWrapper>
      {isLoading ||
        (isFetching && (
          <S.LoaderWrapper>
            <Loading />
          </S.LoaderWrapper>
        ))}
      {!isFetching && !isLoading && savingsData.length > 0 && !isError && (
        <>
          <S.HeaderWrap>
            <h1>
              <FormattedMessage
                id="app.mypages.savings.accounts.heading"
                defaultMessage="Välj sparkonto"
              />
            </h1>
            <CreateDepositApplication />
          </S.HeaderWrap>
          <PromoWrap
            type="savings"
            secondTitleId="app.mypages.main.savingsTitle"
            descriptionId="app.mypages.main.savingsDescription"
            accountTitleId="app.common.account.savingsAccount"
            linkText="app.mypages.investments.requiresOnboarding.buttonCTA"
            link={BROCC_LANDING_SAVINGS}
            data={savingsData}
          />
        </>
      )}
      {isError && (
        <h2>
          <FormattedMessage
            id="app.mypages.savings.accounts.error"
            defaultMessage="Det gick inte att ladda sparkonton"
          />
        </h2>
      )}
    </S.SavingsWrapper>
  )
}

export default Accounts
