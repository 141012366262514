import styled from "styled-components"

export const LoansList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1em;
  margin-bottom: 2em;
`

export const RemoveLoanContainer = styled.div`
  margin-left: 1rem;
`
