import styled from "styled-components"
import { motion } from "framer-motion"
import { mediaQueries } from "Styles/styleGlobal"

export const ManualChecKWrap = styled(motion.div)`
  @media screen and (min-width: ${mediaQueries.medium}) {
    display: grid;
    overflow: hidden;
    grid-template-columns: 40% 60%;
    grid-template-rows: repeat(1, 1fr);
    width: 71vw;
  }

  display: flex;
  flex-direction: column;

  margin: 0 auto;
  padding-top: 10em;

  @media screen and (max-width: ${mediaQueries.medium}) {
    padding-top: 2em;
  }
`

export const IllustrationWrap = styled.div`
  display: flex;
  justify-content: start;
  max-width: 200px;

  @media screen and (min-width: ${mediaQueries.large}) {
    max-width: 100%;
  }

  @media screen and (max-width: ${mediaQueries.medium}) {
    max-width: 230px;
    margin: 0 auto;
  }
`
export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding-left: 1em;

  a {
    text-decoration: underline;
    white-space: nowrap;
  }

  @media screen and (max-width: ${mediaQueries.medium}) {
    width: 80vw;
    margin: 0 auto;
    margin-top: 1em;
    padding: 0;
  }
`
