import type { FC } from "react"
import * as S from "./ProgressSteps.styles"

const ProgressSteps: FC<{ stepsNumber: number; activeStep: number }> = ({
  stepsNumber,
  activeStep,
}) => (
  <S.Wrap>
    {[...Array(stepsNumber).keys()].map((step) => (
      <S.Step isStepActive={step < activeStep} key={step} />
    ))}
  </S.Wrap>
)

export default ProgressSteps
