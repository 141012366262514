import styled from "styled-components"

export const UnorderedList = styled.ul`
  margin-left: 2rem;
`

export const ListItem = styled.li`
  margin-bottom: 1rem;
`

export const Paragraph = styled.p`
  font-weight: 400;
  line-height: 1.3rem;
`
