import crypto from "crypto"
import { ILoanApplication } from "Interfaces/loanApplicationInterfaces"

export const hasValidSignatures = (application: ILoanApplication) => {
  if (
    application.co_applicant &&
    (!application.co_applicant_signed || !application.applicant_signed)
  ) {
    return false
  }

  if (!application.co_applicant_signed && !application.applicant_signed) {
    return false
  }

  return true
}

export const base64URLEncode = (str: Buffer) =>
  str
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "")

export const sha256 = (buffer: string) =>
  crypto.createHash("sha256").update(buffer).digest()
