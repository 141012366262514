import { FC } from "react"
import { FormattedMessage } from "react-intl"

import Button from "UI/Button/Button"
import { LoanCollectionFilesProps } from "Interfaces/iceingInterfaces"
import * as S from "./LoanCollectionFileUpload.styles"

const LoanCollectionFileUpload: FC<LoanCollectionFilesProps> = ({
  uploadInvalid,
  filesNumber,
  onUpdateFiles,
}) => {
  return (
    <div>
      <p>
        <FormattedMessage id="app.mypages.iceing.upload.file" />
      </p>
      <S.FileUploadWrap invalid={uploadInvalid}>
        <p>
          <FormattedMessage
            id="app.onboarding.followApplication.fileUpload.directions"
            defaultMessage="Dra och släpp dina filer här eller"
          />
        </p>
        <Button type="button">
          <FormattedMessage id="app.mypages.iceing.uploadFile" />
        </Button>
        <S.Upload
          type="file"
          name="file"
          multiple
          accept="image/png, image/jpeg,image/jpg, image/jpe, application/pdf"
          onChange={onUpdateFiles}
        />
        {filesNumber > 0 && (
          <S.SelectedFiles>
            <FormattedMessage
              id="app.mypages.iceing.files.selected"
              values={{ files: filesNumber }}
            />
          </S.SelectedFiles>
        )}
        <S.SupportedFileTypes invalid={uploadInvalid}>
          <FormattedMessage
            id="app.onboarding.followApplication.fileUpload.fileTypeDirection"
            defaultMessage="Tillåtna filtyper: png, jpg, jpeg, jpe, pdf"
          />
        </S.SupportedFileTypes>
      </S.FileUploadWrap>
    </div>
  )
}

export default LoanCollectionFileUpload
