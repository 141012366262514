import styled from "styled-components"

export const MethodsBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px 0;
  text-align: center;

  p {
    margin: 0;
  }
`

export const LinkStyledButton = styled.button`
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-decoration: underline;
  color: var(--black);
`
