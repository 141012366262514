import { FC } from "react"
import { FormattedMessage } from "react-intl"

import AccountName from "Components/AccountName"
import { ICustomer } from "Interfaces/customerInterfaces"
import Button from "UI/Button"
import * as S from "./styles"

type LinkedBankAccountProps = {
  bankAccount: Pick<
    ICustomer,
    "bank_name" | "bank_account_number" | "bank_clearing_number"
  >
  handleChangeBankAccount: () => void
}

const LinkedBankAccount: FC<LinkedBankAccountProps> = ({
  bankAccount,
  handleChangeBankAccount,
}) => {
  const hasBankAccount =
    bankAccount.bank_name &&
    bankAccount.bank_clearing_number &&
    bankAccount.bank_account_number

  return (
    <S.SavingsItemWrap data-testid="linked-bank">
      <S.SavingsItemTitle>
        <FormattedMessage
          id="app.mypages.savings.settings.linkedBankAccount"
          defaultMessage="Kopplat bankkonto"
        />
      </S.SavingsItemTitle>
      {hasBankAccount ? (
        <>
          <S.SavingsItemDescription>
            <FormattedMessage
              id="app.mypages.savings.settings.linkedBankAccount.description"
              defaultMessage="Koppla snabbt och enkelt ditt bankkonto för säkra uttag från ditt sparkonto"
            />
          </S.SavingsItemDescription>
          <AccountName
            type="bank"
            accountNumber={`${bankAccount.bank_clearing_number} - ${bankAccount.bank_account_number}`}
            accountTitleId="app.mypages.account.bankAccountNo"
            name={bankAccount.bank_name || undefined}
            scaled
          />
        </>
      ) : (
        <S.SavingsItemDescription>
          <FormattedMessage
            id="app.mypages.savings.settings.noBankDetails"
            defaultMessage="Du har inte kopplat något bankkonto för uttag."
          />
        </S.SavingsItemDescription>
      )}
      <S.ButtonWrap>
        <Button onClick={handleChangeBankAccount}>
          <FormattedMessage
            id={
              hasBankAccount
                ? "app.mypages.savings.settings.changeBankAccount"
                : "app.mypages.savings.settings.bankAccountButton"
            }
            defaultMessage={
              hasBankAccount ? "Ändra bankkonto" : "Ange bankkonto"
            }
          />
        </Button>
      </S.ButtonWrap>
      <S.SavingsItemInformation>
        <FormattedMessage
          id="app.mypages.savings.settings.linkedBankAccount.information"
          defaultMessage="Vi tar hjälp av vår partner Tink för att du effektivt ska hämta korrekta uppgifter."
        />
      </S.SavingsItemInformation>
    </S.SavingsItemWrap>
  )
}

export default LinkedBankAccount
