import { FC } from "react"
import { languageOptions } from "Constants/languages"
import { useAuth } from "Context/AuthContext/auth-context"
import LanguageItem from "./LanguageItem"
import { LanguagesPropTypes } from "./Languages.types"
import { LanguagesWrap } from "./Languages.styles"

const Languages: FC<LanguagesPropTypes> = ({ isSidebar, showLanguages }) => {
  const { isAuthenticated } = useAuth()

  return (
    <LanguagesWrap isSidebar={isSidebar}>
      {isAuthenticated || showLanguages ? (
        languageOptions.map((item) => (
          <LanguageItem isSidebar={isSidebar} key={item.value} {...item} />
        ))
      ) : (
        <LanguageItem isSidebar={isSidebar} value="sv" language="Svenska" />
      )}
    </LanguagesWrap>
  )
}

export default Languages
