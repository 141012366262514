import { useState } from "react"
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

interface UseDownloadFileOptions {
  fileName: string
  fileId: string
  fetchFile: (fileId: string) => Promise<Blob>
  onError?: UseQueryOptions["onError"]
  enabled?: UseQueryOptions["enabled"]
  saveFileOnLoad?: boolean
}

type UseFileDownloadReturnType = {
  downloadFile: () => void
  saveFile: (fileBlob: Blob) => void
  fileDownloadQuery: UseQueryResult<Blob>
}

export const useFileDownload = ({
  fileName,
  fileId,
  fetchFile,
  onError,
  saveFileOnLoad = true,
  enabled = false,
}: UseDownloadFileOptions): UseFileDownloadReturnType => {
  const [downloading, setDownloading] = useState(enabled)

  const fileDownloadQuery = useQuery<Blob>({
    queryKey: ["file", fileId],
    queryFn: () => fetchFile(fileId),
    retry: 1,
    enabled: downloading,
    onError,
    onSettled() {
      setDownloading(false)
    },
    onSuccess(fileBlob) {
      if (saveFileOnLoad) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        saveFile(fileBlob)
      }
    },
  })

  const downloadFile = () => setDownloading(true)

  const saveFile = (fileBlob: Blob) => {
    const url = window.URL.createObjectURL(fileBlob)
    const fileLink = document.createElement("a")
    fileLink.href = url
    fileLink.setAttribute("download", fileName)
    document.body.appendChild(fileLink)
    fileLink.click()
    document.body.removeChild(fileLink)
    window.URL.revokeObjectURL(url)
  }

  return { downloadFile, fileDownloadQuery, saveFile }
}
