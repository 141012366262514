import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0;
  flex-wrap: wrap;
  gap: 20px;
`

export const ButtonGroup = styled.div`
  border: 1px solid var(--light-gray);
  padding: 10px 22px;
  background: var(--white);
  color: var(--black);
  display: flex;

  &:nth-child(1) {
    border-radius: 100px 0 0 100px;
  }

  &:nth-child(2) {
    border-left: none;
    border-right: none;
  }

  &:nth-child(3) {
    border-radius: 0 100px 100px 0;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    width: 60%;
    &:nth-child(1) {
      border-radius: 12px;
    }
    &:nth-child(2) {
      border: none;
    }

    &:nth-child(3) {
      border-radius: 12px;
    }
  }
`
export const DatePickerWrap = styled.div`
  display: flex;
  @media screen and (max-width: ${mediaQueries.medium}) {
    box-sizing: border-box;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    flex-direction: column;
  }
`

export const DatePickerInput = styled.input`
  border: none;
  &:focus {
    outline: none;
  }
`
