import { css } from "styled-components"

export const ellipsisText = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const justifyCenter = css`
  display: flex;
  justify-content: center;
`

const justifyBetween = css`
  display: flex;
  justify-content: space-between;
`

const alignCenter = css`
  display: flex;
  align-items: center;
`

const flexCenter = css`
  ${alignCenter}
  justify-content: center;
`

const alignCenterBetween = css`
  ${alignCenter}
  justify-content: space-between;
`

const flexColumn = css`
  display: flex;
  flex-direction: column;
`

const flexColumnBetween = css`
  ${flexColumn}
  justify-content: space-between;
`

const flexColumnAlignCenter = css`
  ${flexColumn}
  align-items: center;
`

const flexColumnCenter = css`
  ${flexColumnAlignCenter}
  justify-content: center;
`

export const flex = {
  justifyCenter,
  justifyBetween,
  alignCenter,
  alignCenterBetween,
  center: flexCenter,
  column: flexColumn,
  columnAlignCenter: flexColumnAlignCenter,
  columnCenter: flexColumnCenter,
  columnBetween: flexColumnBetween,
}
