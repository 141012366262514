import type { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import IconFactory from "UI/IconFactory"
import { OnboardingStepsEnum } from "../Onboarding.types"
import * as S from "./Layout.styles"

type LeftSectionSettings = {
  svg?: ReactNode
  heading: ReactNode
  grayBox: ReactNode
  overview: ReactNode
  yellowBox: ReactNode
}

export const LayoutType: {
  [key: number]: LeftSectionSettings
} = {
  [OnboardingStepsEnum.Accept]: {
    svg: (
      <S.SVGWrap>
        <IconFactory name="manPhoneYellow" />
      </S.SVGWrap>
    ),
    heading: null,
    grayBox: null,
    overview: null,
    yellowBox: true,
  },
  [OnboardingStepsEnum.BankScreening]: {
    svg: false,
    heading: (
      <h1>
        <FormattedMessage
          id="app.common.yourLoanOffer"
          defaultMessage="Ditt låneerbjudande"
        />
      </h1>
    ),
    grayBox: true,
    overview: true,
    yellowBox: true,
  },
  [OnboardingStepsEnum.BankAccountDetails]: {
    svg: false,
    heading: false,
    grayBox: true,
    overview: true,
    yellowBox: true,
  },
  [OnboardingStepsEnum.Signing]: {
    svg: <IconFactory name="manPhonePurple" />,
    heading: false,
    grayBox: false,
    overview: false,
    yellowBox: true,
  },
  [OnboardingStepsEnum.InsuranceCheck]: {
    svg: false,
    heading: (
      <h1>
        <FormattedMessage id="app.common.amount" defaultMessage="Belopp" />
      </h1>
    ),
    grayBox: true,
    overview: true,
    yellowBox: true,
  },
  [OnboardingStepsEnum.LoanPurpose]: {
    svg: <IconFactory name="manRelaxingGreen" />,
    heading: false,
    grayBox: true,
    overview: true,
    yellowBox: true,
  },
  [OnboardingStepsEnum.LoanCollection]: {
    svg: false,
    heading: false,
    grayBox: true,
    overview: true,
    yellowBox: true,
  },
  [OnboardingStepsEnum.FollowApplication]: {
    svg: <IconFactory name="mobilePhone" />,
    heading: false,
    grayBox: false,
    overview: false,
    yellowBox: false,
  },
}
