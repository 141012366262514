import { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormattedMessage } from "react-intl"

import Button from "UI/Button"
import { InsuranceInfoList } from "Components/InsuranceInfoList/InsuranceInfoList"
import {
  useGetBasicLoanServiceByID,
  usePostCreateLoanInsurance,
} from "Hooks/API/useBasicLoanServices"
import { InsuranceFormSchema } from "Pages/Unauthenticated/Onboarding/Steps/InsuranceCheck/InsuranceForm/data"
import { InsuranceFormFields } from "Components/InsuranceFormFields/InsuranceFormFields"

import * as S from "./InsuranceSignForm.styles"

interface InsuranceSignFormProps {
  loanId: string
  onClose: () => void
  onSuccess?: () => void
}

export const InsuranceSignForm = ({
  loanId,
  onClose,
  onSuccess,
}: InsuranceSignFormProps) => {
  const [insuranceCompleted, setInsuranceCompleted] = useState(false)

  const loanServiceQuery = useGetBasicLoanServiceByID(loanId)
  const createInsuranceMutation = usePostCreateLoanInsurance(loanId)

  const insuranceForm = useForm({
    defaultValues: {
      insuranceChecked: true,
      readPrePurchaseInfo: false,
      fitForWork: false,
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(InsuranceFormSchema),
  })

  const onSubmit = () => {
    createInsuranceMutation.mutateAsync().then(() => {
      setInsuranceCompleted(true)
    })
  }

  const closeSuccessModal = () => {
    if (onSuccess) {
      onSuccess()
    }
    onClose()
  }

  if (insuranceCompleted) {
    return (
      <S.LoanInsuranceSignFormBlock>
        <S.InsuranceCompleteBlock>
          <h2>
            <FormattedMessage id="app.insurancePromotion.modal.success.title" />
          </h2>
          <p>
            <FormattedMessage
              id="app.insurancePromotion.modal.success.info"
              values={{
                link: (
                  <a
                    href="https://brocc.se/lana/trygghetsforsakring"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://brocc.se/lana/trygghetsforsakring
                  </a>
                ),
              }}
            />
          </p>
          <Button type="button" onClick={closeSuccessModal}>
            <FormattedMessage id="app.common.ok" defaultMessage="Ok" />
          </Button>
        </S.InsuranceCompleteBlock>
      </S.LoanInsuranceSignFormBlock>
    )
  }

  return (
    <S.LoanInsuranceSignFormBlock>
      <form onSubmit={insuranceForm.handleSubmit(onSubmit)}>
        <h2>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.nextCTAText.withInsurance"
            defaultMessage="Lägg till försäkring"
          />
        </h2>
        <h3>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.subheading"
            defaultMessage="Extra trygghet med låneskydd"
          />
        </h3>
        <InsuranceInfoList
          periodCost={loanServiceQuery.data?.current_contract.period_cost || 0}
        />
        <InsuranceFormFields form={insuranceForm} />
        <S.AgreementSubtitle>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.agreement.subtitle"
            defaultMessage="Försäkringen tillhandahålls av Brocc AB som anknuten försäkringsförmedlare för Knif Trygghet Forsikring AS."
          />
        </S.AgreementSubtitle>
        <S.ButtonsBlock>
          <Button
            type="submit"
            disabled={
              !insuranceForm.formState.isValid ||
              createInsuranceMutation.isLoading
            }
          >
            <FormattedMessage
              id="app.insurancePromotion.signForm.submitButton.text"
              defaultMessage="Lägg till försäkring"
            />
          </Button>
          <Button type="button" variant="inverse" onClick={onClose}>
            <FormattedMessage
              id="app.common.no-thanks"
              defaultMessage="Nej tack"
            />
          </Button>
        </S.ButtonsBlock>
      </form>
    </S.LoanInsuranceSignFormBlock>
  )
}
