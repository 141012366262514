import { LanguagesEnum } from "Constants/languages"

export type FormatNumberStyle = "decimal" | "currency" | "percent"

export const formatNumber = (
  value: number,
  options?: Intl.NumberFormatOptions & {
    style?: FormatNumberStyle
    roundingMode?: "floor" | "trunc"
  }
): string => {
  const numberFormat = new Intl.NumberFormat(LanguagesEnum.SV, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    currency: "SEK",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    roundingMode: "floor",
    ...options,
  })
  return numberFormat.format(value)
}
