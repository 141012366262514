import styled from "styled-components"

type InvalidPropsType = {
  invalid: boolean
}

export const FileUploadWrap = styled.div<InvalidPropsType>`
  position: relative;
  margin: 25px 0 15px;
  border: 3px dashed
    ${(p) => (p.invalid ? "var(--brand-danger)" : "var(--light-gray)")};
  padding: 1rem 1rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.infoBox};
  justify-content: center;
  transition: all 200ms ease-in-out;
`

export const SelectedFiles = styled.p`
  margin-bottom: 0;
`

export const SupportedFileTypes = styled.p<InvalidPropsType>`
  color: ${(p) => (p.invalid ? "var(--brand-danger)" : "var(--light-gray)")};
  transition: all 200ms ease-in-out;
`

export const Upload = styled.input`
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`
