import { useGetCustomer } from "Hooks/API/useCustomer"
import { FormattedMessage } from "react-intl"
import Loading from "UI/Loading"
import * as Styles from "./PersonalInformation.styles"

const PersonalInformation = () => {
  const { data, isLoading, isError } = useGetCustomer()

  if (isLoading) {
    return (
      <Styles.LoadingContainer data-testid="loader">
        <Loading />
      </Styles.LoadingContainer>
    )
  }

  if (isError) {
    return (
      <div data-testid="error">
        <FormattedMessage
          id="app.mypages.error.customer.getCustomer"
          defaultMessage="Vi kunde inte hämta din kontoinformation."
        />
      </div>
    )
  }

  return (
    <Styles.AccountInfo>
      <Styles.AccountInfoHeader>
        <h2>{data?.full_name}</h2>
        <span>{data?.customer_number}</span>
      </Styles.AccountInfoHeader>
      <Styles.InfoDetailsContainer>
        <h3>
          <FormattedMessage
            id="app.mypages.account.contactHeader"
            defaultMessage="Kontaktuppgifter"
          />
        </h3>
        <Styles.DetailsContainer>
          <ul>
            <li>
              <span id="tel">
                <FormattedMessage
                  id="app.mypages.account.phone"
                  defaultMessage="Telefon"
                />
              </span>

              <span aria-labelledby="tel">{data?.tel ? data.tel : "-"}</span>
            </li>

            <li>
              <span id="email">
                <FormattedMessage
                  id="app.common.email"
                  defaultMessage="E-post"
                />
              </span>

              <span aria-labelledby="email">
                {data?.email ? data.email : "-"}
              </span>
            </li>

            <li>
              <span id="address">
                <FormattedMessage
                  id="app.mypages.account.address"
                  defaultMessage="Adress"
                />
              </span>

              <span aria-labelledby="address">
                {data?.street_address ? data.street_address : "-"},{" "}
                {data?.zip_code} {data?.city}
              </span>
            </li>
          </ul>
        </Styles.DetailsContainer>
      </Styles.InfoDetailsContainer>
      <Styles.InfoDetailsContainer>
        <h3>
          <FormattedMessage
            id="app.mypages.account.bankAccountHeader"
            defaultMessage="Bankuppgifter"
          />
        </h3>
        <Styles.DetailsContainer>
          <ul>
            <li>
              <span id="bank-name">
                <FormattedMessage
                  id="app.mypages.account.bankName"
                  defaultMessage="Bank"
                />
              </span>

              <span aria-labelledby="bank-name">
                {data?.bank_name ? data?.bank_name : "-"}
              </span>
            </li>

            <li>
              <span id="bank-account">
                <FormattedMessage
                  id="app.mypages.account.bankAccountNo"
                  defaultMessage="Bankkonto"
                />
              </span>

              <span aria-labelledby="bank-account">
                {data?.bank_clearing_number}{" "}
                {data?.bank_account_number
                  ? ` - ${data?.bank_account_number}`
                  : "-"}
              </span>
            </li>
          </ul>
        </Styles.DetailsContainer>
      </Styles.InfoDetailsContainer>
    </Styles.AccountInfo>
  )
}

export default PersonalInformation
