import { useInfiniteQuery } from "react-query"

import { getInvoices } from "ApiServices/invoices"
import { INVOICES_DATA } from "Constants/queryKeys"
import { IInvoices } from "Interfaces/invoicesInterfaces"

export function useGetInvoices(pageNumber: number) {
  return useInfiniteQuery<IInvoices>({
    queryKey: [INVOICES_DATA, pageNumber],
    queryFn: ({ pageParam = 1 }) => getInvoices(pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.last_page === lastPage.meta.current_page) {
        return undefined
      }
      return lastPage.meta.current_page + 1 ?? undefined
    },
  })
}
