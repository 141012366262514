import styled, { css } from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"
import { InputWrap } from "UI/Input/Input.styles"
import { flex } from "config/mixins"
import type { CheckboxContainerPropTypes } from "../KYC.types"

export const FormContainer = styled.form`
  > :not(:last-child) {
    margin-bottom: 30px;
  }
`

export const FormBlock = styled.div`
  border-radius: 20px;
  border: 1px solid var(--light-gray);
`

export const FormBlockTitle = styled.div`
  padding: 0 1.5em;
  border-bottom: 1px solid var(--light-gray);
  > :first-child {
    margin: 1em 0;
  }
`

export const FormFieldsBlock = styled.div`
  padding: 1.5em;
  div p {
    line-height: 1.5em;
  }
`
export const CheckboxesWrap = styled.div`
  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
`

export const InputContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
  column-gap: 1em;
  margin-bottom: 1em;
  h4 {
    font-size: ${font.size.base};
    margin: 0;
  }
  select {
    & + span {
      top: 6px;
    }
  }

  ${InputWrap} {
    input {
      height: 60px;
    }
    label[for="purpose_of_saving"] {
      top: -5px;
    }
    &:focus-within label[for="purpose_of_saving"] {
      top: 6px;
    }
    .active {
      top: 6px !important;
      line-height: 1.5em;
    }
  }
  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    grid-template-columns: 1fr;
    ${InputWrap} {
      label[for="purpose_of_saving"] {
        top: 6px;
      }
      &:focus-within label[for="purpose_of_saving"] {
        top: 9px;
      }
    }
  }

  @media screen and (max-width: ${mediaQueries.large}) and (min-width: ${mediaQueries.medium}) {
    ${InputWrap} {
      label[for="business_focus"],
      label[for="purpose_of_saving"] {
        top: -5px;
      }
      &:focus-within label[for="purpose_of_saving"] {
        top: 5px;
      }
      &:focus-within label[for="business_focus"] {
        top: 5px;
      }
    }
  }

  @media screen and (max-width: ${mediaQueries.medium}) {
    ${InputWrap} {
      label[for="purpose_of_saving"] {
        top: 1px;
      }
    }
  }

  @media screen and (max-width: ${mediaQueries.small}) {
    ${InputWrap} {
      label[for="business_focus"],
      label[for="purpose_of_saving"] {
        top: -9px;
      }
      &:focus-within label[for="purpose_of_saving"] {
        top: 5px;
      }
      &:focus-within label[for="business_focus"] {
        top: 5px;
      }
    }
  }
`

export const ButtonWrap = styled.div`
  @media screen and (min-width: ${mediaQueries.extraLarge}) {
    width: 75%;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    button {
      margin: 0 auto;
    }
  }
`

export const CheckboxContainer = styled.div<CheckboxContainerPropTypes>`
  ${(props) =>
    props.error &&
    css`
      border-left: 1px solid red;
      padding-left: 0.5em;
    `}
  @media screen and (min-width: ${mediaQueries.extraLarge}) {
    width: 75%;
  }
  &:not(:last-of-type) {
    margin: 10px 0;

    @media screen and (max-width: ${mediaQueries.extraLarge}) {
      margin-bottom: 0;
    }
  }
  h4 {
    margin: 0;
    line-height: 1.5em;
  }

  margin: 6px 0;
  > * {
    padding: 3px 0;
  }
`
export const CheckboxesFieldsWrap = styled.div`
  margin: 6px 0;
  > * {
    padding: 6px 0;
  }
`

export const CheckboxWrap = styled.div`
  margin: 0.75em 0;
  display: flex;

  &:last-of-type {
    @media screen and (max-width: ${mediaQueries.extraLarge}) {
      margin-bottom: 0;
    }
  }
`

export const CheckboxLabel = styled.label`
  display: flex;
  line-height: 1.5em;
  cursor: pointer;
`

export const ToggleContainer = styled.div`
  margin-top: 1em;

  > *:not(:last-child) {
    margin-bottom: 30px;
  }
`

export const ToggleLabel = styled.div`
  line-height: 1.5em;
  > div {
    margin: 1em 0;
  }
`

export const Subtitle = styled.span`
  font-size: ${font.size.sm};
  color: var(--dark-gray);
  line-height: 1.5em;
`

export const MainHeader = styled.h1`
  margin: 1em 0 0 0;
`

export const ConfirmationWrap = styled.div`
  color: var(--dark-gray);
  line-height: 1.5em;
`

export const ModalButton = styled.button`
  align-self: flex-start;
  text-decoration: underline;
  margin: 1em 0 1.5em 0;
  font-size: ${font.size.sm};
  padding: 0;
  color: ${(props) => props.theme.fontColor};
`

export const Error = styled.span`
  color: var(--brand-danger);
  display: block;
  font-size: ${font.size.xs};
`

export const PepExtraFields = styled.div`
  h4 {
    margin: 0 0 5px;
  }
`

export const PEPContainer = styled.div`
  hr {
    margin: 3em 0;
  }
  label {
    line-height: 1.5em;
  }
`

export const LoadingContainer = styled.div`
  ${flex.center}
  height: 100%;
  flex: 1;
`

export const AddOwnerWrap = styled.div`
  margin: 0;
`
export const CitizenContainer = styled.div`
  width: 100%;
  margin-bottom: 1em;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 3em;
  @media screen and (max-width: ${mediaQueries.large}) {
    grid-template-columns: 1fr;
  }
`

export const CitizenSelectWrap = styled.div`
  margin-bottom: 0.75em;
  button {
    margin: 10px 0 0;
    padding-left: 20px;
  }
`

export const AdditionalInfo = styled.p`
  padding: 20px;
  background-color: var(--very-light-gray);
  border-radius: 16px;
`

export const SelectWrap = styled.div`
  display: flex;
  button {
    margin: 10px 0;
    height: 60px;
  }
`

export const ErrorMessage = styled.span`
  line-height: 1.1;
  display: inline-block;
  font-size: ${font.size.xs};
  color: var(--brand-danger);
  margin: 0;
  transform: translateY(-5px);
`
