import styled from "styled-components"
import { flex } from "config/mixins"

export const GenericErrorWrapper = styled.div`
  ${flex.columnCenter}
  padding: 3rem;
  max-width: 600px;
`

export const ErrorHeader = styled.span`
  margin-bottom: 1rem;
  font-weight: bold;
`

export const ErrorBody = styled.span`
  margin-bottom: 3rem;
  text-align: center;
`
