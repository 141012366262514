import { Api } from "Constants/api"
import { LoanInsuranceEligibilities } from "Interfaces/loanInterfaces"
import api from "./axios"

export const getInsuranceEligibilities = async () => {
  const { data } = await api.get<LoanInsuranceEligibilities>(
    Api.InsuranceEligibilities
  )
  return data
}
