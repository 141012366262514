import { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { FormattedMessage } from "react-intl"

import { useGetReports } from "Hooks/API/useReports"
import ErrorService from "Layouts/ErrorService"
import ReportsTableRow from "Components/ReportsTableRow"
import Loading from "UI/Loading"
import * as S from "./ReportsTable.styles"

const ReportsTable = () => {
  const {
    data: reports,
    isError,
    isLoading,
    fetchNextPage,
    isRefetching,
  } = useGetReports(1)
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage])

  if (isLoading || isRefetching) {
    return (
      <S.LoadingContainer>
        <Loading />
      </S.LoadingContainer>
    )
  }

  if (isError || !reports?.pages.length) {
    return (
      <div>
        <ErrorService type="reports" />
      </div>
    )
  }
  return (
    <S.Table>
      <S.TableHeadWrap>
        <S.TableHead>
          <FormattedMessage
            id="app.mypages.reports.download"
            defaultMessage="Ladda ned"
          />
        </S.TableHead>

        <S.TableHead hide>
          <FormattedMessage
            id="app.mypages.reports.table.created"
            defaultMessage="Skapad"
          />
        </S.TableHead>

        <S.TableHead>
          <FormattedMessage
            id="app.mypages.reports.table.report"
            defaultMessage="Rapport"
          />
        </S.TableHead>

        <S.TableHead hide />

        <S.TableHead chevron>
          <FormattedMessage id="app.common.expand" defaultMessage="Expandera" />
        </S.TableHead>
      </S.TableHeadWrap>

      {reports.pages.map((report, index) => (
        <ReportsTableRow key={`${report.uid}_${index}`} report={report} />
      ))}

      <span ref={ref} />
    </S.Table>
  )
}

export default ReportsTable
