import styled from "styled-components"

interface GenericBank {
  isAnnanKreditgivare?: boolean
}

export const Bank = styled.div<GenericBank>`
  display: flex;
  align-items: center;
  border-radius: 15px;
  border: 1px solid var(--light-gray);
  padding: 0.75em 1em;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  ${(props) => {
    if (props.isAnnanKreditgivare) {
      return `
        background-color: var(--very-light-gray);
      `
    }

    return ``
  }}
  &:hover {
    background-color: var(--very-light-yellow);
  }

  span {
    font-weight: 500;
  }
`
