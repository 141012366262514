import TinkCallback from "Components/TinkCallback"
import { useFeedbackModal } from "Context/modal-context"
import { useSaveSelectedBankAccount } from "Hooks/API/useDepositServices"
import { FC, useCallback } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import * as S from "./VerificationSuccess.styles"

const VerificationSuccess: FC<{
  uid: string
  handleNextStep: () => void
  legalEntityNumber: string
}> = ({ uid, handleNextStep, legalEntityNumber }) => {
  const intl = useIntl()

  const mutation = useSaveSelectedBankAccount()
  const { errorModal } = useFeedbackModal()

  const handleTinkEvent = useCallback(
    (type: string, data?: string) => {
      if (type === "error") {
        errorModal(
          intl.formatMessage({
            id: "app.mypages.savings.onBoarding.verification.tink.error",
          })
        )
        return
      }

      if (type !== "account_verification_report_id") {
        return
      }

      if (data) {
        mutation
          .mutateAsync({ uid, data })
          .then(handleNextStep)
          .catch((error) => {
            errorModal(error.message)
          })
      }
    },
    [errorModal, handleNextStep, intl, mutation, uid]
  )

  return (
    <>
      <S.Message>
        <h4>
          <FormattedMessage
            id="app.mypages.savings.onBoarding.accountVerification.heading"
            defaultMessage="Välj det bankkonto du planerar göra uttag till."
          />
        </h4>
        <p>
          <FormattedMessage
            id="app.mypages.savings.onBoarding.accountVerification.text1"
            defaultMessage="Vi samarbetar med Tink för att smidigt låta dig välja uttagskonto. Om du vill ange clearing- och kontonummer manuellt, tryck på 'ändra verifieringsmetod' nedan."
          />
        </p>
        <p>
          <FormattedMessage
            id="app.mypages.savings.onBoarding.accountVerification.text2"
            defaultMessage="Observera att vi enbart tar del av ditt konto-/clearingnummer på det kontot du väljer - vi tar inte del av ditt saldo, transaktioner eller andra detaljer."
          />
        </p>
      </S.Message>
      <TinkCallback
        handleTinkEvent={handleTinkEvent}
        type="accountVerification"
        legalEntityNumber={legalEntityNumber}
      />
    </>
  )
}

export default VerificationSuccess
