import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const TinkHeading = styled.h4`
  margin-top: 0;
  font-size: ${font.size.lg};
`

export const TinkDescription = styled.p``

export const TinkWrap = styled.div``
