import axios from "ApiServices/axios"
import { Api } from "Constants/api"
import type { AxiosError, AxiosResponse } from "axios"
import type {
  ILoanContract,
  ILoanContracts,
} from "Interfaces/loanContractsInterfaces"
import type {
  CreateLoanContractSignature,
  LoanContractSignatureStatus,
} from "Interfaces/loanInterfaces"

export const getListLoanContracts = async () => {
  try {
    const { data } = await axios.get<ILoanContracts>(Api.LoanContracts)
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getLoanContract = async (id: string) => {
  try {
    const { data } = await axios.get<ILoanContract>(
      Api.LoanContract.replace(":id", id)
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const createLoanContractSignature = async (uid: string) => {
  try {
    const { data } = await axios.post<
      CreateLoanContractSignature,
      AxiosResponse,
      { use_new_version: boolean }
    >(Api.LoanContractSignature.replace(":id", uid), { use_new_version: true })

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getLoanContractSignatureStatus = async (
  uid: string,
  signatureUid: string
) => {
  try {
    const { data } = await axios.get<LoanContractSignatureStatus>(
      Api.LoanContractSignatureStatus.replace(":id", uid).replace(
        ":signatureId",
        signatureUid
      )
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getLoanContractPdf = async (contractId: string) => {
  try {
    const { data } = await axios.get<Blob>(
      Api.LoanContractPdf.replace(":id", contractId),
      { responseType: "blob" }
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}
