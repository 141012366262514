import { PropsWithChildren } from "react"

import IconFactory from "UI/IconFactory"
import * as S from "./TaxBanner.styles"

export const TaxBanner = ({ children }: PropsWithChildren) => {
  return (
    <S.TaxBannerBlock role="banner">
      <IconFactory name="info24" />
      <S.TaxBannerText>{children}</S.TaxBannerText>
    </S.TaxBannerBlock>
  )
}
