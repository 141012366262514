import { IPep, KYCRequest, KYCResponse } from "Interfaces/KYCInterfaces"
import { pickBy } from "lodash"
import { IAMLQuestionsRequest } from "Pages/Unauthenticated/Onboarding/Steps/Accept/AMLQuestions/AMLQuestions.types"

export const filterPepFields = (data: KYCRequest | IAMLQuestionsRequest) =>
  data.pep ? { ...pickBy(data.pep, (v) => v && v.length > 0) } : data.pep

export const withMissingPepFields = (
  data: KYCResponse,
  missingFields: IPep
): KYCRequest => ({
  ...data,
  pep: data.pep ? { ...missingFields, ...data.pep } : data.pep,
})
