import { useCallback } from "react"

import useHandleKeyPress from "./useHandleKeyPress"

const ESC = "Escape"

const useCloseOnEsc = (onEscape: () => void) => {
  const handleOnEsc = useCallback(
    (event: React.KeyboardEvent | KeyboardEvent) => {
      const { key } = event
      if (key === ESC) {
        onEscape()
      }
    },
    [onEscape]
  )
  useHandleKeyPress(handleOnEsc)
}

export default useCloseOnEsc
