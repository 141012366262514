import { FC, useMemo } from "react"

import { useGetInvoices } from "Hooks/API/useInvoices"
import ErrorService from "Layouts/ErrorService"
import Loading from "UI/Loading"
import { InvoiceStatus } from "types/enums"
import Overview from "./Overview"
import TableInvoices from "./TableInvoices"
import * as S from "./Invoices.styles"

const Invoices: FC = () => {
  const invoices = useGetInvoices(1)

  const overviewData = useMemo(() => {
    if (invoices.data && invoices.data.pages[0].data.length <= 0) {
      return null
    }
    if (invoices.data) {
      return {
        total_outstanding_amount:
          invoices.data.pages[0].total_outstanding_amount,
        total_amount: invoices.data.pages[0].data[0].total_amount,
        due_date: invoices.data.pages[0].data[0].due_date,
        invoice_type: invoices.data.pages[0].data[0].invoice_type,
        invoice_number: invoices.data.pages[0].data[0].invoice_number,
      }
    }
    return null
  }, [invoices.data])

  const transactionsList = useMemo(() => {
    if (invoices.data) {
      return invoices.data.pages.flatMap((page) => page.data)
    }
    return []
  }, [invoices.data])

  const isAnyUnpaid = useMemo(
    () =>
      transactionsList.some(
        (invoice) =>
          invoice.status === InvoiceStatus.overdue ||
          invoice.status === InvoiceStatus.outstanding
      ),
    [transactionsList]
  )

  const loaderJsx = (
    <S.LoadingWrap data-testid="loader">
      <Loading />
    </S.LoadingWrap>
  )

  if (invoices.isLoading) {
    return loaderJsx
  }

  return (
    <S.InvoicesWrap>
      {invoices.isLoading && loaderJsx}
      {(!overviewData || invoices.isError) && <ErrorService type="invoices" />}

      {invoices.isSuccess && overviewData && !invoices.isError && (
        <>
          <Overview {...overviewData} isAnyUnpaid={isAnyUnpaid} />
          <TableInvoices />
        </>
      )}
    </S.InvoicesWrap>
  )
}

export default Invoices
