import styled, { css } from "styled-components"
import { flex } from "config/mixins"
import { mediaQueries } from "Styles/styleGlobal"
import {
  determineBankIcon,
  getBackgroundColorByStatus,
  getBackgroundColorByType,
} from "Components/AccountName/helpers"
import { LoanApplicationStatusType } from "Interfaces/loanApplicationInterfaces"

export const WithdrawalAccountItem = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.8em;
  font-size: 16px;
  font-weight: normal;
  border-radius: 6px;
  gap: 0.5em;
  color: ${(p) => p.theme.fontColor};

  :disabled {
    cursor: default;
    background-color: var(--very-light-gray);
  }
`

export const AccountInfo = styled.div`
  ${flex.alignCenter}
  gap: 0 1em;
`

type SvgWrapProps = {
  type?: "savings" | "bank"
  status?: LoanApplicationStatusType
  name?: string | null
}

export const SVGWrap = styled.div<SvgWrapProps>`
  ${flex.center}
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(p) => getBackgroundColorByType(p.type)};
  background: ${(p) => getBackgroundColorByStatus(p.status)};
  ${(p) =>
    p.type === "bank" &&
    css`
      background-image: url(${determineBankIcon(p.name)});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    `}

  svg {
    width: 25px;
    height: 25px;
    rect {
      display: none;
    }
  }
`

export const DetailsInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    margin: 0;
  }
`

export const AccountName = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
`
export const AccountNumber = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: var(--dark-gray);
`

export const BalanceBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    margin: 0;
    font-weight: 500;
  }

  .icon {
    transform: translateY(-2px);
    path {
      stroke: #717171;
    }
  }
`

export const WithdrawalsBankItem = styled.div`
  border-radius: 6px;
  background-color: var(--very-light-gray);
  padding: 0.8em;
  @media screen and (max-width: ${mediaQueries.large}) {
    width: 100%;
  }
`
export const WithdrawalsInfoBox = styled.div`
  p {
    margin: 0;
  }
  button {
    margin-top: 1.5em;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    width: 100%;
  }
`
