import { FormattedMessage } from "react-intl"
import QRCode from "react-qr-code"

import IconFactory from "UI/IconFactory"
import Button from "UI/Button"
import Loading from "UI/Loading"

export type BankIDQRCodeProps = {
  cancelAuth: () => void
  qrCode: string
  isSigning: boolean
}

export const BankIDQRCode = ({
  cancelAuth,
  qrCode,
  isSigning,
}: BankIDQRCodeProps) => {
  return (
    <>
      <IconFactory name="bankId" width={56} height={50} />
      <h1>
        <FormattedMessage id="app.login.button.bankId" />
      </h1>
      {isSigning ? (
        <>
          <Loading isCentered />
          <p>
            <FormattedMessage id="app.login.method.info.signing" />
          </p>
        </>
      ) : (
        <>
          <QRCode value={qrCode} className="qr-code" size={160} />
          <p>
            <FormattedMessage id="app.login.method.info.bankId" />
          </p>
        </>
      )}
      <Button type="button" variant="inverse" onClick={cancelAuth}>
        <FormattedMessage id="app.common.cancel" />
      </Button>
    </>
  )
}
