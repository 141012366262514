import { ChangeEvent, FC } from "react"
import BankIcon from "Pages/LoanCollection/Banks/BankIcons/BankIcon"
import useLoanType from "Hooks/UI/LoanCollection/useLoanType"
import {
  Installment,
  Credit,
  Loan,
} from "Pages/LoanCollection/LoansList/LoanItem/LoanItem.types"

import * as S from "./ScrapedItem.styles"

interface ScrapedItemProps {
  loan: Loan | Installment | Credit
  checkboxId: string
  onHandleLoanItem: (event: ChangeEvent<HTMLInputElement>) => void
  isMainInterface?: boolean
}

const ScrapedItem: FC<ScrapedItemProps> = ({
  loan,
  checkboxId,
  onHandleLoanItem,
  isMainInterface,
}) => {
  const { monthlyCost, interestRate, balance } = useLoanType({
    loan,
  })

  return (
    <S.ScrapedItem isMainInterface={isMainInterface}>
      <input
        id={checkboxId}
        type="checkbox"
        className="visually-hidden"
        onChange={onHandleLoanItem}
        disabled={isMainInterface}
      />
      <S.ScrapedItemContent htmlFor={checkboxId}>
        <S.ScrapedItemLeft>
          <BankIcon bankName={loan.bankName} />
          <S.ScrapedItemInformation>
            <S.ScrapedItemType title={loan.loanType}>
              {loan.bankName}
            </S.ScrapedItemType>
            <S.ScrapedItemPercentage>{interestRate}</S.ScrapedItemPercentage>
          </S.ScrapedItemInformation>
        </S.ScrapedItemLeft>
        <S.ScrapedItemRight>
          <S.ScrapedItemAmount>{balance} kr</S.ScrapedItemAmount>
          {monthlyCost && (
            <S.ScrapedItemCostPerMonth>
              {monthlyCost} kr/mån
            </S.ScrapedItemCostPerMonth>
          )}
        </S.ScrapedItemRight>
      </S.ScrapedItemContent>
    </S.ScrapedItem>
  )
}

export default ScrapedItem
