export type PaymentDetails = {
  interestRate: string
  amount: string
  periodCost: string
  effectiveInterestRate: string
  totalCost: string
  fee: string
  adminFee: string
}

export enum ApplicantType {
  applicant = "applicant",
  coApplicant = "co_applicant",
}

export enum LoanApplicationStepEnum {
  Onboarding = "onboarding",
  Converted = "converted",
  Unknown = "unknown",
}

export type LoanApplicationFormError = {
  response: {
    data: {
      data?: {
        loan_application_step?: LoanApplicationStepEnum
      }
      message?: string
    }
  }
}
