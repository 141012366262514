import type { FC } from "react"
import { useFormContext } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"

import type { KYCPrivateRequestFormValues } from "Interfaces/KYCInterfaces"
import { SelectField } from "Components/form/SelectField"
import { TextField } from "Components/form/TextField"
import { CheckboxGroupField } from "Components/form/CheckboxGroupField"
import {
  sourceOfFundsOptions,
  useCapitalOwnerOptions,
  useDepositFrequencyOptions,
  useDepositSizeOptions,
  useInvestmentOptions,
  useWithdrawalFrequencyOptions,
  purposeOfSavingOptions,
} from "Components/KYC/options"
import * as S from "Components/KYC/Form/Form.styles"

const KYCDepositInvestmentFields: FC = () => {
  const intl = useIntl()

  const form = useFormContext<KYCPrivateRequestFormValues>()

  const investmentOptions = useInvestmentOptions()
  const depositSizeOptions = useDepositSizeOptions()
  const depositFrequencyOptions = useDepositFrequencyOptions()
  const withdrawalFrequencyOptions = useWithdrawalFrequencyOptions()
  const capitalOwnerOptions = useCapitalOwnerOptions()

  const sourceOfFunds = form.watch("source_of_funds")
  const purposeOfSaving = form.watch("purpose_of_saving")
  const capitalOwner = form.watch("capital_owner")

  return (
    <S.FormBlock>
      <S.FormBlockTitle>
        <h3>
          <FormattedMessage
            id="app.mypages.investments.onboarding.KYC.private.formGroup.purposeOfDeposits"
            defaultMessage="Syfte med sparande och/eller investeringar hos Brocc"
          />
        </h3>
      </S.FormBlockTitle>
      <S.FormFieldsBlock>
        <S.InputContainer>
          <label>
            <SelectField
              name="investment"
              control={form.control}
              options={investmentOptions}
              aria-label={intl.formatMessage({
                id: "app.mypages.investments.onboarding.KYC.private.investmentSelect.howMuchInvestment",
                defaultMessage: "Hur mycket kommer du spara/investera?",
              })}
            />
          </label>
          <label>
            <SelectField
              name="deposit_size"
              control={form.control}
              options={depositSizeOptions}
              aria-label={intl.formatMessage({
                id: "app.mypages.investments.onboarding.KYC.private.depositSelect.howMuchDeposit",
                defaultMessage: "Hur stora ar insattningarna normalt sett?",
              })}
            />
          </label>
          <label>
            <SelectField
              name="deposit_frequency"
              control={form.control}
              options={depositFrequencyOptions}
              aria-label={intl.formatMessage({
                id: "app.mypages.investments.onboarding.KYC.private.depositFrequencySelect.howOften",
                defaultMessage:
                  "Hur ofta bedömer du att du kommer att göra insättningar till Brocc?",
              })}
            />
          </label>
          <label>
            <SelectField
              name="withdrawal_frequency"
              control={form.control}
              options={withdrawalFrequencyOptions}
              aria-label={intl.formatMessage({
                id: "app.mypages.investments.onboarding.KYC.private.withdrawlFrequencySelect.howOften",
                defaultMessage: "Hur ofta avser du att ta ut pengar?",
              })}
            />
          </label>
          <label>
            <SelectField
              name="capital_owner"
              control={form.control}
              options={capitalOwnerOptions}
              aria-label={intl.formatMessage({
                id: "app.mypages.investments.onboarding.KYC.private.capitalOwnerSelect.capitalOwner",
                defaultMessage: "Kapital Ägare",
              })}
              rules={{
                onChange: (e) => {
                  form.setValue("capital_owner", e.target.value)
                  if (capitalOwner === "other") {
                    form.setValue("other_capital_owner", undefined)
                  }
                },
              }}
            />
          </label>
          {capitalOwner === "other" && (
            <TextField
              name="other_capital_owner"
              control={form.control}
              placeholder={intl.formatMessage({
                id: "app.mypages.investments.onboarding.KYC.private.input.otherCapitalOwner",
                defaultMessage: "Andra kapital ägare",
              })}
            />
          )}
        </S.InputContainer>

        <S.InputContainer>
          <S.CheckboxesWrap>
            <h4>
              <FormattedMessage
                id="app.mypages.investments.onboarding.KYC.private.sourceOfIncome.title"
                defaultMessage="Varifrån kommer kapitalet som du sparar via Brocc?"
              />
            </h4>
            <S.Subtitle>
              <FormattedMessage
                id="app.mypages.investments.onboarding.KYC.company.capital.subheader"
                defaultMessage="Du kan välja flera av nedanstående alternativ"
              />
            </S.Subtitle>
            <S.Error>{form.formState.errors.source_of_funds?.message}</S.Error>
            <S.CheckboxContainer
              error={!!form.formState.errors.source_of_funds?.message}
            >
              <CheckboxGroupField
                sizeType="large"
                name="source_of_funds"
                control={form.control}
                options={sourceOfFundsOptions}
              />
            </S.CheckboxContainer>
            {sourceOfFunds?.includes("other") && (
              <TextField
                control={form.control}
                name="other_source_of_funds"
                placeholder={intl.formatMessage({
                  id: "app.mypages.investments.onboarding.KYC.private.input.otherSourceFunds",
                  defaultMessage: "Ange varifrån kapitalet kommer",
                })}
              />
            )}
          </S.CheckboxesWrap>
          <S.CheckboxesWrap>
            <h4>
              <FormattedMessage
                id="app.mypages.investments.onboarding.KYC.private.purposeOfSaving.title"
                defaultMessage="Vad är syftet med ditt sparande hos Brocc?"
              />
            </h4>
            <S.Subtitle>
              <FormattedMessage
                id="app.mypages.investments.onboarding.KYC.company.capital.subheader"
                defaultMessage="Du kan välja flera av nedanstående alternativ"
              />
            </S.Subtitle>
            <S.Error>
              {form.formState.errors.purpose_of_saving?.message}
            </S.Error>
            <S.CheckboxContainer
              error={!!form.formState.errors.purpose_of_saving?.message}
            >
              <CheckboxGroupField
                sizeType="large"
                name="purpose_of_saving"
                control={form.control}
                options={purposeOfSavingOptions}
              />
            </S.CheckboxContainer>
            {purposeOfSaving?.includes("other") && (
              <TextField
                control={form.control}
                name="other_purpose_of_saving"
                placeholder={intl.formatMessage({
                  id: "app.mypages.investments.onboarding.KYC.private.input.otherPurposeOfSaving",
                  defaultMessage: "Ange annat syfte med ditt sparande",
                })}
              />
            )}
          </S.CheckboxesWrap>
        </S.InputContainer>
      </S.FormFieldsBlock>
    </S.FormBlock>
  )
}

export default KYCDepositInvestmentFields
