import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const FeaturedBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;
  margin-top: 3em;
  @media screen and (max-width: ${mediaQueries.medium}) {
    grid-template-columns: 1fr;
  }
  img {
    width: 20px;
    margin-left: 3px;
    transform: translateY(3px);
  }
`
