import styled from "styled-components"

export const InvoicesWrap = styled.div`
  h1 {
    margin-bottom: 1em;
  }
  flex: 1;
  display: flex;
  flex-direction: column;
`
export const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`
