import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const SignFlowBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 32px;
  margin: 0 auto;
  width: 100%;
  padding: 24px 0;

  &.selected-method {
    text-align: center;

    .icon-bankId,
    .qr-code {
      align-self: center;
    }
  }

  .error-message {
    margin: 0;
    font-size: ${font.size.xs};
    color: var(--brand-danger);
    text-align: center;
  }

  .button-bankId {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  h1 {
    font-size: ${font.size.xl};
    margin: 0;
  }

  p {
    margin: 0;
    text-align: center;
  }

  @media screen and (min-width: ${mediaQueries.medium}) {
    max-width: 450px;
    width: 100%;

    h1 {
      font-size: ${font.size.xl5};
      margin: 0;
    }
  }
`
