import { FC, useEffect, useRef, RefObject } from "react"
import ReactDOM from "react-dom"
import { motion } from "framer-motion"

import useCloseOnEsc from "Hooks/UI/useCloseOnEsc"
import IconFactory from "UI/IconFactory"

import { ModalPropTypes } from "./Modal.types"
import * as S from "./Modal.styles"

const rootNode =
  document.getElementById("modal") || document.createElement("div")
rootNode.setAttribute("id", "modal")
document.body.appendChild(rootNode)

const Modal: FC<ModalPropTypes> = ({
  title,
  body,
  onClick,
  fullWidth,
  maxWidth,
}) => {
  const ref = useRef() as RefObject<HTMLDivElement>

  useCloseOnEsc(onClick)

  useEffect(() => {
    document.body.style.overflowY = "hidden"
    return () => {
      document.body.style.overflowY = "visible"
    }
  }, [])

  return ReactDOM.createPortal(
    <S.StyledModal
      as={motion.div}
      data-testid="modal"
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeOut", duration: 0.3 }}
    >
      <S.ModalBackdrop onClick={onClick} />
      <S.ModalInner
        as={motion.div}
        ref={ref}
        animate={{ y: 30 }}
        exit={{ y: 0, opacity: 0 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
        $fullWidth={fullWidth}
        role="alert"
        $maxWidth={maxWidth}
      >
        {title && (
          <>
            <div className="head">
              {title && <h3>{title}</h3>}
              <button aria-label="Close Modal" onClick={onClick} type="button">
                <IconFactory name="close" />
              </button>
            </div>
            <hr />
          </>
        )}

        <div className="body">
          <div className="innerBody">{body}</div>
        </div>
      </S.ModalInner>
    </S.StyledModal>,
    rootNode
  )
}

export default Modal
