import {
  FC,
  createContext,
  useContext,
  useReducer,
  useMemo,
  ReactElement,
  useCallback,
  ReactNode,
} from "react"
import { FormattedMessage } from "react-intl"
import { VariantType } from "UI/Button/Button.types"

interface StateContext {
  isOpen: boolean
  isSuccess: boolean
  body: ReactNode
  closeCallback?: VoidFunction
  btnMsg?: ReactNode
  showIcon?: boolean
  btnVariant?: VariantType
}

type ActionType =
  | {
      type: "openSuccessModal"
      payload: {
        body: ReactNode
        btnMsg?: ReactElement
      }
    }
  | { type: "openErrorModal"; payload: ReactNode }
  | { type: "closeModal" }
  | {
      type: "closeCallbackModal"
      payload: {
        callback?: VoidFunction
        body: ReactNode
        isSuccess: boolean
        btnMsg?: ReactNode
        showIcon?: boolean
        btnVariant?: VariantType
      }
    }

interface ModalContextInterface {
  state: StateContext
  dispatch: React.Dispatch<ActionType>
}

type ModalContextProviderPropTypes = {
  children?: React.ReactNode
}

const ModalContext = createContext<ModalContextInterface>(
  {} as ModalContextInterface
)

const initialState = {
  isOpen: false,
  isSuccess: false,
  body: "",
}

const reducer = (state: StateContext, action: ActionType) => {
  switch (action.type) {
    case "openSuccessModal":
      return {
        isOpen: true,
        isSuccess: true,
        body: action.payload.body,
        btnMsg: action.payload.btnMsg,
      }
    case "openErrorModal":
      return {
        isOpen: true,
        isSuccess: false,
        body: action.payload,
      }
    case "closeCallbackModal":
      return {
        isOpen: true,
        isSuccess: action.payload?.isSuccess,
        closeCallback: action.payload.callback,
        body: action.payload.body,
        btnMsg: action.payload.btnMsg,
        showIcon: action.payload.showIcon,
        btnVariant: action.payload.btnVariant,
      }
    case "closeModal":
      return initialState
    default:
      return state
  }
}

const ModalContextProvider: FC<ModalContextProviderPropTypes> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  )

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export const useModalContext = () => useContext(ModalContext)

export const useFeedbackModal = () => {
  const { dispatch } = useModalContext()
  const feedbackModal = {
    successModal: useCallback(
      (body: ReactNode, btnMsg?: ReactElement) => {
        dispatch({
          type: "openSuccessModal",
          payload: {
            body,
            btnMsg,
          },
        })
      },
      [dispatch]
    ),
    errorModal: useCallback(
      (body?: ReactNode) => {
        const payload = body || (
          <FormattedMessage
            id="app.common.error.header"
            defaultMessage="Oj då! Något gick fel :("
          />
        )
        dispatch({ type: "openErrorModal", payload })
      },
      [dispatch]
    ),
    closeWithCallback: useCallback(
      (payload: {
        isSuccess: boolean
        callback?: VoidFunction
        body: ReactNode
        btnMsg: ReactNode
        showIcon?: boolean
        btnVariant?: VariantType
      }) => {
        dispatch({ type: "closeCallbackModal", payload })
      },
      [dispatch]
    ),
  }

  return feedbackModal
}

export const useCloseModal = () => {
  const { dispatch } = useModalContext()
  return function closeModal() {
    dispatch({ type: "closeModal" })
  }
}

export default ModalContextProvider
