import { useMutation, useQuery, useQueryClient } from "react-query"

import { getCashback, postRequestCashback } from "ApiServices/cashback"
import { CASHBACK } from "Constants/queryKeys"

export function useGetCashback() {
  return useQuery({
    queryKey: [CASHBACK],
    queryFn: getCashback,
    retry: 1,
  })
}

export const usePostCashback = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: postRequestCashback,
    onSuccess: () => {
      queryClient.invalidateQueries(CASHBACK)
    },
  })
}
