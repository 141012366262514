import { FC } from "react"
import { useAuth } from "Context/AuthContext/auth-context"
import { Navigate, Outlet } from "react-router-dom"
import { Urls } from "Constants/urls"
import { redirectToTarget } from "Utils"

type PrivateRoutePropTypes = {
  redirectPath: string
}

export const PrivateRoute: FC<PrivateRoutePropTypes> = ({ redirectPath }) => {
  const { accessToken } = useAuth()

  return accessToken ? <Outlet /> : <Navigate to={redirectPath} />
}

export const PublicRoute: FC = () => {
  const { accessToken } = useAuth()

  if (accessToken) {
    redirectToTarget()
  }

  return accessToken ? <Navigate to={Urls.Home} /> : <Outlet />
}
