import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const ErrorWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  font-weight: 600;
  font-size: ${font.size.xl};
`
