import yup from "config/yup"
import { ChangeTermRequest } from "./ChangeTerm.types"

const BaseChangeTermSchema: yup.SchemaOf<Omit<ChangeTermRequest, "reason">> =
  yup.object().shape({
    repaymentPeriods: yup.number().required(),
  })

const ExtendedChangeTermSchema: yup.SchemaOf<ChangeTermRequest> =
  BaseChangeTermSchema.shape({
    reason: yup.string().min(10).max(100).required(),
  })

export const getChangeTermSchema = (isEligilble: boolean) =>
  isEligilble ? BaseChangeTermSchema : ExtendedChangeTermSchema
