import { FC } from "react"
import { useGetDepositServices } from "Hooks/API/useDepositServices"
import Loading from "UI/Loading"
import ReportsTable from "./ReportsTable"
import ReportFilters from "./ReportFilters"
import SavingsAccounts from "./SavingsAccounts"

import { LoadingWrap, ReportsWrap } from "./Reports.styles"

const Reports: FC = () => {
  const { isLoading } = useGetDepositServices()

  if (isLoading) {
    return (
      <LoadingWrap data-testid="loader">
        <Loading />
      </LoadingWrap>
    )
  }

  return (
    <ReportsWrap>
      <SavingsAccounts>
        {(id, setRangeDate) => (
          <ReportFilters id={id} setRangeDate={setRangeDate} type="savings" />
        )}
      </SavingsAccounts>
      <ReportsTable />
    </ReportsWrap>
  )
}

export default Reports
