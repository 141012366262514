import styled, { css } from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const IceingNav = styled.nav`
  height: 91px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 20px;
  border-bottom: 4px solid var(--dark-yellow);
`

export const IceingLayout = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${mediaQueries.medium}) {
    display: grid;
    overflow: hidden;
    grid-template-columns: 30% 70%;
    grid-template-rows: repeat(1, 1fr);
    flex: 1;
  }
`

export const IceingAside = styled.aside`
  min-width: 300px;
  max-width: 300px;
  border-right: 1px solid var(--light-gray);
  @media screen and (max-width: 769px) {
    display: none;
  }

  .icon {
    display: block;
    line-height: 0;
    width: 260px;
    height: 450px;
    margin: 0 auto;
  }
`

export const IceingContent = styled.div`
  flex: 1;
  overflow: hidden auto;
`

export const IceingMain = styled.main`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
`

export const FollowYourAppBlock = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`

export const CollectYourLoansBlock = styled.section`
  display: flex;
  flex-direction: column;
`

export const IceingSectionHeading = styled.h1`
  margin: 0 0 1em;
  font-size: ${font.size.xl3};
`

export const FollowStepsBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 45px;
`

export const FollowStepItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 45px;

  ::before {
    content: "";
    position: absolute;
    border-radius: 50%;

    ${(p) => css`
      width: ${p.isActive ? "28px" : "18px"};
      height: ${p.isActive ? "28px" : "18px"};
      left: ${p.isActive ? "0" : "6px"};
      top: ${p.isActive ? "0" : "6px"};
      background-color: ${p.isActive
        ? "var(--dark-yellow)"
        : "var(--light-gray)"};
    `}
  }

  :not(:last-child)::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 50px;
    left: 14px;
    top: 40px;
    background-color: var(--light-gray);
  }

  && > * {
    color: ${(p) => !p.isActive && "var(--light-gray)"};
  }
`

export const FollowStepTitle = styled.p`
  margin: 0;
  font-weight: 700;
`

export const FollowStepInfo = styled.p`
  margin: 0;
  color: var(--dark-gray);
`

export const HowSpeedUpPayout = styled.p`
  background-color: #8fbbae;
  padding: 18px;
  font-weight: 700;
  border-radius: 30px;
  border-top-left-radius: 0;
  margin: 0;
  text-align: center;
`

export const DataList = styled.ul`
  display: flex;
  justify-content: space-between;
  background-color: var(--light-gray);
  padding: 20px;
  border-radius: 15px;
`

export const DataListItem = styled.li`
  p {
    margin: 0;
  }
  > :not(:last-child) {
    margin: 0 0 1em;
  }
`

export const DataValue = styled.p`
  font-weight: 700;
`

export const DataLabel = styled.p`
  color: var(--dark-gray);
`

export const TextWithLink = styled.p`
  font-size: ${font.size.base};
  font-weight: 500;
  margin: 0;

  a {
    text-decoration: underline;
    color: var(--dark-yellow);
  }
`

export const InfoTextBlock = styled.div`
  margin-bottom: 1em;

  > p {
    margin: 0;

    :last-child {
      margin-top: 1em;
      font-style: italic;
    }
  }
`

export const TotalSelectedLoansBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  h3 {
    margin: 0;
    line-height: 2em;
  }
`

export const TotalSelectedLoans = styled.p`
  font-weight: 700;
`

export const TotalSelectedPerMonth = styled.p`
  color: var(--dark-gray);
`

export const FooterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`

export const SomeText = styled.p`
  color: var(--dark-gray);
  margin: 0;
`

export const SelectedLoansButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  button[data-cy="loansMainInterfaceButton"] {
    margin-right: 1rem;
  }

  @media screen and (max-width: ${mediaQueries.small}) {
    flex-direction: column;

    button[data-cy="loansMainInterfaceButton"] {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
`

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  p[data-cy="monthlyCost-text"] {
    color: black;
    font-weight: bold;
    font-size: ${font.size.lg};
  }

  p {
    margin: 0;
    color: gray;
  }
`

export const GenericErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
`

export const ErrorHeader = styled.span`
  margin-bottom: 1rem;
  font-weight: bold;
`

export const ErrorBody = styled.span`
  margin-bottom: 1.25rem;
`

export const IcingUsageMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--light-gray);
  border-radius: 16px;

  p {
    margin: 0;
    font-weight: 500;
    text-align: center;
    font-size: 20px;
  }

  svg.moneyBag {
    width: 60px;
    height: 60px;
  }

  @media screen and (max-width: ${mediaQueries.small}) {
    margin-top: 1rem;

    p {
      text-align: inherit;
    }
  }
`
