import { FC } from "react"
import { useLocation } from "react-router-dom"
import { FormattedMessage } from "react-intl"

import Button from "UI/Button"
import Information from "Pages/LoanCollection/UI/Information/Information"
import {
  IScrappingLoanBank,
  LoginSelectedBank,
} from "Interfaces/iceingInterfaces"
import { font } from "Styles/styleGlobal"
import IconFactory from "UI/IconFactory/IconFactory"
import { isMobile } from "Utils"
import { BROCC_LANDING_TERMS_AND_INFO } from "Constants/externalLinks"
import { ICING_EVENTS } from "Events/List"
import { publish } from "../../../../Events"
import * as S from "./Actions.styles"

const Actions: FC<
  Omit<LoginSelectedBank, "autostarttoken" | "isLoading" | "isLoggedIn"> &
    Omit<IScrappingLoanBank, "bankId" | "loginStrategies" | "enabled">
> = ({ onLoginSelectedBank, bankName }) => {
  const location = useLocation()

  const handleOnLoginSelectedBank = () => {
    publish(ICING_EVENTS.CLICK_LOGIN_SELECTED_BANK_BUTTON, {
      bankName,
      standalone: location.pathname === "/iceing",
    })
    onLoginSelectedBank(true)
  }

  let buttonUI

  if (isMobile()) {
    buttonUI = (
      <>
        <Button onClick={handleOnLoginSelectedBank}>
          <IconFactory name="bankIdWhite" style={{ marginRight: "1rem" }} />
          <FormattedMessage id="app.login.button.qr" />
        </Button>
        <Button
          type="submit"
          variant="inverse"
          onClick={handleOnLoginSelectedBank}
          style={{ border: "none" }}
        >
          <FormattedMessage id="app.login.button.mobileOtherDevice" />
        </Button>
      </>
    )
  } else {
    buttonUI = (
      <Button type="submit" onClick={handleOnLoginSelectedBank}>
        <IconFactory name="bankIdWhite" style={{ marginRight: "1rem" }} />
        <FormattedMessage id="app.login.button.mobile" />
      </Button>
    )
  }

  return (
    <S.ButtonContainer>
      <S.InformationContainer>
        <Information
          content={{ p: "app.mypages.iceing.login.message" }}
          values={{ p: { bankName } }}
          style={{ p: { marginBottom: "3rem" } }}
        />
        <Information
          content={{ p: "app.mypages.iceing.terms.and.conditions" }}
          style={{
            p: { color: "gray", marginBottom: "0", fontSize: font.size.sm },
          }}
          additionalInformation={{
            p: {
              children: (
                <a
                  href={BROCC_LANDING_TERMS_AND_INFO}
                  target="_blank"
                  style={{ color: "gray", textDecoration: "underline" }}
                  rel="noreferrer"
                >
                  <FormattedMessage id="app.mypages.iceing.terms.and.conditions.agreement" />
                </a>
              ),
            },
          }}
        />
      </S.InformationContainer>
      {buttonUI}
    </S.ButtonContainer>
  )
}

export default Actions
