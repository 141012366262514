import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"
import { RadioLabel } from "UI/Radio/Radio.styles"

export const SavingsWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  @media screen and (max-width: ${mediaQueries.small}) {
    ${RadioLabel} {
      &:nth-child(2) {
        margin-top: 1em;
      }
    }
  }
`

export const ErrorMessage = styled.p`
  padding: 10px 20px;
  text-align: center;
  border-radius: 15px;
  background: rgba(var(--light-yellow-rgb), 0.5);
`

export const SubmitButtonContainer = styled.div`
  width: fit-content;
  margin: 1rem 0 2rem 0;
  button {
    padding: 15px 20px;
    font-size: ${font.size.base};
  }
`
