import styled from "styled-components"
import { flex } from "config/mixins"
import { font, mediaQueries } from "Styles/styleGlobal"

export const LoaderWrapper = styled.div`
  ${flex.center}
  flex: 1;
  align-self: center;
`

export const SavingsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  flex: 1;
  h2 {
    font-size: ${font.size.xl2};
    @media screen and (max-width: ${mediaQueries.medium}) {
      line-height: 28px;
      font-size: ${font.size.xl};
    }
  }
  h1 {
    order: 5;
  }
`

export const HeaderWrap = styled.div`
  order: 5;
  ${flex.alignCenterBetween}
  h1 {
    order: 0;
  }
  button {
    margin-bottom: 0;
  }
`

export const ModalBody = styled.div`
  padding: calc(32px - 0.5em) 0 calc(32px - 0.5em) calc(39px - 2em);
  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 46px;
    margin: 0 0 32px;
  }

  button {
    width: 100%;
  }

  svg.close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    transition: 0.3s ease-in-out all;
    cursor: pointer;
    @media screen and (max-width: ${mediaQueries.medium}) {
      top: 5px;
      right: 5px;
    }
  }

  ul {
    margin: 1.5em 0;
    li {
      list-style: none;
      align-items: center;
      text-align: left;
      display: grid;
      grid-template-columns: 30px 1fr;
      gap: 16px;
      &:nth-of-type(2) {
        svg {
          width: 30px;
        }
      }
      p {
        font-size: 18px;
        margin: 0;
        line-height: 46px;
      }
      svg {
        width: 25px;
        height: 30px;
      }
    }
  }
`
