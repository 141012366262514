import { languageIcons, languageOptions } from "Constants/languages"
import { useLangContext } from "Context/lang-context"

import * as S from "./Languages.styles"

export const Languages = () => {
  const { locale, selectLanguage } = useLangContext()
  return (
    <S.LanguagesBlock>
      {languageOptions.map((lang) => (
        <S.LangItem key={lang.value}>
          <S.LangRadioButton
            id={lang.value}
            name="language"
            value={lang.value}
            checked={locale === lang.value}
            onChange={() => selectLanguage(lang.value)}
          />
          <S.LangLabel
            htmlFor={lang.value}
            langFlagBg={languageIcons[lang.value]}
          >
            {lang.language}
          </S.LangLabel>
        </S.LangItem>
      ))}
    </S.LanguagesBlock>
  )
}
