import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const SubmitLoansBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;

  @media screen and (max-width: ${mediaQueries.small}) {
    padding: 0.5rem;
  }
`

export const TextContainer = styled.div`
  display: inherit;
  flex-direction: column;
  margin-bottom: 1rem;

  span {
    margin-bottom: 1rem;
  }
`

export const SubmitLoansBodyButtons = styled.div`
  display: inherit;
  justify-content: center;

  > button:first-child {
    margin-right: 1rem;
  }
`
