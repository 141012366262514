import { FC, memo } from "react"
import IconFactory from "UI/IconFactory"
import { InfoBadgePropTypes } from "./InfoBadge.types"
import { Badge, Info } from "./InfoBadge.styles"

const InfoBadge: FC<InfoBadgePropTypes> = ({ title, placement }) => (
  <Badge>
    <IconFactory name="infoBadge" />
    <Info placement={placement}>{title}</Info>
  </Badge>
)

export default memo(InfoBadge)
