import { FC, ReactNode } from "react"
import IconFactory from "UI/IconFactory/IconFactory"
import Button from "UI/Button/Button"
import { FormattedMessage } from "react-intl"

import * as S from "./TrashOverlay.styles"

const TrashOverlay: FC<{ children: ReactNode; onClick: () => void }> = ({
  children,
  onClick,
}) => {
  return (
    <S.Wrap>
      {children}
      <S.Overlay>
        <Button onClick={onClick}>
          <IconFactory name="trash" />
          <span>
            <FormattedMessage id="app.common.remove" defaultMessage="Ta bort" />
          </span>
        </Button>
      </S.Overlay>
    </S.Wrap>
  )
}

export default TrashOverlay
