import styled from "styled-components"
import { HamburgerButtonPropTypes } from "./Hamburger.types"

export const HamburgerButton = styled.button<HamburgerButtonPropTypes>`
  background-color: transparent;
  border: none;
  display: flex;
  outline: transparent;
  padding: 0;
  position: relative;
  width: 45px;
  z-index: 80;
  &.opened {
    svg > .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
    }

    svg > .line2 {
      stroke-width: 0;
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
    }

    svg > .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
    }
  }

  svg {
    width: 100%;
    .line {
      fill: none;
      stroke: var(--black);
      stroke-width: 6;
      transition:
        stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .line1 {
      stroke-dasharray: 60 207;
    }

    .line2 {
      stroke-dasharray: 60 60;
    }

    .line3 {
      stroke-dasharray: 60 207;
    }
  }
`
