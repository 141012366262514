import styled from "styled-components"

export const InsuranceSelectAccountBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 32px;

  h2,
  p {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 0;
  }
`

export const LoanSelectBlock = styled.div`
  width: 100%;
  margin-top: 32px;
`

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;
`

export const Warning = styled.p`
  width: 100%;
  margin: 0;
  padding: 1em;
  border-radius: 15px;
  background-color: var(--very-light-yellow);
  text-align: center;
`
