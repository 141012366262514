import { useQuery, useMutation } from "react-query"

import {
  getBasicLoanServices,
  requestCoApplicant,
  requestLoanTermChange,
  getLoanContracts,
  getBasicLoanServiceByID,
  postCreateLoanInsurance,
} from "ApiServices/basicLoanServices"
import {
  BASIC_LOAN_SERVICES_CONTRACTS,
  BASIC_LOAN_SERVICES_DATA,
  BASIC_LOAN_SERVICES_HAS_RECENT_REQUEST,
  BASIC_LOAN_SERVICES_SERVICE,
} from "Constants/queryKeys"

export function useBasicLoanServices(enabled = true) {
  return useQuery({
    queryKey: [BASIC_LOAN_SERVICES_DATA],
    queryFn: getBasicLoanServices,
    retry: 0,
    enabled,
  })
}

export const useGetBasicLoanServiceByID = (uid: string, enabled = true) =>
  useQuery({
    queryKey: [BASIC_LOAN_SERVICES_SERVICE, uid],
    queryFn: () => getBasicLoanServiceByID(uid),
    retry: 1,
    enabled,
  })

// Hook with only purpose to get has_recent_term_change_request field after sending change loan term request
export const useGetLoanHasRecentTermChangeRequest = (
  uid: string,
  enabled = false
) =>
  useQuery({
    queryKey: [BASIC_LOAN_SERVICES_HAS_RECENT_REQUEST, uid],
    queryFn: () => getBasicLoanServiceByID(uid),
    retry: 1,
    enabled,
    select(data) {
      return data.has_recent_term_change_request
    },
  })

export const useRequestCoApplicant = () => useMutation(requestCoApplicant)

export const useRequestLoanTermChange = () => useMutation(requestLoanTermChange)

export const useGetLoanContracts = (uid: string) =>
  useQuery({
    queryKey: [BASIC_LOAN_SERVICES_CONTRACTS],
    queryFn: () => getLoanContracts(uid),
  })

export const usePostCreateLoanInsurance = (uid: string) => {
  return useMutation({
    mutationFn: () => postCreateLoanInsurance(uid),
  })
}
