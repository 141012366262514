import { useMemo } from "react"
import { useIntl } from "react-intl"

export const useButtonsArray = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        range: "months" as const,
        text: intl.formatMessage({
          id: "app.mypages.report.filter.button.treeMonth",
          defaultMessage: "Senaste 3 mån",
        }),
      },
      {
        range: "one_year" as const,
        text: intl.formatMessage({
          id: "app.mypages.report.filter.button.oneYear",
          defaultMessage: "1 år",
        }),
      },
      {
        range: "current_year" as const,
        text: intl.formatMessage({
          id: "app.mypages.report.filter.button.currentYear",
          defaultMessage: "i år",
        }),
      },
      {
        range: "since_start" as const,
        text: intl.formatMessage({
          id: "app.mypages.report.filter.button.thenStart",
          defaultMessage: "Sedan start",
        }),
      },
      {
        range: "custom" as const,
        text: intl.formatMessage({
          id: "app.mypages.report.filter.button.angePeriod",
          defaultMessage: "Ange period",
        }),
      },
    ],
    [intl]
  )
}

export const oneDay = 86400000
export const yesterday = new Date(Date.now() - oneDay)

export const formatDate = (date: Date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

const date = new Date()
export const firstDayOfMonth = new Date(
  date.getFullYear(),
  date.getMonth(),
  1,
  2
)
export const lastDayOfPreviousMonth = new Date(firstDayOfMonth)
lastDayOfPreviousMonth.setDate(lastDayOfPreviousMonth.getDate() - 1)
export const prevMonths = new Date(lastDayOfPreviousMonth).setMonth(
  lastDayOfPreviousMonth.getMonth() - 2
)
export const firstDayOfPrevMonth = new Date(new Date(prevMonths).setDate(1))

const previousYear = date.getFullYear() - 1
export const startOfPrevYear = new Date(previousYear, 0, 1)
export const lastDayOfPrevYear = new Date(previousYear, 11, 31)
