import axios from "ApiServices/axios"
import { Api } from "Constants/api"
import type { AxiosError } from "axios"
import type { IDocuments } from "Interfaces/documentsInterfaces"

export type InvestmentReportsPayload = {
  investment_service_uids: string[]
  start_date: string
  end_date: string
}

export type DepositReportsPayload = {
  deposit_service_uid: string
  start_date: string
  end_date?: string
}

export const createDepositReports = async (filters: DepositReportsPayload) => {
  try {
    const { data } = await axios.post(Api.ReportsDepositService, filters)

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getReports = async (page: number) => {
  try {
    const { data } = await axios.get<IDocuments>(Api.Reports, {
      params: {
        page,
      },
    })

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const deleteReport = async (publishableUid: string) => {
  try {
    const { data } = await axios.delete(`${Api.Reports}/${publishableUid}`)

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getReportPdf = async (reportId: string) => {
  try {
    const { data } = await axios.get<Blob>(
      Api.ReportPdf.replace(":id", reportId),
      { responseType: "blob" }
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}
