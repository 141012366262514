import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"
import { InputWrap } from "UI/Input/Input.styles"

export const AMLQuestionsWrap = styled.div``

export const AMLQuestionsDescription = styled.p`
  margin-bottom: 2em;
  font-weight: 500;
`

export const AMLQuestionsForm = styled.form``

export const InputContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
  column-gap: 3em;
  margin: 1em 0 2em;

  @media screen and (max-width: 1234px) and (min-width: ${mediaQueries.extraLarge}) {
    ${InputWrap} {
      label[for="tax_domicile"] {
        top: -5px;
      }
      .active {
        top: 9px !important;
      }
    }
  }
  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    grid-template-columns: 1fr;
  }
`

export const ToggleLabel = styled.label`
  line-height: 1.5em;
  > span {
    display: flex;
    margin: 1em 0;
    align-items: center;
    gap: 0.5em;
    line-height: 1.5em;
  }
`
export const ToggleWrap = styled.div`
  margin-top: 3em;
`
export const ButtonWrap = styled.div`
  margin: 2em 0 0 0;
  @media screen and (max-width: ${mediaQueries.medium}) {
    display: flex;
    margin: 2em 0;
    justify-content: center;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    margin: 1em 0;
  }
`
export const RadioWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  line-height: 1.5em;
`

export const RadioLabel = styled.div`
  margin: 3em 0 1em;
  line-height: 1.5em;
`
