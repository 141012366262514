import { FC } from "react"
import { FormattedMessage } from "react-intl"

import { BankgiroTransferPropTypes } from "../SavingsDeposit.types"
import * as S from "./BankgiroTransfer.styles"

const BankgiroTransfer: FC<BankgiroTransferPropTypes> = ({
  marketplaceNumber,
  productNumber,
}) => (
  <S.BankgiroTransferWrap>
    <S.BankgiroTransferHeading>
      <FormattedMessage
        id="app.mypages.savings.deposit.bankgiroTransfer.heading2"
        defaultMessage="Sätt in pengar med en bankgiro överföring"
      />
    </S.BankgiroTransferHeading>
    <S.BankgiroTransferDescription>
      <FormattedMessage
        id="app.mypages.savings.deposit.bankgiroTransfer.description2"
        defaultMessage="Sätt in pengar i 2 enkla steg. Insättningar tar vanligtvis 1 vardag."
      />
    </S.BankgiroTransferDescription>
    <ol>
      <li>
        <FormattedMessage
          id="app.mypages.error.deposit.depositInfo.step1"
          defaultMessage="Logga in på din internetbank"
        />
      </li>
      <li>
        <FormattedMessage
          id="app.mypages.error.deposit.depositInfo.step2"
          defaultMessage="Utför inbetalning från din bank till bankgiro {marketplaceNumber} med OCR-nummer {productNumber}"
          values={{
            marketplaceNumber: <strong>{marketplaceNumber}</strong>,
            productNumber: <strong>{productNumber}</strong>,
          }}
        />
      </li>
    </ol>
  </S.BankgiroTransferWrap>
)

export default BankgiroTransfer
