import { useState, memo } from "react"
import { useParams } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { AnimatePresence } from "framer-motion"

import { useGetReviewStatus } from "Hooks/API/useDepositServices"
import DocumentView from "Components/DocumentView"
import Loading from "UI/Loading"
import { IParams } from "./Agreement.types"
import PendingAgreement from "./PendingAgreement"
import { SignSavingsAgreementModal } from "./SignAgreementModal/SignAgreementModal"
import * as S from "./Agreement.styles"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"

const Agreement = () => {
  const { accountID } = useParams() as IParams
  const [showSignAgreementModal, setShowSignAgreementModal] = useState(false)

  const signatureReviewStatusQuery = useGetReviewStatus(accountID)

  const handleSignAgreement = () => {
    setShowSignAgreementModal(true)
  }

  if (signatureReviewStatusQuery.isLoading) {
    return (
      <S.LoadingWrapper>
        <Loading />
      </S.LoadingWrapper>
    )
  }

  if (signatureReviewStatusQuery.data?.status === "review") {
    return <PendingAgreement />
  }

  if (signatureReviewStatusQuery.data?.status === "rejected") {
    return (
      <p>
        <FormattedMessage
          id="app.mypages.savings.onBoarding.agreement.rejected"
          defaultMessage="Ditt konto har avvisats. Om du har några frågor är du välkommen att kontakta oss."
        />
      </p>
    )
  }

  return (
    <S.AgreementWrapper data-testid="agreement-block">
      <DocumentView id={accountID} onSignDocument={handleSignAgreement} />
      <AnimatePresence>
        {showSignAgreementModal && (
          <SignSavingsAgreementModal
            accountID={accountID}
            onClose={() => setShowSignAgreementModal(false)}
          />
        )}
      </AnimatePresence>
    </S.AgreementWrapper>
  )
}

export default memo(Agreement)
