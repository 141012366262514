import { Dispatch, FC, SetStateAction } from "react"
import { ILoanApplication } from "Interfaces/loanApplicationInterfaces"

export type StepPropsType = {
  data: ILoanApplication
}

export type BankScreeningPropTypes = {
  data: ILoanApplication
  isIndependent?: boolean
  setIsScreeningCompleted?: Dispatch<SetStateAction<boolean>>
}

type CurrentStepProps = {
  data: ILoanApplication
}

type RejectedPropsType = {
  source: string
  hasExpired?: boolean
}
type NotFoundPropTypes = {
  isError?: boolean
}

export enum OnboardingStepsEnum {
  Default,
  Accept,
  BankScreening,
  BankAccountDetails,
  Signing,
  InsuranceCheck,
  LoanPurpose,
  LoanCollection,
  FollowApplication,
  Rejected,
  ManualCheck,
  NotFound,
}

export type StepsType = {
  [key: number]:
    | FC<CurrentStepProps>
    | FC<StepPropsType>
    | FC<RejectedPropsType>
    | FC<NotFoundPropTypes>
    | FC<BankScreeningPropTypes>
}

export interface LaunchDarklyInterface {
  provenirPricing: boolean
  iceing: boolean
}
