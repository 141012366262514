import { FC, useEffect, useRef, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { LoginSelectedBank, LoanBank } from "Interfaces/iceingInterfaces"
import { FormattedMessage } from "react-intl"
import QRCodeStyling from "qr-code-styling"
import Loading from "UI/Loading"
import { isMobile } from "Utils"
import { ICING_EVENTS } from "Events/List"
import { publish } from "../../../../Events"
import * as S from "./LoginProcess.styles"

const LoginProcess: FC<
  Omit<LoanBank & LoginSelectedBank, "onLoginSelectedBank">
> = ({ autostarttoken, isLoading, isLoggedIn, bankName, loginStrategies }) => {
  const qrCodeRef = useRef<HTMLDivElement>(null)
  const location = useLocation()

  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        width: 200,
        height: 200,
        data: autostarttoken,
      }),
    [autostarttoken]
  )

  useEffect(() => {
    if (qrCodeRef.current) {
      qrCode.append(qrCodeRef.current)
    }
  }, [qrCode])

  useEffect(() => {
    qrCode.update({
      data: autostarttoken,
    })
  }, [qrCode, autostarttoken])

  if (autostarttoken && !isLoggedIn && isMobile()) {
    window.location.href = autostarttoken

    return <Loading isCentered />
  }

  let loginProcessUI

  if (loginStrategies?.includes("SSN") && !autostarttoken && !isLoggedIn) {
    loginProcessUI = (
      <>
        <Loading />
        <p style={{ textAlign: "center" }}>
          <FormattedMessage id="app.login.bankid.instruction" />
        </p>
      </>
    )
  } else if (
    loginStrategies?.includes("BANK_ID") &&
    !isLoading &&
    !isLoggedIn
  ) {
    loginProcessUI = (
      <S.QrCodeContainer>
        <p>
          <FormattedMessage id="app.mypages.iceing.loginQrInstructions" />
        </p>
        <div ref={qrCodeRef} />
      </S.QrCodeContainer>
    )
  } else if (isLoading && isLoggedIn) {
    publish(ICING_EVENTS.SUCCESSFUL_LOGIN, {
      bankName,
      standalone: location.pathname === "/iceing",
    })
    loginProcessUI = (
      <>
        <Loading />
        <p>
          <FormattedMessage id="app.mypages.iceing.wait.until.we.get.your.loans" />
        </p>
      </>
    )
  } else {
    loginProcessUI = <Loading />
  }

  return <S.LoginProcessContainer>{loginProcessUI}</S.LoginProcessContainer>
}

export default LoginProcess
