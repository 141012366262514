import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

type UnpaidInvoicesBoxPropsType = {
  isAnyUnpaid: boolean
}

export const Overview = styled.div`
  display: flex;
  margin-bottom: 2em;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3em;

  @media screen and (max-width: ${mediaQueries.medium}) {
    margin-bottom: 1em;
  }
`

export const UnpaidInvoicesBox = styled.div<UnpaidInvoicesBoxPropsType>`
  background: ${(props) =>
    props.isAnyUnpaid ? "var(--light-yellow)" : "var(--light-green)"};
  border-radius: 16px;
  padding: 1em 1em;
  display: flex;
  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    width: 100%;
    margin-bottom: 2em;
  }
`

export const IconWrap = styled.div<UnpaidInvoicesBoxPropsType>`
  background: ${(props) =>
    props.isAnyUnpaid ? "var(--dark-yellow)" : "var(--green)"};
  height: 41px;
  width: 41px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 41px;
  min-width: 41px;

  svg {
    transform: scale(1.6);
  }
`

export const UnpaidInvoiceDetails = styled.div`
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const UnpaidInvoiceStatus = styled.div`
  font-size: ${font.size.sm};
  color: var(--dark-gray);
`

export const UnpaidInvoiceAmount = styled.span`
  font-size: ${font.size.base};
`

export const NextPaymentDetails = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const NextPaymentHeader = styled.div`
  font-size: ${font.size.sm};
  color: var(--dark-gray);
  margin-bottom: 0.5em;
`

export const NextPaymentAmount = styled.div`
  font-size: ${font.size.base};
`

export const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`
