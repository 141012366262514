import styled from "styled-components"
import { flex } from "config/mixins"

export const AgreementWrapper = styled.div`
  ${flex.column}
  max-height: 75vh;
  position: relative;
  height: 100%;
  .react-pdf__Document {
    display: flex;
    margin: 10px 0 0 0;
  }
`
export const LoadingWrapper = styled.div`
  ${flex.columnCenter}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(85vh + 20px);
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.7);
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    filter: blur(5px);
    z-index: 0;
  }
  p {
    font-size: 1.2em;
  }
  svg,
  p {
    z-index: 5;
  }
`
