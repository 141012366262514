import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import { mediaQueries } from "Styles/styleGlobal"
import { flex } from "config/mixins"

export const LeftSection = styled.div`
  @media screen and (max-width: ${mediaQueries.medium}) {
    display: none;
  }

  display: initial;
  border-right: 1px solid var(--light-gray);
  height: 100%;
  padding: 2em;

  & > svg {
    width: 100%;
    &.man-relaxing-green {
      height: 90%;
    }
  }

  > h1 {
    word-wrap: break-word;
    width: 100%;
  }
`

export const SVGWrap = styled.div`
  padding: 1em 0 3em 0;
  svg {
    width: 100%;
  }
`

export const Layout = styled(motion.div)<{ step: number }>`
  ${flex.column}
  @media screen and (min-width: ${mediaQueries.medium}) {
    display: grid;
    overflow: hidden;
    grid-template-rows: repeat(1, 1fr);
    flex: 1;
    ${(props) => {
      if (props.step >= 10) {
        return css`
          grid-template-columns: 1fr;
          & > div {
            align-items: flex-start;
          }
        `
      }
      return css`
        grid-template-columns: 30% 70%;
      `
    }}
  }
`

export const AnimationVariants = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: -50 },
}
