import { getInvestmentServices } from "ApiServices/investmentServices"
import { INVESTMENT_SERVICES_DATA } from "Constants/queryKeys"
import { useQuery } from "react-query"

export function useInvestmentServices() {
  return useQuery({
    queryKey: [INVESTMENT_SERVICES_DATA],
    queryFn: getInvestmentServices,
    retry: 0,
  })
}
