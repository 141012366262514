import { FC, memo } from "react"
import { FormattedMessage } from "react-intl"
import { useFormContext, useFormState } from "react-hook-form"

import { CheckboxGroupField } from "Components/form/CheckboxGroupField"
import { TextField } from "Components/form/TextField"
import { KYCPrivateRequestFormValues } from "Interfaces/KYCInterfaces"
import type { PEPupdatedProps } from "../KYC.types"
import {
  pepCoworkerOptions,
  pepPositionOptions,
  pepRelationOptions,
} from "./pepOptions"
import * as S from "../Form/Form.styles"

const PEP: FC<PEPupdatedProps> = () => {
  const form = useFormContext<KYCPrivateRequestFormValues>()
  const { errors } = useFormState({ control: form.control })

  const pepPosition = form.watch("pep.pep_position")
  const pepRelation = form.watch("pep.pep_relation")

  return (
    <S.PEPContainer>
      <S.AdditionalInfo>
        <FormattedMessage
          id="app.mypages.investments.onboarding.KYC.private.PEP.statement"
          defaultMessage="Brocc är skyldig att inhämta kännedom om dig som kund för att kunna bedöma risken att drabbas av penningtvätt eller terrorismfinansiering. Därför är vi tacksamma för om du har förståelse för att vi måste inhämta nedanstående information om dig. Tack på förhand."
        />
      </S.AdditionalInfo>
      <S.CheckboxContainer error={!!errors.pep?.pep_position}>
        <h4>
          <FormattedMessage
            id="app.mypages.investments.onboarding.KYC.private.PEP.position.title"
            defaultMessage="Befattning"
          />
        </h4>
        <S.Subtitle>
          <FormattedMessage
            id="app.mypages.investments.onboarding.KYC.private.PEP.position.subtitle"
            defaultMessage="Jag är eller har varit:"
          />
        </S.Subtitle>
        <S.Error>{errors.pep?.pep_position?.message}</S.Error>
        <S.CheckboxesFieldsWrap>
          <CheckboxGroupField
            control={form.control}
            name="pep.pep_position"
            options={pepPositionOptions}
            sizeType="large"
          />
        </S.CheckboxesFieldsWrap>
      </S.CheckboxContainer>
      {pepPosition && pepPosition.length > 0 && (
        <S.PepExtraFields>
          <S.InputContainer>
            <TextField
              control={form.control}
              name="pep.pep_position_title"
              placeholder={
                <FormattedMessage
                  id="app.mypages.investments.onboarding.KYC.private.PEP.relationPosition.input.title"
                  defaultMessage="Titel"
                />
              }
            />
            <TextField
              control={form.control}
              name="pep.pep_position_country"
              placeholder={
                <FormattedMessage
                  id="app.mypages.investments.onboarding.KYC.private.PEP.relationPosition.input.country"
                  defaultMessage="Land / internationellt organ"
                />
              }
            />
          </S.InputContainer>
        </S.PepExtraFields>
      )}
      <S.CheckboxContainer error={!!errors.pep?.pep_relation}>
        <h4>
          <FormattedMessage
            id="app.mypages.investments.onboarding.KYC.private.PEP.relation.title"
            defaultMessage="Relation"
          />
        </h4>
        <S.Subtitle>
          <FormattedMessage
            id="app.mypages.investments.onboarding.KYC.private.PEP.relation.subtitle"
            defaultMessage="Jag är nära familjemedlem till en person med ovanstående
        befattningar. Min relation är:"
          />
        </S.Subtitle>
        <S.Error>{errors.pep?.pep_relation?.message}</S.Error>
        <S.CheckboxesFieldsWrap>
          <CheckboxGroupField
            control={form.control}
            name="pep.pep_relation"
            options={pepRelationOptions}
            sizeType="large"
          />
        </S.CheckboxesFieldsWrap>
      </S.CheckboxContainer>
      {pepRelation && pepRelation.length > 0 && (
        <S.PepExtraFields>
          <h4>
            <FormattedMessage
              id="app.mypages.investments.onboarding.KYC.private.PEP.relationPosition.title"
              defaultMessage="Relation till (namn och titel)"
            />
          </h4>
          <S.InputContainer>
            <TextField
              control={form.control}
              name="pep.pep_relation_position_title"
              placeholder={
                <FormattedMessage
                  id="app.mypages.investments.onboarding.KYC.private.PEP.relationPosition.input.title"
                  defaultMessage="Titel"
                />
              }
            />
            <TextField
              control={form.control}
              name="pep.pep_relation_position_country"
              placeholder={
                <FormattedMessage
                  id="app.mypages.investments.onboarding.KYC.private.PEP.relationPosition.input.country"
                  defaultMessage="Land / internationellt organ"
                />
              }
            />
          </S.InputContainer>
        </S.PepExtraFields>
      )}
      <S.CheckboxContainer>
        <h4>
          <FormattedMessage
            id="app.mypages.investments.onboarding.KYC.private.PEP.relationPosition.coworker.title"
            defaultMessage="Medarbetare"
          />
        </h4>
        <S.Subtitle>
          <FormattedMessage
            id="app.mypages.investments.onboarding.KYC.private.PEP.relationPosition.coworker.subtitle"
            defaultMessage="Jag är medarbetare till en person med någon av ovanstående
        befattningar som omfattas i begreppet Person i politiskt utsatt
        ställning. Jag är:"
          />
        </S.Subtitle>
        <S.CheckboxesFieldsWrap>
          <CheckboxGroupField
            control={form.control}
            name="pep.pep_coworker"
            options={pepCoworkerOptions}
            sizeType="large"
          />
        </S.CheckboxesFieldsWrap>
      </S.CheckboxContainer>
    </S.PEPContainer>
  )
}

export default memo(PEP)
