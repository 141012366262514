import styled, { css } from "styled-components"

import { mediaQueries } from "Styles/styleGlobal"
import { flex } from "config/mixins"
import { ModalInnerPropTypes } from "./Modal.types"

export const StyledModal = styled.div`
  ${flex.center}
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 9998;
`

export const ModalBackdrop = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(var(--black-rgb), 0.6);
`

export const ModalInner = styled.div<ModalInnerPropTypes>`
  @media screen and (min-width: 1044px) {
    top: -5vh;
  }

  @media screen and (max-width: 992px) {
    top: 0;
    width: 45%;
    max-width: 45%;
  }

  @media screen and (max-width: 764px) {
    top: 0;
    width: 95%;
    max-width: 95%;
  }

  ${(p) => css`
    background: ${p.theme.background};
    width: ${p.$fullWidth ? "90%" : "50%"};
    max-width: ${p.$maxWidth};
    .head button svg path {
      stroke: ${p.theme.fontColor};
    }
  `}

  border-radius: 20px;
  position: relative;
  z-index: 999;
  max-height: 90%;
  overflow: hidden;
  hr {
    margin: 0;
  }

  .head {
    ${flex.alignCenterBetween}
    padding: 0.5em 2em 0em 2em;

    @media screen and (max-width: ${mediaQueries.medium}) {
      padding: 0.5em 10px 0em 10px;
    }

    button {
      padding: 0;
      font-size: 0px;

      svg {
        height: 40px;
        width: 40px;
      }
    }
  }

  .body {
    padding: 0.5em 1em;
    padding-right: 0;
    @media screen and (max-width: ${mediaQueries.medium}) {
      padding: 0.5em 0;
    }
  }
  .innerBody {
    max-height: 80vh;
    overflow-y: auto;
    padding-left: 1em;
    padding-right: 2em;
    @media screen and (max-width: ${mediaQueries.medium}) {
      padding: 0 10px;
    }
  }
`
