import { motion } from "framer-motion"
import styled from "styled-components"
import { flex } from "config/mixins"

export const AccountWrap = styled.div`
  margin-bottom: 1rem;
`

export const IsCustomerWrap = styled(motion.div)`
  ${flex.center}
  height: 500px;
  text-align: center;
  padding: 0 1em;

  a button {
    margin-top: 2em;
    margin: 0 auto;
  }
`
