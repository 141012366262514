import { FormattedMessage } from "react-intl"
import IconFactory from "UI/IconFactory"
import type { FC } from "react"
import { BROCC_LANDING_LOGIN } from "Constants/externalLinks"
import { EmptyInvestmentsWrap } from "./styles"

const EmptyInvestments: FC = () => (
  <EmptyInvestmentsWrap>
    <IconFactory name="manRelaxingGreen" />

    <h2>
      <FormattedMessage
        id="app.mypages.investments.accounts.noAccounts"
        defaultMessage="Investera i svenska privatlån"
      />
    </h2>

    <p>
      <FormattedMessage
        id="app.mypages.main.investmentsDescription"
        values={{
          link: (
            <a
              href={BROCC_LANDING_LOGIN}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="app.common.here" defaultMessage="här" />
            </a>
          ),
        }}
      />
    </p>
  </EmptyInvestmentsWrap>
)

export default EmptyInvestments
