import styled, { css } from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"
import { ActiveAccountName } from "Components/AccountName/AccountName.styles"

type StatusProps = {
  status: string
}

export const ButtonWrap = styled.div`
  margin: 1em 0;
`

export const Section = styled.div``

export const SavingsDetail = styled.div`
  font-size: ${font.size.xl2};
  margin-bottom: 0.5em;
  @media screen and (max-width: ${mediaQueries.medium}) {
    font-size: ${font.size.xl};
  }
`

export const SavingsItemSectionTitle = styled.div`
  font-size: ${font.size.base};
  color: var(--dark-gray);
`

export const SavingsItemFooter = styled.div`
  display: flex;
`

export const SavingsItemBody = styled.div`
  padding: 2.5em 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 2em 5em;
  @media screen and (max-width: ${mediaQueries.medium}) {
    padding: 1.5em 0;
  }
`

export const SavingsItemWrap = styled.div`
  background: ${(props) => props.theme.infoBox};
  margin: 2em 0;
  @media screen and (max-width: ${mediaQueries.small}) {
    margin: 0.5em 0;
  }
  ${ActiveAccountName} {
    margin: 2.5em 0;
    font-size: ${font.size.lg};
  }
`

export const SavingsItemHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
`

export const SavingsItemTitle = styled.h2`
  font-weight: bold;
  font-size: ${font.size.xl};
  margin: 0;
`

export const Status = styled.div<StatusProps>`
  color: var(--dark-gray);
  background: rgba(var(--light-gray), 0.3);
  display: inline;
  font-weight: bold;
  padding: 0.5em 1em;
  border-radius: 100px;
  font-size: ${font.size.base};

  ${(props) =>
    props.status === "active" &&
    css`
      color: var(--green);
      background: var(--light-green);
    `}

  ${(props) =>
    props.status !== "active" &&
    css`
      color: var(--dark-yellow);
      background: rgba(var(--light-yellow-rgb), 0.3);
    `}
`
export const DetailsHeading = styled.h1``

export const SavingsItemDescription = styled.p`
  font-weight: 500;
`

export const SavingsItemInformation = styled.span`
  color: var(--dark-gray);
  font-size: ${font.size.base};
  @media screen and (max-width: ${mediaQueries.small}) {
    font-size: ${font.size.sm};
  }
`
export const DetailsWrap = styled.div`
  @media screen and (max-width: ${mediaQueries.medium}) {
    margin: 0.75em 0;
  }
`
