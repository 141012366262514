import * as yup from "yup"
import { FormattedMessage } from "react-intl"

const requiredMessage = () => (
  <FormattedMessage id="app.common.obligatory" defaultMessage="Obligatorisk" />
)

yup.setLocale({
  string: {
    min: ({ min }) => (
      <FormattedMessage id="app.common.form.minCharacters" values={{ min }} />
    ),
    max: ({ max }) => (
      <FormattedMessage id="app.common.form.maxCharacters" values={{ max }} />
    ),
  },
  number: {
    min: requiredMessage,
    max: requiredMessage,
    moreThan: requiredMessage,
  },
  array: {
    min: requiredMessage,
  },
  mixed: {
    required: requiredMessage,
    oneOf: requiredMessage,
  },
})

export default yup
