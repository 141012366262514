import axios from "ApiServices/axios"
import type { AxiosError } from "axios"
import { Api } from "Constants/api"
import type {
  KYCRequest,
  KYCResponse,
  StatusKYCResponse,
} from "Interfaces/KYCInterfaces"

export const getKYC = async (): Promise<KYCResponse | AxiosError> => {
  try {
    const { data } = await axios.get<KYCResponse>(Api.Kyc)

    const isEmptyCitizenship = !data.citizenship || data.citizenship.length <= 0
    const newData = isEmptyCitizenship
      ? {
          ...data,
          citizenship: [""],
        }
      : data

    return newData
  } catch (err) {
    const error = err as AxiosError
    if (error.response?.status === 404) {
      return error
    }
    throw error
  }
}

export const createKYC = async (payload: KYCRequest): Promise<KYCResponse> => {
  try {
    const { data } = await axios.post<KYCResponse>(Api.Kyc, payload)
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getStatusKYC = async (): Promise<StatusKYCResponse> => {
  try {
    const { data } = await axios.get<StatusKYCResponse>(Api.KycStatus)
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}
