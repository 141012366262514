import { FC } from "react"
import { AnimatePresence } from "framer-motion"
import { FormattedMessage } from "react-intl"

import Button from "UI/Button"
import IconFactory from "UI/IconFactory"
import Modal from "UI/Modal"
import * as S from "./DepositModalSuccess.styles"

type DepositModalSuccessProps = {
  onClick: () => void
  isSuccessModal: boolean
}

const DepositModalSuccess: FC<DepositModalSuccessProps> = ({
  onClick,
  isSuccessModal,
}) => {
  return (
    <AnimatePresence>
      {isSuccessModal && (
        <Modal
          body={
            <S.DepositModalSuccess>
              <S.CloseButton
                aria-label="Close Modal"
                onClick={onClick}
                type="button"
              >
                <IconFactory name="close" />
              </S.CloseButton>
              <IconFactory name="like" />
              <h3>
                <FormattedMessage
                  id="app.mypages.savings.depositAmount.success.title"
                  defaultMessage="Din insättning är klar!"
                />
              </h3>
              <p>
                <FormattedMessage
                  id="app.mypages.savings.depositAmount.success.text"
                  defaultMessage="Din insättning har utförts och kommer att synas på ditt konto inom 1-2 vardagar."
                />
              </p>

              <Button onClick={onClick}>
                <FormattedMessage
                  id="app.mypages.savings.depositAmount.success.button"
                  defaultMessage="Då vet jag!"
                />
              </Button>
            </S.DepositModalSuccess>
          }
          onClick={onClick}
        />
      )}
    </AnimatePresence>
  )
}

export default DepositModalSuccess
