import { FC, useRef, RefObject } from "react"
import { AnimatePresence } from "framer-motion"

import useCloseOnEsc from "Hooks/UI/useCloseOnEsc"
import useHandleClickOutside from "Hooks/UI/useHandleClickOutside"

import { useOverflowEffect } from "Hooks/UI/useOverflowEffect"
import IconFactory from "../IconFactory"
import { DrawerPropTypes } from "./Drawer.types"
import * as S from "./Drawer.styles"

const Drawer: FC<DrawerPropTypes> = ({
  isOpen,
  handleClose,
  closeBtn,
  children,
  disableBackdropClick,
}) => {
  const ref = useRef() as RefObject<HTMLDivElement>

  useHandleClickOutside(ref, handleClose, disableBackdropClick)
  useCloseOnEsc(handleClose)

  useOverflowEffect(isOpen)

  return (
    <AnimatePresence>
      {isOpen && (
        <S.DrawerWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          exit={{ opacity: 0 }}
          data-testid="drawer"
        >
          <S.Drawer
            ref={ref}
            initial={{ opacity: 0, transform: "translateX(100%)" }}
            animate={{ opacity: 1, transform: "translateX(0%)" }}
            exit={{
              opacity: 0,
              transform: "translateX(100%)",
              transition: {
                duration: 0.3,
              },
            }}
            transition={{ type: "tween" }}
          >
            {closeBtn && (
              <S.CloseBtnWrapper onClick={handleClose} aria-label="close">
                <IconFactory name="close" />
              </S.CloseBtnWrapper>
            )}
            {children}
          </S.Drawer>
        </S.DrawerWrapper>
      )}
    </AnimatePresence>
  )
}

export default Drawer
