import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const GrayBoxWrap = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2em 0;
  margin: 2em 0;
  background: var(--very-light-gray);
  border-radius: 15px;

  width: 100%;
  h1 {
    margin: -15px 0 0;
  }

  span {
    color: var(--dark-gray);
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    margin: 1em 0;
  }
`
