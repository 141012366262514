import { FormattedMessage } from "react-intl"

import CustomFormattedDate from "Components/CustomFormattedDate"
import Button from "UI/Button"
import * as S from "./SignLoanContractComplete.styles"

export const SignLoanContractComplete = ({
  onComplete,
  contractDate,
}: {
  onComplete: () => void
  contractDate: string | null
}) => {
  return (
    <S.SignLoanContractCompleteBlock>
      <p>
        <FormattedMessage
          id="app.mypages.loans.contractsToSign.status.complete"
          values={{
            contractDate: (
              <CustomFormattedDate value={contractDate ?? new Date()} />
            ),
          }}
        />
      </p>
      <Button type="button" onClick={onComplete}>
        <FormattedMessage id="app.common.thankYou" defaultMessage="Tack!" />
      </Button>
    </S.SignLoanContractCompleteBlock>
  )
}
