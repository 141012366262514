import { useState } from "react"
import { AnimatePresence } from "framer-motion"

import FeatureBox from "Components/FeatureBox"
import { FeatureBoxProps } from "Components/FeatureBox/FeatureBox.types"
import PaymentFreeModal from "Components/LoanCTALinks/PaymentFreeModal"
import { useFeaturesBoxes } from "./helper"
import * as S from "./LoansFeatures.styles"

const LoansFeatures = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const features = useFeaturesBoxes()

  const toggleIsModalOpen = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleClick = (props: FeatureBoxProps) => {
    if (props.externalLink) {
      window.open(props.externalLink, "_blank")
    } else if (!props.link) {
      toggleIsModalOpen()
    }
  }

  return (
    <S.FeaturedBoxes>
      <AnimatePresence>
        {isModalOpen && <PaymentFreeModal setModal={setIsModalOpen} />}
      </AnimatePresence>
      {features.map((props: FeatureBoxProps) => (
        <FeatureBox
          key={props.color}
          onClick={() => handleClick(props)}
          {...props}
        />
      ))}
    </S.FeaturedBoxes>
  )
}

export default LoansFeatures
