import KYCStep from "./KYCStep"
import AccountType from "./AccountType"
import AccountVerification from "./AccountVerification"
import Agreement from "./Agreement"
import Intro from "./Intro"
import {
  SavingsOnboardingSteps,
  StepsTitleType,
} from "./SavingsOnboarding.types"

export const STEPS: SavingsOnboardingSteps = {
  0: Intro,
  1: KYCStep,
  2: AccountVerification,
  3: AccountType,
  4: Agreement,
}

export const totalSteps = Object.keys(STEPS).length - 1

export const STEP_TITLES: StepsTitleType = {
  1: {
    id: "app.mypages.savings.onBoardings.start.step1",
    defaultMessage: "Personuppgifter",
  },
  2: {
    id: "app.mypages.savings.onBoardings.start.step2",
    defaultMessage: "Kundkännedom",
  },
  3: {
    id: "app.mypages.savings.onBoardings.start.step3",
    defaultMessage: "Välj kontotyp",
  },
  4: {
    id: "app.mypages.savings.onBoardings.start.step4",
    defaultMessage: "Signera avtal",
  },
}
