import { FormattedMessage } from "react-intl"

import yup from "config/yup"
import { IAMLQuestionsRequest } from "./AMLQuestions.types"

yup.setLocale({
  string: {
    min: () => (
      <FormattedMessage
        id="app.common.obligatory"
        defaultMessage="Obligatorisk"
      />
    ),
  },
})

export const AMLQuestionsSchema: yup.SchemaOf<IAMLQuestionsRequest> = yup
  .object()
  .shape({
    living_in_sweden: yup.boolean().required(),
    birthplace_citizenship: yup
      .string()
      .nullable()
      .when("living_in_sweden", {
        is: (livingInSweden: boolean) => !livingInSweden,
        then: yup.string().required().nullable(),
      }),
    occupancy: yup
      .string()
      .nullable()
      .when("living_in_sweden", {
        is: (livingInSweden: boolean) => !livingInSweden,
        then: yup.string().min(2).required().nullable(),
      }),
    tax_domicile: yup.string().nullable(),
    tax_identification_number: yup
      .string()
      .nullable()
      .when("tax_domicile", {
        is: (taxDomicile: string | undefined) => !!taxDomicile,
        then: yup
          .string()
          .required(() => (
            <FormattedMessage
              id="app.onboarding.accept.AMLQuestions.input.taxIdentificationNumber.errorMessage"
              defaultMessage="Obligatoriskt vid skatterättsligt hemvist utanför Sverige"
            />
          ))
          .nullable(),
      }),
    pep: yup
      .object()
      .nullable()
      .shape(
        {
          pep_position: yup
            .array()
            .of(
              yup
                .string()
                .oneOf([
                  "head_of_state",
                  "member_of_parliament",
                  "judge",
                  "auditor",
                  "diplomat",
                  "public_company_mgmt",
                  "international_pep",
                ])
            )
            .when("pep_relation", {
              is: (relation: string[]) => relation?.length > 0,
              then: yup.array().min(0),
              otherwise: yup
                .array()
                .min(1, () => (
                  <FormattedMessage
                    id="app.mypages.investments.onboarding.KYC.private.PEP.errorMessage"
                    defaultMessage="Befattning eller relation obligatorisk"
                  />
                )),
            }),
          pep_position_title: yup.string().when("pep_position", {
            is: (position: string[]) => position?.length > 0,
            then: yup.string().required(),
          }),
          pep_position_country: yup.string().when("pep_position", {
            is: (position: string[]) => position?.length > 0,
            then: yup.string().required(),
          }),
          pep_relation: yup
            .array()
            .of(
              yup.string().oneOf(["married", "partner", "children", "parents"])
            )
            .when(["pep_position", "pep_coworker"], {
              is: (position: string[], coworker: string[]) =>
                position?.length === 0 || coworker?.length > 0,
              then: yup
                .array()
                .min(1, () => (
                  <FormattedMessage
                    id="app.mypages.investments.onboarding.KYC.private.PEP.errorMessage"
                    defaultMessage="Befattning eller relation obligatorisk"
                  />
                )),
              otherwise: yup.array().min(0),
            }),
          pep_relation_position_title: yup.string().when("pep_relation", {
            is: (relation: string[]) => relation?.length > 0,
            then: yup.string().required(),
          }),
          pep_relation_position_country: yup.string().when("pep_relation", {
            is: (relation: string[]) => relation?.length > 0,
            then: yup.string().required(),
          }),
          pep_coworker: yup
            .array()
            .of(
              yup.string().oneOf(["relationship_to_pep", "beneficiary_of_pep"])
            ),
        },
        [["pep_position", "pep_relation"]]
      ),
  })
