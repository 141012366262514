import { Api } from "Constants/api"
import axios from "ApiServices/axios"
import type { AxiosError } from "axios"
import { IInvestmentServices } from "Interfaces/investmentServicesInterfaces"

export const getInvestmentServices = async () => {
  try {
    const { data } = await axios.get<IInvestmentServices>(
      Api.InvestmentServices
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}
