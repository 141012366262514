import { useEffect } from "react"

const useHandleClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: (e?: MouseEvent | TouchEvent) => void,
  disableBackdropClick?: boolean
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (
        disableBackdropClick ||
        !ref.current ||
        ref.current.contains(event.target as Node)
      ) {
        return
      }
      callback(event)
    }
    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)
    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [ref, callback, disableBackdropClick])
}

export default useHandleClickOutside
