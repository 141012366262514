import { forwardRef } from "react"
import { SelectProps } from "./Select.types"
import * as S from "./Select.styles"

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      options,
      message,
      error,
      onChange,
      name,
      type,
      selected,
      placeholderOption,
      ...rest
    },
    ref
  ) => {
    const value = rest.defaultValue || rest.value || selected || ""
    const showPlacholder = value === "-" || value === "0" ? "" : value
    const isPlaceholder = Boolean(showPlacholder) && Boolean(rest["aria-label"])
    const allOptions = placeholderOption
      ? [placeholderOption, ...options]
      : options

    return (
      <S.SelectWrap>
        <S.StyledSelect
          {...rest}
          ref={ref}
          data-testid="select"
          error={error}
          onChange={onChange}
          name={name}
          id={name}
          type={type}
          value={value}
          $selected={isPlaceholder}
        >
          {allOptions.map((option, index) => (
            <option key={`${index}`} value={option.value}>
              {option.name}
            </option>
          ))}
        </S.StyledSelect>
        {isPlaceholder && (
          <S.Placeholder $type={type}>{rest["aria-label"]}</S.Placeholder>
        )}
        {message && <S.ValidationMessage>{message}</S.ValidationMessage>}
      </S.SelectWrap>
    )
  }
)

Select.displayName = "Select"

export default Select
