import { FC } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormattedMessage } from "react-intl"

import Button from "UI/Button"
import { BROCC_LANDING_SECURITY_INSURANCE } from "Constants/externalLinks"
import { InsuranceFormFields } from "Components/InsuranceFormFields/InsuranceFormFields"
import { InsuranceInfoList } from "Components/InsuranceInfoList/InsuranceInfoList"
import { InsuranceFormSchema } from "./data"
import { InsuranceFormProps } from "./InsuranceForm.types"
import * as S from "./InsuranceForm.styles"
import * as Styles from "../../../Onboarding.styles"

const InsuranceForm: FC<InsuranceFormProps> = ({
  periodCost,
  handleSubmit: onFormSubmit,
  handleSubmitWithoutInsurance,
}) => {
  const insuranceForm = useForm({
    defaultValues: {
      insuranceChecked: true,
      readPrePurchaseInfo: false,
      fitForWork: false,
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(InsuranceFormSchema),
  })

  const isInsuranceFieldChecked = insuranceForm.watch("insuranceChecked")

  return (
    <form onSubmit={insuranceForm.handleSubmit(onFormSubmit)}>
      <S.LoanProtectionWrap>
        <h2>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.heading"
            defaultMessage="Gratis låneskydd i tre månader!"
          />
        </h2>
        <h4>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.subheading"
            defaultMessage="Extra trygghet med låneskydd"
          />
        </h4>
        <InsuranceInfoList periodCost={periodCost} />
        <InsuranceFormFields form={insuranceForm} />
        <S.AgreementSubtitle>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.agreement.subtitle"
            defaultMessage="Försäkringen tillhandahålls av Brocc AB som anknuten
              försäkringsförmedlare för Nordeuropa försäkring AB"
          />
        </S.AgreementSubtitle>
      </S.LoanProtectionWrap>
      <Styles.SmallPrint>
        <FormattedMessage
          id="app.onboarding.insuranceCheck.smallPrint"
          defaultMessage="Fullständig information om Låneskyddet finner du på"
        />
        <a
          href={BROCC_LANDING_SECURITY_INSURANCE}
          target="_blank"
          rel="noreferrer"
        >
          brocc.se/trygghetsforsakring
        </a>
      </Styles.SmallPrint>
      <S.ButtonsBlock>
        <Button
          variant="inverse"
          onClick={handleSubmitWithoutInsurance}
          disabled={isInsuranceFieldChecked}
          type="button"
        >
          <FormattedMessage
            id="app.onboarding.insuranceCheck.nextCTAText.withoutInsurance"
            defaultMessage="Gå vidare utan låneskydd"
          />
        </Button>
        <br />
        <Button disabled={!isInsuranceFieldChecked} type="submit">
          <FormattedMessage
            id="app.onboarding.insuranceCheck.nextCTAText.withInsurance"
            defaultMessage="Lägg till försäkring"
          />
        </Button>
      </S.ButtonsBlock>
    </form>
  )
}

export default InsuranceForm
