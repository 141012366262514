import { useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { useBasicLoanServices } from "Hooks/API/useBasicLoanServices"
import { useGetInsuranceEligibilities } from "Hooks/API/useInurance"
import Button from "UI/Button"
import IconFactory from "UI/IconFactory"
import Select from "UI/Select"
import { OptionPropTypes } from "UI/Select/Select.types"
import { formatNumber } from "Utils/formatNumber"
import { useLangContext } from "Context/lang-context"
import * as S from "./InsuranceSelectAccount.styles"

export const InsuranceSelectAccount = ({
  loanId,
  closeModal,
  selectLoanId,
}: {
  loanId?: string
  closeModal: () => void
  selectLoanId: React.Dispatch<React.SetStateAction<string | undefined>>
}) => {
  const intl = useIntl()
  const { locale } = useLangContext()
  const basicLoanServicesQuery = useBasicLoanServices()
  const insuranceEligibilitiesQuery = useGetInsuranceEligibilities()

  const loanAccountsOptions = useMemo(() => {
    return (basicLoanServicesQuery.data?.data || []).reduce((res, loan) => {
      if (loan.is_eligible_for_insurance) {
        res.push({
          id: loan.uid,
          name: [
            loan.loan_number,
            formatNumber(loan.loan_size, {
              style: "currency",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          ].join(", "),
          value: loan.uid,
        })
      }
      return res
    }, [] as OptionPropTypes[])
  }, [basicLoanServicesQuery.data])

  const [loanAccountId, setLoanAccountId] = useState<string>(
    loanAccountsOptions.length > 0
      ? (loanAccountsOptions[0].value as string)
      : loanId || ""
  )

  return (
    <S.InsuranceSelectAccountBlock>
      <IconFactory name="shield" width={50} height={65} />
      <h2>
        <FormattedMessage
          {...(insuranceEligibilitiesQuery.data?.free_months
            ? {
                id: "app.insurancePromotion.modal.selectAccount.hasFreeMonths.heading",
                values: {
                  freeMonths: insuranceEligibilitiesQuery.data.free_months,
                },
              }
            : {
                id: "app.insurancePromotion.modal.selectAccount.noFreeMonths.heading",
              })}
        />
      </h2>
      <p>
        <FormattedMessage id="app.insurancePromotion.modal.selectAccount.info" />
      </p>
      {locale === "en" && (
        <S.Warning>
          Note that the insurance information is only available in Swedish.
        </S.Warning>
      )}
      {!loanId && (
        <S.LoanSelectBlock>
          <label htmlFor="loan_id">
            <FormattedMessage id="app.insurancePromotion.modal.selectAccount.label" />
          </label>
          <Select
            name="loan_id"
            options={loanAccountsOptions}
            onChange={(e) => setLoanAccountId(e.target.value)}
            selected={loanAccountId}
            aria-label={intl.formatMessage({
              id: "app.common.loan",
            })}
          />
        </S.LoanSelectBlock>
      )}
      <S.ButtonsBlock>
        <Button
          type="button"
          disabled={!loanAccountId}
          onClick={() => selectLoanId(loanAccountId)}
        >
          <FormattedMessage
            id="app.common.go-next"
            defaultMessage="Gå vidare"
          />
        </Button>
        <Button variant="inverse" type="button" onClick={closeModal}>
          <FormattedMessage
            id="app.common.no-thanks"
            defaultMessage="Nej tack"
          />
        </Button>
      </S.ButtonsBlock>
    </S.InsuranceSelectAccountBlock>
  )
}
