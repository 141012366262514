import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

export const AccountInfo = styled.section`
  background-color: ${(props) => props.theme.infoBox};
  border-radius: 20px;
  padding: 0 0 3em 0em;
  margin: 3em 0 0 0;

  h2 {
    margin: 0;
    @media screen and (max-width: ${mediaQueries.medium}) {
      font-size: ${font.size.xl};
    }
  }

  @media screen and (max-width: ${mediaQueries.medium}) {
    padding: 0;
  }
`

export const AccountInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding: 0 0 1em;
  margin: 0 0 2em;
  span {
    color: var(--dark-gray);
  }
`

export const InfoDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  h3 {
    margin: 0;
  }
  ul {
    list-style: none;
    width: 100%;
    margin: 1em 0 3em 0;
    @media screen and (max-width: ${mediaQueries.medium}) {
      margin: 0 0 3em 0;
    }
    li {
      display: flex;
      justify-content: space-between;
      padding: 1em 0;
      border-bottom: 1px solid var(--light-gray);
      &:last-of-type {
        border-bottom: 0;
      }
      span:last-of-type {
        font-weight: 500;
        text-align: right;
      }
    }
  }
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 1em;
  @media screen and (min-width: ${mediaQueries.large}) {
    margin-bottom: 0;
  }
`
