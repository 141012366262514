import styled from "styled-components"

export const SwitchBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`

export const SwitchLabelText = styled.span``

export const SwitchBase = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: 4px;
  top: 0;
  left: 0;
  border: 0;
  border-radius: 50%;
  background-color: transparent;
  user-select: none;
  z-index: 1;
`
export const SwitchTrack = styled.span<{ checked?: boolean }>`
  border-radius: 15px;
  height: 100%;
  width: 100%;
  z-index: -1;
  transition: background-color 0.2s;
  background-color: ${(p) =>
    p.checked ? "var(--light-yellow)" : "var(--light-gray)"};
`

export const SwitchLabel = styled.label`
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  width: 42px;
  height: 26px;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
`

export const SwitchThumb = styled.span`
  background-color: var(--white);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: transform 0.2s;
`

export const SwitchCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  width: 200%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
  cursor: inherit;

  &:checked + ${SwitchThumb} {
    transform: translateX(16px);
  }
`
