import styled from "styled-components"

export const SelectedBankWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;
`

export const BankHeader = styled.header`
  display: inherit;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  gap: 15px;
  svg {
    background-color: var(--light-gray);
  }
`

export const ButtonContainer = styled.div`
  display: inherit;
  flex-direction: column;
  justify-content: center;
  gap: 1.5em;
`

export const FillInformationText = styled.p`
  color: gray;
`
