import { FormattedMessage } from "react-intl"
import type { FC } from "react"

import { formatNumber } from "Utils/formatNumber"
import { GrayBoxWrap } from "./GrayBox.styles"

const GrayBox: FC<{ amount: number }> = ({ amount }) => {
  return (
    <GrayBoxWrap>
      <span>
        <FormattedMessage id="app.common.amount" defaultMessage="Belopp" />
      </span>
      <br />
      <h1>
        {formatNumber(amount, {
          style: "currency",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      </h1>
    </GrayBoxWrap>
  )
}

export default GrayBox
