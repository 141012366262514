import type { ICustomer } from "Interfaces/customerInterfaces"
import axios from "ApiServices/axios"
import { Api } from "Constants/api"
import type { AxiosError } from "axios"

export const getCustomer = async () => {
  try {
    const { data } = await axios.get<ICustomer>(Api.Customer)

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}
