import { defaultValues as collectValues } from "Pages/Authenticated/Loans/CollectLoans/data"
import { defaultValues as applyValues } from "Pages/Authenticated/Loans/ApplyForLoan/data"
import { formatNumber } from "./formatNumber"

type LoanType = {
  value: string
  pv: number
  n: number
  i: number
  discount: number
}

type LoanValuesType = typeof collectValues | typeof applyValues

type ReCalcValues = {
  loanInterestRate: string
  loanServiceFee: number | null
  remainingPrincipal: number
}

export const PMT = (
  rate: number,
  nper: number,
  pv: number,
  fv = 0,
  type = 0
) => {
  if (rate === 0) return -(pv + fv) / nper

  const pvif = (1 + rate) ** nper
  let pmt = (rate / (pvif - 1)) * -(pv * pvif + fv)

  if (type === 1) {
    pmt /= 1 + rate
  }

  return pmt
}

export const determineLowerInterestAmount = (loans: LoanType[]) => {
  const costDifferences: number[] = []
  loans.forEach((loan) => {
    const nper = loan.n
    const { pv } = loan
    const pmtCurrent = PMT(loan.i / 12, nper, -pv, 0)

    const totalCost = pmtCurrent * nper

    const pmtDiscounted = PMT((loan.i - loan.discount) / 12, nper, -pv, 0)
    const discountedCost = pmtDiscounted * nper

    costDifferences.push(Math.round(totalCost - discountedCost))
  })

  return costDifferences.reduce((a, b) => a + b, 0)
}

export const determineMonthlyCost = (values: LoanValuesType) => {
  const PV = values.amount
  const nper = values.repayment_periods
  const i = 4.45 / 100
  const r = i / 12

  const pmtAmount = PMT(r, nper, -PV, 0) || 0

  return formatNumber(pmtAmount, { style: "currency" })
}

export const determineTotalCost = (values: LoanValuesType) => {
  const PV = values.amount
  const nper = values.repayment_periods
  const i = 4.45 / 100
  const r = i / 12

  const pmtAmount = PMT(r, nper, -PV, 0) || 0
  const total = pmtAmount * nper

  return formatNumber(total, { style: "currency" })
}

export const newPMT = (
  ir: number,
  np: number,
  pv: number,
  fv = 0,
  type = 0
) => {
  let pmt
  const pvif = (1 + ir) ** np

  if (ir === 0) {
    return -(pv + fv) / np
  }

  pmt = (-ir * pv * (pvif + fv)) / (pvif - 1)

  if (type === 1) {
    pmt /= 1 + ir
  }

  return pmt
}

export const reCalcPMT = (
  { loanInterestRate, loanServiceFee, remainingPrincipal }: ReCalcValues,
  termAmount: number
) => {
  let ir = parseFloat(loanInterestRate)
  const sf = loanServiceFee ? parseFloat(loanServiceFee.toString()) : 0

  // full interest rate
  if (!Number.isNaN(sf)) {
    ir += sf
  }

  const pv = parseFloat(remainingPrincipal.toString())

  return Math.floor(Math.abs(newPMT(ir / 12, termAmount, pv)))
}

export const RATE = (
  periods: number,
  payment: number,
  present: number,
  future = 0,
  type = 0,
  guess = 0.01
) => {
  // Set maximum epsilon for end of iteration
  const epsMax = 1e-10
  // Set maximum number of iterations
  const iterMax = 10

  // Implement Newton's method
  let y = 0
  let y0 = 0
  let y1 = 0
  let x0 = 0
  let x1 = 0
  let f = 0
  let i = 0
  let rate = guess

  if (Math.abs(rate) < epsMax) {
    y =
      present * (1 + periods * rate) +
      payment * (1 + rate * type) * periods +
      future
  } else {
    f = Math.exp(periods * Math.log(1 + rate))
    y = present * f + payment * (1 / rate + type) * (f - 1) + future
  }

  y0 = present + payment * periods + future
  y1 = present * f + payment * (1 / rate + type) * (f - 1) + future
  x1 = rate

  while (Math.abs(y0 - y1) > epsMax && i < iterMax) {
    rate = (y1 * x0 - y0 * x1) / (y1 - y0)
    x0 = x1
    x1 = rate

    if (Math.abs(rate) < epsMax) {
      y =
        present * (1 + periods * rate) +
        payment * (1 + rate * type) * periods +
        future
    } else {
      f = Math.exp(periods * Math.log(1 + rate))
      y = present * f + payment * (1 / rate + type) * (f - 1) + future
    }

    y0 = y1
    y1 = y
    i += 1
  }

  return rate
}
