import { FormattedMessage } from "react-intl"

import { useAutostartTokenEffect } from "Hooks/use-autostart-token-effect"
import Loading from "UI/Loading"

import * as S from "./AutostartTokenSign.styles"

export type AutostartTokenSignProps = {
  cancelAuth: () => void
  autostartToken: string
  isSigning: boolean
}

export const AutostartTokenSign = ({
  isSigning,
  cancelAuth,
  autostartToken,
}: AutostartTokenSignProps) => {
  useAutostartTokenEffect(autostartToken)

  return (
    <>
      <p>
        <FormattedMessage
          id={
            isSigning
              ? "app.login.method.info.signing"
              : "hintcode-desktop-outstandingTransaction"
          }
        />
      </p>
      <Loading isCentered />
      {!isSigning && (
        <S.LinkStyledButton type="button" onClick={cancelAuth}>
          <FormattedMessage id="open-bankid-on-another-device" />
        </S.LinkStyledButton>
      )}
    </>
  )
}
