import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const BackButtonWrap = styled.div`
  display: flex;
  align-items: center;

  span {
    color: var(--dark-gray);
    margin: 0 10px 0 5px;
  }

  button {
    display: flex;
    align-items: center;
    margin-left: 15px;

    &:focus {
      outline: none;
    }

    .icon-chevronRight {
      transform: rotate(180deg);
      path {
        stroke: var(--black);
      }
    }

    &:disabled {
      cursor: not-allowed;
      .icon-chevronRight {
        path {
          stroke: var(--light-gray);
        }
      }
    }
  }

  @media screen and (min-width: ${mediaQueries.medium}) {
    span {
      margin: 0 15px 0 10px;
    }

    button {
      margin-left: 35px;
    }
  }
`

export const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2em;
  @media screen and (max-width: ${mediaQueries.medium}) {
    padding: 1em 2em;
  }
  .opened {
    svg path.line {
      stroke: var(--white);
    }
  }
`

export const NavList = styled.div`
  display: none;
  text-align: center;
  font-weight: bold;
  margin-right: 1.25em;
  @media screen and (min-width: ${mediaQueries.medium}) {
    display: block;
  }
`
export const NavLink = styled.a`
  color: ${(props) => props.theme.fontColor};
  text-decoration: underline;
  margin: 0 1.25em;
  font-size: ${font.size.lg};
  &:hover {
    text-decoration: none;
  }
`

export const LogoWrap = styled.div`
  display: flex;
  align-items: center;

  svg path {
    fill: ${(props) => props.theme.fontColor};
  }
`
