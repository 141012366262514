import { AnimatePresence } from "framer-motion"
import { useCallback, useState } from "react"
import { FormattedMessage } from "react-intl"
import Modal from "UI/Modal"
import * as S from "../LoanApplication.styles"

const TermsModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleModal = useCallback(() => setIsOpen(!isOpen), [isOpen])

  const body = () => {
    return (
      <>
        <h3>
          <FormattedMessage id="app.mypages.loans.application.modal.uc.header" />
        </h3>
        <p>
          <FormattedMessage id="app.mypages.loans.application.modal.uc.body" />
        </p>
        <h3>
          <FormattedMessage id="app.mypages.loans.application.modal.gdpr.header" />
        </h3>
        <p>
          <FormattedMessage id="app.mypages.loans.application.modal.gdpr.body" />
        </p>
        <h3>
          <FormattedMessage id="app.mypages.loans.application.modal.gdpr_responsible.header" />
        </h3>
        <p>
          <FormattedMessage id="app.mypages.loans.application.modal.gdpr_responsible.body" />
        </p>
        <h3>
          <FormattedMessage id="app.mypages.loans.application.modal.gdpr_use.header" />
        </h3>
        <p>
          <FormattedMessage id="app.mypages.loans.application.modal.gdpr_use.body" />
        </p>
      </>
    )
  }

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <Modal
            body={body()}
            onClick={handleModal}
            title={
              <FormattedMessage id="app.mypages.loans.application.modal.title" />
            }
          />
        )}
      </AnimatePresence>
      <S.ButtonLink onClick={handleModal} type="button">
        <FormattedMessage
          id="app.mypages.loans.application.terms.link"
          defaultMessage="användarvillkor"
        />
      </S.ButtonLink>
    </>
  )
}

export default TermsModal
