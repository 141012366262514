import { FormattedMessage } from "react-intl"

import Button from "UI/Button"

import * as S from "./SignFlowError.styles"

export const SignFlowError = ({
  errorMessage,
  handleTryAgain,
}: {
  errorMessage: string
  handleTryAgain: () => void
}) => {
  return (
    <S.SignFlowErrorBlock>
      <p>{errorMessage}</p>
      <Button type="button" onClick={handleTryAgain}>
        <FormattedMessage id="app.common.tryAgain" />
      </Button>
    </S.SignFlowErrorBlock>
  )
}
