import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"
import { MenuWrapperPropTypes, MenuPropTypes } from "./SideMenu.types"

export const MenuWrapper = styled.div<MenuWrapperPropTypes>`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: flex;
  bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: right 300ms ease;
  visibility: visible;
  width: 100%;
  z-index: 79;
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  background: ${(props) =>
    props.isOpen ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0)"};
`

export const Menu = styled.div<MenuPropTypes>`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: flex;
  align-items: center;
  background-color: var(--black);
  height: 100%;
  position: absolute;
  right: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 100%;
  flex-direction: column;
  justify-content: center;
  transition: right 300ms ease;

  @media screen and (min-width: ${mediaQueries.small}) {
    width: 50%;
  }

  @media screen and (max-width: ${mediaQueries.large}) {
    padding-right: 0;
  }
`

export const MenuList = styled.div`
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80vh;
  overflow: visible;
  padding-top: 10px;
  padding-bottom: 10px;
`

export const MenuLink = styled.a`
  margin: 1em 0;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  color: var(--white);
  font-size: ${font.size.xl2};
  font-weight: 700;

  @media screen and (min-width: ${mediaQueries.large}) {
    font-size: ${font.size.xl5};
  }
`
