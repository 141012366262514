import styled from "styled-components"

import { mediaQueries } from "Styles/styleGlobal"

interface DeleteLoan {
  isMainInterface: boolean | undefined
}

export const ScrapedItem = styled.li<DeleteLoan>`
  min-width: 100%;
  transition: 0.3s;
  border-radius: 15px;
  z-index: 0;
  list-style: none;

  ${(props) => {
    if (props.isMainInterface) {
      return `
        &:hover {
          background-color: var(--very-light-gray);
          opacity: 0.5;
        }
      `
    }

    return ``
  }}

  input[type="checkbox"] {
    :checked ~ label {
      background-color: var(--very-light-yellow);
      box-shadow: inset 0px 0px 0px 3px var(--dark-yellow);
    }

    :checked:hover ~ label {
      background-color: #faf1dd;
    }
  }

  @media screen and (max-width: ${mediaQueries.small}) {
    min-width: 100%;
  }
`

export const ScrapedItemContent = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  padding: 1rem;
  box-shadow: inset 0px 0px 0px 1px var(--light-gray);
  transition: all 200ms ease-in-out;
  p {
    margin: 0;
  }

  @media screen and (max-width: ${mediaQueries.small}) {
    padding: 0.8rem;
  }
`

export const ScrapedItemLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (min-width: ${mediaQueries.medium}) {
    min-width: 33%;
  }
`

export const ScrapedItemInformation = styled.div`
  display: inherit;
  flex-direction: column;
  margin-left: 0.5rem;
`

export const ScrapedItemType = styled.span`
  font-weight: 500;
  grid-area: bank-name;
  margin: 0;
`
export const ScrapedItemPercentage = styled.span`
  grid-area: info;
  color: var(--dark-gray);
`

export const ScrapedItemRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (min-width: ${mediaQueries.medium}) {
    min-width: 33%;
  }
`

export const ScrapedItemAmount = styled.p`
  font-weight: 500;
`
export const ScrapedItemCostPerMonth = styled.p`
  color: var(--dark-gray);
`
