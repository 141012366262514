import axios from "ApiServices/axios"
import { Api } from "Constants/api"
import type { AxiosError } from "axios"
import type { PaymentFreeMonthPayload } from "Components/LoanCTALinks/PaymentFreeModal/PaymentFreeModal.types"
import type { PaymentFreeMonthsStatus } from "Interfaces/loanInterfaces"

export const postPaymentFreeMonth = async (
  payload: PaymentFreeMonthPayload
) => {
  try {
    return await axios.post(Api.PaymentFreeMonths, payload)
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getPaymentFreeMonthStatus = async () => {
  try {
    const { data } = await axios.get<PaymentFreeMonthsStatus>(
      Api.PaymentFreeMonths
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}
