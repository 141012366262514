import styled, { css } from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"
import { StyledSelectPropTypes } from "./Select.types"

export const StyledSelect = styled.select<StyledSelectPropTypes>`
  background: var(--white);
  border: 1px solid var(--light-gray);
  width: 100%;
  height: 60px;
  padding: 1em;
  color: var(--black);
  margin: 0.5em 0;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.331044 0.256714C0.741554 -0.112745 1.37384 -0.0794661 1.7433 0.331044L10 9.50516L18.2567 0.331044C18.6262 -0.0794661 19.2585 -0.112745 19.669 0.256714C20.0795 0.626173 20.1128 1.25846 19.7433 1.66897L10.7433 11.669C10.5537 11.8797 10.2835 12 10 12C9.71652 12 9.44636 11.8797 9.25671 11.669L0.256714 1.66897C-0.112745 1.25846 -0.0794661 0.626173 0.331044 0.256714Z' fill='%23717171'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  padding-right: 2.5em;
  line-height: 1.5em;
  text-overflow: ellipsis;

  ${(p) => css`
    font-size: ${font.size.lg};
    padding: ${p.$selected ? "1em 1em 0" : "15px 1em"};
    color: ${p.$selected ? "var(--black)" : "var(--dark-gray)"};
  `}

  ${(props) =>
    props.type === "rounded"
      ? css`
          border-radius: 60px;
          height: 50px;
          padding-top: ${() => (props.$selected ? "1em" : "0px")};
          padding-bottom: 0px;
        `
      : css`
          border-radius: 6px;
        `}

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.error &&
    css`
      border: 1px solid var(--brand-danger);
    `}

  @media screen and (max-width: ${mediaQueries.medium}) {
    font-size: ${font.size.base};
  }
`

export const ValidationMessage = styled.div`
  font-size: ${font.size.xs};
  color: var(--brand-danger);
  margin-bottom: 9px;
`

export const SelectWrap = styled.div`
  position: relative;
  width: 100%;
`

export const Placeholder = styled.span<{ $type?: string }>`
  color: blue;
  position: absolute;
  font-size: ${font.size.lg};
  color: var(--dark-gray);
  pointer-events: none;
  left: 1em;
  transform-origin: top left;
  transition: all 0.2s ease-out;
  font-weight: normal;
  z-index: 3;
  transform: translate(0, 13px) scale(0.65);
  width: max-content;
  line-height: 1.5em;
  top: ${(props) => (props.$type === "rounded" ? "0px" : "6px")};
`
