import styled from "styled-components"
import { motion } from "framer-motion"

export const MenuWrapper = styled(motion.div)`
  box-sizing: border-box;
  margin: 0;
  display: flex;
  bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: visible;
  z-index: 79;
  justify-content: flex-end;
  background-color: var(--white);
`

export const Transparent = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.1);
  display: block;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: -100vw;
  position: absolute;
  z-index: 1;
`
