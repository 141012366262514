import { useMutation, useQuery, useQueryClient } from "react-query"
import { getKYC, createKYC, getStatusKYC } from "ApiServices/KYC"
import { KYC_DATA, KYC_STATUS } from "Constants/queryKeys"

export function useGetKYC(enabled?: boolean) {
  return useQuery({
    queryKey: [KYC_DATA],
    queryFn: getKYC,
    retry: false,
    enabled,
  })
}

export const useCreateKYC = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createKYC,
    onSuccess: () => {
      queryClient.invalidateQueries([KYC_DATA])
      queryClient.invalidateQueries([KYC_STATUS])
    },
  })
}

export const useGetStatusKYC = () => {
  return useQuery({
    queryKey: [KYC_STATUS],
    queryFn: getStatusKYC,
    retry: 0,
  })
}
