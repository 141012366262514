import { FC } from "react"

import BalanceItem from "./BalanceItem"
import type { BalanceOverviewProps } from "./BalanceOverview.types"
import * as S from "./BalanceOverview.styles"

const BalanceOverview: FC<BalanceOverviewProps> = ({ data }) => (
  <S.BalanceOverviewContainer>
    {data.map((item, index) => (
      <BalanceItem key={`${item.value}-${index}`} {...item} />
    ))}
  </S.BalanceOverviewContainer>
)

export default BalanceOverview
