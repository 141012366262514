import { FC } from "react"
import { NavLink } from "react-router-dom"
import IconFactory from "UI/IconFactory"
import { FeatureBoxProps } from "./FeatureBox.types"
import { FeatureBoxWrap, FeatureBoxBody } from "./FeatureBox.styles"

const FeatureBox: FC<FeatureBoxProps> = ({
  title,
  bodyNode,
  color,
  link,
  onClick,
}) => (
  <FeatureBoxWrap color={color} onClick={onClick}>
    {link && <NavLink aria-label={title} to={link} />}
    <h4>{title}</h4>
    <FeatureBoxBody>
      <p>{bodyNode}</p>
    </FeatureBoxBody>

    <IconFactory name="arrowRightShort" />
  </FeatureBoxWrap>
)

export default FeatureBox
