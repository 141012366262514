import styled from "styled-components"
import { font } from "Styles/styleGlobal"
import { flex } from "config/mixins"

type InvalidPropsType = {
  invalid: boolean
}

export const FormWrap = styled.div`
  margin: 3em 0 0 0;
`

export const SupportedFileTypes = styled.p<InvalidPropsType>`
  color: ${(p) => (p.invalid ? "var(--brand-danger)" : "var(--light-gray)")};
  transition: all 200ms ease-in-out;
`

export const Submit = styled.input`
  background: transparent;
  border-radius: 100px;
  color: var(--black);
  transition: all 200ms ease-in-out;
  border: 2px solid var(--black);
  padding: 12px 42px;
  width: 100%;
  cursor: pointer;
  font-size: ${font.size.lg};
  font-weight: 700;
  &:disabled {
    border: 2px solid var(--light-gray);
    cursor: not-allowed;
    color: var(--light-gray);
  }
`
export const FileUploadWrap = styled.div<InvalidPropsType>`
  ${flex.columnCenter}
  position: relative;
  margin: 25px 0 15px;
  border: 3px dashed
    ${(p) => (p.invalid ? "var(--brand-danger)" : "var(--light-gray)")};
  padding: 35px 20px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.infoBox};
  min-height: 350px;
  transition: all 200ms ease-in-out;
`

export const Form = styled.form``

export const Upload = styled.input`
  font-size: ${font.size.lg};
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`
