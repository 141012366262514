import { forwardRef } from "react"

import { FormCheckboxProps } from "./FormCheckbox.types"
import * as S from "./FormCheckbox.styles"

const FormCheckbox = forwardRef<HTMLInputElement, FormCheckboxProps>(
  ({ sizeType = "medium", priority, label, id, ...props }, ref) => (
    <S.CheckboxContainer>
      <S.CheckboxLabel>
        <S.CheckboxInput
          {...props}
          ref={ref}
          priority={priority}
          type="checkbox"
          className="visually-hidden"
          aria-labelledby={id}
        />
        <S.CheckMarkIconWrap sizeType={sizeType}>
          <S.CheckMarkSvg viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" stroke="black" />
          </S.CheckMarkSvg>
        </S.CheckMarkIconWrap>
        {label && <S.CheckboxLabelText id={id}>{label}</S.CheckboxLabelText>}
      </S.CheckboxLabel>
    </S.CheckboxContainer>
  )
)

FormCheckbox.displayName = "FormCheckbox"

export default FormCheckbox
