import { useMutation, useQuery } from "react-query"

import { getUser, logout } from "ApiServices/user"
import { USER } from "Constants/queryKeys"

export function useGetUser() {
  return useQuery({
    queryKey: [USER],
    queryFn: getUser,
    retry: 0,
  })
}

export const useLogout = () => useMutation({ mutationFn: logout })
