import { FormattedMessage } from "react-intl"

import IconFactory from "UI/IconFactory"
import Button from "UI/Button"
import Loading from "UI/Loading"
import { useAutostartTokenEffect } from "Hooks/use-autostart-token-effect"

export type AutostartTokenProps = {
  cancelAuth: () => void
  autostartToken: string
  isSigning: boolean
}

export const AutostartToken = ({
  isSigning,
  cancelAuth,
  autostartToken,
}: AutostartTokenProps) => {
  useAutostartTokenEffect(autostartToken)

  return (
    <>
      <IconFactory name="bankId" width={56} height={50} />
      <h1>
        <FormattedMessage id="app.login.button.bankId" />
      </h1>
      {isSigning ? (
        <>
          <Loading isCentered />
          <p>
            <FormattedMessage id="app.login.method.info.signing" />
          </p>
        </>
      ) : (
        <p>
          <FormattedMessage id="app.login.method.info.autostartToken" />
        </p>
      )}
      <Button type="button" variant="inverse" onClick={cancelAuth}>
        <FormattedMessage id="app.common.cancel" />
      </Button>
    </>
  )
}
