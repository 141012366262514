import * as yup from "yup"
import { SubmitHandler, useForm } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import { yupResolver } from "@hookform/resolvers/yup"
import { TextField } from "Components/form/TextField"
import Button from "UI/Button"
import { useUpdateDepositInitialAmount } from "Hooks/API/useDepositServices"
import { useParams } from "react-router-dom"
import { FC } from "react"
import * as S from "./AccountInitAmount.styles"

const InitAmountSchema = yup.object().shape({
  amount: yup
    .number()
    .min(100, () => (
      <FormattedMessage
        id="app.mypages.savings.onBoarding.start.accountType.initialAmount.min.error"
        defaultMessage="Beloppet måste vara minst 100"
      />
    ))
    .transform((v) => (Number.isNaN(v) ? null : v))
    .nullable(true)
    .required(),
})

const AccountInitAmount: FC<{
  handleNextStep: (isInitAmountSet: boolean) => void
}> = ({ handleNextStep }) => {
  const { accountID } = useParams() as { accountID: string }

  const updateDepositInitialAmount = useUpdateDepositInitialAmount()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      amount: 0,
    },
    resolver: yupResolver(InitAmountSchema),
  })

  const onSubmit: SubmitHandler<{ amount: number }> = ({ amount }) => {
    updateDepositInitialAmount
      .mutateAsync({ amount, uid: accountID })
      .then(() => {
        handleNextStep(true)
      })
  }

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <p>
        <FormattedMessage
          id="app.mypages.savings.onBoarding.start.accountType.initialAmount"
          defaultMessage="Vänligen skriv det belopp du planerar att sätta in som din första insättning. Efter att kontot öppnats är insättningar och uttag möjliga i 7 dagar."
        />
      </p>
      <TextField
        control={control}
        name="amount"
        type="number"
        autoFocus
        placeholder={
          <FormattedMessage
            id="app.mypages.savings.onBoarding.start.accountType.initialAmount.placeholder"
            defaultMessage="Ange belopp i kr"
          />
        }
      />

      <Button
        disabled={updateDepositInitialAmount.isLoading}
        isLoading={updateDepositInitialAmount.isLoading}
      >
        <FormattedMessage
          id="app.onboarding.loanPurpose.nextCTAText"
          defaultMessage="Gå vidare"
        />
      </Button>
    </S.Form>
  )
}

export default AccountInitAmount
