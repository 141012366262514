export const FILTER = {
  THREE_MONTH: "3_months",
  THIS_YEAR: "this_year",
  ONE_YEAR: "1_year",
  SINCE_START: "since_start",
} as const

export const PROFILE_CLASS = {
  PROGRESSIVE: "progressive",
  BALANCED: "balanced",
  CONSERVATIVE: "conservative",
} as const

export const PROFILE_TERM = {
  SHORT: "0-84",
  MEDIUM: "0-120",
} as const

export const PROFILE_PREFERANCE = {
  REINVEST_ALL: "reinvest_all_proceeds",
  REINVEST_AMORTIZATIONS: "reinvest_only_amortizations",
  PAYOUT_ALL: "payout_all_proceeds",
} as const

export const DEPOSIT_STATUS = {
  ACTIVE: "active",
  DEACTIVATED: "deactivated",
  REVOKED: "revoked",
  SIGNED: "signed",
  CREATED: "created",
  CLOSED: "closed",
}

export const ACCOUNT_BALANCE = "account_balance"
export const RETURN = "return"
export const ENDED = "ended"
export const CURRENT = "current"

export enum CashbackTypeEnum {
  Withdraw = "withdraw",
  ExtraPayment = "extra_payment",
  Charity = "donate_to_charity",
  Investment = "open_investment_account",
}

export const FIXED_PROFILE_TYPE = "fixed"
export const FLEX_PROFILE_TYPE = "flex"

export const MOCKED_CYPRESS_SESSION_UID = "d1f39151-27e7-41ff-a7ed-29c6ef71f731"
