import { FC, useState } from "react"
import { useParams } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useQueryClient } from "react-query"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useUpdateDepositServiceName } from "Hooks/API/useDepositServices"
import IconFactory from "UI/IconFactory"
import { useFeedbackModal } from "Context/modal-context"
import { IDepositService } from "Interfaces/depositInterfaces"
import { TextField } from "Components/form/TextField"
import { DEPOSIT_SERVICES_SERVICE } from "Constants/queryKeys"
import { updateDepositNameSchema } from "./validationSchema"
import { UpdateDepositNameValues } from "./UpdateDepositName.types"
import * as S from "./UpdateDepositName.styles"

const UpdateDepositeName: FC = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const { accountID } = useParams() as { accountID: string }
  const { errorModal } = useFeedbackModal()
  const updateDepositServiceName = useUpdateDepositServiceName(accountID)
  const queryClient = useQueryClient()
  const initialData = queryClient.getQueryData<IDepositService>([
    DEPOSIT_SERVICES_SERVICE,
    accountID,
  ])

  const form = useForm<UpdateDepositNameValues>({
    values: {
      depositName: initialData?.name || "",
    },
    resolver: yupResolver(updateDepositNameSchema),
  })

  const onSubmit = (values: UpdateDepositNameValues) => {
    setIsEditMode(false)
    updateDepositServiceName.mutateAsync(values).catch(() => errorModal())
  }

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode)
    if (!isEditMode) {
      form.reset({}, { keepDefaultValues: true })
    }
  }

  if (!isEditMode) {
    return (
      <S.IconWrap>
        <IconFactory name="editPen" onClick={toggleEditMode} />
      </S.IconWrap>
    )
  }

  return (
    <S.Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
      <S.InputWrap>
        <TextField
          name="depositName"
          id="depositName"
          control={form.control}
          autoFocus
          placeholder={
            <FormattedMessage
              id="app.mypages.deposits.home.input.placeholder"
              defaultMessage="Ny insättning namn"
            />
          }
        />
        <S.ButtonEditNameWrap>
          <S.TextButton
            type="submit"
            disabled={
              updateDepositServiceName.isLoading || !form.formState.isValid
            }
          >
            <FormattedMessage
              id="app.mypages.investments.home.editButtonName.save"
              defaultMessage="Spara"
            />
          </S.TextButton>
          <S.TextButton onClick={toggleEditMode} type="button">
            <FormattedMessage id="app.common.cancel" defaultMessage="Avbryt" />
          </S.TextButton>
        </S.ButtonEditNameWrap>
      </S.InputWrap>
    </S.Form>
  )
}

export default UpdateDepositeName
