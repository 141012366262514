import styled, { css } from "styled-components"

export const CheckboxContainer = styled.div``

export const CheckboxLabel = styled.label`
  display: inline-flex;
  cursor: pointer;
`

export const CheckMarkSvg = styled.svg`
  position: relative;
  z-index: 2;
  fill: none;
  stroke: white;
  stroke-width: 2px;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 150ms;
`

export const CheckMarkIconWrap = styled.div<{ sizeType?: string }>`
  display: block;
  position: relative;
  line-height: 0;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  margin-right: 0.5em;

  ${(p) =>
    p.sizeType === "large" &&
    css`
      min-width: 26px;
      min-height: 26px;
      width: 26px;
      height: 26px;
    `}

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    transition: all 150ms;
    border: 1px solid;
    border-color: var(--light-gray);
    background-color: var(--white);
    z-index: 1;
  }
`
export const CheckboxLabelText = styled.span`
  line-height: 1.5;
`

export const CheckboxInput = styled.input<{ priority?: boolean }>`
  & ~ ${CheckMarkIconWrap} {
    margin: ${(p) => p.priority && "0 20px"};
  }

  :not(:checked) ~ ${CheckMarkIconWrap} {
    &::before {
      background-color: ${(p) => p.priority && "var(--very-light-gray)"};
    }
  }

  :checked ~ ${CheckMarkIconWrap} {
    ${CheckMarkSvg} {
      opacity: 1;
    }

    &::before {
      border-color: transparent;
      background-color: var(--light-yellow);
    }
  }
`
