import { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { useParams } from "react-router-dom"

import { useGetDepositServiceTransactions } from "Hooks/API/useDepositServices"
import TransactionsTable from "Components/TransactionsTable"
import Loading from "UI/Loading"
import * as S from "./Transactions.styles"

const Transactions = () => {
  const { accountID } = useParams() as { accountID: string }

  const { ref, inView } = useInView()

  const transactions = useGetDepositServiceTransactions(accountID)

  useEffect(() => {
    if (inView) {
      transactions.fetchNextPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  if (transactions.isLoading) {
    return (
      <S.LoadingContainer>
        <Loading />
      </S.LoadingContainer>
    )
  }

  return (
    <S.TransactionsWrap>
      {transactions.isSuccess && (
        <TransactionsTable transactions={transactions} />
      )}
      <span ref={ref} />
    </S.TransactionsWrap>
  )
}

export default Transactions
