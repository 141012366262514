import styled from "styled-components"

export const SavingsHomeWrap = styled.div``

export const ClosedAccountMessage = styled.p`
  padding: 1em;
  border-radius: 16px;
  text-align: center;
  font-weight: 500;
  background-color: var(--very-light-yellow);
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`
