import { FieldValues, useController } from "react-hook-form"
import { useIntl } from "react-intl"

import Radio from "UI/Radio"
import { ErrorMessage } from "UI/Radio/Radio.styles"
import { RadioGroupFieldProps } from "./RadioGroupField.types"

export const RadioGroupField = <FieldsType extends FieldValues>({
  name,
  control,
  rules,
  message,
  defaultValue,
  options,
  transformValueToString,
  ...props
}: RadioGroupFieldProps<FieldsType>) => {
  const intl = useIntl()
  const { field, fieldState } = useController<FieldsType>({
    name,
    rules,
    control,
    defaultValue,
  })

  const fieldValue = transformValueToString
    ? transformValueToString(field.value)
    : field.value
  return (
    <>
      {options.map(({ label, value }) => {
        const val = transformValueToString
          ? transformValueToString(value)
          : value
        return (
          <Radio
            {...props}
            {...field}
            id={val}
            key={val}
            value={val}
            checked={fieldValue === val}
            labelText={intl.formatMessage(label)}
          />
        )
      })}
      <ErrorMessage>{fieldState.error?.message}</ErrorMessage>
    </>
  )
}
