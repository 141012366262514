import styled from "styled-components"

export const SignFlowErrorBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 32px;
  margin: 0 auto;
  width: 100%;
  padding: 24px 0;

  p {
    margin: 0;
    text-align: center;
  }
`
