export enum InsuranceAgreementStatus {
  complete = "complete",
  error = "error",
}

export enum LoanContractSignatureStatusEnum {
  pending = "pending",
  complete = "complete",
  error = "error",
}

export enum LoanContractSignatureProgressStatus {
  alreadyInProgress = "already_in_progress",
  invalidParameters = "invalid_parameters",
  internalError = "internal_error",
  retry = "retry",
  accessDenied = "access_denied_rp",
}

export enum DepositContractSignatureStatus {
  pending = "pending",
  complete = "complete",
  error = "error",
}

export enum DepositContractSignatureProgressStatus {
  alreadyInProgress = "already_in_progress",
  invalidParameters = "invalid_parameters",
  internalError = "internal_error",
  retry = "retry",
  accessDenied = "access_denied_rp",
}

export enum DepositServiceStatus {
  created = "created",
  active = "active",
  signed = "signed",
  revoked = "revoked",
  closed = "closed",
}

export enum AuthSignatureStatus {
  pending = "pending",
  complete = "complete",
  error = "error",
}

export enum InvoiceStatus {
  paid = "paid",
  outstanding = "outstanding",
  overdue = "overdue",
  reminder = "reminder",
  collection = "collection",
}

export enum LoanApplicationSignatureStatusEnum {
  pending = "pending",
  complete = "complete",
  error = "error",
}

export enum LoanApplicationSignatureProgressStatus {
  alreadyInProgress = "already_in_progress",
  invalidParameters = "invalid_parameters",
  internalError = "internal_error",
  retry = "retry",
  accessDenied = "access_denied_rp",
}
