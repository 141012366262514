import { FC } from "react"
import { useLangContext } from "Context/lang-context"
import { LocaleType } from "Constants/languages"
import { LanguageLink } from "../Languages.styles"

type LanguageItemPropTypes = {
  isSidebar: boolean
  value: LocaleType
  language: string
}

const LanguageItem: FC<LanguageItemPropTypes> = ({
  value,
  language,
  isSidebar,
}) => {
  const { selectLanguage, locale } = useLangContext()
  const handleClick = () => {
    selectLanguage(value)
  }

  return (
    <LanguageLink
      isSidebar={isSidebar}
      active={locale === value}
      onClick={handleClick}
    >
      {language}
    </LanguageLink>
  )
}

export default LanguageItem
