import { FC } from "react"
import { ProgressBarPropTypes } from "./ProgressBar.types"
import {
  StyledProgressWrap,
  StyledProgressBar,
  Progress,
} from "./ProgressBar.styles"

const ProgressBar: FC<ProgressBarPropTypes> = ({ width }) => (
  <StyledProgressWrap data-testid="progress-bar">
    <StyledProgressBar aria-label="Progress Bar">
      <Progress width={width} data-testid="progress" />
    </StyledProgressBar>
  </StyledProgressWrap>
)

export default ProgressBar
