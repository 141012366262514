import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const MobileWrap = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 100%;
  }
`

export const LoanProtectionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em 2em 0;
  align-items: center;

  .loan-protection-header {
    font-size: ${font.size.xl};
  }
`

export const ApplicationProgress = styled.div`
  display: flex;
  flex-direction: column;

  .circle-wrap {
    display: flex;
    align-items: center;

    span {
      color: var(--light-gray);

      &.active {
        color: ${(props) => props.theme.fontColor};
        font-weight: bold;
      }
      .small {
        display: block;
        color: var(--dark-gray);
        font-size: ${font.size.sm};
        margin: -2px 0;
        position: absolute;
        line-height: 18px;
      }
    }
  }

  .circle {
    background: var(--light-gray);
    height: 16px;
    width: 16px;
    border-radius: 100%;
    margin: 10px 15px 10px 0;

    &.active {
      background: var(--dark-yellow);
      height: 24px;
      width: 24px;
    }

    &:not(.active) {
      position: relative;
      left: 4px;
      margin: 10px 23px 10px 0;
    }
  }

  .line {
    width: 3px;
    height: 50px;
    background: var(--light-gray);
    position: relative;
    left: 10px;

    &.active {
      background: var(--dark-yellow);
    }
  }
`

export const DetailsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5em 2em;

  div {
    text-align: left;
  }

  .detail-amount {
    font-size: ${font.size.xl2};
    margin: 0 0 0.5em 0;
  }

  .detail-title {
    font-size: ${font.size.sm};
    color: var(--dark-gray);
  }

  @media screen and (max-width: ${mediaQueries.large}) {
    flex-direction: column-reverse;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }
`

export const Link = styled.a`
  text-decoration: underline;
`
