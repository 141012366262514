import type { FC } from "react"
import { FormattedMessage } from "react-intl"

import Button from "UI/Button"
import { BROCC_LOAN_REJECTED } from "Constants/externalLinks"
import * as S from "./Rejected.styles"

export type RejectedPropsType = {
  source: string
  hasExpired?: boolean
}

const Rejected: FC<RejectedPropsType> = ({ source, hasExpired }) => (
  <S.TextContent>
    <h1>
      <FormattedMessage
        id="app.onboarding.rejected.header"
        defaultMessage="Tack för din ansökan"
      />
    </h1>

    <p>
      <FormattedMessage
        id="app.onboarding.rejected.paragraph1"
        defaultMessage="Baserat på den information du lämnat samt i kreditupplysning från UC, så kan Brocc tyvärr inte bevilja din ansökan om lån just nu."
      />
    </p>

    {source === "brocc" && (
      <>
        <p>
          <FormattedMessage
            id="app.onboarding.rejected.paragraph.partner"
            defaultMessage="Vår samarbetspartner Direkto kan möjligtvis hjälpa er. Klicka på knappen nedan för att komma vidare till deras sida. Observera att de behöver ta en kreditupplysning."
          />
        </p>
        <S.ButtonWrap>
          <Button
            component="a"
            href={BROCC_LOAN_REJECTED}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id="app.onboarding.rejected.paragraph.partnerButton"
              defaultMessage="Till Ordna"
            />
          </Button>
        </S.ButtonWrap>
      </>
    )}

    {hasExpired && (
      <>
        <h1>
          <FormattedMessage id="app.onboarding.rejected.expired.header" />
        </h1>
        <p>
          <FormattedMessage id="app.onboarding.rejected.expired.paragraph" />
        </p>
      </>
    )}
  </S.TextContent>
)

export default Rejected
