import { FormattedMessage } from "react-intl"

export const ModalsBody1 = () => {
  return (
    <>
      <p>
        <FormattedMessage
          id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal.paragraph1"
          defaultMessage="För att anses som fullt arbetsför ska försäkringssökande:"
        />
      </p>
      <ul>
        <li>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal.list1"
            defaultMessage="kunna fullgöra sitt vanliga arbete utan inskränkningar"
          />
        </li>
        <li>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal.list2"
            defaultMessage="inte varit sjukskriven mer än 14 dagar de senaste 90 dagarna"
          />
        </li>
        <li>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal.list3"
            defaultMessage="inte till någon del uppbära sjuklön för arbetsgivare eller
                    ersättning från Försäkringskassan (sjukpenning,
                    Rehabiliterinspenning, sjuk-/aktivitetsersättning - även
                    vilande eller tidsbegränsad, handikappersättning eller annan
                    liknande ersättning,) inte ha särskilt anpassat arbete eller
                    lönebidragsansällning, inte heller ha pågående eller vilande
                    arbetsskadelivränta."
          />
        </li>
      </ul>
      <p>
        <FormattedMessage
          id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal.paragraph2"
          defaultMessage="Om du vill ansöka ersättning för Arbetsoförmåga och Arbetslöshet
                    krävs dessutom följande:"
        />
      </p>
      <ul>
        <li>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal.list4"
            defaultMessage="du är vid tiden för ansökan tillsvidareanställd sedan minst 6
                  sammanhängande månader i sträck med en arbetstid på minst 20
                  timmar per vecka eller"
          />
        </li>
        <li>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal.list5"
            defaultMessage="du är egen företagare och anslutet till erkänd
                    arbetslöshetskassa eller du är visstids- eller projektanställd
                    med anställningsavtal som gäller för minst 20 timmars arbete i
                    veckan under minst 12 månader framåt"
          />
        </li>
        <li>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal.list6"
            defaultMessage="är du tillsvidareanställd eller egen företagare är du vid
                    tiden för ansökan inte medveten om förestående varsel om
                    uppsägning eller förestående arbetslöshet"
          />
        </li>
      </ul>
    </>
  )
}

export const ModalsBody2 = () => {
  return (
    <>
      <p>
        <FormattedMessage
          id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal2.paragraph"
          defaultMessage="Teckningsregler för Liv- och Olycksfallsförsäkring:"
        />
      </p>
      <ul>
        <li>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal2.list1"
            defaultMessage="du ingår i den försäkringsberättigade gruppen"
          />
        </li>
        <li>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal2.list2"
            defaultMessage="du har fyllt 18 men inte 60 år"
          />
        </li>
        <li>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal2.list3"
            defaultMessage="du är fullt arbetsför"
          />
        </li>
        <li>
          <FormattedMessage
            id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal2.list4"
            defaultMessage="du är bosatt och folkbokförd i Sverige och inskriven i svensk
          försäkringskassa"
          />
        </li>
      </ul>
    </>
  )
}

export const ModalsBody3 = () => {
  return (
    <>
      <p>
        <FormattedMessage
          id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal3.paragraph1"
          defaultMessage="Vid hel arbetsoförmåga lämnas inte ersättning om arbetsoförmågan har sin grund i sjukdom, besvär, skada eller handikapp som visat symptom eller förelegat före försäkringen trädde i kraft, även om diagnos eller orsak först kan fastställas efter att försäkringen
          trätt i kraft."
        />
      </p>
      <p>
        <FormattedMessage
          id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal3.paragraph2"
          defaultMessage="Vid dödsfall beviljas inte ersättning för försäkringsfall/dödsfall som beror på sjukdom, besvär,skada eller handikapp som visat symptom eller förelagat innan försäkringen trädde i kraft, även om diagnos eller orsak först kan fastställas efter att försäkringen
trätt i kraft."
        />
      </p>
      <p>
        <FormattedMessage
          id="app.onboarding.insuranceCheck.fitToWorkConfirmation.modal3.paragraph3"
          defaultMessage="Försäkrad som varit helt behandlings- och symptomfri under en
          sammanhängande tid av minst 12 månader närmast före det att
          ersättningsanspråk framställts beviljas dock ersättning för en sådan
          skada som tidigare inte skulle gett ersättning enligt denna
          symptomklausul."
        />
      </p>
    </>
  )
}
