import { FC, ReactElement } from "react"
import { FilterButtonVariants } from "../ReportFilters.types"
import { Button } from "./FilterButton.styles"

type FilterButtonPropsTypes = {
  range: FilterButtonVariants
  text: ReactElement | string
  active: boolean
  onClick: (range: FilterButtonVariants) => void
}

const FilterButton: FC<FilterButtonPropsTypes> = ({
  range,
  text,
  active,
  onClick,
}) => {
  const handleClick = () => onClick(range)

  return (
    <Button
      type="button"
      data-range={range}
      active={active}
      onClick={handleClick}
    >
      {text}
    </Button>
  )
}

export default FilterButton
