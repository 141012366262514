import yup from "config/yup"
import { FormattedMessage } from "react-intl"

const maritalStatusOptions = [
  "",
  "single",
  "living_together",
  "married",
  "divorced",
]

const housingOptions = ["", "rental", "house", "condominium", "tenant"]

const occupationOptions = [
  "",
  "full_time",
  "student",
  "unemployed",
  "early_pensioner",
  "finite_time",
  "hourly",
  "self_employed",
]

yup.setLocale({
  mixed: {
    required: () => (
      <FormattedMessage
        id="app.common.obligatory"
        defaultMessage="Obligatorisk"
      />
    ),
  },
})

const loanApplicantDetailsSchema = yup.object().shape({
  number_of_kids: yup.number().when("has_kids", {
    is: true,
    then: yup
      .number()
      .required()
      .typeError(() => (
        <FormattedMessage
          id="app.common.obligatory"
          defaultMessage="Obligatoriskt"
        />
      )),
    otherwise: yup.number().nullable(),
  }),
  housing_type: yup
    .string()
    .oneOf(housingOptions)
    .typeError(() => (
      <FormattedMessage
        id="app.common.obligatory"
        defaultMessage="Obligatoriskt"
      />
    ))
    .required(),
  housing_cost: yup
    .number()
    .typeError(() => (
      <FormattedMessage
        id="app.common.errors.number"
        defaultMessage="Detta fält får endast innehålla siffror"
      />
    ))
    .positive(() => (
      <FormattedMessage
        id="app.common.errors.positive"
        defaultMessage="Detta fält måste vara en positiv siffra"
      />
    ))
    .required(),
  marital_status: yup
    .string()
    .oneOf(maritalStatusOptions)
    .typeError(() => (
      <FormattedMessage
        id="app.common.obligatory"
        defaultMessage="Obligatoriskt"
      />
    ))
    .required(),
  occupation: yup
    .string()
    .oneOf(occupationOptions)
    .typeError(() => (
      <FormattedMessage
        id="app.common.obligatory"
        defaultMessage="Obligatoriskt"
      />
    ))
    .required(),
  work_income: yup
    .number()
    .typeError(() => (
      <FormattedMessage
        id="app.common.errors.number"
        defaultMessage="Detta fält får endast innehålla siffror"
      />
    ))
    .positive(() => (
      <FormattedMessage
        id="app.common.errors.positive"
        defaultMessage="Detta fält måste vara en positiv siffra"
      />
    ))
    .required(),
})

const loanApplicantSchema = yup.object().shape({
  tel: yup
    .string()
    .required()
    .typeError(() => (
      <FormattedMessage
        id="app.mypages.loans.application.applicant.tel.error"
        defaultMessage="Vänligen ange ett giltigt telefonnummer (07000000000)"
      />
    ))
    .matches(/^07[0-9]{8}$/, () => (
      <FormattedMessage
        id="app.mypages.loans.application.applicant.tel.error"
        defaultMessage="Vänligen ange ett giltigt telefonnummer (07000000000)"
      />
    )),
  email: yup
    .string()
    .required()
    .typeError(
      <FormattedMessage
        id="app.mypages.loans.application.applicant.email.error"
        defaultMessage="Vänligen ange en giltig email"
      />
    )
    .email(
      <FormattedMessage
        id="app.mypages.loans.application.applicant.email.error"
        defaultMessage="Vänligen ange en giltig email"
      />
    ),
  legal_entity_number: yup
    .string()
    .required()
    .typeError(() => (
      <FormattedMessage
        id="app.common.errors.number"
        defaultMessage="Detta fält får endast innehålla siffror"
      />
    ))
    .matches(/^(19|20)?(\d{6}(-|\s)\d{4}|(?!19|20)\d{10})$/, () => (
      <FormattedMessage
        id="app.mypages.loans.application.applicant.ssn.error"
        defaultMessage="Vänligen ange ett giltigt personnummer (ÅÅMMDD-XXXX)"
      />
    )),
  applicant_detail: loanApplicantDetailsSchema.required(),
})

const loanApplicationSchema = yup.object().shape({
  amount: yup
    .number()
    .required()
    .positive()
    .typeError(() => (
      <FormattedMessage
        id="app.common.errors.number"
        defaultMessage="Detta fält får endast innehålla siffror"
      />
    )),
  applicant: loanApplicantSchema.required(),
  has_coapplicant: yup.boolean().required(),
  co_applicant: yup.object().when("has_coapplicant", {
    is: true,
    then: loanApplicantSchema.required(),
  }),
})

export default loanApplicationSchema
