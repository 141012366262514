import { FC } from "react"
import { KYCPropTypes } from "Components/KYC/KYC.types"
import { MessageDescriptor } from "react-intl"

export enum SavingsOnboardingStepsEnum {
  Intro,
  KYC,
  AccountVerification,
  AccountType,
  Agreement,
}

export type SavingsOnboardingSteps = {
  [key: number]:
    | FC<{
        setStep: React.Dispatch<React.SetStateAction<number>>
        handleCancelModal?: () => void
      }>
    | FC<KYCPropTypes>
}

export type StepsTitleType = Record<number, MessageDescriptor>

export type SavingsOnboardingData = Record<
  string,
  {
    brocc_has_account_type?: boolean
    brocc_has_started_savings_onboarding?: boolean
    brocc_has_account_verified?: boolean
    brocc_has_saved_kyc?: boolean
    brocc_has_type_initial_amount?: boolean
  }
>
