import styled from "styled-components"
import { motion } from "framer-motion"

import { mediaQueries } from "Styles/styleGlobal"

export const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${mediaQueries.medium}) {
    display: grid;
    overflow: hidden;
    grid-template-columns: 30% 70%;
    grid-template-rows: repeat(1, 1fr);
    flex: 1;
  }
`

export const MobileAttentionBox = styled.div`
  max-width: 450px;
  margin: 1em auto 0 auto;
  @media screen and (min-width: ${mediaQueries.medium}) {
    display: none;
  }

  @media screen and (max-width: ${mediaQueries.small}) {
    display: block;
  }
`

export const BodyText = styled.p`
  color: var(--black);
  margin: 0.3em 0;
  &:last-of-type {
    margin: 0;
    display: inline-block;
  }
`

export const LeftSection = styled.div`
  @media screen and (max-width: ${mediaQueries.medium}) {
    display: none;
  }

  display: initial;
  border-right: 1px solid var(--light-gray);
  height: 100%;
  padding: 2em;

  div > svg {
    width: 100%;
    &.man-relaxing-green {
      height: 90%;
    }
  }

  > h1 {
    word-wrap: break-word;
  }
`

export const Container = styled.div`
  padding: 0 1em 1em;

  > button {
    width: 100%;
  }

  @media screen and (min-width: ${mediaQueries.medium}) {
    > button {
      width: auto;
    }
  }

  @media screen and (min-width: ${mediaQueries.medium}) {
    padding: 1em 6em;
  }
`

export const GreenAttentionBox = styled.div`
  background: var(--green);
  border-radius: 0px 32px 32px 32px;
  padding: 1.5em 1em;
  margin: 1em 0;
  text-align: center;
  font-weight: bold;
`

export const GrayBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2em 0;
  margin: 2em 0;
  background: var(--very-light-gray);
  border-radius: 15px;
  h1 {
    margin: -15px 0 0;
  }

  span {
    color: var(--dark-gray);
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    margin: 1em 0;
  }
`

export const Overview = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-direction: row;

  div > span {
    color: var(--dark-gray);
  }

  @media screen and (min-width: ${mediaQueries.medium}) {
    flex-direction: column;
  }

  @media screen and (min-width: ${mediaQueries.extraLarge}) {
    flex-direction: row;
  }
`

export const SmallPrint = styled.small`
  color: var(--dark-gray);
  margin: 0 0 2em 0;
  display: block;

  a {
    text-decoration: underline;
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  transform: scale(2);
`

export const ProgressBarWrap = styled.div``

export const OnboardingWrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2rem;
  @media screen and (max-width: ${mediaQueries.small}) {
    flex-direction: column;
  }
`

export const LoanSurveyChoice = styled.span`
  text-decoration: underline;
  cursor: pointer;
`
