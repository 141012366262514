import styled, { css } from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

type TableHeadPropTypes = {
  chevron?: boolean
  hide?: boolean
}

export const Table = styled.div`
  background: ${(props) => props.theme.infoBox};
  border-radius: 20px;
  margin-bottom: 2em;
  padding: 2em 0;
  box-sizing: border-box;
  width: 100%;
`
export const TableHeadWrap = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr 1fr 1fr 100px;
  @media screen and (max-width: ${mediaQueries.large}) {
    grid-template-columns: 80px 1fr 80px;
  }
`

export const TableHead = styled.div<TableHeadPropTypes>`
  font-weight: bold;
  margin-bottom: 1em;
  display: flex;
  padding: 0 2em;
  justify-content: center;

  ${(p) => css`
    display: ${p.chevron && "none"};
    font-size: ${font.size.sm};
    @media screen and (max-width: ${mediaQueries.large}) {
      display: ${p.hide && "none"};
      display: ${p.chevron && "flex"};
    }
  `}
`

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3em 0;
`
