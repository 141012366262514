import { HAS_CLEARED_INSTANTOR } from "Constants/storage"
import { ILoanApplication } from "Interfaces/loanApplicationInterfaces"
import { URLSearchParams } from "url"
import { getStorageItem } from "Utils/localStorage"
import { LaunchDarklyInterface, OnboardingStepsEnum } from "../Onboarding.types"

export const determineStep = (
  data: ILoanApplication,
  query: URLSearchParams,
  flags: LaunchDarklyInterface
) => {
  if (!data) {
    return OnboardingStepsEnum.Default
  }

  const {
    activities,
    status,
    is_paid_out: isPaidOut,
    is_issued: isIssued,
    offer_accepted: offerAccepted,
    applicant,
    loan_purpose: loanPurpose,
    co_applicant: coApplicant,
    is_signed: isSigned,
  } = data

  const ppiReviewStatus = activities.find(
    (activity) => activity.name === "review_payment_protection_insurance"
  )
  const bankTransactionHistory = activities.find(
    (activity) => activity.name === "require.data.bank_transaction_history"
  )
  const existingLoanRedemption = activities.find(
    (activity) => activity.name === "require_existing_loan_redemption"
  )

  const hasClearedInstantor = getStorageItem(HAS_CLEARED_INSTANTOR)

  if (status === "manual_check") {
    return OnboardingStepsEnum.ManualCheck
  }

  if (data.is_rejected) {
    return OnboardingStepsEnum.Rejected
  }

  if (isPaidOut || isIssued) {
    return OnboardingStepsEnum.FollowApplication
  }

  if (!offerAccepted) {
    return OnboardingStepsEnum.Accept
  }

  if (
    bankTransactionHistory &&
    !bankTransactionHistory.completed &&
    !hasClearedInstantor
  ) {
    return OnboardingStepsEnum.BankScreening
  }

  if (!applicant.bank_account_number || !applicant.bank_clearing_number) {
    return OnboardingStepsEnum.BankAccountDetails
  }

  if (!isSigned) {
    return OnboardingStepsEnum.Signing
  }

  if (query.get("co-applicant") && coApplicant) {
    return OnboardingStepsEnum.Signing
  }

  if (
    applicant.is_eligible_for_insurance &&
    (!ppiReviewStatus || !ppiReviewStatus?.completed)
  ) {
    return OnboardingStepsEnum.InsuranceCheck
  }

  if (!loanPurpose || !applicant.applicant_detail.employer) {
    return OnboardingStepsEnum.LoanPurpose
  }

  if (flags.iceing) {
    if (existingLoanRedemption && !existingLoanRedemption.completed) {
      return OnboardingStepsEnum.LoanCollection
    }
  }

  return OnboardingStepsEnum.FollowApplication
}
