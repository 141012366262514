import { FormattedMessage } from "react-intl"
import QRCode from "react-qr-code"

import Loading from "UI/Loading"
import * as S from "./SignBankIDQRCode.styles"

export type BankIDQRCodeProps = {
  authWithAutostartToken: () => void
  qrCode: string
  isSigning: boolean
  isLoading: boolean
}

export const SignBankIDQRCode = ({
  authWithAutostartToken,
  qrCode,
  isSigning,
  isLoading,
}: BankIDQRCodeProps) => {
  return (
    <>
      {isSigning ? (
        <>
          <p>
            <FormattedMessage id="app.login.method.info.signing" />
          </p>
          <Loading isCentered />
        </>
      ) : (
        <>
          <p>
            <FormattedMessage id="hintcode-qr-outstandingTransaction" />
          </p>
          {isLoading || !qrCode ? (
            <Loading isCentered />
          ) : (
            <QRCode value={qrCode} className="qr-code" size={160} />
          )}
        </>
      )}
      {!isSigning && (
        <S.LinkStyledButton type="button" onClick={authWithAutostartToken}>
          <FormattedMessage id="open-bankid-on-this-device" />
        </S.LinkStyledButton>
      )}
    </>
  )
}
