import type {
  CapitalOwnerType,
  DepositAmountType,
  FrequencyType,
  IKYCPrivateRequest,
  IncomeAmountType,
  IPep,
  OccupationType,
} from "Interfaces/KYCInterfaces"

export const emptyInitialPrivateData: IKYCPrivateRequest = {
  is_corporate: false,
  citizenship: [""],
  occupation: "" as OccupationType,
  work_info: "",
  other_occupation: undefined,
  income: "" as IncomeAmountType,
  living_in_sweden: true,
  birthplace_citizenship: "",
  occupancy: "",
  tax_domicile: undefined,
  tax_identification_number: undefined,
  investment: "" as IncomeAmountType,
  deposit_size: "" as DepositAmountType,
  deposit_frequency: "" as FrequencyType,
  withdrawal_frequency: "" as FrequencyType,
  capital_owner: "" as CapitalOwnerType,
  other_capital_owner: undefined,
  source_of_funds: [],
  other_source_of_funds: undefined,
  purpose_of_saving: [],
  other_purpose_of_saving: undefined,
  taxable_usa: false,
  pep: null,
}

export const emptyInitialCompanyData = {
  is_corporate: true as const,
  business_focus: "",
  investment: "" as IncomeAmountType,
  deposit_frequency: "" as FrequencyType,
  source_of_funds: [],
  other_source_of_funds: undefined,
  purpose_of_saving: "",
  owner: null,
  true_owner: null,
  taxable_usa: false,
  tax_domicile: undefined,
  tax_identification_number: undefined,
  pep: null,
}

export const emptyPepData: IPep = {
  pep_position: [],
  pep_relation: [],
  pep_coworker: [],
  pep_relation_position_title: "",
  pep_relation_position_country: "",
  pep_position_title: "",
  pep_position_country: "",
}

export const emptyRealOwnerData = {
  true_owner_name: "",
  true_owner_legal_entity_number: "",
  true_owner_address: null,
  true_owner_country: null,
}
