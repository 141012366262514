import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const ButtonWrap = styled.div`
  margin: 1em auto;
  display: flex;
  flex-direction: row;
  a {
    margin: 0 1em;
  }

  @media screen and (max-width: ${mediaQueries.medium}) {
    flex-direction: column;
  }
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  grid-column: 1/3;
  width: 80vw;
  margin: 0 auto;
  align-self: flex-start;
  padding-top: 5em;
  button {
    margin: 0.5em 0;
  }

  @media screen and (max-width: ${mediaQueries.medium}) {
    width: 80vw;
    margin: 0 auto;
    margin-top: 1em;
    padding-top: 2em;
  }
`
