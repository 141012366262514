import { FormattedMessage } from "react-intl"

import { useAuth } from "Context/AuthContext/auth-context"
import { useLogout } from "Hooks/API/useUser"
import IconFactory from "UI/IconFactory"
import Button from "UI/Button"
import { Urls } from "Constants/urls"
import MenuLink from "Components/MenuLink"
import { BROCC_SUPPORT } from "Constants/externalLinks"
import { useCallback } from "react"
import * as S from "./Account.styles"

const Account = () => {
  const { logout } = useAuth()
  const { isLoading } = useLogout()

  const handleLogout = useCallback(() => logout(false), [logout])

  return (
    <S.AccountWrap>
      <h1>
        <FormattedMessage
          id="app.mypages.account.mainHeader"
          defaultMessage="Kontoinställningar"
        />
      </h1>

      <MenuLink to={Urls.AccountInfo}>
        <FormattedMessage
          id="app.mypages.account.personalInformation"
          defaultMessage="Kontoinformation "
        />
      </MenuLink>

      <MenuLink to={Urls.AccountKyc}>
        <FormattedMessage
          id="app.mypages.mainNav.kyc"
          defaultMessage="Kundkännedom"
        />
      </MenuLink>

      <MenuLink to={Urls.AccountReports}>
        <FormattedMessage
          id="app.mypages.mainNav.reports"
          defaultMessage="Rapporter"
        />
      </MenuLink>

      <MenuLink target="_blank" rel="noreferrer" href={BROCC_SUPPORT}>
        <FormattedMessage
          id="app.mypages.mainNav.support"
          defaultMessage="Support"
        />
      </MenuLink>

      <MenuLink to={Urls.AccountLanguage}>
        <S.LinkWrap>
          <FormattedMessage id="app.common.language" defaultMessage="Språk" />
          <IconFactory name="language" />
        </S.LinkWrap>
      </MenuLink>

      <S.LogOut>
        <Button
          disabled={isLoading}
          type="button"
          variant="inverse"
          onClick={handleLogout}
        >
          <FormattedMessage
            id="app.mypages.mainNav.logout"
            defaultMessage="Logga ut"
          />
        </Button>
      </S.LogOut>
    </S.AccountWrap>
  )
}

export default Account
