import { FormattedMessage, useIntl } from "react-intl"
import { LoanApplicationForm } from "Interfaces/loanApplicationInterfaces"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { SelectField } from "Components/form/SelectField"
import { TextField } from "Components/form/TextField"
import {
  useDetailMaritalStatusOptions,
  useDetailOccupationOptions,
  useHousingOptions,
  useNumberOfKidsOptions,
} from "Components/LoanApplicationForm/options"
import { SwitchField } from "Components/form/SwitchField"
import { yupResolver } from "@hookform/resolvers/yup"
import Button from "UI/Button"
import { ReactNode } from "react"
import { BROCC_LANDING_INTEGRITY } from "Constants/externalLinks"
import * as S from "../LoanApplication.styles"
import { emptyLoanApplicationData } from "../initialData"
import loanApplicationSchema from "../validations"
import { show } from "../helpers"
import DynamicFields from "./DynamicFields"
import TermsModal from "./TermsModal"
import CalculationExample from "./CalculationExample"
import { ApplicantType } from "../LoanApplication.types"
import { DEFAULT_REPAYMENT_PERIODS } from "../constants"

type FormPropsType = {
  initialFormData?: LoanApplicationForm
  onNext: (data: LoanApplicationForm) => void
  submitText: ReactNode
  amount?: number
  repaymentPeriods?: number
  id?: string
}

const Form = ({
  initialFormData,
  onNext,
  submitText,
  amount,
  repaymentPeriods,
  id,
}: FormPropsType) => {
  const intl = useIntl()

  const getDefaultValues = () => {
    if (amount && repaymentPeriods) {
      return {
        ...emptyLoanApplicationData,
        amount,
        repayment_periods: repaymentPeriods,
      }
    }

    return emptyLoanApplicationData
  }

  const getInitialForm = () => {
    if (initialFormData?.repayment_periods === null) {
      return {
        ...initialFormData,
        repayment_periods: DEFAULT_REPAYMENT_PERIODS,
      }
    }

    return initialFormData
  }

  const form = useForm<LoanApplicationForm>({
    values: initialFormData ? getInitialForm() : getDefaultValues(),
    mode: "onChange",
    resolver: yupResolver(loanApplicationSchema),
  })

  const occupationOptions = useDetailOccupationOptions()
  const housingOptions = useHousingOptions()
  const detailMaritalStatusOptions = useDetailMaritalStatusOptions()
  const numberOfKidsOptions = useNumberOfKidsOptions()

  const applicantHasKids = form.watch("applicant.applicant_detail.has_kids")
  const coApplicantHasKids = form.watch(
    "co_applicant.applicant_detail.has_kids"
  )
  const hasCoApplicant = form.watch("has_coapplicant")
  const housingType = form.watch("applicant.applicant_detail.housing_type")

  const hasKids = (applicantType: ApplicantType) => {
    return (
      (applicantType === ApplicantType.applicant && applicantHasKids) ||
      (applicantType === ApplicantType.coApplicant && coApplicantHasKids)
    )
  }

  const onValid: SubmitHandler<LoanApplicationForm> = (formData) => {
    onNext(formData)
  }

  const applicantFields = (applicantType: ApplicantType) => {
    return (
      <>
        <S.InputGridContainer>
          <label>
            <TextField
              control={form.control}
              name={`${applicantType}.tel`}
              placeholder={
                <FormattedMessage
                  id="app.mypages.loans.application.applicant.tel"
                  defaultMessage="Mobiltelefon"
                />
              }
            />
          </label>
          <label>
            <TextField
              control={form.control}
              name={`${applicantType}.email`}
              placeholder={
                <FormattedMessage
                  id="app.mypages.loans.application.applicant.email"
                  defaultMessage="E-post"
                />
              }
            />
          </label>
          <label>
            <TextField
              control={form.control}
              name={`${applicantType}.legal_entity_number`}
              placeholder={
                <FormattedMessage
                  id="app.mypages.loans.application.applicant.ssn"
                  defaultMessage="Personnummer"
                />
              }
            />
          </label>
          <label>
            <TextField
              control={form.control}
              name={`${applicantType}.applicant_detail.work_income`}
              placeholder={
                <FormattedMessage
                  id="app.mypages.loans.application.applicant.work_income"
                  defaultMessage="Inkomst före skatt (kr/mån)"
                />
              }
            />
          </label>
        </S.InputGridContainer>

        <S.InputContainer>
          <label>
            <SelectField
              control={form.control}
              name={`${applicantType}.applicant_detail.occupation`}
              options={occupationOptions}
              aria-label={intl.formatMessage({
                id: "app.common.occupation",
                defaultMessage: "Sysselsättning",
              })}
            />
          </label>
          <label>
            <SelectField
              control={form.control}
              name={`${applicantType}.applicant_detail.housing_type`}
              options={housingOptions}
              aria-label={intl.formatMessage({
                id: "app.common.housingType",
                defaultMessage: "Boendeform",
              })}
            />
          </label>
          {housingType && (
            <label>
              <TextField
                control={form.control}
                name={`${applicantType}.applicant_detail.housing_cost`}
                placeholder={
                  <FormattedMessage
                    id="app.mypages.loans.application.applicant.housing_cost"
                    defaultMessage="Boendekostnad (kr/mån)"
                  />
                }
              />
            </label>
          )}
          <label>
            <SelectField
              control={form.control}
              name={`${applicantType}.applicant_detail.marital_status`}
              options={detailMaritalStatusOptions}
              aria-label={intl.formatMessage({
                id: "app.common.maritalStatus",
                defaultMessage: "Civilstånd",
              })}
            />
          </label>
        </S.InputContainer>
        <S.ToggleContainer>
          <S.ToggleLabel>
            <FormattedMessage
              id="app.mypages.loans.application.applicant.has_kids"
              defaultMessage="Har du barn under 18 år?"
            />
            <SwitchField
              control={form.control}
              name={`${applicantType}.applicant_detail.has_kids`}
              labelOff={
                <FormattedMessage id="app.common.no" defaultMessage="Nej" />
              }
              labelOn={
                <FormattedMessage id="app.common.yes" defaultMessage="Ja" />
              }
            />
          </S.ToggleLabel>
        </S.ToggleContainer>

        {hasKids(applicantType) && (
          <S.InputContainer>
            <label>
              <SelectField
                control={form.control}
                name={`${applicantType}.applicant_detail.number_of_kids`}
                options={numberOfKidsOptions}
                aria-label={intl.formatMessage({
                  id: "app.common.numberOfKids",
                  defaultMessage: "Antal barn under 18 år",
                })}
              />
            </label>
          </S.InputContainer>
        )}
      </>
    )
  }

  return (
    <FormProvider {...form}>
      <S.FormContainer onSubmit={form.handleSubmit(onValid)}>
        {id && <DynamicFields initialFormData={initialFormData} form={form} />}
        {applicantFields(ApplicantType.applicant)}

        {show(initialFormData?.has_coapplicant) && (
          <S.ToggleContainer>
            <S.ToggleLabel>
              <FormattedMessage
                id="app.mypages.loans.application.has_coapplicant"
                defaultMessage="Medsökande?"
              />
              <SwitchField
                control={form.control}
                name="has_coapplicant"
                labelOff={
                  <FormattedMessage id="app.common.no" defaultMessage="Nej" />
                }
                labelOn={
                  <FormattedMessage id="app.common.yes" defaultMessage="Ja" />
                }
              />
            </S.ToggleLabel>
          </S.ToggleContainer>
        )}

        {hasCoApplicant && applicantFields(ApplicantType.coApplicant)}
        <S.Terms>
          <FormattedMessage
            id="app.mypages.loans.application.integrity.left"
            defaultMessage="Genom att klicka på 'Gå vidare med ansökan' bekräftar jag att jag svarat korrekt på alla frågor. Jag har även tagit del av Broccs"
          />
          <a
            href={BROCC_LANDING_INTEGRITY}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id="app.mypages.loans.application.integrity.link"
              defaultMessage="integritetspolicy"
            />
          </a>
          <FormattedMessage
            id="app.mypages.loans.application.integrity.right"
            defaultMessage=" och förstår att Brocc kommer att hantera mina personuppgifter i enlighet med denna information. Jag godkänner även Broccs "
          />
          <TermsModal />.
        </S.Terms>
        <S.SubmitButtonContainer>
          <Button type="submit">{submitText}</Button>
        </S.SubmitButtonContainer>
        <CalculationExample source={initialFormData?.source} />
      </S.FormContainer>
    </FormProvider>
  )
}

export default Form
