import styled from "styled-components"

import { font, mediaQueries } from "Styles/styleGlobal"

export const LoginSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 32px;
  flex: 1;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 78px;

  &.selected-method {
    text-align: center;

    .icon-bankId,
    .qr-code {
      align-self: center;
    }
  }

  .error-message {
    margin: 0;
    font-size: ${font.size.xs};
    color: var(--brand-danger);
    text-align: center;
  }

  .button-bankId {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  h1 {
    font-size: ${font.size.xl};
    margin: 0;
  }

  p {
    margin: 0;
  }

  @media screen and (min-width: ${mediaQueries.medium}) {
    max-width: 400px;
    width: 100%;

    h1 {
      font-size: ${font.size.xl5};
      margin: 0;
    }
  }
`

export const InformationBannerWrap = styled.div`
  @media screen and (min-width: ${mediaQueries.medium}) {
    max-width: 400px;
  }
`

export const TempWelcomeMessage = styled.div`
  padding: 2em 1.5em;
  background-color: var(--very-light-gray);
  border-radius: 16px;

  h6 {
    margin: 0 0 1em;
    font-size: ${font.size.lg};
    font-weight: 700;
  }

  p {
    margin: 0;
    font-weight: 500;
  }

  a {
    color: var(--dark-yellow);
  }
`

export const MethodsBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
