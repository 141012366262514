import { FC, useCallback, useState } from "react"
import { useParams } from "react-router-dom"
import { useQueryClient } from "react-query"
import { FormattedMessage } from "react-intl"
import { AnimatePresence } from "framer-motion"

import { BalanceItemProps } from "Components/BalanceOverview/BalanceOverview.types"
import BalanceOverview from "Components/BalanceOverview"
import { IDepositService } from "Interfaces/depositInterfaces"
import Drawer from "UI/Drawer"
import Button from "UI/Button"
import Modal from "UI/Modal"
import { DEPOSIT_SERVICES_SERVICE } from "Constants/queryKeys"
import * as S from "./SavingsOverview.styles"
import SavingsDeposit from "../../Deposit/SavingsDeposit"
import SavingsWithdrawal from "../../Withdrawal"

const SavingsOverview: FC = () => {
  const { accountID } = useParams()
  const queryClient = useQueryClient()
  const [showDepositDrawer, setShowDepositDrawer] = useState(false)
  const [showWithdrawalDrawer, setShowWithdrawalDrawer] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)

  const openDepositDrawer = () => {
    setShowDepositDrawer(true)
  }
  const openWithdrawalDrawer = () => {
    setShowWithdrawalDrawer(true)
  }

  const closeDrawer = useCallback(() => {
    if (showDepositDrawer) {
      setShowDepositDrawer(false)
    }
    if (showWithdrawalDrawer) {
      setShowWithdrawalDrawer(false)
    }
  }, [showDepositDrawer, showWithdrawalDrawer])

  const openInfoModal = () => {
    setShowInfoModal(true)
  }
  const closeInfoModal = () => {
    setShowInfoModal(false)
  }

  const deposit = queryClient.getQueryData<IDepositService>([
    DEPOSIT_SERVICES_SERVICE,
    accountID,
  ])!

  const depositHandler = () => {
    if (!deposit.is_open_for_deposits) {
      openInfoModal()
    } else {
      openDepositDrawer()
    }
  }

  const data: BalanceItemProps[] = [
    {
      text: {
        id: "app.mypages.deposits.home.balanceOverview.accruedInterest",
        defaultMessage: "Upplupen ränta",
      },
      value: deposit?.accrued_interest,
      style: "currency",
    },
    {
      text: {
        id: "app.mypages.deposits.home.balanceOverview.currentInterestRate",
        defaultMessage: "Aktuell ränta",
      },
      value: deposit?.interest_rate,
      style: "percent",
    },
    {
      text: {
        id: "app.mypages.deposits.home.balanceOverview.accountBalance",
        defaultMessage: "Kontobalans",
      },
      value: deposit?.current_balance,
      style: "currency",
    },
  ]

  return (
    <S.SavingsOverviewWrap>
      {deposit && (
        <S.SavingsOverviewBody>
          <BalanceOverview data={data} />
        </S.SavingsOverviewBody>
      )}
      <S.ButtonWrap>
        <Button type="button" onClick={depositHandler}>
          <FormattedMessage
            id="app.common.deposit"
            defaultMessage="Insättning"
          />
        </Button>
        <Button type="button" variant="inverse" onClick={openWithdrawalDrawer}>
          <FormattedMessage
            id="app.common.transfer"
            defaultMessage="Överföring"
          />
        </Button>
      </S.ButtonWrap>
      <Drawer
        isOpen={showWithdrawalDrawer || showDepositDrawer}
        handleClose={closeDrawer}
        disableBackdropClick
        closeBtn
      >
        {showDepositDrawer && <SavingsDeposit closeDrawer={closeDrawer} />}
        {showWithdrawalDrawer && (
          <SavingsWithdrawal closeDrawer={closeDrawer} />
        )}
      </Drawer>
      <AnimatePresence>
        {showInfoModal && (
          <Modal
            onClick={closeInfoModal}
            body={
              <S.SavingsModalBody>
                <p>
                  <FormattedMessage
                    id="app.mypages.deposits.home.closedForDepositModal.message"
                    values={{ lockUntilDate: deposit.term_end_date }}
                  />
                </p>
                <Button type="button" onClick={closeInfoModal}>
                  <FormattedMessage id="app.common.ok" defaultMessage="Ok" />
                </Button>
              </S.SavingsModalBody>
            }
          />
        )}
      </AnimatePresence>
    </S.SavingsOverviewWrap>
  )
}

export default SavingsOverview
