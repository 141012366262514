import { FC, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import CustomFormattedDate from "Components/CustomFormattedDate"
import IconFactory from "UI/IconFactory"
import Loading from "UI/Loading"
import { IDepositTransaction } from "Interfaces/depositInterfaces"
import { formatNumber } from "Utils/formatNumber"
import { titleTypes } from "./typeTitles"
import { TransactionsTablePropTypes } from "./TransactionsTable.types"
import * as S from "./TransactionsTable.styles"

const TransactionsTable: FC<TransactionsTablePropTypes> = ({
  transactions,
}) => {
  const intl = useIntl()
  const list = useMemo(
    () =>
      transactions.data
        ? transactions.data.pages.flatMap<IDepositTransaction>(
            (page) => page.data
          )
        : [],
    [transactions]
  )

  if (transactions.isError) {
    return (
      <FormattedMessage
        id="app.mypages.error.depositServices.useGetDepositServiceTransactions"
        defaultMessage="Det går inte att få fram listan över transaktioner. Försök igen"
      />
    )
  }

  return (
    <S.TransactionsOverview>
      <S.TransactionsOverviewHeader>
        <h3>
          <FormattedMessage
            id="app.mypages.investments.home.balanceOverview.transactionsOverview.latestTransactions.title"
            defaultMessage="Senaste transaktioner"
          />
        </h3>
      </S.TransactionsOverviewHeader>
      <div data-testid="transactions">
        {list.length > 0 ? (
          list.map((transaction, index) => {
            const transactionName = transaction.transaction_name
            const type = titleTypes[transactionName]
            return (
              <S.TransactionsTableRow key={index + transactionName}>
                <S.TransactionsTableTypeDate>
                  <IconFactory name={type?.icon || "question"} />
                  <div>
                    {intl.formatMessage(
                      type?.text || {
                        id: "app.common.unknown",
                        defaultMessage: "Okänd",
                      }
                    )}
                    <br />
                    <span>
                      <CustomFormattedDate
                        value={transaction.reference_datetime.replace(
                          /-/g,
                          "/"
                        )}
                      />
                    </span>
                  </div>
                </S.TransactionsTableTypeDate>
                <S.TransactionsTableAmount>
                  {formatNumber(transaction.amount, {
                    style: "currency",
                    roundingMode: "trunc",
                  })}
                </S.TransactionsTableAmount>
              </S.TransactionsTableRow>
            )
          })
        ) : (
          <S.EmptyTransactionWrap>
            <FormattedMessage
              id="app.mypages.loans.transactions.table.noData"
              defaultMessage="Det finns inga transaktioner att visa. Gör en insättning för att komma igång! 🎉"
            />
          </S.EmptyTransactionWrap>
        )}
      </div>
      {transactions.isFetching && (
        <S.LoadingWrap>
          <Loading isCentered />
        </S.LoadingWrap>
      )}
    </S.TransactionsOverview>
  )
}

export default TransactionsTable
