import { useState, useReducer, useEffect } from "react"
import { useParams } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { AnimatePresence } from "framer-motion"
import { v4 as uuidv4 } from "uuid"

import { Actions, initialState, reducer } from "Store/Iceing/Loans/Reducers"
import Button from "UI/Button"
import Drawer from "UI/Drawer"
import Modal from "UI/Modal/Modal"
import {
  useCollectLoans,
  useUpdateDocumentation,
  useGetLoanApplication,
} from "Hooks/API/useLoansApplications"
import { useIceingBanks } from "Hooks/API/useIceing"
import useScrapingWebSocket from "Hooks/API/useScrapingWebSocket"
import Loading from "UI/Loading/Loading"
import { ICING_EVENTS } from "Events/List"
import {
  ErrorType,
  LoanBank,
  LoanCollectionError,
} from "Interfaces/iceingInterfaces"
import { LoanCollection as LoanCollectionSchema } from "ApiServices/loanApplications"
import IconFactory from "UI/IconFactory/IconFactory"
import { publish } from "../../Events"
import GenericError from "../../UI/GenericError/GenericError"
import { getLoanByType, transformExistingLoans } from "./Utils"
import Information from "./UI/Information/Information"
import { SelectedLoans } from "./LoansList/LoanItem/LoanItem.types"
import BanksList from "./Banks"
import LoansList from "./LoansList"
import SelectedBank from "./Login/SelectedBank/SelectedBank"
import InformationOfCosts from "./UI/Information/InformationOfCosts/InformationOfCosts"
import * as S from "./LoanCollection.styles"
import * as Styles from "../Unauthenticated/Onboarding/Onboarding.styles"
import SubmitLoansBody from "./UI/SubmitLoans/SubmitLoansBody"

const LoanCollection = () => {
  const [isManualForm, setIsManualForm] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isModalOpen, setisModalOpen] = useState(false)
  const [isLoginIn, setIsLoginIn] = useState(false)
  const [isSubmissionError, setIsSubmissionError] = useState(false)
  const [uuidSession, setUuidSession] = useState("")
  const [selectedBank, setSelectedBank] = useState<LoanBank>({
    bankId: 0,
    bankName: "",
    loginStrategies: [],
  })
  const [state, dispatch] = useReducer(reducer, initialState)
  const { id } = useParams() as { id: string }
  const intl = useIntl()

  const { data } = useGetLoanApplication(id)

  const { data: banks } = useIceingBanks()
  const {
    autostarttoken,
    loans: fetchedLoans,
    isError: isLoanCollectionError,
    loanError: loanCollectionError,
    isLoading,
    isLoggedIn,
    reset,
  } = useScrapingWebSocket({
    isLoginIn,
    bankName: selectedBank.bankName,
    bankId: selectedBank.bankId || 0,
    firstName: data?.applicant.first_name || "",
    lastName: data?.applicant.last_name || "",
    ssn: data?.applicant.legal_entity_number || "",
    uuid: uuidSession,
  })
  const collectionMutation = useCollectLoans(id)

  const updateDocumentMutation = useUpdateDocumentation(id)

  const isLoginMode = () => selectedBank.bankName !== ""
  const { loans, installments, creditCards } = fetchedLoans

  const handleReset = () => {
    setSelectedBank({ bankId: 0, bankName: "", loginStrategies: [] })
    setIsOpen(false)
    setIsLoginIn(false)
    setIsManualForm(false)
    reset()
  }

  const handleOpenDrawer = () => {
    publish(ICING_EVENTS.CLICK_COLLECT_LOANS_BUTTON, { standalone: false })
    setIsOpen(true)
  }

  const handleOpenModal = () => {
    publish(ICING_EVENTS.CLICK_CONTINUE_APPLICATION, { standalone: false })
    setisModalOpen(true)
  }
  const handleCloseModal = () => {
    if (isSubmissionError) {
      dispatch({ type: Actions.CLEAN_INITIAL_LOANS })
      dispatch({ type: Actions.CLEAN_SELECTED_LOANS })
    }
    setIsSubmissionError(false)
    setisModalOpen(false)
    setIsManualForm(false)
  }

  const handleSelectedBank = (bank: {
    bankId: number
    bankName: string
    loginStrategies?: string[]
  }) => {
    setSelectedBank({ ...bank })
  }

  const handleIsLoginIn = (loginIn: boolean) => {
    setIsLoginIn(loginIn)
    setUuidSession(uuidv4())
  }

  const handleUnsuccessfulLogin = () => {
    reset()
    setIsManualForm(true)
  }

  const handleAddLoans = (loansToDisplay: SelectedLoans[]) => {
    dispatch({
      type: Actions.ADD_LOAN_TO_DISPLAY,
      payload: { loans: loansToDisplay },
    })
    handleReset()
  }

  const handleRemoveLoan = (removeLoanId: string) => {
    dispatch({
      type: Actions.REMOVE_LOAN_TO_DISPLAY,
      payload: { id: removeLoanId },
    })
  }

  const handleShowLoans = () => {
    dispatch({ type: Actions.CLEAN_INITIAL_LOANS })
    handleReset()
  }

  const handleUploadFiles = (files: File[]) => {
    dispatch({ type: Actions.ADD_DOCUMENTATION, payload: { files } })
  }

  const handleSubmitLoans = async () => {
    publish(ICING_EVENTS.CLICK_CONFIRM_CONTINUE_APPLICATION, {
      standalone: false,
    })
    const existingLoans: LoanCollectionSchema[] = state.selectedLoans.map(
      transformExistingLoans
    )

    await collectionMutation
      .mutateAsync({
        loansCollected: existingLoans,
      })
      .then(() => {
        if (state.files.length > 0) {
          updateDocumentMutation
            .mutateAsync({
              documentation: {
                files: [...state.files],
                activity: "require_existing_loan_redemption",
              },
            })
            .catch(() => {
              setIsSubmissionError(true)
            })
        }
      })
      .catch(() => {
        setIsSubmissionError(true)
      })
  }

  useEffect(() => {
    if (fetchedLoans && !isLoading) {
      const initialLoansFetched = getLoanByType(loans, "loan")
      const installmentsFetched = getLoanByType(installments, "installment")
      const creditCardsFetched = getLoanByType(creditCards, "credit")
      dispatch({
        type: Actions.ADD_INITIAL_LOANS,
        payload: {
          initialLoans: [
            ...initialLoansFetched,
            ...installmentsFetched,
            ...creditCardsFetched,
          ],
          bankName: selectedBank.bankName,
        },
      })
    }
  }, [
    isLoading,
    fetchedLoans,
    loans,
    creditCards,
    installments,
    selectedBank.bankName,
  ])

  useEffect(() => {
    publish(ICING_EVENTS.ICEING_LANDED, { standalone: false })
  }, [])

  useEffect(() => {
    if (!isOpen) {
      dispatch({ type: Actions.CLEAN_INITIAL_LOANS })
    }
  }, [isOpen])

  const getLoanCollectionError = (error: LoanCollectionError | undefined) => {
    switch (error?.type) {
      case ErrorType.ActionNeeded:
        return (
          <GenericError
            idMessages={{
              header: "app.common.iceing.error.action_needed.header",
              body: "app.common.iceing.error.action_needed.body",
              button: "app.common.iceing.error.general.button",
            }}
            onClick={handleUnsuccessfulLogin}
          />
        )
      default:
        return (
          <GenericError
            idMessages={{
              header: "app.common.iceing.error.general.header",
              body: "app.common.iceing.error.general.body",
              button: "app.common.iceing.error.general.button",
            }}
            onClick={handleUnsuccessfulLogin}
          />
        )
    }
  }

  let drawerContent

  if (isLoanCollectionError) {
    publish(ICING_EVENTS.UNSUCCESSFUL_LOGIN, {
      bankName: selectedBank.bankName,
      standalone: false,
    })

    drawerContent = getLoanCollectionError(loanCollectionError)
  } else if (state.initialLoans.length > 0 && !isLoading) {
    drawerContent = (
      <>
        <Information
          content={{
            h3: "app.mypages.iceing.information.collected",
            p: "app.mypages.iceing.loans.found",
          }}
          style={{ p: { color: "gray", margin: "0" } }}
        />
        <LoansList
          loans={state.initialLoans}
          onAddLoan={handleAddLoans}
          onShowLoans={handleShowLoans}
          showAddButton
        />
      </>
    )
  } else if (!isLoginMode()) {
    drawerContent = (
      <>
        <Information
          content={{
            h3: "app.mypages.iceing.choose.your.bank",
            p: "app.mypages.iceing.get.your.loans.and.credits",
          }}
          style={{ p: { color: "gray", margin: "0" } }}
        />
        <BanksList banks={banks} onSelectedBank={handleSelectedBank} />
      </>
    )
  } else {
    drawerContent = (
      <SelectedBank
        bankName={selectedBank.bankName}
        loginStrategies={selectedBank.loginStrategies}
        autostarttoken={autostarttoken}
        isLoading={isLoading}
        isLoggedIn={isLoggedIn}
        isManualForm={isManualForm}
        onLoginSelectedBank={handleIsLoginIn}
        onReset={handleReset}
        onAddLoan={handleAddLoans}
        onUploadFiles={handleUploadFiles}
      />
    )
  }

  let modalBodyContainer

  if (isSubmissionError) {
    modalBodyContainer = <GenericError onClick={handleCloseModal} />
  } else {
    modalBodyContainer =
      collectionMutation.isLoading || updateDocumentMutation.isLoading ? (
        <Loading isCentered />
      ) : (
        <SubmitLoansBody
          onCloseModal={handleCloseModal}
          onSubmitLoans={handleSubmitLoans}
        />
      )
  }

  return (
    <Styles.Container>
      <S.IcingUsageMessage>
        <IconFactory name="moneyBag" className="moneyBag" />
        <p>
          <FormattedMessage
            values={{
              speed: (
                <strong>
                  <FormattedMessage id="app.common.iceing.usage.message.speed" />
                </strong>
              ),
            }}
            id="app.common.iceing.usage.message"
          />
        </p>
      </S.IcingUsageMessage>
      <S.CollectYourLoansBlock>
        <div>
          <h2>
            <FormattedMessage id="app.mypages.iceing.find.loans" />
          </h2>
          <p>
            <FormattedMessage id="app.mypages.iceing.find.loans.bank.list" />
          </p>
          {state.selectedLoans.length === 0 && (
            <Button type="button" onClick={handleOpenDrawer}>
              <FormattedMessage id="app.mypages.iceing.collect.loans.button" />
            </Button>
          )}
        </div>
        {state.selectedLoans.length > 0 && (
          <>
            <h3>
              <FormattedMessage id="app.mypages.iceing.collected.loans" />
            </h3>
            <LoansList
              loans={state.selectedLoans}
              onAddLoan={handleAddLoans}
              onRemoveLoan={handleRemoveLoan}
              isMainInterface
            />
            <InformationOfCosts
              monthlyCosts={state.monthlyCosts}
              totalAmountCosts={state.totalAmountCosts}
              periodCost={data?.period_cost}
              amount={data?.amount}
            />
            <S.SelectedLoansButtonsContainer>
              <Button
                data-cy="loansMainInterfaceButton"
                variant="inverse"
                onClick={handleOpenDrawer}
              >
                <FormattedMessage id="app.mypages.iceing.collect.loans.main.interface.button" />
              </Button>
              <Button
                data-cy="loansMainInterfaceSubmit"
                onClick={handleOpenModal}
              >
                <FormattedMessage id="app.mypages.iceing.collect.loans.main.interface.submit" />
              </Button>
            </S.SelectedLoansButtonsContainer>
          </>
        )}
      </S.CollectYourLoansBlock>
      <Drawer
        isOpen={isOpen}
        handleClose={handleReset}
        closeBtn
        disableBackdropClick
      >
        {drawerContent}
      </Drawer>
      <AnimatePresence>
        {isModalOpen && (
          <Modal
            title={intl.formatMessage({
              id: "app.mypages.iceing.submit.application.title",
            })}
            body={modalBodyContainer}
            onClick={handleCloseModal}
          />
        )}
      </AnimatePresence>
    </Styles.Container>
  )
}

export default LoanCollection
