import { forwardRef, useState } from "react"

import { SwitchProps } from "./Switch.types"
import * as S from "./Switch.styles"

const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ checked, onChange, labelOff, labelOn, defaultChecked, ...props }, ref) => {
    const [initiallyChecked, setInitiallyChecked] = useState(defaultChecked)

    const onChangeDefault = () =>
      setInitiallyChecked((prevChecked) => !prevChecked)

    return (
      <S.SwitchBox>
        {labelOff && <S.SwitchLabelText>{labelOff}</S.SwitchLabelText>}
        <S.SwitchLabel data-testid="switch">
          <S.SwitchBase>
            <S.SwitchCheckbox
              {...props}
              ref={ref}
              type="checkbox"
              checked={checked ?? initiallyChecked}
              onChange={onChange || onChangeDefault}
              defaultChecked={defaultChecked}
            />
            <S.SwitchThumb />
          </S.SwitchBase>
          <S.SwitchTrack checked={checked ?? initiallyChecked} />
        </S.SwitchLabel>
        {labelOn && <S.SwitchLabelText>{labelOn}</S.SwitchLabelText>}
      </S.SwitchBox>
    )
  }
)

Switch.displayName = "Switch"

export default Switch
