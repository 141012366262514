import { FormattedMessage } from "react-intl"

import { useSignSavingsAgreement } from "Hooks/API/useDepositServices"
import Modal from "UI/Modal"
import { isMobile } from "Utils"
import { SignFlow } from "Components/BankIDAuth/SignFlow/SignFlow"
import { SignFlowMobile } from "Components/BankIDAuth/SignFlowMobile/SignFlowMobile"

export const SignSavingsAgreementModal = ({
  accountID,
  onClose,
}: {
  accountID: string
  onClose: () => void
}) => {
  const signProcess = useSignSavingsAgreement(accountID)

  const handleCloseModal = () => {
    signProcess.reset()
    onClose()
  }

  let modalBodyJsx = (
    <SignFlow
      qrCode={signProcess.qrCode}
      autostartToken={signProcess.autostartToken}
      isSigning={signProcess.isSigning}
      isError={signProcess.isError}
      errorMessage={signProcess.errorMessage}
      isLoading={signProcess.isLoading}
      startSigning={signProcess.startSigning}
      cancelSigning={signProcess.cancelSigning}
    />
  )

  if (isMobile()) {
    modalBodyJsx = (
      <SignFlowMobile
        qrCode={signProcess.qrCode}
        autostartToken={signProcess.autostartToken}
        isLoading={signProcess.isLoading}
        isSigning={signProcess.isSigning}
        isError={signProcess.isError}
        errorMessage={signProcess.errorMessage}
        startSigning={signProcess.startSigning}
        cancelSigning={signProcess.cancelSigning}
      />
    )
  }

  return (
    <Modal
      maxWidth="600px"
      title={
        <FormattedMessage
          id="app.common.signWithBankID"
          defaultMessage="Signera med BankID"
        />
      }
      onClick={handleCloseModal}
      body={modalBodyJsx}
    />
  )
}
