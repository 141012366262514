import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"
import { Badge } from "UI/InfoBadge/InfoBadge.styles"

export const WithdrawalsBody = styled.div`
  display: flex;
  flex-direction: column;
`

export const WithdrawalsTitle = styled.h3`
  margin: 0;
  padding: 0 0 0.5em em 0;
  font-size: 1.25em;
  margin-bottom: 24px;
`

export const WithdrawalsItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
`

export const WithdrawalsItemubtitle = styled.span`
  font-size: ${font.size.base};
  color: var(--dark-gray);
`
export const WithdrawalsItemTitle = styled.h2`
  margin: 0;
  font-size: ${font.size.xl3};
`

export const ButtonWrap = styled.div`
  margin-top: 1.5em;
`

export const InputWrap = styled.div`
  position: relative;
  ${Badge} {
    position: absolute;
    top: 30px;
    right: 10px;
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    width: 100%;
  }
`
export const WithdrawalsHeader = styled.h1``

export const WithdrawalsTo = styled.span`
  margin: 0.2em 0 0.8em;
`

export const WithdrawalsBankItem = styled.div`
  border-radius: 6px;
  background-color: var(--very-light-gray);
  padding: 0.8em;
  @media screen and (max-width: ${mediaQueries.large}) {
    width: 100%;
  }
`
export const WithdrawalsInfoBox = styled.div`
  p {
    margin: 0;
  }
  button {
    margin-top: 1.5em;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    width: 100%;
  }
`

export const ConfirmWithdrawalModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: 32px;
`

export const ConfirmationModalMessage = styled.span`
  text-align: center;
  white-space: pre-line;
`

export const ConfirmWithdrawalButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-bottom: 2em;
`

export const ConfirmationMessage = styled.p`
  font-size: 1rem;
`
