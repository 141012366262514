import yup from "config/yup"
import { PaymentFreeMonthPayload, ReasonType } from "./PaymentFreeModal.types"

export const PaymentFreeSchema: yup.SchemaOf<PaymentFreeMonthPayload> = yup
  .object()
  .shape({
    reason: yup
      .string()
      .oneOf([
        "VAB",
        "lower_salary",
        "retirement",
        "sickness",
        "unemployed",
        "unforeseen_expenses",
        "others",
      ])
      .required(),
    comments: yup.string().when("reason", {
      is: (reason: ReasonType) => reason === "others",
      then: yup.string().required(),
    }),
  })
