import { FC, ReactNode } from "react"
import { FormattedMessage } from "react-intl"

import Button from "UI/Button/Button"
import { MessageId } from "Constants/languages"
import * as S from "./GenericError.styles"

interface GenericErrorProps {
  onClick: () => void
  idMessages?: {
    header?: MessageId
    body?: MessageId
    button?: MessageId
  }
  values?: {
    header?: Record<string, ReactNode>
    body?: Record<string, ReactNode>
    button?: Record<string, ReactNode>
  }
}

const GenericError: FC<GenericErrorProps> = ({
  onClick,
  idMessages,
  values,
}) => {
  return (
    <S.GenericErrorWrapper>
      <S.ErrorHeader>
        <FormattedMessage
          id={idMessages?.header || "app.common.error.header"}
          values={values?.header}
        />
      </S.ErrorHeader>
      <S.ErrorBody>
        <FormattedMessage
          id={idMessages?.body || "app.common.error.body"}
          values={values?.body}
        />
      </S.ErrorBody>
      <Button type="button" variant="inverse" onClick={onClick}>
        <FormattedMessage
          id={idMessages?.button || "app.common.error.button"}
          values={values?.button}
        />
      </Button>
    </S.GenericErrorWrapper>
  )
}

export default GenericError
