import { useState, FC, useCallback } from "react"
import { useParams } from "react-router-dom"
import { FormattedMessage } from "react-intl"

import {
  useAcceptLoanApplication,
  useStoreKYCLoanApplication,
} from "Hooks/API/useLoansApplications"
import CustomFormattedDate from "Components/CustomFormattedDate"
import { useFeedbackModal } from "Context/modal-context"
import RepaymentPeriods from "Components/RepaymentPeriods"
import { formatNumber } from "Utils/formatNumber"
import { StepPropsType } from "../../Onboarding.types"
import { Container, MobileAttentionBox, GrayBox } from "../../Onboarding.styles"
import LoanPerksBox from "../../Shared/LoanPerksBox"
import AMLQuestions from "./AMLQuestions"
import type { IAMLQuestionsRequest } from "./AMLQuestions/AMLQuestions.types"
import * as S from "./Accept.styles"

const Accept: FC<StepPropsType> = ({ data }) => {
  const { id } = useParams() as { id: string }
  const [showDetails, setShowDetails] = useState(false)
  const acceptLoanApplicationMutation = useAcceptLoanApplication(id)
  const createKYCMutation = useStoreKYCLoanApplication(id)
  const { errorModal } = useFeedbackModal()

  const toggleDetails = () => setShowDetails(!showDetails)

  const handleAcceptOffer = useCallback(
    (values: IAMLQuestionsRequest) => {
      createKYCMutation
        .mutateAsync(values)
        .then(() => {
          acceptLoanApplicationMutation.mutateAsync()
        })
        .catch(() => {
          errorModal()
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  )

  return (
    <Container>
      <div className="inner">
        <S.WelcomeHeading>
          <FormattedMessage
            id="app.onboarding.accept.heading.greeting"
            values={{ name: data.applicant.first_name }}
            defaultMessage="Hej {name},"
          />
          <br />
          <FormattedMessage
            id="app.onboarding.accept.heading.offer"
            defaultMessage="här är ditt låneerbjudande"
          />
        </S.WelcomeHeading>
        <GrayBox>
          <S.AmountTitle id="amount">
            <FormattedMessage id="app.common.amount" defaultMessage="Belopp" />
          </S.AmountTitle>
          <S.Amount aria-labelledby="amount">
            {formatNumber(data.amount, {
              style: "currency",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </S.Amount>
        </GrayBox>

        <S.Overview>
          <S.OverviewItem>
            <S.OverviewTitle id="monthly-cost">
              <FormattedMessage
                id="app.common.monthlyCost"
                defaultMessage="Månadskostnad"
              />
            </S.OverviewTitle>
            <S.OverviewFigure aria-labelledby="monthly-cost">
              {formatNumber(data.period_cost, {
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </S.OverviewFigure>
          </S.OverviewItem>
          <S.OverviewItem>
            <S.OverviewTitle id="loan-period">
              <FormattedMessage
                id="app.common.loanPeriod"
                defaultMessage="Återbetalningstid"
              />
            </S.OverviewTitle>
            <S.OverviewFigure aria-labelledby="loan-period">
              <RepaymentPeriods repaymentPeriods={data.repayment_periods} />
            </S.OverviewFigure>
          </S.OverviewItem>
          <S.OverviewItem>
            <S.OverviewTitle id="interest-rate">
              <FormattedMessage
                id="app.common.effectiveInterest"
                defaultMessage="Effektiv ränta"
              />
            </S.OverviewTitle>
            <S.OverviewFigure aria-labelledby="interest-rate">
              {formatNumber(+data.effective_interest_rate, {
                style: "percent",
              })}
            </S.OverviewFigure>
          </S.OverviewItem>
        </S.Overview>
        <hr />
        <S.DetailsWrap>
          <span onClick={toggleDetails} aria-hidden="true">
            {showDetails ? (
              <FormattedMessage
                id="app.onboarding.accept.details.title.hide"
                defaultMessage="Göm detaljer"
              />
            ) : (
              <FormattedMessage
                id="app.onboarding.accept.details.title"
                defaultMessage="Visa detaljer"
              />
            )}
          </span>
          <S.DetailsMessage show={showDetails}>
            <FormattedMessage
              id="app.onboarding.accept.details.body"
              values={{
                date: <CustomFormattedDate value={data.expiry_date} />,
                effectiveInterestRate: formatNumber(
                  +data.effective_interest_rate,
                  { style: "percent" }
                ),
                loanApplicationFee: formatNumber(data.loan_application_fee, {
                  style: "currency",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
                invoiceAdminFee: formatNumber(data.invoice_admin_fee, {
                  style: "currency",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
              }}
              defaultMessage="Erbjudandet gäller till {date}. Effektiv ränta är {effectiveInterestRate}. Den effektiva räntan inkluderar {loanApplicationFee} uppläggningsavgift samt {invoiceAdminFee} i administrationskostnad per månad."
            />
          </S.DetailsMessage>
          {showDetails && <hr />}
        </S.DetailsWrap>
        <AMLQuestions
          handleAcceptOffer={handleAcceptOffer}
          isSubmitting={
            createKYCMutation.isLoading ||
            acceptLoanApplicationMutation.isLoading
          }
        />
      </div>
      <MobileAttentionBox>
        <LoanPerksBox estimatedCashBack={data.estimated_cashback} />
      </MobileAttentionBox>
    </Container>
  )
}

export default Accept
