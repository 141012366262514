import { FormikProps } from "formik"
import { defaultValues } from "Pages/Authenticated/Loans/ApplyForLoan/data"
import { defaultValues as defaultCollectData } from "Pages/Authenticated/Loans/CollectLoans/data"
import { FC, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import Button from "UI/Button"
import Checkbox from "UI/Checkbox"
import Input from "UI/Input"
import Select from "UI/Select"
import { BROCC_LANDING } from "Constants/externalLinks"
import * as Styled from "./LoanApplicationForm.styles"
import {
  useDetailMaritalStatusOptions,
  useNumberOfKidsOptions,
  useHousingOptions,
  useDetailOccupationOptions,
} from "./options"

type LoanApplicationFormPropTypes = {
  disabled?: boolean
  formik:
    | FormikProps<typeof defaultValues>
    | FormikProps<typeof defaultCollectData>
  handleChangeToNumber: (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => void
}

const LoanApplicationForm: FC<LoanApplicationFormPropTypes> = ({
  formik,
  disabled,
  handleChangeToNumber,
}) => {
  const [acceptTandC, setAcceptTandC] = useState(false)
  const { handleChange, values, errors } = formik

  const intl = useIntl()

  const housingOptions = useHousingOptions()
  const detailMaritalStatusOptions = useDetailMaritalStatusOptions()
  const detailOccupationOptions = useDetailOccupationOptions()
  const numberOfKidsOptions = useNumberOfKidsOptions()

  return (
    <>
      <Styled.InputsWrap>
        <Input
          type="number"
          name="applicant.applicant_detail.work_income"
          onChange={handleChange}
          value={
            values.applicant.applicant_detail.work_income === 0
              ? ""
              : values.applicant.applicant_detail.work_income
          }
          pattern="\d*"
          inputMode="numeric"
          placeholder={
            <FormattedMessage
              id="app.common.incomeBeforeTax"
              defaultMessage="Inkomst före skatt"
            />
          }
          controlledByFormik
          error={!!errors.applicant?.applicant_detail?.work_income}
          message={errors.applicant?.applicant_detail?.work_income}
        />
        <label>
          <Select
            value={values.applicant.applicant_detail.occupation}
            options={detailOccupationOptions}
            aria-label={intl.formatMessage({
              id: "app.common.occupation",
              defaultMessage: "Sysselsättning",
            })}
            onChange={handleChange}
            name="applicant.applicant_detail.occupation"
            error={!!errors.applicant?.applicant_detail?.occupation}
            message={errors.applicant?.applicant_detail?.occupation}
          />
        </label>
        <Styled.EmploymentWrap>
          <Input
            type="number"
            name="applicant.applicant_detail.occupation_from_month"
            onChange={handleChangeToNumber}
            placeholder={
              <FormattedMessage
                id="app.mypages.loans.applyForLoan.occupation_from_month.placeholder"
                defaultMessage="Anställd från (Mån)"
              />
            }
            controlledByFormik
            value={
              values.applicant.applicant_detail.occupation_from_month === 0
                ? ""
                : values.applicant.applicant_detail.occupation_from_month
            }
            error={!!errors.applicant?.applicant_detail?.occupation_from_month}
            message={errors.applicant?.applicant_detail?.occupation_from_month}
          />
          <Input
            type="number"
            name="applicant.applicant_detail.occupation_from_year"
            onChange={handleChangeToNumber}
            placeholder={
              <FormattedMessage
                id="app.mypages.loans.applyForLoan.occupation_from_year.placeholder"
                defaultMessage="Anställd från (År)"
              />
            }
            controlledByFormik
            value={
              values.applicant.applicant_detail.occupation_from_year === 0
                ? ""
                : values.applicant.applicant_detail.occupation_from_year
            }
            error={!!errors.applicant?.applicant_detail?.occupation_from_year}
            message={errors.applicant?.applicant_detail?.occupation_from_year}
          />
        </Styled.EmploymentWrap>
        <Input
          type="text"
          name="applicant.applicant_detail.employer"
          onChange={handleChange}
          value={values.applicant.applicant_detail.employer}
          error={!!errors.applicant?.applicant_detail?.employer}
          message={errors.applicant?.applicant_detail?.employer}
          placeholder={
            <FormattedMessage
              id="app.common.employer"
              defaultMessage="Arbetsgivare"
            />
          }
          controlledByFormik
        />
        <Input
          type="number"
          name="applicant.applicant_detail.housing_cost"
          onChange={handleChange}
          value={
            values.applicant.applicant_detail.housing_cost === 0
              ? ""
              : values.applicant.applicant_detail.housing_cost
          }
          placeholder={
            <FormattedMessage
              id="app.common.housingCost"
              defaultMessage="Boendekostnad"
            />
          }
          pattern="\d*"
          inputMode="tel"
          error={!!errors.applicant?.applicant_detail?.housing_cost}
          message={errors.applicant?.applicant_detail?.housing_cost}
          controlledByFormik
        />
        <label>
          <Select
            value={values.applicant.applicant_detail.marital_status}
            options={detailMaritalStatusOptions}
            aria-label={intl.formatMessage({
              id: "app.common.maritalStatus",
              defaultMessage: "Civilstånd",
            })}
            onChange={handleChange}
            name="applicant.applicant_detail.marital_status"
            error={!!errors.applicant?.applicant_detail?.marital_status}
            message={errors.applicant?.applicant_detail?.marital_status}
          />
        </label>
        <Input
          type="tel"
          name="applicant.tel"
          onChange={handleChange}
          value={values.applicant.tel}
          placeholder={
            <FormattedMessage
              id="app.common.telAbbreviated"
              defaultMessage="Tel"
            />
          }
          error={!!errors.applicant?.tel}
          message={errors.applicant?.tel}
          controlledByFormik
        />
        <label>
          <Select
            options={housingOptions}
            value={values.applicant.applicant_detail.housing_type}
            onChange={handleChange}
            aria-label={intl.formatMessage({
              id: "app.common.housingType",
              defaultMessage: "Boendeform",
            })}
            name="applicant.applicant_detail.housing_type"
            error={!!errors.applicant?.applicant_detail?.housing_type}
            message={errors.applicant?.applicant_detail?.housing_type}
          />
        </label>
        <Input
          type="email"
          name="applicant.email"
          controlledByFormik
          onChange={handleChange}
          value={values.applicant.email}
          placeholder={
            <FormattedMessage id="app.common.email" defaultMessage="E-post" />
          }
          error={!!errors.applicant?.email}
          message={errors.applicant?.email}
        />
        <label>
          <Select
            value={values.applicant.applicant_detail.number_of_kids}
            options={numberOfKidsOptions}
            aria-label={intl.formatMessage({
              id: "app.common.numberOfKids",
              defaultMessage: "Antal Barn",
            })}
            onChange={handleChangeToNumber}
            name="applicant.applicant_detail.number_of_kids"
            error={!!errors.applicant?.applicant_detail?.number_of_kids}
            message={errors.applicant?.applicant_detail?.number_of_kids}
          />
        </label>
        <Input
          type="text"
          controlledByFormik
          name="applicant.legal_entity_number"
          onChange={handleChange}
          value={values.applicant.legal_entity_number}
          placeholder={
            <FormattedMessage
              id="app.common.personNumber"
              defaultMessage="Personnummer"
            />
          }
          error={!!errors.applicant?.legal_entity_number}
          message={errors.applicant?.legal_entity_number}
        />
      </Styled.InputsWrap>
      <Styled.CheckboxWrap>
        <label>
          <Checkbox
            checked={acceptTandC}
            onChange={() => setAcceptTandC(!acceptTandC)}
            size="large"
            color="white"
          />
          <div>
            <FormattedMessage
              id="app.mypages.loans.applyForLoan.t&cAgreementText"
              defaultMessage="Jag intygar att lämnade uppgifter är korrekta och jag godkänner
    Broccs användarvillkor och har läst och förstått
    Integritetspolicyn."
            />{" "}
            <a target="_blank" href={BROCC_LANDING} rel="noreferrer">
              <FormattedMessage
                id="app.mypages.loans.applyForLoan.t&cAgreementTextLink"
                defaultMessage="Se Integritetspolicyn här."
              />
            </a>
          </div>
        </label>
      </Styled.CheckboxWrap>
      <Styled.ButtonWrap>
        <Button type="submit" disabled={!acceptTandC || disabled}>
          <FormattedMessage
            id="app.mypages.loans.applyForLoan.button"
            defaultMessage="Ansök nu"
          />
        </Button>
      </Styled.ButtonWrap>
    </>
  )
}

export default LoanApplicationForm
