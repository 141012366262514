import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const AccountWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  h1 {
    margin: 0 0 2em 0;
  }
  svg path {
    stroke: var(--black);
  }
`
export const LogOut = styled.div`
  align-self: flex-start;
  margin: 0.5em 0.5em 0.5em 0em;
  margin-top: 50px;
  button {
    font-size: ${font.size.lg};
    padding: 10px 20px;
    min-height: auto;
  }
`

export const ReviewWrap = styled.div`
  text-align: center;
  margin-top: 1em;
  a {
    font-weight: 400;
    color: var(--dark-yellow);
    font-size: 1rem;
    justify-content: center;
  }
`

export const LinkWrap = styled.div`
  display: flex;
  flex-direction: row;
  max-width: auto;
  gap: 0.7em;
  svg {
    width: 20px;
    height: 20px;
    transform: scale(1.6);
    path {
      transition: 0.2s ease-in-out all;
    }
  }
`
