import { FC, useState, useMemo } from "react"
import { FormattedMessage } from "react-intl"

import CustomFormattedNumber from "Components/CustomFormattedNumber"
import { useGetCashback, usePostCashback } from "Hooks/API/useCashback"
import { useFeedbackModal } from "Context/modal-context"
import Button from "UI/Button"
import Loading from "UI/Loading"
import ProgressRing from "UI/ProgressRing"
import { CashbackTypeEnum } from "Constants/dataStrings"
import TermsModal from "./termsModal"
import * as S from "./Cashback.styles"

const Cashback: FC = () => {
  const [termsModal, setTermsModal] = useState(false)
  const { data, isError, isLoading, isSuccess } = useGetCashback()
  const { successModal, errorModal } = useFeedbackModal()
  const sendCashback = usePostCashback()

  const handleCashback = (type: CashbackTypeEnum) => {
    sendCashback
      .mutateAsync(type)
      .then(() =>
        successModal(
          <FormattedMessage
            id="app.mypages.cashback.use.success"
            defaultMessage="Vi kommer nu att behandla din begäran!"
          />
        )
      )
      .catch(() =>
        errorModal(
          <FormattedMessage
            id="app.mypages.cashback.use.error"
            defaultMessage="Något gick fel. Om problemet kvarstår, vänligen kontakta vår kundtjänst på 08-50 12 15 80 eller help@brocc.se."
          />
        )
      )
  }

  const { remaining, progress } = useMemo(() => {
    if (!data) {
      return {
        remaining: 0,
        progress: 0,
      }
    }
    const diff = data.goal - data.balance
    const pr = data.balance / data.goal
    return {
      remaining: diff > 0 ? diff : 0,
      progress: pr < 1 ? pr : 1,
    }
  }, [data])

  if (isLoading) {
    return (
      <S.LoadingContainer>
        <Loading />
      </S.LoadingContainer>
    )
  }

  if (isError) {
    return (
      <FormattedMessage
        id="app.mypages.error.cashback.getCashback"
        defaultMessage="Jag kan inte få tillbaka pengarna. Försök igen, tack."
      />
    )
  }

  return (
    <S.CashbackHeadContainer>
      {isSuccess && data.balance >= data.goal && !data.has_redemption ? (
        <S.CashbackContainer>
          <S.ButtonWrap>
            <h2>
              <FormattedMessage
                id="app.mypages.cashback.use.header"
                defaultMessage="Använd Pengar tillbaka"
              />
            </h2>
            <Button
              onClick={() => handleCashback(CashbackTypeEnum.Withdraw)}
              disabled={sendCashback.isLoading}
            >
              <FormattedMessage
                id="app.mypages.cashback.use.account"
                defaultMessage="Betala ut till mitt bankkonto"
              />
            </Button>
            <Button
              onClick={() => handleCashback(CashbackTypeEnum.ExtraPayment)}
              disabled={sendCashback.isLoading}
            >
              <FormattedMessage
                id="app.mypages.cashback.use.amortise"
                defaultMessage="Amortera på mitt lån"
              />
            </Button>
            {/* Temporary commented because we won't use them */}
            {/* <Button
              onClick={() => handleCashback(CashbackTypeEnum.Charity)}
              disabled={sendCashback.isLoading}
            >
              <FormattedMessage
                id="app.mypages.cashback.use.charity"
                defaultMessage="Skänk till välgörenhet"
              />
            </Button> */}
            {/* <Button
              onClick={() => handleCashback(CashbackTypeEnum.Investment)}
              disabled={sendCashback.isLoading}
            >
              <FormattedMessage
                id="app.mypages.cashback.use.invest"
                defaultMessage="Investera"
              />
            </Button> */}
          </S.ButtonWrap>
          <S.CTAContent>
            <S.CTAContentDescription>
              <h2>
                <FormattedMessage
                  id="app.mypages.cashback.use.interestBack"
                  defaultMessage="5% av räntan tillbaka"
                />
              </h2>
              <p>
                <FormattedMessage
                  id="app.mypages.cashback.use.interestBack.description"
                  defaultMessage="Du får 5 % av räntan betalad tillbaka varje gång du betalar fakturan i tid."
                />
                <br />
                <FormattedMessage
                  id="app.mypages.cashback.totalAmount.start"
                  defaultMessage="Ditt nuvarande belopp är"
                />{" "}
                <b>
                  <CustomFormattedNumber
                    value={data?.balance || 0}
                    type="currency"
                  />
                </b>
              </p>
              <S.ModalLinks>
                <S.ModalLink onClick={() => setTermsModal(true)}>
                  <FormattedMessage
                    id="app.mypages.cashback.modalTermsLink"
                    defaultMessage="Se villkor"
                  />
                </S.ModalLink>
              </S.ModalLinks>
            </S.CTAContentDescription>
          </S.CTAContent>
        </S.CashbackContainer>
      ) : (
        <S.CashbackCTA>
          <S.CTAContent>
            {isSuccess &&
              (data.has_redemption ? (
                <S.CashbackHeadingWrap>
                  <h2 data-testid="pending-request">
                    <FormattedMessage
                      id="app.mypages.cashback.totalAmount.hasRedemption"
                      defaultMessage="Vi håller på att behandla din begäran om användandet av Pengar tillbaka."
                    />
                  </h2>
                </S.CashbackHeadingWrap>
              ) : (
                <>
                  <h1>
                    <FormattedMessage
                      id="app.mypages.cashback.header"
                      defaultMessage="Pengar tillbaka"
                    />
                  </h1>
                  <p className="remainder">
                    <FormattedMessage
                      id="app.mypages.cashback.CTAheaderEnd"
                      defaultMessage="0 kvar tills du kan använda dina Pengar tillbaka!"
                      values={{
                        remaining: (
                          <strong>
                            <CustomFormattedNumber
                              value={remaining}
                              type="currency"
                              minimumFractionDigits={0}
                              maximumFractionDigits={0}
                            />
                          </strong>
                        ),
                      }}
                    />
                  </p>
                  <strong>
                    <FormattedMessage
                      id="app.mypages.cashback.monthlyCashback"
                      defaultMessage="Pengar tillbaka varje månad"
                    />
                  </strong>
                  <p className="description">
                    <FormattedMessage
                      id="app.mypages.cashback.borrowDescription"
                      values={{
                        goal: (
                          <CustomFormattedNumber
                            value={data.goal}
                            type="currency"
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                          />
                        ),
                      }}
                    />
                  </p>
                </>
              ))}
          </S.CTAContent>
          <S.ProgressWrap>
            <ProgressRing
              size={200}
              progress={progress || 0}
              strokeWidth={15}
            />
            <S.Balance aria-label="cashback-balance">
              <CustomFormattedNumber
                value={data?.balance || 0}
                type="currency"
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </S.Balance>
          </S.ProgressWrap>
        </S.CashbackCTA>
      )}
      {termsModal && <TermsModal setTermsModal={setTermsModal} />}
    </S.CashbackHeadContainer>
  )
}

export default Cashback
