import { flex } from "config/mixins"
import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const ProposedLoanContractWrap = styled.div`
  background-color: ${(props) => props.theme.infoBox};
  border-radius: 20px;
  margin-bottom: 3em;

  h1 {
    margin-bottom: 0;
    @media screen and (min-width: ${mediaQueries.medium}) {
      font-size: ${font.size.xl4};
    }
  }

  ul {
    list-style: none;
    width: 100%;
    margin: 1em 0 3em 0;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1em 0;

      &:not(:last-child) {
        border-bottom: 1px solid var(--light-gray);
      }
    }
  }

  > button {
    margin: 0 auto;
  }
`

export const LoadingWrap = styled.div`
  ${flex.center}
  width: 100%;
  height: 100%;
`

export const DataValue = styled.span`
  font-weight: 500;
`

export const DownloadButton = styled.button`
  padding: 0;
  line-height: 0;
`
