import { useEffect, useState } from "react"

import { AutostartTokenSign } from "../AutostartTokenSign/AutostartTokenSign"
import { SignBankIDQRCode } from "../SignBankIDQRCode/SignBankIDQRCode"
import { SignFlowError } from "../SignFlowError/SignFlowError"
import * as S from "./SignFlow.styles"

export const SignFlow = ({
  startSigning,
  autostartToken,
  cancelSigning,
  isSigning,
  qrCode,
  errorMessage,
  isError,
  isLoading,
}: {
  isSigning: boolean
  qrCode: string
  autostartToken: string
  cancelSigning: () => void
  startSigning: () => void
  isError: boolean
  errorMessage: string
  isLoading: boolean
}) => {
  const [showAuthWithAutostartToken, setShowAuthWithAutostartToken] =
    useState(false)

  const changeAuthMethodToAutostartToken = () => {
    setShowAuthWithAutostartToken(true)
  }
  const showAuthWithQRCode = () => {
    setShowAuthWithAutostartToken(false)
  }

  const handleTryAgain = () => {
    setShowAuthWithAutostartToken(false)
    startSigning()
  }

  useEffect(() => {
    startSigning()

    return cancelSigning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isError) {
    return (
      <SignFlowError
        errorMessage={errorMessage}
        handleTryAgain={handleTryAgain}
      />
    )
  }

  if (showAuthWithAutostartToken && autostartToken) {
    return (
      <S.SignFlowBlock className="selected-method">
        <AutostartTokenSign
          autostartToken={autostartToken}
          cancelAuth={showAuthWithQRCode}
          isSigning={isSigning}
        />
      </S.SignFlowBlock>
    )
  }

  return (
    <S.SignFlowBlock className="selected-method">
      <SignBankIDQRCode
        authWithAutostartToken={changeAuthMethodToAutostartToken}
        qrCode={qrCode}
        isSigning={isSigning}
        isLoading={isLoading}
      />
    </S.SignFlowBlock>
  )
}
