import { useMemo, useEffect, memo, useState, FC, useCallback } from "react"
import { useIsMutating } from "react-query"
import Loading from "UI/Loading"
import { SE, localCountryCodes } from "Constants/languages"
import { useLangContext } from "Context/lang-context"
import { EnvKeysEnum, getEnv } from "Utils/environment"
import { TINK_LINK, TINK_LINK_ACCOUNT_CHECK } from "Constants/externalLinks"
import { tinkTypes, screenTypes, reportTypes } from "./constants"
import { TinkCallbackPropTypes } from "./TinkCallback.types"
import { LoadingWrapper, TinkCallbackWrap } from "./TinkCallback.styles"

const TinkCallback: FC<TinkCallbackPropTypes> = ({
  type,
  handleTinkEvent,
  legalEntityNumber,
  paymentID,
  showBackButton,
  state,
  isIndependent,
}) => {
  const { locale } = useLangContext()
  const [screen, setScreen] = useState("")
  const isMutating = useIsMutating()

  const encodeTinkStateParam = (object: Record<string, unknown>) =>
    window.btoa(JSON.stringify(object))

  const lang = localCountryCodes[locale] || `${locale}_${locale.toUpperCase()}`

  const tinkParams = useMemo(() => {
    const tinkLinkParams = new URLSearchParams()

    const tinkPaymentClientId = getEnv(EnvKeysEnum.TinkPaymentClientId)
    const tinkReportingClientId = getEnv(EnvKeysEnum.TinkReportingClientId)

    if (type !== "bundledFlow" && tinkPaymentClientId) {
      tinkLinkParams.append("client_id", tinkPaymentClientId)
    }

    if (type === "bundledFlow" && tinkReportingClientId) {
      tinkLinkParams.append("client_id", tinkReportingClientId)
    }

    tinkLinkParams.append("market", SE)
    tinkLinkParams.append("locale", lang)

    tinkLinkParams.append("redirect_uri", window.location.origin)
    tinkLinkParams.append("iframe", "true")
    tinkLinkParams.append("theme", "LIGHT")

    if (type === "pay" && paymentID) {
      tinkLinkParams.append("payment_request_id", paymentID)
      tinkLinkParams.append(
        "scope",
        "payment:write,payment:read,user:read,credentials:read"
      )
    }

    if (type === "bundledFlow" && state) {
      tinkLinkParams.append("report_types", reportTypes.join(","))
      const nextState = isIndependent
        ? { ...state, is_independent: isIndependent }
        : state
      tinkLinkParams.append("state", encodeTinkStateParam(nextState))
    }

    if (getEnv(EnvKeysEnum.TinkEnvironment) === "test") {
      tinkLinkParams.append("test", "true")
    } else {
      tinkLinkParams.append("input_username", legalEntityNumber)
    }

    return tinkLinkParams
  }, [isIndependent, lang, legalEntityNumber, paymentID, state, type])

  const tinkLinkAccountCheck = TINK_LINK_ACCOUNT_CHECK.replace(
    ":tinkType",
    tinkTypes[type]
  ).replace(":tinkParams", tinkParams.toString())

  const decodeTinkStateData = (data: string) => JSON.parse(data)

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      if (event.origin !== TINK_LINK) {
        return
      }
      try {
        const { type: decodedType, data } = decodeTinkStateData(event.data)
        if (data?.screen) {
          setScreen(data.screen)
        }
        handleTinkEvent(decodedType, data)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },
    [handleTinkEvent]
  )

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false)
    return () => {
      window.removeEventListener("message", receiveMessage, false)
    }
  }, [receiveMessage])

  if (isMutating > 0) {
    return (
      <LoadingWrapper isPayment={type === "pay"}>
        <Loading />
      </LoadingWrapper>
    )
  }

  return (
    <TinkCallbackWrap
      isFirstScreen={
        screen === screenTypes.providerSelection && !showBackButton
      }
      isPayment={type === "pay"}
    >
      <iframe
        title="tink"
        src={tinkLinkAccountCheck}
        frameBorder={0}
        style={{ width: "100%", height: "700px" }}
      />
    </TinkCallbackWrap>
  )
}

export default memo(TinkCallback)
