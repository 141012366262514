import { FC, useState, useEffect, useCallback } from "react"
import { Navigate, useParams } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"

import Loading from "UI/Loading"
import { useGetKYC } from "Hooks/API/useKYC"
import { useGetCustomer } from "Hooks/API/useCustomer"
import { useGetDepositServiceByID } from "Hooks/API/useDepositServices"
import IconFactory from "UI/IconFactory"
import { Urls } from "Constants/urls"
import { SAVINGS_ONBOARDING } from "Constants/storage"
import { getParsedStorageItemOrDefault } from "Utils/localStorage"
import { DepositServiceStatus } from "types/enums"
import { STEPS, STEP_TITLES, totalSteps } from "./constants"
import {
  SavingsOnboardingData,
  SavingsOnboardingStepsEnum,
} from "./SavingsOnboarding.types"
import * as S from "./SavingsOnboarding.styles"
import CancelApplication from "./CancelApplication"
import ProgressSteps from "./ProgressSteps"

const SavingsOnboarding: FC = () => {
  const intl = useIntl()
  const { accountID } = useParams() as { accountID: string }

  const [step, setStep] = useState(SavingsOnboardingStepsEnum.Intro)
  const [showCancelApplicationModal, setShowCancelApplicationModal] =
    useState(false)
  const [stepIsDefined, setStepIsDefined] = useState(false)
  const [storedData] = useState(() =>
    getParsedStorageItemOrDefault<SavingsOnboardingData>(SAVINGS_ONBOARDING, {})
  )

  const { data, isError, isLoading, isSuccess } =
    useGetDepositServiceByID(accountID)

  const { isLoading: isCustomerLoading } = useGetCustomer()
  const { isLoading: isLoadingKyc } = useGetKYC(false)

  const handleCancelModal = () => setShowCancelApplicationModal((prev) => !prev)

  const determineStep = () => {
    if (!storedData[accountID] && !data?.bank_account && !data?.type) {
      setStep(SavingsOnboardingStepsEnum.Intro)
      return
    }
    if (
      !storedData[accountID]?.brocc_has_saved_kyc &&
      !data?.bank_account &&
      !data?.type
    ) {
      setStep(SavingsOnboardingStepsEnum.KYC)
      return
    }
    if (!data?.bank_account) {
      setStep(SavingsOnboardingStepsEnum.AccountVerification)
      return
    }
    if (
      !data?.type ||
      (data?.type === "fixed" && !data?.initial_deposit_amount)
    ) {
      setStep(SavingsOnboardingStepsEnum.AccountType)
      return
    }
    if (data.type === "flex" || (data?.type && data?.initial_deposit_amount)) {
      setStep(SavingsOnboardingStepsEnum.Agreement)
    }
  }

  useEffect(() => {
    if (data) {
      determineStep()
      setStepIsDefined(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const CurrentStep = STEPS[step]

  const handleBackButtonClick = useCallback(() => {
    if (step > SavingsOnboardingStepsEnum.KYC) {
      setStep(step - 1)
    }
  }, [step])

  if (data?.status === DepositServiceStatus.active || isError) {
    return <Navigate to={Urls.Home} />
  }

  if (
    !data ||
    isLoading ||
    isCustomerLoading ||
    isLoadingKyc ||
    !stepIsDefined
  ) {
    return (
      <S.LoadingWrapper>
        <Loading isCentered />
      </S.LoadingWrapper>
    )
  }

  if (!isSuccess) return null

  return (
    <S.OnboardingStepsContainer>
      {step !== SavingsOnboardingStepsEnum.Intro && (
        <S.CounterContainer>
          <S.StepHeader>
            {(step === SavingsOnboardingStepsEnum.AccountVerification ||
              step === SavingsOnboardingStepsEnum.AccountType) &&
              !data.type && (
                <S.BackButton
                  onClick={handleBackButtonClick}
                  type="button"
                  aria-label="Go back"
                >
                  <IconFactory name="chevron" />
                </S.BackButton>
              )}
            {step > SavingsOnboardingStepsEnum.Intro && (
              <strong>{intl.formatMessage(STEP_TITLES[step])}</strong>
            )}
            <S.CancelButton onClick={handleCancelModal}>
              <IconFactory
                className="close"
                name="close"
                width={30}
                height={30}
              />
              <span>
                <FormattedMessage id="app.common.cancel" />
              </span>
            </S.CancelButton>
          </S.StepHeader>
        </S.CounterContainer>
      )}
      {step !== SavingsOnboardingStepsEnum.Intro && (
        <ProgressSteps activeStep={step} stepsNumber={totalSteps} />
      )}
      <S.StepContainer>
        {CurrentStep ? (
          <CurrentStep
            isCompany={data.customer.is_corporate}
            setStep={setStep}
            handleCancelModal={handleCancelModal}
            emptyInitialData
          />
        ) : (
          <FormattedMessage
            id="app.common.error.header"
            defaultMessage="Något gick fel"
          />
        )}
      </S.StepContainer>
      {showCancelApplicationModal && (
        <CancelApplication handleCancelModal={handleCancelModal} />
      )}
    </S.OnboardingStepsContainer>
  )
}

export default SavingsOnboarding
