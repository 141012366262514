import { flex } from "config/mixins"
import styled, { css } from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

type InsuranceStatePropTypes = {
  status?: string
}

type LoanTitlePropTypes = {
  hasModal?: boolean
}

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const NoLoansWrap = styled.div`
  ${flex.columnCenter}
  max-width: 800px;
  margin: auto;
  text-align: center;
  h2 {
    margin: 15px 0;
    font-size: ${font.size.xl2};
  }
  p {
    max-width: 80%;
  }
`
export const ActiveInsuranceWrap = styled.section`
  background-color: ${(props) => props.theme.infoBox};
  border-radius: 20px;
  margin: 1em 0 3em 0;
`

export const ActiveInsuranceHead = styled.div`
  ${flex.alignCenterBetween}
  padding: 1em 2em 1em 2em;
  border-bottom: 1px solid var(--light-gray);
`

export const ActiveInsuranceTitle = styled.div`
  font-size: ${font.size.xl};
  font-weight: 700;
  line-height: 24px;
`

export const InsuranceState = styled.div<InsuranceStatePropTypes>`
  font-weight: bold;
  padding: 0.75em 1.5em;
  border-radius: 100px;
  font-size: ${font.size.base};
  color: var(--dark-gray);
  background: rgba(var(--light-gray), 0.3);

  ${(props) =>
    props.status === "active" &&
    css`
      color: var(--green);
      background: var(--light-green);
    `}

  ${(props) =>
    props.status === "inactive" &&
    css`
      color: var(--dark-yellow);
      background: rgba(var(--light-yellow-rgb), 0.3);
    `}
`

export const ActiveInsuranceBody = styled.div`
  padding: 2.5em 2em 2.5em 2em;
  display: flex;
  flex-wrap: wrap;
  gap: 2em 7em;
`

export const Section = styled.div``

export const LoanDetail = styled.div`
  font-size: ${font.size.xl2};
  margin-bottom: 0.5em;
`

export const LoanTitle = styled.div<LoanTitlePropTypes>`
  ${flex.alignCenter}
  font-size: ${font.size.sm};
  color: var(--dark-gray);
  gap: 0 0.5em;
  cursor: ${(props) => (props.hasModal ? "pointer" : "initial")};

  svg {
    min-height: 14px;
    min-width: 14px;
    display: block;
  }
`

export const ActiveInsuranceFooter = styled.div`
  ${flex.center}
  padding: 1em 2em 1em 2em;

  @media screen and (max-width: ${mediaQueries.large}) {
    flex-direction: column;
  }
`

export const ButtonWrap = styled.div`
  ${flex.column}
  gap: 1em 0;
  width: 50%;
  @media screen and (max-width: ${mediaQueries.large}) {
    width: 80%;
    margin-top: 1em;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    width: 100%;
  }
`

export const DownloadLink = styled.a`
  color: var(--black);
`

export const ActionButton = styled.button`
  ${flex.alignCenter}
  height: 3em;
  border-radius: 8px;
  border: 1px solid var(--light-gray);

  svg {
    margin-right: 0.5em;
  }
`
export const OverviewWrap = styled.div`
  padding-top: 2em;
`
export const ProposedLoanContractWrap = styled.div`
  ${flex.column}
  h2 {
    margin: 1.5em 0 0.5em 0;
  }
  a {
    ${flex.alignCenterBetween}
    color: var(--black);
    font-weight: bold;
    text-decoration: none;
    padding: 20px 0;
    border-bottom: 1px solid var(--light-gray);
    transition: 0.2s ease-in-out all;
    svg path {
      stroke: var(--black);
    }
  }
`

export const FeaturesWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;
  margin: 2.5em 0;

  @media screen and (max-width: ${mediaQueries.medium}) {
    grid-template-columns: 1fr;
  }
`
