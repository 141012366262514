import { useState } from "react"

import { InsuranceSelectAccount } from "../InsuranceSelectAccount/InsuranceSelectAccount"
import { InsuranceSignForm } from "../InsuranceSignForm/InsuranceSignForm"

interface InsurancePromotionModalContentProps {
  loanId?: string
  closeModal: () => void
  onSuccess?: () => void
}

export const InsurancePromotionModalContent = ({
  loanId,
  closeModal,
  onSuccess,
}: InsurancePromotionModalContentProps) => {
  const [selectedLoanId, setSelectedLoanId] = useState<string | undefined>()

  if (selectedLoanId) {
    return (
      <InsuranceSignForm
        loanId={selectedLoanId}
        onClose={closeModal}
        onSuccess={onSuccess}
      />
    )
  }

  return (
    <InsuranceSelectAccount
      loanId={loanId}
      closeModal={closeModal}
      selectLoanId={setSelectedLoanId}
    />
  )
}
