import { useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useQueryClient } from "react-query"

import { IDepositService } from "Interfaces/depositInterfaces"
import WithdrawalsForm from "Components/WithdrawalsForm"
import { Urls } from "Constants/urls"
import { DEPOSIT_SERVICES_SERVICE } from "Constants/queryKeys"
import {
  useGetDepositServices,
  usePostDepositWithdrawalAmount,
} from "Hooks/API/useDepositServices"
import { WithdrawalsFormValues } from "Components/WithdrawalsForm/WithdrawalsForm.types"
import Button from "UI/Button"
import type { PostWithdrawalAmountOptions } from "ApiServices/depositServices"
import IconFactory from "UI/IconFactory"
import { TaxBanner } from "Components/TaxBanner/TaxBanner"
import { formatNumber } from "Utils/formatNumber"
import { BankAccountItem } from "./BankAccountItem/BankAccountItem"
import * as S from "./SavingsWithdrawal.styles"

export interface SelectedAccount {
  bankAccountType?: "bank" | "savings"
  deposit: IDepositService
}

interface SavingsWithdrawalProps {
  closeDrawer?: () => void
  isClosingTransferring?: boolean
  successWithdrawalCallback?: () => void
}

const SavingsWithdrawal = ({
  closeDrawer,
  isClosingTransferring,
  successWithdrawalCallback,
}: SavingsWithdrawalProps) => {
  const navigate = useNavigate()
  const { accountID } = useParams() as { accountID: string }
  const queryClient = useQueryClient()
  const [selectedAccount, setSelectedAccount] =
    useState<SelectedAccount | null>(null)

  const postDepositWithdrawalMutation = usePostDepositWithdrawalAmount()
  const deposits = useGetDepositServices()

  const deposit = queryClient.getQueryData<IDepositService>([
    DEPOSIT_SERVICES_SERVICE,
    accountID,
  ])

  const availableAccounts = useMemo(() => {
    if (deposits.data?.data) {
      return deposits.data.data.filter(
        (d) => d.uid !== accountID && d.is_open_for_deposits
      )
    }
    return []
  }, [deposits.data, accountID])

  const onSubmit = (values: WithdrawalsFormValues) => {
    const mutationVars: PostWithdrawalAmountOptions = {
      amount: +values.amount,
      uid: accountID,
      toDepositServiceId: null,
    }

    if (selectedAccount && selectedAccount.bankAccountType === "savings") {
      mutationVars.toDepositServiceId = selectedAccount.deposit.uid
    }

    return postDepositWithdrawalMutation.mutateAsync(mutationVars)
  }

  const hasBankAccount = useMemo(
    () =>
      deposit &&
      deposit.bank_account.bank_name &&
      deposit.bank_account.bank_clearing_number &&
      deposit.bank_account.bank_account_number,
    [deposit]
  )

  if (!deposit) {
    return (
      <S.WithdrawalsOverviewWrap>
        <FormattedMessage
          id="app.mypages.error.depositServices.useGetDepositServiceByID"
          defaultMessage="Kan inte få tillgång till den begärda insättningstjänsten. Försök igen"
        />
      </S.WithdrawalsOverviewWrap>
    )
  }

  const accountSettingsLink = `${Urls.Savings}/${accountID}/${Urls.SavingsAccountSettings}`

  return (
    <S.WithdrawalsOverviewWrap>
      {!selectedAccount && (
        <>
          <S.ListTitle>
            {isClosingTransferring ? (
              <FormattedMessage id="app.mypages.savings.withdrawal.drawer.closing.listTitle" />
            ) : (
              <FormattedMessage
                id="app.mypages.savings.withdrawal.drawer.listTitle"
                defaultMessage="Välj konto att överföra pengar till"
              />
            )}
          </S.ListTitle>
          <S.ListDescription>
            {isClosingTransferring ? (
              <FormattedMessage id="app.mypages.savings.withdrawal.drawer.description.closing" />
            ) : (
              <FormattedMessage
                id="app.mypages.savings.withdrawal.drawer.description"
                defaultMessage="Överför pengar direkt till annat konto eller ta ut pengar till ditt
anslutna bankkontot."
              />
            )}
          </S.ListDescription>
          {deposit.type === "fixed" && deposit.withdrawal_fee_rate > 0 && (
            <TaxBanner>
              {isClosingTransferring ? (
                <FormattedMessage
                  id="app.mypages.savings.withdrawal.taxBanner.sidebar.closing"
                  values={{
                    lockUntilDate: deposit.term_end_date,
                    fee: formatNumber(deposit.withdrawal_fee_rate, {
                      style: "percent",
                      minimumFractionDigits: undefined,
                    }),
                  }}
                />
              ) : (
                <FormattedMessage
                  id="app.mypages.savings.withdrawal.taxBanner.sidebar"
                  values={{
                    lockUntilDate: deposit.term_end_date,
                    fee: formatNumber(deposit.withdrawal_fee_rate, {
                      style: "percent",
                      minimumFractionDigits: undefined,
                    }),
                  }}
                />
              )}
            </TaxBanner>
          )}
          <S.WithdrawalAccountsList>
            {availableAccounts.map((dep) => (
              <S.WithdrawalAccountsListItem key={dep.uid}>
                <BankAccountItem
                  title={dep.name}
                  description={dep.product_number.toString()}
                  name={dep.bank_account.bank_name}
                  type="savings"
                  balance={dep.current_balance}
                  showArrowIcon
                  onClick={() =>
                    setSelectedAccount({
                      bankAccountType: "savings",
                      deposit: dep,
                    })
                  }
                />
              </S.WithdrawalAccountsListItem>
            ))}
          </S.WithdrawalAccountsList>
          {hasBankAccount && (
            <BankAccountItem
              title={deposit.bank_account.bank_name}
              description={`${deposit.bank_account.bank_clearing_number} - ${deposit.bank_account.bank_account_number}`}
              name={deposit.bank_account.bank_name}
              type="bank"
              showArrowIcon
              isExternalBank
              onClick={() =>
                setSelectedAccount({ bankAccountType: "bank", deposit })
              }
            />
          )}
          {!hasBankAccount && (
            <S.WithdrawalsInfoBox>
              <p>
                <FormattedMessage
                  id="app.main.withdrawingInvestment.enter.account.number"
                  defaultMessage="Innan du kan registrera uttag behöver du ange ditt kontonummer. Detta gör du enkelt under inställningar. Vänligen försök att göra en överföring igen när du har kopplat ditt bankkonto."
                />
              </p>
              <Button
                type="button"
                onClick={() => navigate(accountSettingsLink)}
              >
                <FormattedMessage
                  id="app.mypages.savings.settings.bankAccountButton"
                  defaultMessage="Ange bankkonto"
                />
              </Button>
            </S.WithdrawalsInfoBox>
          )}
        </>
      )}
      {selectedAccount && (
        <>
          <S.BackButton onClick={() => setSelectedAccount(null)}>
            <IconFactory name="chevron" />
            <FormattedMessage id="app.common.back" defaultMessage="Tillbaka" />
          </S.BackButton>
          <WithdrawalsForm
            onSubmit={onSubmit}
            isLoading={postDepositWithdrawalMutation.isLoading}
            type="savings"
            link={accountSettingsLink}
            selectedAccount={selectedAccount}
            closeDrawer={closeDrawer}
            isClosingTransferring={isClosingTransferring}
            successWithdrawalCallback={successWithdrawalCallback}
            deposit={{
              withdrawalFee: deposit.withdrawal_fee_rate,
              type: deposit.type,
              lockUntilDate: deposit.term_end_date,
              currentBalance: deposit.current_balance,
              bankClearingNumber: deposit.bank_account.bank_clearing_number,
              bankAccountNumber: deposit.bank_account.bank_account_number,
              bankName: deposit.bank_account.bank_name,
              accruedInterest: deposit.accrued_interest,
            }}
          />
        </>
      )}
    </S.WithdrawalsOverviewWrap>
  )
}

export default SavingsWithdrawal
