import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const AccountVerificationWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 20px;
  align-items: flex-start;
  position: relative;
  min-height: calc(100vh - 6em);
  iframe {
    border: none;
    height: 80vh;
  }
  h4 {
    font-weight: 500;
    font-size: ${font.size.lg};
    margin-top: 0;
  }
  & > p:not(:first-of-type) {
    margin: 0;
    color: var(--dark-gray);
  }
`

export const VerificationOption = styled.div`
  display: flex;
  align-self: center;
  margin-top: 2em;
  button {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: black;
    svg {
      margin-bottom: 10px;
      path {
        stroke: black;
      }
    }
  }
`

export const VerificationInfoMessage = styled.p`
  padding: 20px;
  border-radius: 20px;
  background-color: var(--very-light-gray);
  color: var(--black);
  margin-bottom: 20px;
`
