import { ReactNode, useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { FormattedMessage } from "react-intl"

import { Urls } from "Constants/urls"
import { useGetCustomer } from "Hooks/API/useCustomer"
import { useCreateDepositApplication } from "Hooks/API/useDepositServices"
import IconFactory from "UI/IconFactory"
import Loading from "UI/Loading"
import Modal from "UI/Modal"
import Button from "UI/Button"
import { useFeedbackModal } from "Context/modal-context"
import * as S from "./CreateDepositApplication.styles"

const CreateDepositApplication = ({
  createButtonText,
}: {
  createButtonText?: ReactNode
}) => {
  const navigate = useNavigate()
  const { errorModal } = useFeedbackModal()

  const { mutateAsync, isLoading } = useCreateDepositApplication()
  const { data: customerData } = useGetCustomer()

  const [isCreateSavingsAccountModal, setCreateSavingsAccountModal] =
    useState(false)

  const handleCreateSavingsAccountModal = useCallback(
    () => setCreateSavingsAccountModal((prev) => !prev),
    [setCreateSavingsAccountModal]
  )

  const handleCreateDepositApplication = useCallback(async () => {
    if (customerData) {
      await mutateAsync({
        accept_agreement: true,
        subscribe_newsletter: false,
        legal_entity_number: customerData.legal_entity_number,
        email: customerData.email,
        tel: customerData.tel,
      })
        .then((data) => {
          const { uid } = data
          navigate(
            `${Urls.Savings}/${Urls.SavingsOnboarding.replace(
              ":accountID",
              uid
            )}`
          )
        })
        .catch(() => errorModal())
    }
  }, [mutateAsync, customerData, navigate, errorModal])

  return (
    <>
      {createButtonText ? (
        <Button onClick={handleCreateSavingsAccountModal}>
          {createButtonText}
        </Button>
      ) : (
        <S.PlusButton onClick={handleCreateSavingsAccountModal}>
          <IconFactory name="plus" width={24} height={24} />
        </S.PlusButton>
      )}
      {isCreateSavingsAccountModal && (
        <Modal
          maxWidth="600px"
          onClick={!isLoading ? handleCreateSavingsAccountModal : () => {}}
          body={
            <S.ModalBody>
              <S.CloseButton onClick={handleCreateSavingsAccountModal}>
                <IconFactory className="close" name="close" />
              </S.CloseButton>
              <h3>
                <FormattedMessage id="app.mypages.savings.openAccount.heading" />
              </h3>
              <ul>
                <li>
                  <IconFactory name="security" />
                  <p>
                    <FormattedMessage
                      id="app.mypages.main.savingsDescriptionList.item1"
                      defaultMessage="Statlig insättningsgaranti"
                    />
                  </p>
                </li>
                <li>
                  <IconFactory name="discount" />
                  <p>
                    <FormattedMessage
                      id="app.mypages.main.savingsDescriptionList.item2"
                      defaultMessage="Månatliga ränteutbetalningar"
                    />
                  </p>
                </li>
                <li>
                  <IconFactory name="like" />
                  <p>
                    <FormattedMessage
                      id="app.mypages.main.savingsDescriptionList.item3"
                      defaultMessage="Öppna konto tryggt och enkelt med BankID"
                    />
                  </p>
                </li>
              </ul>

              <S.CreateApplicaionButton
                onClick={handleCreateDepositApplication}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loading size="small" />
                ) : (
                  <FormattedMessage id="app.common.continue" />
                )}
              </S.CreateApplicaionButton>
            </S.ModalBody>
          }
        />
      )}
    </>
  )
}

export default CreateDepositApplication
