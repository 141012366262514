import styled, { css } from "styled-components"
import { font } from "Styles/styleGlobal"
import { ActiveListRowPropTypes } from "../PromoWrap.types"

export const ActiveList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
  height: auto;
  overflow-y: auto;
  padding: 0;
  margin-top: 0.5em;
`
export const ActiveListRow = styled.div<ActiveListRowPropTypes>`
  padding: 1em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.fontColor};
  font-weight: normal;
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  gap: 0.5em;
  &:hover {
    cursor: pointer;
    background-color: var(--light-gray);
  }
  ${(props) =>
    props.type === "loanApplications" &&
    css`
      background-color: var(--very-light-gray);
    `}
`

export const ToggleClosedAccountsButton = styled.button`
  align-self: flex-start;
  margin-top: 1em;
  text-decoration: underline;
  font-weight: 500;
  font-size: ${font.size.base};
  color: var(--black);
`

export const Anchor = styled.a``
