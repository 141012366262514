import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  PropsWithChildren,
  ReactNode,
} from "react"
import { LinkProps } from "react-router-dom"

export enum VariantEnum {
  Primary = "primary",
  Inverse = "inverse",
  Chevron = "chevron",
}

export type VariantType = `${VariantEnum}`

export type ButtonProps = PropsWithChildren<
  BtnProps | AnchorProps | RouterLinkProps
>

type SharedProps = {
  variant?: VariantType
  icon?: ReactNode
}

export type BtnProps = {
  component?: "button"
  isLoading?: boolean
} & SharedProps &
  ButtonHTMLAttributes<HTMLButtonElement>

export type AnchorProps = {
  component?: "a"
  isLoading?: never
} & SharedProps &
  AnchorHTMLAttributes<HTMLAnchorElement>

export type RouterLinkProps = {
  component?: "link"
  isLoading?: never
} & SharedProps &
  LinkProps

export type StyledButtonPropType = {
  variant: VariantType
  component: "a" | "link" | "button"
}

export type LoadingWrapPropType = {
  variant: VariantType
}
