import { ComponentType } from "react"
import { createRoot } from "react-dom/client"
import { HelmetProvider } from "react-helmet-async"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { EnvKeysEnum, getEnv } from "Utils/environment"
import { withLDProvider } from "launchdarkly-react-client-sdk"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import LocaleWrapper from "./LocaleWrapper"
import "Integrations/Mixpanel"

const sentryDsn = getEnv(EnvKeysEnum.SentryDsn)

const LDProvider = withLDProvider({
  clientSideID: getEnv(EnvKeysEnum.LaunchDarklyClientId)!,
})(App as ComponentType)

if (process.env.REACT_APP_ENVIROMENT !== "test" && sentryDsn !== "") {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  })
}

const container = document.getElementById("root") as HTMLDivElement
const root = createRoot(container)

root.render(
  <LocaleWrapper>
    <HelmetProvider>
      <LDProvider />
    </HelmetProvider>
  </LocaleWrapper>
)

reportWebVitals()
