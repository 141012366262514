import { FieldValues, useController } from "react-hook-form"
import Checkbox from "UI/Checkbox"
import { CheckboxFieldProps } from "./CheckboxField.types"

export const CheckboxField = <FieldsType extends FieldValues>({
  name,
  control,
  rules,
  defaultValue,
  ...props
}: CheckboxFieldProps<FieldsType>) => {
  const { field } = useController<FieldsType>({
    name,
    rules,
    control,
    defaultValue,
  })

  const { ref, value, onChange, ...rest } = field
  return <Checkbox {...props} {...rest} onChange={onChange} checked={value} />
}
export default CheckboxField
