import { FC, memo } from "react"
import { FormattedMessage } from "react-intl"

import { InvoiceStatusPropTypes } from "./InvoiceStatus.types"
import { StyledInvoiceStatus } from "./InvoiceStatus.styles"

const InvoiceStatus: FC<InvoiceStatusPropTypes> = ({ status, iconNeutral }) => (
  <StyledInvoiceStatus
    status={status}
    iconNeutral={iconNeutral}
    data-testid="invoice-status"
  >
    <FormattedMessage id={`app.ui.invoiceStatus.${status}`} />
  </StyledInvoiceStatus>
)

export default memo(InvoiceStatus)
