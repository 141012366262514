import { FC } from "react"
import { FormattedMessage } from "react-intl"

import Button from "UI/Button"

import * as S from "./SubmitLoansBody.styles"

interface SubmitLoansBodyProps {
  onCloseModal: () => void
  onSubmitLoans: () => void
}

const SubmitLoansBody: FC<SubmitLoansBodyProps> = ({
  onCloseModal,
  onSubmitLoans,
}) => {
  return (
    <S.SubmitLoansBody>
      <S.TextContainer>
        <span>
          <FormattedMessage id="app.mypages.iceing.submit.application.warning" />
        </span>
        <span>
          <FormattedMessage id="app.mypages.iceing.submit.application.message" />
        </span>
        <span>
          <FormattedMessage id="app.mypages.iceing.submit.application.phone" />
        </span>
      </S.TextContainer>
      <S.SubmitLoansBodyButtons>
        <Button type="button" variant="inverse" onClick={onCloseModal}>
          <FormattedMessage id="app.common.cancel" />
        </Button>
        <Button type="submit" onClick={onSubmitLoans}>
          <FormattedMessage id="app.mypages.iceing.collect.loans.main.interface.submit" />
        </Button>
      </S.SubmitLoansBodyButtons>
    </S.SubmitLoansBody>
  )
}

export default SubmitLoansBody
