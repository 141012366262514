import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const InputsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3em;
  align-items: flex-start;

  @media screen and (max-width: ${mediaQueries.large}) {
    grid-template-columns: 1fr;
  }
`
export const EmploymentWrap = styled.div`
  display: flex;
  align-items: flex-start;
  div {
    label {
      font-size: ${font.size.base};
      top: 7px;
    }
    &:nth-child(1) {
      input {
        border-radius: 6px 0 0 6px;
      }
    }
    &:nth-child(2) {
      input {
        border-radius: 0 6px 6px 0;
        border-left: none;
      }
    }
  }
  @media screen and (max-width: 1278px) {
    div {
      label {
        top: -6px;
      }
    }
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    div {
      label {
        top: 7px;
      }
    }
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    div {
      label {
        top: -6px;
      }
    }
  }
`
export const CheckboxWrap = styled.div`
  margin: 1.5em 0;

  label {
    font-size: ${font.size.sm};
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
`
export const ButtonWrap = styled.div`
  width: 400px;

  @media screen and (max-width: ${mediaQueries.medium}) {
    width: 100%;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    button {
      margin: 0 auto;
    }
  }
`
