import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { AnimatePresence } from "framer-motion"

import { useGetLoanContract } from "Hooks/API/useLoanContracts"
import CustomFormattedDate from "Components/CustomFormattedDate"
import BackTitleWithLink from "Components/BackTitleWithLink"
import ErrorService from "Layouts/ErrorService"
import Button from "UI/Button"
import IconFactory from "UI/IconFactory"
import Loading from "UI/Loading"
import { useFileDownload } from "Hooks/API/useFileDownload"
import { getLoanContractPdf } from "ApiServices/loanContracts"
import { LOAN_CONTRACT_PDF } from "Constants/fileNames"
import { formatNumber } from "Utils/formatNumber"
import { SignLoanContractModal } from "./SignLoanContract/SignLoanContract"
import * as S from "./ProposedLoanContract.styles"

const ProposedLoanContract = () => {
  const intl = useIntl()
  const { id } = useParams() as { id: string }

  const [showSignLoanContractModal, setShowSignLoanContractModal] =
    useState(false)

  const loanContractQuery = useGetLoanContract(id)

  const { downloadFile } = useFileDownload({
    fetchFile: getLoanContractPdf,
    fileId: id,
    fileName: LOAN_CONTRACT_PDF,
  })

  const handleSignAgreement = () => {
    setShowSignLoanContractModal(true)
  }

  if (loanContractQuery.isError) {
    return <ErrorService type="loan" />
  }

  if (loanContractQuery.isLoading || !loanContractQuery.data) {
    return (
      <S.LoadingWrap>
        <Loading />
      </S.LoadingWrap>
    )
  }

  return (
    <S.ProposedLoanContractWrap>
      <BackTitleWithLink
        titleNode={
          <FormattedMessage
            id="app.mypages.loans.loanAgreement.changeOfTerms"
            defaultMessage="Villkorsändring"
          />
        }
      />
      <ul>
        <li>
          <span>
            <FormattedMessage
              id="app.mypages.loans.loanSize"
              defaultMessage="Lånebelopp"
            />
          </span>
          <S.DataValue>
            {formatNumber(loanContractQuery.data.size, {
              style: "currency",
            })}
          </S.DataValue>
        </li>
        <li>
          <span>
            <FormattedMessage
              id="app.common.interestRate"
              defaultMessage="Ränta"
            />
          </span>
          <S.DataValue>
            {formatNumber(loanContractQuery.data.interest_rate, {
              style: "percent",
            }).replace(".", ",")}
          </S.DataValue>
        </li>
        <li>
          <span>
            <FormattedMessage
              id="app.mypages.loans.loanPeriodStartDate"
              defaultMessage="Ränta gäller från och med"
            />
          </span>
          <S.DataValue>
            {loanContractQuery.data.period_start_date ? (
              <CustomFormattedDate
                value={loanContractQuery.data.period_start_date}
              />
            ) : (
              "-"
            )}
          </S.DataValue>
        </li>
        <li>
          <span>
            <FormattedMessage
              id="app.mypages.loans.loanEffectiveRate"
              defaultMessage="Effektiv ränta"
            />
          </span>
          <S.DataValue>
            {formatNumber(loanContractQuery.data.effective_interest_rate, {
              style: "percent",
            }).replace(".", ",")}
          </S.DataValue>
        </li>
        <li>
          <span>
            <FormattedMessage
              id="app.common.monthlyCost"
              defaultMessage="Månadskostnad"
            />
          </span>
          <S.DataValue>
            {formatNumber(loanContractQuery.data.period_cost, {
              style: "currency",
            })}
          </S.DataValue>
        </li>
        <li>
          <span>
            <FormattedMessage id="app.common.term" defaultMessage="Löptid" />
          </span>
          <S.DataValue>
            {loanContractQuery.data.repayment_periods}{" "}
            <FormattedMessage
              id="app.common.monthAbbreviated"
              defaultMessage="mån"
            />
          </S.DataValue>
        </li>
        <li>
          <span>
            <FormattedMessage
              id="app.mypages.loans.agreement"
              defaultMessage="Avtal"
            />
          </span>
          <S.DownloadButton onClick={downloadFile} title="Download contract">
            <IconFactory name="download" />
          </S.DownloadButton>
        </li>
      </ul>
      <Button type="button" onClick={handleSignAgreement}>
        {intl.formatMessage({
          id: "app.common.signWithBankID",
          defaultMessage: "Signera med BankID",
        })}
      </Button>
      <AnimatePresence>
        {showSignLoanContractModal && (
          <SignLoanContractModal
            accountID={id}
            contractDate={loanContractQuery.data.period_start_date}
            onClose={() => setShowSignLoanContractModal(false)}
          />
        )}
      </AnimatePresence>
    </S.ProposedLoanContractWrap>
  )
}

export default ProposedLoanContract
