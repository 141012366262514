import { Link } from "react-router-dom"
import Loading from "../Loading/Loading"
import {
  AnchorProps,
  BtnProps,
  ButtonProps,
  RouterLinkProps,
} from "./Button.types"
import { StyledButton, LoadingWrap } from "./Button.styles"

const Button = ({
  children,
  icon,
  variant = "primary",
  component = "button",
  ...p
}: ButtonProps) => {
  if (component === "a") {
    return (
      <StyledButton
        as="a"
        data-testid="button-anchor"
        {...(p as AnchorProps)}
        variant={variant}
        component={component}
      >
        {children}
        {icon}
      </StyledButton>
    )
  }

  if (component === "link") {
    return (
      <StyledButton
        as={Link}
        {...(p as RouterLinkProps)}
        component={component}
        variant={variant}
      >
        {children}
        {icon}
      </StyledButton>
    )
  }

  return (
    <StyledButton
      data-testid="button"
      {...(p as BtnProps)}
      component={component}
      variant={variant}
    >
      {p.isLoading ? (
        <LoadingWrap variant={variant} data-testid="btn-loading">
          <Loading />
        </LoadingWrap>
      ) : (
        children
      )}
      {icon}
    </StyledButton>
  )
}

export default Button
