import { FieldValues, useController } from "react-hook-form"

import Switch from "UI/Switch"
import { SwitchFieldProps } from "./SwitchField.types"

export const SwitchField = <FieldsType extends FieldValues>({
  name,
  control,
  rules,
  defaultValue,
  ...props
}: SwitchFieldProps<FieldsType>) => {
  const { field } = useController<FieldsType>({
    name,
    rules,
    control,
    defaultValue,
  })

  return (
    <Switch
      {...props}
      {...field}
      value={field.value ?? ""}
      checked={!!field.value}
    />
  )
}
