import type { FC, ReactNode } from "react"
import type { ILoanApplication } from "Interfaces/loanApplicationInterfaces"
import { OverviewBox, LoanPerksBox, GrayBox } from "../Shared"
import { LayoutType } from "./data"
import type { OnboardingStepsEnum } from "../Onboarding.types"
import * as S from "./Layout.styles"

export const Layout: FC<{
  data: ILoanApplication
  children: ReactNode
  step: OnboardingStepsEnum
}> = ({ data, children, step }) => {
  return (
    <S.Layout
      initial="hidden"
      animate="visible"
      variants={S.AnimationVariants}
      transition={{ duration: 0.3 }}
      step={step}
    >
      {LayoutType[step] && (
        <S.LeftSection>
          {LayoutType[step].heading && LayoutType[step].heading}
          {LayoutType[step].svg && LayoutType[step].svg}
          {LayoutType[step].grayBox && <GrayBox amount={data.amount} />}
          {LayoutType[step].overview && (
            <OverviewBox
              effectiveInterestRate={+data.effective_interest_rate}
              periodCost={data.period_cost}
              repaymentPeriods={data.repayment_periods}
            />
          )}
          {LayoutType[step].yellowBox && (
            <LoanPerksBox estimatedCashBack={data.estimated_cashback} />
          )}
        </S.LeftSection>
      )}

      {children}
    </S.Layout>
  )
}

export default Layout
