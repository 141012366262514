import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const LoanBanner = styled.button`
  margin-top: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fef8e9;
  border-radius: 15px;
  padding: 1em;
  text-align: center;
  width: 100%;
  .icon-infoBadge {
    width: 18px;
    height: 18px;
    line-height: 0;
  }
  > span {
    font-size: ${font.size.base};
    font-weight: 500;
  }
`
