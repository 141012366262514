export const formats = {
  number: {
    SEK: {
      currency: "SEK",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    DKK: {
      currency: "DKK",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    NOK: {
      currency: "NOK",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    EUR: {
      currency: "EUR",
    },
  },
  currency: {
    SEK: {
      style: "currency" as const,
      currency: "SEK",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    DKK: {
      style: "currency" as const,
      currency: "DKK",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    NOK: {
      style: "currency" as const,
      currency: "NOK",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    EUR: {
      style: "currency" as const,
      currency: "EUR",
    },
  },
}
