import { FC, useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"

import { useFeedbackModal } from "Context/modal-context"
import { useDepositCloseAccount } from "Hooks/API/useDepositServices"
import Button from "UI/Button"
import Loading from "UI/Loading"
import Modal from "UI/Modal"
import { Urls } from "Constants/urls"

import * as S from "./CloseAccountModal.styles"

const CloseAccountModal: FC<{ handleCloseModal: () => void }> = ({
  handleCloseModal,
}) => {
  const navigate = useNavigate()

  const { accountID } = useParams() as { accountID: string }
  const { successModal, errorModal } = useFeedbackModal()

  const { mutateAsync, isLoading } = useDepositCloseAccount(accountID)

  const handleCloseAccount = useCallback(() => {
    mutateAsync({
      uid: accountID,
      toDepositServiceUid: null,
    })
      .then(() => {
        successModal(
          <FormattedMessage id="app.mypages.savings.details.closeAccount.success" />
        )
        navigate(Urls.Savings)
      })
      .catch(() => errorModal())
  }, [mutateAsync, successModal, errorModal, navigate, accountID])

  return (
    <Modal
      maxWidth="600px"
      onClick={!isLoading ? handleCloseModal : () => {}}
      body={
        <S.CloseModalBody>
          <p>
            <FormattedMessage id="app.mypages.savings.details.closeAccount" />
          </p>

          <S.ButtonsWrap>
            <Button
              disabled={isLoading}
              variant="inverse"
              onClick={handleCloseModal}
            >
              <FormattedMessage id="app.common.no" />
            </Button>
            <Button onClick={handleCloseAccount} disabled={isLoading}>
              {isLoading ? (
                <Loading size="small" />
              ) : (
                <FormattedMessage id="app.common.yes" />
              )}
            </Button>
          </S.ButtonsWrap>
        </S.CloseModalBody>
      }
    />
  )
}

export default CloseAccountModal
