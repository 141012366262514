import styled from "styled-components"

export const ContractsBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  order: 1;
`

export const ContractItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 2em;
  border-radius: 15px;
  background-color: rgba(var(--light-yellow-rgb), 0.7);

  > p {
    margin: 0;
  }

  a {
    margin-top: 1em;
  }
`

export const ContractHeading = styled.p`
  font-weight: 700;
`
