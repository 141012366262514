import { FC } from "react"
import { ThemeProvider } from "styled-components"
import ModalContextProvider from "Context/modal-context"
import FeedbackModal from "Components/FeedbackModal"
import { RouterProvider } from "react-router-dom"
import { router } from "Routes"
import { GlobalStyle, lightTheme } from "./Styles/styleGlobal"

const App: FC = () => (
  <ThemeProvider theme={lightTheme}>
    <GlobalStyle />
    <ModalContextProvider>
      <RouterProvider router={router} />
      <FeedbackModal />
    </ModalContextProvider>
  </ThemeProvider>
)

export default App
