import { MessageDescriptor } from "react-intl"
import { IconNameType } from "UI/IconFactory/icons"

export const titleTypes: Record<
  string,
  { text: MessageDescriptor; icon: IconNameType }
> = {
  "transfer-funds": {
    text: {
      id: "app.common.transfer",
      defaultMessage: "Överföring",
    },
    icon: "arrowLeft",
  },
  "funds-transfer": {
    text: {
      id: "app.common.transfer",
      defaultMessage: "Överföring",
    },
    icon: "arrowRight",
  },
  "reserve-funds": {
    text: {
      id: "app.common.withdrawal",
      defaultMessage: "Uttag",
    },
    icon: "arrowLeft",
  },
  "capitalize-accrued_deposit_interest_tax": {
    text: {
      id: "app.common.interestRate.tax",
      defaultMessage: "Skatt",
    },
    icon: "substraction",
  },
  "uncapitalize-accrued_deposit_interest_tax": {
    text: {
      id: "app.common.interestRate.tax.uncapitalize",
      defaultMessage: "Återbetalning av skatt",
    },
    icon: "plus",
  },
  "capitalize-accrued_deposit_interest": {
    text: {
      id: "app.common.interestRate",
      defaultMessage: "Ränta",
    },
    icon: "plus",
  },
  "deposit-funds": {
    text: {
      id: "app.common.deposit",
      defaultMessage: "Insättning",
    },
    icon: "arrowRight",
  },
  withdrawal: {
    text: {
      id: "app.common.withdrawal",
      defaultMessage: "Uttag",
    },
    icon: "arrowLeft",
  },
  deposit: {
    text: {
      id: "app.common.deposit",
      defaultMessage: "Insättning",
    },
    icon: "arrowRight",
  },
  "pay-withdrawal_fee": {
    text: {
      id: "app.common.withdrawalFee",
      defaultMessage: "Uttagsavgift",
    },
    icon: "arrowLeft",
  },
}
