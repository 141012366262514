import { FC } from "react"
import { FormattedMessage } from "react-intl"

import Button from "UI/Button"

import * as S from "./AutogiroDeposit.styles"
import { AutogiroDepositProps } from "../SavingsDeposit.types"

const AutogiroDeposit: FC<AutogiroDepositProps> = ({ handleClose }) => {
  return (
    <S.AutogiroWrap>
      <S.AutogiroHeading>
        <FormattedMessage
          id="app.mypages.savings.deposit.autogiro.heading2"
          defaultMessage="Sätt in pengar med autogiro"
        />
      </S.AutogiroHeading>
      <S.AutogiroTempMessage>
        <p>
          <FormattedMessage
            id="app.mypages.savings.deposit.autogiro.tempMessage1"
            defaultMessage="Just nu arbetar vi med att förbättra denna funktion och stödjer därför inte insättningar via autogiro för tillfället."
          />
        </p>
        <p>
          <FormattedMessage
            id="app.mypages.savings.deposit.autogiro.tempMessage2"
            defaultMessage="Våra övriga alternativ för att sätta in pengar fungerar precis som vanligt."
          />
        </p>
      </S.AutogiroTempMessage>
      <Button onClick={handleClose}>Ok!</Button>
    </S.AutogiroWrap>
  )
}

export default AutogiroDeposit
