import {
  useCreateLoanApplication,
  useGetLoanApplicationFormById,
} from "Hooks/API/useLoansApplications"
import Loading from "UI/Loading"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Urls } from "Constants/urls"
import IconFactory from "UI/IconFactory"
import { LoanApplicationForm } from "Interfaces/loanApplicationInterfaces"
import {
  BankAccountScreening,
  LoanApplicationPayload,
} from "ApiServices/loanApplications"
import GenericError from "UI/GenericError/GenericError"
import { contact } from "Constants/contact"
import ProgressBar from "UI/ProgressBar"
import Hamburger from "UI/Hamburger"
import useTrapFocus from "Hooks/UI/useTrapFocus"
import Sidemenu from "Components/UnauthenticatedNav/SideMenu"
import * as S from "./LoanApplication.styles"
import Form from "./components/Form"
import Tink from "./components/Tink"
import {
  MAX_AMOUNT,
  MAX_REPAYMENT_PERIODS,
  MIN_AMOUNT,
  MIN_REPAYMENT_PERIODS,
} from "./constants"
import {
  LoanApplicationFormError,
  LoanApplicationStepEnum,
} from "./LoanApplication.types"

type LoanApplicationParams = {
  id?: string
}

const LoanApplication = () => {
  const { id } = useParams<LoanApplicationParams>()
  const [searchParams] = useSearchParams()
  const amount = Number(searchParams.get("amount"))
  const repaymentPeriods = Number(searchParams.get("repaymentPeriods"))

  const {
    data: initialData,
    isLoading,
    isError,
    error,
  } = useGetLoanApplicationFormById(id)
  const createLoanApplicationMutation = useCreateLoanApplication()

  const [applicationData, setApplicationData] = useState<LoanApplicationForm>()
  const [isSubmissionError, setIsSubmissionError] = useState<boolean>(false)
  const [isAmountError, setIsAmountError] = useState<boolean>(false)
  const [isRepaymentPeriodsError, setIsRepaymentPeriodsError] =
    useState<boolean>(false)
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)
  const [sidemenuRef, hamburgerRef] = useTrapFocus(isSideMenuOpen)
  const [step, setStep] = useState(0)

  const navigate = useNavigate()

  useEffect(() => {
    validateParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateParams = () => {
    if (!id && (!amount || !repaymentPeriods)) {
      navigate(Urls.Home)
      return
    }

    if (!!amount && (amount > MAX_AMOUNT || amount < MIN_AMOUNT)) {
      setIsAmountError(true)
      return
    }

    if (
      !!repaymentPeriods &&
      (repaymentPeriods > MAX_REPAYMENT_PERIODS ||
        repaymentPeriods < MIN_REPAYMENT_PERIODS)
    ) {
      setIsRepaymentPeriodsError(true)
    }
  }

  const goBack = () => {
    if (step > 0) {
      setStep(step - 1)
    }
  }

  const goNext = (data: LoanApplicationForm) => {
    setApplicationData(data)
    setStep((currentStep) => currentStep + 1)
  }

  const submit = (data: LoanApplicationForm) => {
    setApplicationData(data)
    createLoanApplication(data)
  }

  const onTinkSuccess = (data: Record<string, unknown>) => {
    createLoanApplication(applicationData, data as BankAccountScreening)
  }

  const closeTink = () => {
    setIsSubmissionError(false)
    goBack()
  }

  const createLoanApplication = (
    formData?: LoanApplicationForm,
    tinkData?: BankAccountScreening
  ) => {
    if (!formData) {
      setIsSubmissionError(true)
      return
    }

    if (!id && (!repaymentPeriods || !amount)) {
      setIsSubmissionError(true)
      return
    }

    const payload = buildLoanApplPayload(formData, tinkData)

    createLoanApplicationMutation
      .mutateAsync(payload)
      .then((res) => {
        const { uid } = res
        navigate(`/onboarding/${uid}`)
      })
      .catch(() => setIsSubmissionError(true))
  }

  const closeSideMenu = (e?: TouchEvent | MouseEvent) => {
    if (e && hamburgerRef.current?.contains(e.target as Node)) return
    setIsSideMenuOpen(false)
  }

  const buildLoanApplPayload = (
    formData: LoanApplicationForm,
    tinkData?: BankAccountScreening
  ): LoanApplicationPayload => {
    // Create a copy of formData to avoid mutating the original object
    const loanApplPayload = { ...formData } as LoanApplicationPayload

    // Insert the tink data as bank_account_screening in formData's applicant object
    if (tinkData) {
      loanApplPayload.applicant.bank_account_screening = tinkData
    }

    // Insert the amount and repayment periods from the URL params if they exist
    if (amount && repaymentPeriods) {
      loanApplPayload.amount = amount
      loanApplPayload.repayment_periods = repaymentPeriods
    }

    // If the number of kids is not set, set it to 0
    if (
      loanApplPayload.applicant?.applicant_detail &&
      !loanApplPayload.applicant.applicant_detail?.number_of_kids
    ) {
      loanApplPayload.applicant.applicant_detail.number_of_kids = 0
    }

    return loanApplPayload
  }

  const renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <Form
            initialFormData={applicationData || initialData}
            onNext={id ? goNext : submit}
            amount={amount}
            repaymentPeriods={repaymentPeriods}
            submitText={
              <FormattedMessage
                id="app.mypages.loans.application.tink"
                defaultMessage="Gå vidare med ansökan"
              />
            }
            id={id}
          />
        )
      case 1:
        return (
          <Tink
            legalEntityNumber={
              applicationData?.applicant.legal_entity_number || ""
            }
            closeTink={closeTink}
            showBackButton
            onSuccess={onTinkSuccess}
          />
        )
      default:
        return null
    }
  }

  const toggleIsOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setIsSideMenuOpen((prevIsOpen) => !prevIsOpen)
  }

  const progress = (step / 2) * 100

  const getRenderState = () => {
    if (isLoading || createLoanApplicationMutation.isLoading) {
      return (
        <S.LoadingContainer>
          <Loading isCentered />
        </S.LoadingContainer>
      )
    }

    if (isError) {
      return handleError(error)
    }

    if (isSubmissionError) {
      return (
        <GenericError
          idMessages={{
            header: "app.mypages.loans.application.error.header",
            body: "app.mypages.loans.application.submit.error.body",
            button: "app.mypages.loans.application.submit.error.button",
          }}
          onClick={() => closeTink()}
          values={{ body: { tel: contact.tel, mail: contact.mail } }}
        />
      )
    }

    if (isAmountError) {
      return (
        <GenericError
          idMessages={{
            header: "app.mypages.loans.application.error.header",
            body: "app.mypages.loans.application.params.amount.error.body",
          }}
          onClick={() => navigate(Urls.Home)}
          values={{ body: { min: MIN_AMOUNT, max: MAX_AMOUNT } }}
        />
      )
    }

    if (isRepaymentPeriodsError) {
      return (
        <GenericError
          idMessages={{
            header: "app.mypages.loans.application.error.header",
            body: "app.mypages.loans.application.params.repayment_periods.error.body",
          }}
          onClick={() => navigate(Urls.Home)}
          values={{
            body: { min: MIN_REPAYMENT_PERIODS, max: MAX_REPAYMENT_PERIODS },
          }}
        />
      )
    }

    return renderSteps()
  }

  const handleError = (_error: LoanApplicationFormError) => {
    const loanApplicationStep = _error.response.data.data?.loan_application_step

    switch (loanApplicationStep) {
      case LoanApplicationStepEnum.Onboarding:
        navigate(`/onboarding/${id}`)
        break
      case LoanApplicationStepEnum.Converted:
        navigate(Urls.Home)
        break
      default:
        break
    }

    return (
      <GenericError
        idMessages={{
          header: "app.mypages.loans.application.error.header",
          body: "app.mypages.loans.application.prefill.error.body",
          button: "app.mypages.loans.application.prefill.error.button",
        }}
        onClick={() => window.open(`mailto: ${contact.mail}`)}
        values={{ body: { tel: contact.tel } }}
      />
    )
  }

  return (
    <S.Container>
      <S.Nav>
        {step !== 0 ? (
          <S.BackButton onClick={closeTink}>
            <IconFactory name="arrowLeft" />
          </S.BackButton>
        ) : (
          <Link to={Urls.Home}>
            <IconFactory name="logo" />
          </Link>
        )}

        <Hamburger
          handleClick={toggleIsOpen}
          isOpen={isSideMenuOpen}
          ref={hamburgerRef as React.MutableRefObject<HTMLButtonElement>}
          isBlack
        />

        <Sidemenu
          closeMenu={closeSideMenu}
          isOpen={isSideMenuOpen}
          ref={sidemenuRef as React.MutableRefObject<HTMLDivElement>}
          showLanguages
        />
      </S.Nav>

      <ProgressBar width={progress} />

      <h1>
        <FormattedMessage
          id="app.mypages.loans.application.title"
          defaultMessage="Låneansökan"
        />
      </h1>

      {getRenderState()}
    </S.Container>
  )
}

export default LoanApplication
