const PREFIX = "brocc-"

export const ACCESS_TOKEN = `${PREFIX}auth-token`
export const REFRESH_TOKEN = `${PREFIX}refresh-token`
export const LOGIN_TIMESTAMP_IN_MS = `${PREFIX}login-timestamp-in-ms`
export const TOKEN_EXPIRES_IN_SECONDS = `${PREFIX}token-expires-in-seconds`
export const SIGNATURE_CODE = `${PREFIX}signature-code`
export const LANGUAGE = `${PREFIX}language`
export const POSTPONED_KYC = `${PREFIX}postponed-kyc`
export const DISABLE_WELCOME_TEXT = `${PREFIX}disable-welcome-text`
export const SAVINGS_ONBOARDING = `${PREFIX}savings-onboarding`
export const INVESTMENT_ONBOARDING = `${PREFIX}investment-onboarding`
export const HAS_CLEARED_BANK_ACCOUNT_SCREENING = `${PREFIX}has-cleared-bank-account-screening`
export const HAS_CLEARED_INSTANTOR = `${PREFIX}has-сleared-instantor`
export const SESSION_STORAGE_ID = `${PREFIX}session-id`
