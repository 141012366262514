import { useMemo } from "react"
import { useIntl } from "react-intl"

export const useLoanPurposeOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      { id: 0, name: "-", value: "-" },
      {
        id: 1,
        name: intl.formatMessage({
          id: "app.common.loanOptions.consolidateLoans",
          defaultMessage: "Samla befintliga lån",
        }),
        value: "consolidate_existing_loans",
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "app.common.loanOptions.capitalPurchase",
          defaultMessage: "Fordonsköp/Elektronikköp",
        }),
        value: "capital_purchase",
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "app.common.loanOptions.capitalImprovements",
          defaultMessage: "Renovering",
        }),
        value: "capital_improvement",
      },
      {
        id: 4,
        name: intl.formatMessage({
          id: "app.common.loanOptions.capitalRepair",
          defaultMessage: "Reparation",
        }),
        value: "capital_repair",
      },
      {
        id: 5,
        name: intl.formatMessage({
          id: "app.common.loanOptions.consumption",
          defaultMessage: "Konsumtion",
        }),
        value: "consumption",
      },
      {
        id: 6,
        name: intl.formatMessage({
          id: "app.common.loanOptions.equityDownPayment",
          defaultMessage: "Kontantinsats",
        }),
        value: "equity_down_payment",
      },
      {
        id: 7,
        name: intl.formatMessage({
          id: "app.common.loanOptions.purchaseOfService",
          defaultMessage: "Köp av tjänst",
        }),
        value: "purchase_of_service",
      },
      {
        id: 8,
        name: intl.formatMessage({
          id: "app.common.loanOptions.furniture",
          defaultMessage: "Möbler",
        }),
        value: "furnitures",
      },
      {
        id: 9,
        name: intl.formatMessage({
          id: "app.common.occupationOptions.unemployed",
          defaultMessage: "Arbetslös",
        }),
        value: "other",
      },
    ],
    [intl]
  )
}

export const useNumberOfKidsOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        id: 0,
        name: intl.formatMessage({
          id: "app.common.numberOfKids",
          defaultMessage: "Antal Barn",
        }),
        value: "",
      },
      { id: 1, name: "0", value: 0 },
      { id: 2, name: "1", value: 1 },
      { id: 3, name: "2", value: 2 },
      { id: 4, name: "3", value: 3 },
      { id: 5, name: "4", value: 4 },
      { id: 6, name: "5", value: 5 },
      { id: 7, name: "6", value: 6 },
      { id: 8, name: "7", value: 7 },
      { id: 9, name: "8", value: 8 },
    ],
    [intl]
  )
}

export const useHousingOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        id: 0,
        name: intl.formatMessage({
          id: "app.common.housingType",
          defaultMessage: "Boendeform",
        }),
        value: "",
      },
      {
        id: 1,
        name: intl.formatMessage({
          id: "app.common.housingOptions.rental",
          defaultMessage: "Hyresrätt",
        }),
        value: "rental",
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "app.common.housingOptions.house",
          defaultMessage: "Villa eller Radhus",
        }),
        value: "house",
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "app.common.housingOptions.condominum",
          defaultMessage: "Bostadsrätt",
        }),
        value: "condominium",
      },
      {
        id: 4,
        name: intl.formatMessage({
          id: "app.common.housingOptions.tenant",
          defaultMessage: "Inneboende",
        }),
        value: "tenant",
      },
    ],
    [intl]
  )
}

export const useDetailMaritalStatusOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        id: 0,
        name: intl.formatMessage({
          id: "app.common.maritalStatus",
          defaultMessage: "Civilstånd",
        }),
        value: "",
      },
      {
        id: 1,
        name: intl.formatMessage({
          id: "app.common.maritalOptions.single",
          defaultMessage: "Ensamstående",
        }),
        value: "single",
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "app.common.maritalOptions.livingTogether",
          defaultMessage: "Sambo",
        }),
        value: "living_together",
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "app.common.maritalOptions.married",
          defaultMessage: "Gift",
        }),
        value: "married",
      },
      {
        id: 4,
        name: intl.formatMessage({
          id: "app.common.maritalOptions.divorced",
          defaultMessage: "Skild",
        }),
        value: "divorced",
      },
    ],
    [intl]
  )
}

export const useDetailOccupationOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: "app.common.occupation",
          defaultMessage: "Sysselsättning",
        }),
        value: "",
        id: 0,
      },
      {
        name: intl.formatMessage({
          id: "app.common.occupationOptions.employed",
          defaultMessage: "Anställd",
        }),
        value: "full_time",
        id: 1,
      },
      {
        name: intl.formatMessage({
          id: "app.common.occupationOptions.selfEmployed",
          defaultMessage: "Egenföretagare",
        }),
        value: "self_employed",
        id: 2,
      },
      {
        name: intl.formatMessage({
          id: "app.common.occupationOptions.unemployed",
          defaultMessage: "Arbetslös",
        }),
        value: "unemployed",
        id: 3,
      },
      {
        name: intl.formatMessage({
          id: "app.common.occupationOptions.student",
          defaultMessage: "Student",
        }),
        value: "student",
        id: 4,
      },
      {
        name: intl.formatMessage({
          id: "app.common.occupationOptions.pensions",
          defaultMessage: "Pensionär",
        }),
        value: "early_pensioner",
        id: 5,
      },
      {
        name: intl.formatMessage({
          id: "app.common.occupationOptions.finiteTime",
          defaultMessage: "Provanställd",
        }),
        value: "finite_time",
        id: 6,
      },
      {
        name: intl.formatMessage({
          id: "app.common.occupationOptions.hourlyEmployed",
          defaultMessage: "Timmanställd",
        }),
        value: "hourly",
        id: 7,
      },
    ],
    [intl]
  )
}
