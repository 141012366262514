import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const Wrap = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  h1 {
    margin-top: 10px;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }

  @media screen and (max-width: ${mediaQueries.medium}) {
    svg {
      width: 90%;
      gap: 0;
    }
    p {
      font-size: 16px;
    }
    h1 {
      margin-top: 0;
    }
  }
`

export const Heading = styled.h1`
  color: black;
`
