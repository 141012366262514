import { FormattedMessage } from "react-intl"

import { StyledNotFound } from "./Error.styles"

const Error = () => {
  return (
    <StyledNotFound data-cy="404">
      <h1>
        <FormattedMessage id="app.common.error.header" />
      </h1>
      <p>
        <FormattedMessage id="app.common.error.body" />
      </p>
    </StyledNotFound>
  )
}

export default Error
