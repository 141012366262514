import { FC, useCallback } from "react"
import { IDepositService } from "Interfaces/depositInterfaces"
import { useIntl } from "react-intl"
import Radio from "UI/Radio"

type RadioItemPropTypes = {
  checked: boolean
  onChange: (id: string) => void
  account: IDepositService
}

const RadioItem: FC<RadioItemPropTypes> = ({ checked, onChange, account }) => {
  const intl = useIntl()
  const handleChecked = useCallback(
    () => onChange(account.uid),
    [account, onChange]
  )
  return (
    <Radio
      className="checkbox"
      key={account.uid}
      labelText={`${account.product_number}, ${
        account.name ||
        intl.formatMessage({
          id: "app.common.account.savingsAccount",
          defaultMessage: "Sparkonto",
        })
      }, ${account.customer.full_name}`}
      checked={checked}
      name="savings"
      value={account.uid}
      onChange={handleChecked}
    />
  )
}

export default RadioItem
