import type { FC } from "react"
import { FormattedMessage } from "react-intl"

import IconFactory from "UI/IconFactory"
import CreateDepositApplication from "Pages/Authenticated/Savings/Accounts/CreateDepositApplication"
import { EmptyServiceWrap } from "../EmptyService.styles"

const EmptySavings: FC = () => (
  <EmptyServiceWrap type="savings">
    <div>
      <h2>
        <FormattedMessage
          id="app.mypages.savings.accounts.noAccounts"
          defaultMessage="Öppna sparkonto enkelt!"
        />
      </h2>
      <p>
        <FormattedMessage
          id="app.mypages.main.savingsDescription"
          defaultMessage="Våra sparkonton passar dig som vill ha ett säkert sparande med låg risk men med högre sparränta än på ditt vanliga bankkonto. Hos Brocc Finance AB (f.d. GCC Capital) är dina pengar i trygga händer."
        />
      </p>
      <ul>
        <li>
          <IconFactory name="security" />
          <p>
            <FormattedMessage
              id="app.mypages.main.savingsDescriptionList.item1"
              defaultMessage="Statlig insättningsgaranti"
            />
          </p>
        </li>
        <li>
          <IconFactory name="discount" />
          <p>
            <FormattedMessage
              id="app.mypages.main.savingsDescriptionList.item2"
              defaultMessage="Månatliga ränteutbetalningar"
            />
          </p>
        </li>
        <li>
          <IconFactory name="like" />
          <p>
            <FormattedMessage
              id="app.mypages.main.savingsDescriptionList.item3"
              defaultMessage="Öppna konto tryggt och enkelt med BankID"
            />
          </p>
        </li>
      </ul>
      <CreateDepositApplication
        createButtonText={
          <FormattedMessage
            id="app.mypages.savings.accounts.openAccount"
            defaultMessage="Öppna sparkonto"
          />
        }
      />
    </div>
    <IconFactory name="manPhonePurple" />
  </EmptyServiceWrap>
)

export default EmptySavings
