import styled from "styled-components"

export const InsurancePromotionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  width: 100%;
  border-radius: 16px;
  background-color: #f7f7f7;
`

export const PromotionText = styled.span`
  flex: 1;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  color: #000;
`
