import { v4 as uuidv4 } from "uuid"
import { LoanCollection } from "ApiServices/loanApplications"
import { IScrappingLoanBank } from "Interfaces/iceingInterfaces"
import {
  Loan,
  Installment,
  Credit,
  SelectedLoans,
} from "../LoansList/LoanItem/LoanItem.types"
import { OtherActorFormValues } from "../OtherActorForm/OtherActorFormValues.types"

interface LoanType {
  loan: string
  installment: string
  credit: string
}

type TypeOfLoan = Loan[] | Installment[] | Credit[]

const loanType: LoanType = {
  loan: "Lån",
  installment: "Avbetalning",
  credit: "Kredit",
}

export const getLoanByType = (loans: TypeOfLoan, type: string) =>
  loans.map((loan) => {
    const id = `${loanType[
      `${type as keyof LoanType}`
    ].toLowerCase()}-${uuidv4()}`

    return {
      ...loan,
      id,
      loanType: loanType[`${type as keyof LoanType}`],
    }
  })

export const transformExistingLoans = (loan: SelectedLoans): LoanCollection => {
  let balance = 0
  let interestRate: number | bigint = 0

  if (loan.balance) {
    balance = loan.balance
  }

  if ("interestRate" in loan && loan.interestRate) {
    interestRate = loan.interestRate
  }

  return {
    amount: balance,
    creditor: loan.bankName,
    payment_to: loan.accountNumber,
    ocr: loan.paymentReference,
    type: loan.accountType?.toLowerCase() || "",
    to_resolve: true,
    loan_type: loan.loanType,
    interest: interestRate,
    monthly_cost:
      "transactions" in loan && loan.transactions.length > 0
        ? loan.transactions[0].amount
        : 0,
    verified: loan.verified,
  }
}

export const transformToSelectedLoan = (values: OtherActorFormValues) => {
  return {
    id: `${values.loanType.toLowerCase()}-${uuidv4()}`,
    accountNumber: values.bankAccount,
    accountType: values.accountType,
    bankName: values.bankName,
    paymentReference: values.paymentReference,
    loanType: values.loanType,
    loanNumber: "",
    grantedCredit: 0,
    balance: values.totalAmount,
    interestRate: values.interestRate / 100,
    monthlyCost: values.monthlyCost,
    transactions: [
      {
        transactionDate: new Date().toString(),
        description: "Annan kreditgivare",
        amount: values.monthlyCost,
      },
    ],
    coApplicant: "NO",
    insurance: null,
    automaticPayment: null,
    administrationFee: null,
    loanCreationDate: new Date().toString(),
    loanEndDate: null,
    accruedDebtInterest: null,
    unpaidFees: null,
    lastPdfBase64: null,
    verified: false,
  }
}

export const removeBankByName = (
  banks: IScrappingLoanBank[],
  bankName: string
) => banks.filter((item) => item.bankName !== bankName)
