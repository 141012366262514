import type { IInvoices } from "Interfaces/invoicesInterfaces"
import axios from "ApiServices/axios"
import { Api } from "Constants/api"
import type { AxiosError } from "axios"

export const getInvoices = async (page: number) => {
  try {
    const { data } = await axios.get<IInvoices>(Api.Invoices, {
      params: {
        page,
      },
    })

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getInvoicePdf = async (id: string) => {
  try {
    const { data } = await axios.get(Api.InvoicePdf.replace(":id", id), {
      responseType: "blob",
    })
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}
