import { FieldValues, useController } from "react-hook-form"

import Slider from "../../UI/Slider/Slider"
import { SliderInputProps } from "./SliderInput.types"

const SliderInput = <FieldsType extends FieldValues>({
  name,
  control,
  rules,
  error,
  message,
  defaultValue,
  min,
  max,
  step,
  onChange,
}: SliderInputProps<FieldsType>) => {
  const { field, fieldState } = useController<FieldsType>({
    name,
    rules,
    control,
    defaultValue,
  })

  return (
    <Slider
      {...field}
      min={min}
      max={max}
      step={step}
      type="range"
      defaultValue={defaultValue}
      error={!!fieldState.error || error}
      message={fieldState.error?.message || message}
      onChange={onChange}
    />
  )
}

export default SliderInput
