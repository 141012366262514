import { useEffect, useMemo, useState } from "react"
import { Outlet } from "react-router-dom"

import MainNav from "Components/MainNav"
import Menu from "Components/MainNav/Menu"
import UpdateKYC from "Components/UpdateKYC"
import type { StatusType } from "Interfaces/KYCInterfaces"
import { useGetUser } from "Hooks/API/useUser"
import { useGetStatusKYC } from "Hooks/API/useKYC"
import { POSTPONED_KYC } from "Constants/storage"
import { getStorageItem } from "Utils/localStorage"
import { redirectToTarget } from "Utils"
import * as S from "./MyPages.styles"

const MyPages = () => {
  const { data } = useGetStatusKYC()
  const { data: user, isSuccess } = useGetUser()

  const [isPostponedKYC] = useState<boolean>(() =>
    Boolean(getStorageItem(POSTPONED_KYC))
  )
  const [show, setShow] = useState(!isPostponedKYC)

  const showBanner = useMemo(
    () => data?.status !== "ok" && !isPostponedKYC,
    [data?.status, isPostponedKYC]
  )

  useEffect(() => {
    redirectToTarget()
  }, [])

  if (isSuccess && showBanner && data && show) {
    return (
      <UpdateKYC
        setShow={setShow}
        user={user}
        status={data.status as Omit<StatusType, "ok">}
      />
    )
  }

  return (
    <>
      <MainNav />
      <S.Layout>
        <S.SideNav>
          <Menu />
        </S.SideNav>
        <S.Main>
          <S.MainContainer>
            <Outlet />
          </S.MainContainer>
        </S.Main>
      </S.Layout>
    </>
  )
}

export default MyPages
