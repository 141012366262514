import type { FC } from "react"
import { useIntl } from "react-intl"

import { formatNumber } from "Utils/formatNumber"
import type { BalanceItemProps } from "../BalanceOverview.types"
import * as S from "./BalanceItem.styles"

const BalanceItem: FC<BalanceItemProps> = ({ value, style, text }) => {
  const intl = useIntl()
  return (
    <S.BalanceItem>
      <S.BalanceTitle>{intl.formatMessage(text)}</S.BalanceTitle>
      <S.BalanceAmount>{formatNumber(value, { style })}</S.BalanceAmount>
    </S.BalanceItem>
  )
}

export default BalanceItem
