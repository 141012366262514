import { FormattedMessage } from "react-intl"
import IconFactory from "UI/IconFactory"
import * as S from "./PendingAgreement.styles"

const PendingAgreement = () => (
  <S.Wrap>
    <S.Heading>
      <FormattedMessage
        id="app.mypages.savings.onBoarding.agreement.pending.heading"
        defaultMessage="Strax klar!"
      />
    </S.Heading>

    <IconFactory name="manOkSign" />

    <p>
      <FormattedMessage
        id="app.mypages.savings.onBoarding.agreement.pending"
        defaultMessage="Vi behöver utvärdera dina svar innan du kan fortsätta. Håll koll på din mailkorg!"
      />
    </p>
  </S.Wrap>
)

export default PendingAgreement
