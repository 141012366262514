import { useCallback, useState } from "react"
import { FormattedMessage } from "react-intl"
import Button from "UI/Button"
import KYCSuccess from "Components/KYCSuccess"
import Form from "Components/KYC/Form"
import { useInvestmentServices } from "Hooks/API/useInvestmentServices"
import { useGetDepositServices } from "Hooks/API/useDepositServices"

const KYCForm = () => {
  const [showSuccess, setShowSuccessKyc] = useState<boolean>(false)

  const { data: investments } = useInvestmentServices()
  const { data: deposits } = useGetDepositServices()

  const handleClickSuccess = useCallback(
    () => setShowSuccessKyc(!showSuccess),
    [setShowSuccessKyc, showSuccess]
  )

  const hasDepositOrInvestment = Boolean(
    investments?.data.length || deposits?.data.length
  )

  if (showSuccess) {
    return (
      <KYCSuccess>
        <Button variant="inverse" onClick={handleClickSuccess}>
          <FormattedMessage
            id="app.mypages.kyc.success.btnMessage"
            defaultMessage="Ändra mina svar"
          />
        </Button>
      </KYCSuccess>
    )
  }

  return (
    <Form
      submitCallback={handleClickSuccess}
      hasDepositOrInvestment={hasDepositOrInvestment}
      buttonText={
        <FormattedMessage
          id="app.mypages.account.kyc.updateKycInfo"
          defaultMessage="Spara mina svar"
        />
      }
    />
  )
}
export default KYCForm
