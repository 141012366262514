import styled from "styled-components"
import { mediaQueries, font } from "Styles/styleGlobal"
import type { EmptyServicePropsType } from "./EmptyService"

export const EmptyServiceWrap = styled.div<EmptyServicePropsType>`
  align-items: center;
  margin: auto 0;
  text-align: left;
  gap: 50px;
  display: flex;
  flex-direction: row;
  h2 {
    margin: 0px 0px 1em;
    font-size: ${font.size.xl2};
  }
  p {
    margin: 0;
  }
  & > svg {
    position: relative;
    width: 250px;
    top: -20px;
    min-width: 250px;
  }

  a {
    display: inline-block;
    margin: 0;
    button {
      margin: 0;
    }
  }
  ul {
    margin: 1.5em 0;
    & + {
      button {
        margin: 0;
      }
    }
    li {
      list-style: none;
      margin-bottom: 0.8em;
      line-height: 28px;
      align-items: center;
      text-align: left;
      display: grid;
      grid-template-columns: 40px 1fr;
      gap: 10px;
      p {
        font-weight: 600;
        margin: 0;
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    grid-template-columns: 1fr;
    & > svg {
      display: none;
    }
  }
`
