import { NavLink } from "react-router-dom"
import type { FC } from "react"

import IconFactory from "UI/IconFactory"
import * as S from "./MenuLink.styles"
import type { MenuLinkPropsType } from "./MenuLink.types"

const MenuLink: FC<MenuLinkPropsType> = ({ children, ...rest }) => {
  return (
    <S.NavLink
      as={"href" in rest ? "a" : NavLink}
      {...(rest as MenuLinkPropsType)}
    >
      {children}
      <IconFactory name="chevronRight" />
    </S.NavLink>
  )
}

export default MenuLink
