export type LoadingPropTypes = {
  isCentered?: boolean
  withMessage?: boolean
} & StyledSpinnerPropTypes

export type StyledSpinnerPropTypes = {
  size?: LoadingSize
}

export enum LoadingSizeEnum {
  ExtraSmall = "extra-small",
  Small = "small",
}

type LoadingSize = `${LoadingSizeEnum}`
