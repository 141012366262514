/* eslint-disable no-console */
import { jsonParse } from "./json"

const hasLocalStorage = (): boolean => "localStorage" in window

export function getStorageItem(key: string): string | null {
  if (!hasLocalStorage()) {
    return null
  }
  return localStorage.getItem(key)
}

export function getStorageItemOrDefault<T extends string>(
  key: string,
  defaultItem: T
): T {
  const item = getStorageItem(key)
  if (item === null) {
    return defaultItem
  }
  return item as T
}

export function getParsedStorageItem<T>(key: string): T | null {
  const item = getStorageItem(key)
  return item ? jsonParse<T>(item) : null
}

export function getParsedStorageItemOrDefault<T>(
  key: string,
  defaultItem: T
): T {
  const item = getParsedStorageItem<T>(key)
  if (item === null) {
    return defaultItem
  }
  return item
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setStorageItem(key: string, value: any): void {
  if (!hasLocalStorage()) {
    console.warn("LocalStorage is not available")
    return
  }
  if (value === null || value === undefined) {
    return
  }
  try {
    localStorage.setItem(
      key,
      typeof value === "object" ? JSON.stringify(value) : value
    )
  } catch (e) {
    console.error("Set storage error", e)
  }
}

export function removeStorageItem(key: string): void {
  if (!hasLocalStorage()) {
    console.warn("LocalStorage is not available")
    return
  }
  localStorage.removeItem(key)
}
