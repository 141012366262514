import { memo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useController, useFieldArray, useFormContext } from "react-hook-form"

import Button from "UI/Button"
import IconFactory from "UI/IconFactory"
import { useCountriesOptions } from "Components/KYC/options"
import { SelectField } from "Components/form/SelectField"
import * as S from "Components/KYC/Form/Form.styles"

const KYCCitizenship = () => {
  const intl = useIntl()
  const form = useFormContext()

  const countriesOptions = useCountriesOptions()
  const { fieldState } = useController({
    control: form.control,
    name: "citizenship",
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "citizenship",
  })

  const validateCitizenshipField = () => form.trigger("citizenship")
  const addCitizenshipRow = () => append({ countryCode: "" })

  return (
    <S.CitizenContainer>
      <S.CitizenSelectWrap>
        {fields.map((field, index) => {
          return (
            <S.SelectWrap key={field.id}>
              <SelectField
                rules={{ onChange: validateCitizenshipField }}
                error={!!fieldState.error}
                control={form.control}
                name={`citizenship.${index}.countryCode`}
                options={countriesOptions}
                aria-label={intl.formatMessage({
                  id: "app.common.citizenship",
                  defaultMessage: "Medborgarskap",
                })}
                placeholderOption={{
                  value: "",
                  id: "",
                  name: (
                    <FormattedMessage
                      id="app.common.citizenship"
                      defaultMessage="Medborgarskap"
                    />
                  ),
                }}
              />
              {index > 0 && (
                <button type="button" onClick={() => remove(index)}>
                  <IconFactory name="trash" />
                </button>
              )}
            </S.SelectWrap>
          )
        })}
        {fieldState.error?.message && (
          <S.ErrorMessage>{fieldState.error?.message}</S.ErrorMessage>
        )}
        <Button
          onClick={addCitizenshipRow}
          variant="inverse"
          type="button"
          data-name="citizenship"
        >
          <FormattedMessage
            id="app.mypages.investments.onboarding.KYC.addCitizenship"
            defaultMessage="Lägg till ytterligare medborgarskap"
          />
        </Button>
      </S.CitizenSelectWrap>
    </S.CitizenContainer>
  )
}

export default memo(KYCCitizenship)
