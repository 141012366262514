import { FC, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useLangContext } from "Context/lang-context"
import { languageIcons, languageOptions, LocaleType } from "Constants/languages"
import Radio from "UI/Radio"
import {
  LanguageInfo,
  LanguageWrap,
  LanguageItem,
  IconWrap,
} from "./Language.styles"

const Language: FC = () => {
  const { selectLanguage, locale } = useLangContext()
  const [language, setLanguage] = useState(locale)
  const handleChange = (value: LocaleType) => setLanguage(value)

  useEffect(() => selectLanguage(language), [language, selectLanguage])

  return (
    <LanguageWrap>
      <LanguageInfo>
        <FormattedMessage
          id="app.mypages.accountSettings.language.selectLanguage"
          defaultMessage="Du kan när som helst välja att byta språk."
        />
      </LanguageInfo>
      {languageOptions.map((item) => (
        <LanguageItem key={item.language}>
          <IconWrap bg={languageIcons[item.value]} />
          <Radio
            value={language}
            checked={language === item.value}
            onChange={() => handleChange(item.value)}
            labelText={item.language}
          />
        </LanguageItem>
      ))}
    </LanguageWrap>
  )
}
export default Language
