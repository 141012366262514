import type { FC } from "react"
import EmptyLoan from "./EmptyLoans"
import EmptySavings from "./EmptySavings"

export type EmptyServicePropsType = {
  type: "savings" | "loans"
}

const ELEMENTS = {
  loans: EmptyLoan,
  savings: EmptySavings,
}

const EmptyService: FC<EmptyServicePropsType> = ({ type }) => {
  const Element = ELEMENTS[type]
  return <Element />
}

export default EmptyService
