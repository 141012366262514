import { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import * as yup from "yup"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { account } from "se-bank-account"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { TextField } from "Components/form/TextField"
import Button from "UI/Button"
import {
  useGetDepositServiceByID,
  useUpdateBankAccountManually,
} from "Hooks/API/useDepositServices"
import { useParams } from "react-router-dom"
import * as S from "./ManualVerification.styles"

type BankAccountManual = {
  clearing_number: string
  account_number: string
}

const BankAccountDetailsSchema = yup.object().shape({
  clearing_number: yup.string().required(),
  account_number: yup.string().required(),
})

const ManualVerification: FC<{ handleNextStep: () => void }> = ({
  handleNextStep,
}) => {
  const { accountID: uid } = useParams() as { accountID: string }
  const intl = useIntl()

  const updateBankAccountManually = useUpdateBankAccountManually(uid)

  const { data } = useGetDepositServiceByID(uid)

  const { control, handleSubmit, getValues, setError } = useForm({
    mode: "onChange",
    defaultValues:
      data?.bank_account?.bank_account_number &&
      data?.bank_account?.bank_clearing_number
        ? {
            account_number: data?.bank_account.bank_account_number,
            clearing_number: data?.bank_account.bank_clearing_number,
          }
        : {
            account_number: "",
            clearing_number: "",
          },
    resolver: yupResolver(BankAccountDetailsSchema),
  })

  const validateAccount = () => {
    const values = getValues()
    const valid = account(`${values.clearing_number}-${values.account_number}`)

    return valid
  }

  const onSubmit = (values: BankAccountManual) => {
    if (validateAccount()) {
      updateBankAccountManually.mutateAsync(values).then(() => {
        handleNextStep()
      })
    } else {
      const message = intl.formatMessage({
        id: "app.mypages.savings.onBoarding.accountVerification.manual.validation.error",
      })

      setError("clearing_number", { message: "" })
      setError("account_number", { message })
    }
  }

  return (
    <S.Wrap>
      <S.Warning>
        <FormattedMessage
          id="app.mypages.savings.onBoarding.accountVerification.infoMessage"
          defaultMessage="Vänligen observera att vi behöver granska dessa uppgifter manuellt för att säkerställa att du är kontoinnehavare. Detta på grund av lagkrav (2017:630) som gäller vår kännedom om dig som kund. Tyvärr tar detta några bankdagar. Du kan undvika detta genom att ändra verifieringsmetod till Tink."
        />
      </S.Warning>

      <S.Message>
        <FormattedMessage
          id="app.mypages.savings.onBoarding.accountVerification.manual.message"
          defaultMessage="Ange det konto du vill genomföra uttag till."
        />
      </S.Message>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          control={control}
          name="clearing_number"
          type="number"
          placeholder={
            <FormattedMessage
              id="app.onboarding.bankAccountDetails.input.clearingNumber"
              defaultMessage="Clearingnummer"
            />
          }
        />

        <TextField
          control={control}
          type="number"
          name="account_number"
          placeholder={
            <FormattedMessage
              id="app.mypages.savings.settings.input.accountNumber"
              defaultMessage="Kontonummer"
            />
          }
        />

        <Button
          type="submit"
          isLoading={updateBankAccountManually.isLoading}
          disabled={updateBankAccountManually.isLoading}
        >
          <FormattedMessage
            id="app.mypages.investments.onboarding.KYC.nextCTA"
            defaultMessage="Gå vidare"
          />
        </Button>
      </S.Form>
    </S.Wrap>
  )
}

export default ManualVerification
