import { FC, useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import { AnimatePresence } from "framer-motion"

import Button from "UI/Button"
import Modal from "UI/Modal"
import BalanceOverview from "Components/BalanceOverview"
import { BalanceItemProps } from "Components/BalanceOverview/BalanceOverview.types"
import { InsurancePromotion } from "Components/InsurancePromotion/InsurancePromotion"
import LoansFeatures from "Components/LoansFeatures"
import { useGetInsuranceEligibilities } from "Hooks/API/useInurance"
import ChangeTermModal from "./ChangeTermModal"
import { LoanItemPropTypes } from "./LoanItem.types"
import * as S from "./LoanItem.styles"

const LoanItem: FC<LoanItemPropTypes> = ({ loan }) => {
  const [isChangeTermModalOpen, setIsChangeTermModalOpen] = useState(false)
  const insuranceEligibilitiesQuery = useGetInsuranceEligibilities()
  const [insuranceCompleted, setInsuranceCompleted] = useState(false)

  const handleChangeTermModal = () => {
    setIsChangeTermModalOpen(!isChangeTermModalOpen)
  }

  const data: BalanceItemProps[] = useMemo(
    () => [
      {
        type: "currency",
        value: loan.remaining_principal,
        style: "currency",
        text: {
          id: "app.mypages.loans.loanRemainingAmoubt",
          defaultMessage: "Återstående lånebelopp",
        },
      },
      {
        type: "number",
        value: +loan.loan_interest_rate,
        style: "percent",
        text: {
          id: "app.common.interestRate",
          defaultMessage: "Ränta",
        },
      },
      {
        type: "currency",
        value: loan.current_contract.period_cost,
        style: "currency",
        text: {
          id: "app.common.monthlyCost",
          defaultMessage: "Månadskostnad",
        },
      },
    ],
    [
      loan.current_contract.period_cost,
      loan.loan_interest_rate,
      loan.remaining_principal,
    ]
  )

  const showInsurancePromotion = useMemo(() => {
    return Boolean(
      !insuranceCompleted &&
        loan.is_eligible_for_insurance &&
        insuranceEligibilitiesQuery.data?.is_eligible
    )
  }, [loan, insuranceEligibilitiesQuery.data?.is_eligible, insuranceCompleted])

  return (
    <>
      <AnimatePresence>
        {isChangeTermModalOpen && (
          <Modal
            fullWidth
            maxWidth="700px"
            title={
              <FormattedMessage
                id="app.mypages.loans.changeTermModal.header"
                defaultMessage="Ändra löptid"
              />
            }
            body={
              <ChangeTermModal loan={loan} closeModal={handleChangeTermModal} />
            }
            onClick={handleChangeTermModal}
          />
        )}
      </AnimatePresence>
      <S.LoanItemWrap data-testid="loan-item">
        {showInsurancePromotion && (
          <InsurancePromotion
            loanId={loan.uid}
            onSuccess={() => setInsuranceCompleted(true)}
          />
        )}
        <BalanceOverview data={data} />
        <S.LoanFooter>
          <S.ButtonWrap>
            <Button onClick={handleChangeTermModal}>
              <FormattedMessage
                id="app.mypages.loanSuggestions.changeTermTime"
                defaultMessage="Ändra löptid"
              />
            </Button>
          </S.ButtonWrap>
        </S.LoanFooter>

        <LoansFeatures />
      </S.LoanItemWrap>
    </>
  )
}

export default LoanItem
