import { MessageDescriptor } from "react-intl"

export type LayoutTypes = "account" | "transactions" | "assets"

export const headings: Record<
  LayoutTypes,
  Record<string, MessageDescriptor>
> = {
  account: {
    kyc: {
      id: "app.mypages.mainNav.kyc",
      defaultMessage: "Kundkännedom",
    },
    reports: {
      id: "app.mypages.reports.mainHeader",
      defaultMessage: "Rapporter",
    },
    information: {
      id: "app.mypages.account.personalInformation",
      defaultMessage: "Kontoinformation",
    },
    language: {
      id: "app.common.language",
      defaultMessage: "Språk",
    },
  },
  transactions: {
    deposit: {
      id: "app.common.deposit",
      defaultMessage: "Insättning",
    },
    withdraw: {
      id: "app.mypages.investments.transactions.optionsNav.withdraw",
      defaultMessage: "Uttag",
    },
    "registered-deposits": {
      id: "app.mypages.investments.transactions.optionsNav.registeredDepositsAndWithdrawls",
      defaultMessage: "Registrerade insättningar/uttag",
    },
  },
  assets: {
    "sell-assets": {
      id: "app.mypages.investments.sellAssets.title",
      defaultMessage: "Sälj innehav",
    },
    "prepare-for-sale": {
      id: "app.mypages.investments.assets.myAssets.link.prepareForSale",
      defaultMessage: "Utlagt till försäljning",
    },
    "ended-assets": {
      id: "app.mypages.investments.assets.myAssets.link.endedAssets",
      defaultMessage: "Avslutade innehav",
    },
  },
}
