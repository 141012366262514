import { forwardRef, Ref } from "react"
import { HamburgerPropTypes } from "./Hamburger.types"
import { HamburgerButton } from "./Hamburger.styles"

const Hamburger = forwardRef(
  (props: HamburgerPropTypes, ref: Ref<HTMLButtonElement>) => {
    const { handleClick, isOpen, isBlack } = props
    return (
      <HamburgerButton
        aria-label="Side Menu"
        className={`${props.isOpen ? "opened" : ""}`}
        onClick={(e) => handleClick(e)}
        isOpen={isOpen}
        ref={ref}
        isBlack={isBlack}
        data-testid="hamburger"
      >
        <svg viewBox="0 0 100 100">
          <path
            className="line line1"
            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
            strokeLinecap="round"
          />
          <path className="line line2" d="M 20,45 H 80" strokeLinecap="round" />
          <path
            className="line line3"
            d="M 20,60.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
            strokeLinecap="round"
          />
        </svg>
      </HamburgerButton>
    )
  }
)

Hamburger.displayName = "Hamburger"

export default Hamburger
