import { FieldValues, useController } from "react-hook-form"

import Select from "UI/Select"
import { SelectFieldProps } from "./SelectField.types"

export const SelectField = <FieldsType extends FieldValues>({
  control,
  name,
  rules,
  defaultValue,
  error,
  ...rest
}: SelectFieldProps<FieldsType>) => {
  const { field, fieldState } = useController<FieldsType>({
    name,
    rules,
    control,
    defaultValue,
  })

  return (
    <Select
      {...rest}
      {...field}
      error={error || !!fieldState.error}
      message={fieldState.error?.message}
    />
  )
}
