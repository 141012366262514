import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const EmptyInvestmentsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0;
  text-align: center;
  h2 {
    font-size: ${font.size.xl2};
    margin: 0px;
  }

  p {
    max-width: 80%;
    margin: 10px auto;
  }
  svg {
    width: 300px;
  }
`
