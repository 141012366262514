import styled from "styled-components"
import { font } from "Styles/styleGlobal"
import { StyledTextAreaPropTypes } from "./TextArea.types"

export const TextAreaWrap = styled.div`
  width: 100%;
`

export const StyledTextArea = styled.textarea<StyledTextAreaPropTypes>`
  border-radius: 6px;
  background: var(--white);
  border: 1px solid var(--light-gray);
  width: 100%;
  height: 100%;
  padding: 1em;
  font-size: ${font.size.lg};
  margin: 0.5em 0;
  appearance: none;
  font-family: "Kumbh Sans", sans-serif;
  min-height: 200px;
  resize: none;
  border-color: ${(p) => p.error && "var(--brand-danger)"};
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--light-yellow);
  }
`

export const ValidationMessage = styled.div`
  font-size: ${font.size.xs};
  color: var(--brand-danger);
  margin-bottom: 9px;
`
