import type { AxiosError, AxiosResponse } from "axios"
import crypto from "crypto"

import axiosInstance from "ApiServices/axios"
import authInstance from "ApiServices/authAxios"
import type {
  BankIdAuthData,
  ISignatureAuthStatus,
  ITokenData,
} from "Interfaces/authInterfaces"
import { base64URLEncode } from "Utils/signingUtils"
import { getEnv, EnvKeysEnum } from "Utils/environment"
import { Api } from "Constants/api"
import { REFRESH_TOKEN, SIGNATURE_CODE } from "Constants/storage"
import {
  getStorageItem,
  getStorageItemOrDefault,
  setStorageItem,
} from "Utils/localStorage"

export const initiateAuthSession = async (ssn?: string) => {
  try {
    const { data } = await axiosInstance.post<
      BankIdAuthData,
      AxiosResponse<BankIdAuthData>,
      { personal_number?: string }
    >(Api.BankidAuth, {
      personal_number: ssn,
    })
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getToken = async (code: string, verifier: string) => {
  try {
    const postData = {
      code,
      code_verifier: verifier,
      grant_type: "authorization_code",
      client_id: getEnv(EnvKeysEnum.BroccAuthClientId),
    }

    const { data } = await authInstance.post<ITokenData>(Api.Token, postData)

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getSignatureAuthStatus = async (
  uid: string,
  challenge: string,
  state: string,
  signal?: AbortSignal
) => {
  const { data } = await axiosInstance.get<ISignatureAuthStatus>(
    Api.BankidAuthStatus.replace(":id", uid),
    {
      signal,
      params: {
        client_id: getEnv(EnvKeysEnum.BroccAuthClientId),
        code_challenge: challenge,
        code_challenge_method: "S256",
        response_type: "code",
        state,
      },
    }
  )
  setStorageItem(SIGNATURE_CODE, data.code)
  return data
}

export const getRefreshedToken = async () => {
  try {
    const refreshToken = getStorageItem(REFRESH_TOKEN)
    const code = getStorageItemOrDefault<string>(SIGNATURE_CODE, "")
    const verifier = base64URLEncode(crypto.randomBytes(32))

    const postData = {
      code,
      code_verifier: verifier,
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      client_id: getEnv(EnvKeysEnum.BroccAuthClientId),
    }

    const { data } = await authInstance.post(Api.Token, postData)

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}
