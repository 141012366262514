import { FC, useState } from "react"
import { FormattedMessage } from "react-intl"
import { AnimatePresence } from "framer-motion"

import { useDeleteReport } from "Hooks/API/useReports"
import CustomFormattedDate from "Components/CustomFormattedDate"
import { useFeedbackModal } from "Context/modal-context"
import Button from "UI/Button"
import IconFactory from "UI/IconFactory"
import Loading from "UI/Loading"
import Modal from "UI/Modal"
import { useFileDownload } from "Hooks/API/useFileDownload"
import { getReportPdf } from "ApiServices/reports"
import { REPORT_PDF } from "Constants/fileNames"
import * as S from "./ReportsTableRow.styles"
import type {
  ReportsTableRowPropTypes,
  DeleteType,
} from "./ReportsTableRow.types"

const ReportsTableRow: FC<ReportsTableRowPropTypes> = ({ report }) => {
  const [show, setShow] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const deleteReportMutation = useDeleteReport()
  const { errorModal } = useFeedbackModal()

  const { downloadFile, fileDownloadQuery } = useFileDownload({
    fileName: report?.file?.filename || REPORT_PDF,
    fileId: report.uid,
    fetchFile: getReportPdf,
    onError: () => errorModal(),
  })

  const deleteTypes: DeleteType = {
    "investor-report": "investment-reports",
    "deposit-report": "deposit-service-reports",
  }

  const deleteReport = () => {
    const deleteType = deleteTypes[report.publishable_type]
    const link = `${deleteType}/${report.publishable_uid}`
    if (deleteType) {
      deleteReportMutation.mutateAsync(link).catch(() => errorModal())
    }
  }

  const handleDelete = () => {
    deleteReport()
    setIsModalOpen(false)
  }

  const handleCloseModal = () => setIsModalOpen(false)

  const handleOpenModal = () => setIsModalOpen(true)

  const toggleShowMobileRowWrap = () => {
    setShow((prevState) => !prevState)
  }

  return (
    <S.TableItemsWrap>
      <S.TableDataItem>
        {fileDownloadQuery.isLoading ? (
          <Loading size="small" />
        ) : (
          <S.DownloadLink onClick={downloadFile}>
            <IconFactory name="download" />
          </S.DownloadLink>
        )}
      </S.TableDataItem>

      <S.TableDataItem hide>
        <CustomFormattedDate value={report?.created_at} />
      </S.TableDataItem>

      <S.TableDataItem>{report?.file?.file_type}</S.TableDataItem>

      <S.TableDataItem hide>
        {deleteTypes[report.publishable_type] && (
          <S.DeleteLink
            href="#"
            onClick={handleOpenModal}
            type={report?.publishable_type}
          >
            <IconFactory name="trash" />
          </S.DeleteLink>
        )}
      </S.TableDataItem>

      <S.TableDataItem chevron>
        <S.ExpandButton onClick={toggleShowMobileRowWrap} show={show}>
          <IconFactory name="chevronRight" />
        </S.ExpandButton>
      </S.TableDataItem>

      <S.MobileRowWrap show={show} mobile>
        <S.MobileRow>
          <div>
            <S.MobileRowItem>
              <strong>
                <FormattedMessage
                  id="app.mypages.reports.table.created"
                  defaultMessage="Skapad"
                />
              </strong>{" "}
              : <CustomFormattedDate value={report?.created_at} />
            </S.MobileRowItem>
          </div>

          <S.MobileRowItem>
            {deleteTypes[report.publishable_type] && (
              <S.DeleteLink href="#" onClick={handleOpenModal}>
                <IconFactory name="trash" />
              </S.DeleteLink>
            )}
          </S.MobileRowItem>
        </S.MobileRow>
      </S.MobileRowWrap>

      <AnimatePresence>
        {isModalOpen && (
          <Modal
            maxWidth="30%"
            body={
              <S.BodyModal>
                <FormattedMessage
                  id="app.mypages.reports.modal.delete"
                  defaultMessage="Vill du radera?"
                />
                <S.ModalButtonContainer>
                  <Button
                    disabled={deleteReportMutation.isLoading}
                    onClick={handleDelete}
                  >
                    <FormattedMessage id="app.common.yes" defaultMessage="Ja" />
                  </Button>
                  <Button onClick={handleCloseModal}>
                    <FormattedMessage
                      id="app.common.cancel"
                      defaultMessage="Avbryt"
                    />
                  </Button>
                </S.ModalButtonContainer>
              </S.BodyModal>
            }
            onClick={handleCloseModal}
            title={
              <FormattedMessage
                id="app.mypages.mainNav.reports"
                defaultMessage="Rapporter"
              />
            }
          />
        )}
      </AnimatePresence>
    </S.TableItemsWrap>
  )
}

export default ReportsTableRow
