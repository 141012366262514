import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { FormattedMessage } from "react-intl"

import IconFactory from "UI/IconFactory"
import NotFound from "Components/NotFound"
import { useGetLoanApplication } from "Hooks/API/useLoansApplications"
import { ILoanApplicant } from "Interfaces/loanApplicationInterfaces"
import UnauthenticatedNav from "Components/UnauthenticatedNav"
import ProgressBar from "UI/ProgressBar"
import Loading from "UI/Loading"
import BankScreening from "../Onboarding/Steps/BankScreening/BankScreening"
import {
  LoadingWrap,
  SuccessWrap,
  IndependentBankScreeningWrap,
} from "./IndependentBankScreening.styles"
import { ProgressBarWrap } from "../Onboarding/Onboarding.styles"

const IndependentBankScreening = () => {
  const { application, applicant } = useParams() as {
    application: string
    applicant: string
  }
  const { data, status } = useGetLoanApplication(application)
  const [selectedApplicant, setSelectedApplicant] =
    useState<ILoanApplicant | null>(null)

  const [isScreeningCompleted, setIsScreeningCompleted] = useState(false)

  useEffect(() => {
    if (!data) {
      return
    }

    if (data.applicant.uid === applicant) {
      setSelectedApplicant(data.applicant)
    } else if (data.co_applicant && data.co_applicant.uid === applicant) {
      // Quick fix to make screening of co applicant.
      data.applicant = data.co_applicant
      setSelectedApplicant(data.co_applicant)
    }
  }, [applicant, data])

  if (isScreeningCompleted) {
    return (
      <SuccessWrap>
        <IconFactory name="manRelaxing" />
        <h1 data-testid="title">
          <FormattedMessage
            id="app.independentBankScreening.success.header"
            defaultMessage="Bankuppgifter verifierade"
          />
        </h1>
        <p data-testid="paragraph">
          <FormattedMessage
            id="app.independentBankScreening.success.message"
            defaultMessage="Inhämtning av din bankhistorik är färdig."
          />
        </p>
      </SuccessWrap>
    )
  }

  if (status === "success" && !selectedApplicant) {
    return <NotFound />
  }

  return (
    <IndependentBankScreeningWrap>
      <UnauthenticatedNav />
      <ProgressBarWrap>
        <ProgressBar width={0} />
      </ProgressBarWrap>
      {status === "error" && <NotFound />}
      {status === "loading" && (
        <LoadingWrap>
          <Loading />
        </LoadingWrap>
      )}

      {status === "success" && (
        <BankScreening
          data={data}
          setIsScreeningCompleted={setIsScreeningCompleted}
          isIndependent
        />
      )}
    </IndependentBankScreeningWrap>
  )
}

export default IndependentBankScreening
