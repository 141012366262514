import styled, { css } from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

type BalanceOverviewContainerProps = {
  isMonthlySaving?: boolean
}

export const BalanceOverviewContainer = styled.div<BalanceOverviewContainerProps>`
  border-radius: 20px;
  padding: 3em 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background: ${(p) => p.theme.infoBox};
  ${(p) =>
    p.isMonthlySaving &&
    css`
      padding: 1em 4em;
      margin: 0;
    `}

  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    flex-direction: column;
    height: auto;
    text-align: center;
    align-items: center;
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    padding: 0;
  }
`
