import { FC } from "react"
import { FormattedMessage } from "react-intl"

import IconFactory from "UI/IconFactory"
import ProgressRing from "UI/ProgressRing"
import { LoanApplicationStatusType } from "Interfaces/loanApplicationInterfaces"
import UpdateDepositeName from "Pages/Authenticated/Savings/Home/UpdateDepositeName/UpdateDepositName"
import { MessageId } from "Constants/languages"
import { Urls } from "Constants/urls"
import { determineStatusIcon } from "./helpers"
import * as S from "./AccountName.styles"

type AccountNamePropTypes = {
  type: "loans" | "savings" | "loanApplications" | "loan" | "bank"
  accountNumber: number | string
  accountTitleId: MessageId
  name?: string
  progress?: number
  scaled?: boolean
  status?: LoanApplicationStatusType
  showDepositUpdate?: boolean
}

const AccountName: FC<AccountNamePropTypes> = ({
  type,
  accountNumber,
  accountTitleId,
  name,
  progress,
  scaled,
  status,
  showDepositUpdate,
}) => {
  const accountIcon = {
    loan: (
      <S.IconWithBackLinkWrap to={Urls.Loans}>
        <IconFactory name="chevron" viewBox="0 0 35 35" />
        <ProgressRing
          size={scaled ? 50 : 40}
          progress={progress || 0}
          strokeWidth={scaled ? 10 : 7}
        />
      </S.IconWithBackLinkWrap>
    ),
    loans: (
      <ProgressRing
        size={scaled ? 50 : 40}
        progress={progress || 0}
        strokeWidth={scaled ? 10 : 7}
      />
    ),
    loanApplications: (
      <S.SVGWrap status={status}>{determineStatusIcon(status)}</S.SVGWrap>
    ),
    savings: (
      <S.SVGWrap type="savings">
        <IconFactory name="cashbackPig" />
      </S.SVGWrap>
    ),
    bank: <S.SVGWrap type="bank" name={name} />,
  }

  return (
    <S.ActiveAccountName scaled={scaled}>
      {accountIcon[type]}
      <S.AccountNameAndNumberWrap scaled={scaled}>
        <S.AccountNameWrap>
          {name || <FormattedMessage id={accountTitleId} />}
          {showDepositUpdate && <UpdateDepositeName />}
        </S.AccountNameWrap>
        <S.AccountNumber scaled={scaled} data-testid="account-number">
          {accountNumber}
        </S.AccountNumber>
      </S.AccountNameAndNumberWrap>
    </S.ActiveAccountName>
  )
}

export default AccountName
